/* eslint-disable react/prop-types */
import React from "react";

export const Delete = () => (
  <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.56127 5.99987L12.2801 1.28102C12.5733 0.988097 12.5733 0.51281 12.2801 0.219883C11.9869 -0.0732943 11.5122 -0.0732943 11.219 0.219883L6.50012 4.93873L1.78102 0.219883C1.48785 -0.0732943 1.01306 -0.0732943 0.719883 0.219883C0.426706 0.51281 0.426706 0.988097 0.719883 1.28102L5.43898 5.99987L0.719883 10.7187C0.426706 11.0117 0.426706 11.4869 0.719883 11.7799C0.866471 11.9262 1.05859 11.9995 1.25045 11.9995C1.44232 11.9995 1.63444 11.9262 1.78102 11.7796L6.50012 7.06077L11.219 11.7796C11.3656 11.9262 11.5577 11.9995 11.7495 11.9995C11.9414 11.9995 12.1335 11.9262 12.2801 11.7796C12.5733 11.4867 12.5733 11.0114 12.2801 10.7185L7.56127 5.99987Z" fill="#595959" />
  </svg>
);

export const Up = ({ svgClassName, fill = "#5A2AF1" }) => (
  <svg className={String(svgClassName)} width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.37821 0.76523L0.257566 8.09864C-0.0858555 8.45233 -0.0858555 9.02574 0.257566 9.37938L1.08808 10.2347C1.43091 10.5878 1.98655 10.5885 2.33019 10.2362L8.00002 4.42437L13.6698 10.2363C14.0134 10.5885 14.5691 10.5878 14.9119 10.2347L15.7424 9.37942C16.0859 9.02574 16.0859 8.45232 15.7424 8.09868L8.62179 0.765268C8.27841 0.411584 7.72163 0.411584 7.37821 0.76523Z" fill={fill} />
  </svg>
);

export const Down = ({ svgClassName, fill = "#5A2AF1" }) => (
  <svg className={String(svgClassName)} width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.62179 10.2348L15.7424 2.90136C16.0859 2.54767 16.0859 1.97426 15.7424 1.62062L14.9119 0.765292C14.5691 0.412212 14.0134 0.411532 13.6698 0.763783L7.99998 6.57563L2.33019 0.763745C1.98655 0.411495 1.43091 0.412174 1.08808 0.765254L0.257567 1.62058C-0.0858556 1.97426 -0.0858556 2.54767 0.257567 2.90132L7.37821 10.2347C7.72159 10.5884 8.27837 10.5884 8.62179 10.2348Z" fill={fill} />
  </svg>
);

export const Duplicate = ({ svgClassName }) => (
  <svg className={String(svgClassName)} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.25 4.25H6C5.175 4.25 4.5 4.925 4.5 5.75V15.5C4.5 15.9125 4.8375 16.25 5.25 16.25C5.6625 16.25 6 15.9125 6 15.5V6.5C6 6.0875 6.3375 5.75 6.75 5.75H14.25C14.6625 5.75 15 5.4125 15 5C15 4.5875 14.6625 4.25 14.25 4.25ZM17.25 7.25H9C8.175 7.25 7.5 7.925 7.5 8.75V19.25C7.5 20.075 8.175 20.75 9 20.75H17.25C18.075 20.75 18.75 20.075 18.75 19.25V8.75C18.75 7.925 18.075 7.25 17.25 7.25ZM16.5 19.25H9.75C9.3375 19.25 9 18.9125 9 18.5V9.5C9 9.0875 9.3375 8.75 9.75 8.75H16.5C16.9125 8.75 17.25 9.0875 17.25 9.5V18.5C17.25 18.9125 16.9125 19.25 16.5 19.25Z" fill="#212B46" />
  </svg>
);
