import React, { useCallback, useRef, useState } from "react";
import TooltipSuccess from "components/Common/Tooltip/Success";
import { Agency, KeyRotate, MailBlack, Phone, Tick } from "mixins/svgIcons";
import { copyTextToClipboard } from "mixins/helpers";
import PropTypes from "prop-types";

const icons = {
  Tick: <Tick />,
  Phone: <Phone />,
  Agency: <Agency />,
  MailBlack: <MailBlack />,
  KeyRotate: <KeyRotate />
};

const ListItem = ({ t, item, index }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tootipText, setTooltipText] = useState("");

  const handleCopyText = useCallback(({ text, key }) => {
    if (!text || key !== "integrationKey") return;
    setTooltipText(t("button.copied"));
    setShowTooltip(true);
    copyTextToClipboard(text);
    setTimeout(() => setShowTooltip(false), 1000);
  }, [t]);

  const handleShowTooltip = useCallback(({ text, key }) => {
    if (!text || key !== "integrationKey") return;
    setTooltipText(t("button.infoClickTip"));
    setShowTooltip(true);
  }, []);

  const handleHideTooltip = useCallback(() => {
    setShowTooltip(false);
  }, []);

  const itemRef = useRef(null);

  return (
    <li
      className="profile-card__item"
      title={item.text}
      key={`profile-card-${item.id}`}
    >
      <div
        role="button"
        tabIndex={index}
        onMouseEnter={() => handleShowTooltip(item)}
        onMouseLeave={() => handleHideTooltip(item)}
        onClick={() => handleCopyText(item)}
        onKeyDown={() => handleCopyText(item)}
        ref={itemRef}
      >
        <span className="profile-card__item-icon">
          {icons[item.icon]}
        </span>
        <span className="profile-card__item-text">{item.text}</span>
      </div>

      {item.text && item.key === "integrationKey" && (
        <TooltipSuccess
          position="top"
          message={tootipText}
          target={itemRef.current}
          show={showTooltip}
        />
      )}
    </li>
  );
};

ListItem.defaultProps = {
  index: undefined,
  item: undefined,
  t: undefined
};

ListItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string,
    key: PropTypes.string,
    id: PropTypes.string
  }),
  t: PropTypes.func
};

export default ListItem;
