import React from "react";
import PropTypes from "prop-types";

import { formTimeString } from "mixins/helperVideoRecording";
import classNames from "classnames";

const CountdownTimer = ({ cashedElapsingTime }) => {
  const timerInSeconds = cashedElapsingTime / 1000;
  return (
    <div className={classNames("countdown-timer", (timerInSeconds <= 20) ? "n-bg-red-100 blink" : "")}>
      <span>
        {formTimeString(cashedElapsingTime)}
      </span>
    </div>
);
  };

CountdownTimer.defaultProps = {
  cashedElapsingTime: undefined
};

CountdownTimer.propTypes = {
  cashedElapsingTime: PropTypes.number
};

export default CountdownTimer;
