import { connect } from "react-redux";

import { fetchIndustries } from "store/modules/jobs/actions";
import { createCompany, clearCompanyError } from "store/modules/companies/actions";
import { getIsTrialOrPaid } from "store/modules/notifications/selectors";
import { getIndustriesOptionsForSelect, getCompanyError } from "store/modules/companies/selectors";
import { getCardInfoLoading } from "store/modules/profile/selectors";
import Create from "views/Companies/Create";

const mapStateToProps = state => ({
  isCardInfoLoading: getCardInfoLoading(state),
  isAbiltyСreate: getIsTrialOrPaid(state),
  industriesOptions: getIndustriesOptionsForSelect(state),
  error: getCompanyError(state),
  isLoadingScreen: state.widgets.isLoading
});

const mapDispatchToProps = {
  fetchIndustries,
  createCompany,
  clearCompanyError
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
