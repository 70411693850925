import React, { useState, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import BeatLoader from "react-spinners/BeatLoader";

import { Close } from "mixins/svgIcons";
import { ROLE_ADMIN, ROLE_STANDARD } from "configs/user/rolesConfig";

import { Error } from "./svgIcons";
import Buttons from "./Buttons";

const rEmail =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

const StepInvite = ({
  t,
  error,
  isUsersLoading,
  setStep,
  updateAgency,
  addNewUsers
}) => {
  const [newAdminInvite, setNewAdminInvite] = useState("");
  const [adminInvites, setAdminInvites] = useState([]);
  const [newStandardInvite, setNewStandardInvite] = useState("");
  const [standardInvites, setStandardInvites] = useState([]);

  const handleAdminInput = e => {
    e.preventDefault();
    if (newAdminInvite) {
      if (
        adminInvites.every(({ email }) => newAdminInvite.toLowerCase() !== email.toLowerCase()) &&
        standardInvites
          .every(({ email }) => newAdminInvite.toLowerCase() !== email.toLowerCase())
      ) {
        const isValid = rEmail.test(newAdminInvite);
        setAdminInvites([...adminInvites, {
          email: newAdminInvite,
          isValid
        }]);
      }
      setNewAdminInvite("");
    }
  };
  const handleStandardInput = e => {
    e.preventDefault();
    if (newStandardInvite) {
      if (
        adminInvites
          .every(({ email }) => newStandardInvite.toLowerCase() !== email.toLowerCase()) &&
        standardInvites
          .every(({ email }) => newStandardInvite.toLowerCase() !== email.toLowerCase())
      ) {
        const isValid = rEmail.test(newStandardInvite);
        setStandardInvites([...standardInvites, {
          email: newStandardInvite,
          isValid
        }]);
      }
      setNewStandardInvite("");
    }
  };

  const handleKeyDown = e => {
    if (["Enter", ";", ",", " "].includes(e.key)) {
      handleAdminInput(e);
      handleStandardInput(e);
    }
  };
  const handleAdminDelete =
    email => setAdminInvites(adminInvites.filter(invite => invite.email !== email));
  const handleStandardDelete =
    email => setStandardInvites(standardInvites.filter(invite => invite.email !== email));
  const removeNotValidAdminEmails =
    () => setAdminInvites(adminInvites.filter(invite => invite.isValid));
  const removeNotValidStandardEmails =
    () => setStandardInvites(standardInvites.filter(invite => invite.isValid));
  const errorAdminCounter =
    useMemo(() => adminInvites.filter(({ isValid }) => !isValid).length, [adminInvites]);
  const errorStandardCounter =
    useMemo(() => standardInvites.filter(({ isValid }) => !isValid).length, [standardInvites]);
  const handlePrevStep = () => setStep(3);
  const handleNextStep = () => {
    setStep(5);
    updateAgency({
      is_team_invited: true
      // is_setup_completed: true
    });
  };
  const handleClick = () => {
    addNewUsers({
      invites: [
        ...adminInvites.map(({ email }) => ({ email, role: ROLE_ADMIN })),
        ...standardInvites.map(({ email }) => ({ email, role: ROLE_STANDARD }))
      ],
      callback: handleNextStep
    });
  };

  return (
    <>
      <div className="onboard__card">
        <Row className="onboard__flex">
          <Col md={12} className="onboard__card-title">
            <div>
              <h1 className="onboard__title n-grey-100 n-font-semi-bold">
                {t("onboarding.letsInvite")}
              </h1>
              <p className="onboard__subtitle n-grey-100 n-font-large n-font-medium-weight">
                {t("onboarding.allowThem")}
              </p>
              <div className="onboard__invites">
                <div className="onboard__invites-container">
                  <p className="n-font-small n-black-80">{t("onboarding.adminUsers")}</p>
                  <div className="onboard__invites-textarea">
                    {adminInvites.map(({ email, isValid }) => (
                      <div className={`onboard__invites-email ${isValid ? "" : "error"}`}>
                        {!isValid && (
                          <div className="onboard__invites-email-error">
                            <Error />
                          </div>
                        )}
                        <p>{email}</p>
                        <div role="button" onKeyPress={null} tabIndex={0} onClick={() => handleAdminDelete(email)} className="onboard__invites-email-close">
                          <Close fill="#BDC0C8" width="14" height="14" />
                        </div>
                      </div>
                    ))}
                    <input
                      className="n-font-small onboard__invites-input"
                      placeholder={t("input.inviteUserPlaceholder")}
                      value={newAdminInvite}
                      onChange={event => setNewAdminInvite(event.target.value)}
                      onKeyDown={handleKeyDown}
                      onBlur={handleAdminInput}
                      type="text"
                    />
                  </div>
                  {errorAdminCounter ? (
                    <p className="n-black-100 n-font-small onboard__invites-error-message">
                      <Error />
                      {t(`onboarding.removeAll.${errorAdminCounter === 1 ? "0" : "1"}`, { counter: errorAdminCounter })}
                      <button type="button" className="button__without-styles n-blue-100" onClick={removeNotValidAdminEmails}>
                        {t("onboarding.removeAll.2")}
                      </button>
                    </p>
                  ) : null}
                </div>
                <div className="onboard__invites-container">
                  <p className="n-font-small n-black-80">{t("onboarding.standardUsers")}</p>
                  <div className="onboard__invites-textarea">
                    {standardInvites.map(({ email, isValid }) => (
                      <div className={`onboard__invites-email ${isValid ? "" : "error"}`}>
                        {!isValid && (
                          <div className="onboard__invites-email-error">
                            <Error />
                          </div>
                        )}
                        <p>{email}</p>
                        <div role="button" onKeyPress={null} tabIndex={0} onClick={() => handleStandardDelete(email)}>
                          <Close fill="#BDC0C8" width="14" height="14" />
                        </div>
                      </div>
                    ))}
                    <input
                      className="n-font-small onboard__invites-input"
                      placeholder={t("input.inviteUserPlaceholder")}
                      value={newStandardInvite}
                      onChange={event => setNewStandardInvite(event.target.value)}
                      onKeyDown={handleKeyDown}
                      onBlur={handleStandardInput}
                      type="text"
                    />
                  </div>
                  {errorStandardCounter ? (
                    <p className="n-black-100 n-font-small onboard__invites-error-message">
                      <Error />
                      {t(`onboarding.removeAll.${errorStandardCounter === 1 ? "0" : "1"}`, { counter: errorStandardCounter })}
                      <button type="button" className="button__without-styles n-blue-100" onClick={removeNotValidStandardEmails}>
                        {t("onboarding.removeAll.2")}
                      </button>
                    </p>
                  ) : null}
                </div>
              </div>
              {error ? (
                <p className="n-black-100 n-font-small onboard__invites-error-message">
                  <Error />
                  {t(error)}
                </p>
              ) : null}
            </div>
            <div className="onboard__next">
              <button
                className="onboard__button n-button__medium n-bg-purple-100 n-white"
                type="button"
                onClick={handleClick}
                disabled={
                  !(adminInvites.length || standardInvites.length) ||
                  (errorAdminCounter || errorStandardCounter) || isUsersLoading
                }
              >
                {isUsersLoading ? <BeatLoader color="#FFFFFF" size={6} margin={1} loading /> : t("button.invite")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <Buttons
        handlePrevStep={handlePrevStep}
        handleNextStep={handleNextStep}
      />
    </>
  );
};

StepInvite.propTypes = {
  t: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  isUsersLoading: PropTypes.bool.isRequired,
  setStep: PropTypes.func.isRequired,
  updateAgency: PropTypes.func.isRequired,
  addNewUsers: PropTypes.func.isRequired
};

export default withTranslation()(StepInvite);
