import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Buttons from "./Buttons";

const StepCompany = ({ t, setStep, updateAgency, name }) => {
  const [company, setCompany] = useState("");
  const handlePrevStep = () => setStep(1);
  const handleNextStep = () => {
    setStep(3);
    updateAgency({ is_agency_name_provided: true });
  };
  const handleClick = () => {
    updateAgency({
      name: company?.trim(),
      is_agency_name_provided: true
    });
    setStep(3);
  };

  useEffect(() => {
    if (name) {
      setCompany(name);
    }
  }, [name]);

  return (
    <>
      <div className="onboard__card">
        <Row className="onboard__flex">
          <Col md={12} className="onboard__card-title">
            <div>
              <h1 className="onboard__title n-grey-100 n-font-semi-bold">
                {t("onboarding.whatsTheName")}
              </h1>
              <p className="onboard__subtitle n-grey-100 n-font-large n-font-medium-weight">
                {t("onboarding.wellUse")}
              </p>
              <div className="input-wrapper">
                <input
                  className="form__search-input name-search__input n-font-small onboard__input"
                  placeholder={t("input.organisationName")}
                  value={company}
                  onChange={event => setCompany(event.target.value)}
                  type="text"
                />
              </div>
            </div>
            <div className="onboard__next">
              <button
                className="onboard__button n-button__medium n-bg-purple-100 n-white"
                type="button"
                onClick={handleClick}
                disabled={!company?.trim()}
              >
                {t("button.nextStep")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <Buttons
        handlePrevStep={handlePrevStep}
        handleNextStep={handleNextStep}
      />
    </>
  );
};

StepCompany.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  updateAgency: PropTypes.func.isRequired
};

export default withTranslation()(StepCompany);
