import { createAction } from "redux-actions";

export const fetchNotifications = createAction("FETCH_NOTIFICATIONS");
export const setNotificationsResponse = createAction(
  "SET_NOTIFICATIONS_RESPONSE"
);
export const fetchNotificationsError = createAction(
  "FETCH_NOTIFICATIONS_ERROR"
);
export const setPromoResponse = createAction(
  "SET_PROMO_RESPONSE"
);
export const setQuotaExceedMute = createAction("PUT_QUOTA_EXCEED_MUTE");
export const setQuotaExceedMuteResponse = createAction(
  "PUT_QUOTA_EXCEED_MUTE_Response"
);
export const setShowTutorialMute = createAction("PUT_SHOW_TUTORIAL_MUTE");
export const setShowTutorialMuteResponse = createAction(
  "PUT_SHOW_TUTORIAL_MUTE_Response"
);

export const setCompletedOnboarding = createAction("SET_COMPLETED_ONBOARDING");
