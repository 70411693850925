import { ANSWER_TYPES, CHECKBOXES_TYPE } from "configs/jobs/constants";
import { nanoid } from "nanoid";
import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { map } from "lodash";
import MultipleOptionItem from "./MultipleOptionItem";

const MultipleOptions = ({ t, answerType, order, options, isEditable }) => {
  const { setValue } = useFormContext();

  const handleAddOption = () => {
    setValue(`questions[${order - 1}].type_specific_data.answer_options`, [
      ...options,
      {
        key: `new__${nanoid()}`,
        text: null,
        autofocus: true,
        is_correct: false,
        is_touched: false
      }
    ]);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Handles the sorting of options in the MultipleOptions component.
   *
   * @param {Object} result - The result object from the react-beautiful-dnd library.
   */
  const onSort = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      options,
      result.source.index,
      result.destination.index
    );
    setValue(`questions[${order - 1}].type_specific_data.answer_options`, items);
  };

  return (
    <>
      {[
        ANSWER_TYPES.multiple_choice.value,
        ANSWER_TYPES.checkboxes.value
      ]?.includes(answerType) ? (
        <div className="multiple-options__container">
          <DragDropContext onDragEnd={onSort}>
            <Droppable droppableId="droppable-options">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="multiple-options__container-item">
                  {map(options, (item, index) => (
                    <Draggable
                      key={`draggable-item-${index}`}
                      draggableId={`${item.key}-${index}`}
                      index={index}
                      isDragDisabled={options?.length === 1}
                    >
                      {provided2 => (
                        <div
                          ref={provided2.innerRef}
                          {...provided2.draggableProps}
                          {...provided2.dragHandleProps}
                          tabIndex="-1"
                        >
                          <MultipleOptionItem
                            item={item}
                            key={item.key}
                            optionOrder={index}
                            questionOrder={order}
                            answerType={answerType}
                            handleAddOption={handleAddOption}
                            isEditable={isEditable}
                            isDraggable={options?.length > 1}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div
            className="multiple-options__wrapper"
            style={{ paddingLeft: 23 }}
          >
            {answerType === CHECKBOXES_TYPE ? (
              <Form.Check
                type="checkbox"
                defaultChecked={false}
                readOnly
                onClick={() => handleAddOption()}
              />
            ) : (
              <div>
                <input
                  type="radio"
                  defaultChecked={false}
                  onClick={() => handleAddOption()}
                  readOnly
                />
              </div>
            )}

            <button
              type="button"
              tabIndex="0"
              className="button__without-styles add-option"
              onClick={() => handleAddOption()}
            >
              {t("input.addOption")}
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};

MultipleOptions.defaultProps = {
  answerType: undefined,
  order: undefined,
  options: undefined,
  isEditable: false
};

MultipleOptions.propTypes = {
  t: PropTypes.func.isRequired,
  answerType: PropTypes.string,
  order: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  isEditable: PropTypes.bool
};

export default withTranslation()(MultipleOptions);
