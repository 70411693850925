import { createAction } from "redux-actions";

export const isLibrariesLoading = createAction("IS_LIBRARIES_LOADING");
export const isCurrentLibraryLoading = createAction("IS_CURRENT_LIBRARY_LOADING");
export const isLatestLibrariesLoading = createAction("IS_LATEST_LIBRARIES_LOADING");

export const fetchLibraries = createAction("FETCH_LIBRARIES");
export const fetchLibrariesResponse = createAction("FETCH_LIBRARIES_RESPONSE");
export const fetchLibrariesError = createAction("FETCH_LIBRARIES_ERROR");

export const fetchLatestLibraries = createAction("FETCH_LATEST_LIBRARIES");
export const fetchLatestLibrariesResponse = createAction("FETCH_LATEST_LIBRARIES_RESPONSE");

export const fetchLibrariesDivided = createAction("FETCH_LIBRARIES_DIVIDED");
export const fetchLibrariesDividedResponse = createAction("FETCH_LIBRARIES_DIVIDED_RESPONSE");
export const fetchLibrariesDividedError = createAction("FETCH_LIBRARIES_DIVIDED_ERROR");

export const deleteLibrary = createAction("DELETE_LIBRARY");
export const deleteLibraryResponse = createAction("DELETE_LIBRARY_RESPONSE");
export const deleteLibraryError = createAction("DELETE_LIBRARY_ERROR");

export const fetchCurrentLibrary = createAction("FETCH_CURRENT_LIBRARY");
export const fetchCurrentLibraryResponse = createAction("FETCH_CURRENT_LIBRARY_RESPONSE");
export const fetchCurrentLibraryError = createAction("FETCH_CURRENT_LIBRARY_ERROR");

export const createLibrary = createAction("CREATE_LIBRARY_START");
export const createLibraryError = createAction("CREATE_LIBRARY_ERROR");
export const updateLibrary = createAction("UPDATE_LIBRARY");
export const updateLibraryError = createAction("UPDATE_LIBRARY_ERROR");

export const duplicateLibrary = createAction("DUPLICATE_LIBRARY");
export const duplicateLibraryResponse = createAction("DUPLICATE_LIBRARY_RESPONSE");
export const duplicateLibraryError = createAction("DUPLICATE_LIBRARY_ERROR");

export const clearLibraryError = createAction("CLEAR_LIBRARY_ERROR");
