import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import TooltipInfo from "components/Common/Tooltip/Info";
import DropdownIndicator from "./dropdown";

const SelectInput = ({
  disabled,
  label,
  topRightLabel,
  bottomLabel,
  options,
  value,
  defaultValue,
  error,
  changed,
  getOptionLabel,
  components,
  isSearchable,
  placeholder,
  tooltipMessage,
  onMenuClose = () => {},
  isShowTooltip = false,
  InfoElement = <></>
}) => {
  const inputClasses = ["input"];
  const selectElementClasses = ["select"];

  if (error) {
    selectElementClasses.push("invalid");
  }

  return (
    <div className={inputClasses}>
      <div className="label-container">
        {label && (
          <div className="label-container-element">
            <span className="label n-font-small n-black-100">{label}</span>
            {InfoElement}
          </div>
        )}
        {topRightLabel && (
          <span className="label n-font-small n-grey-100">{topRightLabel}</span>
        )}
      </div>

      <TooltipInfo
        prefix="tooltip__icon tooltip__icon-people without-styles align-center"
        position="bottom"
        message={tooltipMessage}
        isShow={false}
        disabled={!isShowTooltip}
      >
        <div className="input-wrapper">
          <Select
            className={selectElementClasses.join(" ")}
            classNamePrefix="select"
            options={options}
            value={value}
            components={{ DropdownIndicator, ...components }}
            defaultValue={defaultValue}
            isDisabled={disabled}
            onChange={changed}
            getOptionLabel={getOptionLabel}
            isSearchable={isSearchable}
            placeholder={placeholder}
            onMenuClose={onMenuClose}
          />

          {bottomLabel && (
            <span className="label__bottom n-font-small n-black-100">
              {bottomLabel}
            </span>
          )}

          {error && <p className="input-error-message">{error}</p>}
        </div>
      </TooltipInfo>
    </div>
  );
};

SelectInput.defaultProps = {
  disabled: undefined,
  label: undefined,
  bottomLabel: undefined,
  topRightLabel: undefined,
  value: undefined,
  defaultValue: undefined,
  error: undefined,
  changed: undefined,
  getOptionLabel: undefined,
  components: null,
  isSearchable: true,
  placeholder: undefined,
  tooltipMessage: undefined,
  isShowTooltip: false,
  onMenuClose: undefined,
  InfoElement: undefined
};

SelectInput.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  topRightLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({}),
    PropTypes.element,
    PropTypes.bool
  ]),
  bottomLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  value: PropTypes.shape({}),
  defaultValue: PropTypes.shape({}),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  changed: PropTypes.func,
  getOptionLabel: PropTypes.func,
  components: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({})
  ]),
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  tooltipMessage: PropTypes.string,
  isShowTooltip: PropTypes.bool,
  InfoElement: PropTypes.element,
  onMenuClose: PropTypes.func
};

export default SelectInput;
