/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { animations } from "react-animation";

import { DATE_FORMATS } from "configs/dates";
import { getTrialPeriodEnd } from "mixins/helperProfile";
import { resolveCurrency } from "mixins/helperForPayment";

import { Link } from "react-router-dom";
import Notification from "./Notification";

const Notifications = ({
  t,
  fetchNotifications,
  setQuotaExceedMute,
  currentUser: {
    key,
    billing_period_end: billingPeriodEnd,
    plan_price_per_extra_response: planPricePerCandidate = 0,
    plan_currency: planCurrency,
    plan_name: planName
  },
  profileInfo: { agency } = {},
  isSubscriptionUnpaid,
  notifications: {
    is_quota_exceed: isQuotaExceed = false,
    promo = {},
    is_trial_period: isTrialPeriod = false,
    trial_period_end: trialPeriodEnd = null,
    is_admin_user: isAdminUser = false,
    is_setup_completed: isSetupCompleted,
    is_sms_hard_limit_reached: isSmsHardLimitReached
  } = {},
  history,
  isTrialOrFree
}) => {
  useEffect(() => {
    let isMounted = true;
    if (key && fetchNotifications && isMounted) {
      isMounted = false;
      fetchNotifications({ key, refreshCompleteOnboarding: true });
    }

    return () => {
      isMounted = true;
    };
  }, [key]);

  const [animationQuotaExceed, setAnimationQuotaExceed] = useState(false);
  const [animationSubscriptionUnpaid] = useState(false);

  /*
    currently we dont have opportunity to close subscription notification
    const [animationSubscriptionUnpaid, setAnimationSubscriptionUnpaid] = useState(false);
   */

  const handleQuotaExceed = useCallback(async () => {
    if (agency && typeof setQuotaExceedMute === "function") {
      setAnimationQuotaExceed(true);
      await setQuotaExceedMute({ key: agency });
      setTimeout(() => setAnimationQuotaExceed(false), 1500);
    }
  }, [agency, setQuotaExceedMute]);

  const { popIn, popOut } = animations;

  const trialDays = useMemo(() => getTrialPeriodEnd(trialPeriodEnd, t), [
    trialPeriodEnd,
    t
  ]);

  const handleUpgrade = () => {
    if (!isTrialOrFree) {
      const message = {
        subject: t("notifications.sms_quota_upgrade.subject"),
        text: t("notifications.sms_quota_upgrade.text")
      };

      if (window.Beacon) {
        window.Beacon("open");
        window.Beacon("navigate", "/ask/message/");
        window.Beacon("prefill", message);
      }
    }
  };

  return (
    <>
      {(isQuotaExceed || animationQuotaExceed) && (
        <Notification
          type="info"
          message={t("notifications.quota_exceed", {
            currency: resolveCurrency(planCurrency),
            price: planPricePerCandidate,
            date: moment(billingPeriodEnd).format(DATE_FORMATS.SPACE_SEPARATOR)
          })}
          onClick={handleQuotaExceed}
          animation={isQuotaExceed ? popIn : popOut}
        />
      )}
      {
        isSmsHardLimitReached && (
          <Notification
            type="alert"
            className="center"
            message={(
              <>
                {t("notifications.sms_quota_exceed.0")}
                {
                  isTrialOrFree ? (
                    <Link
                      to="/plans"
                      style={{ textDecoration: "underline" }}
                    >
                      {t("notifications.sms_quota_exceed.1")}
                    </Link>
                  ) : (
                    <span
                      role="presentation"
                      onClick={handleUpgrade}
                      style={{ textDecoration: "underline", color: "#3C3C3C", cursor: "pointer" }}
                    >
                      {t("notifications.sms_quota_exceed.1")}
                    </span>
                  )
                }
              </>
            )}
            onClick={null}
            animation={isSmsHardLimitReached ? popIn : popOut}
          />
        )
      }
      {(isSubscriptionUnpaid || animationSubscriptionUnpaid) && (
        <Notification
          type="alert"
          message={t("notifications.subscription_unpaid", {})}
          onClick={null}
          animation={isSubscriptionUnpaid ? popIn : popOut}
        />
      )}

      {isTrialPeriod && isSetupCompleted && window.location.pathname !== "/onboarding" && (
        <Notification
          type="trial"
          message={`${t("notifications.trial_end", {
            count: trialDays,
            plan: planName
          })}${t("notifications.upgradeNow", { count: trialDays })}`}
          withCloseIcon={false}
          onClick={
            isAdminUser
              ? () =>
                history.push(
                  "/plans?utm_source=internal&utm_medium=user&utm_campaign=trial_banner"
                )
              : null
          }
          style={{ justifyContent: "center", cursor: "pointer" }}
          animation={popIn}
        />
      )}

      {promo.show_banner && (
        <Notification
          type="info"
          message={promo.banner_message}
          withCloseIcon={false}
          onClick={null}
          style={{ justifyContent: "center" }}
          animation={popIn}
        />
      )}
    </>
  );
};

Notifications.defaultProps = {
  t: undefined,
  fetchNotifications: undefined,
  setQuotaExceedMute: undefined,
  profileInfo: undefined,
  notifications: undefined,
  currentUser: undefined,
  isSubscriptionUnpaid: false,
  history: undefined,
  isTrialOrFree: undefined
};

Notifications.propTypes = {
  t: PropTypes.func,
  fetchNotifications: PropTypes.func,
  setQuotaExceedMute: PropTypes.func,
  profileInfo: PropTypes.shape({
    agency: PropTypes.string
  }),
  notifications: PropTypes.shape({
    is_quota_exceed: PropTypes.bool,
    is_tutorial_enabled: PropTypes.bool,
    is_subscription_unpaid: PropTypes.bool
  }),
  currentUser: PropTypes.shape({
    key: PropTypes.string,
    billing_period_end: PropTypes.string,
    plan_price_per_extra_response: PropTypes.number,
    plan_currency: PropTypes.string,
    plan_name: PropTypes.string
  }),
  isSubscriptionUnpaid: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  isTrialOrFree: PropTypes.bool
};

export default withTranslation()(Notifications);
