/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable no-nested-ternary */
import React from "react";
import PropTypes from "prop-types";

const ErrorView = ({ t, message, onlyAudio }) => {
  const isMediaBlocked =
    message.includes("Permission denied") || message.includes("mediaBlocked");

  const noAudio = message.includes("No sound");
  return (
    <div className="danger candidate-player__error">
      <div className="candidate-player__error-content">
        {isMediaBlocked ? (
          <p className="danger n-text-center n-font-huge">
            {t(`candidate.videoCreate.${onlyAudio ? "mediaBlockedAudio" : "mediaBlocked"}`)}
            <span className="danger n-font-huge"> - </span>
            {/* eslint-disable-next-line */}
            <a
              href="#"
              data-beacon-article="5feb2c886451e825e3b8eac5"
              target="_blank"
              rel="noopener noreferrer"
              className="underline danger n-font-huge"
            >
              {t("candidate.videoCreate.showMeHow")}
            </a>
          </p>
        ) : noAudio ? (
          <p className="danger n-text-center n-font-huge">
            {t("candidate.videoCreate.mediaNotDetected")}
            {" "}
            <a
              href="#"
              data-beacon-article="5feb2c886451e825e3b8eac5"
              target="_blank"
              rel="noopener noreferrer"
              className="danger n-font-huge"
            >
              {t("candidate.videoCreate.checkAudio")}
            </a>
          </p>
        ) : (
          <p className="danger n-font-huge n-text-center">{t(message)}</p>
        )}
      </div>
    </div>
  );
};

ErrorView.propTypes = {
  t: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onlyAudio: PropTypes.bool.isRequired
};

export default ErrorView;
