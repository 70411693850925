import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  updateCandidateStatus,
  deleteCandidate,
  sendComment,
  updateComment,
  deleteComment,
  sendReminderCandidate,
  fetchCandidateInformation,
  fetchCandidateIdv
} from "store/modules/jobs/actions";
import {
  changeCandidateFile,
  changeCandidateFileError,
  downloadAll
} from "store/modules/сandidates/actions";
import {
  shareCandidatePublic,
  clearSharableLink,
  setAvailableAnswer,
  setAvailableLink
} from "store/modules/public/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";
import {
  getUserCurrentJob,
  getCandidateAnswers,
  getCandidateHistory,
  getComments,
  getJobCurrent,
  getUserCurrentInterviewersList,
  getCandidateIdvResponse,
  getIsJobLoading
} from "store/modules/jobs/selectors";
import {
  getRoleOfCurrentUser,
  getCurrentUser
} from "store/modules/users/selectors";
import { getProfileInfo } from "store/modules/profile/selectors";
import { getSharableLink } from "store/modules/public/selectors";
import Candidate from "views/Jobs/Candidate";
import { ROLE_ADMIN } from "configs/user/rolesConfig";

const mapStateToProps = state => ({
  isAdmin: getRoleOfCurrentUser(state) === ROLE_ADMIN,
  sharableLink: getSharableLink(state),
  profileInfo: getProfileInfo(state),
  jobCandidateInfo: getUserCurrentJob(state),
  currentUser: getCurrentUser(state),
  jobCurrent: getJobCurrent(state),
  history: getCandidateHistory(state),
  comments: getComments(state),
  answers: getCandidateAnswers(state),
  isLoadingScreen:
    state.widgets.isLoading || state.jobsCandidate.candidateInformationLoading,
  showcaseLinkOpenedAmount: state.publicPage.showcaseLinkOpenedAmount,
  isShowcaseLinkEnabled: state.publicPage.isShowcaseLinkEnabled,
  interviewersList: getUserCurrentInterviewersList(state),
  error: state.candidates.candidateFileError,
  idvCheckData: getCandidateIdvResponse(state),
  isJobLoading: getIsJobLoading(state)
});

const mapDispatchToProps = {
  shareCandidatePublic,
  clearSharableLink,
  updateCandidateStatus,
  deleteCandidate,
  sendComment,
  updateComment,
  deleteComment,
  setLoadingScreen,
  setAvailableAnswer,
  setAvailableLink,
  sendReminderCandidate,
  changeCandidateFile,
  changeCandidateFileError,
  fetchCandidateInformation,
  downloadAll,
  fetchCandidateIdv
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Candidate)
);
