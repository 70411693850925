/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

import resetPasswordConfig from "configs/authentification/resetPassword";
import Input from "components/Common/Input";
import Message from "components/Common/Message";
import SocialsLogin from "components/SocialsLogin";
import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";
import Spinner from "components/Common/Spinner";
import PageWrapper from "hoc/AuthWrapper";
import { updateObject, checkValidity } from "mixins/helpers";

import PasswordField from "./PasswordField";
import "./styles.scss";

const ResetPassword = ({
  t,
  resetPasswordGetEmail,
  resetPassword,
  match: { params },
  email,
  clearErrors,
  isLoading,
  error
}) => {
  const [controls, setControls] = useState({ ...resetPasswordConfig });
  const [formIsValid, setFormIsValid] = useState(false);

  const constractedUrl = `${params.code}/${params.id}/`;

  const isSuccess = document.getElementsByClassName("success");

  useEffect(() => {
    resetPasswordGetEmail({ token: constractedUrl });

    return () => clearErrors();
  }, []);

  useEffect(() => {
    setControls(state => ({
      ...state,
      email: { ...state.email, value: email }
    }));
  }, [email]);

  const inputChangedHandler = (value, controlName, isPasswordValid) => {
    let currentValue = null;

    if (controlName === "password") {
      currentValue = controls.newPassword.value;
      controls.password.errorMessage = "passwordError";
    }

    if (controlName === "newPassword") {
      currentValue = controls.password.value;
      controls.newPassword.errorMessage = "passwordConfirmError";
    }

    const updatedControls = updateObject(controls, {
      [controlName]: updateObject(controls[controlName], {
        value,
        valid: checkValidity(
          value,
          controls[controlName].validation,
          currentValue
        ),
        touched: true
      })
    });

    let formIsValidCurrent = isPasswordValid;
    Object.keys(updatedControls).forEach(key => {
      formIsValidCurrent = updatedControls[key].valid && formIsValidCurrent;
      if (updatedControls[key].validation.isTheSame) {
        controls[key].errorMessage = null;
        controls[key].valid = true;
      }
    });

    setControls(updatedControls);
    setFormIsValid(formIsValidCurrent);
  };

  const onSubmitHandler = event => {
    event.preventDefault();

    resetPassword({
      url: constractedUrl,
      email: controls.email.value.toLowerCase(),
      password: controls.password.value,
      re_password: controls.newPassword.value
    });
  };

  let resetPasswordContent = (
    <div className="form form__reset-password">
      <form className="form__inner" onSubmit={onSubmitHandler}>
        <h2 className={error ? "form__title_error" : "form__title"}>
          {t("resetPassword.header")}
        </h2>

        {error && <Message error message={t(error)} />}

        <Input
          name="email"
          formElement={controls.email}
          inputChangedHandler={
            (event, name, elementType) => inputChangedHandler(event.target.value, name, elementType)
          }
        />
        <PasswordField
          name="password"
          formElement={controls.password}
          inputChangedHandler={inputChangedHandler}
        />
        <PasswordField
          name="newPassword"
          formElement={controls.newPassword}
          inputChangedHandler={inputChangedHandler}
        />

        <button
          type="submit"
          className={classNames(
            "button",
            "button__submit",
            "button__purple",
            "button__purple_shadow"
          )}
          disabled={!formIsValid && !isSuccess?.length}
        >
          {t("button.resetPassword")}
        </button>

        <div className="form__link">
          <Link to="/sign-in">{t("link.signIn")}</Link>
        </div>
      </form>

      <SocialsLogin />
    </div>
  );

  if (isLoading) {
    resetPasswordContent = <Spinner />;
  }

  return (
    <PageWrapper header={<Header />} footer={<Footer isPublic />}>
      {resetPasswordContent}
    </PageWrapper>
  );
};

ResetPassword.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.object
  }),
  email: undefined,
  resetPasswordGetEmail: undefined,
  clearErrors: undefined,
  isLoading: undefined,
  error: undefined
};

ResetPassword.propTypes = {
  t: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }),
  email: PropTypes.string,
  resetPasswordGetEmail: PropTypes.func,
  clearErrors: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string
};

export default withTranslation()(ResetPassword);
