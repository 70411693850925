import React from "react";
import PropTypes from "prop-types";

import Left from "./Left";
import Right from "./Right";
import Progress from "./Progress";

const Line = ({ children }) => <div className="progress-line">{children}</div>;

Line.Left = Left;
Line.Right = Right;
Line.Progress = Progress;

Line.defaultProps = {
  children: undefined
};

Line.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
};

export default Line;
