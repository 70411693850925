/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { animations } from "react-animation";
import { Container, Row, Col } from "react-bootstrap";

import GraphsCandidates from "components/Graphs/Candidates";
import Message from "components/Common/Message";
import PageWrapper from "hoc/PageWrapper";
import Header from "containers/Common/HeaderContainer";
import Onboarding from "containers/Widgets/Onboarding";
import { selectListDatesWithTranslations } from "mixins/selectRanges";
import ProgressPanel from "components/Common/Progress/Panel";
import { ROLE_ADMIN } from "configs/user/rolesConfig";
import { PLANS_NAMES } from "configs/plans";
import { isEmpty } from "lodash";
import { useComponentWillMount } from "hooks/useComponentWillMount";

const Dashboard = ({
  t,
  dateRange = {},
  currentUser,
  candidates = {},
  candidates: { received_percentage: receivedPersent = 0 } = {},
  fetchAllJobsStats,
  handleOnboarding,
  setDashboardDateRange,
  clearError,
  error,
  notifications: { is_tutorial_enabled: showOnboarding },
  setShowTutorialMute,
  completeOnboarding,
  onboarding,
  hasCurrentUserLoaded
}) => {
  useComponentWillMount(() => {
    clearError();
  });

  useEffect(() => {
    let isMounted = true;

    if (currentUser?.key && isMounted && !hasCurrentUserLoaded) {
      isMounted = false;
      handleOnboarding(currentUser);
    }

    return () => {
      isMounted = true;
    };
  }, [currentUser, hasCurrentUserLoaded]);

  useEffect(() => {
    let isMounted = true;

    if (!isEmpty(currentUser?.key) && isMounted) {
      isMounted = false;

      const currentDateRange =
        dateRange.value || selectListDatesWithTranslations(t)[4].value;

      fetchAllJobsStats({ currentUserKey: currentUser.key, currentDateRange });
    }

    return () => {
      isMounted = true;
    };
  }, [dateRange, currentUser.key]);

  useEffect(() => {
    if (showOnboarding && completeOnboarding) {
      setShowTutorialMute();
    }
  }, [completeOnboarding, showOnboarding]);

  const currentDateRange = dateRange.value
    ? dateRange
    : selectListDatesWithTranslations(t)[4];
  const { popIn, popOut } = animations;
  const isAdmin = currentUser.role === ROLE_ADMIN;

  return (
    <PageWrapper header={<Header />} prefix="dashboard">
      <Container>
        <Row>
          <Col md={12}>
            {showOnboarding ? (
              <Onboarding animation={showOnboarding ? popIn : popOut} />
            ) : (
              <div className="dashboard__header">
                <h1 className="dashboard__header-title n-grey-100">
                  {t("dashboard.header")}
                </h1>
                <p className="dashboard__header-caption n-font-medium n-font-regular n-grey-100">
                  {t("dashboard.subHeader")}
                </p>
              </div>
            )}
          </Col>
        </Row>

        <Row style={{ filter: `blur(${showOnboarding ? "1px" : "0px"})` }}>
          <Col md={12}>
            {error && <Message message={error} error />}
            <GraphsCandidates
              companyCandidates={candidates}
              currentDateRange={currentDateRange}
              setCurrentDateRange={setDashboardDateRange}
            />
          </Col>

          <Col md={3}>
            <div className="dashboard__card">
              <div className="dashboard__card-text">
                <h3 className="dashboard__card-title n-grey-100">
                  {isAdmin
                    ? t("dashboard.agencyActiveJobs")
                    : t("dashboard.youActiveJobs")}
                </h3>
                <Link
                  className="n-font-small n-font-regular n-grey-100"
                  to="/jobs"
                >
                  <h1 className="dashboard__card-number n-grey-100">
                    {(isAdmin
                      ? onboarding.agency_jobs_amount
                      : onboarding.my_jobs_amount) || 0}
                  </h1>
                </Link>
              </div>
              <div className="dashboard__card-links">
                <Link
                  className="n-font-small n-font-regular n-grey-100"
                  to="/jobs"
                >
                  {t("link.activeJobs")}
                </Link>
                <Link
                  className="n-font-small n-font-regular n-grey-100"
                  to="/jobs"
                >
                  {t("link.listActiveJobs")}
                </Link>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div className="dashboard__card">
              <div className="dashboard__card-text">
                <h3 className="dashboard__card-title n-grey-100">
                  {t("dashboard.response")}
                </h3>
                <h1 className="dashboard__card-number n-grey-100">
                  {`${(receivedPersent * 100).toFixed(2)}%`}
                </h1>
              </div>
            </div>
          </Col>

          <Col md={3}>
            <div className="dashboard__card">
              <div className="dashboard__card-text">
                <h3 className="dashboard__card-title n-grey-100">
                  {t("dashboard.plan")}
                </h3>
                <h1 className="dashboard__card-number n-grey-100">
                  {/* get user plan */}
                  {currentUser.plan_name}
                </h1>
              </div>

              <ProgressPanel>
                <ProgressPanel.Title postfix="dashboard">
                  <h2>{t("widgets.progressReceived.responses")}</h2>
                </ProgressPanel.Title>

                <ProgressPanel.LeftLabel
                  label={currentUser.responses_quota_used || 0}
                >
                  <ProgressPanel.ProgressBarGraph
                    currentValue={
                      currentUser.responses_quota_used || 0
                    }
                    totalValue={currentUser.responses_quota || 0}
                  />
                </ProgressPanel.LeftLabel>

                {currentUser.is_owner &&
                  currentUser.plan_name === PLANS_NAMES.freeForever && (
                  <Link className="link__right " to="/plans?utm_source=internal&utm_medium=user&utm_campaign=header_upgrade">
                    {t("link.upgrade")}
                  </Link>
                )}
              </ProgressPanel>
            </div>
          </Col>

          <Col md={3}>
            <div className="dashboard__card">
              <div className="dashboard__card-text">
                <h3 className="dashboard__card-title n-grey-100">
                  {t("dashboard.question")}
                </h3>
              </div>
              <button
                // eslint-disable-next-line
                onClick={() => Beacon("open")}
                type="button"
                className="
                  button
                  button__support
                  button__purple
                  button__purple_shadow"
              >
                {t("button.contact")}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

Dashboard.defaultProps = {
  dateRange: {},
  candidates: {},
  currentUser: {},
  currentDateRange: undefined,
  error: undefined,
  notifications: undefined,
  completeOnboarding: undefined,
  onboarding: undefined
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
  dateRange: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  currentUser: PropTypes.shape({
    key: PropTypes.string,
    responses_quota: PropTypes.number,
    responses_quota_used: PropTypes.number,
    stripe_service_portal_url: PropTypes.string,
    role: PropTypes.string,
    is_owner: PropTypes.bool,
    plan_name: PropTypes.string
  }),
  onboarding: PropTypes.shape({
    agency_jobs_amount: PropTypes.number,
    my_jobs_amount: PropTypes.number
  }),
  candidates: PropTypes.shape({
    invited_total: PropTypes.number,
    received_total: PropTypes.number,
    received_percentage: PropTypes.string,
    chart_data: PropTypes.arrayOf(
      PropTypes.shape({
        invited: PropTypes.number,
        received: PropTypes.number,
        name: PropTypes.string,
        day: PropTypes.string
      })
    )
  }),
  currentDateRange: PropTypes.shape({}),
  error: PropTypes.string,
  notifications: PropTypes.shape({
    is_tutorial_enabled: PropTypes.bool
  }),
  completeOnboarding: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  fetchAllJobsStats: PropTypes.func.isRequired,
  handleOnboarding: PropTypes.func.isRequired,
  setDashboardDateRange: PropTypes.func.isRequired,
  clearError: PropTypes.func.isRequired,
  setShowTutorialMute: PropTypes.func.isRequired,
  hasCurrentUserLoaded: PropTypes.bool.isRequired
};

export default withTranslation()(Dashboard);
