import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getCurrentUser } from "store/modules/users/selectors";
import { getNotifications } from "store/modules/notifications/selectors";

import TrialCompleted from "views/Billing/TrialCompleted";

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  notifications: getNotifications(state)
});

export default withRouter(connect(mapStateToProps)(TrialCompleted));
