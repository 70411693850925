/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import ReactTextareaAutosize from "react-textarea-autosize";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { CHECKBOXES_TYPE } from "configs/jobs/constants";
import TooltipInfo from "components/Common/Tooltip/Info";
import { IoCloseOutline } from "react-icons/io5";
import { MdWarningAmber, MdDragIndicator } from "react-icons/md";

const MultipleOptionItem = ({
  t,
  item,
  optionOrder,
  questionOrder,
  answerType,
  handleAddOption,
  isEditable,
  isDraggable = false
}) => {
  const textareaRef = useRef(null);
  const { control, setValue, watch } = useFormContext();
  const options = watch(`questions[${questionOrder - 1}].type_specific_data.answer_options`);
  const currentOption = watch(`questions[${questionOrder - 1}].type_specific_data.answer_options[${optionOrder}]`) ?? {};
  const [isLoading, setIsLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const handleDuplicates = (isFocused = true) => {
    const newOptions = options?.map(current => {
      const isDupe = options?.some(opt => opt.text === current.text
        && opt.key !== current.key && current.text?.length > 0);

      const isCurrentTextEmpty = current.text === null || current.text?.length === 0;

      return {
        ...current,
        has_duplicate: isDupe,
        has_error: (current?.has_error && isCurrentTextEmpty) ||
          (isCurrentTextEmpty && !isFocused),
        autofocus: !isFocused ? false : current.autofocus,
        is_touched: !isFocused && !current.is_touched
      };
    });

    setValue(`questions[${questionOrder - 1}].type_specific_data.answer_options`, newOptions);
  };

  const handleSetCorrectAnswer = () => {
    if (!currentOption?.text?.length && !item.is_correct) setValue(`questions[${questionOrder - 1}].type_specific_data.answer_options[${optionOrder}].has_error`, true);
    setValue(`questions[${questionOrder - 1}].type_specific_data.answer_options[${optionOrder}].is_correct`, !item.is_correct);
  };

  return (
    <div
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div
        className="multiple-options__wrapper"
        id={item.key}
      >
        <div className="multiple-options__icons">
          <MdDragIndicator style={{ visibility: isHovering && isDraggable ? "visible" : "hidden", cursor: "move" }} />

          {
            answerType === CHECKBOXES_TYPE ? (
              <TooltipInfo
                trigger={["hover"]}
                message={currentOption?.is_correct
                  ? t("job.questions.multiple_options.remove_correct_answer")
                  : t("job.questions.multiple_options.set_correct_answer")}
                position="bottom"
              >
                <Form.Check
                  tabIndex="0"
                  type="checkbox"
                  checked={item.is_correct === true}
                  onClick={handleSetCorrectAnswer}
                  onKeyDown={e => {
                    if (["Enter"].includes(e.key)) handleSetCorrectAnswer();
                  }}
                />
              </TooltipInfo>
            ) : (
              <TooltipInfo
                trigger={["hover"]}
                message={currentOption?.is_correct
                  ? t("job.questions.multiple_options.remove_correct_answer")
                  : t("job.questions.multiple_options.set_correct_answer")}
                position="bottom"
              >
                <input
                  tabIndex="0"
                  type="radio"
                  checked={item.is_correct === true}
                  onClick={handleSetCorrectAnswer}
                  onKeyDown={e => {
                    if (["Enter"].includes(e.key)) handleSetCorrectAnswer();
                  }}
                />
              </TooltipInfo>
            )
          }
        </div>
        <Controller
          name={`questions[${questionOrder - 1}].type_specific_data.answer_options[${optionOrder}].text`}
          control={control}
          render={({
          field: { onChange, value }
        }) => (
          <ReactTextareaAutosize
            tabIndex="0"
            autoFocus={item.autofocus}
            ref={textareaRef}
            value={value || ""}
            onChange={evt => {
              const newValue = evt.target.value;
              if (newValue?.length > 0 && currentOption?.has_error) setValue(`questions[${questionOrder - 1}].type_specific_data.answer_options[${optionOrder}].has_error`, false);
              if (newValue?.length <= 0 && currentOption?.is_correct) setValue(`questions[${questionOrder - 1}].type_specific_data.answer_options[${optionOrder}].has_error`, true);

              onChange(newValue);
            }}
            type="text"
            className={`input-autoresize ${currentOption?.has_error ? "input-error" : ""}`}
            autoComplete="off"
            maxLength={256}
            placeholder={`Option ${optionOrder + 1}`}
            onKeyDown={e => {
              if (["Enter"].includes(e.key)) {
                e.preventDefault();
                e.stopPropagation();
              }

              if (["Enter"].includes(e.key) && typeof handleAddOption === "function" && !isLoading) {
                setIsLoading(true);
                setTimeout(() => {
                  handleAddOption();
                  setIsLoading(false);
                }, 200);
              }
            }}
            onFocus={() => handleDuplicates(true)}
            onBlur={() => handleDuplicates(false)}
          />
          )}
        />

        { currentOption?.has_duplicate ? (
          <TooltipInfo
            element={null}
            position="bottom"
            message={t("job.questions.multiple_options.duplicate_tooltip")}
          >
            <MdWarningAmber color="#F7C137" />
          </TooltipInfo>
      ) : null}

        {
        options?.length > 1 && (isEditable || item.key.includes("new__")) ? (
          <TooltipInfo
            element={null}
            position="bottom"
            message={t("job.questions.multiple_options.delete_option")}
          >
            <button
              type="button"
              className="question__button-delete button__without-styles"
              onClick={() => {
                const newOptions = options?.filter(opt => opt.key !== item.key);
                setValue(`questions[${questionOrder - 1}].type_specific_data.answer_options`, newOptions);
              }}
              tabIndex="0"
            >
              <IoCloseOutline />
            </button>
          </TooltipInfo>
        ) : null
      }
      </div>

      {currentOption?.has_error ? <span className="error-message">{t("job.questions.multiple_options.text_required")}</span> : null}
    </div>
  );
};

MultipleOptionItem.defaultProps = {
  item: undefined,
  handleAddOption: undefined,
  isEditable: false
};

MultipleOptionItem.propTypes = {
  t: PropTypes.func.isRequired,
  item: PropTypes.shape({
    value: PropTypes.string,
    autofocus: PropTypes.bool
  }),
  handleAddOption: PropTypes.func,
  isEditable: PropTypes.bool
};

export default withTranslation()(MultipleOptionItem);
