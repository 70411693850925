import { AUDIO_TYPE, INITIAL_TYPE } from "configs/jobs/constants";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { store } from "store";
import { getQuestionsForCandidate } from "store/modules/сandidates/selectors";

const isAudioContextSupported = () => "AudioContext" in window || "webkitAudioContext" in window;

const useDetectMute = (isRecording, questionId, audioDeviceId) => {
  const [hasAudio, setHasAudio] = useState(true);
  const [displayAudioAnimation, setDisplayAudioAnimation] = useState(false);
  const intervalId = useRef();
  const [hasAudioArray, setHasAudioArray] = useState([]);
  const [audioContextSupported, setaudioContextSupported] = useState(true);
  const audioContextRef = useRef();
  const [lastAudioDetected, setLastAudioDetected] = useState(moment());

  const questionsForCandidate = getQuestionsForCandidate(store.getState());

  const currentQuestion = useMemo(
    () => questionsForCandidate.find(item => item.key === questionId),
    [questionId, questionsForCandidate]
  );

  const closeAudioContext = async () => {
    if (!audioContextRef.current) return;

    try {
      // First check if the context exists and its current state
      const contextState = audioContextRef.current.state;

      // Only attempt to close if it's not already closed
      if (contextState !== "closed") {
        await audioContextRef.current.close();
      }
    } catch (error) {
      console.warn("Error while closing AudioContext:", error.message);
    } finally {
      // Always clean up the reference
      audioContextRef.current = null;
    }
  };

  const handleChangeDevice = useCallback(async () => {
    const isRetrievingMediaDevicesAllowed = typeof navigator?.mediaDevices?.ondevicechange === "object" ||
    typeof navigator?.mediaDevices?.ondevicechange === "function";

    if (([INITIAL_TYPE, AUDIO_TYPE].includes(currentQuestion?.answer_type) ||
      !currentQuestion?.answer_type) && isRetrievingMediaDevicesAllowed) {
      try {
        // Clean up existing audio context before creating a new one
        await closeAudioContext();

        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: audioDeviceId }
        });

        // Create new AudioContext only after ensuring old one is cleaned up
        const AudioContextClass = window.AudioContext || window.webkitAudioContext;
        audioContextRef.current = new AudioContextClass();

        if (!isAudioContextSupported() || !audioContextRef.current) {
          setaudioContextSupported(false);
          return;
        }

        setaudioContextSupported(true);
        const analyzer = audioContextRef.current.createAnalyser();
        analyzer.fftSize = 512;
        analyzer.smoothingTimeConstant = 0.1;
        const sourceNode = audioContextRef.current.createMediaStreamSource(stream);
        sourceNode.connect(analyzer);

        if (intervalId.current) {
          clearInterval(intervalId.current);
        }

        intervalId.current = setInterval(() => {
          if (!audioContextRef.current || audioContextRef.current.state === "closed") {
            clearInterval(intervalId.current);
            return;
          }

          const fftBins = new Float32Array(analyzer.frequencyBinCount);
          analyzer.getFloatFrequencyData(fftBins);
          const audioPeakDB = Math.max(...fftBins);

          const frequencyRangeData = new Uint8Array(analyzer.frequencyBinCount);
          analyzer.getByteFrequencyData(frequencyRangeData);
          const sum = frequencyRangeData.reduce((p, c) => p + c, 0);
          const audioMeter = Math.sqrt(sum / frequencyRangeData.length);

          if (isRecording) {
            setHasAudioArray(prev => ([...prev, audioMeter > 0]));
          } else {
            setHasAudioArray([]);
          }

          if (audioMeter === 0 && (String(audioPeakDB).includes("Infinity") || Number(audioPeakDB) < -100)
          ) {
            setHasAudio(false);
          } else {
            setHasAudio(true);
            setLastAudioDetected(moment());
          }

          setDisplayAudioAnimation(audioPeakDB > -50);
        }, 100);
      } catch (error) {
        console.error("Error setting up audio context:", error);
        setaudioContextSupported(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioDeviceId, currentQuestion?.answer_type, isRecording]);

  useEffect(() => {
    if (process.env.REACT_APP_DETECT_MUTE === "false" || isMobile) return;

    if (typeof navigator?.mediaDevices?.ondevicechange === "object" && !isMobile) {
      navigator.mediaDevices.ondevicechange = handleChangeDevice;
    }

    handleChangeDevice();

    // eslint-disable-next-line consistent-return
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      closeAudioContext();
    };
  }, [isRecording, currentQuestion, audioDeviceId, handleChangeDevice]);

  useEffect(() => {
    if (isMobile) {
      setaudioContextSupported(false);
    }
  }, []);

  if (process.env.REACT_APP_DETECT_MUTE === "false") {
    return { hasAudio: true };
  }

  const showNoAudio = moment(moment()).diff(lastAudioDetected, "seconds") > 5;

  return {
    hasAudio: hasAudio === true ? hasAudio : !showNoAudio,
    displayAudioAnimation: audioContextSupported ? displayAudioAnimation : false,
    fullVideoHasAudio: audioContextSupported
      ? (hasAudioArray.filter(a => a).length / hasAudioArray.length) > 0.1 : true,
    audioContextSupported,
    delayedShowAudioAnimation: showNoAudio
  };
};

export default useDetectMute;
