import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getDashboardOnboarding } from "store/modules/dashboard/selectors";
import Onboarding from "components/Onboarding";

const mapStateToProps = state => {
  const onboarding = getDashboardOnboarding(state);

  return ({
    jobsFirstId: onboarding.first_job_key,
    agencyJobsAmount: onboarding.agency_jobs_amount,
    invitedAmount: onboarding.total_received_candidates,
    companiesAmount: onboarding.companies_amount
  });
};

const mapDispatchToProps = null;

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Onboarding)
);
