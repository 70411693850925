/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import PlayerDesktop from "components/Video/Recording/Desktop/Player";
import Buttons from "components/Video/Recording/Buttons";
import QuestionsText from "components/Common/QuestionsText";
import QuestionTitle from "components/Common/QuestionTitle";
import Timer from "utils/timer";
import { INITIAL_DURATION, INITIAL_RETAKES } from "configs/jobs/constants";

import { retry } from "mixins/helpers";
import { store } from "store";
import { getCandidateJobInfo } from "store/modules/сandidates/selectors";
import { isIOS, isMobile } from "react-device-detect";
import { isEmpty, isNull } from "lodash";
import * as Sentry from "@sentry/browser";
import WithHooks from "./withHooks";
import ButtonsCaption from "../ButtonsCaption";
import AudioVideoDevice from "./Player/AudioVideoDevice";

const VideoRecording = ({
  t,
  isRecording,
  isCompleted,
  isShowDelayMessage,
  isStartTimer,
  isTestMode,
  question: {
    key,
    max_duration: maxDuration = INITIAL_DURATION,
    max_retakes: maxRetakes = INITIAL_RETAKES,
    attempt_number: attemptNumber = 0,
    html_text: htmlText,
    thinking_time: thinkingTime
  } = {},
  spentTime,
  blobUrl,
  btnsDisabled,
  cashedVideo,
  memorizedCompanyInfo,
  videoPlayer,
  startRecord,
  stopRecord,
  finishRecord,
  cashedElapsingTime,
  setCashedElapsingTime,
  currentQuestionNumber,
  navigateToNextStep,
  savedAnswers,
  retakeAnswerRecord,
  onSwitchCamera,
  videoDevices,
  videoDeviceId,
  isMediaBlocked,
  setIsMediaBlocked,
  isSuccessRecord,
  isVideoPreview,
  videoSourceRef,
  onlyAudio,
  streamRef,
  streamId,
  showErrorModal,
  showLoadingBtn,
  hasAudio,
  displayAudioAnimation,
  audioContextSupported,
  updateFirebaseData,
  hasUploadedMedia,
  audioDevices,
  audioDeviceId,
  clearTracks,
  userId
}) => {
  const jobInfo = getCandidateJobInfo(store.getState());
  const [shouldShowMissingMedia, setShouldShowMissingMedia] = useState(false);
  const [isEBMLCalled, setIsEBMLCalled] = useState(false);
  const [hasEBML, setHasEBML] = useState(true);

  const handleVisibilityChange = useCallback(() => {
    const isTabChangeAllowed = isIOS ? false : jobInfo?.is_candidate_tabs_change_allowed === false;

    if (((document.hidden && isRecording && !isCompleted && !btnsDisabled.btnStop) ||
    (document.hidden && isRecording && cashedElapsingTime === 0))
    && isTabChangeAllowed) {
      retry(stopRecord, showErrorModal);
    }
  }, [isRecording, isCompleted, btnsDisabled.btnStop, jobInfo?.is_candidate_tabs_change_allowed]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  const isVideoExist = isRecording || cashedVideo;
  const isVideoEmpty = !isVideoExist;

  const isLoadingVisible =
    isVideoEmpty && !isVideoPreview && !isMediaBlocked && !isShowDelayMessage;

  const currentSavedAnswer = useMemo(() =>
    savedAnswers?.find(({ question }) => question?.key === key), [savedAnswers]);

  const attempts = useMemo(() => {
    if (maxRetakes === 0 || maxRetakes === attemptNumber) return 1;

    if (maxRetakes === null) return null;

    return (maxRetakes - attemptNumber) + 1;
  }, [maxRetakes, attemptNumber]);

  const shouldShowNoRetakes = (maxRetakes === 0 || attempts < 1) && maxRetakes !== null;
  const noAvailableRetakes = attemptNumber > 0 && shouldShowNoRetakes && !isRecording
    && !isIOS && !isTestMode;

  const isMediaUploadInterrupted = noAvailableRetakes && !isMobile &&
    (isNull(currentSavedAnswer?.remote_link) || isEmpty(currentSavedAnswer?.remote_link));

  const isShowControls = !isRecording && cashedVideo;

  useEffect(() => {
    if (typeof EBML === "undefined") {
      const script = document.createElement("script");
      script.src = "https://www.webrtc-experiment.com/EBML.js";
      script.async = true;

      script.onload = () => {
        console.log("EBML is successfully loaded and ready to use!", typeof EBML !== "undefined");
        setHasEBML(typeof EBML !== "undefined");
      };

      script.onerror = () => {
        setHasEBML(false);
        setIsEBMLCalled(true);

        Sentry.captureException("Failed to load EBML.js");
      };

      document.body.appendChild(script);
    } else {
      setIsEBMLCalled(true);
      setHasEBML(true);
    }
  }, []);

  return (
    <>
      {isStartTimer && (
        <Timer
          stopRecord={stopRecord}
          setCashedElapsingTime={setCashedElapsingTime}
        />
      )}

      <Col
        md={{ span: 6, order: 2 }}
        xs={{ span: 12, order: 2 }}
        className="zero-padding"
      >
        <QuestionTitle
          questionNumber={currentQuestionNumber}
          thinkingTime={thinkingTime}
          spentTime={spentTime}
        />

        <QuestionsText text={isTestMode ? t("candidate.common.howAreYou") : htmlText} />

        <div className="question-create__mobile-hide">
          <Buttons
            remoteLink={currentSavedAnswer}
            navigateToNextStep={navigateToNextStep}
            isRecording={isRecording}
            isCompleted={isCompleted}
            blobUrl={blobUrl}
            btnsDisabled={btnsDisabled}
            startRecord={startRecord}
            stopRecord={stopRecord}
            finishRecord={finishRecord}
            memorizedCompanyInfo={memorizedCompanyInfo}
            initialElapsingTime={maxDuration * 1000}
            max_retakes={maxRetakes}
            cashedElapsingTime={cashedElapsingTime}
            attempt_number={attemptNumber}
            isBlobEmpty={!cashedVideo?.blob || cashedVideo?.blob?.size === 0}
            retakeAnswerRecord={retakeAnswerRecord}
            isShowDelayMessage={isShowDelayMessage}
            isMediaBlocked={isMediaBlocked}
            setIsMediaBlocked={setIsMediaBlocked}
            isSuccessRecord={isSuccessRecord}
            onlyAudio={onlyAudio}
            isLoadingVisible={isLoadingVisible}
            showLoadingBtn={showLoadingBtn}
            isTestMode={isTestMode}
            noAvailableRetakes={noAvailableRetakes}
            shouldShowMissingMedia={shouldShowMissingMedia}
            setShouldShowMissingMedia={setShouldShowMissingMedia}
            isMediaUploadInterrupted={isMediaUploadInterrupted}
            hasEBML={hasEBML}
            hasUploadedMedia={hasUploadedMedia}
          />
          <ButtonsCaption
            initialElapsingTime={maxDuration * 1000}
            max_retakes={maxRetakes}
            cashedElapsingTime={cashedElapsingTime}
            attempt_number={attemptNumber}
          />
        </div>
      </Col>

      {
        (isMobile || window.innerWidth < 768) ? (
          <Col
            xs={{ span: 12, order: 2 }}
            className={`zero-padding question-create__desktop-hide ${isRecording &&
              !isCompleted &&
              "question-create__sticky"}`}
          >
            <Buttons
              remoteLink={currentSavedAnswer}
              navigateToNextStep={navigateToNextStep}
              isRecording={isRecording}
              isCompleted={isCompleted}
              blobUrl={blobUrl}
              btnsDisabled={btnsDisabled}
              startRecord={startRecord}
              stopRecord={stopRecord}
              finishRecord={finishRecord}
              memorizedCompanyInfo={memorizedCompanyInfo}
              initialElapsingTime={maxDuration * 1000}
              max_retakes={maxRetakes}
              cashedElapsingTime={cashedElapsingTime}
              attempt_number={attemptNumber}
              isBlobEmpty={!cashedVideo?.blob || cashedVideo?.blob?.size === 0}
              retakeAnswerRecord={retakeAnswerRecord}
              isShowDelayMessage={isShowDelayMessage}
              isMediaBlocked={isMediaBlocked}
              setIsMediaBlocked={setIsMediaBlocked}
              isSuccessRecord={isSuccessRecord}
              onlyAudio={onlyAudio}
              showErrorModal={showErrorModal}
              isLoadingVisible={isLoadingVisible}
              showLoadingBtn={showLoadingBtn}
              isTestMode={isTestMode}
              hasUploadedMedia={hasUploadedMedia}
              noAvailableRetakes={noAvailableRetakes}
              shouldShowMissingMedia={shouldShowMissingMedia}
              setShouldShowMissingMedia={setShouldShowMissingMedia}
              isMediaUploadInterrupted={isMediaUploadInterrupted}
              hasEBML={hasEBML}
            />
          </Col>
        ) : null
      }

      <Col xs={{ span: 12, order: 2 }} className="question-create__desktop-hide">
        <ButtonsCaption
          initialElapsingTime={maxDuration * 1000}
          max_retakes={maxRetakes}
          cashedElapsingTime={cashedElapsingTime}
          attempt_number={attemptNumber}
        />
      </Col>

      <Col md={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
        {
          shouldShowMissingMedia || isMediaUploadInterrupted || (isEBMLCalled && !hasEBML) ? (
            <div
              className="player"
              style={{
                ...isMobile ? {
                  paddingTop: onlyAudio ? 0 : "56.25!important",
                  aspectRatio: 16 / 9
                } : {}
              }}
            >
              <div
                className="processing-wrapper"
                style={{
                  height: "100%",
                  width: "100%",
                  position: "absolute",
                  background: "black",
                  opacity: 1,
                  zIndex: 5,
                  top: 0,
                  color: "white",
                  padding: 10
                }}
              >
                <span className="processing-wrapper__text">
                  {
                    !hasEBML ? (
                      <>
                        {t("errors.ebmlError.0")}
                        <a href="https://support.willo.video/article/52-network-error" target="_blank" rel="noopener noreferrer">{t("errors.ebmlError.1")}</a>
                        {t("errors.ebmlError.2")}
                        <a href="https://support.willo.video/article/116-transfering-to-another-device" target="_blank" rel="noopener noreferrer">{t("errors.ebmlError.3")}</a>
                      </>
                    ) : (
                      <>
                        {t("errors.interruptedVideoUpload.0")}
                        <br />
                        {t("errors.interruptedVideoUpload.1")}
                      </>
                    )
                  }
                </span>

                <video ref={videoPlayer} style={{ display: "none" }} />
              </div>
            </div>
          ) : (
            <PlayerDesktop
              clearTracks={clearTracks}
              savedAnswer={currentSavedAnswer}
              blobUrl={blobUrl}
              isRecording={isRecording}
              cashedVideo={cashedVideo}
              cashedElapsingTime={cashedElapsingTime}
              stopRecord={stopRecord}
              videoPlayerRef={videoPlayer}
              videoDevices={videoDevices}
              audioDevices={audioDevices}
              audioDeviceId={audioDeviceId}
              videoDeviceId={videoDeviceId}
              onSwitchCamera={onSwitchCamera}
              isShowDelayMessage={isShowDelayMessage}
              isVideoPreview={isVideoPreview}
              videoSourceRef={videoSourceRef}
              isMediaBlocked={isMediaBlocked}
              onlyAudio={onlyAudio}
              streamRef={streamRef}
              streamId={streamId}
              showLoadingBtn={showLoadingBtn}
              isLoadingVisible={isLoadingVisible}
              hasAudio={hasAudio}
              displayAudioAnimation={displayAudioAnimation}
              isTestMode={isTestMode}
              audioContextSupported={audioContextSupported}
              updateFirebaseData={updateFirebaseData}
              noAvailableRetakes={noAvailableRetakes}
              setShouldShowMissingMedia={setShouldShowMissingMedia}
              shouldShowMissingMedia={shouldShowMissingMedia}
              userId={userId}
            />
          )
        }

        <AudioVideoDevice
          videoDevices={videoDevices}
          audioDevices={audioDevices}
          isRecording={isRecording}
          isShowDelayMessage={isShowDelayMessage}
          isShowControls={isShowControls}
          onlyAudio={onlyAudio}
          isMediaUploadInterrupted={shouldShowMissingMedia || isMediaUploadInterrupted}
          userId={userId}
        />

      </Col>
    </>
  );
};

VideoRecording.defaultProps = {
  isRecording: undefined,
  isCompleted: undefined,
  isStartTimer: undefined,
  isShowDelayMessage: undefined,
  history: undefined,
  cashedVideo: undefined,
  blobUrl: undefined,
  btnsDisabled: {},
  currentQuestionNumber: undefined,
  question: undefined,
  memorizedCompanyInfo: undefined,
  videoPlayer: {},
  cashedElapsingTime: undefined,
  setCashedElapsingTime: undefined,
  startRecord: undefined,
  stopRecord: undefined,
  finishRecord: undefined,
  navigateToNextStep: undefined,
  savedAnswers: undefined,
  retakeAnswerRecord: undefined,
  videoDevices: [],
  videoDeviceId: undefined,
  videoSourceRef: null,
  isTestMode: false,
  streamId: undefined,
  updateFirebaseData: undefined,
  hasUploadedMedia: false,
  onSwitchCamera: undefined
};

VideoRecording.propTypes = {
  t: PropTypes.func.isRequired,
  isRecording: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isStartTimer: PropTypes.bool,
  isShowDelayMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  history: PropTypes.shape({
    push: PropTypes.func,
    length: PropTypes.number
  }),
  cashedVideo: PropTypes.shape({
    blob: PropTypes.any
  }),
  blobUrl: PropTypes.string,
  btnsDisabled: PropTypes.shape({
    btnStart: PropTypes.bool,
    btnStop: PropTypes.bool
  }),
  currentQuestionNumber: PropTypes.number,
  question: PropTypes.shape({
    key: PropTypes.string,
    html_text: PropTypes.string,
    max_duration: PropTypes.number,
    max_retakes: PropTypes.number,
    attempt_number: PropTypes.number
  }),
  memorizedCompanyInfo: PropTypes.shape({}),
  videoPlayer: PropTypes.shape({
    current: PropTypes.shape({})
  }),
  cashedElapsingTime: PropTypes.number,
  setCashedElapsingTime: PropTypes.func,
  startRecord: PropTypes.func,
  stopRecord: PropTypes.func,
  finishRecord: PropTypes.func,
  navigateToNextStep: PropTypes.func,
  savedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      remote_link: PropTypes.string
    })
  ),
  retakeAnswerRecord: PropTypes.func,
  onSwitchCamera: PropTypes.func,
  videoDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ),
  videoDeviceId: PropTypes.string,
  spentTime: PropTypes.number.isRequired,
  isMediaBlocked: PropTypes.bool.isRequired,
  setIsMediaBlocked: PropTypes.func.isRequired,
  isSuccessRecord: PropTypes.bool.isRequired,
  isVideoPreview: PropTypes.bool.isRequired,
  videoSourceRef: PropTypes.shape({}),
  onlyAudio: PropTypes.bool.isRequired,
  isTestMode: PropTypes.bool,
  streamId: PropTypes.string,
  streamRef: PropTypes.shape({}).isRequired,
  showErrorModal: PropTypes.func.isRequired,
  showLoadingBtn: PropTypes.func.isRequired,
  hasAudio: PropTypes.bool.isRequired,
  displayAudioAnimation: PropTypes.bool.isRequired,
  audioContextSupported: PropTypes.bool.isRequired,
  clearTracks: PropTypes.bool.isRequired,
  audioDeviceId: PropTypes.string.isRequired,
  audioDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  updateFirebaseData: PropTypes.func,
  hasUploadedMedia: PropTypes.bool,
  userId: PropTypes.string.isRequired
};

export default WithHooks(withTranslation()(VideoRecording));
