/* eslint-disable operator-linebreak */
import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Labels = ({ t, currentValue, totalValue }) => {
  const current =
    currentValue === 1
      ? `${currentValue} ${t("widgets.accounts.activeAccount")}`
      : `${currentValue} ${t("widgets.accounts.activeAccounts")}`;
  const total =
    totalValue !== Infinity && totalValue !== 0 ? (
      <span className="right">
        {`
          ${totalValue} 
          ${t("widgets.accounts.available")}
        `}
      </span>
    ) : null;

  return (
    <div className="progress__labels">
      <span className="left">{current}</span>
      {total}
    </div>
  );
};

Labels.defaultProps = {
  currentValue: undefined,
  totalValue: undefined
};

Labels.propTypes = {
  t: PropTypes.func.isRequired,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withTranslation()(Labels);
