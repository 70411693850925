import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { ROLE_ADMIN } from "configs/user/rolesConfig";
import Overview from "views/Jobs/Overview";
import { fetchCurrentJob,
  fetchCandidatesList,
  fetchNextCandidatesList,
  fetchJobCandidatesStats,
  restoreJob,
  deleteJob,
  clearStatuses,
  updateCandidatesList,
  downloadCandidatesList,
  setSelectedCandidates,
  setSelectedCandidatesByStatus,
  setSelectedCandidatesLoading,
  fetchJobUsersList,
  fetchNextJobUsersList,
  updateJobUsersList,
  getJobAtsDetails,
  resetJobAtsDetails } from "store/modules/jobs/actions";
import {
  shareCandidatesListPublic,
  clearSharableLink,
  setAvailableAnswerBulk
} from "store/modules/public/actions";
import { setModal } from "store/modules/modals/actions";
import { inviteUsers, addNewUsersError } from "store/modules/users/actions";
import {
  getIsJobLoading,
  getIsCandidateStatsLoading,
  getIsJobStatsLoading,
  getJobCurrent,
  getInterviewers,
  getInterviewersProgress,
  getInterviewersFilter,
  getJobCompany,
  getSelectedCandidates,
  getSelectedCandidatesLoading,
  getJobUsers,
  getJobUsersModal,
  getJobAts,
  getUsersCanSeeCount
} from "store/modules/jobs/selectors";
import { getCurrentUser, getUsersLoading, getInviteError } from "store/modules/users/selectors";
import { getProfileInfo } from "store/modules/profile/selectors";
import { getIsPaid, getIsTrial } from "store/modules/notifications/selectors";

const mapStateToProps = state => ({
  isAdmin: getCurrentUser(state).role === ROLE_ADMIN,
  isLoading: getIsJobLoading(state),
  isCandidatesStatsLoading: getIsCandidateStatsLoading(state),
  isNextCandidatesLoading: state?.jobsOverview?.isNextCandidatesLoading,
  isJobStatsLoading: getIsJobStatsLoading(state),
  jobCurrent: getJobCurrent(state),
  profileInfo: getProfileInfo(state),
  interviewers: getInterviewers(state),
  interviewersProgress: getInterviewersProgress(state),
  interviewersFilter: getInterviewersFilter(state),
  currentUser: getCurrentUser(state),
  bulkShowcaseLink: state.publicPage.bulkShowcaseLink,
  bulkKey: state.publicPage.bulkKey,
  jobCompany: getJobCompany(state),
  selectedCandidates: getSelectedCandidates(state),
  isSelectedCandidatesLoading: getSelectedCandidatesLoading(state),
  isAbiltyСreate: getIsPaid(state) && !getIsTrial(state),
  jobUsers: getJobUsers(state),
  usersCanSeeCount: getUsersCanSeeCount(state),
  jobUsersModal: getJobUsersModal(state),
  isUsersLoading: state.jobsCandidate.isJobUsersLoading,
  isNextUsersLoading: state.jobsCandidate.isNextJobUsersLoading,
  inviteLoading: getUsersLoading(state),
  inviteError: getInviteError(state),
  agencyKey: getCurrentUser(state)?.key,
  atsDetails: getJobAts(state)
});

const mapDispatchToProps = {
  fetchCurrentJob,
  fetchCandidatesList,
  fetchNextCandidatesList,
  updateCandidatesList,
  downloadCandidatesList,
  shareCandidatesListPublic,
  fetchJobCandidatesStats,
  setModal,
  restoreJob,
  deleteJob,
  clearStatuses,
  clearSharableLink,
  setAvailableAnswerBulk,
  setSelectedCandidates,
  setSelectedCandidatesByStatus,
  setSelectedCandidatesLoading,
  fetchJobUsersList,
  fetchNextJobUsersList,
  updateJobUsersList,
  inviteUsers,
  addNewUsersError,
  getJobAtsDetails,
  resetJobAtsDetails
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Overview)
);
