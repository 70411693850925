/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect, useRef } from "react";
import { getCurrentStep, isQuestionCompleted, isQuestionCurrentActive, sortQuestions } from "utils/stepper.ts";
import classNames from "classnames";
import useStepperNavigation from "hooks/useStepperNavigation.tsx";
import useWindowResize from "hooks/useWindowResize.tsx";
import { CandidateAnswersType, Question } from "types/candidateAnswer.ts";
import StepperMobile from "./StepperMobile.tsx";
import StepperDesktop from "./StepperDesktop.tsx";
import BottomSheet from "./BottomSheet.tsx";
import "./styles.scss";

type LineProgressProps = {
  userId: string;
  jobId: string;
  questions: Question[];
  savedAnswers: CandidateAnswersType;
  currentStepActive: string;
  previousQuestionId: string;
  isRecording: boolean;
  isAllAnsweredSaved: boolean;
  isShowDelayMessage: boolean;
  history: {
    push: (path: { pathname: string }) => void;
  };
  currentAction: string;
  savePreviousQuestion: (params: { userId: string; questionId: string }) => void;
  setModal: (params: {
    type: string;
    videoConfirmation: { goToNextQuestion: (questionId: string) => void; questionId: string }
  } | null) => void;
  saveCurrentQuestion: (params: { userId: string; questionCurrentCashed: null }) => void;
  setCashedElapsingTime: (time: null) => void;
  updateFirebaseData?: () => void;
  isIdvQuestion: boolean;
};

const LineProgress = ({
  userId,
  jobId,
  questions,
  savedAnswers,
  currentStepActive,
  previousQuestionId,
  isRecording,
  isAllAnsweredSaved,
  isShowDelayMessage,
  history,
  currentAction = "create",
  savePreviousQuestion,
  setModal,
  saveCurrentQuestion,
  setCashedElapsingTime,
  updateFirebaseData,
  isIdvQuestion
} : LineProgressProps) => {
  const [hoveredStep, setHoveredStep] = useState<number | null>(null);
  const [selectedStep, setSelectedStep] = useState<string | null>(null);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const sliderRef = useRef<HTMLDivElement>(null);

  const sortedQuestions = sortQuestions(questions);
  const currentStep = getCurrentStep(questions, currentStepActive);
  const totalSteps = questions.length;

  const { isMobileAndTabletScreen } = useWindowResize();

  const { goToNextQuestion } = useStepperNavigation({
    userId,
    jobId,
    questions,
    currentAction,
    saveCurrentQuestion,
    setCashedElapsingTime,
    updateFirebaseData,
    history
  });

  useEffect(() => {
    if (!isMobileAndTabletScreen) return;

    if (isMobileAndTabletScreen) {
      const handleScroll = () => setIsScrolled(window.scrollY > 0);
      window.addEventListener("scroll", handleScroll);

      // eslint-disable-next-line consistent-return
      return () => window.removeEventListener("scroll", handleScroll);
    }
  }, [isMobileAndTabletScreen]);

  const handleStepClick = useCallback((questionId: string, index: number) => {
    const isDisabled =
      isRecording ||
      isAllAnsweredSaved ||
      questionId === currentStepActive ||
      isShowDelayMessage;

    const canProgress = savedAnswers.length < index;

    if (isDisabled || canProgress) return;

    const isCurrentQuestionSaved = savedAnswers.some(
      item => item.question.key === previousQuestionId
    );
    const isTheSameQuestion = previousQuestionId === questionId;

    savePreviousQuestion({ userId, questionId });

    if (!isCurrentQuestionSaved || isTheSameQuestion) {
      setModal({
        type: "lostVideoConfirmation",
        videoConfirmation: { goToNextQuestion, questionId }
      });
    } else {
      goToNextQuestion(questionId);
    }
  }, [
    isRecording,
    isAllAnsweredSaved,
    currentStepActive,
    isShowDelayMessage,
    savedAnswers,
    previousQuestionId,
    userId,
    savePreviousQuestion,
    setModal,
    goToNextQuestion
  ]);

  const renderStep = useCallback((question: Question, index: number) => {
    const isCompleted = isQuestionCompleted(question.key, savedAnswers);
    const isCurrent = question.key === currentStepActive;
    const isNotCurrentActive = isQuestionCurrentActive(question.key, currentStepActive);

    const handleClick = () => {
      if (isCompleted && isNotCurrentActive && isMobileAndTabletScreen) {
        setSelectedStep(question.key);
        setIsBottomSheetOpen(true);
        // eslint-disable-next-line no-unused-expressions
        navigator?.vibrate?.(20);
      } else {
        handleStepClick(question.key, index);
      }
    };

    if (isMobileAndTabletScreen) {
      return (
        <button
          key={question.key}
          type="button"
          onClick={handleClick}
          className={`
            relative flex-1 min-h-[64px] p-2 rounded-lg
            flex flex-col items-center justify-center gap-1
            transition-all duration-300
            ${isCurrent ? "bg-tertiary-green-10 shadow-md scale-105" :
            isCompleted ? "bg-tertiary-green" :
              "bg-gray-100"}
          `}
          disabled={!isCompleted && !isCurrent}
        >
          <span className={`
            text-lg font-medium
            ${isCurrent ? "text-tertiary-green" :
            isCompleted ? "text-white" : "text-gray-400"}
          `}
          >
            {question.order}
          </span>
          <div className={`
            w-2 h-2 rounded-full
            ${isCurrent ? "bg-tertiary-green" :
            isCompleted ? "bg-white" : "bg-gray-300"}
          `}
          />
        </button>
      );
    }

    return (
      <div
        key={question.key}
        className="relative flex-1 min-w-0"
        onMouseEnter={() => (isCompleted || isCurrent) && setHoveredStep(index)}
        onMouseLeave={() => setHoveredStep(null)}
      >
        <button
          type="button"
          onClick={handleClick}

          disabled={!isCompleted && !isCurrent}
          className={classNames(
            "w-full h-2 transition-all absolute",
            {
              "bg-tertiary-green border-b-2 border-tertiary-green": isCompleted,
              "bg-tertiary-green-10 border-b-2 border-tertiary-green": !isCompleted && isCurrent,
              "bg-gray-200 border-b-2 border-gray-200": !isCompleted && !isCurrent,
              "cursor-default": !isCompleted && !isCurrent,
              "cursor-pointer hover:opacity-80": isCompleted || isCurrent,
              "bg-gray-200": (isCompleted || isCurrent) && hoveredStep === index
            }
          )}

          aria-label={`Question ${question.order}`}
        />

        {hoveredStep === index && (isCompleted || isCurrent) && (
          <div
            className="hidden sm:block absolute bg-white border-2 border-tertiary-green rounded px-3 py-2 shadow-sm whitespace-nowrap z-50 w-full"
            style={{ minWidth: 250, top: 5 }}
          >
            <div className="flex flex-col items-start gap-2 w-full">
              <span className="text-gray-500 text-sm">
                {`Question ${question.order} of ${totalSteps}`}
              </span>
              <div className="text-gray-900 font-medium text-sm truncate w-full flex-1">
                {question.text}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }, [
    currentStepActive,
    handleStepClick,
    hoveredStep,
    isMobileAndTabletScreen,
    savedAnswers,
    totalSteps
  ]);

  return (
    <div className={`
      w-full transition-all duration-300 stepper-progress-bar-v2
      ${isMobileAndTabletScreen && isScrolled ? "top-0 left-0 right-0 z-50 shadow-md" : "relative"}
    `}
    >
      <div className={`stepper-progress-bar-v2 w-full mx-auto ${isIdvQuestion ? "" : "lg:pb-4"}`}>
        {
          questions?.length > 1 ? (
            <div className="bg-white top-0">
              <div ref={sliderRef}>
                {isMobileAndTabletScreen ? (
                  <StepperMobile
                    questions={sortedQuestions}
                    renderStep={renderStep}
                    currentStep={currentStep}
                  />
                ) : (
                  <StepperDesktop
                    questions={sortedQuestions}
                    currentStep={currentStep}
                    totalSteps={totalSteps}
                    renderStep={renderStep}
                    handleStepClick={handleStepClick}
                  />
                )}
              </div>
            </div>
          ) : null
        }

        <BottomSheet
          isOpen={isBottomSheetOpen}
          selectedStep={selectedStep}
          onClose={() => setIsBottomSheetOpen(false)}
          questions={questions}
          savedAnswers={savedAnswers}
          currentStepActive={currentStepActive}
          handleStepClick={handleStepClick}
        />
      </div>
    </div>
  );
};

export default LineProgress;
