import React, { useState } from "react";
import PropTypes from "prop-types";

import { AddUserPlus } from "mixins/svgIcons";
import UsersModal from "./UsersModal";
import "./styles.scss";

const getAvatarName = (userName, email) => {
  if (userName) {
    return userName
    // eslint-disable-next-line no-return-assign
      ? userName.split(/\s/).reduce((name, word) => name += word.slice(0, 1), "")
      : "";
  }

  return email.slice(0, 1);
};

const UsersList = ({
  jobCurrent,
  jobUsers,
  jobUsersModal,
  companyName,
  fetchJobUsersList,
  fetchNextJobUsersList,
  updateJobUsersList,
  isUsersLoading,
  isNextUsersLoading,
  hasAbilityEdit,
  currentUserKey,
  inviteUsers,
  inviteLoading,
  inviteError,
  addNewUsersError,
  usersCanSeeCount
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const usersCanSee = jobUsers.filter(
    ({ can_see_interview: canSee }) => canSee
  );

  return (
    <>
      {usersCanSee?.length ? (
        <div className="overview__user-list">
          {usersCanSee.slice(0, 5).map(({ full_name: fullName, email }, index) => (
            <div
              className="overview__user-list-item"
              onClick={handleShow}
              role="presentation"
              key={String(index)}
            >
              <div className="overview__user-list-item-name">
                <p className="n-font-small n-font-medium-weight n-white n-uppercase">
                  {getAvatarName(fullName, email)}
                </p>
              </div>
              {fullName ? (
                <p className="n-font-extra-small n-font-medium-weight n-white n-capitalize name">
                  {fullName}
                </p>
              ) : null}
            </div>
          ))}
          {usersCanSeeCount - 5 > 0 ? (
            <div
              className="overview__user-list-item"
              onClick={handleShow}
              role="presentation"
            >
              <div className="overview__user-list-item-name">
                <p className="n-font-small n-font-medium-weight n-white n-uppercase">
                  {`+${usersCanSeeCount - 5}`}
                </p>
              </div>
            </div>
          ) : null}
          <div
            className="overview__user-list-item"
            onClick={handleShow}
            role="presentation"
          >
            <div className="overview__user-list-item-name add">
              <p className="n-font-small n-font-medium-weight n-white n-uppercase">
                <AddUserPlus />
              </p>
            </div>
          </div>
        </div>
      ) : null}
      <UsersModal
        show={show}
        setShow={setShow}
        usersCanSee={usersCanSee}
        jobCurrent={jobCurrent}
        companyName={companyName}
        jobUsers={jobUsersModal}
        fetchJobUsersList={fetchJobUsersList}
        fetchNextJobUsersList={fetchNextJobUsersList}
        isUsersLoading={isUsersLoading}
        isNextUsersLoading={isNextUsersLoading}
        updateJobUsersList={updateJobUsersList}
        hasAbilityEdit={hasAbilityEdit}
        currentUserKey={currentUserKey}
        inviteUsers={inviteUsers}
        inviteLoading={inviteLoading}
        inviteError={inviteError}
        addNewUsersError={addNewUsersError}
        isLoadingScreen={inviteLoading}
      />
    </>
  );
};

UsersList.defaultProps = {
  isNextUsersLoading: undefined,
  inviteError: undefined,
  companyName: undefined,
  isUsersLoading: undefined
};

UsersList.propTypes = {
  jobCurrent: PropTypes.shape({}).isRequired,
  jobUsers: PropTypes.arrayOf(PropTypes.shape({
    full_name: PropTypes.string
  })).isRequired,
  jobUsersModal: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  companyName: PropTypes.string,
  fetchJobUsersList: PropTypes.func.isRequired,
  fetchNextJobUsersList: PropTypes.func.isRequired,
  updateJobUsersList: PropTypes.func.isRequired,
  isUsersLoading: PropTypes.bool,
  isNextUsersLoading: PropTypes.bool,
  hasAbilityEdit: PropTypes.bool.isRequired,
  currentUserKey: PropTypes.string.isRequired,
  inviteUsers: PropTypes.func.isRequired,
  inviteLoading: PropTypes.bool.isRequired,
  inviteError: PropTypes.string,
  addNewUsersError: PropTypes.func.isRequired,
  usersCanSeeCount: PropTypes.number.isRequired
};


export default UsersList;
