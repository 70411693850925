import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  addJobInvite,
  sendInvites,
  sendSpreadsheet,
  fetchCurrentJob,
  updateJobInvite,
  clearJobInvites,
  clearErrors,
  clearJobInvitesParseData,
  setJobInvites
} from "store/modules/jobs/actions";
import { setModal } from "store/modules/modals/actions";
import {
  getJobCurrent,
  getJobInvitesData,
  getJobInvitesParseData,
  getJobInvitesIsLoading,
  getJobInvitesSending,
  getJobInvitesSend,
  getJobInvitesError,
  getParseError
} from "store/modules/jobs/selectors";
import Invite from "views/Jobs/Invite";

const mapStateToProps = state => ({
  jobCurrent: getJobCurrent(state),
  jobInvitesData: getJobInvitesData(state),
  jobInvitesParseData: getJobInvitesParseData(state),
  isSendingUploading: getJobInvitesSending(state),
  isJobInvitesSend: getJobInvitesSend(state),
  isLoading: getJobInvitesIsLoading(state),
  error: getJobInvitesError(state),
  parseError: getParseError(state)
});

const mapDispatchToProps = {
  addJobInvite,
  sendInvites,
  sendSpreadsheet,
  fetchCurrentJob,
  updateJobInvite,
  setModal,
  clearJobInvites,
  clearErrors,
  clearJobInvitesParseData,
  setJobInvites
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invite));
