import React from "react";
import PropTypes from "prop-types";

const Title = ({ children }) => (
  <div className="progress__title">{children}</div>
);

Title.defaultProps = {
  children: undefined
};

Title.propTypes = {
  children: PropTypes.element
};

export default Title;
