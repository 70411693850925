import { createAction } from "redux-actions";

export const fetchLeverConfiguration = createAction("FECTH_LEVER_CONFIGURATION");
export const fetchLeverConfigurationResponse = createAction("FECTH_LEVER_CONFIGURATION_RESPONSE");
export const fetchLeverConfigurationError = createAction("FECTH_LEVER_CONFIGURATION_ERROR");

export const loginLever = createAction("LOGIN_LEVER");
export const loginLeverResponse = createAction("LOGIN_LEVER_RESPONSE");
export const loginLeverError = createAction("LOGIN_LEVER_ERROR");

export const updateLeverStages = createAction("UPDATE_LEVER_STAGES");
export const updateLeverStagesResponse = createAction("UPDATE_LEVER_STAGES_RESPONSE");
export const updateLeverStagesError = createAction("UPDATE_LEVER_STAGES_ERROR");

export const isLeverLoading = createAction("IS_LEVER_LOADING");
export const isLeverStagesLoading = createAction("IS_LEVER_STAGES_LOADING");

export const fetchLeverStages = createAction("FECTH_LEVER_STAGES");
export const fetchLeverStagesResponse = createAction("FECTH_LEVER_STAGES_RESPONSE");
export const fetchLeverStagesError = createAction("FECTH_LEVER_STAGES_ERROR");

export const setSavedStages = createAction("SET_SAVED_STAGES");
