import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { updateCandidateInfo } from "store/modules/сandidates/actions";
import { removeDevice } from "store/modules/profile/actions";
import { deleteJob, deleteJobInvite } from "store/modules/jobs/actions";
import { deleteCompany } from "store/modules/companies/actions";
import { deleteUser } from "store/modules/users/actions";
import { deleteLibrary } from "store/modules/libraries/actions";
import { removeModal } from "store/modules/modals/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";
import { getModalInfo } from "store/modules/modals/selectors";
import Modals from "views/Modals";

const mapStateToProps = state => ({
  modalInfo: getModalInfo(state),
  isLoadingScreen: state.widgets.isLoading
});

const mapDispatchToProps = {
  updateCandidateInfo,
  deleteJobInvite,
  deleteJob,
  deleteCompany,
  deleteUser,
  deleteLibrary,
  removeModal,
  setLoadingScreen,
  removeDevice
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Modals));
