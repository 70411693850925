import React, { } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  ANSWER_TYPES,
  CHECKBOXES_TYPE,
  COUNTRIES,
  IDV_INCLUDED_TYPES,
  IDV_LABEL,
  IDV_TYPE,
  IDV_TYPES,
  MULTIPLE_CHOICE_TYPE
} from "configs/jobs/constants";
import countries from "i18n-iso-countries";
import ReactHtmlParser from "react-html-parser";

const InactiveQuestionItem = ({
  order,
  t,
  answer_type: answerType,
  idv_type: idvType,
  idv_country_of_employment_iso_code: idvCountryOfEmployment,
  idv_max_checks: idvMaxChecks,
  max_characters: maxCharacters,
  max_duration: maxDuration,
  max_retakes: maxRetakes = undefined,
  max_words: maxWords,
  html_text,
  thinking_hours: thinkingHours,
  thinking_minutes: thinkingMinutes
}) => {
  const textLimit = maxWords
    ? "job.questions.textLimit.words"
    : "job.questions.textLimit.characters";
  const maxCount = maxWords || maxCharacters;

  const alpha2code =
    countries.alpha3ToAlpha2(idvCountryOfEmployment) ?? idvCountryOfEmployment;
  const countryName = COUNTRIES.find(country => country.iso === alpha2code)
    ?.country;

  const mapAnswerTypeLabel = {
    [IDV_TYPE]: IDV_LABEL,
    [MULTIPLE_CHOICE_TYPE]: t("job.questions.types.multiple_choice"),
    [CHECKBOXES_TYPE]: t("job.questions.types.checkboxes")
  };

  return (
    <div className="inactive-question__wrapper">
      <div className="active-question__title-container">
        <span className="question__order n-font-medium-weight n-font-small">
          {order}
        </span>
        <span
          className="question__title"
          style={{
            minHeight: html_text?.length ? 0 : 35
          }}
        >
          {ReactHtmlParser(html_text)}
        </span>
      </div>

      <div className="active-question__field">
        <div className="label__container">
          <div className="label__container-title">
            {t("job.questions.types.title")}
          </div>
          <div className="label__container-answer">
            <div className="label__container-icon">
              <i
                className={`fa xsm mr4 ml4 grey ${
                  IDV_INCLUDED_TYPES.find(type => type.value === answerType)
                    ?.icon
                }`}
              >
                {IDV_INCLUDED_TYPES.find(type => type.value === answerType)?.reactIcon}
              </i>
            </div>
            <p className="label__container-value">
              {[IDV_TYPE, MULTIPLE_CHOICE_TYPE, CHECKBOXES_TYPE].includes(answerType)
                ? mapAnswerTypeLabel[answerType] : answerType}
            </p>
          </div>
        </div>

        {[ANSWER_TYPES.audio.value, ANSWER_TYPES.video.value].includes(
          answerType
        ) ? (
          <>
            <div className="label__container">
              <div className="label__container-title">
                {t("job.questions.retakes.title")}
              </div>
              <p className="label__container-value">
                {maxRetakes || maxRetakes === 0
                  ? `${maxRetakes}x`
                  : t("job.questions.retakes.unlimited")}
              </p>
            </div>
            <div className="label__container">
              <div className="label__container-title">
                {t("job.questions.minutes.title")}
              </div>
              <p className="label__container-value">
                {t(maxDuration / 60 > 1 ? "time.minutes" : "time.minute", {
                  total: maxDuration / 60
                })}
              </p>
            </div>
          </>
        ) : null}

        {answerType === ANSWER_TYPES.text.value ? (
          <>
            <div className="label__container">
              <div className="label__container-title">
                {t("job.questions.textLimit.title")}
              </div>
              <p className="label__container-value">{t(textLimit)}</p>
            </div>
            <div className="label__container">
              <div className="label__container-title">
                {t("job.questions.textLimit.maxCount")}
              </div>
              <p className="label__container-value">{maxCount}</p>
            </div>
          </>
        ) : null}
        {answerType !== ANSWER_TYPES.idv.value ? (
          <div className="label__container">
            <div className="label__container-title">
              {t("input.thinkingLabel")}
            </div>
            <p className="label__container-value">
              {`${Number(thinkingHours ?? "")}h ${Number(
                thinkingMinutes ?? ""
              )}m`}
            </p>
          </div>
        ) : (
          <>
            <div className="label__container">
              <div className="label__container-title">
                {t("job.questions.idv.checkType.title")}
              </div>
              <p className="label__container-value">
                {t(IDV_TYPES.find(a => a.value === idvType)?.title)}
              </p>
            </div>
            <div className="label__container">
              <div className="label__container-title">
                {t("input.countryOfEmployment")}
              </div>
              <p className="label__container-value">{countryName}</p>
            </div>
            <div className="label__container">
              <div className="label__container-title">
                {t("input.idvMaxChecks")}
              </div>
              <p className="label__container-value">
                {idvMaxChecks || t("input.notSet")}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

InactiveQuestionItem.defaultProps = {
  order: undefined,
  t: undefined,
  answer_type: undefined,
  idv_country_of_employment_iso_code: undefined,
  idv_max_checks: undefined,
  max_characters: undefined,
  max_duration: undefined,
  max_retakes: undefined,
  max_words: undefined,
  html_text: undefined,
  thinking_hours: undefined,
  thinking_minutes: undefined,
  idv_type: undefined
};

InactiveQuestionItem.propTypes = {
  order: PropTypes.number,
  t: PropTypes.func,
  answer_type: PropTypes.string,
  idv_country_of_employment_iso_code: PropTypes.string,
  idv_max_checks: PropTypes.string,
  max_characters: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max_duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max_retakes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max_words: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  html_text: PropTypes.string,
  thinking_hours: PropTypes.number,
  thinking_minutes: PropTypes.number,
  idv_type: PropTypes.string
};

export default withTranslation()(InactiveQuestionItem);
