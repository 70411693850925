/* eslint-disable react/jsx-indent */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";

import { PERIODS } from "configs/plans";

import "./styles.scss";

const PlansSwitcher = ({ t, currentPeriod, setCurrentPeriod,
    isApi, setIsApi }) => {
  const isMonthly = currentPeriod === PERIODS.monthly;
  const isAnnual = currentPeriod === PERIODS.annual;

  return (
    <div className="plans__switcher-container">
      <div className="plans__period">
        <label
          className={`plans__period-item ${!isApi ? "plans__active isMonthly" : "plans__inactive"}`}
          onClick={() => setIsApi(false)}

        >
          <input
            type="radio"
            id="apiFalse"
            name="period"
            checked={!isApi}
          />
          <div>
            <div className="plans__period-item-label pricing">
              {t("plans.standardPricing")}
            </div>
          </div>
        </label>
        <label
          className={`plans__period-item ${isApi ? "plans__active isAnnual" : "plans__inactive"}`}
          onClick={() => setIsApi(true)}
        >
          <input
            type="radio"
            name="period"
            id="apiTrue"
            checked={isApi}
          />
          <div>
            <div className="plans__period-item-label pricing">
              {t("plans.apiPricing")}
            </div>
          </div>
        </label>
      </div>
      {
      !isApi && <div className="plans__period-container">

        <div className="plans__period">
          <label
            htmlFor={PERIODS.monthly}
            className={`plans__period-item ${isMonthly ? "plans__active isMonthly" : "plans__inactive"}`}
            onClick={() => {
              setCurrentPeriod(PERIODS.monthly);
            }}
          >
            <input
              type="radio"
              id={PERIODS.monthly}
              name="period"
              checked={isMonthly}
            />
            <div>
              <div className="plans__period-item-label">
                {t("plans.monthly")}
              </div>
              <p className="plans__period-item-sublabel">
                {t("modals.billing.cancel")}
              </p>
            </div>
          </label>
          <label
            htmlFor={PERIODS.annual}
            onClick={() => setCurrentPeriod(PERIODS.annual)}
            className={`plans__period-item ${isAnnual ? "plans__active isAnnual" : "plans__inactive"}`}
          >
            <input
              type="radio"
              id={PERIODS.annual}
              name="period"
              checked={isAnnual}
            />
            <div>
              <div className="plans__period-item-label">
                {t("plans.annually")}
              </div>
              <p className="plans__period-item-sublabel">
                {t("plans.monthFree")}
                {" "}
                🎉
              </p>
            </div>
          </label>
        </div>
        {/* <div className="plans__period-year">
        {
            YEAR_VALUES.map(year => (
              <div
                className={currentYear === year ? "active" : ""}
                onClick={() => setCurrentYear(year)}
              >
                {year}
                {" "}
                {year === 1 ? t("plans.year") : t("plans.years")}
              </div>
            ))
          }
      </div> */}
                </div>
     }

    </div>
  );
};


PlansSwitcher.propTypes = {
  currentPeriod: PropTypes.string.isRequired,
  // currentYear: PropTypes.number.isRequired,
  isApi: PropTypes.bool.isRequired,
  setCurrentPeriod: PropTypes.func.isRequired,
  // setCurrentYear: PropTypes.func.isRequired,
  setIsApi: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default PlansSwitcher;
