import React from "react";
import PropTypes from "prop-types";

const Header = ({ children }) => (
  <div className="profile-card__header">{children}</div>
);

Header.defaultProps = {
  children: undefined
};

Header.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
};

export default Header;
