import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Message from "components/Common/Message";
import DropdownIndicator from "components/Forms/Select/dropdown";
import Header from "containers/Common/HeaderContainer";
import LibrariesTable from "containers/Tables/LibrariesTable";
import Select from "components/Common/Select";
import PageWrapper from "hoc/PageWrapper";
import { Plus } from "mixins/svgIcons";
import { ROLE_ADMIN } from "configs/user/rolesConfig";

import "./styles.scss";

const optionTypes = [
  {
    label: "email",
    value: "email"
  }, {
    label: "SMS",
    value: "sms"
  }
];

const Jobs = ({
  t,
  success,
  error,
  currentUser: { role }
}) => {
  const isAdmin = role === ROLE_ADMIN;
  const newFilter = { title: "", type: null };
  const [filter, setFilter] = useState(newFilter);

  const clearFilter = () => setFilter(newFilter);

  return (
    <PageWrapper header={<Header />}>
      <Container className="jobs">
        <Row justify="center">
          <Col md={4}>
            <input
              id="name-search"
              className="form__search-input name-search__input n-font-small jobs"
              placeholder={t("input.searchTitlePlaceholder")}
              onChange={e => setFilter({ ...filter, title: e.target.value })}
              value={filter.title}
              type="text"
            />
          </Col>
          <Col md={4}>
            <Row justify="center">
              <Col md={{ span: 8, offset: 4 }}>
                <Select
                  name="type"
                  formElement={{
                    elementType: "select",
                    elementConfig: {
                      type: "text",
                      placeholder: "typeFilterPlaceholder"
                    },
                    value: filter.type,
                    options: optionTypes,
                    valid: false,
                    touched: false
                  }}
                  inputChangedHandler={type => setFilter({ ...filter, type })}
                  className="types__select"
                  components={{
                    DropdownIndicator: props => (
                      <DropdownIndicator
                        {...props}
                        height="13"
                        width="13"
                        fill="#BBC2C9"
                      />
                    )
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={2} className="jobs__col-center">
            <button
              type="button"
              className="jobs__clear-filter n-font-medium n-grey-100"
              onClick={clearFilter}
            >
              <span className="text">{t("input.jobClear")}</span>
            </button>
          </Col>

          {isAdmin && (
            <Col md={2} className="jobs__col-reverse">
              <Link className="jobs__cleate-button n-button__medium n-white button__purple n-button__full-width" to="/libraries/create">
                <Plus svgIconClass="n-icon__medium right n-svg-white" />
                <span className="text">{t("button.createJob")}</span>
              </Link>
            </Col>
          )}

          <Col md={12}>
            <div className="jobs__message">
              {success && <Message success message={t(success)} />}
              {error && <Message error message={t(error)} />}
            </div>
          </Col>
        </Row>
        <Row>
          <LibrariesTable filter={filter} clearFilter={clearFilter} />
        </Row>
      </Container>
    </PageWrapper>
  );
};

Jobs.propTypes = {
  t: PropTypes.func.isRequired,
  success: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    role: PropTypes.string
  }).isRequired
};

export default withTranslation()(Jobs);
