/* eslint-disable operator-linebreak */
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { confirmable } from "react-confirm";
import { Modal } from "react-bootstrap";
import cn from "classnames";
import PropTypes from "prop-types";

import Rejection from "./Rejection";
import Withdrawal from "./Withdrawal";

const Reject = ({ t, show, proceed, dismiss }) => {
  const [currentFrom, setCurrentFrom] = useState("rejection");

  return (
    <Modal dialogClassName="reject-details" show={show} onHide={dismiss}>
      <Modal.Body>
        <div className="reject-details__buttons">
          <div className="button__group_center">
            <button
              type="button"
              name="rejection"
              onClick={() => setCurrentFrom("rejection")}
              className={cn(
                currentFrom === "rejection"
                  ? "n-button__medium n-bg-grey-100 n-white"
                  : "n-button__medium-border n-border-grey-100 n-grey-100"
              )}
            >
              {t("button.rejection")}
            </button>

            <button
              type="button"
              name="withdrawal"
              onClick={() => setCurrentFrom("withdrawal")}
              className={cn(
                "button button__small button__small_round",
                currentFrom === "withdrawal"
                  ? "n-button__medium n-bg-grey-100 n-white"
                  : "n-button__medium-border n-border-grey-100 n-grey-100"
              )}
            >
              {t("button.withdrawal")}
            </button>
          </div>
        </div>
        {currentFrom === "rejection" && <Rejection t={t} proceed={proceed} />}
        {currentFrom === "withdrawal" && <Withdrawal t={t} proceed={proceed} />}
      </Modal.Body>
    </Modal>
  );
};

Reject.defaultProps = {
  show: undefined,
  proceed: undefined,
  dismiss: undefined
};

Reject.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  proceed: PropTypes.func,
  dismiss: PropTypes.func
};

export default confirmable(withTranslation()(Reject));
