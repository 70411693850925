import { all } from "redux-saga/effects";

import authSaga from "./auth";
import jobsSaga from "./jobs";
import commentsSaga from "./comments";
import companiesSaga from "./companies";
import usersSaga from "./users";
import profileSaga from "./profile";
import candidateSaga from "./candidate";
import dashboardSaga from "./dashboard";
import publicSaga from "./public";
import commonSaga from "./common";
import widgetsSaga from "./widgets";
import notificationsSaga from "./notifications";
import billingSaga from "./billing";
import librariesSaga from "./libraries";
import leverSaga from "./lever";
import peopleSaga from "./people";
import idvSaga from "./idv";
import marketplaceSaga from "./marketplace";
import scorecardsSaga from "./scorecards";

const rootSaga = function* root() {
  yield all([
    authSaga(),
    jobsSaga(),
    commentsSaga(),
    companiesSaga(),
    usersSaga(),
    billingSaga(),
    profileSaga(),
    candidateSaga(),
    dashboardSaga(),
    publicSaga(),
    commonSaga(),
    widgetsSaga(),
    notificationsSaga(),
    librariesSaga(),
    leverSaga(),
    peopleSaga(),
    idvSaga(),
    marketplaceSaga(),
    scorecardsSaga()

  ]);
};

export default rootSaga;
