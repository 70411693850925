/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";

import PropTypes from "prop-types";

import {
  handleClassNameById,
  handleGoNextQuestion
} from "mixins/helperCandidate";
import { callAll } from "utils/videoClearer";

const Questions = ({
  jobId,
  userId,
  history,
  questions = [],
  savedAnswers = [],
  isAllAnsweredSaved,
  currentStepActive,
  previousQuestionId,
  currentAction = "create",
  savePreviousQuestion,
  setModal,
  clearPreviousQuestion,
  isRecording,
  saveCurrentQuestion,
  setCashedElapsingTime,
  isShowDelayMessage,
  isIdvQuestion,
  updateFirebaseData
}) => {
  useEffect(() => () => clearPreviousQuestion({ userId }), []);

  const sortedQuestions = useMemo(
    () => questions?.sort((a, b) => a?.order - b?.order),
    [questions]
  );

  const sortedSavedAnswers = useMemo(
    () =>
      savedAnswers
        .map(item => ({
          ...item,
          question: questions.find(
            question => question.key === item.question.key
          )
        }))
        .sort((a, b) => a?.question?.order - b?.question?.order),
    [questions, savedAnswers]
  );

  const goToNextQuestion = questionId => {
    callAll();
    saveCurrentQuestion({ userId, questionCurrentCashed: null });
    setCashedElapsingTime(null);

    const questionNumber = questions.findIndex(item => item.key === questionId);

    if (typeof updateFirebaseData === "function") updateFirebaseData();

    history.push({
      pathname: `/candidate/video-questions/${currentAction}/${userId}/${jobId}/${questionId}/${questionNumber}`
    });
  };

  const handleWarning = questionId => {
    const isCurrentQuestionSaved = savedAnswers.some(
      item => item.question.key === previousQuestionId
    );

    const isTheSameQuestion = previousQuestionId === questionId;

    savePreviousQuestion({
      userId,
      questionId
    });

    if (!isCurrentQuestionSaved || isTheSameQuestion) {
      setModal({
        type: "lostVideoConfirmation",
        videoConfirmation: { goToNextQuestion, questionId }
      });
    } else {
      goToNextQuestion(questionId);
    }
  };

  return (
    <div className={`steps-wrapper question ${isIdvQuestion ? "idv" : ""}`}>
      <div
        className={`${
          sortedQuestions.length > 10 ? "steps-many" : "steps"
        } small`}
      >
        {sortedQuestions.map((currentQuestion, index) =>
           (
             <button
               type="button"
               key={`question-step-${currentQuestion?.key}`}
               className={`button__without-styles ${
              sortedQuestions.length >= 10 &&
              ((index + 1) % 10 === 0 || index + 1 === sortedQuestions.length)
                ? "steps__item-last"
                : "steps__item"
            }`}
               disabled={
                isRecording ||
                isAllAnsweredSaved ||
                currentQuestion?.key === currentStepActive ||
                handleGoNextQuestion(index, sortedQuestions, sortedSavedAnswers) ||
                isShowDelayMessage
              }
               onClick={() => handleWarning(currentQuestion?.key)}
               data-testid="Interview-Btn-Stepper_Btn"
               aria-label={`Go to question #${index + 1}`}
             >
               <div
                 className={handleClassNameById(
                isAllAnsweredSaved,
                currentStepActive,
                currentQuestion?.key,
                savedAnswers.find(({ question }) => question?.key === currentQuestion?.key)
              )}
               >
                 {index + 1}
               </div>
             </button>
          ))}
      </div>
    </div>
  );
};

Questions.defaultProps = {
  history: undefined,
  savedAnswers: [],
  isAllAnsweredSaved: false,
  jobId: undefined,
  userId: undefined,
  questions: [],
  currentAction: undefined,
  currentStepActive: undefined,
  previousQuestionId: undefined,
  savePreviousQuestion: undefined,
  setModal: undefined,
  clearPreviousQuestion: undefined,
  isRecording: undefined,
  saveCurrentQuestion: undefined,
  setCashedElapsingTime: undefined,
  isIdvQuestion: false,
  isShowDelayMessage: false,
  updateFirebaseData: undefined
};

Questions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  savedAnswers: PropTypes.arrayOf(PropTypes.shape({})),
  isAllAnsweredSaved: PropTypes.bool,
  jobId: PropTypes.string,
  userId: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string
    })
  ),
  currentAction: PropTypes.string,
  currentStepActive: PropTypes.string,
  previousQuestionId: PropTypes.string,
  savePreviousQuestion: PropTypes.func,
  setModal: PropTypes.func,
  clearPreviousQuestion: PropTypes.func,
  isRecording: PropTypes.bool,
  saveCurrentQuestion: PropTypes.func,
  setCashedElapsingTime: PropTypes.func,
  isShowDelayMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isIdvQuestion: PropTypes.bool,
  updateFirebaseData: PropTypes.func
};

export default Questions;
