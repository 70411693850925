import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { createDevice, confirmDevice, clearErrors } from "store/modules/profile/actions";
import { setModal } from "store/modules/modals/actions";
import { getIsTrialCompleted, getIsSubscriptionFailed, getNotifications } from "store/modules/notifications/selectors";
import { getProfileInfo } from "store/modules/profile/selectors";
import Security from "views/Security";
import { getCurrentUser } from "store/modules/users/selectors";

const mapStateToProps = state => {
  const {
    completedOnboarding = true
  } = getNotifications(state);

  const user = getCurrentUser(state);
  const isSetupCompleted = user?.is_setup_completed;

  return ({
    profileInfo: getProfileInfo(state),
    newDevice: state?.profile?.newDevice,
    error: state?.profile?.error,
    isTrialCompleted: getIsTrialCompleted(state),
    isSubscriptionFailed: getIsSubscriptionFailed(state),
    isSetupCompleted: isSetupCompleted && completedOnboarding
  });
};

const mapDispatchToProps = dispatch => ({
  createDevice: () => dispatch(createDevice()),
  setModal: payload => dispatch(setModal(payload)),
  confirmDevice: payload => dispatch(confirmDevice(payload)),
  clearErrors: () => dispatch(clearErrors())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Security)
);
