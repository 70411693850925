import React, { useEffect, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Edit, TrashBin, Accept, Cancel } from "mixins/svgIcons";
import { ROLE_ADMIN, ROLE_STANDARD } from "configs/user/rolesConfig";
import Table from "components/Common/Table";

const Users = ({
  t,
  dataUsers = [],
  currentUser,
  profileInfo,
  updateUser,
  fetchUsersList,
  setModal
}) => {
  const role = useRef();

  const [editing, setEditing] = useState(null);

  useEffect(() => {
    if (currentUser) {
      fetchUsersList(currentUser.key);
    }
  }, [currentUser, fetchUsersList]);

  const titleOfTheRole = ({ role: uRole, is_owner: uIsOwner }) => {
    switch (true) {
    case uIsOwner:
      return t("users.table.owner");
    case uRole === ROLE_ADMIN:
      return t("users.table.admin");
    case uRole === ROLE_STANDARD:
      return t("users.table.standard");
    default:
      return null;
    }
  };

  const handleSubmit = event => {
    updateUser({
      key: event?.target?.value,
      // eslint-disable-next-line react/prop-types
      role: role?.current?.value
    });

    setEditing(null);
  };

  const getActionProps = (gridState, rowProps) =>
    (rowProps && {
      mode: editing === rowProps.original ? "edit" : "view",
      actions: {
        onEdit: () => setEditing(rowProps.original),
        onCancel: () => setEditing(null)
      }
    }) ||
    {};

  const handleDelete = event => {
    setModal({
      type: "deleteUserConfirmation",
      userConfirmation: { value: event.target.value }
    });
  };

  const filtredDataOfUsers =
    dataUsers.reduce((acc, item) => {
      if ([ROLE_ADMIN, ROLE_STANDARD].includes(item.role)) {
        if (item.email !== profileInfo.email) {
          acc.push(item);
        } else {
          acc.unshift(item);
        }
      }

      return acc;
    }, []) || [];

  return (
    <Table
      t={t}
      header={[
        {
          Header: t("users.table.fullName"),
          accessor: "full_name",
          headerClassName: "first-column left",
          style: { fontWeight: 400 },
          Cell: row => <span>{row.original.full_name || "—"}</span>
        },
        {
          Header: t("users.table.email"),
          accessor: "email",
          style: { textAlign: "center" },
          Cell: row => (
            <span title={row.original.email}>{row.original.email}</span>
          )
        },
        {
          Header: t("users.table.phoneNumber"),
          accessor: "phone",
          style: { textAlign: "center" },
          Cell: row => <span>{row.original.phone || "—"}</span>
        },
        {
          Header: t("users.table.role"),
          accessor: "role",
          Cell: row => {
            const isEditable = editing === row.original;
            return isEditable ? (
              <select ref={role} defaultValue={row.original.role}>
                <option value={ROLE_ADMIN}>{t("users.table.admin")}</option>
                <option value={ROLE_STANDARD}>{t("users.table.standard")}</option>
              </select>
            ) : (
              <span>{titleOfTheRole(row.original)}</span>
            );
          },
          style: { textAlign: "center", paddingLeft: 0, paddingRight: 0 },
          sortable: false
        },
        {
          Header: t("users.table.status"),
          accessor: "status",
          style: { textAlign: "center" },
          Cell: row =>
            row.original.status && t(`users.table.${row.original.status?.toLowerCase()}`)
        },
        {
          Header: t("users.table.2fa"),
          accessor: "mfa_enabled",
          style: { textAlign: "center" },
          Cell: row => (
            <span className={!row.original.mfa_enabled ? "table-cell_disabled" : ""}>
              {t(`users.table.${row.original.mfa_enabled ? "on" : "off"}`)}
            </span>
          )
        },
        {
          accessor: false,
          Cell: row => {
            if (row.original.is_owner) return null;

            const {
              mode,
              actions: { onEdit }
            } = row.columnProps.rest;
            return mode === "edit" ? (
              <button
                value={row.original.key}
                onClick={handleSubmit}
                type="button"
                className="button__without-styles"
              >
                <Accept svgIconClass="not-clickable-icon" />
              </button>
            ) : (
              profileInfo.email !== row.original.email && (
                <button
                  onClick={() => onEdit(row.index)}
                  value={row.original.key}
                  className="button__without-styles"
                  type="button"
                >
                  <Edit svgIconClass="not-clickable-icon" />
                </button>
              )
            );
          },
          style: { textAlign: "center", paddingLeft: 0, paddingRight: 0 },
          width: 30,
          sortable: false,
          getProps: getActionProps
        },
        {
          accessor: false,
          Cell: row => {
            if (row.original.is_owner) return null;

            const {
              mode,
              actions: { onCancel }
            } = row.columnProps.rest;
            return mode === "edit" ? (
              <button
                onClick={onCancel}
                type="button"
                className="button__without-styles"
              >
                <Cancel svgIconClass="not-clickable-icon" />
              </button>
            ) : (
              profileInfo.email !== row.original.email && (
                <button
                  onClick={handleDelete}
                  value={row.original.key}
                  className="button__without-styles"
                  type="button"
                >
                  <TrashBin svgIconClass="not-clickable-icon" />
                </button>
              )
            );
          },
          style: { textAlign: "center", paddingLeft: 0 },
          width: 60,
          sortable: false,
          getProps: getActionProps
        }
      ]}
      getTrProps={(state, rowInfo) => {
        if (rowInfo) {
          const isCurrentRow =
            rowInfo.original.key === (editing && editing.key);
          return {
            style: {
              backgroundColor: isCurrentRow ? "rgba(224,224,224,0.2)" : "white",
              border: isCurrentRow ? "1px solid #B6BDC5" : "inherit"
            }
          };
        }
        return false;
      }}
      body={filtredDataOfUsers}
    />
  );
};

Users.defaultProps = {
  updateUser: undefined,
  profileInfo: undefined,
  dataUsers: undefined,
  fetchUsersList: undefined,
  setModal: undefined
};

Users.propTypes = {
  t: PropTypes.func.isRequired,
  updateUser: PropTypes.func,
  currentUser: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    role: PropTypes.string,
    is_owner: PropTypes.bool,
    email: PropTypes.string
  }).isRequired,
  profileInfo: PropTypes.shape({
    agency: PropTypes.string,
    agency_name: PropTypes.string,
    email: PropTypes.string,
    full_name: PropTypes.string,
    phone: PropTypes.string,
    worker_count: PropTypes.number
  }),
  dataUsers: PropTypes.arrayOf(PropTypes.object),
  fetchUsersList: PropTypes.func,
  setModal: PropTypes.func
};

export default withTranslation()(Users);
