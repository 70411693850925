import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getCurrentUser
} from "store/modules/users/selectors";
import { getCheckoutLink, isBillingInfoLoading, getCheckoutLinkError, getCurrencies } from "store/modules/profile/actions";
import { getNotifications } from "store/modules/notifications/selectors";
import Plans from "views/Billing/Plans";

const mapStateToProps = state => ({
  isLoading: state.profile.isBillingLoading,
  currentUser: getCurrentUser(state),
  notifications: getNotifications(state),
  errorCheckout: state.profile.errorCheckout,
  currencies: state.profile.currencies,
  isCurrenciesLoading: state.profile.isCurrenciesLoading
});

const mapDispatchToProps = {
  getCheckoutLink,
  getCheckoutLinkError,
  isBillingInfoLoading,
  getCurrencies
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Plans));
