/* eslint-disable no-return-assign */
import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import { defaultTimeFormat } from "configs/dates";

import AddToCalendar from "./AddToCalendar";
import "./styles.scss";

const ScheduleComponent = ({
  t,
  candidateName,
  candidateEmail,
  calendarTitle,
  availability,
  utcMinutesDelta,
  selected,
  isPast
}) => {
  const ref = useRef(null);
  const handleClick = e => {
    const newLeft = Math.abs(document.getElementById("availabilities")?.getBoundingClientRect()?.left - e.target?.getBoundingClientRect()?.left);
    setTimeout(() => {
      const dropdown = document.getElementsByClassName("atc-dropdown")[0];
      if (dropdown) dropdown.style.left = `${newLeft}px`;
    }, 10);
  };

  const addTimeToAvailability = moment(availability).add(30, "m").toDate();

  const availableStartTime = utcMinutesDelta === null
    ? defaultTimeFormat(moment(availability))
    : defaultTimeFormat(
      moment(availability).utcOffset(
        utcMinutesDelta
      )
    );

  const availableEndTime = utcMinutesDelta === null
    ? defaultTimeFormat(moment(addTimeToAvailability))
    : defaultTimeFormat(
      moment(addTimeToAvailability).utcOffset(
        utcMinutesDelta
      )
    );

  return selected ? (
    <AddToCalendar
      title={calendarTitle}
      startDatetime={moment(availability)}
      email={candidateEmail}
      CustomButton={args => (
        <OverlayTrigger
          key={availability}
          placement="bottom"
          delay={{ show: 250, hide: 100 }}
          ref={el => ref.current = el}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false
              }
            }
          }}
          overlay={props => (
            <Tooltip
              className="tooltip-bottom__custom"
              {...props}
            >
              {t("job.candidate.localTime", {
                time:
                  `${availableStartTime} - ${availableEndTime}`,
                name: candidateName
              })}
            </Tooltip>
          )}
        >
          <span
            className="n-font-small n-font-medium-weight n-white"
            onKeyDown={isPast ? null : e => {
              args.onClick(e);
              if (ref.current) ref.current.hide();
              if (isMobile) handleClick(e);
            }}
            onClick={isPast ? null : e => {
              args.onClick(e);
              if (ref.current) ref.current.hide();
              if (isMobile) handleClick(e);
            }}
          >
            {t(`job.candidate.${isPast ? "availableDate" : "scheduleMeeting"}`)}
          </span>
        </OverlayTrigger>
      )}
    />
  ) : <div className="calendar-cell__target" />;
};

ScheduleComponent.defaultProps = {
  utcMinutesDelta: undefined
};

ScheduleComponent.propTypes = {
  t: PropTypes.func.isRequired,
  candidateName: PropTypes.string.isRequired,
  candidateEmail: PropTypes.string.isRequired,
  calendarTitle: PropTypes.string.isRequired,
  availability: PropTypes.string.isRequired,
  utcMinutesDelta: PropTypes.number,
  selected: PropTypes.bool.isRequired,
  isPast: PropTypes.bool.isRequired
};

export default withTranslation()(ScheduleComponent);
