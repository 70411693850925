import React, { useMemo } from "react";
import PropTypes from "prop-types";
import className from "classnames";
import { createSelectable } from "react-selectable";

import { Target } from "mixins/svgIcons";

import ScheduleComponent from "./ScheduleComponent";
import "./styles.scss";

const SelectableComponent = createSelectable(() => (
  <div className="calendar-cell__target">
    <Target />
  </div>
));

const Cell = ({
  data: {
    timeFormatted, dateTimeISO, hasMinutes, isTimeBeforeCurrent, isDateBeforeCurrent, isCurrentTime
  },
  handleSelection,
  chosenCalendarDates,
  isOverview,
  candidateName,
  candidateEmail,
  calendarTitle,
  utcMinutesDelta
}) => {
  const isSelectableCell = isOverview ? !timeFormatted : (!timeFormatted && !isTimeBeforeCurrent);

  const selected = useMemo(() => {
    const result = isSelectableCell
      ? chosenCalendarDates.includes(dateTimeISO)
      : false;

    return result;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = className({
    "calendar-cell": isSelectableCell,
    "calendar-cell__disabled calendar-cell": isTimeBeforeCurrent,
    "calendar-cell__min": hasMinutes && !timeFormatted,
    "calendar-cell__min-data": hasMinutes,
    "calendar-cell__time": timeFormatted,
    "calendar-cell__selected": selected,
    "calendar-cell__selected-past": isTimeBeforeCurrent || isDateBeforeCurrent,
    "calendar-cell__selected-current": isCurrentTime
  });

  const toggleSelection = () => {
    handleSelection([dateTimeISO]);
  };

  return (
    <td
      className={classes}
      onClick={isTimeBeforeCurrent ? null : toggleSelection}
      role="presentation"
    >
      {timeFormatted && <span>{timeFormatted}</span>}
      {isSelectableCell && (isOverview
        ? (
          <ScheduleComponent
            candidateName={candidateName}
            candidateEmail={candidateEmail}
            calendarTitle={calendarTitle}
            utcMinutesDelta={utcMinutesDelta}
            availability={dateTimeISO}
            selected={selected}
            isPast={isTimeBeforeCurrent || isDateBeforeCurrent}
          />
        ) : <SelectableComponent selectableKey={dateTimeISO} />)}
    </td>
  );
};

Cell.defaultProps = {
  chosenCalendarDates: undefined,
  utcMinutesDelta: undefined,
  isCurrentTime: false,
  isOverview: false
};

Cell.propTypes = {
  data: PropTypes.shape({
    timeFormatted: PropTypes.string,
    hasMinutes: PropTypes.bool,
    dateTimeISO: PropTypes.string,
    isTimeBeforeCurrent: PropTypes.bool,
    isDateBeforeCurrent: PropTypes.bool,
    isCurrentTime: PropTypes.bool
  }).isRequired,
  handleSelection: PropTypes.func.isRequired,
  chosenCalendarDates: PropTypes.arrayOf(PropTypes.string),
  isOverview: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  candidateName: PropTypes.string.isRequired,
  candidateEmail: PropTypes.string.isRequired,
  calendarTitle: PropTypes.string.isRequired,
  utcMinutesDelta: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  isCurrentTime: PropTypes.bool
};

export default Cell;
