/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";

import Header from "containers/Common/HeaderContainer";
import PageWrapper from "hoc/PageWrapper";
import PeopleTable from "containers/Tables/PeopleContainer";

import { store } from "store";
import { setPeoplesPageSelectedFilter } from "store/modules/people/actions";
import Filters from "./Filters";


const People = ({
  isLoading,
  filters,
  filtersPaginationData,
  fetchLatestPeopleFilterCompanies,
  fetchLatestPeopleFilterJobs,
  fetchLatestPeopleFilterOwners,
  fetchSearchPeopleFilter,
  isFilterCompaniesLoading,
  isFilterJobsLoading,
  isFilterOwnersLoading,
  isSearchFilterLoading,
  filter
}) => {
  const [ordering, setOrdering] = useState("-submitted_at");
  const [updated, setUpdated] = useState(false);
  // const clearFilter = () => setFilter(newFilter);

  useEffect(() => {
    store.dispatch(setPeoplesPageSelectedFilter({
      value: filter.companies,
      field: "companies"
    }));
    store.dispatch(setPeoplesPageSelectedFilter({
      value: filter.jobs,
      field: "jobs"
    }));
    store.dispatch(setPeoplesPageSelectedFilter({
      value: filter.owners,
      field: "owners"
    }));
    store.dispatch(setPeoplesPageSelectedFilter({
      value: filter.statuses,
      field: "statuses"
    }));
    store.dispatch(setPeoplesPageSelectedFilter({
      value: filter.ratings,
      field: "ratings"
    }));
    setUpdated(true);
  }, [filters]);


  const clearFilter = () => {
    store.dispatch(setPeoplesPageSelectedFilter({
      value: [],
      field: "companies"
    }));
    store.dispatch(setPeoplesPageSelectedFilter({
      value: [],
      field: "jobs"
    }));
    store.dispatch(setPeoplesPageSelectedFilter({
      value: [],
      field: "owners"
    }));
    store.dispatch(setPeoplesPageSelectedFilter({
      value: [],
      field: "statuses"
    }));
    store.dispatch(setPeoplesPageSelectedFilter({
      value: [],
      field: "ratings"
    }));
    store.dispatch(setPeoplesPageSelectedFilter({
      value: "",
      field: "title"
    }));
  };
  return (
    <PageWrapper header={<Header />}>
      <Container className="people">
        <Filters
          isLoading={isLoading}
          filters={filters}
          clearFilter={clearFilter}
          filtersPaginationData={filtersPaginationData}
          fetchLatestPeopleFilterCompanies={fetchLatestPeopleFilterCompanies}
          fetchLatestPeopleFilterJobs={fetchLatestPeopleFilterJobs}
          fetchLatestPeopleFilterOwners={fetchLatestPeopleFilterOwners}
          fetchSearchPeopleFilterCompanies={search => fetchSearchPeopleFilter({ search, filter, field: "companies" })}
          fetchSearchPeopleFilterJobs={search => fetchSearchPeopleFilter({ search, filter, field: "jobs" })}
          fetchSearchPeopleFilterOwners={search => fetchSearchPeopleFilter({ search, filter, field: "owners" })}
          fetchSearchPeopleFilterRatings={search => fetchSearchPeopleFilter({ search, filter, field: "ratings" })}
          fetchSearchPeopleFilterStatuses={search => fetchSearchPeopleFilter({ search, filter, field: "statuses" })}
          isFilterCompaniesLoading={isFilterCompaniesLoading}
          isFilterJobsLoading={isFilterJobsLoading}
          isFilterOwnersLoading={isFilterOwnersLoading}
          isSearchFilterLoading={isSearchFilterLoading}
        />

        <Row justify="center">
          <Col md={12}>
            <PeopleTable
              filter={filter}
              clearFilter={clearFilter}
              updated={updated}
              setUpdated={setUpdated}
              ordering={ordering}
              setOrdering={setOrdering}
            />
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

People.defaultProps = {
  isFilterCompaniesLoading: undefined,
  isFilterJobsLoading: undefined,
  isFilterOwnersLoading: undefined,
  isSearchFilterLoading: undefined
};

People.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    companiesSelected: PropTypes.arrayOf(PropTypes.any),
    jobsSelected: PropTypes.arrayOf(PropTypes.any),
    ownersSelected: PropTypes.arrayOf(PropTypes.any),
    companies: PropTypes.arrayOf(PropTypes.any),
    jobs: PropTypes.arrayOf(PropTypes.any),
    owners: PropTypes.arrayOf(PropTypes.any),
    ratings: PropTypes.arrayOf(PropTypes.any),
    statuses: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  filter: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.any),
    jobs: PropTypes.arrayOf(PropTypes.any),
    owners: PropTypes.arrayOf(PropTypes.any),
    ratings: PropTypes.arrayOf(PropTypes.any),
    statuses: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  filtersPaginationData: PropTypes.shape({}).isRequired,
  isFilterCompaniesLoading: PropTypes.bool,
  isFilterJobsLoading: PropTypes.bool,
  isFilterOwnersLoading: PropTypes.bool,
  isSearchFilterLoading: PropTypes.bool,
  fetchLatestPeopleFilterCompanies: PropTypes.func.isRequired,
  fetchLatestPeopleFilterJobs: PropTypes.func.isRequired,
  fetchLatestPeopleFilterOwners: PropTypes.func.isRequired,
  fetchSearchPeopleFilter: PropTypes.func.isRequired
};

export default People;
