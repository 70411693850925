import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  fetchAllJobsStats,
  setDashboardDateRange,
  clearError
} from "store/modules/dashboard/actions";
import { handleOnboarding } from "store/modules/widgets/actions";
import {
  getDashboardOnboarding,
  getDashboardDateRange,
  getDashboardCanidates,
  getError
} from "store/modules/dashboard/selectors";
import { getCurrentUser, getHasCurrentUserLoaded } from "store/modules/users/selectors";
import { getNotifications } from "store/modules/notifications/selectors";
import { setShowTutorialMute } from "store/modules/notifications/actions";
import Dashboard from "views/Dashboard";

const mapStateToProps = state => {
  const onboarding = getDashboardOnboarding(state);

  return ({
    dateRange: getDashboardDateRange(state),
    currentUser: getCurrentUser(state),
    onboarding,
    candidates: getDashboardCanidates(state),
    error: getError(state),
    notifications: getNotifications(state),
    hasCurrentUserLoaded: getHasCurrentUserLoaded(state),
    completeOnboarding:
      onboarding.companies_amount &&
      onboarding.agency_jobs_amount &&
      onboarding.total_received_candidates
  });
};

const mapDispatchToProps = {
  fetchAllJobsStats,
  handleOnboarding,
  setDashboardDateRange,
  clearError,
  setShowTutorialMute
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
