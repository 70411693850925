import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { Person } from "mixins/svgIcons";
import TooltipSuccess from "components/Common/Tooltip/Success";

const Left = ({ hasIcon = false, position, totalValue = 0, currentValue = 0 }) => {
  const max = totalValue > currentValue ? totalValue : currentValue;
  const percent = 100 / max;
  const marginLeft = currentValue * percent;

  const itemRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <span className="left" style={{ marginLeft: `${marginLeft}%`, marginTop: "10px" }}>
      {hasIcon && (
        <span className="left-icon">
          <Person />
        </span>
      )}
      <span
        className={classNames({
          "left-text": true,
          [position]: true,
          danger: currentValue / totalValue >= 0.9
        })}
        ref={itemRef}
        onPointerEnter={() => setShowTooltip(true)}
        onPointerLeave={() => setShowTooltip(false)}
      >
        {currentValue}
      </span>
      <TooltipSuccess
        position="top"
        message="Current usage"
        target={itemRef.current}
        show={showTooltip}
      />
    </span>
  );
};

Left.defaultProps = {
  hasIcon: undefined,
  position: undefined,
  currentValue: undefined,
  totalValue: undefined
};

Left.propTypes = {
  hasIcon: PropTypes.bool,
  position: PropTypes.string,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Left;
