import { createAction } from "redux-actions";

export const isPublicInfoLoading = createAction("PUBLIC_INFO_LOADING");
export const isShareLinkSending = createAction("SHARE_LINK_SENDING");

export const shareCandidatePublic = createAction("SHARE_CANDIDATE_PUBLIC");
export const shareCandidatePublicResponse = createAction(
  "SHARE_CANDIDATE_PUBLIC_RESPONSE"
);
export const shareCandidatePublicError = createAction(
  "SHARE_CANDIDATE_PUBLIC_ERROR"
);

export const shareCandidatesListPublic = createAction("SHARE_CANDIDATES_LIST_PUBLIC");
export const shareCandidatesListPublicResponse = createAction(
  "SHARE_CANDIDATES_LIST_PUBLIC_RESPONSE"
);
export const shareCandidatesListPublicError = createAction(
  "SHARE_CANDIDATES_LIST_PUBLIC_ERROR"
);

export const fetchPublicUserInfo = createAction("FETCH_PUBLIC_USER_INFO");
export const fetchPublicUserInfoResponse = createAction(
  "FETCH_PUBLIC_USER_INFO_RESPONSE"
);
export const fetchPublicUserInfoError = createAction(
  "FETCH_PUBLIC_USER_INFO_ERROR"
);

export const fetchPublicBulkInfo = createAction("FETCH_PUBLIC_BULK_INFO");
export const fetchPublicBulkInfoResponse = createAction(
  "FETCH_PUBLIC_BULK_INFO_RESPONSE"
);
export const fetchPublicBulkInfoError = createAction(
  "FETCH_PUBLIC_BULK_INFO_ERROR"
);
export const fetchPublicBulkCandidateInfo = createAction("FETCH_PUBLIC_BULK_CANDIDATE_INFO");

export const clearSharableLink = createAction("CLEAR_SHARABLE_LINK");
export const clearErrors = createAction("CLEAR_ERRORS");

export const setAvailableAnswer = createAction("SET_AVAILABLE_ANSWER");
export const setAvailableLink = createAction("SET_AVAILABLE_LINK");
export const setAvailableAnswerResponse = createAction("SET_AVAILABLE_ANSWER_RESPONSE");

export const setAvailableAnswerBulk = createAction("SET_AVAILABLE_ANSWER_BULK");
export const setCandidateCompanyInfo = createAction("SET_CANDIDATE_COMPANY_INFO");
