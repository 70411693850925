import React from "react";
import { bool, shape, string } from "prop-types";
import { components } from "react-select";

import { ArrowUp, ArrowDown } from "mixins/svgIcons";

const DropdownIndicator = ({
  height,
  width,
  fill,
  ...rest
}) => {
  const { selectProps: { menuIsOpen } = {} } = rest;

  return (
    <components.DropdownIndicator {...rest}>
      {menuIsOpen ? (
        <ArrowUp
          height={height}
          width={width}
          fill={fill}
        />
      ) : (
        <ArrowDown
          height={height}
          width={width}
          fill={fill}
        />
      )}
    </components.DropdownIndicator>
  );
};

DropdownIndicator.defaultProps = {
  selectProps: {},
  height: "16",
  width: "16",
  fill: "#212B46"
};

DropdownIndicator.propTypes = {
  height: string,
  width: string,
  fill: string,
  selectProps: shape({
    menuIsOpen: bool
  })
};

export default DropdownIndicator;
