/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, Switch } from "react-router-dom";

import PrivateChat from "containers/PrivateChat";
import TrialCompleted from "containers/Billing/TrialCompleted";
import SubscriptionFailed from "containers/Billing/SubscriptionFailed";
import Plans from "containers/Billing/Plans";
import Notifications from "containers/Notifications";
import Users from "containers/Users/UsersContainer";
import Onboarding from "containers/Onboarding";
import Security from "containers/Security";
import StripeSession from "containers/Billing/StripeSession";
import DownloadNotification from "containers/DownloadNotification";
import IdvCheckoutSession from "containers/Connect/IdvCheckoutSession/IdvCheckoutSession";
import { getHealthcheckError } from "store/modules/widgets/selectors";
import { connect } from "react-redux";
import RateLimitingPage from "../RateLimiting/RateLimitingPage";

const PrivateRoute = ({
  isAuthenticated,
  saveRoute,
  savingRoute,
  isTrialCompleted,
  isSubscriptionFailed,
  isSetupCompleted,
  hasAdminRight,
  path,
  healthcheckError,
  ...rest
}) => {
  const hasRateLimitingIssue = healthcheckError === "api_rate_limiting";
  useEffect(() => {
    if (!isAuthenticated) {
      saveRoute({
        pathname: rest.location.pathname,
        search: rest.location.search
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && savingRoute) {
      saveRoute(null);
    }
  }, [isAuthenticated]);

  return isAuthenticated ? (
    <>
      {hasRateLimitingIssue ? (
        <RateLimitingPage />
      ) : (
        <>
          <Notifications />
          <DownloadNotification />
          {isSubscriptionFailed ? (
            <Switch>
              <Route
                path="/subscription-failed"
                exact
                component={SubscriptionFailed}
              />
              <Route path="/security" exact component={Security} />
              <Route
                path="/stripe-session/:id"
                exact
                component={StripeSession}
              />
              <PrivateRoute
                path="/connect/:type/:id"
                exact
                component={IdvCheckoutSession}
              />
              {hasAdminRight && <Route path="/plans" exact component={Plans} />}
              <Redirect to="/subscription-failed" />
            </Switch>
          ) : isTrialCompleted ? (
            <Switch>
              <Route path="/trial-completed" exact component={TrialCompleted} />
              <Route path="/users" exact component={Users} />
              <Route path="/security" exact component={Security} />
              <Route
                path="/stripe-session/:id"
                exact
                component={StripeSession}
              />
              <PrivateRoute
                path="/connect/:type/:id"
                exact
                component={IdvCheckoutSession}
              />
              {hasAdminRight && <Route path="/plans" component={Plans} />}
              <Redirect to="/trial-completed" />
            </Switch>
          ) : !isSetupCompleted ? (
            <Switch>
              <Route path="/onboarding" exact component={Onboarding} />
              <Route path="/security" exact component={Security} />
              <Redirect to="/onboarding" />
            </Switch>
          ) : savingRoute ? (
            <Redirect to={savingRoute} />
          ) : (
            <Route {...rest} />
          )}
          <PrivateChat />
        </>
      )}
    </>
  ) : (
    <Redirect to="/sign-in" />
  );
};

PrivateRoute.defaultProps = {
  savingRoute: null,
  healthcheckError: undefined
};

PrivateRoute.propTypes = {
  saveRoute: PropTypes.func.isRequired,
  savingRoute: PropTypes.shape({}),
  isAuthenticated: PropTypes.bool.isRequired,
  isTrialCompleted: PropTypes.bool.isRequired,
  isSubscriptionFailed: PropTypes.bool.isRequired,
  hasAdminRight: PropTypes.bool.isRequired,
  isSetupCompleted: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  healthcheckError: PropTypes.string
};

const mapStateToProps = state => ({
    healthcheckError: getHealthcheckError(state)
  });

export default connect(mapStateToProps)(PrivateRoute);
