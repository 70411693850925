/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React, { useEffect, useState, useMemo } from "react";
import "./styles.scss";
import { updateInterviewScore } from "store/modules/scorecards/action";
import { store } from "store";
import { connect } from "react-redux";
import {
  getCandidateScoresLoading,
  getInterviewScorecard,
  getInterviewScores,
  getIsUpdatingScoreLoading,
  getScorecardInterviewCriteria,
  getScorecardInterviewTitle
} from "store/modules/scorecards/selectors";
import { getUserScoreDraftKey } from "store/modules/jobs/selectors";
import { find, findIndex, set, size } from "lodash";
import { BeatLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import TooltipInfo from "components/Common/Tooltip/Info";
import classNames from "classnames";
import InterviewScorecardItem from "./InterviewScorecardItem";

const InterviewScorecard = ({
  userId,
  criteria,
  scorecard,
  title,
  draftKey,
  scores,
  jobId,
  isUpdatingScoreLoading,
  isCandidateScoresLoading,
  withBorder = true
}) => {
  const { t } = useTranslation();
  const [isSubmitting, setisSubmitting] = useState(false);
  const hasOptional = criteria?.findIndex(a => a.is_optional) > -1;
  const [scorecardCriteriaScores, setScorecardCriteriaScores] = useState(
    scores?.criteria_scores?.map(a => ({
      ...a,
      scorecard_criteria:
        a?.scorecard_criteria?.key ?? a?.scorecard_criteria ?? undefined
    })) ?? []
  );

  const showSubmit = useMemo(() => {
    let show = true;
    const requiredQuestions = criteria
      ?.filter(a => !a.is_optional)
      ?.map(a => a.key);
    const answeredQuestions = scorecardCriteriaScores?.map(
      a => a?.scorecard_criteria?.key ?? a?.scorecard_criteria ?? undefined
    );

    if (size(requiredQuestions) > 0) {
      requiredQuestions.forEach(reqQuestionKey => {
        if (!answeredQuestions?.includes(reqQuestionKey)) {
          show = false;
        }
      });
    }
    return show;
  }, [criteria, scorecardCriteriaScores]);

  useEffect(() => {
    if (!isCandidateScoresLoading) return;

    if (!scores?.criteria_scores) setScorecardCriteriaScores([]);

    if (scores?.criteria_scores?.length > 0) {
      setScorecardCriteriaScores(
        scores?.criteria_scores?.map(a => ({
          ...a,
          scorecard_criteria:
            a?.scorecard_criteria?.key ?? a?.scorecard_criteria ?? undefined
        }))
      );
    }
  }, [isCandidateScoresLoading, scores, scores.criteria_scores]);

  const handleUpdate = (score, scoreKey, criteriaKey) => {
    const scoreIndex = findIndex(
      scorecardCriteriaScores,
      b => b.scorecard_criteria === criteriaKey
    );

    const tempCriteriaScores =
      scorecardCriteriaScores?.map(crit => ({
        ...crit,
        scorecard_criteria: crit?.scorecard_criteria ?? undefined
      })) ?? [];

    const tempCriteriaScores1 =
      scoreIndex === -1
        ? [
            ...tempCriteriaScores,
            {
              scorecard_criteria: criteriaKey,
              key: scoreKey ?? undefined,
              score
            }
          ]
        : set(tempCriteriaScores, `${scoreIndex}`, {
            scorecard_criteria: criteriaKey,
            key: scoreKey ?? undefined,
            score
          });

    store.dispatch(
      updateInterviewScore({
        scorecard: scorecard.key,
        candidate: userId,
        criteria_scores: tempCriteriaScores1,
        userId,
        isDraft: true,
        draftKey
      })
    );

    setScorecardCriteriaScores(tempCriteriaScores1);
  };

  const handleSubmit = () => {
    const submitScoreValues = criteria.map(crit => {
      const currentVal = scorecardCriteriaScores?.find(
        a => a.scorecard_criteria === crit.key
      );

      if (currentVal) return currentVal;

      return {
        scorecard_criteria: crit.key,
        key: undefined,
        score: 0
      };
    });

    setisSubmitting(true);

    store.dispatch(
      updateInterviewScore({
        scorecard: scorecard.key,
        candidate: userId,
        criteria_scores: submitScoreValues,
        userId,
        isDraft: false,
        draftKey,
        jobId
      })
    );
  };

  const sortedCriteria = criteria?.sort((a, b) => a?.order - b?.order);

  return (
    <div className="scorecard-table">
      {withBorder && (
      <div className="scorecard-table__header" id="scorecard-table">
        {title}
      </div>
      )}
      <div
        className={classNames(
          "scorecard-table__content",
          withBorder ? "scorecard-table__border" : ""
        )}
        style={{ position: "relative" }}
      >
        {(isUpdatingScoreLoading ||
          isCandidateScoresLoading ||
          isSubmitting) && (
          <div className="scorecard-table__content-loader">
            <div
              style={{
                fontWeight: 500
              }}
            >
              <BeatLoader color="#5a2af1" loading />
            </div>
          </div>
        )}
        {sortedCriteria?.map(a => (
          <InterviewScorecardItem
            {...a}
            text={a.text.text}
            text_markdown={a.text.text_markdown}
            description={a?.description?.description}
            handleUpdate={handleUpdate}
            key={`${a.key}-${find(
              scorecardCriteriaScores,
              b => b.scorecard_criteria === a.key
            )?.score ?? ""}`}
            isEditable
            userId={userId}
            id={a.key}
            scorecard={scorecard}
            isRequired={!a.is_optional}
            hasOptional={hasOptional}
            score={
              find(scorecardCriteriaScores, b => b.scorecard_criteria === a.key)
                ?.score
            }
          />
        ))}
        {hasOptional && (
        <div className="required">{t("scorecard.required")}</div>
        )}
        {showSubmit && (
        <TooltipInfo message={t("scorecard.buttonTooltip")} position="top">
          <div
            className="scorecard-table__button"
            onClick={handleSubmit}
            style={{
                background: isSubmitting ? "#5A2AF180" : ""
              }}
          >
            {isSubmitting ? t("button.submitting") : t("button.submit")}
          </div>
        </TooltipInfo>
        )}
      </div>
    </div>
  );
};

InterviewScorecard.propTypes = {
  userId: PropTypes.string.isRequired,
  criteria: PropTypes.arrayOf.isRequired,
  title: PropTypes.string.isRequired,
  scorecard: PropTypes.string.isRequired,
  draftKey: PropTypes.string.isRequired,
  scores: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  isUpdatingScoreLoading: PropTypes.bool.isRequired,
  isCandidateScoresLoading: PropTypes.bool.isRequired,
  withBorder: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  criteria: getScorecardInterviewCriteria(state),
  scorecard: getInterviewScorecard(state),
  title: getScorecardInterviewTitle(state),
  draftKey: getUserScoreDraftKey(state),
  scores: getInterviewScores(state),
  isUpdatingScoreLoading: getIsUpdatingScoreLoading(state),
  isCandidateScoresLoading: getCandidateScoresLoading(state)
});

export default connect(mapStateToProps)(InterviewScorecard);
