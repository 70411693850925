import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  getCandidateInfo,
  getCandidateJobInfoCompany,
  getCandidateJobInfo
} from "store/modules/сandidates/selectors";

import SavedForLaterView from "views/Candidate/SavedForLater";

const mapStateToProps = state => ({
  candidateInfo: getCandidateInfo(state),
  jobInfoCompany: getCandidateJobInfoCompany(state),
  jobInfo: getCandidateJobInfo(state)
});

export default withRouter(connect(mapStateToProps)(SavedForLaterView));
