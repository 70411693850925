import React from "react";

import { CreditCard } from "mixins/svgIcons";

const ManualInvoce = () => (
  <div className="show-form">
    <div className="show-form__row">
      <CreditCard svgIconClass="show-form__icon" />
      <span className="n-font-medium-weight n-black-100">
        Looking to update your billing details?
      </span>
    </div>
    <div className="show-form__row show-form__row_second">
      <a
        href="https://support.willo.video"
        target="_blank"
        rel="noopener noreferrer"
      >
        Please contact support@willotalent.com
      </a>
    </div>
  </div>
);

export default ManualInvoce;
