import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getCurrentUser } from "store/modules/users/selectors";
import Libraries from "views/Libraries";

const mapStateToProps = state => ({
  error: state.libraries.error,
  success: state.libraries.success,
  currentUser: getCurrentUser(state)
});

const mapDispatchToProps = null;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Libraries));
