/* eslint-disable camelcase */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable global-require */
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  DescriptionInfo,
  RatingNo,
  RatingStrongNo,
  RatingStrongYes,
  RatingUndecided,
  RatingYes
} from "mixins/svgIcons";
import React, { useState, useEffect } from "react";
import TooltipInfo from "components/Common/Tooltip/Info";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { isEmpty } from "lodash";
import { isIOS } from "react-device-detect";

const InterviewScorecardItem = ({
  text_markdown,
  text,
  isEditable,
  id,
  score: initialScore,
  handleUpdate,
  description,
  isRequired,
  hasOptional
}) => {
  const { t } = useTranslation();
  const [showSavingIndex, setShowSavingIndex] = useState(null);
  const onChange = score => {
    setShowSavingIndex(score);
    if (score === initialScore) {
      if (isRequired) return;
      handleUpdate(0, initialScore?.key, id);
    } else {
      handleUpdate(score, initialScore?.key, id);
    }
  };

  useEffect(() => {
    if (showSavingIndex) {
      setTimeout(() => {
        setShowSavingIndex(null);
      }, 1000);
    }
  }, [showSavingIndex]);

  return (
    <div className="scorecard-table__content-container">
      <div className="scorecard-table__content-label">
        <div className="scorecard-table__content-label_markdown-wrapper">
          <div className="scorecard-table__content-label_markdown">
            {ReactHtmlParser(
              !isEmpty(text)
                ? text_markdown
                : t("scorecard.create.input.criteriaTitlePlaceholder")
            )}
          </div>
          {isRequired && hasOptional && <span className="required">*</span>}
        </div>
        {description && (
          <div
            style={{
              width: 18
            }}
          >
            <TooltipInfo
              position="top"
              message={description}
              popperConfig={{
                modifiers: {
                  preventOverflow: {
                    enabled: false
                  }
                }
              }}
            >
              <div>
                <DescriptionInfo size={12} />
              </div>
            </TooltipInfo>
          </div>
        )}
      </div>
      <div className="scorecard-table__content-icons">
        <TooltipInfo
          disabled={isIOS}
          trigger={["hover"]}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false
              }
            }
          }}
          message={
              showSavingIndex === 1
                ? t("scorecard.scores.saving")
                : t("scorecard.scores.strongNo")
            }
        >
          <div>
            <RatingStrongNo
              onClick={() => {
                  if (isEditable) onChange(1);
                }}
              fill="#BBC2C9"
              svgIconCLass={classNames(
                  "strong-no",
                  initialScore === 1 ? "active" : ""
                )}
            />
          </div>
        </TooltipInfo>

        <TooltipInfo
          disabled={isIOS}
          trigger={["hover"]}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false
              }
            }
          }}
          message={
              showSavingIndex === 2
                ? t("scorecard.scores.saving")
                : t("scorecard.scores.no")
            }
        >
          <div>
            <RatingNo
              fill="#BBC2C9"
              svgIconCLass={classNames(
                  "no",
                  initialScore === 2 ? "active" : ""
                )}
              onClick={() => {
                  if (isEditable) onChange(2);
                }}
            />
          </div>
        </TooltipInfo>
        <TooltipInfo
          disabled={isIOS}
          trigger={["hover"]}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false
              }
            }
          }}
          message={
              showSavingIndex === 3
                ? t("scorecard.scores.saving")
                : t("scorecard.scores.notSure")
            }
        >
          <div>
            <RatingUndecided
              onClick={() => {
                  if (isEditable) onChange(3);
                }}
              fill="#BBC2C9"
              svgIconCLass={classNames(
                  "undecided",
                  initialScore === 3 ? "active" : ""
                )}
            />
          </div>
        </TooltipInfo>

        <TooltipInfo
          disabled={isIOS}
          trigger={["hover"]}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false
              }
            }
          }}
          message={
              showSavingIndex === 4
                ? t("scorecard.scores.saving")
                : t("scorecard.scores.yes")
            }
        >
          <div>
            <RatingYes
              fill="#BBC2C9"
              svgIconCLass={classNames(
                  "yes",
                  initialScore === 4 ? "active" : ""
                )}
              onClick={() => {
                  if (isEditable) onChange(4);
                }}
            />
          </div>
        </TooltipInfo>

        <TooltipInfo
          disabled={isIOS}
          trigger={["hover"]}
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false
              }
            }
          }}
          message={
              showSavingIndex === 5
                ? t("scorecard.scores.saving")
                : t("scorecard.scores.strongYes")
            }
        >
          <div>
            <RatingStrongYes
              fill="#BBC2C9"
              svgIconCLass={classNames(
                  "strong-yes",
                  initialScore === 5 ? "active" : ""
                )}
              onClick={() => {
                  if (isEditable) onChange(5);
                }}
            />
          </div>
        </TooltipInfo>
      </div>
    </div>
  );
};

InterviewScorecardItem.defaultProps = {
  description: "",
  handleUpdate: () => {},
  id: "",
  isEditable: false,
  isRequired: false,
  hasOptional: false,
  score: 0,
  text: "",
  text_markdown: ""
};

InterviewScorecardItem.propTypes = {
  description: PropTypes.string,
  handleUpdate: PropTypes.func,
  id: PropTypes.string,
  isEditable: PropTypes.bool,
  isRequired: PropTypes.bool,
  hasOptional: PropTypes.bool,
  score: PropTypes.number,
  text: PropTypes.string,
  text_markdown: PropTypes.string
};

export default InterviewScorecardItem;
