/* eslint-disable camelcase */
import React from "react";
import { NoSummaryIcon } from "mixins/svgIcons";
import { useTranslation } from "react-i18next";

const NoSummaryHistory = () => {
  const isCTAEnabled = false;

  const { t } = useTranslation();

  const handleSummaryNow = () => {
    console.log("Summarise Now");
  };

  return (
    <div className="no-transcriptions__container pt-12 md:pt-0">
      <div className="no-transcriptions__icon">
        <NoSummaryIcon />
      </div>

      <span className="no-transcriptions__text">
        {t("summary.noSummary")}
      </span>

      {
        isCTAEnabled ? (
          <button
            type="button"
            onClick={handleSummaryNow}
            className="no-transcriptions__transcribe button__without-styles"
            aria-label="Summarise Now"
          >
            {t("summary.summariseNow")}
          </button>
        ) : null
      }
    </div>
  );
};

export default NoSummaryHistory;
