import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip
} from "recharts";

import { selectListDatesWithTranslations } from "mixins/selectRanges";

import DropdownIndicator from "components/Forms/Select/dropdown";

const Candidates = ({
  t,
  setCurrentDateRange,
  companyCandidates = {},
  companyCandidates: {
    started_total: invitedTotal = 0,
    received_total: receivedTotal = 0,
    received_percentage: receivedPersent = 0
  },
  currentDateRange
}) => {
  const [selectValue, setSelectValue] = useState({});

  useEffect(() => {
    setSelectValue(currentDateRange);
  }, [currentDateRange]);

  const data = useMemo(() => companyCandidates.chart_data, [
    companyCandidates.chart_data
  ]);

  const hadleCurrentDateRange = dateRange => {
    setCurrentDateRange(dateRange);
    setSelectValue(dateRange);
  };

  return (
    <div className="comapany__chart">
      <header className="comapany__chart-header">
        <div className="comapany__chart-labels-wrapper">
          <p className="comapany__chart-label">
            <span
              className="
                comapany__chart-label-text
                n-font-extra-small
                n-uppercase
                n-purple-100
              "
            >
              {t("dashboard.chart.started")}
            </span>

            <span
              className="
                comapany__chart-label-data
                n-font-small
                n-lowercase
                n-black-100
              "
            >
              {`${invitedTotal || 0}  ${t("dashboard.chart.people")}`}
            </span>
          </p>

          <p className="comapany__chart-label">
            <span
              className="
                comapany__chart-label-text
                n-font-extra-small
                n-uppercase
                n-purple-100
              "
            >
              {t("dashboard.chart.responded")}
            </span>
            <span
              className="
                comapany__chart-label-data
                n-font-small
                n-lowercase
                n-black-100
              "
            >
              {`${receivedTotal || 0}
                ${t("dashboard.chart.answers")} (${(
                receivedPersent * 100
              ).toFixed(2)}%)`}
            </span>
          </p>
        </div>

        <Select
          className="comapany__chart-select"
          classNamePrefix="select"
          value={selectValue}
          onChange={hadleCurrentDateRange}
          options={selectListDatesWithTranslations(t)}
          components={{ DropdownIndicator }}
          isSearchable={false}
        />
      </header>

      <div className="comapany__chart-body">
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 20,
              left: 20,
              bottom: 5
            }}
          >
            <CartesianGrid horizontal={false} />

            <XAxis
              label={<span>{}</span>}
              axisLine={false}
              tickLine={false}
              tick={{
                stroke: "#8D9AA9",
                strokeWidth: 0.5,
                fontSize: 12,
                fontWeight: 300
              }}
              minTickGap={5}
              dataKey="name"
            />

            <YAxis hide />

            <Tooltip
              labelStyle={{
                marginBottom: 10
              }}
              itemStyle={{
                padding: 0,
                textAlign: "left"
              }}
            />

            <Line
              type="monotone"
              name={t("dashboard.chart.started")}
              dataKey="started"
              stroke="#5A2AF1"
              fill="#5A2AF1"
              activeDot={{ r: 4 }}
            />

            <Line
              type="monotone"
              name={t("dashboard.chart.responded")}
              dataKey="received"
              stroke="#595959"
              fill="#595959"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

Candidates.defaultProps = {
  setCurrentDateRange: undefined,
  currentDateRange: null,
  companyCandidates: null
};

Candidates.propTypes = {
  t: PropTypes.func.isRequired,
  setCurrentDateRange: PropTypes.func,
  currentDateRange: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  companyCandidates: PropTypes.shape({
    started_total: PropTypes.number,
    received_total: PropTypes.number,
    received_percentage: PropTypes.string,
    chart_data: PropTypes.arrayOf(
      PropTypes.shape({
        invited: PropTypes.number,
        received: PropTypes.number,
        name: PropTypes.string,
        day: PropTypes.string
      })
    )
  })
};

export default withTranslation()(Candidates);
