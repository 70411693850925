import { getCurrentUser } from "store/modules/users/selectors";

export const getProfileInfo = state => state?.profile?.profileInfo || {};

export const getIsSubscriptionActive = state => {
  const { is_pro_account: isProAccount } = getCurrentUser(state);
  if (isProAccount) return true;

  const { subscriptions = [] } = getProfileInfo(state);
  if (!subscriptions?.length) return false;

  return subscriptions.some(plan => plan.is_active || (plan.plan_name === "Willo plan" && plan.trial));
};

export const getIsLockedCardWithActiveSubscription = state => {
  const {
    is_annual_subscription: isAnnualSubscription,
    is_monthly_subscription: isMonthlySubscription
  } = getCurrentUser(state);
  const { is_agency_locked: isLocked } = state?.notifications || {};

  return isLocked && (isAnnualSubscription || isMonthlySubscription);
};

export const getIsSubscriptionUnpaid = state => {
  const { is_subscription_unpaid: isSubscriptionUnpaid } = state?.notifications || {};

  return isSubscriptionUnpaid;
};

export const getCardInfoLoading = state => state.profile.isCardInfoLoading;

export const getLanguage = state => state.profile.profileInfo?.language;

export const getIsAgencyUpdated = state => state?.profile?.isAgencyUpdated;
