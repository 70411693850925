import { handleActions, combineActions } from "redux-actions";

import {
  fetchPeopleResponse,
  fetchPeopleError,
  setPeopleLoading,
  fetchLatestPeopleResponse,
  fetchPeopleFiltersResponse,
  fetchPeopleFiltersError,
  setPeopleFiltersLoading,
  fetchLatestPeopleError,
  setLatestPeopleLoading,
  fetchLatestPeopleFilterCompaniesResponse,
  fetchLatestPeopleFilterJobsResponse,
  fetchLatestPeopleFilterOwnersResponse,
  setLatestPeopleFilterCompaniesLoading,
  setLatestPeopleFilterJobsLoading,
  setLatestPeopleFilterOwnersLoading,
  fetchSearchPeopleFilterResponse,
  setSearchPeopleFilterLoading,
  setLatestPeopleFilterRatingsLoading,
  setLatestPeopleFilterStatusesLoading,
  setPeoplesPageSelectedFilter,
  resetPeoplesPageSelectedFilter
} from "./actions";

const defaultState = {
  tablesData: [],
  tablesFilters: {},
  filtersPaginationData: {},
  isLoading: false,
  isLatestLoading: false,
  isFiltersLoading: false,
  isSearchFilterLoading: false,
  error: null,
  selectedFilter: {
    title: "",
    companies: [],
    jobs: [],
    owners: [],
    ratings: [],
    statuses: []
  }
};

export default handleActions(
  {
    [fetchPeopleResponse]: (state, { payload }) => ({
      ...state,
      tablesData: payload
    }),
    [fetchPeopleFiltersResponse]: (state, { payload }) => ({
      ...state,
      tablesFilters: payload.filters,
      filtersPaginationData: payload.filtersPaginationData
    }),
    [combineActions(
      fetchPeopleError,
      fetchLatestPeopleError,
      fetchPeopleFiltersError
    )]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [setPeopleLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [fetchLatestPeopleResponse]: (state, { payload: { next, previous, count, results } }) => ({
      ...state,
      tablesData: { next, previous, count, results: [...state.tablesData.results, ...results] }
    }),
    [fetchLatestPeopleFilterCompaniesResponse]: (state, { payload }) => ({
      ...state,
      tablesFilters: {
        ...state.tablesFilters,
        companies: [...state.tablesFilters.companies, ...payload.results],
        companiesSelected: payload?.selected
      },
      filtersPaginationData: {
        ...state.filtersPaginationData,
        companiesNext: payload.next
      }
    }),
    [fetchLatestPeopleFilterJobsResponse]: (state, { payload }) => ({
      ...state,
      tablesFilters: {
        ...state.tablesFilters,
        jobs: [...state.tablesFilters.jobs, ...payload.results],
        jobsSelected: payload?.selected
      },
      filtersPaginationData: {
        ...state.filtersPaginationData,
        jobsNext: payload.next
      }
    }),
    [fetchLatestPeopleFilterOwnersResponse]: (state, { payload }) => ({
      ...state,
      tablesFilters: {
        ...state.tablesFilters,
        owners: [...state.tablesFilters.owners, ...payload.results],
        ownersSelected: payload?.selected
      },
      filtersPaginationData: {
        ...state.filtersPaginationData,
        ownersNext: payload.next
      }
    }),
    [fetchSearchPeopleFilterResponse]: (state, { payload }) => ({
      ...state,
      tablesFilters: {
        ...state.tablesFilters,
        ...payload.filters
      },
      filtersPaginationData: {
        ...state.filtersPaginationData,
        ...payload.filtersPaginationData
      }
    }),
    [setLatestPeopleLoading]: (state, { payload }) => ({
      ...state,
      isLatestLoading: payload
    }),
    [setPeopleFiltersLoading]: (state, { payload }) => ({
      ...state,
      isFiltersLoading: payload
    }),
    [setLatestPeopleFilterCompaniesLoading]: (state, { payload }) => ({
      ...state,
      isFilterCompaniesLoading: payload
    }),
    [setLatestPeopleFilterRatingsLoading]: (state, { payload }) => ({
      ...state,
      isFilterRatingsLoading: payload
    }),
    [setLatestPeopleFilterStatusesLoading]: (state, { payload }) => ({
      ...state,
      isFilterStatusesLoading: payload
    }),
    [setLatestPeopleFilterJobsLoading]: (state, { payload }) => ({
      ...state,
      isFilterJobsLoading: payload
    }),
    [setLatestPeopleFilterOwnersLoading]: (state, { payload }) => ({
      ...state,
      isFilterOwnersLoading: payload
    }),
    [setSearchPeopleFilterLoading]: (state, { payload }) => ({
      ...state,
      isSearchFilterLoading: payload
    }),
    [setPeoplesPageSelectedFilter]: (state, { payload }) => ({
      ...state,
      selectedFilter: {
        ...state.selectedFilter,
        [payload.field]: payload.value
      }
    }),
    [resetPeoplesPageSelectedFilter]: state => ({
      ...state,
      selectedFilter: defaultState.selectedFilter
    })
  },
  defaultState
);
