import React from "react";
import { isMobile } from "react-device-detect";
import { Container, Navbar } from "react-bootstrap";

import logo from "assets/images/icons/logo-blue.png";

const OnboardingHeader = () => (
  <div className="header">
    <Container fluid={isMobile}>
      <Navbar>
        <Navbar.Brand>
          <img src={logo} alt="logo" className="header__logo" />
        </Navbar.Brand>
      </Navbar>
    </Container>
  </div>
);

OnboardingHeader.propTypes = {
};

export default OnboardingHeader;
