export default {
  email: {
    label: "emailLabel",
    icon: "Mail",
    elementType: "input",
    elementConfig: {
      type: "email",
      placeholder: "emailPlaceholder",
      disabled: false
    },
    value: "",
    validation: {
      required: true,
      isEmail: true
    },
    errorMessage: "emailError",
    valid: false,
    touched: false
  }
};
