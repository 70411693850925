import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import MobilePlayer from "components/Video/Recording/Mobile/Player";
import Message from "components/Common/Message";
import Buttons from "components/Video/Recording/Buttons";
import QuestionsText from "components/Common/QuestionsText";
import QuestionTitle from "components/Common/QuestionTitle";
import { INITIAL_DURATION, INITIAL_RETAKES } from "configs/jobs/constants";

import { isRetakePromptsEnabled } from "mixins/helperVideoRecording";
import WithHooks from "./withHooks";
import ButtonsCaption from "../ButtonsCaption";

const VideoRecording = ({
  t,
  isRecording,
  isCompleted,
  isShowPlayButton,
  isTestMode,
  question,
  question: {
    max_duration: maxDuration = INITIAL_DURATION,
    max_retakes: maxRetakes = INITIAL_RETAKES,
    attempt_number: attemptNumber = 0,
    thinking_time: thinkingTime
  } = {},
  spentTime,
  memorizedCompanyInfo,
  cashedVideo,
  currentQuestionNumber,
  warningMessage,
  remoteLink,
  videoBlob,
  startRecord,
  finishRecord,
  playCachedVideo,
  savedAnswers,
  onFileUpload,
  videoPlayer,
  videoInput,
  navigateToNextStep,
  savedAnswer,
  showLoadingBtn
}) => {
  const [hasEBML, setHasEBML] = useState(true);
  const [isEBMLCalled, setIsEBMLCalled] = useState(false);

  useEffect(() => {
    if (typeof EBML === "undefined") {
      const script = document.createElement("script");
      script.src = "https://www.webrtc-experiment.com/EBML.js";
      script.async = true;

      script.onload = () => {
        console.log("EBML is successfully loaded and ready to use!", typeof EBML !== "undefined");
        setHasEBML(typeof EBML !== "undefined");
      };

      script.onerror = () => {
        setHasEBML(false);
        setIsEBMLCalled(true);
      };

      document.body.appendChild(script);
    } else {
      setIsEBMLCalled(true);
      setHasEBML(true);
    }
  }, []);

  return (
    <>
      <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
        <QuestionTitle
          questionNumber={currentQuestionNumber}
          thinkingTime={thinkingTime}
          spentTime={spentTime}
        />

        <QuestionsText text={isTestMode ? t("candidate.common.howAreYou") : question?.html_text} />

        <Buttons
          navigateToNextStep={navigateToNextStep}
          remoteLink={isRetakePromptsEnabled ? savedAnswer : remoteLink}
          isRecording={isRecording}
          isCompleted={isCompleted}
          startRecord={startRecord}
          finishRecord={finishRecord}
          blobUrl={cashedVideo}
          memorizedCompanyInfo={memorizedCompanyInfo}
          max_retakes={maxRetakes}
          attempt_number={attemptNumber}
          isBlobEmpty={!cashedVideo}
          isSuccessRecord
          isLoadingVisible={false}
          showLoadingBtn={showLoadingBtn}
          hasEBML={hasEBML}
        />

        <ButtonsCaption
          initialElapsingTime={maxDuration * 1000}
          max_retakes={maxRetakes}
          attempt_number={attemptNumber}
        />
      </Col>

      <Col md={{ span: 6, order: 2 }} xs={{ span: 12, order: 1 }}>
        {warningMessage && <Message message={warningMessage} error />}
        <MobilePlayer
          isShowPlayButton={isShowPlayButton}
          videoBlob={videoBlob}
          question={question}
          savedAnswers={savedAnswers}
          playCachedVideo={playCachedVideo}
          onFileUpload={onFileUpload}
          videoPlayerRef={videoPlayer}
          videoInputRef={videoInput}
          cashedVideo={cashedVideo}
          remoteLink={remoteLink}
          isEBMLCalled={isEBMLCalled}
          hasEBML={hasEBML}
        />
      </Col>
    </>
  );
};

VideoRecording.defaultProps = {
  isRecording: undefined,
  isCompleted: undefined,
  isShowPlayButton: undefined,
  history: undefined,
  remoteLink: undefined,
  warningMessage: undefined,
  currentQuestionNumber: undefined,
  question: undefined,
  memorizedCompanyInfo: undefined,
  videoPlayer: undefined,
  videoInput: undefined,
  savedAnswers: [],
  videoBlob: undefined,
  cashedVideo: undefined,
  startRecord: undefined,
  finishRecord: undefined,
  playCachedVideo: undefined,
  onFileUpload: undefined,
  navigateToNextStep: undefined,
  isTestMode: false,
  savedAnswer: undefined,
  showLoadingBtn: undefined
};

VideoRecording.propTypes = {
  t: PropTypes.func.isRequired,
  isRecording: PropTypes.bool,
  isCompleted: PropTypes.bool,
  isShowPlayButton: PropTypes.bool,
  isTestMode: PropTypes.bool,
  history: PropTypes.shape({
    length: PropTypes.number
  }),
  remoteLink: PropTypes.string,
  warningMessage: PropTypes.string,
  currentQuestionNumber: PropTypes.number,
  question: PropTypes.shape({
    key: PropTypes.string,
    html_text: PropTypes.string,
    max_duration: PropTypes.number,
    max_retakes: PropTypes.number,
    attempt_number: PropTypes.number
  }),
  spentTime: PropTypes.number.isRequired,
  memorizedCompanyInfo: PropTypes.shape({}),
  videoPlayer: PropTypes.shape({
    current: PropTypes.shape({})
  }),
  videoInput: PropTypes.shape({
    current: PropTypes.shape({})
  }),
  savedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      remote_link: PropTypes.string
    })
  ),
  videoBlob: PropTypes.shape({}),
  cashedVideo: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  startRecord: PropTypes.func,
  finishRecord: PropTypes.func,
  playCachedVideo: PropTypes.func,
  onFileUpload: PropTypes.func,
  navigateToNextStep: PropTypes.func,
  savedAnswer: PropTypes.shape({}),
  showLoadingBtn: PropTypes.func
};

export default withTranslation()(WithHooks(VideoRecording));
