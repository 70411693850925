import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getNotifications } from "store/modules/notifications/selectors";
import { getUsersError, getUsersLoading, getCurrentUserId, getCurrentUser } from "store/modules/users/selectors";
import { fetchNotifications, setCompletedOnboarding } from "store/modules/notifications/actions";
import { updateAgency, addNewUsers, clearUserError } from "store/modules/users/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";
import Onboarding from "views/Onboarding";

const mapStateToProps = state => ({
  agencyKey: getCurrentUserId(state),
  notifications: getNotifications(state),
  isLoadingScreen: state.widgets.isLoading,
  error: getUsersError(state),
  isUsersLoading: getUsersLoading(state),
  currentUser: getCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  updateAgency: payload => dispatch(updateAgency(payload)),
  addNewUsers: payload => dispatch(addNewUsers(payload)),
  setLoadingScreen: payload => dispatch(setLoadingScreen(payload)),
  clearUserError: () => dispatch(clearUserError()),
  fetchNotifications: payload => dispatch(fetchNotifications(payload)),
  setCompletedOnboarding: payload => dispatch(setCompletedOnboarding(payload))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Onboarding)
);
