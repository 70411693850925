import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Buttons = ({
  t,
  handlePrevStep,
  handleNextStep
}) => (
  <>
    <button
      type="button"
      className="button__without-styles n-grey-100 n-font-large n-font-medium-weight onboard__back"
      onClick={handlePrevStep}
    >
      {`< ${t("button.back")}`}
    </button>
    <button
      type="button"
      className="button__without-styles n-grey-100 n-font-large n-font-medium-weight onboard__skip"
      onClick={handleNextStep}
    >
      {t("button.skip")}
    </button>
  </>
);

Buttons.propTypes = {
  t: PropTypes.func.isRequired,
  handlePrevStep: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired
};

export default withTranslation()(Buttons);
