import React, { useState, useMemo, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Close } from "mixins/svgIcons";
import Message from "components/Common/Message";

import { Error } from "./svgIcons";

const rEmail =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

const InviteUsers = ({
  t,
  invites,
  setInvites,
  inviteError,
  addNewUsersError
}) => {
  const [newInvite, setNewInvite] = useState("");

  useEffect(() => {
    addNewUsersError("");
  }, []);

  const handleInput = e => {
    e.preventDefault();
    if (newInvite) {
      if (
        invites.every(({ email }) => newInvite.toLowerCase() !== email.toLowerCase())
      ) {
        const isValid = rEmail.test(newInvite);
        setInvites([...invites, {
          email: newInvite,
          isValid
        }]);
      }
      setNewInvite("");
    }
  };

  const handleChange = event => {
    setNewInvite(event.target.value);
    addNewUsersError("");
  };
  const handleKeyDown = e => {
    if (["Enter", ";", ",", " "].includes(e.key)) handleInput(e);
  };
  const handleDelete =
    email => setInvites(invites.filter(invite => invite.email !== email));
  const removeNotValidEmails =
    () => setInvites(invites.filter(invite => invite.isValid));
  const errorCounter =
    useMemo(() => invites.filter(({ isValid }) => !isValid).length, [invites]);

  return (
    <>
      <div className="onboard__invites-textarea">
        {invites.map(({ email, isValid }) => (
          <div className={`onboard__invites-email ${isValid ? "" : "error"}`}>
            {!isValid && (
              <div className="onboard__invites-email-error">
                <Error />
              </div>
            )}
            <p>{email}</p>
            <div role="button" onKeyPress={null} tabIndex={0} onClick={() => handleDelete(email)} className="onboard__invites-email-close">
              <Close fill="#BDC0C8" width="14" height="14" />
            </div>
          </div>
        ))}
        <input
          className="n-font-small onboard__invites-input"
          placeholder={t("input.inviteUserPlaceholder")}
          value={newInvite}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onBlur={handleInput}
          type="text"
        />
      </div>
      {errorCounter ? (
        <p className="n-black-100 n-font-small onboard__invites-error-message">
          <Error />
          {t(`onboarding.removeAll.${errorCounter === 1 ? "0" : "1"}`, { counter: errorCounter })}
          <button type="button" className="button__without-styles n-blue-100" onClick={removeNotValidEmails}>
            {t("onboarding.removeAll.2")}
          </button>
        </p>
      ) : null}
      {inviteError ? (
        <Message error message={inviteError} />
      ) : null}
    </>
  );
};

InviteUsers.propTypes = {
  t: PropTypes.func.isRequired,
  invites: PropTypes.arrayOf.isRequired,
  setInvites: PropTypes.func.isRequired,
  inviteError: PropTypes.string.isRequired,
  addNewUsersError: PropTypes.func.isRequired
};

export default withTranslation()(InviteUsers);
