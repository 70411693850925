import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  saveCurrentQuestion,
  savePreviousQuestion,
  clearPreviousQuestion,
  retakeAnswer,
  saveIsAllAnswerSaved
} from "store/modules/сandidates/actions";
import { setModal } from "store/modules/modals/actions";
import {
  getSavedAnswers,
  getQuestionsForCandidate,
  getCandidateJobInfo,
  getCandidateJobInfoCompany,
  getIsAllAnsweredSaved,
  getPreviousQuestionId,
  getCurrentCandidateId,
  getCandidateToken
} from "store/modules/сandidates/selectors";

import { getIdvSession } from "store/modules/idv/actions";
import { getCurrentIdvSession } from "store/modules/idv/selectors";

import IDVSession from "views/Candidate/IDV/IDVSession";

import { withTranslation } from "react-i18next";

const mapStateToProps = state => ({
  questionsForCandidate: getQuestionsForCandidate(state),
  jobInfo: getCandidateJobInfo(state),
  jobInfoCompany: getCandidateJobInfoCompany(state),
  savedAnswers: getSavedAnswers(state),
  isAllAnsweredSaved: getIsAllAnsweredSaved(state),
  previousQuestionId: getPreviousQuestionId(state),
  currentCandidateId: getCurrentCandidateId(state),
  idvSession: getCurrentIdvSession(state),
  token: getCandidateToken(state)
});

const mapDispatchToProps = {
  saveCurrentQuestion,
  savePreviousQuestion,
  setModal,
  clearPreviousQuestion,
  retakeAnswer,
  getIdvSession,
  saveIsAllAnswerSaved
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IDVSession))
);
