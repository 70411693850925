import React from "react";
import PropTypes from "prop-types";

const CardField = ({
  label,
  value
}) => (
  <div className="checkout-field">
    <span className="checkout-label">{label}</span>
    <span className="checkout-value">{value}</span>
  </div>
);

CardField.defaultProps = {
  value: undefined
};

CardField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default CardField;
