/* eslint-disable react/jsx-one-expression-per-line */
import React from "react";

import { Container, Col, Row } from "react-bootstrap";

import PageWrapper from "hoc/PageWrapper";
import Header from "components/Common/Header/Main";

const PrivacyPolicy = () => (
  <PageWrapper header={<Header />}>
    <Container>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <div className="document">
            <h1>Privacy Policy</h1>
            <p>
              <span>Who are we?</span>
              We are weeve technologies limited. Right now we are carrying out
              market research to make sure our platform will be the best it can
              be for people like you. We’re committed to protecting and
              respecting your privacy. If you have any questions about your
              personal information please contact euan@weeve.co
            </p>

            <p>
              <span>What information we hold about you</span>
              Your email address
              <br />
              Demographic information from Facebook, Instagram and Google
              Analytics
              <br />
              Answers you may have given to the survey on our website
              <br />
            </p>

            <p>
              <span>We use this information to</span>
              Contact you in future
              <br />
              Analyse how we can help build the most helpful service possible
              <br />
              Analyse and design the best way to contact people like you that we
              would like to have as our customers in the future
              <br />
            </p>

            <p>
              <span>Who will we share it with?</span>
              We may disclose your personal information to anyone who works for
              us as an employee or a consultant.
              <br />
              Third party services we use for storing information and market
              research – like Intercom, Mailchimp and TypeForm.
              <br />
            </p>

            <p>
              Authorities requiring us to share your personal data for us to
              comply with the law; or to enforce our Terms and Conditions; or to
              protect the rights, property, or safety of us or others.
            </p>

            <p>
              <span>Rights</span>
              You have a right to access the personal data we hold about you or
              to obtain a copy of it. To do so please contact us by emailing
              euan@weeve.co. We may charge you a small fee to cover our costs
              for providing this information.
            </p>

            <p>
              <span>What we will not do</span>
              We will not share identifiable personal data with third parties
              for their direct marketing purposes.
            </p>

            <p>
              We will not keep personal information longer than required. We
              will only keep your personal information for as long as we need
              to. We’ll then delete it securely and safely.
            </p>

            <p>
              <span>Where your data is stored</span>
              The data we collect from you may be transferred to and stored
              somewhere outside the European Economic Area (EEA). It may also be
              processed by our suppliers outside the EEA.
            </p>

            <p>
              <span>Changes to this policyM</span>
              Any material changes we make to our privacy notice in the future
              will be posted on this page and, if appropriate, sent to you by
              email.
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </PageWrapper>
);

export default PrivacyPolicy;
