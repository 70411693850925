import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import "./styles.scss";
import { getTotalJobsAndStagesCount } from "mixins/helpers";
import { store } from "store";
import { updateConnectedAtsJobsAndStages } from "store/modules/jobs/actions";
import { BeatLoader } from "react-spinners";
import { setModal } from "store/modules/modals/actions";
import { isEqual } from "lodash";

const ConnectDropdownFooter = ({
  t,
  atsDetails,
  isMobileOrTabletScreen,
  jobId,
  agencyKey,
  selectedJobsAndStages,
  reset
}) => {
  const total = getTotalJobsAndStagesCount(selectedJobsAndStages);
  const [btnClicked, setBtnClicked] = useState(null);

  const isChanged = useMemo(() => {
    if (selectedJobsAndStages?.length !== atsDetails?.savedJobsAndStages?.length) return true;

    const saved = atsDetails?.savedJobsAndStages?.map(({ key, ...rest }) => rest);
    const selected = selectedJobsAndStages?.map(({ key, ...rest }) => rest);

    const sortedSAved = saved?.sort(
      (a, b) => {
        if (
          a.remote_interview_id < b.remote_interview_id) return -1;

        if (a.remote_interview_id > b.remote_interview_id) return 1;

        return 0;
      }
    );

    const sortedSelected = selected?.sort(
      (a, b) => {
        if (
          a.remote_interview_id < b.remote_interview_id) return -1;

        if (a.remote_interview_id > b.remote_interview_id) return 1;

        return 0;
      }
    );

    return !isEqual(sortedSAved, sortedSelected);
  }, [atsDetails.savedJobsAndStages, selectedJobsAndStages]);

  const resetAllConnectedAtsJobsAndStages = async () => {
    store.dispatch(
      updateConnectedAtsJobsAndStages({
        selectedJobsAndStages: [],
        agencyKey,
        jobId,
        callbackFn: () => reset()
      })
    );
  };

  const handleReset = () => {
    setBtnClicked("reset");

    store.dispatch(setModal({
      type: "resetAtsJobsAndStages",
      userConfirmation: {
        onProceed: resetAllConnectedAtsJobsAndStages
      }
    }));
  };

  const handleApply = async () => {
    setBtnClicked("apply");
    store.dispatch(updateConnectedAtsJobsAndStages({
      selectedJobsAndStages,
      agencyKey,
      jobId,
      callbackFn: () => reset()
    }));
  };

  useEffect(() => () => {
    setBtnClicked(null);
  }, []);

  return (
    <div className="connect__dropdown__select-footer">
      {
        !isMobileOrTabletScreen && (
          <span className="connect__dropdown__select-footer-text">
            {t(`job.overview.connect.totalSelectedJob${total > 1 ? "s" : ""}`, { total })}
          </span>
        )
      }
      <div className="connect-dropdown__footer-buttons">
        <button
          type="button"
          onClick={handleReset}
          className={!selectedJobsAndStages?.length ? "disabled" : ""}
          disabled={!selectedJobsAndStages?.length}
        >
          {
            atsDetails?.isSubscribeToAtsJobLoading && btnClicked === "reset" ? (
              <BeatLoader color="#BBC2C9" size="10" loading />
            ) : (
              t("connectDropdown.clearAll")
            )
          }
        </button>
        <button
          type="button"
          className={`primary ${!isChanged ? "disabled" : ""}`}
          onClick={handleApply}
          disabled={!isChanged}
        >
          {
            atsDetails?.isSubscribeToAtsJobLoading && btnClicked === "apply" ? (
              <BeatLoader color="#BBC2C9" size="10" loading />
            ) : (
              t("connectDropdown.apply")
            )
          }
        </button>
      </div>
    </div>
  );
};

ConnectDropdownFooter.defaultProps = {
  atsDetails: undefined,
  isMobileOrTabletScreen: undefined,
  jobId: undefined,
  agencyKey: undefined,
  selectedJobsAndStages: undefined,
  reset: undefined
};

ConnectDropdownFooter.propTypes = {
  t: PropTypes.func.isRequired,
  isMobileOrTabletScreen: PropTypes.bool,
  jobId: PropTypes.string,
  agencyKey: PropTypes.string,
  atsDetails: PropTypes.shape({
    atsConnected: PropTypes.arrayOf(PropTypes.shape({})),
    isLoading: PropTypes.bool,
    availableJobs: PropTypes.shape({
      next: PropTypes.string,
      results: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    connectedJobs: PropTypes.arrayOf(PropTypes.shape({})),
    connectedJobsCount: PropTypes.number,
    availableStages: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string
    })),
    savedJobsAndStages: PropTypes.arrayOf(PropTypes.shape({})),
    isSubscribeToAtsJobLoading: PropTypes.bool
  }),
  selectedJobsAndStages: PropTypes.arrayOf(PropTypes.shape({})),
  reset: PropTypes.func
};

export default withTranslation()(ConnectDropdownFooter);
