/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
import Copy from "components/Tables/Jobs/Buttons/Copy";
import { formatStringtoDuration } from "mixins/helperVideoRecording";
import { copyToClipboard } from "mixins/helpers";
import { CopyTable } from "mixins/svgIcons";
import React, { MouseEvent, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { store } from "store";
import useMediaPlayerStore from "store/mediaPlayerStore";
import { setCurrentQuestionAnswer } from "store/modules/jobs/actions";
import { CandidateAnswerType } from "types/candidateAnswer.ts";
import { TranscriptionItemType, TranscriptionSearchItemType } from "types/transcription.ts";

const CandidateTranscriptionItem = ({
  transcription,
  currentQuestionAnswer,
  answer,
  scrollToAnswer,
  idx,
  isHighlighted
}: {
  transcription: TranscriptionItemType & TranscriptionSearchItemType,
  currentQuestionAnswer: CandidateAnswerType,
  answer: CandidateAnswerType,
  scrollToAnswer: (index: number, forcedScroll: boolean) => void,
  isHighlighted: boolean,
  idx: number
}) => {
  const { setCurrentTimeStamp } = useMediaPlayerStore();
  const [isCopied, setIsCopied] = useState(false);
  const [showToolTip, setShowToolTip] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringIcon, setIsHoveringIcon] = useState(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  // Helper function to escape special regex characters
  function escapeRegExp(string: string) {
    return string?.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  // Create a case-insensitive regex for multi-language support
  function createRegex(searchInput: string[] | undefined): RegExp | null {
    if (!searchInput || searchInput.length === 0) return null;
    const escapedPhrases = searchInput.map(escapeRegExp);
    return new RegExp(`(${escapedPhrases.join("|")})`, "gi");
  }

  const searchArray = transcription.highlights;
  const regex = createRegex(searchArray);

  // Split text into words, keeping punctuation intact
  const words = transcription.text.split(/(\s+)/); // This splits the text by spaces but keeps the spaces as part of the array

  const handleCopy = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsCopied(true);

    setShowToolTip(true);
    copyToClipboard(transcription.text);

    setTimeout(() => {
      setShowToolTip(false);
    }, 3000);
  };

  const handleBackToCurrent = () => {
    setCurrentTimeStamp(transcription.start_time);

    if (transcription?.question_key !== currentQuestionAnswer?.question?.key) {
      setTimeout(() => {
        store.dispatch(setCurrentQuestionAnswer(answer));
      }, 500);
    }

    scrollToAnswer(idx, true);
  };

  return (
    <div
      className="transcript-item"
      onMouseEnter={() => {
        if (!isMobile) setIsHovering(true);
      }}
      onMouseLeave={() => {
        if (!isMobile) setIsHovering(false);
      }}
      id={`transcription-item-${idx}`}
      key={`trans-item-${idx}`}
    >
      <div
        className="transcript-details"
        onClick={handleBackToCurrent}
      >
        <span
          className="transcript-timestamp"
          style={{
            backgroundColor: isHighlighted ? "#5A2AF11A" : "transparent",
            borderRadius: 16
          }}
        >
          {formatStringtoDuration(transcription.start_time, true)}
        </span>
        <div className="flex items-center flex-1">
          <div className={`flex-1 w-full leading-7 text-sm hover:text-main-purple ${isHighlighted ? "text-main-purple" : ""}`}>
            <span>
              {words.map((word, index) => (
                regex?.test(word) ? (
                  <mark
                    key={index}
                    style={{ background: "#5A2AF11A" }}
                    className="px-1 rounded-2xl text-main-purple"
                  >
                    {word}
                  </mark>
                ) : (
                  <span key={index}>{word}</span>
                )
              ))}
            </span>
          </div>
          <div className="transcript-action" ref={buttonRef}>
            <Copy
              handleCopy={handleCopy}
              isCopied={isCopied}
              isShowTooltip={showToolTip}
              successStyles={{ marginLeft: 5 }}
              copyBtn={<CopyTable
                style={{ marginLeft: 5, display: isHovering || isMobile ? "inline-block" : "none" }}
                svgIconClass="not-clickable-icon"
                fill={isHoveringIcon ? "#5A2AF1" : "#B6BDC5"}
                onMouseEnter={() => setIsHoveringIcon(true)}
                onMouseLeave={() => setIsHoveringIcon(false)}
              />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: {
  jobsCandidate: {
    currentQuestionAnswer: CandidateAnswerType
  }
}) => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer
});

export default connect(mapStateToProps)(CandidateTranscriptionItem);
