import React from "react";
import PropTypes from "prop-types";

const Layout = ({
  input,
  previews,
  submitButton,
  dropzoneProps,
  files
}) => (
  <div {...dropzoneProps}>
    {previews}

    {input}

    {files.length > 0 && submitButton}
  </div>
);

Layout.propTypes = {
  input: PropTypes.node.isRequired,
  previews: PropTypes.arrayOf(PropTypes.node).isRequired,
  submitButton: PropTypes.node.isRequired,
  dropzoneProps: PropTypes.shape({
    ref: PropTypes.any.isRequired,
    className: PropTypes.string.isRequired,
    style: PropTypes.shape({}),
    onDragEnter: PropTypes.func.isRequired,
    onDragOver: PropTypes.func.isRequired,
    onDragLeave: PropTypes.func.isRequired,
    onDrop: PropTypes.func.isRequired
  }).isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default Layout;
