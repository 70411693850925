import { create } from "zustand";
import { getRequestWithToken } from "sagas/api";
import { TranscriptionsType } from "types/transcription.ts";

export type TranscriptionsStoreType = {
  transcriptions: TranscriptionsType;
  searchString: string;
  currentState: string;
  originalTranscriptions: TranscriptionsType;
  setCurrentState: (val: string) => void;
  setSearchString: (value: string) => void;
  fetch: (id: string, token: string) => void;
  searchTranscription: (params: {
    id: string;
    search: string;
    token: string;
  }) => void;
  resetTranscript: () => void;
  activeRequestId: null | number
}

const initialState = {
  transcriptions: [],
  searchString: "",
  currentState: "",
  originalTranscriptions: [],
  activeRequestId: null
};

const useTranscriptionStore = create<TranscriptionsStoreType>((set, get) => ({
  ...initialState,
  setCurrentState: (val: string) => {
    set({
      currentState: val
    });
  },
  setSearchString: (value: string) => {
    set(state => ({
      searchString: value,
      transcriptions: value === "" ? state.originalTranscriptions : state.transcriptions
    }));
  },
  fetch: async (id: string, token: string) => {
    try {
      const res = await getRequestWithToken({
        url: `/api/transcripts/candidate/${id}/list/`,
        token
      }) as {
        data: TranscriptionsType
      };
      set({
        transcriptions: res.data,
        originalTranscriptions: res.data
      });
    } catch (err) {
      console.error(err);
      set({
        transcriptions: [],
        originalTranscriptions: []
      });
    }
  },
  searchTranscription: async ({
    id,
    search,
    token
  }: {
    search: string,
    id: string,
    token: string
  }) => {
    // Generate a unique ID for this search operation
    const requestId = Date.now();
    set({ currentState: "loading", activeRequestId: requestId });

    try {
      let res;

      if (!search) {
        res = await getRequestWithToken({
          url: `/api/transcripts/candidate/${id}/list/`,
          token
        }) as { data: TranscriptionsType };
      } else {
        res = await getRequestWithToken({
          url: `/api/transcripts/candidate/${id}/search/?search=${search}`,
          token
        }) as { data: TranscriptionsType };
      }

      // Check if this response is still relevant (matches the latest request ID)
      if (get().activeRequestId === requestId) {
        set({
          transcriptions: res.data,
          originalTranscriptions: search ? get().originalTranscriptions : res.data,
          currentState: "success"
        });
      }
    } catch (err) {
      // Reset to original transcriptions only if the request is still the latest one
      if (get().activeRequestId === requestId) {
        set(state => ({
          transcriptions: state.originalTranscriptions,
          currentState: "failed"
        }));
      }
      console.error(err);
    }
  },
  downloadTranscription: async (id: string, token: string) => {
    try {
      const res = await getRequestWithToken({
        url: `/api/transcripts/candidate/${id}/download/`,
        token,
        responseType: "arraybuffer"
      }) as {
        data: ArrayBuffer
      };

      const blob = new Blob([res.data], { type: "text/csv" });

      const link = document.createElement("a");
      const urlBlob = window.URL.createObjectURL(blob);
      link.href = urlBlob;

      link.setAttribute("download", "file.csv");

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(urlBlob); // Release memory
    } catch (err) {
      console.error(err);
    }
  },
  resetTranscript: () => set({ ...initialState })
}));

export default useTranscriptionStore;
