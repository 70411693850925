/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useImperativeHandle, forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Formik } from "formik";

import FormBootstrap from "react-bootstrap/Form";
import { PaperPlane } from "mixins/svgIcons";
import { editFormSchema } from "mixins/helperUsers";
import { ROLE_ADMIN, ROLE_STANDARD } from "configs/user/rolesConfig";

const AddNewUser = ({
  isCardInfoLoading,
  isUsersAdded,
  addNewUsers,
  setIsUsersAdded,
  clearUserError,
  clearBillingError,
  error,
  isUsersLoading
}, ref) => {
  const resetFormFunc = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      clearUserError();
    }
  }, []);

  useEffect(() => {
    setIsUsersAdded(false);
  }, [isUsersAdded, setIsUsersAdded]);

  const wrapEmailChangeHandler = handler => (...args) => {
    if (error) {
      clearUserError();
    }
    handler(...args);
  };

  const onSubmit = (values, { resetForm }) => {
    resetFormFunc.current = resetForm;

    const { email, role } = values;
    clearBillingError();
    addNewUsers({
      invites: [{
        email: email.toLowerCase(),
        role
      }]
    });
  };

  const initialValues = {
    email: "",
    role: ROLE_STANDARD,
    errors: {}
  };

  useImperativeHandle(
    ref,
    () => ({
      clearField: () => {
        if (resetFormFunc?.current) {
          resetFormFunc.current(initialValues);
        }
      }
    }),
    [resetFormFunc, initialValues]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={editFormSchema()}
      onSubmit={onSubmit}
      render={({
        values,
        errors,
        isValid,
        handleSubmit,
        handleChange,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit} className="add-new-user">
          <div className="add-new-user__input">
            <input
              value={values.email}
              type="text"
              name="email"
              className="add-new-user__input-field n-font-small n-bg-white n-black-100"
              placeholder={t("widgets.invite.title")}
              onChange={wrapEmailChangeHandler(handleChange)}
            />

            <button
              className="add-new-user__btn n-button__small n-bg-purple-100 n-white"
              disabled={!isValid || isCardInfoLoading || isUsersLoading}
              type="submit"
            >
              <PaperPlane svgIconClass="n-icon__small right" />
              {t("button.invite")}
            </button>
          </div>

          {errors.email ? (
            <span className="add-new-user__error n-font-extra-small n-red-100">
              {t("errors.validEmail")}
            </span>
          ) : null}
          {/* Errors form validation */}

          {error ? (
            <span className="add-new-user__error">{t(error)}</span>
          ) : null}

          {/* Error response from server */}
          <fieldset className="add-new-user__radio">
            <FormBootstrap.Group>
              <FormBootstrap.Check
                value={ROLE_STANDARD}
                checked={values.role === ROLE_STANDARD}
                type="radio"
                label={
                  <span>{t("widgets.invite.standard")}</span>
                }
                name="form-horizontal-radio"
                id="form-horizontal-radio-two"
                className="form-check"
                onChange={event => setFieldValue("role", event.target.value)}
              />

              <FormBootstrap.Check
                value={ROLE_ADMIN}
                checked={values.role === ROLE_ADMIN}
                type="radio"
                label={
                  <span>
                    <span>{t("widgets.invite.admin")}</span>

                  </span>
                }
                name="form-horizontal-radio"
                id="form-horizontal-radio-one"
                className="form-check"
                onChange={event => setFieldValue("role", event.target.value)}
              />
            </FormBootstrap.Group>
          </fieldset>
          <a className="mt-2" href="https://support.willo.video/article/39-add-or-remove-users" target="_blank">
            Learn more about roles and permissions
          </a>
        </form>
      )}
    />
  );
};

AddNewUser.defaultProps = {
  isCardInfoLoading: false,
  isUsersAdded: false,
  setIsUsersAdded: undefined,
  clearUserError: undefined,
  clearBillingError: undefined,
  error: undefined
};

AddNewUser.propTypes = {
  isCardInfoLoading: PropTypes.bool,
  isUsersAdded: PropTypes.bool,
  addNewUsers: PropTypes.func.isRequired,
  setIsUsersAdded: PropTypes.func,
  clearUserError: PropTypes.func,
  clearBillingError: PropTypes.func,
  error: PropTypes.string,
  isUsersLoading: PropTypes.bool.isRequired
};

export default forwardRef(AddNewUser);
