import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { refreshStatus, setLoadingScreen } from "store/modules/widgets/actions";
import ConnectionLost from "views/Common/ConnectionLost";

const mapStateToProps = state => ({
  isLoadingScreen: state.widgets.isLoadingMaintenance
});

const mapDispatchToProps = {
  refreshStatus,
  setLoadingScreen
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectionLost));
