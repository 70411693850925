import { handleActions, combineActions } from "redux-actions";

import { handleOnboardingResponse } from "store/modules/widgets/actions";

import {
  isAllCandidatesLoading,
  setDashboardDateRange,
  fetchAllJobsStatsResponse,
  fetchAllJobsStatsError,
  fetchAllCandidatesStatsResponse,
  fetchAllCandidatesStatsError,
  clearError
} from "./actions";

const defaultState = {
  currentDateRange: {
    label: "Select ...",
    value: "",
    onboarding: {}
  }
};

export default handleActions(
  {
    [isAllCandidatesLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [setDashboardDateRange]: (state, { payload }) => ({
      ...state,
      dateRange: payload
    }),
    [fetchAllJobsStatsResponse]: (state, { payload }) => ({
      ...state,
      jobs: payload
    }),
    [fetchAllCandidatesStatsResponse]: (state, { payload }) => ({
      ...state,
      candidates: payload
    }),
    [combineActions(fetchAllJobsStatsError, fetchAllCandidatesStatsError)]: (
      state,
      { payload }
    ) => ({
      ...state,
      error: payload
    }),
    [clearError]: state => ({
      ...state
    }),
    [handleOnboardingResponse]: (state, { payload }) => ({
      ...state,
      onboarding: payload
    })
  },
  defaultState
);
