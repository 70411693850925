import React from "react";
import { Formik, Field } from "formik";
import PropTypes from "prop-types";
import uuidv4 from "uuid/v4";

import Radio from "components/Forms/Inputs/Radio";

import { withdrawalQuestions } from "configs/jobs/rejectionConfig";

const Withdrawal = ({ t, proceed }) => (
  <Formik
    enableReinitialize
    initialValues={{
      reasonId: ""
    }}
    onSubmit={({ reasonId }) => {
      proceed({ widthrawal_type: reasonId });
    }}
  >
    {({ isValid, handleSubmit }) => (
      <form className="form__reject-details" onSubmit={handleSubmit}>
        {withdrawalQuestions.map(question => (
          <Field
            id={question.id}
            key={uuidv4()}
            component={Radio}
            name="reasonId"
            label={t(`status.user.Rejected.${question.id}`)}
          />
        ))}

        <div className="form__reject-details-button">
          <button
            disabled={!isValid}
            type="submit"
            className="
              n-button__medium
              n-bg-green-100
              n-white
            "
          >
            {t("button.save")}
          </button>
        </div>
      </form>
    )}
  </Formik>
);

Withdrawal.defaultProps = {
  proceed: undefined
};

Withdrawal.propTypes = {
  t: PropTypes.func.isRequired,
  proceed: PropTypes.func
};

export default Withdrawal;
