/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { history } from "store";

import { getFontColorFromBgColor, getWilloLogoFromBgColor } from "mixins/helperDynamicStyles";
import styled from "styled-components";
import { getPublicUserInfo } from "store/modules/public/selectors";
import { connect } from "react-redux";

const StyledLabel = styled("span")`
  color: ${props => props.color} !important
`;

const Job = ({
  t,
  company,
  className,
  pathname,
  publicCandidateInfo: {
    configuration: { showcase_promotions_enabled: showcasePromotionsEnabled } = {}
  } = {}
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const fontColor = getFontColorFromBgColor({ hex: company?.layout_color });
  const Logo = getWilloLogoFromBgColor(company?.layout_color, "header");

  return (
    <div
      className={`header header__candidate ${className}`}
      style={{ backgroundColor: company.layout_color }}
    >
      <Container>
        <Navbar>
          {company.logo !== undefined && (
            <Navbar.Brand>
              <img
                src={company.logo === null ? Logo : company.logo}
                alt="logo"
                className={company?.logo ? "header__custom_logo" : "header__logo"}
              />
            </Navbar.Brand>
          )}
          {pathname ? (
            <button
              className="n-font-medium n-grey navbar-signin candidate button__without-styles"
              style={isHovering
                ? { backgroundColor: "#FFF", border: "1px solid #5a2af1" }
                : { border: `1px solid ${fontColor}` }
              }
              type="button"
              onClick={() => history.push(pathname)}
              onPointerEnter={() => setIsHovering(true)}
              onPointerLeave={() => setIsHovering(false)}
            >
              <StyledLabel
                color={isHovering ? "#5a2af1" : fontColor}
              >
                {showcasePromotionsEnabled ? t("link.startYourFreeTrial") : t("link.sign")}
              </StyledLabel>
            </button>
          ) : null}
        </Navbar>
      </Container>
    </div>
  );
};

Job.defaultProps = {
  jobMainInfo: {},
  company: {},
  className: "",
  pathname: null
};

Job.propTypes = {
  t: PropTypes.func.isRequired,
  pathname: PropTypes.string,
  jobMainInfo: PropTypes.shape({
    description: PropTypes.string,
    salary: PropTypes.string,
    title: PropTypes.string,
    video_link: PropTypes.string
  }),
  company: PropTypes.shape({
    logo: PropTypes.string,
    layout_color: PropTypes.string,
    button_color: PropTypes.string
  }),
  className: PropTypes.string
};

const mapStateToProps = state => ({
  publicCandidateInfo: getPublicUserInfo(state)
});

export default connect(mapStateToProps)(withTranslation()(Job));
