import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Select from "react-select";

import DropdownIndicator from "components/Forms/Select/dropdown";

const SelectInput = ({
  t,
  name,
  disabled,
  onMenuOpen,
  inputChangedHandler,
  formElement: {
    value,
    touched,
    valid,
    prefix,
    label,
    bottomLabel,
    options,
    elementType,
    elementConfig,
    errorMessage
  },
  className,
  isLoading,
  components,
  IconLabel,
  RightIconLabel,
  LabelRight,
  labelClasses,
  titleClasses
}) => {
  const inputClasses = ["input"];
  const selectElementClasses = ["select"];

  if (prefix) {
    selectElementClasses.push(`select_${prefix}`);
  }

  if (!valid && touched) {
    selectElementClasses.push("invalid");
  }

  return (
    <div className={inputClasses}>
      {label && (
        <label htmlFor={elementType} className={`label ${String(labelClasses)}`}>
          <span className={`label__main ${String(titleClasses)}`}>
            {IconLabel && <IconLabel />}
            {t(`select.${[label]}`)}
            {RightIconLabel && <RightIconLabel />}
          </span>
          {LabelRight && <LabelRight />}
        </label>
      )}

      <div className={`input-wrapper ${className}`}>
        <Select
          {...elementConfig}
          placeholder={t(`select.${[elementConfig.placeholder]}`)}
          className={selectElementClasses.join(" ")}
          classNamePrefix="select"
          components={components}
          options={options}
          id={elementType}
          value={value}
          isDisabled={disabled}
          onMenuOpen={onMenuOpen}
          onChange={event => inputChangedHandler(event, name, elementType)}
          isLoading={isLoading}
        />
        {!valid && touched && (
          <p className="input-error-message">
            {errorMessage || t("select.jobCompanyError")}
          </p>
        )}
      </div>
      {bottomLabel && (
        <span className="label__bottom">{t(`select.${bottomLabel}`)}</span>
      )}
    </div>
  );
};

SelectInput.defaultProps = {
  name: undefined,
  disabled: undefined,
  formElement: undefined,
  onMenuOpen: undefined,
  inputChangedHandler: undefined,
  className: "",
  isLoading: false,
  components: { DropdownIndicator },
  IconLabel: null,
  RightIconLabel: null,
  LabelRight: null,
  labelClasses: "",
  titleClasses: ""
};

SelectInput.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  onMenuOpen: PropTypes.func,
  inputChangedHandler: PropTypes.func,
  formElement: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object
    ]),
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    prefix: PropTypes.string,
    label: PropTypes.string,
    bottomLabel: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    elementType: PropTypes.string,
    elementConfig: PropTypes.oneOfType([PropTypes.object]),
    errorMessage: PropTypes.string
  }),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  components: PropTypes.shape({}),
  IconLabel: PropTypes.node,
  RightIconLabel: PropTypes.node,
  LabelRight: PropTypes.node,
  labelClasses: PropTypes.string,
  titleClasses: PropTypes.string
};

export default withTranslation()(SelectInput);
