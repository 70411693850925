/* eslint-disable import/no-cycle */
/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable function-paren-newline */
import {
  takeEvery,
  select,
  put,
  take,
  call
} from "redux-saga/effects";

/* eslint-disable-next-line */
import { history } from "store";

import {
  fetchLeverConfiguration,
  fetchLeverConfigurationResponse,
  fetchLeverConfigurationError,
  fetchLeverStages,
  fetchLeverStagesResponse,
  fetchLeverStagesError,
  loginLever,
  loginLeverError,
  updateLeverStages,
  updateLeverStagesError,
  isLeverLoading,
  isLeverStagesLoading,
  setSavedStages
} from "store/modules/lever/actions";
import { getToken } from "store/modules/auth/selectors";
import { getCurrentUserId } from "store/modules/users/selectors";

import { activateKomboConnectWorker } from "./marketplace";
import { getRequestWithToken, postRequestWithToken } from "./api";

export function* fetchLeverConfigurationWorker({ payload }) {
  try {
    if (payload) {
      yield put(fetchLeverConfigurationResponse({}));
    }

    yield put(isLeverLoading(true));

    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const response = yield getRequestWithToken({
      url: `/api/integrations/lever/agency/${agencyKey}/webhook/`,
      token
    });

    yield put(fetchLeverConfigurationResponse({
      stages: response.data
    }));
  } catch (error) {
    console.log("[fetchLeverConfigurationError]", error);
    yield put(fetchLeverConfigurationError("There is a problem with loading lever configuration."));
  } finally {
    yield put(isLeverLoading(false));
  }
}

export function* fetchLeverStagesWorker({ payload: { serviceKey } = {} }) {
  try {
    yield put(isLeverStagesLoading(true));
    yield put(fetchLeverStagesResponse([]));

    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    const responseStages = yield getRequestWithToken({
      url: `/api/marketplace/integration/agency/${agencyKey}/service/${serviceKey}/stages/`,
      token
    });

    yield put(fetchLeverStagesResponse(responseStages.data));
  } catch (error) {
    console.log("[fetchLeverStagesError]", error);
    const errorText = error?.message?.response?.detail || "There is a problem with loading lever stages.";
    yield put(fetchLeverStagesError(errorText));
  } finally {
    yield put(isLeverStagesLoading(false));
  }
}

export function* loginLeverWorker({ payload: { code, serviceKey } }) {
  try {
    yield put(isLeverLoading(true));
    yield put(loginLeverError(""));

    const agencyKey = yield select(getCurrentUserId);

    yield call(activateKomboConnectWorker, {
      payload: {
        serviceKey,
        agencyKey,
        activationToken: code
      }
    });

    yield put(fetchLeverConfiguration());
  } catch (error) {
    console.log("[loginLeverError]", error);
    yield put(loginLeverError("There is a problem with login lever."));
    yield put(isLeverLoading(false));
  }
}

export function* updateLeverStagesWorker({ payload }) {
  try {
    yield put(isLeverStagesLoading(true));

    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    yield postRequestWithToken({
      url: `/api/integrations/lever/agency/${agencyKey}/webhook/`,
      token,
      payload: {
        stages: payload
      }
    });

    // refetch all configured stages
    yield put(fetchLeverConfiguration());
    yield take([fetchLeverConfigurationResponse, fetchLeverConfigurationError]);
    yield put(setSavedStages(true));
  } catch (error) {
    console.log("[updateLeverStagesError]", error);
    yield put(updateLeverStagesError("There is a problem with updating lever stages."));
  } finally {
    yield put(isLeverStagesLoading(false));
  }
}

export function* createLeverWatcher() {
  yield takeEvery(fetchLeverConfiguration, fetchLeverConfigurationWorker);
  yield takeEvery(loginLever, loginLeverWorker);
  yield takeEvery(updateLeverStages, updateLeverStagesWorker);
  yield takeEvery(fetchLeverStages, fetchLeverStagesWorker);
}

export default createLeverWatcher;
