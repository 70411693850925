/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isSafari, isMobile, isIOS, isAndroid } from "react-device-detect";
import WaveSurfer from "wavesurfer.js";
// eslint-disable-next-line import/extensions
import CursorPlugin from "wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js";

import TimerCountdown from "components/CountdownTimer";
import VideoStatus from "components/Video/Recording/VideoStatus";
import LoadingView from "components/Video/Recording/LoadingView";
import ErrorView from "components/Video/Recording/ErrorView";
import srcPoster from "assets/images/video/candidate-poster.jpg";
import { customTimeFormat, isRetakePromptsEnabled } from "mixins/helperVideoRecording";
import { connect } from "react-redux";

import "./styles.scss";
import { store } from "store";
import { updateVideoPreference } from "store/modules/сandidates/actions";
import getValidVolume from "utils/number";
import { getCandidateVolume } from "store/modules/сandidates/selectors";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import { BeatLoader } from "react-spinners";
import { isCurrentUserCandidate } from "mixins/helperCandidate";


import Wave from "./Wave";
import NoAudioPopUp from "./NoAudioPopUp";
import NoAudioSnackbar from "./NoAudioSnackbar";
import AudioAnimation from "./Wave/AudioAnimation";

const PlayerDesktop = ({
  t,
  isRecording,
  cashedVideo,
  savedAnswer,
  blobUrl,
  videoPlayerRef,
  clearTracks,
  cashedElapsingTime,
  isShowDelayMessage,
  isVideoPreview,
  videoSourceRef,
  isMediaBlocked,
  onlyAudio,
  streamRef,
  streamId,
  volume,
  hasAudio,
  displayAudioAnimation,
  audioContextSupported,
  isTestMode,
  updateFirebaseData,
  noAvailableRetakes = false,
  setShouldShowMissingMedia,
  shouldShowMissingMedia
}) => {
  const isShowControls = !isRecording && cashedVideo;
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isVideoPortrait, setIsVideoPortrait] = useState(!!onlyAudio);

  const isVideoExist = isRecording || cashedVideo;
  const isVideoEmpty = !isVideoExist;
  const hasNoRecordedVideo = isRetakePromptsEnabled && !isTestMode
    ? isEmpty(savedAnswer?.remote_link) : isVideoEmpty;
  const storeVolume = getValidVolume(volume);

  const [height, setHeight] = useState(
    isMobile && onlyAudio ? "240px" : "330px"
  );
  const wavesurfer = useRef(null);

  if (document.getElementById("audio") && (hasNoRecordedVideo || isRecording)) {
    document.getElementById("audio").style.display = "none";
  }

  useEffect(() => {
    if (onlyAudio) {
      wavesurfer.current = WaveSurfer.create({
        container: "#waveform",
        cursorColor: "#1E384B",
        progressColor: "#1E384B",
        waveColor: "#CAD0D6",
        barGap: 3,
        barHeight: 1.3,
        barMinHeight: 1,
        barRadius: 0,
        barWidth: 3,
        cursorWidth: 1,
        height: isMobile ? 200 : 276,
        mediaControls: true,
        backend: "MediaElement",
        plugins: [
          CursorPlugin.create({
            showTime: true,
            opacity: 1,
            customShowTimeStyle: {
              "background-color": "#000",
              color: "#fff",
              padding: "2px",
              "font-size": "10px"
            },
            formatTimeCallback: customTimeFormat
          })
        ]
      });
    }
  }, []);

  useEffect(() => {
    if (onlyAudio && (blobUrl || savedAnswer?.remote_link) && isRetakePromptsEnabled) {
      const srcLink = `${savedAnswer?.remote_link}#t=0.001?${savedAnswer?.updated_at}`;
      wavesurfer.current.load(srcLink);
    }
  }, [savedAnswer?.updated_at, savedAnswer?.remote_link]);

  useEffect(() => {
    if (onlyAudio && (blobUrl || savedAnswer?.remote_link) && !isRetakePromptsEnabled) {
      wavesurfer.current.load(blobUrl || savedAnswer?.remote_link);
    }
  }, [cashedVideo, savedAnswer, blobUrl]);

  wavesurfer?.current?.on("ready", () => {
    setHasLoaded(true);
  });

  const onVolumeChange = () => {
    if (onlyAudio) {
      const audio = document.querySelector("audio");
      store.dispatch(
        updateVideoPreference(
          audio.muted ? 0 : audio.volume === 0 ? 1 : audio.volume
        )
      );
    } else {
      const video = document.querySelector("video");
      store.dispatch(
        updateVideoPreference(
          video.muted ? 0 : video.volume === 0 ? 1 : video.volume
        )
      );
    }
  };

  useEffect(() => {
    if (onlyAudio) {
      const audio = document.querySelector("audio");
      if (audio) {
        audio.pause();
      }
    }
  }, [clearTracks, onlyAudio]);

  const getMediaHttpStatus = async () => {
    const response = await fetch(savedAnswer?.remote_link);

    if (![200, 201]?.includes(response?.status)) {
      setShouldShowMissingMedia(true);
      updateFirebaseData({ has_uploaded_media: true });
    }

    setHasLoaded(true);
  };

  const onError = () => {
    const video = document.querySelector("video");

    if (video?.readyState === 0 && noAvailableRetakes && (isAndroid ? !blobUrl : true) && !isIOS) {
      if (savedAnswer?.remote_link) {
        return getMediaHttpStatus();
      }

      setShouldShowMissingMedia(true);
      updateFirebaseData({ has_uploaded_media: true });
    }

    setHasLoaded(true);
  };

  useEffect(() => {
    if (onlyAudio) {
      const audio = document.querySelector("audio");
      if (audio) {
        audio.muted = storeVolume === 0;
        audio.volume = storeVolume > 1 ? 1 : parseFloat(storeVolume);

        audio.addEventListener("volumechange", onVolumeChange);

        return () => {
          audio.removeEventListener("volumechange", onVolumeChange);
        };
      }
    } else {
      const video = document.querySelector("video");

      if (isShowControls) {
        video.muted = storeVolume === 0;
        video.volume = storeVolume > 1 ? 1 : parseFloat(storeVolume);
      }

      video.addEventListener("volumechange", onVolumeChange);

      return () => {
        video.removeEventListener("volumechange", onVolumeChange);
      };
    }
  }, [storeVolume, isShowControls, onlyAudio, savedAnswer, blobUrl]);

  useEffect(() => {
    const video = document.querySelector("video");

    video.addEventListener("error", () => onError, true);

    return () => {
      video.removeEventListener("error", onError, true);
    };
  }, [isShowControls, savedAnswer, blobUrl]);

  const shouldUpdateFirebase = useMemo(() => hasLoaded && !!cashedVideo?.questionId && !!savedAnswer?.remote_link,
    [hasLoaded, cashedVideo?.questionId, savedAnswer?.remote_link]);

  useEffect(() => {
    const video = document.querySelector("video");
    const audio = document.querySelector("audio");

    // Define handlers outside to maintain reference
    const updateOnPlay = () => {
      if (shouldUpdateFirebase) {
        updateFirebaseData({ is_played: true });
      }
    };

    if (!onlyAudio && video) {
      video.addEventListener("playing", updateOnPlay);
    }

    if (onlyAudio && audio) {
      audio.addEventListener("playing", updateOnPlay);
    }

    return () => {
      if (video) video.removeEventListener("playing", updateOnPlay);
      if (audio) audio.removeEventListener("playing", updateOnPlay);
    };
  }, [shouldUpdateFirebase, onlyAudio]);

  const handleMetadata = () => {
    if (!onlyAudio) {
      const { clientWidth } = videoPlayerRef?.current || {};
      const clientHeight = Number(
        9 / 16 * clientWidth
      );
      const clientHeightPx = `${clientHeight}px`;
      const isPortrait = clientHeight > clientWidth;

      if (!onlyAudio) setIsVideoPortrait(isPortrait);
      setHeight(clientHeightPx);
    }
  };

  const isLoadingVisible =
    isVideoEmpty && !isVideoPreview && !isMediaBlocked && !isShowDelayMessage;

  const style = { height };

  const recordedProps = {
    autoPlay: true,
    preload: "auto"
  };

  return (
    <>
      {
        (!hasAudio && ((isVideoEmpty) || isRecording)) && audioContextSupported &&
        <NoAudioSnackbar t={t} hasAudio={hasAudio} />
      }
      <div
        className={`player ${hasLoaded && !isVideoPortrait ? "loaded-player" : ""}`}
        style={{
          ...style,
          ...isMobile ? {
            paddingTop: onlyAudio ? 0 : "56.25!important",
            aspectRatio: 16 / 9
          } : {}
        }}
      >
        {isRecording && (
          <VideoStatus
            label={t("candidate.videoCreate.playerRecording")}
            color="red"
          />
        )}

        {isVideoPreview && !isRecording && !cashedVideo && (
          <VideoStatus label={t("candidate.videoCreate.ready")} color="green" />
        )}

        {isRecording && (
          <TimerCountdown cashedElapsingTime={cashedElapsingTime} />
        )}
        {((!hasAudio && isRecording) || (hasNoRecordedVideo && !hasAudio)) && audioContextSupported && <NoAudioPopUp />}
        {isCurrentUserCandidate
          && hasAudio
          && (isRecording || (!isRecording && hasNoRecordedVideo)) && audioContextSupported && (

          <AudioAnimation
            showAnimation={displayAudioAnimation}
          />
        )}
        {
          !hasLoaded && !isRecording && (
            <div
              className="processing-wrapper"
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                background: "black",
                opacity: 0.7,
                zIndex: 2,
                top: 0
              }}
            >
              <BeatLoader color="#BBC2C9" size={10} margin={1} loading />
            </div>
          )
        }
        <video
          ref={videoPlayerRef}
          poster={isVideoEmpty ? srcPoster : ""}
          controls={isShowControls}
          controlsList="nodownload"
          muted
          onLoadedData={() => {
            // // android devices wont upload media until save & continue, so this should not be triggered
            if ((!isVideoEmpty) && typeof updateFirebaseData === "function" && !isAndroid) updateFirebaseData({ has_uploaded_media: true });

            setHasLoaded(true);
          }}
          onError={() => onError()}
          onLoadedMetadata={handleMetadata}
          style={{
            ...style,
            visibility: shouldShowMissingMedia ? "hidden" : "visible"
          }}
          playsInline
          {...savedAnswer?.remote_link && isRetakePromptsEnabled ? { ...recordedProps } : {}}
        >
          {isSafari && <source ref={videoSourceRef} type="video/mp4" onLoadedData={() => setHasLoaded(true)} />}
        </video>
        {onlyAudio ? (
          <>
            {streamRef.current &&
              ((isVideoPreview && !isRecording && !cashedVideo) || isRecording) ? (
                <Wave
                  streamRef={streamRef}
                  streamId={streamId}
                  hasLoaded={hasLoaded}
                />
              ) : null}
            <div id="waveform" className="player audio" />
          </>
        ) : null}

        {isShowDelayMessage && (
          <div className="job__delay">{isShowDelayMessage}</div>
        )}

        {isLoadingVisible && <LoadingView t={t} onlyAudio={onlyAudio} />}

        {isMediaBlocked && (
          <ErrorView t={t} message={isMediaBlocked} onlyAudio={onlyAudio} />
        )}
      </div>
    </>
  );
};

PlayerDesktop.defaultProps = {
  isRecording: false,
  cashedVideo: undefined,
  cashedElapsingTime: undefined,
  videoPlayerRef: undefined,
  videoDevices: [],
  videoDeviceId: undefined,
  isShowDelayMessage: undefined,
  videoSourceRef: null,
  streamId: undefined,
  blobUrl: undefined,
  savedAnswer: undefined,
  updateFirebaseData: undefined,
  noAvailableRetakes: false,
  shouldShowMissingMedia: undefined,
  setShouldShowMissingMedia: undefined,
  onSwitchCamera: undefined
};

PlayerDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  isRecording: PropTypes.bool,
  cashedVideo: PropTypes.shape({}),
  cashedElapsingTime: PropTypes.number,
  videoPlayerRef: PropTypes.shape({
    current: PropTypes.object
  }),
  volume: PropTypes.number.isRequired,
  videoDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ),
  videoDeviceId: PropTypes.string,
  onSwitchCamera: PropTypes.func,
  isShowDelayMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  isVideoPreview: PropTypes.bool.isRequired,
  videoSourceRef: PropTypes.shape({}),
  isMediaBlocked: PropTypes.bool.isRequired,
  onlyAudio: PropTypes.bool.isRequired,
  savedAnswer: PropTypes.shape({
    remote_link: PropTypes.string,
    updated_at: PropTypes.string
  }),
  blobUrl: PropTypes.string,
  streamId: PropTypes.string,
  streamRef: PropTypes.shape().isRequired,
  hasAudio: PropTypes.bool.isRequired,
  displayAudioAnimation: PropTypes.bool.isRequired,
  audioContextSupported: PropTypes.bool.isRequired,
  isTestMode: PropTypes.bool.isRequired,
  updateFirebaseData: PropTypes.func,
  audioDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired,
  noAvailableRetakes: PropTypes.bool,
  shouldShowMissingMedia: PropTypes.bool,
  clearTracks: PropTypes.bool.isRequired,
  setShouldShowMissingMedia: PropTypes.func
};

const mapStateToProps = state => ({
  volume: getCandidateVolume(state)
});
export default withRouter(connect(mapStateToProps)(withTranslation()(PlayerDesktop)));
