import React, { useState } from "react";
import PropTypes from "prop-types";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

import { SuccessIcon } from "mixins/svgIcons";
import { handleFileName } from "mixins/helperVideoRecording";

import Layout from "./Layout";

const minSteps = 6;
const maxSteps = 10;
const timeBetweenSteps = 100;
const bytesPerStep = 100000;

const DropzoneComponent = ({
  initialFiles,
  setRemoteLink,
  setName,
  setFile,
  setExtension,
  setSize,
  setStatus,
  classNames,
  InputComponent,
  PreviewComponent
}) => {
  const [bytesSent, setBytesSent] = useState(null);

  const uploadProgress = file => {
    const totalSteps = Math.round(Math.min(maxSteps, Math.max(minSteps, file.size / bytesPerStep)));
    const updateProgress = step => {
      const currBytesSent = (step + 1) * file.size / totalSteps;
      return () => setBytesSent(currBytesSent === file.size ? 0 : currBytesSent);
    };

    // eslint-disable-next-line no-plusplus
    for (let step = 0; step < totalSteps; step++) {
      const duration = timeBetweenSteps * (step + 1);
      setTimeout(updateProgress(step), duration);
    }
  };
  const handleChangeStatus = ({ file: newFile }, newStatus) => {
    const fileName = handleFileName(newFile.name);

    if (newStatus === "preparing") uploadProgress(newFile);
    if (newFile.name) {
      setName(fileName.name);
      setSize(newFile.size);
      setExtension(fileName.ext);
      if (setRemoteLink) setRemoteLink(null);
    }
    setStatus(newStatus);
    setFile(newFile);
  };

  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      onSubmit={null}
      multiple={false}
      maxSizeBytes={1024 * 1024 * 25}
      maxFiles={1}
      successIcon={<SuccessIcon svgIconClass="not-clickable-icon" />}
      InputComponent={InputComponent}
      PreviewComponent={props => <PreviewComponent {...props} bytesSent={bytesSent} />}
      LayoutComponent={Layout}
      initialFiles={initialFiles}
      classNames={classNames}
    />
  );
};

DropzoneComponent.defaultProps = {
  classNames: {},
  setRemoteLink: null,
  initialFiles: undefined
};

DropzoneComponent.propTypes = {
  initialFiles: PropTypes.arrayOf(PropTypes.shape({})),
  setRemoteLink: PropTypes.func,
  setName: PropTypes.func.isRequired,
  setFile: PropTypes.func.isRequired,
  setExtension: PropTypes.func.isRequired,
  setSize: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  InputComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  PreviewComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  classNames: PropTypes.shape({})
};

export default React.memo(
  DropzoneComponent,
  // eslint-disable-next-line arrow-body-style
  (prevProps, nextProps) => {
    return prevProps.initialFiles === nextProps.initialFiles &&
    prevProps.setRemoteLink === nextProps.setRemoteLink &&
    prevProps.setName === nextProps.setName &&
    prevProps.setFile === nextProps.setFile &&
    prevProps.setExtension === nextProps.setExtension &&
    prevProps.setSize === nextProps.setSize &&
    prevProps.setStatus === nextProps.setStatus &&
    prevProps.classNames === nextProps.classNames &&
    prevProps.mediaExtension === nextProps.mediaExtension &&
    prevProps.name === nextProps.name &&
    prevProps.handleSave === nextProps.handleSave &&
    prevProps.isEdit === nextProps.isEdit &&
    prevProps.toggleEdit === nextProps.toggleEdit &&
    prevProps.memorizedCompanyInfo === nextProps.memorizedCompanyInfo &&
    prevProps.status === nextProps.status;
  }
);
