/* eslint-disable react/prop-types */
import React from "react";
import { withTranslation } from "react-i18next";

const NameSearch = ({ t, name, setName }) => (
  <div className="name-search">
    <form className="form__search form__search_name">
      <input
        id="name-search"
        className="form__search-input name-search__input n-font-small n-grey-100"
        placeholder={t("input.candidateNamePlaceholder")}
        onChange={e => setName(e.target.value)}
        value={name}
        type="text"
      />
    </form>
  </div>
);

export default withTranslation()(NameSearch);
