/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";

const useRetryByTimes = (callback, errorCallback, times = 3, delay = 1000) => {
  const [refreshCount, setRefreshCount] = useState(0);
  const timer = useRef();

  useEffect(() => {
    if (refreshCount < times) {
      timer.current = setTimeout(() => {
        setRefreshCount(refreshCount + 1);
        callback();
      }, delay);
    }

    if (refreshCount === times && typeof errorCallback === "function") {
      errorCallback();
    }

    return () => clearInterval(timer.current);
  }, [refreshCount]);
};

export default useRetryByTimes;
