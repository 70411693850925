/* eslint-disable no-useless-escape */
/* eslint-disable operator-linebreak */
import * as Yup from "yup";
import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input";

import { DATE_FORMATS } from "configs/dates";

export const initialStateForAllInvite = {
  fileName: "",
  errors: {}
};

export const fileFormForAllInvite = () =>
  Yup.object().shape({
    fileName: Yup.mixed().test(
      "fileFormat",
      "errors.val",
      value => value && ["text/csv", "text/plain"].includes(value.type)
    )
  });

export const handleClassName = (className, error) => {
  const notString = !typeof className;

  if (notString) {
    return "";
  }

  if (error) {
    return `${className} invalid`;
  }

  return className;
};

export const initialStateForPersonalizedInvite = {
  name: "",
  email: "",
  phone: "",
  errors: {},
  touched: {}
};

export const schemaForPersonalizedInvite = () =>
  Yup.object().shape({
    name: Yup.string().required("errors.required"),
    email: Yup.string()
      .email("errors.validEmail")
      .required("errors.required"),
    phone: Yup.mixed().test("phone", "errors.validPhone", value => {
      if (value) {
        return isValidPhoneNumber(value);
      }

      return true;
    })
  });

// Here add so long regular as on the backend ( to avoid aditional handling errors )
export const checkPhoneValidity = data => {
  if (!data) return true;
  const pattern = /^(1[ \-\+]{0,3}|\+1[ -\+]{0,3}|\+1|\+)?((\(\+?1-[2-9][0-9]{1,2}\))|(\(\+?[2-8][0-9][0-9]\))|(\(\+?[1-9][0-9]\))|(\(\+?[17]\))|(\([2-9][2-9]\))|([ \-\.]{0,3}[0-9]{2,4}))?([ \-\.][0-9])?([ \-\.]{0,3}[0-9]{2,4}){2,3}$/;
  return pattern.test(data);
};

export const checkEmailValidity = data => {
  const separateIndex = data.indexOf("@");
  if (separateIndex < 0) return false;

  const userPart = data.substring(0, separateIndex);
  const userRegex = /(^[-!#$%&'*+/=?^_`{}|~0-9a-zA-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9a-zA-Z]+)*|^"([\001-\010\013\014\016-\037!#-\[\]-\177]|\\[\001-\011\013\014\016-\177])*")/;
  if (!userRegex.test(userPart)) return false;

  const domainPart = data.substring(separateIndex + 1);
  const domainRegex = /(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+(?:[a-zA-Z0-9-]{2,63})/;

  return domainRegex.test(domainPart);
};

export const checkValidity = ({ name, email, phone }) =>
  !!name && checkEmailValidity(email) && checkPhoneValidity(phone);

export const checkValidityWithoutPhone = ({ name, email }) =>
  !!name && checkEmailValidity(email);
