import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import AddToCalendarHOC from "react-add-to-calendar-hoc";

import { DATE_FORMATS } from "configs/dates";

import "./styles.scss";

const AddToCalendar = ({
  t,
  title,
  startDatetime,
  email,
  CustomButton = null,
  dropdownClass = ""
}) => {
  const ATCDropdown = args => (
    <ul className={`atc-dropdown ${dropdownClass}`}>
      <span>{t("job.candidate.addToCalendar")}</span>
      {args.children.map(link => link)}
    </ul>
  );

  const ATCWrapper = args => (
    <button
      type="button"
      onClick={args.onClick}
      className="n-border-purple-100 n-button__medium n-white button__purple"
    >
      <span className="text">{t("job.candidate.createMeeting")}</span>
    </button>
  );

  const AddToCalendarDropdown = AddToCalendarHOC(CustomButton || ATCWrapper, ATCDropdown);

  return (
    <AddToCalendarDropdown
      event={{
        title,
        duration: 0.5,
        startDatetime: moment(startDatetime).utc().format(DATE_FORMATS.CALENDAR),
        endDatetime: moment(startDatetime).utc().add(30, "minutes").format(DATE_FORMATS.CALENDAR),
        add: email
      }}
    />
  );
};

AddToCalendar.defaultProps = {
  CustomButton: undefined
};

AddToCalendar.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  startDatetime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date), PropTypes.instanceOf(moment)
  ]).isRequired,
  CustomButton: PropTypes.element,
  email: PropTypes.string.isRequired,
  dropdownClass: PropTypes.string.isRequired
};

export default React.memo(
  withTranslation()(AddToCalendar),
  (prevProps, nextProps) =>
    moment(prevProps.startDatetime).format(DATE_FORMATS.CALENDAR) ===
    moment(nextProps.startDatetime).format(DATE_FORMATS.CALENDAR) &&
    prevProps.title === nextProps.title &&
    prevProps.email === nextProps.email
);
