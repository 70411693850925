/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Close } from "mixins/svgIcons";
import { updateProfileInfo } from "store/modules/profile/actions";
import { store } from "store";
import "./styles.scss";

const UnsafeModal = ({ onDownload, handleCloseModal, withToggle = true }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  return (
    <Modal
      show
      onHide={handleCloseModal}
      backdrop="static"
      dialogClassName="unsafe-modal"
      size="xl"
      centered
    >
      <Modal.Body className="important_notice-body">
        <div className="important_notice-container">
          <div
            className="important_notice-container__close"
            onClick={() => {
              handleCloseModal();
              setIsChecked(false);
            }}
          >
            <Close fill="#212B46" />
          </div>
          <div className="important_notice-container__header">
            {t("unsafeNotice.title")}
          </div>
          <div className="important_notice-container__body">
            {t("unsafeNotice.body")}
          </div>
          <button
            className="important_notice-container__cta"
            onClick={() => {
              if (isChecked) {
                store.dispatch(
                  updateProfileInfo({
                    is_malicious_file_message_muted: true
                  })
                );
              }
              onDownload();
              handleCloseModal();
            }}
          >
            {t("unsafeNotice.download")}
          </button>
          {withToggle && (
            <div
              className="important_notice-container__footer"
              style={{ marginBottom: 33 }}
            >
              <Form.Check
                type="checkbox"
                id="check"
                checked={isChecked}
                onClick={() => setIsChecked(prev => !prev)}
              />
              {t("unsafeNotice.dontShowAgain")}
            </div>
          )}
          <div className="important_notice-container__footer-subheading">
            <span>
              {t("unsafeNotice.contact")}
              {" "}
            </span>
            <span className="important_notice-container__link">
              support@willo.video
              {" "}
            </span>
            <span>{t("unsafeNotice.concerns")}</span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

UnsafeModal.propTypes = {
  onDownload: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  withToggle: PropTypes.func.isRequired
};
export default UnsafeModal;
