/* eslint-disable react/prop-types */
import React from "react";

import StatusJob from "components/Status/Job";
import { Location } from "mixins/svgIcons";
import { JOB_STATUSES } from "configs/jobs/constants";

const Preview = ({ job, job: { status }, company }) => (
  <header className="header-preview__header">
    <h1 className="header-preview__header-title">
      <span className="header-preview__header-title-text">{job.title}</span>
    </h1>

    <div className="header-preview__additional-info">
      <div>
        <p className="header-preview__comapny-name">{company.name || ""}</p>
        {company.location ? (
          <p className="header-preview__company-location">
            <Location svgIconClass="header-preview__icon" />
            {company.location}
          </p>
        ) : null}
      </div>
      <StatusJob isLive={status === JOB_STATUSES.LIVE} prefix="preview" />
    </div>
  </header>
);

export default Preview;
