import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Header from "containers/Common/HeaderContainer";
import Breadcrumbs from "components/Breadcrumbs";
import Job from "components/Company/Job";
import GraphsCandidates from "components/Graphs/Candidates";
import { companyBreadcrumbs } from "mixins/dynamicRoutes";
import { countActiveJobs } from "mixins/helperCompany";
import { selectListDatesWithTranslations } from "mixins/selectRanges";
import { PLANS_NAMES } from "configs/plans";
import PageWrapper from "hoc/PageWrapper";
import withAbilityCreateJob from "hoc/withAbilityCreateJob";

const Overview = ({
  t,
  match: {
    params: { id = null }
  },
  history,
  dataCompanies,
  currentDateRange = {},
  currentUser = {},
  companyJobs,
  companyCandidates = {},
  fetchJobs,
  fetchJobsStats,
  fetchCandidatesStats,
  setCurrentDateRange,
  restoreJob,
  deleteJob,
  createJob,
  redirectTo
}) => {
  const defaultRange = currentDateRange.value
    ? currentDateRange
    : selectListDatesWithTranslations(t)[4];
  const isFree = currentUser.plan_name === PLANS_NAMES.freeForever;

  const currentCompany = dataCompanies?.results?.find(company => company.key === id);

  useEffect(() => {
    const dateRange =
      currentDateRange.value || selectListDatesWithTranslations(t)[4].value;

    fetchJobsStats({ id });
    fetchCandidatesStats({ id, dateRange });
    fetchJobs({ key: currentUser.key });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDateRange]);

  // eslint-disable-next-line consistent-return
  const handleCreateJob = () => {
    if (isFree && countActiveJobs(companyJobs) > 0) {
      return redirectTo(
        "/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_interviews"
      );
    }
    createJob();
    history.push("/jobs/create");
  };

  return (
    <PageWrapper header={<Header />}>
      <Container>
        <Row>
          <Col md={12}>
            <Breadcrumbs
              pathList={companyBreadcrumbs(t, currentCompany?.name, id)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <GraphsCandidates
              companyCandidates={companyCandidates}
              currentDateRange={defaultRange}
              setCurrentDateRange={setCurrentDateRange}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <p className="company__overview-jobs-title">
              {`${t("company.activeJobs")} ${countActiveJobs(companyJobs)}.`}
              {isFree ? (
                <span className="company__overview-jobs-title-bold">
                  {t("company.wantToList")}
                  <Link
                    to="/plans?utm_source=internal&utm_medium=user&utm_campaign=header_upgrade"
                    className="company__overview-jobs-title-link"
                  >
                    {t("link.upgrade")}
                  </Link>
                </span>
              ) : null}
            </p>
          </Col>
          {companyJobs &&
            companyJobs.map(job => (
              <Col md={6} key={job.key}>
                <Job job={job} deleteJob={deleteJob} restoreJob={restoreJob} />
              </Col>
            ))}

          <Col md={6}>
            <button
              type="button"
              className="link__create-interview n-bg-white"
              onClick={handleCreateJob}
            >
              <p>
                <span className="link__create-interview-icon">+</span>
                <span className="link__create-interview-text">
                  {t("button.createJob")}
                </span>
              </p>
            </button>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

Overview.defaultProps = {
  currentDateRange: {},
  companyJobs: [],
  dataCompanies: {},
  companyCandidates: {},
  currentUser: {}
};

Overview.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
    length: PropTypes.number
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  currentDateRange: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }),
  companyJobs: PropTypes.arrayOf(PropTypes.shape({})),
  dataCompanies: PropTypes.shape({
    next: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.object)
  }),
  companyCandidates: PropTypes.shape({
    invited_total: PropTypes.number,
    received_total: PropTypes.number,
    received_percentage: PropTypes.string,
    chart_data: PropTypes.arrayOf(
      PropTypes.shape({
        invited: PropTypes.number,
        received: PropTypes.number,
        name: PropTypes.string,
        day: PropTypes.string
      })
    )
  }),
  currentUser: PropTypes.shape({
    key: PropTypes.string
  }),
  profileInfo: PropTypes.shape({
    worker_count: PropTypes.number
  }).isRequired,
  fetchJobs: PropTypes.func.isRequired,
  fetchJobsStats: PropTypes.func.isRequired,
  fetchCandidatesStats: PropTypes.func.isRequired,
  setCurrentDateRange: PropTypes.func.isRequired,
  restoreJob: PropTypes.func.isRequired,
  deleteJob: PropTypes.func.isRequired,
  createJob: PropTypes.func.isRequired,
  redirectTo: PropTypes.func.isRequired
};

export default withAbilityCreateJob(withTranslation()(Overview));
