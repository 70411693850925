import React from "react";
import PropTypes from "prop-types";

const Footer = ({ children }) => (
  <div className="progress__footer">{children}</div>
);

Footer.defaultProps = {
  children: undefined
};

Footer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};

export default Footer;
