/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import axios from "axios";
import axiosRetry from "axios-retry";
import { isNull } from "lodash";
import { isCurrentUserCandidate } from "mixins/helperCandidate";
import { store } from "store";

import { saveToken, logOut } from "store/modules/auth/actions";
import { setHealthCheckError, setRateLimittingTimeRemaining } from "store/modules/widgets/actions";
import { getHealthcheckError, getIsOnline } from "store/modules/widgets/selectors";
import { getCurrentUser } from "../store/modules/users/selectors";

const AxiosInstance = axios.create({
  timeout: 600000,
  headers: {
    "Content-Type": "application/json"
  }
});

axiosRetry(AxiosInstance, {
  retries: 2,
  retryDelay: (...arg) => axiosRetry.exponentialDelay(...arg, 1000),
  retryCondition(error) {
    const isSaveAnswerApi = error?.config?.url?.includes("/api/candidates/") && error?.config?.url?.includes("/question/") && error?.config?.url?.includes("/job/");
    const isUploadToS3Api = error?.config?.url?.includes("s3-accelerate.amazonaws.com");
    const isTestApi = error?.config?.url?.includes(process.env.REACT_APP_API_AXIOS_TEST_URL);

    if ((isSaveAnswerApi || isUploadToS3Api || isTestApi)
      && ["patch", "post", "get"]?.includes(error?.config?.method)
      && (
        [500, 501].includes(error?.response?.status) || error?.message?.includes("Network Error")
      )) {
      return true;
    }

    return false;
  },
  onRetry: (retryCount, error) => {
    if (retryCount === 2) {
      console.error({ error });
    }
  }
});

AxiosInstance.interceptors.response.use(
  response => {
    store.dispatch(setHealthCheckError(null));

    return response;
  },
  error => {
    const originalRequest = error.config;
    const errorStatus = error.response && error.response.status;
    const refreshUrl = `${process.env.REACT_APP_BACKEND_URL}/api/token/refresh/`;
    const { auth } = store.getState();
    const isConnectionLostPageEnabled = process.env.REACT_APP_CONNECTION_LOST_ENABLED === "true";

    const isOnline = getIsOnline(store.getState());
    // if errorStatus is undefined
    // it means it failed to connect to Network
    if (typeof errorStatus === "undefined" && !isCurrentUserCandidate && isConnectionLostPageEnabled && !isOnline) {
      const healthcheckError = getHealthcheckError(store.getState());

      if (error?.message?.includes("Network Error")
        && isNull(healthcheckError)) {
        store.dispatch(setHealthCheckError(error?.message));
      }
    }

    // async calls make multiple /refresh API calls,
    // which leads to multiple saving of new refresh token
    if (errorStatus === 401 && !originalRequest._retry && auth.data.refreshToken) {
      if (error?.response?.data?.code === "user_inactive") return store.dispatch(logOut());

      originalRequest._retry = true;
      const userId = getCurrentUser(store.getState())?.owner?.key;

      return axios
        .post(refreshUrl, {
          refresh: auth.data.refreshToken,
          user: userId
        })
        .then(response => {
          const validToken = response.data.access;

          store.dispatch(saveToken(validToken));
          originalRequest.headers.authorization = `Bearer ${ validToken}`;

          return axios(originalRequest);
        })
        .catch(err => {
          const refreshRequest = err.config;

          if (errorStatus === 401 && refreshRequest.url === refreshUrl) {
            store.dispatch(logOut());
          }
          return Promise.reject(error);
        });
    } if (errorStatus === 401 && !auth.data.refreshToken && originalRequest._retry) {
      store.dispatch(logOut());
    } if (errorStatus === 429) {
      const num = error?.response?.data?.detail?.replace(/[^0-9]/g, "");
      store.dispatch(setRateLimittingTimeRemaining(parseInt(num, 10)));
        store.dispatch(setHealthCheckError("api_rate_limiting"));
    } else {
      store.dispatch(setHealthCheckError(null));
    }

    return Promise.reject(error);
  }
);

export { AxiosInstance };
