import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { confirmable } from "react-confirm";

import { history } from "store";

import { Modal } from "react-bootstrap";

const Subscription = ({
  t,
  show,
  proceed,
  dismiss,
  cancel,
  confirmation,
  removeModal,
  cancelPath,
  btnCancelText = "cancel",
  btnAcceptText = "ok"
}) => (
  <Modal
    dialogClassName="confirm"
    show={show}
    onHide={() => {
      removeModal();
      dismiss();
      history.push(cancelPath);
    }}
  >
    <Modal.Header closeButton />
    <Modal.Body>{confirmation}</Modal.Body>
    <Modal.Footer>
      <div className="button__group">
        <button
          type="button"
          className="button button__confirm"
          onClick={() => {
            removeModal();
            cancel();
            history.push(cancelPath);
          }}
        >
          {t(`button.${btnCancelText}`)}
        </button>
        <button
          type="button"
          className="button button__confirm button__confirm_fill"
          onClick={() => {
            removeModal();
            proceed();
          }}
        >
          {t(`button.${btnAcceptText}`)}
        </button>
      </div>
    </Modal.Footer>
  </Modal>
);

Subscription.defaultProps = {
  proceed: undefined,
  cancel: undefined,
  dismiss: undefined,
  removeModal: undefined,
  confirmation: undefined,
  cancelPath: undefined,
  btnCancelText: undefined,
  btnAcceptText: undefined
};

Subscription.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  proceed: PropTypes.func,
  cancel: PropTypes.func,
  dismiss: PropTypes.func,
  removeModal: PropTypes.func,
  confirmation: PropTypes.string,
  cancelPath: PropTypes.string,
  btnCancelText: PropTypes.string,
  btnAcceptText: PropTypes.string
};

export default confirmable(withTranslation()(Subscription));
