import React, { useState, useEffect } from "react";
import Switch from "rc-switch";
import PropTypes from "prop-types";

import "./styles.scss";

const Switcher = ({ checked, handleOn, handleOff, disabled, className }) => {
  const [isActive, setActive] = useState(checked);

  useEffect(() => {
    setActive(checked);
  }, [setActive, checked]);

  const handleOnChange = status => {
    if (status) {
      handleOff();
    } else {
      handleOn();
    }
  };
  return (
    <>
      <Switch
        onChange={status => {
          setActive(status);
          handleOnChange(status);
        }}
        checked={isActive}
        disabled={disabled}
        className={`${String(className)}`}
      />
    </>
  );
};

Switcher.defaultProps = {
  checked: undefined,
  handleOn: undefined,
  handleOff: undefined,
  disabled: undefined,
  className: undefined
};

Switcher.propTypes = {
  checked: PropTypes.bool,
  handleOn: PropTypes.func,
  handleOff: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default Switcher;
