import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import HeaderCandidateJob from "components/Common/Header/Candidate/Job";
import Footer from "components/Common/Footer";
import Candidate from "components/Candidates/Item";
import PageWrapper from "hoc/PageWrapper";
import withDocumentTitle from "hoc/withDocumentTitle";
import { Avatar } from "mixins/svgIcons";

import "./styles.scss";
import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import ShowcasePromotions from "containers/Candidate/ShowcasePromotions/ShowcasePromotions";

const BulkPublic = ({
  match: {
    params: { tokenId }
  },
  location: {
    pathname
  },
  currentCompany,
  publicUserInfo,
  publicBulk,
  isLoading,
  fetchPublicBulkInfo,
  fetchPublicBulkCandidateInfo,
  clearErrors
}) => {
  const [selected, setSelected] = useState(null);
  useEffect(() => {
    fetchPublicBulkInfo({ tokenId });
  }, [fetchPublicBulkInfo, tokenId]);

  useEffect(() => {
    setSelected(publicBulk[0]?.key);
  }, [publicBulk]);

  const fetchPublicUserInfo = params => fetchPublicBulkCandidateInfo({
    ...params,
    bulkId: tokenId
  });

  const selectedBorder = currentCompany?.button_color === undefined ? null : { borderColor: currentCompany?.button_color || "#5A2AF1" };

  return (
    <PageWrapper
      header={(
        <HeaderCandidateJob
          company={currentCompany}
          pathname={
            publicUserInfo?.configuration?.showcase_promotions_enabled
            ? `/sign-up?utm_source=showcase&utm_medium=cv-video&utm_campaign=candidates+distibution&utm_id=header&back=${encodeURIComponent(pathname)}`
            : `/jobs/candidate/${publicUserInfo?.candidate?.key}/${publicUserInfo?.candidate?.job_key}?back=${encodeURIComponent(pathname)}`
          }
          className="public-candidate__logo"
        />
      )}
      footer={<Footer company={currentCompany} isPublic />}
      prefix="bulk-showcase"
    >
      <div>
        <div className="candidates__menu-scroll container">
          <div className="candidates__menu-horizontal">
            {publicBulk.map(({
              key,
              name,
              avatar_remote_link: avatar,
              anonymized_at: anonymizedAt
            }) => (
              <div
                role="presentation"
                key={key}
                className={`candidates__menu-horizontal-item ${selected === key ? "selected" : ""}`}
                onClick={() => setSelected(key)}
                style={selected === key ? selectedBorder : null}
              >
                <SkeletonWrapper
                  skeletonProps={{
                    circle: true,
                    width: 45,
                    height: 45,
                    enableAnimation: !anonymizedAt
                  }}
                  skeletonWrapperProps={{
                    style: {
                      paddingRight: 12
                    }
                  }}
                  anonymizedDate={anonymizedAt}
                  isLoading={isLoading}
                >
                  <>
                    {avatar ? (
                      <img src={avatar} className="candidates__list-item-avatar" alt="avatar" />
                    ) : (
                      <div className="candidates__list-item-avatar candidates__list-item-empty-avatar">
                        <Avatar svgIconClass="n-icon__small right" />
                      </div>
                    )}
                  </>
                </SkeletonWrapper>
                <SkeletonWrapper
                  skeletonProps={{
                    count: 1,
                    width: 80,
                    height: 16,
                    enableAnimation: !anonymizedAt
                  }}
                  anonymizedDate={anonymizedAt}
                  isLoading={isLoading}
                >
                  <span className="n-font-small n-black-50">{name}</span>
                </SkeletonWrapper>
              </div>
            ))}
          </div>
        </div>
        {selected && (
          <Candidate
            tokenId={selected}
            publicUserInfo={publicUserInfo}
            fetchPublicUserInfo={fetchPublicUserInfo}
            clearErrors={clearErrors}
            selected={selected}
            publicBulk={publicBulk}
            setSelected={setSelected}
            buttonColor={currentCompany?.button_color}
          />
        )}

        <ShowcasePromotions />
      </div>
    </PageWrapper>
  );
};

BulkPublic.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tokenId: PropTypes.string
    })
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  publicUserInfo: PropTypes.shape({
    candidate: PropTypes.shape({
      key: PropTypes.string,
      job_key: PropTypes.string
    }),
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    company: PropTypes.shape({}),
    configuration: PropTypes.shape({
      showcase_promotions_enabled: PropTypes.bool
    })
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  publicBulk: PropTypes.arrayOf(PropTypes.shape).isRequired,
  fetchPublicBulkCandidateInfo: PropTypes.func.isRequired,
  fetchPublicBulkInfo: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  currentCompany: PropTypes.shape({
    button_color: PropTypes.string
  }).isRequired
};

export default withTranslation()(withDocumentTitle(BulkPublic));
