/* eslint-disable import/prefer-default-export */

export const recordEvent = (category, action, label = "", params) => {
  window.dataLayer.push({
    event: "pageEvent",
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    ...params
  });
};
