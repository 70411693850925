import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { IDVCheck, Question, TimerIcon } from "mixins/svgIcons";
import { spentTimeString } from "mixins/helperVideoRecording";
import { connect } from "react-redux";
import { getQuestionsForCandidate } from "store/modules/сandidates/selectors";
import { size } from "lodash";

const QuestionTitle = ({
  t,
  questionNumber,
  thinkingTime,
  spentTime,
  isIdv = false,
  questions
}) => {
  if (!isIdv) {
    return (
      <>
        <span className="question-create__questions-title flex flex-row items-center">
          <Question svgIconClass="question-create__icon" />
          {`${t("candidate.common.question")} ${questionNumber}`}
          {" "}
          of
          {" "}
          {size(questions)}
        </span>

        {thinkingTime ? (
          <div className="question-create__questions-title thinkin flex flex-row items-center">
            <TimerIcon svgIconClass="question-create__icon" fill="#B2B7C5" />
            <p className="question-create__thinking n-font-medium n-font-regular n-grey-100">
              {t("candidate.common.questionTimed", {
                time: spentTimeString(thinkingTime)
              })}
              <span
                className={`n-font-semi-bold ${
                  thinkingTime < spentTime ? "n-red-100" : "n-purple-100"
                }`}
              >
                {spentTimeString(spentTime)}
              </span>
            </p>
          </div>
        ) : null}
      </>
    );
  }

  return (
    <span className="question-create__questions-title flex flex-row items-center">
      <IDVCheck svgIconClass="question-create__icon" />
      {t("job.questions.idv.label")}
    </span>
  );
};

QuestionTitle.defaultProps = {
  thinkingTime: 0,
  spentTime: 0,
  isIdv: false
};

QuestionTitle.propTypes = {
  t: PropTypes.func.isRequired,
  questionNumber: PropTypes.number.isRequired,
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  isIdv: PropTypes.bool
};

const mapStateToProps = state => ({
  questions: getQuestionsForCandidate(state)
});

export default connect(mapStateToProps)(withTranslation()(QuestionTitle));
