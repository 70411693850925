import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  updateProfileInfo,
  clearErrors
} from "store/modules/profile/actions";
import { updateAgency } from "store/modules/users/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";
import { setModal } from "store/modules/modals/actions";
import { getCurrentUser, getIsCurrentAgencyLoading, getUsersTableData } from "store/modules/users/selectors";
import { getProfileInfo } from "store/modules/profile/selectors";
import { getNotifications, getIsTrialOrPaid } from "store/modules/notifications/selectors";
import Profile from "views/Profile";

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  profileInfo: getProfileInfo(state),
  dataUsers: getUsersTableData(state),
  isSubscriptionActive: getIsTrialOrPaid(state),
  isLoadingScreen: getIsCurrentAgencyLoading(state) || state?.widgets?.isLoading,
  notifications: getNotifications(state),
  errorCheckout: state.profile.errorCheckout
});

const mapDispatchToProps = dispatch => ({
  updateProfileInfo: payload => dispatch(updateProfileInfo(payload)),
  clearErrors: () => dispatch(clearErrors()),
  setLoadingScreen: payload => dispatch(setLoadingScreen(payload)),
  setModal: payload => dispatch(setModal(payload)),
  updateAgency: payload => dispatch(updateAgency(payload))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
