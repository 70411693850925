import React from "react";
import { CloseButton, Col, Modal, Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const ErrorModal = ({
  show,
  hide,
  t,
  content
}) => (
  <Modal dialogClassName="error-modal" show={show} onHide={hide} centered>
    <Modal.Body>
      <CloseButton onClick={hide} />
      <div className="error-container">
        <Row className="error-container__row">
          <Col md={12}>
            <div className="error-container__content">
              <span className="n-font-large n-grey">
                {content || t("errors.errorModal")}
              </span>
              <button
                type="button"
                className="n-button__medium-border n-purple-100"
                onClick={() => window.location.reload()}
              >
                {t("modals.error.refresh")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Modal.Body>
  </Modal>
);

ErrorModal.defaultProps = {
  content: null
};

ErrorModal.propTypes = {
  show: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  hide: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  content: PropTypes.string
};

export default withTranslation()(ErrorModal);
