import { connect } from "react-redux";

import { getCandidateToken, getCandidateInfo } from "store/modules/сandidates/selectors";
import { setCurrentCandidateId } from "store/modules/сandidates/actions";
import PrivateRouteCandidate from "components/Common/PrivateRouteCandidate";

const mapStateToProps = state => ({
  isCandidate: Boolean(getCandidateToken(state)),
  candidateInfo: getCandidateInfo(state)
});

const mapDispatchToProps = {
  setCurrentCandidateId
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouteCandidate);
