/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import React from "react";

import { BellIcon, Close } from "mixins/svgIcons";

import "./styles.scss";

const getAvatarName = (userName, email) => {
  if (userName) {
    return userName
    // eslint-disable-next-line no-return-assign
      ? userName.split(/\s/).reduce((name, word) => name += word.slice(0, 1), "")
      : "";
  }

  return email.slice(0, 1);
};

const UserItem = ({
  user,
  user: {
    full_name: fullName,
    email,
    is_receive_notifications: isReceiveNotifications,
    is_admin: isAdmin
  },
  isCanSee,
  hasAbilityEdit,
  handleDelete,
  handleNotifications,
  handleAdd
}) => (
  <div className="users__user-list-item">
    <div className="users__user-list-item-info">
      {isCanSee ? (
        <button
          type="button"
          className={`users__user-notify button__without-styles ${isReceiveNotifications ? "users__user-notify-alert" : ""} ${(!hasAbilityEdit) && "disable"}`}
          onClick={(!hasAbilityEdit) ? null : () => handleNotifications(user)}
        >
          <BellIcon svgIconClass={isReceiveNotifications ? "notify-alert" : ""} />
        </button>
      ) : <div className="users__user-notify-empty" />}
      <div className="users__user-list-item-info-avatar">
        <p className="n-font-small n-font-medium-weight n-white n-uppercase">{getAvatarName(fullName, email)}</p>
      </div>
      <div className="users__user-list-item-info-name">
        <p className="n-font-small n-font-medium-weight n-grey-60">{fullName}</p>
        <p className="n-font-extra-small n-font-regular n-black-50">{email}</p>
      </div>
    </div>
    {isAdmin ? (
      <p className="users__user-admin n-font-extra-small n-font-regular n-black-50">Admin</p>
    ) : (hasAbilityEdit ? (isCanSee ? (
      <button type="button" className="users__user-delete button__without-styles" onClick={() => handleDelete(user)}>
        <Close fill="#9095A3" />
      </button>
    ) : (
      <button type="button" className="users__user-add button__without-styles" onClick={() => handleAdd(user)}>
        Add
      </button>
    )) : null)}
  </div>
);

UserItem.defaultProps = {
  isCanSee: true,
  handleDelete: null,
  handleNotifications: null,
  handleAdd: null
};

UserItem.propTypes = {
  user: PropTypes.shape({
    full_name: PropTypes.string,
    email: PropTypes.string,
    is_receive_notifications: PropTypes.bool,
    is_admin: PropTypes.bool
  }).isRequired,
  isCanSee: PropTypes.bool,
  hasAbilityEdit: PropTypes.bool.isRequired,
  handleDelete: PropTypes.func,
  handleNotifications: PropTypes.func,
  handleAdd: PropTypes.func
};

export default UserItem;
