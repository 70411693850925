import React from "react";
import PropTypes from "prop-types";
import { FaTrashAlt } from "react-icons/fa";

const DeleteInput = ({ deleted }) => (
  <button type="button" className="button__delete" onClick={deleted}>
    <i className="fas fa-trash-alt xs grey hover-red">
      <FaTrashAlt />
    </i>
  </button>
);

DeleteInput.propTypes = {
  deleted: PropTypes.func.isRequired
};

export default DeleteInput;
