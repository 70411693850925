import { useCallback } from "react";
import { Question } from "types/candidateAnswer.ts";

type NavigationProps = {
  userId: string;
  jobId: string;
  questions: Question[];
  currentAction: string;
  saveCurrentQuestion: (params: { userId: string; questionCurrentCashed: null }) => void;
  setCashedElapsingTime: (time: null) => void;
  updateFirebaseData?: () => void;
  history: {
    push: (path: { pathname: string }) => void;
  };
};

const useStepperNavigation = ({
  userId,
  jobId,
  questions,
  currentAction,
  saveCurrentQuestion,
  setCashedElapsingTime,
  updateFirebaseData,
  history
}: NavigationProps) => {
  const goToNextQuestion = useCallback((questionId: string) => {
    saveCurrentQuestion({ userId, questionCurrentCashed: null });
    setCashedElapsingTime(null);

    const questionNumber = questions.findIndex(item => item.key === questionId);

    if (typeof updateFirebaseData === "function") {
      updateFirebaseData();
    }

    history.push({
      pathname: `/candidate/video-questions/${currentAction}/${userId}/${jobId}/${questionId}/${questionNumber}`
    });
  }, [
    currentAction,
    history,
    jobId,
    questions,
    saveCurrentQuestion,
    setCashedElapsingTime,
    updateFirebaseData,
    userId
  ]);

  return { goToNextQuestion };
};

export default useStepperNavigation;
