/* eslint-disable no-restricted-globals */

import { isNumber } from "lodash";

const getValidVolume = val => {
  if (isNumber(val)) {
    return val;
  }
  return 1;
};

export default getValidVolume;
