import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Lottie from "react-lottie";

import animationData from "assets/images/lock.json";

import { StepFirst, StepSecond, Success } from "./Steps";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const steps = [
  {
    title: "security.useApp",
    subtitle: "security.download",
    component: StepFirst
  },
  {
    title: "security.useApp",
    subtitle: "security.enter",
    component: StepSecond
  },
  {
    title: "security.secured",
    subtitle: "security.success",
    component: Success
  }
];

const Steps = ({
  t,
  step,
  setStep,
  device,
  confirmDevice,
  createDevice,
  error,
  clearErrors,
  isSubscriptionFailed,
  isTrialCompleted,
  isSetupCompleted,
  history
}) => {
  const handleCancel = () => setStep(null);
  const handleContinue = () => setStep(step + 1);
  const handleBack = () => setStep(step - 1);

  const Step = steps[step].component;

  useEffect(() => {
    clearErrors();
  }, []);

  return (
    <Row>
      <Col md={8}>
        <h1 className="security__title n-font-huge">
          {t(steps[step].title)}
        </h1>
        <p className="security__subtitle n-font-small">
          {t(steps[step].subtitle)}
        </p>
      </Col>
      <Col md={4}>
        <Lottie options={defaultOptions} height={150} width={180} />
      </Col>

      <Step
        qrCode={device?.qr_config_url}
        backupCode={device?.backup_code}
        deviceKey={device?.key}
        handleCancel={handleCancel}
        handleContinue={handleContinue}
        handleBack={handleBack}
        t={t}
        confirmDevice={confirmDevice}
        createDevice={createDevice}
        error={error}
        clearErrors={clearErrors}
        isSubscriptionFailed={isSubscriptionFailed}
        isTrialCompleted={isTrialCompleted}
        isSetupCompleted={isSetupCompleted}
        history={history}
      />
    </Row>
  );
};

Steps.defaultProps = {};

Steps.propTypes = {
  t: PropTypes.func.isRequired,
  isSubscriptionFailed: PropTypes.bool.isRequired,
  isTrialCompleted: PropTypes.bool.isRequired,
  isSetupCompleted: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  device: PropTypes.shape.isRequired,
  confirmDevice: PropTypes.func.isRequired,
  createDevice: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default withTranslation()(Steps);
