/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
import React, { useRef, useState } from "react";
import ReactPlayer from "react-player/lazy";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import { isIOS, isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import DomPurify from "dompurify";
import VideoPlayer from "components/Video/Player";
import { Wallet } from "mixins/svgIcons";
import { replaceAll } from "utils/string";


const Details = ({ t, jobMainInfo, jobCompanyInfo }) => {
  const videoPlayerRef = useRef(null);
  const [height, setHeight] = useState(350);
  const [videoErrors, setVideoErrors] = useState([]);

  const handleMetadata = () => {
    const player = videoPlayerRef.current?.getInternalPlayer() || {};
    const { videoHeight, videoWidth, clientWidth } = player;
    const clientHeight = `${Number(clientWidth * videoHeight) / videoWidth}px`;
    setHeight(clientHeight);
  };

  const onReady = function () {
    const clientWidth = this?.el()?.clientWidth;
    const videoWidth = this?.videoWidth?.();
    const videoHeight = this?.videoHeight?.();
    const clientHeight = Number(clientWidth * videoHeight) / videoWidth;
    setHeight(clientHeight);
  };

  const style = { height };

  const videoJsOptions = {
    autoplay: !!jobMainInfo.video_playlist_link && isIOS,
    playbackRates: [0.5, 1, 1.5, 2],
    fluid: true,
    controls: true,
    controlsList: "",
    className: "react-player",
    sources: [
      {
        src: jobMainInfo.video_playlist_link && `${jobMainInfo.video_playlist_link}#t=0.001`,
        type: "application/x-mpegURL"
      },
      {
        src: `${jobMainInfo.video_link}#t=0.001`,
        type: "video/mp4"
      }
    ],
    withDownload: false,
    questionNumber: "intro",
    inactivityTimeout: 0,
    playsinline: true,
    onReady,
    ...!isMobile && {
      style: { width: 600, height, paddingTop: 0 }
    }
  };

  function transform(node) {
    if (node.type === "tag") {
      node.attribs = {
        ...node.attribs,
        target: "_blank"
      };
    }
  }

  return (
    <>
      {jobMainInfo.is_own_video ? (
        (jobMainInfo.video_playlist_link || jobMainInfo.video_link) && (
          <div className="details-player details-player-own">
            <VideoPlayer
              {...videoJsOptions}
              videoErrors={videoErrors}
              setVideoErrors={setVideoErrors}
            />
          </div>
        )
      ) : (
        jobMainInfo.video_link && (
          <div className="details-player" style={style}>
            <ReactPlayer
              ref={videoPlayerRef}
              width={600}
              height={height}
              className="react-player"
              url={jobMainInfo.video_link}
              controls
              onReady={handleMetadata}
            />
          </div>
        )
      )}

      <div className="details-job">
        <p>
          {jobMainInfo.salary && (
            <>
              <Wallet svgIconClass="details-job__icon" />
              <span className="details-job__title">{t("candidate.common.salary")}</span>
              <span className="details-job__data">{jobMainInfo.salary}</span>
            </>
          )}
        </p>
        {jobCompanyInfo.site && (
          <p>
            <a href={jobCompanyInfo.site} className="details-job__link">
              {jobCompanyInfo.site}
            </a>
          </p>
        )}
      </div>

      <div className="details-text__box">
        {ReactHtmlParser(DomPurify.sanitize(replaceAll(jobMainInfo.description, "&lt;br&gt;&lt;br&gt;", "<p></p>"), {
          ADD_ATTR: "href"
        }), {
          transform
        })}
      </div>
    </>
  );
};

Details.defaultProps = {
  jobMainInfo: {},
  jobCompanyInfo: {}
};

Details.propTypes = {
  t: PropTypes.func.isRequired,
  jobMainInfo: PropTypes.shape({
    description: PropTypes.string,
    salary: PropTypes.string,
    title: PropTypes.string,
    video_link: PropTypes.string,
    video_playlist_link: PropTypes.string,
    is_own_video: PropTypes.bool
  }),
  jobCompanyInfo: PropTypes.shape({
    company: PropTypes.string,
    site: PropTypes.string
  })
};

export default withTranslation()(Details);
