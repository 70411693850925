/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect } from "react";
import PropTypes from "prop-types";

const getDefinition = () => ([
  {
    attenuation: 0.1,
    lineWidth: 5,
    opacity: 0.1
  },
  {
    attenuation: 0.5,
    lineWidth: 3,
    opacity: 0.7
  },
  {
    attenuation: 1,
    lineWidth: 1,
    opacity: 1
  }
]);

const Wave = ({ streamRef, streamId, hasLoaded = false }) => {
  useEffect(() => {
    let context;
    const audio = document.getElementById("audio");
    const canvas = document.getElementById("canvas");
    if (audio && canvas) {
      const ctx = canvas?.getContext("2d");

      const visualize = source => {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        if (!AudioContext) return;
        context = new AudioContext();
        // check if has audio track
        if (!source?.getAudioTracks?.().length) return;

        const src = context.createMediaStreamSource(source);
        const analyser = context.createAnalyser();
        const listen = context.createGain();

        src.connect(listen);
        listen.connect(analyser);
        analyser.fftSize = 2 ** 12;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        function renderFrame() {
          setTimeout(() => requestAnimationFrame(renderFrame), 50);

          analyser.smoothingTimeConstant = 0.5;
          listen.gain.setValueAtTime(1, context.currentTime);
          // eslint-disable-next-line no-multi-assign
          const WIDTH = (canvas.width = window.innerWidth);
          // eslint-disable-next-line no-multi-assign
          const HEIGHT = (canvas.height = window.innerHeight);
          const sliceWidth = WIDTH * 1.0 / bufferLength;
          let x = 0;

          ctx.fillStyle = "#9095A3";
          ctx.fillRect(0, 0, WIDTH, HEIGHT);
          getDefinition().forEach(({ attenuation, lineWidth, opacity }) => {
            analyser.getByteFrequencyData(dataArray);
            const start = 0;
            analyser.getByteTimeDomainData(dataArray);
            ctx.lineWidth = lineWidth;
            ctx.strokeStyle = `rgba(30, 56, 75, ${opacity})`;
            ctx.beginPath();
            x = 0;
            for (let i = start; i < bufferLength / 2; i += 3) {
              const v = dataArray[i] / 128.0;
              const y = (1 + ((v - 1) * (Math.sin(Math.PI * x / WIDTH) ** 2) * 1.5) * attenuation) *
              HEIGHT / 2;
              if (i === 0) {
                ctx.moveTo(x, y);
              } else {
                ctx.lineTo(x, y);
              }
              x = i * sliceWidth * 3;
            }
            ctx.lineTo(WIDTH, dataArray[0] / 128.0 * HEIGHT / 2);
            ctx.stroke();
          });
        }
        renderFrame();
      };

      visualize(streamRef.current.clone());
    }
    return () => {
      if (context) { context.close(); }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamId]);

  return (
    <div id="content" className="player audio">
      <canvas id="canvas" className={`player ${hasLoaded ? "loaded-player" : ""}`} />
      <audio id="audio" controls />
    </div>
  );
};

Wave.defaultProps = {
  streamId: undefined,
  hasLoaded: false
};

Wave.propTypes = {
  streamId: PropTypes.string,
  streamRef: PropTypes.shape().isRequired,
  hasLoaded: PropTypes.bool
};

export default Wave;
