/* eslint-disable import/prefer-default-export */
import moment from "moment";
import { DATE_FORMATS } from "configs/dates";

export const getDiffDateToday = (t, updatedAt, vHistory) => {
  const statusUpdatedAt = vHistory?.length
    ? vHistory[vHistory.length - 1].created_at
    : updatedAt;

  const endOfToday = moment().endOf("day");
  const diffHours = Math.abs(endOfToday.diff(statusUpdatedAt, "hours"));
  const diffDays = Math.trunc(diffHours / 24);
  let message;

  switch (true) {
    case diffHours < 24:
      message = t("job.candidate.moveHereToday");
      break;
    case diffHours < 24 * 2:
      message = t("job.candidate.moveHereYesterday");
      break;
    case diffHours < 24 * 7:
      message = t("job.candidate.moveHereDaysAgo", { days: diffDays });
      break;
    default:
      message = t("job.candidate.moveHere", {
        date: moment(statusUpdatedAt).format(
          DATE_FORMATS.SPACE_SEPARATOR_LONG_MONTH
        )
      });
  }

  return message;
};
