import React, { useMemo, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import cn from "classnames";

import MultiSelect from "components/Common/MultiSelect";
import { Plus } from "mixins/svgIcons";
import { store } from "store";

import "./styles.scss";
import {
  fetchJobsCompaniesFilter,
  fetchJobsOwnersFilter,
  fetchJobsStatusesFilter
} from "store/modules/jobs/actions";
import { connect } from "react-redux";
import { unionBy } from "lodash";
import { useDebouncedCallback } from "use-debounce";
import { FaLock } from "react-icons/fa";

import { handleOnboarding } from "store/modules/widgets/actions";
import { getJobsMaximum } from "store/modules/users/selectors";
import TooltipInfo from "components/Common/Tooltip/Info";

const Filters = ({
  t,
  isLoading,
  filters,
  filter,
  setFilter,
  clearFilter,
  isShowBtn,
  isCardInfoLoading,
  handleCreateJob,
  isFreeInterviewsFinished,
  currentUser,
  search,
  setSearch,
  onboarding,
  maximumJobs,
  redirectTo
}) => {
  useEffect(() => {
    if (!onboarding) {
      store.dispatch(handleOnboarding({ key: currentUser.key }));
    }
  }, [currentUser, onboarding]);
  const optionCompanies = useMemo(
    () =>
      unionBy((filters.companies || []).map(
        ({ key, name, interviews_amount: amount }) => ({
          label: name,
          value: key,
          amount
        })
      ), filter.companies.map(a => ({
        ...a,
        amount: 0
      })), "value"),
    [filter.companies, filters.companies]
  );
  const optionOwners = useMemo(
    () =>
      unionBy((filters.owners || []).map(
        ({ key, full_name: name, interviews_amount: amount }) => ({
          label: name,
          value: key,
          amount
        })
      ), filter.owners.map(a => ({
        ...a,
        amount: 0
      })), "value"),
    [filter.owners, filters.owners]
  );
  const optionStatuses = useMemo(
    () =>
      unionBy((filters.statuses || []).map(({ status, interviews_amount: amount }) => ({
        label: `job.table.${status}`,
        value: status,
        amount
      })), filter.statuses.map(a => ({
        ...a,
        amount: 0
      })), "value"),
    [filter.statuses, filters.statuses]
  );
  const filterCount = useMemo(() => {
    const filterArray = Object.keys(filter).filter(
      item => Array.isArray(filter[item]) && filter[item].length
    );
    return filterArray.length;
  }, [filter]);

  const onChangeText = useDebouncedCallback(val => {
    setFilter({ ...filter, title: val });
  }, 500);

  const handleRedirect = () => {
    redirectTo(
      "/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_interviews"
    );
  };

  return (
    <Row justify="center" className="people__filters">
      <Col md={12}>
        <Row justify="center">
          <Col sm={12} md={7} lg={3}>
            <input
              id="name-search"
              className="form__search-input name-search__input n-font-small jobs"
              placeholder={t("input.searchTitlePlaceholder")}
              value={search}
              onChange={e => {
                setSearch(e.target.value);
                onChangeText(e.target.value);
              }}
              type="text"
            />
          </Col>
          <Col sm={12} md={5} lg>
            <MultiSelect
              options={optionCompanies}
              value={filter.companies}
              handleSelect={newCompanies =>
                setFilter({ ...filter, companies: newCompanies })
              }
              isLoading={isLoading}
              placeholder="select.jobFilterPlaceholder"
              fetchLatest={() => {
                store.dispatch(
                  fetchJobsCompaniesFilter({ key: currentUser.key, filter })
                );
              }}
              fetchOnOpen
            />
          </Col>
          <Col sm={12} md lg>
            <MultiSelect
              options={optionOwners}
              value={filter.owners}
              handleSelect={newOwners =>
                setFilter({ ...filter, owners: newOwners })
              }
              isLoading={isLoading}
              placeholder="select.ownerFilterPlaceholder"
              fetchLatest={() => {
                store.dispatch(
                  fetchJobsOwnersFilter({ key: currentUser.key, filter })
                );
              }}
              fetchOnOpen
            />
          </Col>
          <Col sm={12} md lg>
            <MultiSelect
              options={optionStatuses}
              value={filter.statuses}
              handleSelect={newStatuses =>
                setFilter({ ...filter, statuses: newStatuses })
              }
              isLoading={isLoading}
              placeholder="select.statusFilterPlaceholder"
              withTranslationLabel
              fetchLatest={() => {
                store.dispatch(
                  fetchJobsStatusesFilter({ key: currentUser.key, filter })
                );
              }}
              fetchOnOpen
            />
          </Col>
          <Col sm={12} md={3} lg={2}>
            {isShowBtn ? (
              <div className="button__group_right">
                <TooltipInfo
                  message={maximumJobs === 1 ? t("plans.maxJob", { value: maximumJobs }) : t("plans.maximumJobs", { value: maximumJobs })}
                  disabled={!(isCardInfoLoading ||
                      isFreeInterviewsFinished || maximumJobs <= onboarding?.agency_jobs_amount)}
                >
                  <button
                    id="create-job-button"
                    type="button"
                    className={cn(
                    "jobs__cleate-button n-button__medium n-white button__purple n-button__full-width",
                    {
                      disabled: isCardInfoLoading ||
                      isFreeInterviewsFinished || maximumJobs <= onboarding?.agency_jobs_amount
                    }
                  )}
                    disabled={isCardInfoLoading}
                    onClick={() => (maximumJobs <= onboarding?.agency_jobs_amount
                     ? handleRedirect() : handleCreateJob())}
                  >
                    {(isFreeInterviewsFinished || maximumJobs <= onboarding?.agency_jobs_amount) ? (
                      <i className="fas gold fa-lock n-icon__medium right">
                        <FaLock />
                      </i>
                  ) : (
                    <Plus svgIconClass="n-icon__medium right n-svg-white" />
                  )}
                    <span className="text">{t("button.createJob")}</span>
                  </button>
                </TooltipInfo>
              </div>
            ) : null}
          </Col>
        </Row>
      </Col>
      <Col md={5} className="people__clear">
        <p className="n-font-small n-font-medium-weight n-grey-60">
          {t(`people.table.selected.${filterCount === 1 ? "0" : "1"}`, {
            count: filterCount
          })}
        </p>
        <p className="n-font-small n-font-medium-weight n-grey-60 people__clear-separate">
          |
        </p>
        <button
          type="button"
          className="jobs__clear-filter n-font-small n-font-light n-grey-60"
          onClick={clearFilter}
        >
          <span className="text ">{t("input.clearAll")}</span>
        </button>
      </Col>
    </Row>
  );
};

Filters.defaultProps = {
  filter: {
    title: "",
    companies: [],
    jobs: [],
    owners: [],
    ratings: [],
    statuses: []
  },
  clearFilter: null,
  onboarding: undefined
};

Filters.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filters: PropTypes.shape({
    companies: PropTypes.arrayOf(PropTypes.any),
    jobs: PropTypes.arrayOf(PropTypes.any),
    owners: PropTypes.arrayOf(PropTypes.any),
    ratings: PropTypes.arrayOf(PropTypes.any),
    statuses: PropTypes.arrayOf(PropTypes.any)
  }).isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    title: PropTypes.string,
    companies: PropTypes.arrayOf(PropTypes.any),
    jobs: PropTypes.arrayOf(PropTypes.any),
    owners: PropTypes.arrayOf(PropTypes.any),
    ratings: PropTypes.arrayOf(PropTypes.any),
    statuses: PropTypes.arrayOf(PropTypes.any)
  }),
  currentUser: PropTypes.shape({
    key: PropTypes.string
  }).isRequired,
  setFilter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func,
  isShowBtn: PropTypes.bool.isRequired,
  isCardInfoLoading: PropTypes.bool.isRequired,
  handleCreateJob: PropTypes.func.isRequired,
  isFreeInterviewsFinished: PropTypes.bool.isRequired,
  onboarding: PropTypes.shape({
    agency_jobs_amount: PropTypes.number
  }),
  maximumJobs: PropTypes.number.isRequired,
  redirectTo: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  currentUser: state?.users?.currentUser,
  onboarding: state.dashboard?.onboarding,
  maximumJobs: getJobsMaximum(state)
});

export default connect(mapStateToProps)(withTranslation()(Filters));
