import React from "react";
import PropTypes from "prop-types";
import ListItem from "./ListItem";

const List = ({ t, children, items }) => (
  <ul className="profile-card__list">
    {Array.isArray(items) &&
      items.map((item, index) => (
        <ListItem
          title={item.text}
          key={`profile-card-${item.id}`}
          t={t}
          item={item}
          index={index}
        />
      ))}
    {children}
  </ul>
);

List.defaultProps = {
  items: undefined,
  children: undefined,
  t: undefined
};

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  children: PropTypes.element,
  t: PropTypes.func
};

export default List;
