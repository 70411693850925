import { connect } from "react-redux";

import { setLoadingScreen } from "store/modules/widgets/actions";
import { createLibrary, clearLibraryError } from "store/modules/libraries/actions";
import { setModal } from "store/modules/modals/actions";
import Create from "views/Libraries/Create";

const mapStateToProps = state => ({
  isLoadingScreen: state.widgets.isLoading,
  error: state.libraries.errorSaving || {}
});

const mapDispatchToProps = {
  setLoadingScreen,
  createLibrary,
  clearLibraryError,
  setModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Create);
