export default {
  id: {
    value: "",
    valid: true,
    touched: false
  },
  invitation_email: {
    label: "jobEmailTemplateTitle",
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "jobEmailTemplateTitle"
    },
    value: "",
    options: [],
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  invitation_sms: {
    label: "jobSmsTemplateTitle",
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "jobSmsTemplateTitle"
    },
    value: "",
    options: [],
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  reminder_email: {
    label: "jobEmailTemplateTitle",
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "jobEmailTemplateTitle"
    },
    value: "",
    options: [],
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  reminder_sms: {
    label: "jobSmsTemplateTitle",
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "jobSmsTemplateTitle"
    },
    value: "",
    options: [],
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  success_notification_email: {
    label: "jobEmailTemplateTitle",
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "jobEmailTemplateTitle"
    },
    value: "",
    options: [],
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  }
};
