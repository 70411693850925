import React, { useState, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import { Close, PlusIcon } from "mixins/svgIcons";
import InviteUsers from "components/Users/InviteUsers";
import { ROLE_STANDARD } from "configs/user/rolesConfig";

import "./styles.scss";
import { isEmpty } from "lodash";

const AddPeople = ({
  t,
  companyName,
  addNewUsers,
  inviteError,
  addNewUsersError,
  jobCurrent,
  currentUserKey,
  updateJobUsersList,
  refresh
}) => {
  const [show, setShow] = useState(false);
  const [invites, setInvites] = useState([]);
  const errorCounter =
    useMemo(() => invites.filter(({ isValid }) => !isValid).length, [invites]);

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    setInvites([]);
  };

  const handleUpdateReviewers = async reviewers => {
    await updateJobUsersList({
      id: jobCurrent.key,
      reviewers,
      isUpdateJob: true,
      agency: currentUserKey
    });
  };

  const handleCallbackInvite = reviewers => {
    const newReviewers = reviewers.map(reviewer => reviewer?.user_key);
    const currentReviewers = jobCurrent?.reviewers?.map(reviewer => reviewer?.key);
    const newReviewersList = [...newReviewers, ...currentReviewers]?.filter(item => !isEmpty(item));

    handleUpdateReviewers(newReviewersList)
      .then(() => {
        setShow(false);
        setInvites([]);
      }).finally(() => {
        setTimeout(() => {
          refresh();
        }, 1000);
      });
  };

  const handleInvite = async () => {
    await addNewUsers({
      invites: invites.map(({ email }) => ({ email, role: ROLE_STANDARD })),
      callback: handleCallbackInvite
    });
  };

  return (
    <>
      <div className="users__add-people" onClick={handleShow} role="presentation">
        <div className="users__add-people-icon">
          <PlusIcon />
        </div>
        <p className="n-font-small n-font-medium-weight n-grey-60">{t("modals.userManagement.addPeople")}</p>
      </div>
      <Modal dialogClassName="users add-users" show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="users__block">
            <button type="button" className="users__close" onClick={handleClose}>
              <Close fill="#9095A3" />
            </button>

            <h1 className="users__add-people-margin n-font-giant n-font-regular n-grey-60">
              {t("modals.userManagement.addPeopleTo")}
            </h1>
            <p className="users__add-people-margin n-font-small n-font-medium-weight n-grey">
              {`${jobCurrent?.title ?? ""}${t("job.candidate.for")}`}
              <span className="n-font-bold">{companyName}</span>
            </p>
            <div className="users__add-people-invites">
              <InviteUsers
                invites={invites}
                setInvites={setInvites}
                inviteError={inviteError}
                addNewUsersError={addNewUsersError}
              />
            </div>
            <button
              className="n-button__medium n-md n-bg-purple-100 n-white"
              type="button"
              disabled={!invites.length || errorCounter}
              onClick={handleInvite}
            >
              {t("button.invite")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

AddPeople.defaultProps = {
  jobCurrent: {},
  currentUserKey: "",
  companyName: undefined
};

AddPeople.propTypes = {
  t: PropTypes.func.isRequired,
  companyName: PropTypes.string,
  addNewUsers: PropTypes.func.isRequired,
  inviteError: PropTypes.string.isRequired,
  addNewUsersError: PropTypes.func.isRequired,
  jobCurrent: PropTypes.shape({
    key: PropTypes.string,
    reviewers: PropTypes.shape([]),
    title: PropTypes.string
  }),
  currentUserKey: PropTypes.string,
  updateJobUsersList: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired
};

export default withTranslation()(AddPeople);
