import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Lottie from "react-lottie";

import PageWrapper from "hoc/PageWrapper";
import Switch from "rc-switch";
import Header from "containers/Common/HeaderContainer";
import animationData from "assets/images/lock.json";

import Steps from "views/Security/Steps";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Security = ({
  t,
  newDevice,
  profileInfo: {
    mfa_device: mfaDevice,
    mfa_available: mfaAvailable,
    mfa_enabled: mfaEnabled
  },
  createDevice,
  setModal,
  confirmDevice,
  error,
  clearErrors,
  isSubscriptionFailed,
  isTrialCompleted,
  isSetupCompleted,
  history
}) => {
  const [step, setStep] = useState(null);

  const handleOn = () => setStep(0);
  const handleOff = () => {
    setModal({
      type: "deleteSecurityConfirmation"
    });
  };

  useEffect(() => {
    if (!mfaDevice) createDevice();
  }, [mfaDevice]);

  const handleClick = status => {
    if (status) {
      handleOn();
    } else {
      handleOff();
    }
  };

  return (
    <PageWrapper header={<Header />} prefix="security">
      <Container>
        <Col md={{ span: 10, offset: 1 }}>
          <div className="security__card">
            {step !== null ? (
              <Steps
                step={step}
                setStep={setStep}
                device={newDevice}
                confirmDevice={confirmDevice}
                createDevice={createDevice}
                error={error}
                clearErrors={clearErrors}
                isSubscriptionFailed={isSubscriptionFailed}
                isTrialCompleted={isTrialCompleted}
                isSetupCompleted={isSetupCompleted}
                history={history}
              />
            ) : (
              <>
                <Row>
                  <Col md={8}>
                    <h1 className="security__title n-font-huge">
                      {t("security.header")}
                    </h1>
                    <Row className="security__content">
                      <p className="security__subtitle n-font-small">
                        {t("security.subHeader")}
                      </p>
                      <Switch
                        onClick={handleClick}
                        checked={mfaDevice && mfaEnabled}
                        disabled={!mfaAvailable}
                      />
                    </Row>
                    <p className={`security__subtitle-${mfaDevice && mfaEnabled ? "green" : "red"} n-font-small`}>
                      {t(`security.${mfaDevice && mfaEnabled ? "secureAccount" : "extraLevel"}`)}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Lottie options={defaultOptions} height={150} width={180} />
                  </Col>
                </Row>
                {mfaDevice && mfaEnabled && (
                  <p className="security__subtitle-grey n-font-small">
                    {t("security.reEnable")}
                  </p>
                )}
              </>
            )}
          </div>
          <a
            href="https://support.willo.video/article/15-data-security"
            target="_blank"
            rel="noopener noreferrer"
            className="security__link n-font-small"
          >
            {t("security.learn")}
          </a>
        </Col>
      </Container>
    </PageWrapper>
  );
};

Security.propTypes = {
  t: PropTypes.func.isRequired,
  isSubscriptionFailed: PropTypes.bool.isRequired,
  isTrialCompleted: PropTypes.bool.isRequired,
  isSetupCompleted: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  profileInfo: PropTypes.shape.isRequired,
  newDevice: PropTypes.shape.isRequired,
  createDevice: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  confirmDevice: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default withTranslation()(Security);
