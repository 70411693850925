import React from "react";
import PropTypes from "prop-types";

import Cell from "../Cell";

const Row = ({
  data,
  handleSelection,
  chosenCalendarDates,
  isOverview,
  candidateName,
  candidateEmail,
  calendarTitle,
  utcMinutesDelta
}) => (
  <tr>
    {data.map((el, index) => (
      <Cell
        data={el}
        key={`calendar-cell-${String(index)}`}
        handleSelection={handleSelection}
        chosenCalendarDates={chosenCalendarDates}
        isOverview={isOverview}
        candidateName={candidateName}
        candidateEmail={candidateEmail}
        calendarTitle={calendarTitle}
        utcMinutesDelta={utcMinutesDelta}
      />
    ))}
  </tr>
);

Row.defaultProps = {
  chosenCalendarDates: undefined,
  utcMinutesDelta: undefined
};

Row.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ dateTimeISO: PropTypes.string }))
    .isRequired,
  handleSelection: PropTypes.func.isRequired,
  chosenCalendarDates: PropTypes.arrayOf(PropTypes.string),
  isOverview: PropTypes.bool.isRequired,
  candidateName: PropTypes.string.isRequired,
  candidateEmail: PropTypes.string.isRequired,
  calendarTitle: PropTypes.string.isRequired,
  utcMinutesDelta: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
};

export default Row;
