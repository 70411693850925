import { CloseOutline, Warning } from "mixins/svgIcons";
import React from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { getBackIconColor, getFontColorFromBgColor } from "mixins/helperDynamicStyles";

import "./styles.scss";
import { getCandidateJobInfoCompany } from "store/modules/сandidates/selectors";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

const FinalAttemptModal = ({
  t,
  show,
  handleOnHide,
  handleOnContinue,
  companyInfo,
  isOnlyAttempt = false
}) => {
  const color = !isEmpty(companyInfo.button_color) ? companyInfo.button_color : "#5A2AF1";
  return (
    <Modal dialogClassName="final-attempt__modal" show={show} onHide={handleOnHide} centered>
      <div className="final-attempt__modal-header">
        <div className="final-attempt__modal-btn" role="presentation" onClick={handleOnHide}>
          <CloseOutline onClick={handleOnHide} />
        </div>
        <div className="final-attempt__modal-warning">
          <Warning />
        </div>
      </div>
      <Modal.Body className="final-attempt__modal-body">
        <span className="final-attempt__modal-body__title">
          {t(`candidate.videoCreate.finalAttemptModal.${isOnlyAttempt ? "onlyAttempt" : "finalAttempt"}`)}
        </span>
        <span className="final-attempt__modal-body__subtitle">
          {t(`candidate.videoCreate.finalAttemptModal.${isOnlyAttempt ? "onlyDescription" : "finalDescription"}`)}
        </span>
        <button
          className="final-attempt__modal-body__btn-start"
          type="button"
          onClick={handleOnContinue}
          style={{
              backgroundColor: color,
              color: getFontColorFromBgColor({
                hex: color
              })
          }}
          disabled={typeof EBML === "undefined"}
          data-testid="InterviewFinalAttempt-Btn-StartRecording_Btn"
          aria-label="Start final attempt"
        >
          {t("button.recordStartJob")}
        </button>
        <button
          className="final-attempt__modal-body__btn-cancel"
          type="button"
          onClick={handleOnHide}
          style={{
            color: getBackIconColor({
              color: "#FFF",
              hex: color
            }),
            border: `1px solid ${getBackIconColor({
              color: "#FFF",
              hex: color
            })}`
          }}
          data-testid="InterviewFinalAttempt-Btn-Cancel_Btn"
          aria-label="Cancel attempt"
        >
          {t("button.back")}
        </button>
      </Modal.Body>
    </Modal>
  );
};

FinalAttemptModal.defaultProps = {
  show: undefined,
  handleOnHide: undefined,
  handleOnContinue: undefined,
  companyInfo: undefined,
  isOnlyAttempt: undefined
};

FinalAttemptModal.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool,
  handleOnHide: PropTypes.func,
  handleOnContinue: PropTypes.func,
  companyInfo: PropTypes.shape({
    layout_color: PropTypes.string,
    button_color: PropTypes.string
  }),
  isOnlyAttempt: PropTypes.bool
};

const mapStateToProps = state => ({
  companyInfo: getCandidateJobInfoCompany(state)
});

export default connect(mapStateToProps)(withTranslation()(FinalAttemptModal));
