import { connect } from "react-redux";

import { fetchCompaniesForTable } from "store/modules/companies/actions";

import { setModal } from "store/modules/modals/actions";

import {
  getIsLoading,
  getCompainesTableData
} from "store/modules/companies/selectors";

import { getCurrentUser } from "store/modules/users/selectors";

import Companies from "views/Companies";

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  currentUser: getCurrentUser(state),
  dataCompanies: getCompainesTableData(state)
});

const mapDispatchToProps = {
  setModal,
  fetchCompaniesForTable
};

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
