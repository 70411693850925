import { getCurrentUser } from "store/modules/users/selectors";
import { PLANS_NAMES } from "configs/plans";

export const getNotifications = state => state.notifications || {};

export const getIsTrialCompleted = state => {
  const {
    is_agency_locked: isAgencyLocked,
    show_trial_expired_page: showTrialExpiredPage
  } = state.notifications;

  return isAgencyLocked && showTrialExpiredPage;
};

export const getIsSubscriptionFailed = state => {
  const {
    is_agency_locked: isAgencyLocked,
    show_metered_subscription_unpaid_page: showMeteredSubscriptionUnpaidPage,
    show_primary_subscription_unpaid_page: showPrimarySubscriptionUnpaidPage
  } = state.notifications;

  return isAgencyLocked && (showMeteredSubscriptionUnpaidPage || showPrimarySubscriptionUnpaidPage);
};

export const getIsMeterSubscriptionUnpaid = state => {
  const {
    is_agency_locked: isAgencyLocked,
    show_metered_subscription_unpaid_page: showMeteredSubscriptionUnpaidPage
  } = state.notifications;

  return isAgencyLocked && showMeteredSubscriptionUnpaidPage;
};

export const getIsPrimarySubscriptionUnpaid = state => {
  const {
    is_agency_locked: isAgencyLocked,
    show_primary_subscription_unpaid_page: showPrimarySubscriptionUnpaidPage
  } = state.notifications;

  return isAgencyLocked && showPrimarySubscriptionUnpaidPage;
};

export const getIsTrialOrFree = state => {
  const { is_trial_period: isTrialPeriod } = state.notifications;
  const { plan_name: planName } = getCurrentUser(state);

  return planName === PLANS_NAMES.freeForever || isTrialPeriod;
};

export const getIsTrialOrPaid = state => {
  const { is_trial_period: isTrialPeriod } = state.notifications;
  const { plan_name: planName } = getCurrentUser(state);


  return planName !== PLANS_NAMES.freeForever || isTrialPeriod;
};

export const getIsTrial = state => {
  const { is_trial_period: isTrialPeriod } = state.notifications;
  const { plan_name: planName } = getCurrentUser(state);


  return planName !== PLANS_NAMES.freeForever && isTrialPeriod;
};

export const getIsPaid = state => {
  const { plan_name: planName } = getCurrentUser(state);

  return planName !== PLANS_NAMES.freeForever;
};

export const getIsFree = state => {
  const { plan_name: planName } = getCurrentUser(state);

  return planName === PLANS_NAMES.freeForever;
};

export const getIsIdvActivated = state => {
  const { idv_enabled: isIdvActivated } = getNotifications(state);

  return isIdvActivated;
};

export const getIsPaidUser = state => ([4, 5].includes(state?.users?.currentUser?.status)
&& !state?.notifications?.is_trial_period) ?? false;

export const getIsNotificationsLoading = state => state.notifications.notificationsLoading;
