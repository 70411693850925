/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import Header from "containers/Common/HeaderContainer";
import Breadcrumbs from "components/Breadcrumbs";
import Spinner from "components/Common/Spinner";
import PageWrapper from "hoc/PageWrapper";
import { editCompanyBreadcrumbs } from "mixins/dynamicRoutes";
import withLoaderScreen from "hoc/withLoaderScreen";

import CompanyForm from "../CompanyForm";

const Edit = ({
  t,
  isLoading,
  isAbiltyСreate,
  isCardInfoLoading,
  error,
  match: {
    params: { id = null }
  },
  industriesOptions,
  currentCompany,
  fetchIndustries,
  fetchCurrentCompany,
  editCompany,
  clearCompanyError
}) => {
  useEffect(() => {
    clearCompanyError();
    fetchIndustries();
  }, []);

  useEffect(() => {
    if (id) {
      fetchCurrentCompany(id);
    }
  }, [id]);

  // eslint-disable-next-line no-confusing-arrow
  const handleShowModals = () =>
    isAbiltyСreate
      ? false
      : window.open(
          "/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_branding",
          "_blank"
        );
  const handleSaveCompany = ({
    name,
    location,
    site,
    layoutColor,
    buttonColor,
    logo,
    industry,
    isDefault
  }) => {
    const patternHttp = /^https?:\/\//i;
    editCompany({
      id,
      name,
      location,
      site: site && (patternHttp.test(site) ? site : `https://${site}`),
      logo: typeof logo === "object" ? logo : null,
      layout_color: layoutColor,
      button_color: buttonColor,
      industry: industry.value,
      is_default: isDefault
    });
  };

  return (
    <PageWrapper header={<Header />}>
      <Container>
        <Row justify="center">
          <Col md={12}>
            <Breadcrumbs id={id} pathList={editCompanyBreadcrumbs(t)} />
          </Col>
          {isLoading ? (
            <Col md={{ span: 6, offset: 3 }}>
              <Spinner />
            </Col>
          ) : (
            <CompanyForm
              isAbiltyСreate={isAbiltyСreate}
              isCardInfoLoading={isCardInfoLoading}
              industriesOptions={industriesOptions}
              currentCompany={currentCompany}
              handleShowModals={handleShowModals}
              handleSaveCompany={handleSaveCompany}
              error={error}
            />
          )}
        </Row>
      </Container>
    </PageWrapper>
  );
};

Edit.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAbiltyСreate: PropTypes.bool.isRequired,
  isCardInfoLoading: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  industriesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.shape.isRequired,
  currentCompany: PropTypes.shape.isRequired,
  fetchIndustries: PropTypes.func.isRequired,
  fetchCurrentCompany: PropTypes.func.isRequired,
  editCompany: PropTypes.func.isRequired,
  clearCompanyError: PropTypes.func.isRequired
};

export default withLoaderScreen(withTranslation()(Edit));
