import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import HeaderCandidateJob from "components/Common/Header/Candidate/Job";
import Footer from "components/Common/Footer";

import PageWrapper from "hoc/PageWrapper";

import savedImg from "assets/images/candidate/saved.png";

import "./styles.scss";

const SavedForLaterView = ({
  t,
  jobInfoCompany = {},
  candidateInfo: { invite_link: inviteLink }
}) => {
  const memorizedCompanyInfo = useMemo(() => jobInfoCompany, [jobInfoCompany]);

  return (
    <PageWrapper
      header={<HeaderCandidateJob company={memorizedCompanyInfo} />}
      footer={(
        <Footer
          company={memorizedCompanyInfo}
          isPublic
        />
      )}
      overflowVisible
    >
      <Container>
        <Row bsPrefix="row candidate-start answers-saved__container">
          <Col md={{ span: 6, offset: 3 }}>
            <img src={savedImg} alt="chain" className="answers-saved__img" />
            <h1 className="answers-saved__title">
              {t("savedForLater.header")}
            </h1>
            <p className="answers-saved__text">{t("savedForLater.title")}</p>
            <p className="answers-saved__text">
              {t("savedForLater.description")}
            </p>
            {inviteLink && (
              <a
                href={inviteLink}
                className="answers-saved__text answers-saved__link"
              >
                {inviteLink}
              </a>
            )}
            <p className="answers-saved__text">{t("savedForLater.close")}</p>
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

SavedForLaterView.propTypes = {
  t: PropTypes.func.isRequired,
  jobInfoCompany: PropTypes.shape({}).isRequired,
  candidateInfo: PropTypes.shape({
    invite_link: PropTypes.oneOfType([PropTypes.string])
  }).isRequired
};

export default withTranslation()(SavedForLaterView);
