import { connect } from "react-redux";

import { setModal } from "store/modules/modals/actions";
import { getIsTrialOrPaid } from "store/modules/notifications/selectors";
import Templates from "components/Job/TemplatesComponent";

const mapStateToProps = state => ({
  librariesDivided: state.libraries.librariesDivided || {},
  isLoadingLibraries: state.libraries.isLoadingDivided,
  isTrialOrPaid: getIsTrialOrPaid(state)
});

const mapDispatchToProps = {
  setModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
