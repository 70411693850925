export const getDashboardLoading = state => state.dashboard.isLoading;

export const getDashboardDateRange = state => state.dashboard.dateRange;

export const getDashboardCanidates = state => state.dashboard.candidates;

export const getDashboardJobs = state => state.dashboard.jobs || [];

export const getError = state => state.dashboard.error;

export const getDashboardOnboarding = state => state.dashboard.onboarding || {};
