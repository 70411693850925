import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { CreditCard } from "mixins/svgIcons";

const AddCardButton = ({ t, handleUpgrade }) => (
  <div className="add-new-card">
    <CreditCard svgIconClass="add-new-card__icon" />

    <button
      type="button"
      className="add-new-card__button"
      onClick={handleUpgrade}
    >
      {t("billing.card.add")}
    </button>
  </div>
);

AddCardButton.propTypes = {
  t: PropTypes.func.isRequired,
  handleUpgrade: PropTypes.func.isRequired
};

export default withTranslation()(AddCardButton);
