import { handleActions } from "redux-actions";

import { setModal, removeModal } from "./actions";

const defaultState = {
  modalInfo: {
    type: ""
  }
};

export default handleActions(
  {
    [setModal]: (state, { payload }) => ({
      ...state,
      modalInfo: payload
    }),
    [removeModal]: state => ({
      ...state,
      modalInfo: {}
    })
  },
  defaultState
);
