import { stringToPhoneNumber } from "./helpers";
import countries from "./countries.json";

export function getCountryByISO(ISO) {
  return countries.find(country =>
    (ISO?.length === 2 ? ISO === country?.Iso2 : ISO === country?.Iso3));
}

export function getCountryByPhone(phone = "") {
  phone = stringToPhoneNumber(phone);
  return countries.find(country =>
    country?.Dial?.some(phoneNumber => phoneNumber && phone.indexOf(phoneNumber) === 0));
}

export function getTextUserLocation({ city, country_name: countryName } = {}, t) {
  switch (true) {
    case Boolean(city) && Boolean(countryName):
      return [city, countryName].join(", ");
    case Boolean(city) && !countryName:
      return city;
    case !city && Boolean(countryName):
      return countryName;
    default:
      return t("job.candidate.unknown_location");
  }
}
