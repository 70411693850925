import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  fetchProfileInfo
} from "store/modules/profile/actions";

import {
  getHasCurrentUserLoaded,
  getUsersTableData
} from "store/modules/users/selectors";

import PrivateWrapper from "hoc/PrivateWrapper";

const mapStateToProps = state => ({
  dataUsers: getUsersTableData(state),
  hasCurrentUserLoaded: getHasCurrentUserLoaded(state)
});

const mapDispatchToProps = dispatch => ({
  fetchProfileInfo: () => dispatch(fetchProfileInfo())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateWrapper));
