import { createAction } from "redux-actions";

export const fetchPeople = createAction("FETCH_PEOPLE");
export const fetchPeopleResponse = createAction("FETCH_PEOPLE_RESPONSE");
export const fetchPeopleError = createAction("FETCH_PEOPLE_ERROR");

export const fetchPeopleFilters = createAction("FETCH_PEOPLE_FILTERS");
export const fetchPeopleFiltersResponse = createAction("FETCH_PEOPLE_FILTERS_RESPONSE");
export const fetchPeopleFiltersError = createAction("FETCH_PEOPLE_FILTERS_Error");

export const fetchLatestPeople = createAction("FETCH_LATEST_PEOPLE");
export const fetchLatestPeopleResponse = createAction("FETCH_LATEST_PEOPLE_RESPONSE");
export const fetchLatestPeopleError = createAction("FETCH_LATEST_PEOPLE_ERROR");

export const fetchLatestPeopleFilterCompanies = createAction("FETCH_LATEST_PEOPLE_FILTER_COMPANIES");
export const fetchLatestPeopleFilterCompaniesResponse = createAction("FETCH_LATEST_PEOPLE_FILTER_COMPANIES_RESPONSE");
export const fetchLatestPeopleFilterCompaniesError = createAction("FETCH_LATEST_PEOPLE_FILTER_COMPANIES_ERROR");

export const fetchLatestPeopleFilterJobs = createAction("FETCH_LATEST_PEOPLE_FILTER_JOBS");
export const fetchLatestPeopleFilterJobsResponse = createAction("FETCH_LATEST_PEOPLE_FILTER_JOBS_RESPONSE");
export const fetchLatestPeopleFilterJobsError = createAction("FETCH_LATEST_PEOPLE_FILTER_JOBS_ERROR");

export const fetchLatestPeopleFilterOwners = createAction("FETCH_LATEST_PEOPLE_FILTER_OWNERS");
export const fetchLatestPeopleFilterOwnersResponse = createAction("FETCH_LATEST_PEOPLE_FILTER_OWNERS_RESPONSE");
export const fetchLatestPeopleFilterOwnersError = createAction("FETCH_LATEST_PEOPLE_FILTER_OWNERS_ERROR");

export const fetchSearchPeopleFilter = createAction("FETCH_SEARCH_PEOPLE_FILTER");
export const fetchSearchPeopleFilterResponse = createAction("FETCH_SEARCH_PEOPLE_FILTER_RESPONSE");
export const fetchSearchPeopleFilterError = createAction("FETCH_SEARCH_PEOPLE_FILTER_ERROR");

export const setPeopleLoading = createAction("SET_PEOPLE_LOADING");
export const setLatestPeopleLoading = createAction("SET_LATEST_PEOPLE_LOADING");
export const setPeopleFiltersLoading = createAction("SET_PEOPLE_FILTERS_LOADING");
export const setLatestPeopleFilterCompaniesLoading = createAction("SET_LATEST_PEOPLE_FILTER_COMPANIES_LOADING");
export const setLatestPeopleFilterRatingsLoading = createAction("SET_LATEST_PEOPLE_FILTER_RATINGS_LOADING");
export const setLatestPeopleFilterStatusesLoading = createAction("SET_LATEST_PEOPLE_FILTER_STATUSES_LOADING");
export const setLatestPeopleFilterJobsLoading = createAction("SET_LATEST_PEOPLE_FILTER_JOBS_LOADING");
export const setLatestPeopleFilterOwnersLoading = createAction("SET_LATEST_PEOPLE_FILTER_OWNERS_LOADING");
export const setSearchPeopleFilterLoading = createAction("SET_SEARCH_PEOPLE_FILTER_LOADING");
export const setPeoplesPageSelectedFilter = createAction("SET_SELECTED_FILTER");
export const resetPeoplesPageSelectedFilter = createAction("RESET_SELECTED_FILTER");
