import { handleActions, combineActions } from "redux-actions";

import {
  isJobsLoading,
  fetchCompaniesForQuestion,
  fetchCompaniesForQuestionResponse,
  fetchCompaniesForQuestionError,
  setFormSetupWasEdit,
  clearSetupState,
  createSetupInfo,
  clearErrors,
  createJob
} from "./actions";

const defaultState = {
  isLoading: false
};

export default handleActions(
  {
    [isJobsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [createSetupInfo]: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    [fetchCompaniesForQuestion]: state => ({
      ...state,
      isLoadingCompanies: true
    }),
    [fetchCompaniesForQuestionResponse]: (state, { payload }) => ({
      ...state,
      companiesOptionsResults: payload,
      isLoadingCompanies: false
    }),
    [fetchCompaniesForQuestionError]: state => ({
      ...state,
      isLoadingCompanies: false
    }),
    [setFormSetupWasEdit]: (state, { payload }) => ({
      ...state,
      isFormSetupWasEdit: payload
    }),
    [combineActions(clearSetupState, createJob)]: () => ({
      ...defaultState
    }),
    [clearErrors]: state => ({
      ...state,
      error: null
    })
  },
  defaultState
);
