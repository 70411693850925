import React, { useState, useRef } from "react";
import { Overlay, Popover as Body } from "react-bootstrap";
import PropTypes from "prop-types";

import { Info, Close } from "mixins/svgIcons";

const Popover = ({ children, svgIconClass, popoverText }) => {
  const [show, setShow] = useState(0);
  const target = useRef(null);

  return (
    <>
      <span ref={target} onMouseEnter={() => setShow(true)}>
        {popoverText ? (
          <p>{popoverText}</p>
        ) : (
          <Info svgIconClass={svgIconClass} />
        )}
      </span>
      <Overlay target={target.current} show={Boolean(show)} placement="bottom">
        {({ show: _show, ...props }) => (
          <Body {...props}>
            <div
              tabIndex="0"
              role="button"
              className="popover-close"
              onClick={() => setShow(false)}
              onKeyPress={() => {}}
            >
              <Close />
            </div>
            {children}
          </Body>
        )}
      </Overlay>
    </>
  );
};

Popover.defaultProps = {
  children: undefined,
  svgIconClass: undefined,
  popoverText: ""
};

Popover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  svgIconClass: PropTypes.string,
  popoverText: PropTypes.string
};

export default Popover;
