import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import countryList from "react-select-country-list";

import { nanoid } from "nanoid";
import CardField from "./CardField";

const billingFields = ["line1", "city", "postal_code", "country"];

const ShowCard = ({
  t,
  cardInfo: { last4 = "", exp_month: expMonth, exp_year: expYear },
  billingInfo
}) => {
  const countries = useMemo(() => countryList().getData(), []);
  const countryName =
    countries.find(({ value }) => value === billingInfo?.country)?.label || billingInfo?.country;

  return (
    <div className="show-form billing-information">
      {billingFields.map(field => (
        <CardField
          value={field === "country" ? countryName : billingInfo[field]}
          label={t(`billing.${field}`)}
          key={`card-${nanoid()}`}
        />
      ))}
      <div className="checkout-field checkout-field__card">
        <span className="checkout-label">{t("billing.cardDetails")}</span>
        <div className="show-form__row">
          <span className="checkout-value">{`**** **** **** ${last4 || ""}`}</span>
          <span className="show-form__date checkout-value">
            {` ${expMonth || ""} / ${expYear || ""}`}
          </span>
          <span className="checkout-value">***</span>
        </div>
      </div>
    </div>
  );
};

ShowCard.defaultProps = {
  cardInfo: {}
};

ShowCard.propTypes = {
  t: PropTypes.func.isRequired,
  cardInfo: PropTypes.shape({
    last4: PropTypes.string,
    exp_month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    exp_year: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  billingInfo: PropTypes.shape({
    country: PropTypes.string,
    line1: PropTypes.string,
    city: PropTypes.string,
    postal_code: PropTypes.string
  }).isRequired
};

export default withTranslation()(ShowCard);
