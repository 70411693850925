import React from "react";

export const Up = () => (
  <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0278 6.10547L0.0282781 6.01094L5.08477 0.058471L10.0278 6.10547Z" fill="#4D556B" />
  </svg>
);

export const Down = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 3H11L6 9L1 3Z" fill="#4D556B" />
  </svg>
);
