import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { getJobCurrent, getUserCurrentJob } from "store/modules/jobs/selectors";
import CandidateIdvCheck from "./CandidateIdvCheck";

const mapStateToProps = state => ({
  currentJob: getJobCurrent(state),
  candidateInfo: getUserCurrentJob(state)
});

export default connect(mapStateToProps)(withTranslation()(CandidateIdvCheck));
