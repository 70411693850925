import { connect } from "react-redux";

import { saveLanguage, saveLanguagePublic } from "store/modules/language/actions";
import { updateProfileLanguage } from "store/modules/profile/actions";
import { updateCandidateLang } from "store/modules/сandidates/actions";
import { getLanguage, getLanguagePublic } from "store/modules/language/selectors";
import { getLanguage as getUserLanguage } from "store/modules/profile/selectors";
import { getCurrentCandidateLang, getCurrentCandidateId, getCandidateToken } from "store/modules/сandidates/selectors";
import { getCommonJobLang } from "store/modules/common/selectors";

import DropdownLanguage from "components/Common/Dropdown/Language";

const mapStateToProps = (state, ownProps) => ({
  isPublic: ownProps.isPublic,
  currentLanguage: getLanguage(state),
  userLanguage: getUserLanguage(state),
  commonJobLang: getCommonJobLang(state),
  currentCandidateLang: getCurrentCandidateLang(state),
  currentCandidateId: getCurrentCandidateId(state),
  currentLanguagePublic: getLanguagePublic(state),
  candidateToken: getCandidateToken(state)
});

const mapDispatchToProps = {
  updateProfileLanguage,
  updateCandidateLang,
  saveLanguage,
  saveLanguagePublic
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownLanguage);
