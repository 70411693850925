import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import HeaderCandidateJob from "components/Common/Header/Candidate/Job";
import Footer from "components/Common/Footer";
import Candidate from "components/Candidates/Item";

import PageWrapper from "hoc/PageWrapper";

import withDocumentTitle from "hoc/withDocumentTitle";
import ShowcasePromotions from "containers/Candidate/ShowcasePromotions/ShowcasePromotions";

const Public = ({
  match: {
    params: { tokenId }
  },
  location: {
    pathname
  },
  publicUserInfo: {
    company = {},
    configuration: { showcase_promotions_enabled: showcasePromotionsEnabled } = {}
  },
  publicUserInfo,
  fetchPublicUserInfo,
  clearErrors
}) => (
  <PageWrapper
    header={(
      <HeaderCandidateJob
        company={company}
        pathname={
          showcasePromotionsEnabled
          ? `/sign-up?utm_source=showcase&utm_medium=cv-video&utm_campaign=candidates+distibution&utm_id=header&back=${encodeURIComponent(pathname)}`
          : `/jobs/candidate/${publicUserInfo?.candidate?.key}/${publicUserInfo?.candidate?.job_key}?back=${encodeURIComponent(pathname)}`}
        className="public-candidate__logo"
      />
    )}
    footer={<Footer company={company} isPublic />}
  >
    <Candidate
      tokenId={tokenId}
      publicUserInfo={publicUserInfo}
      fetchPublicUserInfo={fetchPublicUserInfo}
      clearErrors={clearErrors}
      buttonColor={company?.button_color}
    />

    <ShowcasePromotions />
  </PageWrapper>
);

Public.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      tokenId: PropTypes.string
    })
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  publicUserInfo: PropTypes.shape({
    candidate: PropTypes.shape({
      key: PropTypes.string,
      job_key: PropTypes.string
    }),
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    company: PropTypes.shape({}),
    configuration: PropTypes.shape({
      showcase_promotions_enabled: PropTypes.bool
    })
  }).isRequired,
  fetchPublicUserInfo: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired
};

export default withTranslation()(withDocumentTitle(Public));
