/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { Dropdown } from "react-bootstrap";
import Select from "react-select";

import DropdownIndicator from "components/Forms/Select/dropdown";
import { ArrowUp, ArrowDown } from "mixins/svgIcons";

import "./styles.scss";
import { store } from "store";
import { getWebhooksLogs } from "store/modules/marketplace/actions";
import { getIsDeveloperToolHeader } from "store/modules/marketplace/selectors";
import { clearCompanies } from "store/modules/companies/actions";

const Menu = ({
  t,
  profileInfo,
  currentUser,
  usersList,
  handleOnboarding,
  logOutUser,
  isAdmin,
  isAppEnabled,
  isSubscriptionFailed,
  isSetupCompleted,
  isTrialOrPaid,
  getCurrentAgency,
  fetchProfileInfo
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const isDeveloperTool = getIsDeveloperToolHeader(store.getState());

  useEffect(() => {
    if (profileInfo?.email && currentUser?.role) {
      Sentry.configureScope(scope => {
        scope.setUser({
          id: currentUser.key,
          username: currentUser.role,
          email: profileInfo?.email
        });
      });
    }
  }, [profileInfo?.email, currentUser?.role]);

  const options = useMemo(
    () =>
      usersList &&
      usersList.map(user => ({
        label: user.name,
        value: user
      })),
    [usersList]
  );

  const value = { label: currentUser.name, value: currentUser };

  const avatarName = profileInfo.full_name
    ? profileInfo.full_name
        .split(/\s/)
        // eslint-disable-next-line no-return-assign
        .reduce((name, word) => (name += word.slice(0, 1)), "")
    : "";

  const handleSelectUser = ({ value: user }) => {
    store.dispatch(clearCompanies());
    getCurrentAgency({ agencyKey: user.key });
    handleOnboarding(user);

    if (currentUser?.key !== user.key && isDeveloperTool) {
      store.dispatch(getWebhooksLogs({ agencyKey: user?.key }));
    }
  };

  return (
    <div className="dropdown-menu__wrapper">
      <div className="nav-user-select-desktop">
        {options.length === 1 ? (
          <div className="nav-user-select-single">
            <p>{options[0].label}</p>
          </div>
        ) : (
          <Select
            className="comapany__chart-select"
            classNamePrefix="select"
            options={options}
            isSearchable={false}
            onChange={handleSelectUser}
            menuPlacement="bottom"
            value={value}
            components={{
              DropdownIndicator: props => (
                <DropdownIndicator
                  {...props}
                  height="12"
                  width="12"
                  fill="#5A2AF1"
                />
              )
            }}
          />
        )}
      </div>
      <Dropdown
        onToggle={(isOpen, event, metadata) => {
          if (metadata.source !== "select") {
            setMenuIsOpen(isOpen);
          }
        }}
      >
        <Dropdown.Toggle id="dropdown-basic" as="div">
          <button type="button" className="nav-user-select-desktop__avatar">
            <span className="n-font-small n-font-semi-bold n-uppercase n-text-center white">
              {avatarName}
            </span>
          </button>
          {menuIsOpen ? (
            <ArrowUp height="12" width="12" fill="#5A2AF1" />
          ) : (
            <ArrowDown height="12" width="12" fill="#5A2AF1" />
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight bsPrefix="dropdown-menu">
          {isAppEnabled && (
            <Link
              className="dropdown-item"
              to="/profile"
              onClick={() => fetchProfileInfo()}
            >
              <span>{t("link.profile")}</span>
            </Link>
          )}

          {profileInfo.mfa_available && (
            <Link className="dropdown-item" to="/security">
              <span>{t("link.security")}</span>
            </Link>
          )}

          {!isSubscriptionFailed && isSetupCompleted && isAdmin && (
            <Link className="dropdown-item" to="/users">
              <span>{t("header.users")}</span>
            </Link>
          )}

          {isAppEnabled && isTrialOrPaid && (
            <Link className="dropdown-item" to="/libraries">
              <span>{t("link.libraries")}</span>
            </Link>
          )}

          {isAdmin && (
            <Link className="dropdown-item" to="/scorecards">
              <span>{t("link.scorecards")}</span>
            </Link>
          )}

          <div>
            <a
              href="https://support.willo.video"
              target="_blank"
              rel="noopener noreferrer"
              className="dropdown-item"
            >
              <span className="n-grey-100">{t("link.support")}</span>
            </a>
          </div>

          <Dropdown.Divider />

          <Dropdown.Item onClick={logOutUser}>
            <span className="n-grey-100">{t("link.logOut")}</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

Menu.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
    owner: PropTypes.shape({
      email: PropTypes.string
    }),
    role: PropTypes.string
  }).isRequired,
  profileInfo: PropTypes.shape({
    agency: PropTypes.string,
    mfa_available: PropTypes.bool,
    full_name: PropTypes.string,
    email: PropTypes.string
  }).isRequired,
  usersList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleOnboarding: PropTypes.func.isRequired,
  logOutUser: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isAppEnabled: PropTypes.bool.isRequired,
  isSubscriptionFailed: PropTypes.bool.isRequired,
  isSetupCompleted: PropTypes.bool.isRequired,
  isTrialOrPaid: PropTypes.bool.isRequired,
  getCurrentAgency: PropTypes.func.isRequired,
  fetchProfileInfo: PropTypes.func.isRequired
};

export default withTranslation()(Menu);
