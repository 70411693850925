export const funcs = {
  mobile: () => {},
  desktop: () => {},
  btns: () => {},
  desktopBlobUrl: () => {}
};

export function setMobile(setter) {
  funcs.mobile = () => setter(null);
}

export function setDesktop(setter, btnsSetter, desktopBlobUrl) {
  funcs.desktop = () => setter(null);
  funcs.btns = () => btnsSetter({ btnStart: false, btnStop: false });
  funcs.desktopBlobUrl = () => desktopBlobUrl(null);
}

export function clearFuncs() {
  funcs.mobile = () => {};
  funcs.desktop = () => {};
  funcs.btns = () => {};
  funcs.desktopBlobUrl = () => {};
}

export function callAll() {
  funcs.mobile();
  funcs.desktop();
  funcs.btns();
  funcs.desktopBlobUrl();
}
