import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { SidebarCloseIcon } from "mixins/svgIcons";
import { setSidebarOpen } from "store/modules/jobs/actions";
import { store } from "store";
import { getCurrentUser } from "store/modules/users/selectors";
import useTranscrpitionStore from "store/transcriptionStore.ts";
import { getIsJobCandidateLoading, getUserCurrentJob } from "store/modules/jobs/selectors";
import CandidateSidebarComments from "./CandidateSidebarComments";
import CandidateSidebarTranscriptions from "./CandidateSidebarTranscriptions/CandidateSidebarTranscriptions";
import CandidateSidebarSummary from "./CandidateSidebarSummary/CandidateSidebarSummary.tsx";
import CandidateSidebarUpsell from "./CandidateSidebarUpsell/CandidateSidebarUpsell.tsx";
import "./index.scss";
import { SUMMARY_STATUS } from "../../../../../types/summaries.ts";

const CandidateSidebar = ({
  isSidebarOpen,
  isSummaryDisabled,
  isTranscriptionDisabled = true,
  currentCandidateId,
  isJobCandidateLoading
}) => {
  const { setSearchString } = useTranscrpitionStore();

  useEffect(() => {
    if (isJobCandidateLoading && currentCandidateId) {
      setSearchString("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCandidateId, isJobCandidateLoading]);

  if (!isSidebarOpen) return null;

  if (isSummaryDisabled && isSidebarOpen === "summary") {
    return (
      <div className="candidate__sidebar-container h-full bg-secondary-violet">
        <div className="transcription__sidebar-container upsell h-full">
          <div className="candidate__sidebar-comments__header flex w-full items-center justify-between">
            <div style={{ height: 27 }} />
            <SidebarCloseIcon
              style={{ pointerEvents: "all", cursor: "pointer" }}
              onClick={() => store.dispatch(setSidebarOpen(false))}
            />
          </div>

          <CandidateSidebarUpsell />
        </div>
      </div>
    );
  }

  if (isTranscriptionDisabled && isSidebarOpen === "transcription") {
    return (
      <div className="candidate__sidebar-container h-full bg-secondary-violet">
        <div className="transcription__sidebar-container upsell h-full">
          <div className="candidate__sidebar-comments__header flex w-full items-center justify-between">
            <div style={{ height: 27 }} />
            <SidebarCloseIcon
              style={{ pointerEvents: "all", cursor: "pointer" }}
              onClick={() => store.dispatch(setSidebarOpen(false))}
            />
          </div>

          <CandidateSidebarUpsell />
        </div>
      </div>
    );
  }

  return (
    <div className="candidate__sidebar-container flex flex-col" id="candidate__sidebar-container">
      { isSidebarOpen === "transcription" ? <CandidateSidebarTranscriptions /> : null }
      { isSidebarOpen === "summary" ? <CandidateSidebarSummary /> : null }
      <div className={`${isSidebarOpen === "comment" ? "flex flex-col h-full" : "hidden"}`}>
        <CandidateSidebarComments />
      </div>
    </div>
  );
};

CandidateSidebar.propTypes = {
  isSidebarOpen: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  isSidebarOpen: state?.jobs?.isSidebarOpen,
  isSummaryDisabled: [SUMMARY_STATUS.DISABLED, SUMMARY_STATUS.ENABLED].includes(
    getCurrentUser(state)?.answers_summaries_status
  ),
  isTranscriptionDisabled: [SUMMARY_STATUS.DISABLED, SUMMARY_STATUS.ENABLED].includes(
    getCurrentUser(state)?.answers_transcripts_status
  ),
  currentCandidateId: getUserCurrentJob(state)?.key,
  isJobCandidateLoading: getIsJobCandidateLoading(state)
});

export default connect(mapStateToProps)(CandidateSidebar);
