import * as Yup from "yup";

export const inviteConfig = ({
  email = ""
} = {}) => ({
  email
});

export const inviteFormSchema = () =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email("errors.validEmail")
      .required("errors.required")
  });
