import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Select from "react-select";

import DropdownIndicator from "components/Forms/Select/dropdown";

import Buttons from "./Buttons";

const sizes = [{
  label: "onboarding.sizes.0",
  value: 1
}, {
  label: "onboarding.sizes.1",
  value: 2
}, {
  label: "onboarding.sizes.2",
  value: 3
}];

const StepCompanySize = ({ t, setStep, updateAgency, size }) => {
  const [newSize, setSize] = useState(null);
  const handlePrevStep = () => setStep(2);
  const handleNextStep = () => {
    setStep(4);
    updateAgency({ is_organisation_size_provided: true });
  };
  const handleClick = () => {
    updateAgency({
      size: newSize.value,
      is_organisation_size_provided: true
    });
    setStep(4);
  };

  useEffect(() => {
    const sizeValue = sizes.find(({ value }) => value === size);
    if (size && sizeValue) {
      setSize(sizeValue);
    }
  }, [size]);

  return (
    <>
      <div className="onboard__card">
        <Row className="onboard__flex">
          <Col md={12} className="onboard__card-title">
            <div>
              <h1 className="onboard__title n-grey-100 n-font-semi-bold">
                {t("onboarding.howBig")}
              </h1>
              <p className="onboard__subtitle n-grey-100 n-font-large n-font-medium-weight">
                {t("onboarding.findPlan")}
              </p>

              <Select
                className="comapany__chart-select onboard__select"
                classNamePrefix="select"
                options={sizes}
                value={newSize}
                onChange={setSize}
                getOptionLabel={({ label }) => t(label)}
                placeholder={t("onboarding.organisationSize")}
                components={{
                  DropdownIndicator: props => (
                    <DropdownIndicator
                      {...props}
                      height="12"
                      width="12"
                      fill="#768493"
                    />
                  )
                }}
              />
            </div>
            <div className="onboard__next">
              <button
                className="onboard__button n-button__medium n-bg-purple-100 n-white"
                type="button"
                onClick={handleClick}
                disabled={!newSize}
              >
                {t("button.nextStep")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <Buttons
        handlePrevStep={handlePrevStep}
        handleNextStep={handleNextStep}
      />
    </>
  );
};

StepCompanySize.propTypes = {
  t: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  updateAgency: PropTypes.func.isRequired,
  size: PropTypes.number.isRequired
};

export default withTranslation()(StepCompanySize);
