/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";
import { convertToRaw, EditorState, Modifier } from "draft-js";
import draftToHtml from "draftjs-to-html";

import Input from "components/Common/Input";
import Select from "components/Common/Select";
import TextEditor from "components/Common/TextEditor";
import { updateCurrentStateHandler, checkIfFormIsValid, prefilledInfo } from "mixins/helpers";
import config, { Types, Triggers, TriggersSms, InviteLink, Variables as VariablesOptions } from "configs/libraries/template";

import TooltipInfo from "components/Common/Tooltip/Info";

import { Eye } from "./svgIcons";
import "../styles.scss";

function findWithRegex(regex, contentBlock, callback) {
  if (!regex) return;
  const text = contentBlock.getText();
  let matchArr;
  let start;
  // eslint-disable-next-line no-cond-assign
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

const Spam = ({ t, children, type, ...rest }) => (
  <TooltipInfo
    element={null}
    position="bottom"
    message={t(`libraries.tooltip.${type}`)}
  >
    <span {...rest} className={`${type}-words`}>
      {children}
    </span>
  </TooltipInfo>
);

Spam.propTypes = {
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const Variables = ({ editorState, onChange, type }) => {
  const [newValue, setValue] = useState();
  const addVariable = ({ value }) => {
    setValue(value);
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      value,
      editorState.getCurrentInlineStyle()
    );
    const newEditorState = EditorState.push(editorState, contentState, "insert-characters");
    onChange(newEditorState);
    onChange(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));
  };

  return (
    <Select
      formElement={{
        ...config.variables,
        value: newValue,
        options: type === "email" ? VariablesOptions : [...VariablesOptions, InviteLink]
      }}
      inputChangedHandler={addVariable}
      className="variable__select"
    />
  );
};

Variables.propTypes = {
  editorState: PropTypes.shape({
    getCurrentContent: PropTypes.func,
    getSelection: PropTypes.func,
    getCurrentInlineStyle: PropTypes.func
  }).isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const Template = ({ t, currentLibrary, handleSaveLibrary, error, clearLibraryError, setModal }) => {
  const editRef = useRef();
  const firstUpdate = useRef(true);
  const [controls, setControls] = useState(config);
  const [formIsValid, setFormIsValid] = useState(false);
  const [inviteLinkMissing, setInviteLinkMissing] = useState(false);
  const [errorVariables, setErrorVariables] = useState(false);
  const [ambers, setAmberRegex] = useState([]);
  const [reds, setRedRegex] = useState([]);
  const amberStrategy = (contentBlock, callback) => findWithRegex(ambers, contentBlock, callback);
  const redStrategy = (contentBlock, callback) => findWithRegex(reds, contentBlock, callback);
  const setEditorReference = ref => { editRef.current = ref; };
  const [decorator, setDecorator] = useState([]);
  const hasSpamWords =
    controls.content.value.getCurrentContent().getPlainText().search(reds) !== -1;

  useEffect(() => {
    clearLibraryError();
  }, []);
  useEffect(() => {
    if (currentLibrary) {
      const predefinedControls = prefilledInfo(controls, currentLibrary ? {
        ...currentLibrary,
        type: Types.find(({ value }) => value === currentLibrary.type) || Types[0],
        trigger: Triggers.find(({ value }) => value === currentLibrary.trigger) || Triggers[0]
      } : null) || controls;
      const formIsValidCurrent = checkIfFormIsValid(predefinedControls);

      setControls(predefinedControls);
      setFormIsValid(formIsValidCurrent);
    }
  }, [currentLibrary?.key]);

  useEffect(() => {
    setRedRegex(error.red?.length ? error.red : []);
    setAmberRegex(error.amber?.length ? error.amber : []);
  }, [error]);

  useEffect(() => {
    const decorators = [{
      strategy: amberStrategy,
      component: props => <Spam {...props} t={t} type="amber" />
    }, {
      strategy: redStrategy,
      component: props => <Spam {...props} t={t} type="red" />
    }];
    setDecorator(decorators);

    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      setTimeout(() => {
        if (editRef.current) {
          editRef.current.blur();
          editRef.current.focus();
        }
      }, 500);
    }
  }, [ambers, reds]);

  const inputChangedHandler = (event, controlName, type) => {
    const updatedControls = updateCurrentStateHandler(event, controls, controlName, type);

    setControls(updatedControls);
    setFormIsValid(checkIfFormIsValid(updatedControls));

    if (
      controlName === "type" &&
      event.value === "sms" &&
      controls.trigger.value.value === "success_email") {
      // eslint-disable-next-line prefer-destructuring
      updatedControls.trigger.value = TriggersSms[0];
    }
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = event => {
    event.preventDefault();

    const smsContent = controls.content.value.getCurrentContent().getPlainText();
    const matches = smsContent.match(/\{([\s\S]*?)\}/g);
    const variables = [...VariablesOptions, InviteLink].map(({ value }) => value);

    if (matches?.find(variable => !variables.includes(variable))) {
      return setErrorVariables(true);
    }
    setErrorVariables(false);
    if (controls.type.value.value === "sms" && !smsContent.includes("{link}")) {
      return setInviteLinkMissing(true);
    }
    setInviteLinkMissing(false);

    const saveLibrary = (pass = false, callback) =>
      handleSaveLibrary({
        title: controls.title.value,
        type: controls.type.value.value,
        trigger: controls.trigger.value.value,
        content: controls.type.value.value === "email"
          ? draftToHtml(convertToRaw(controls.content.value.getCurrentContent())) : smsContent,
        pass_spam_anyway: pass,
        key: currentLibrary?.key,
        callback
      });
    const callback = () =>
      setModal({
        type: "spamWarning",
        saveLibrary: () => saveLibrary(true)
      });

    saveLibrary(false, callback);
  };

  const handlePreview = () =>
    setModal({
      type: "previewTemplate",
      templateInfo: {
        type: controls.type.value.value,
        content: controls.type.value.value === "email"
          ? draftToHtml(convertToRaw(controls.content.value.getCurrentContent()))
          : controls.content.value.getCurrentContent().getPlainText(),
        label: controls.title.value,
        trigger: controls.trigger.value.value
      }
    });

  return (
    <Col md={12}>
      <form className="form-description" onSubmit={handleSubmit}>
        <Container>
          <Row>
            <Col md={6}>
              <Input
                name="title"
                formElement={controls.title}
                inputChangedHandler={inputChangedHandler}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Select
                name="type"
                formElement={controls.type}
                inputChangedHandler={inputChangedHandler}
                disabled={currentLibrary?.is_in_use}
              />
            </Col>
            <Col md={6}>
              <Select
                name="trigger"
                formElement={{
                  ...controls.trigger,
                  options: controls.type.value.value === "sms" ? TriggersSms : Triggers
                }}
                inputChangedHandler={inputChangedHandler}
                disabled={currentLibrary?.is_in_use}
                RightIconLabel={() => (
                  <TooltipInfo
                    position="top"
                    message={t("libraries.tooltip.trigger")}
                  />
                )}
              />
            </Col>

            <Col md={12}>
              {currentLibrary?.is_in_use ? (
                <p className="n-font-small n-black-100 n-text-center">
                  {t("libraries.errors.templateInUse")}
                </p>
              ) : null}
              <TextEditor
                reds={reds}
                ambers={ambers}
                editRef={setEditorReference}
                name="content"
                type="texteditor"
                formElement={controls.content}
                inputChangedHandler={inputChangedHandler}
                invalid={!controls.content.valid}
                withError={(error.red?.length && hasSpamWords) || inviteLinkMissing}
                withCounter
                withSmsCounter={controls.type.value.value === "sms"}
                toolbarCustomButtons={[
                  <Variables type={controls.type.value.value} />
                ]}
                {
                  ...Types[1].value === controls.type.value?.value ? { toolbarOptions: [] } : null
                }
                customDecorators={decorator}
                LabelRight={() => (
                  <div
                    role="presentation"
                    className="label__main preview"
                    onClick={() => handlePreview()}
                  >
                    <Eye />
                    {t("select.preview")}
                  </div>
                )}
              />
              {(error.red?.length && hasSpamWords) ? (
                <p className="text-editor__error usage">
                  {t("libraries.errors.usagePolicy")}
                </p>
              ) : null}

              {inviteLinkMissing ? (
                <p className="text-editor__error usage">
                  {t("libraries.errors.inviteLinkMissing")}
                </p>
              ) : null}
              {errorVariables ? (
                <p className="text-editor__error usage">
                  {t("libraries.errors.invalidUse")}
                </p>
              ) : null}

              <div className="button__group button__group_center">
                <button
                  id="job-next-1-button"
                  type="submit"
                  className="n-button__medium n-bg-purple-100 n-white"
                  disabled={!formIsValid}
                >
                  {t("button.save")}
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </form>
    </Col>
  );
};

Template.propTypes = {
  t: PropTypes.func.isRequired,
  currentLibrary: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    trigger: PropTypes.string,
    content: PropTypes.string,
    pass_spam_anyway: PropTypes.bool,
    is_in_use: PropTypes.bool
  }).isRequired,
  error: PropTypes.shape({
    amber: PropTypes.array,
    red: PropTypes.array
  }).isRequired,
  clearLibraryError: PropTypes.func.isRequired,
  handleSaveLibrary: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired
};

export default withTranslation()(Template);
