import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

const Head = () => {
  const { t } = useTranslation();

  return (
    <div className="application">
      <Helmet>
        <meta name="author" content={t("author")} />
        <meta
          name="description"
          property="og:description"
          content={t("description")}
        />
        <title>{t("title")}</title>
      </Helmet>
    </div>
  );
};

export default Head;
