import React from "react";
import PropTypes from "prop-types";

const Labels = ({ children }) => (
  <div className="progress__moving-labels">{children}</div>
);

Labels.defaultProps = {
  children: undefined
};

Labels.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default Labels;
