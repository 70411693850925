import React from "react";
import PropTypes from "prop-types";

const Right = ({ position, totalValue, currentValue, style }) => {
  const curVal = currentValue > totalValue ? totalValue : currentValue;

  const max = totalValue > curVal ? totalValue : curVal;
  const percent = 100 / max;
  const marginLeft = totalValue * percent;

  return (
    <span className="right" style={{ marginLeft: `${marginLeft}%`, position: "absolute", marginTop: "10px" }}>
      <span className={`right-text ${position}`} style={style}>{totalValue}</span>
    </span>
  );
};

Right.defaultProps = {
  position: "",
  totalValue: 0,
  currentValue: 0,
  style: null
};

Right.propTypes = {
  position: PropTypes.string,
  currentValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape({})
};

export default Right;
