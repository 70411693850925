import { createAction } from "redux-actions";

export const getIdvBundles = createAction("GET_IDV_BUNDLES");
export const getIdvBundlesLoading = createAction("GET_IDV_BUNDLES_LOADING");
export const getIdvBundlesError = createAction("GET_IDV_BUNDLES_ERROR");
export const setIdvBundles = createAction("SET_IDV_BUNDLES");

export const getAgencyIdvPurchased = createAction("GET_AGENCY_IDV_PURCHASED");
export const getAgencyIdvPurchasedLoading = createAction("GET_AGENCY_IDV_PURCHASED_LOADING");
export const getAgencyIdvPurchasedError = createAction("GET_AGENCY_IDV_PURCHASED_ERROR");
export const setAgencyIdvPurchased = createAction("SET_AGENCY_IDV_PURCHASED");

export const getAgencyIdvUsage = createAction("GET_AGENCY_IDV_USAGE");
export const getAgencyIdvUsageLoading = createAction("GET_AGENCY_IDV_USAGE_LOADING");
export const getAgencyIdvUsageError = createAction("GET_AGENCY_IDV_USAGE_ERROR");
export const setAgencyIdvUsage = createAction("SET_AGENCY_IDV_USAGE");

export const getIdvCheckoutLink = createAction("GET_IDV_CHECKOUT_LINK");
export const isIdvCheckoutLinkLoading = createAction("IS_IDV_CHECKOUT_LINK_LOADING");
export const getIdvCheckoutLinkError = createAction("GET_IDV_CHECKOUT_LINK_ERROR");
export const getClearPaymentStatus = createAction("GET_CLEAR_PAYMENT_STATUS");

export const getIdvPaymentStatus = createAction("GET_IDV_PAYMENT_STATUS");
export const getIdvPaymentStatusLoading = createAction("GET_IDV_PAYMENT_STATUS_LOADING");
export const getIdvPaymentStatusError = createAction("GET_IDV_PAYMENT_STATUS_ERROR");
export const setIdvPaymentStatus = createAction("SET_IDV_PAYMENT_STATUS");

export const getIdvPaygPrice = createAction("GET_IDV_PAYG_PRICE");
export const getIdvPaygPriceLoading = createAction("GET_IDV_PAYG_PRICE_LOADING");
export const getIdvPaygPriceError = createAction("GET_IDV_PAYG_PRICE_ERROR");
export const setIdvPaygPrice = createAction("SET_IDV_PAYG_PRICE");

export const getIdvSession = createAction("GET_IDV_SESSION");
export const getIdvSessionLoading = createAction("GET_IDV_SESSION_LOADING");
export const getIdvSessionError = createAction("GET_IDV_SESSION_ERROR");
export const setIdvSession = createAction("SET_IDV_SESSION");

export const setIsPricesDataFetched = createAction("IS_PRICES_DATA_FETCHING");
export const fetchIdvPrices = createAction("FETCH_IDV_PRICES");
