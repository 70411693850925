import React from "react";
import PropTypes from "prop-types";

import { getRatingToString } from "mixins/helperCandidate";
import TooltipInfo from "components/Common/Tooltip/Info";
import { withTranslation } from "react-i18next";

const RadioStar = ({ t, id, value, label, handleRating, active, ...props }) => {
  const rating = getRatingToString({ rating: label, isActive: active, t });

  return (
    <TooltipInfo position="top" message={rating?.label}>
      <div className="input__rating">
        <input
          id={id}
          type="radio"
          value={id} // could be something else for output?
          checked={id === value}
          onChange={handleRating(label)}
          className="input__rating-item"
          {...props}
        />
        <label className="input__rating-label" htmlFor={id}>
          { rating?.icon }
        </label>
      </div>
    </TooltipInfo>
    );
};

RadioStar.defaultProps = {
  handleRating: undefined,
  value: undefined,
  active: false
};

RadioStar.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.number.isRequired,
  handleRating: PropTypes.func,
  active: PropTypes.bool
};

export default withTranslation()(RadioStar);
