import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

export default FieldCollectionWrapper => {
  const Wrapper = ({ activeItem, setActiveItem, order, ...rest }) => {
    const itemRef = useRef(null);
    const [isHovering, setIsHovering] = useState(false);

    const isActive = activeItem === order;

    const handleClickOutside = useCallback(
      ev => {
        const classNames = ev.target.getAttribute("class") || "";
        const parentElement = get(ev, "target.parentElement", "") || "";
        const parentClassNames =
          // eslint-disable-next-line no-nested-ternary
          typeof parentElement === "string"
            ? parentElement?.className || ""
            : typeof parentElement === "object"
            ? parentElement?.getAttribute("class") || ""
            : "";

        if (itemRef?.current?.contains(ev.target)) {
          let activeOrder = order;
          let timeOut = 10;

          if (classNames?.includes("do-not-activate") || parentClassNames?.includes("do-not-activate")) {
            const isDuplicateBtn = classNames?.includes("field-duplicate") || parentClassNames?.includes("field-duplicate");
            const isDeleteBtn = classNames?.includes("field-delete") || parentClassNames?.includes("field-delete");
            const isUpBtn = classNames?.includes("field-up") || parentClassNames?.includes("field-up");
            const isDownBtn = classNames?.includes("field-down") || parentClassNames?.includes("field-down");
            const isDisabled = classNames?.includes("disabled") || parentClassNames?.includes("disabled");

            if ((isDuplicateBtn || isDownBtn) && !isDisabled) activeOrder += 1;

            if (isUpBtn && !isDisabled) activeOrder -= 1;

            if (isDeleteBtn && !isActive) activeOrder = null;

            // run it slower so click events will take event before activating the item
            timeOut = isDeleteBtn ? 250 : 180;
          }

          setTimeout(() => {
            setActiveItem(activeOrder);
            setIsHovering(false);
          }, timeOut);
        }
      },
      [isActive, order, setActiveItem]
    );

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleClickOutside]);

    return (
      <FieldCollectionWrapper
        itemRef={itemRef}
        isActive={isActive}
        order={order}
        isHovering={isHovering}
        setIsHovering={setIsHovering}
        {...rest}
      />
    );
  };

  Wrapper.defaultProps = {
    activeItem: undefined,
    order: undefined
  };

  Wrapper.propTypes = {
    activeItem: PropTypes.number,
    setActiveItem: PropTypes.func.isRequired,
    order: PropTypes.number
  };

  return Wrapper;
};
