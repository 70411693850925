/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { withRouter } from "react-router";
import { getCurrentQuestions } from "store/modules/jobs/selectors";
import { nanoid } from "nanoid";
import { useTranslation, withTranslation } from "react-i18next";
import "./styles.scss";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { store } from "store";
import PropTypes from "prop-types";
import {
  getIsPaid,
  getIsTrialOrFree
} from "store/modules/notifications/selectors";
import { getCurrentUser } from "store/modules/users/selectors";
import { ROLE_ADMIN, ROLE_STANDARD } from "configs/user/rolesConfig";
import IdvConnectModal from "components/Modals/IdvConnectModal";
import countries from "i18n-iso-countries";
import {
  getIntegrations,
  getIntegrationsResponse
} from "store/modules/marketplace/actions";
import { getIsIdvActivated } from "store/modules/marketplace/selectors";
import {
  ALL_IDENTITY_VERIFICATION_TYPES,
  CHECKBOXES_TYPE,
  IDV_CHECK,
  IDV_TYPE,
  IDV_TYPES,
  MULTIPLE_CHOICE_TYPE
} from "configs/jobs/constants";
import Message from "components/Common/Message";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import FieldCollection from "components/FieldCollection.js";
import { updateCurrentJobQuestions } from "store/modules/jobs/actions";
import AddQuestion from "./QuestionItem/AddQuestion";
import ActiveQuestionItem from "./QuestionItem/ActiveQuestionItem";
import InactiveQuestionItem from "./QuestionItem/InactiveQuestionItem";

const INITIAL_DATA = {
  answer_type: "video",
  html_text: "",
  idv_country_of_employment_iso_code: null,
  idv_type: IDV_TYPES.find(type => type.value === IDV_TYPE).value,
  idv_max_checks: null,
  max_characters: null,
  max_duration: 60,
  max_retakes: null,
  max_words: 500,
  order: 1,
  text: "",
  thinking_time: 0,
  thinking_hours: 0,
  thinking_minutes: 0
};

const QuestionsContainer = ({
  isEditableQuestions,
  setCurrentStepActive,
  questionsInfo,
  setEditableJobQuestions,
  setModal,
  fetchSuggestions,
  suggestions,
  error
}) => {
  const wrapperRef = useRef(null);
  const isIdvActivated = getIsIdvActivated(store.getState());
  const questions = getCurrentQuestions(store.getState());
  const { role } = getCurrentUser(store.getState());
  const isPaid = getIsPaid(store.getState());
  const isTrialOrFree = getIsTrialOrFree(store.getState());

  const [showModal, setShowModal] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);
  const [idvQuestion, setIdvQuestion] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  const isStandard = role === ROLE_STANDARD;
  const isAdmin = role === ROLE_ADMIN;

  const isIdvLocked =
    ((isStandard && (isPaid || isTrialOrFree)) || (isAdmin && isTrialOrFree)) &&
    !isIdvActivated;

  const onGoBack = () => setCurrentStepActive("setup");
  const { t } = useTranslation();

  const sortedQuestions = useMemo(
    () =>
      questions
        ?.sort((a, b) => a?.order - b?.order)
        .map(a => ({
          ...a,
          thinking_hours: Math.floor(a.thinking_time / 3600),
          thinking_minutes: Math.floor((a.thinking_time % 3600) / 60),
          answer_type: IDV_TYPES.map(b => b.value).includes(a.answer_type)
            ? IDV_TYPE
            : a.answer_type,
          idv_type: IDV_TYPES.map(b => b.value).includes(a.answer_type)
            ? a.answer_type
            : null,
          ...([MULTIPLE_CHOICE_TYPE, CHECKBOXES_TYPE].includes(a.answer_type) &&
          !a?.type_specific_data?.answer_options?.length
            ? {
                type_specific_data: {
                  answer_options: [
                    {
                      key: nanoid(),
                      text: null,
                      autofocus: false,
                      is_correct: false,
                      is_touched: false
                    }
                  ]
                }
              }
            : {})
        })),
    [questions]
  );

  const methods = useForm({
    defaultValues: {
      questions: [
        {
          ...INITIAL_DATA,
          key: nanoid()
        }
      ]
    },
    mode: "onChange",
    resolver: yupResolver(
      yup.object().shape({
        questions: yup.array().of(
          yup.object().shape({
            text: yup.string().trim().required(t("errors.required"))
          })
        )
      })
    )
  });

  const { control, reset, watch, handleSubmit, setValue } = methods;
  const { fields, append, remove, swap, insert } = useFieldArray({
    control,
    name: "questions"
  });

  const watchQuestions = watch("questions");

  const isValid = () => {
    const hasNoErrors =
      watchQuestions.filter(a => a.text === "" || a?.error).length === 0;

    const idv = watchQuestions.find(a => a.answer_type === IDV_TYPE);

    if (idv && !idv?.idv_country_of_employment_iso_code) {
      return false;
    }

    const hasNoEmptyOptions =
      watchQuestions.filter(
        a =>
          [MULTIPLE_CHOICE_TYPE, CHECKBOXES_TYPE].includes(a.answer_type) &&
          a?.type_specific_data?.answer_options?.filter(
            b => !b?.text || b?.text?.length === 0
          )?.length > 0
      ).length === 0;

    const hasNoDuplicateOptions =
      watchQuestions.filter(
        a =>
          [MULTIPLE_CHOICE_TYPE, CHECKBOXES_TYPE].includes(a.answer_type) &&
          a?.type_specific_data?.answer_options?.filter(
            (b, i, arr) =>
              arr.findIndex(
                c => c.text?.toLowerCase() === b.text?.toLowerCase()
              ) !== i
          )?.length > 0
      ).length === 0;

    return (
      hasNoErrors &&
      watchQuestions.length > 0 &&
      hasNoEmptyOptions &&
      hasNoDuplicateOptions
    );
  };

  function handleClickOutside(ev) {
    if (!wrapperRef?.current?.contains(ev.target)) {
      setActiveItem(null);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (sortedQuestions.length > 0) {
      reset({
        questions: sortedQuestions || [
          {
            ...INITIAL_DATA,
            key: nanoid()
          }
        ]
      });
    }
  }, [questions, reset, sortedQuestions]);

  useEffect(() => {
    methods.trigger();
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getIsIdvEnabled = useCallback(() => {
    const queryParams = `?is_developer_tools=false&search=${IDV_CHECK}`;

    store.dispatch(getIntegrations({ queryParams }));
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      isMounted = false;

      getIsIdvEnabled();
    }

    return () => {
      isMounted = true;
      store.dispatch(getIntegrationsResponse([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDuplicate = (fieldToDuplicate, index) => {
    insert(index, { ...fieldToDuplicate, key: nanoid(), order: index + 1 });
  };

  const onNext = formVal => {
    const currentQuestions = isIdvActivated
      ? formVal.questions
      : formVal.questions?.filter(
          question => question.answer_type !== IDV_TYPE
        );

    const newQuestions = currentQuestions.map(
      (
        {
          thinking_hours: hours,
          max_characters: maxCharacters,
          max_words: maxWords,
          thinking_minutes: minutes,
          idv_country_of_employment_iso_code: idvCountryOfEmployment,
          ...a
        },
        i
      ) => {
        let { answer_options: options = [] } = a?.type_specific_data || {};

        const hasCorrectAnswers = options?.filter(opt => opt.is_correct);

        options = options?.map((opt, index) => ({
          text: opt.text,
          is_correct: hasCorrectAnswers?.length > 0 ? opt.is_correct : null,
          order: index + 1,
          key: opt.key
        }));

        return {
          ...a,
          thinking_time: hours * 3600 + minutes * 60,
          order: i + 1,
          max_characters: Number(maxCharacters),
          max_words: Number(maxWords),
          idv_country_of_employment_iso_code:
            countries.alpha2ToAlpha3(idvCountryOfEmployment) ??
            idvCountryOfEmployment,
          type_specific_data: {
            answer_options: options
          }
        };
      }
    );

    setEditableJobQuestions({
      questions: newQuestions
    });

    store.dispatch(updateCurrentJobQuestions(newQuestions));
  };

  const handleShowModal = (shouldShow, order) => {
    if (shouldShow && isIdvLocked) {
      setShouldShowError(shouldShow);
      setValue(`questions[${order}].error`, "IDV is dismissed");

      return true;
    }

    if (shouldShow && !isIdvActivated && idvQuestion !== order) {
      setIdvQuestion(order);
      setShowModal(shouldShow);

      return true;
    }

    if (shouldShow && !isIdvActivated) {
      setShowModal(shouldShow);

      return true;
    }

    if (
      !shouldShow &&
      shouldShowError &&
      idvQuestion === order &&
      !isIdvActivated
    ) {
      setIdvQuestion(null);
      setShouldShowError(false);

      return true;
    }

    return true;
  };

  const handleCancelOverlay = () => handleShowModal(true, idvQuestion);

  const handleProceedOverlay = () => {
    setValue(`questions[${idvQuestion}].answer_type`, "video");
    setValue(`questions[${idvQuestion}].html_text`, "");
    setValue(`questions[${idvQuestion}].text`, "");
    setIdvQuestion(null);
  };

  const getCurrentQuestion = useCallback(order => watch(`questions[${order}]`), [watch]);

  return (
    <div className="questions-wrapper" ref={wrapperRef}>
      <FormProvider {...methods}>
        {error && (
          <Message
            error
            message={typeof error === "object" ? JSON.stringify(error) : error}
          />
        )}
        {fields?.map((questionItem, index) => (
          <FieldCollection
            key={`question-item-${questionItem.id}-question-${String(index)}`}
            order={index + 1}
            activeItem={activeItem}
            setActiveItem={setActiveItem}
            className="questions-wrapper__item-container"
            inactiveComponent={
              <InactiveQuestionItem
                {...(getCurrentQuestion(index))}
                order={index + 1}
              />
            }
            activeComponent={
              <ActiveQuestionItem
                {...(getCurrentQuestion(index))}
                control={control}
                order={index + 1}
                handleShowModal={handleShowModal}
                suggestions={suggestions}
                fetchSuggestions={fetchSuggestions}
                handleSaveQuestions={handleSubmit(onNext)}
                setValue={setValue}
              />
            }
            canDuplicate={
              isEditableQuestions && watchQuestions?.length < 20
              && !ALL_IDENTITY_VERIFICATION_TYPES.includes(getCurrentQuestion(index)?.answer_type)
            }
            handleDuplicate={() => handleDuplicate(watch(`questions[${index}]`), index)}
            disableDown={index === fields.length - 1}
            disableUp={index === 0}
            onUp={() => {
              if (index !== 0) {
                swap(index, index - 1);
              }
            }}
            onDown={() => {
              if (index !== fields.length - 1) {
                swap(index, index + 1);
              }
            }}
            handleDelete={() => remove(index)}
            isEditable={isEditableQuestions}
            disabled={false}
          />
        ))}
      </FormProvider>

      <AddQuestion
        sortedQuestions={sortedQuestions}
        isEditableQuestions={isEditableQuestions}
        append={append}
        watch={watch}
        handleShowModal={handleShowModal}
      />

      <div
        className="button__group_center"
        style={!isEditableQuestions ? { paddingTop: 22 } : {}}
      >
        <button
          type="button"
          className="n-button__medium-border n-purple-100 n-border-purple-100"
          onClick={onGoBack}
        >
          {t("button.back")}
        </button>

        <button
          type="submit"
          className="n-button__medium n-bg-purple-100 n-white"
          disabled={!isValid()}
          onClick={handleSubmit(onNext)}
        >
          {questionsInfo?.id ? t("button.next") : t("button.save")}
        </button>
      </div>

      <IdvConnectModal
        show={showModal}
        hide={() => setShowModal(false)}
        onDismiss={() => {
          setShowModal(false);

          setModal({
            type: "idvSubscriptionDismissed",
            idvConfirmation: {
              onCancel: handleCancelOverlay,
              onProceed: handleProceedOverlay
            }
          });
        }}
        isConnected={isIdvActivated}
      />
    </div>
  );
};

QuestionsContainer.defaultProps = {
  t: undefined,
  setCurrentStepActive: undefined,
  isEditableQuestions: undefined,
  questionsInfo: undefined,
  setEditableJobQuestions: undefined,
  setModal: undefined,
  suggestions: undefined,
  fetchSuggestions: undefined,
  error: undefined
};

QuestionsContainer.propTypes = {
  t: PropTypes.func,
  setCurrentStepActive: PropTypes.func,
  isEditableQuestions: PropTypes.bool,
  questionsInfo: PropTypes.shape({
    id: PropTypes.string
  }),
  setEditableJobQuestions: PropTypes.func,
  setModal: PropTypes.func,
  fetchSuggestions: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  error: PropTypes.string
};

export default withRouter(withTranslation()(QuestionsContainer));
