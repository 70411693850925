import { connect } from "react-redux";

import { fetchPeople, fetchLatestPeople } from "store/modules/people/actions";
import { setModal } from "store/modules/modals/actions";
import { getPeopleData, getPeopleLoading, getPeopleLoadingLatest, getPeopleDataPagination } from "store/modules/people/selectors";
import { getCurrentUser } from "store/modules/users/selectors";
import { getIsTrialOrPaid } from "store/modules/notifications/selectors";
import Table from "components/Tables/People";

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  peopleData: getPeopleData(state),
  isLoading: getPeopleLoading(state),
  isLoadingLatest: getPeopleLoadingLatest(state),
  dataPagination: getPeopleDataPagination(state),
  isAbiltyСreate: getIsTrialOrPaid(state)
});

const mapDispatchToProps = {
  setModal,
  fetchPeople,
  fetchLatestPeople
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
