/* eslint-disable import/no-cycle */
import { takeEvery, put, call } from "redux-saga/effects";
import { history } from "store";

import {
  fetchCommonJob,
  fetchCommonJobResponse,
  fetchCommonJobError,
  fetchInviteLink,
  setNewCandidateInfo,
  fetchInviteLinkError,
  isCommonJobsLoading
} from "store/modules/common/actions";
import { fetchCandidateInfo } from "store/modules/сandidates/actions";

import { getRequestWithToken, putRequestWithToken } from "./api";

export function* fetchInviteLinkWorker({ payload: { jobId = "", withRedirect = true } }) {
  try {
    const response = yield call(putRequestWithToken, {
      url: `/api/job/public/${jobId}/`
    });

    if (response.status === 200) {
      const { invite_link: inviteLink } = response.data;
      const url = new URL(inviteLink);
      const userId = url.pathname.split("/").pop();
      const urlSearchParams = url.searchParams;
      const { access_token: candidateToken } = Object.fromEntries(urlSearchParams.entries());

      yield put(setNewCandidateInfo({ userId, jobId, candidateToken }));
      yield put(fetchCandidateInfo({ userId, jobId, candidateToken }));
      if (withRedirect) yield history.push(`/candidate/tips/${userId}`);
    }
  } catch (error) {
    if (error.response.status === 404) {
      yield put(fetchInviteLinkError("Could not load info for remote link"));
      console.error("Could not load info for remote link", error);
      history.push("/");
    }
  }
}

export function* fetchCommonJobWorker({ payload: { jobId = "" } }) {
  try {
    yield put(isCommonJobsLoading(true));
    yield put(fetchCommonJobResponse({}));
    const response = yield getRequestWithToken({
      url: `/api/job/public/${jobId}/`
    });

    yield put(fetchCommonJobResponse(response.data));

    const { show_hints: showHints = true } = response.data || {};

    if (!showHints) {
      yield fetchInviteLinkWorker({ payload: { jobId, withRedirect: false } });
    }
  } catch (error) {
    if (error.response?.status === 404) {
      history.push("/interview-closed");
    }

    yield put(fetchCommonJobError("Could not load job"));
    console.error("Could not load job", error);
  } finally {
    yield put(isCommonJobsLoading(false));
  }
}

export function* createCommonWatcher() {
  yield takeEvery(fetchCommonJob, fetchCommonJobWorker);
  yield takeEvery(fetchInviteLink, fetchInviteLinkWorker);
}

export default createCommonWatcher;
