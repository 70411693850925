import { connect } from "react-redux";

import { updateUser, fetchUsersList } from "store/modules/users/actions";

import { setModal } from "store/modules/modals/actions";

import {
  getCurrentUser,
  getUsersTableData
} from "store/modules/users/selectors";

import { getProfileInfo } from "store/modules/profile/selectors";

import Table from "components/Tables/Users";

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  profileInfo: getProfileInfo(state),
  dataUsers: getUsersTableData(state)
});

const mapDispatchToProps = {
  updateUser,
  fetchUsersList,
  setModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
