import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";

import { store } from "store";
import { fetchCandidatesByStatusListResponse, setSelectAll } from "store/modules/jobs/actions";
import { capitalize, get, round } from "lodash";
import { connect } from "react-redux";
import { getInterviewersProgress } from "store/modules/jobs/selectors";
import Item from "../Item";

const statusString = {
  accepted: "status=Accepted",
  rejected: "status=Rejected",
  to_review: "status=Received",
  invited: "status=Invited"
};

const Column = ({
  job,
  candidates,
  title,
  status,
  handleBillingModal,
  currentUser = {},
  changeSelected,
  setSelectedCandidatesByStatus,
  selected,
  interviewProgress,
  rank,
  selectedStatus,
  setSelectedStatus
}) => {
  const handleSetInterviewersByStatusList = () => {
    const page = round(candidates?.length / 10) + 1;
    const next = candidates?.length <= 10 ? null :
      `${process.env.REACT_APP_BACKEND_URL}/api/job/${job.key}/candidates/status-keys/?page=${page}&page_size=10&status=${capitalize(status)}`;

    store.dispatch(fetchCandidatesByStatusListResponse({
      results: candidates,
      next
    }));
  };

  return (
    <Col md={3} key={`candidate-column-${status}`}>
      <div className={`candidates__list-column ${status}-status`}>
        <h3
          role="presentation"
          className={`candidates__list-column-title ${setSelectedCandidatesByStatus &&
        "cursor"}`}
          onClick={() => {
            setSelectedStatus(status);
            if (setSelectedCandidatesByStatus && selectedStatus !== status) {
              setSelectedCandidatesByStatus({
                status: statusString[status],
                jobId: job.key,
                rank
              });
              store.dispatch(setSelectAll(status === "to_review" ? "received" : status));
              changeSelected(
                candidates
                  .filter(({ is_locked: isLocked }) => isLocked === false)
                  .map(({ key, status: statusVal }) => ({
                    id: key,
                    status: statusVal
                  }))
              );
            }
          }}
          title={setSelectedCandidatesByStatus && "Select all"}
        >
          {title}
          <span className={`candidates__list-counter ${status}`}>{get(interviewProgress, status, 0)}</span>
        </h3>
        {candidates.map(user => (
          <Item
            user={user}
            job={job}
            userColumnName={status}
            key={`candidate-key-${user.key}`}
            handleBillingModal={handleBillingModal}
            currentUser={currentUser}
            changeSelected={user.is_locked ? null : changeSelected}
            selected={selected}
            clearSelectedStatus={() => {
              setSelectedStatus("");
            }}
            handleSetInterviewersByStatusList={handleSetInterviewersByStatusList}
          />
        ))}
      </div>
    </Col>
  );
};

Column.defaultProps = {
  candidates: undefined,
  job: undefined,
  company: undefined,
  title: undefined,
  status: undefined,
  handleBillingModal: undefined,
  changeSelected: undefined,
  setSelectedCandidatesByStatus: undefined,
  selected: undefined
};

Column.propTypes = {
  title: PropTypes.string,
  job: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string
  }),
  company: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string
  }),
  candidates: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        submitted: PropTypes.string,
        rating: PropTypes.number,
        status: PropTypes.string
      })
    })
  ),
  status: PropTypes.string,
  interviewProgress: PropTypes.number.isRequired,
  handleBillingModal: PropTypes.func,
  currentUser: PropTypes.shape({
    plan_name: PropTypes.string
  }).isRequired,
  changeSelected: PropTypes.func,
  setSelectedCandidatesByStatus: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  rank: PropTypes.number.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  setSelectedStatus: PropTypes.func.isRequired
};
// getInterviewersProgress
const mapStateToProps = state => ({
  interviewProgress: getInterviewersProgress(state)
});
export default connect(mapStateToProps)(Column);
