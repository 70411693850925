import DetectRTC from "detectrtc";
import {
  browserName,
  browserVersion,
  isMobile,
  isWindows,
  isMacOs
} from "react-device-detect";
import { MAC_OS_BROWSERS, WINDOWS_BROWSERS } from "configs/browser";

const isBrowserSupported = (() =>
  isMobile
    ? true
    : DetectRTC?.isGetUserMediaSupported &&
      window.MediaRecorder &&
      ((isWindows && WINDOWS_BROWSERS[browserName] <= browserVersion) ||
        (isMacOs && MAC_OS_BROWSERS[browserName] <= browserVersion) ||
        (!isWindows && !isMacOs)))();

export default isBrowserSupported;
