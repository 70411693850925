/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import PageWrapper from "hoc/PageWrapper";
import HeaderCandidateJob from "components/Common/Header/Candidate/Job";
import Footer from "components/Common/Footer";
import StepsQuestions from "components/Steps/Questions";
import { Col, Container, Row } from "react-bootstrap";
import ErrorModal from "components/Common/Error/ErrorModal";
import { isMobileOnly, isTablet } from "react-device-detect";
import QuestionTitle from "components/Common/QuestionTitle";
import { withTranslation } from "react-i18next";
import withDocumentTitle from "hoc/withDocumentTitle";
import Message from "components/Common/Message";
import StartFirebase from "configs/firebase";
import { useObject } from "react-firebase-hooks/database";
import { ref, update } from "firebase/database";
import Input from "components/Common/Input";
import { initQuestion } from "store/modules/сandidates/actions";
import { store } from "store";
import useInterviewStore from "store/interviewStore";
import { isEmpty, toUpper } from "lodash";
import withLoading from "../Video/Create/withLoading";
import Offshoot from "../Video/Create/Offshoot";

import ShareIcon from "./svgIcons";
import "./styles.scss";

const ShareCode = ({
  match: {
    params: { userId = "", jobId = "", questionId = "", step }
  },
  jobInfoCompany,
  jobInfo: {
    is_idv_share_code_skip_allowed: isSkipShareCodeAllowed
  },
  questionsForCandidate = [],
  previousQuestionId,
  history,
  savedAnswers,
  saveCurrentQuestion,
  savePreviousQuestion,
  setModal,
  clearPreviousQuestion,
  isAllAnsweredSaved,
  retakeAnswer,
  currentCandidateId,
  t,
  saveShareCode,
  error,
  token,
  saveIsAllAnswerSaved,
  saveAnswer
}) => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [, setCashedElapsingTime] = useState(null);
  const [, setUploadProgress] = useState(0);
  const [shareCode, setShareCode] = useState("");
  const [db, setDB] = useState();
  const [snapshot, loading] = useObject((db && userId) ? ref(db, `Candidates/${userId}`) : null);

  const { setIsUploading } = useInterviewStore();

  const isUserIdExists = !isEmpty(userId) && userId !== "null";

  const savedAnswer = useMemo(
    () => savedAnswers.find(answer => answer.question.key === questionId),
    [savedAnswers, questionId]
  );

  useEffect(() => {
    const getDatabase = async () => {
      const database = await StartFirebase();
      setDB(database);
    };

    getDatabase();
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (jobId && questionId !== "null" && questionId !== "" && !questionId?.includes("null") && isUserIdExists && isMounted) {
      isMounted = false;

      store.dispatch(initQuestion({
        userId,
        questionId,
        jobId,
        shouldSkipRedirectToIDV: true
      }));
    }

    return () => {
      isMounted = true;
    };
  }, [jobId, questionId, userId]);

  useEffect(() => {
    if (db && isUserIdExists) {
      update(ref(db, `Candidates/${userId}`), {
        device: navigator.userAgent,
        jobId,
        questionId,
        token
      });
    }
  }, [userId, db]);

  useEffect(() => {
    if (!loading && snapshot?.val()?.device && snapshot?.val()?.device !== navigator.userAgent) {
      history.push(`/candidate/transferred/${userId}?isShareCode=true`);
    }
  }, [snapshot?.val()?.device, loading]);

  const currentQuestion = useMemo(
    () => questionsForCandidate.find(item => item.key === questionId),
    [questionId, questionsForCandidate, step]
  );

  const isLastQuestion =
    userId === currentCandidateId &&
    savedAnswers.length > 0 &&
    savedAnswers.length >= questionsForCandidate.length &&
    savedAnswers.every(item => item?.is_finished);

  const questionNumber = useMemo(
    () =>
      Number(step) ||
        questionsForCandidate.findIndex(item => item.key === questionId),
    [questionId, questionsForCandidate, step]
  );

  const navigateToNextStep = () => {
    if (!isLastQuestion) {
      const nextQuestionNumber = questionNumber + 1;
      const nextQuestionByNumber = questionsForCandidate[nextQuestionNumber];
      const nextQuestionNotFinished = questionsForCandidate
        .find(({ key: questionKey }) =>
          questionKey !== questionId &&
          savedAnswers.every(({ question }) => question.key !== questionKey));
      const nextQuestion = nextQuestionByNumber || nextQuestionNotFinished;

      if (nextQuestion) {
        saveCurrentQuestion({ userId, questionCurrentCashed: null });

        history.push({
          pathname: `/candidate/video-questions/create/${userId}/${jobId}/${nextQuestion.key}`
        });

        setCashedElapsingTime(null);
      }
    } else {
      history.push({
        pathname: `/candidate/video-questions/preview/${userId}`
      });
    }
  };

  const handleSubmit = () => {
    // this is being triggered
    if (typeof saveAnswer === "function") {
      setIsUploading(true);

      const callbackFinish = () => {
        setIsUploading(false);
        setUploadProgress(0);
        navigateToNextStep();
      };

      const onUploadProgress = percentage => setUploadProgress(percentage);

      saveShareCode({
        onUploadProgress,
        shareCode,
        userId
      });

      const dataToUpload = {
        userId,
        questionId,
        jobId,
        is_finished: true,
        is_skipped: false,
        callback: callbackFinish,
        onUploadProgress
      };

      saveCurrentQuestion({ userId, questionCurrentCashed: dataToUpload });
      saveAnswer(dataToUpload);
    } else {
      history.goBack();
      setUploadProgress(0);
    }
  };

  const handleSkip = () => {
    if (typeof saveAnswer === "function") {
      setIsUploading(true);

      const callbackFinish = () => {
        setIsUploading(false);
        setUploadProgress(0);
        navigateToNextStep();
      };

      const onUploadProgress = percentage => setUploadProgress(percentage);

      saveShareCode({
        onUploadProgress,
        shareCode: null,
        userId
      });

      const dataToUpload = {
        userId,
        questionId,
        jobId,
        is_finished: true,
        is_skipped: false,
        callback: callbackFinish,
        onUploadProgress
      };

      saveCurrentQuestion({ userId, questionCurrentCashed: dataToUpload });

      saveAnswer(dataToUpload);
    } else {
      history.goBack();
      setUploadProgress(0);
    }
  };

  const memoizedUpdateShareCode = useCallback(event => {
    const currentValue = event.target.value.replaceAll("-", "");

    if (currentValue?.length <= 9) {
      const code = currentValue?.match(/.{1,3}/g);
      const newShareCode = code?.join("-") || "";

      setShareCode(toUpper(newShareCode));
    }
  },
  [shareCode]);

  const memorizedCompanyInfo = useMemo(() => jobInfoCompany, [jobInfoCompany]);

  useEffect(() => {
    if (isLastQuestion && typeof saveIsAllAnswerSaved === "function") {
      saveIsAllAnswerSaved({ userId, isAllAnsweredSaved: true });
      history.push({
        pathname: `/candidate/video-questions/preview/${userId}`,
        state: {
          jobId
        }
      });
    }
  }, [isLastQuestion, history, userId, jobId, saveIsAllAnswerSaved]);

  let pageContent = (
    <Container bsPrefix="container question-create pt-4">
      <Offshoot userId={userId} isRecording={false} isShareCode />
      <Row>
        <Col md={12}>
          <StepsQuestions
            jobId={jobId}
            userId={userId}
            history={history}
            isAllAnsweredSaved={isAllAnsweredSaved}
            savedAnswers={savedAnswers}
            questions={questionsForCandidate}
            currentStepActive={questionId}
            previousQuestionId={previousQuestionId}
            savePreviousQuestion={savePreviousQuestion}
            setModal={setModal}
            clearPreviousQuestion={clearPreviousQuestion}
            retakeAnswer={retakeAnswer}
            isRecording={false}
            saveCurrentQuestion={saveCurrentQuestion}
            setCashedElapsingTime={setCashedElapsingTime}
            isShowDelayMessage={false}
          />
        </Col>
      </Row>
      <Row>
        {error && <Message error message={typeof error === "object" ? JSON.stringify(error) : t(`${error}`)} />}
        <Col md={12}>
          <QuestionTitle
            questionNumber={questionNumber}
            thinkingTime={currentQuestion?.thinking_time}
            spentTime={0}
            isIdv
          />
        </Col>
        <Col md={12}>
          {t("job.candidate.idv.alreadyAnswered")}
        </Col>
        <Col md={{ offset: 8, span: 4 }} xs={{ span: 12 }}>
          <div className="button__wrapper upload">
            <button
              className="n-fluid n-button__large n-bg-purple-100 n-white"
              style={
                memorizedCompanyInfo && {
                  background: memorizedCompanyInfo.button_color
                }
              }
              type="button"
              onClick={navigateToNextStep}
            >
              {t("button.saveContinue")}
            </button>
          </div>
        </Col>
      </Row>
    </Container>
  );


  if (!savedAnswer?.is_finished) {
    pageContent = (
      <Container bsPrefix="container question-create pt-4">
        <Offshoot userId={userId} isRecording={false} isShareCode />
        <Row>
          <Col md={12}>
            <StepsQuestions
              jobId={jobId}
              userId={userId}
              history={history}
              isAllAnsweredSaved={isAllAnsweredSaved}
              savedAnswers={savedAnswers}
              questions={questionsForCandidate}
              currentStepActive={questionId}
              previousQuestionId={previousQuestionId}
              savePreviousQuestion={savePreviousQuestion}
              setModal={setModal}
              clearPreviousQuestion={clearPreviousQuestion}
              retakeAnswer={retakeAnswer}
              isRecording={false}
              saveCurrentQuestion={saveCurrentQuestion}
              setCashedElapsingTime={setCashedElapsingTime}
              isShowDelayMessage={false}
            />
          </Col>
        </Row>
        <Row>
          {error && <Message error message={typeof error === "object" ? JSON.stringify(error) : t(`${error}`)} />}
          <Col md={12}>
            <QuestionTitle
              questionNumber={questionNumber}
              thinkingTime={currentQuestion?.thinking_time}
              spentTime={0}
              isIdv
            />
          </Col>
          <Col md={12}>
            <p className="idv-question-citizenship">{t("job.candidate.shareCode.questionTitle")}</p>
          </Col>
          <Col md={12} className="idv-question-sharecode-container flex flex-row items-start">
            <p className="idv-question-citizenship-subtitle">{t("job.candidate.shareCode.questionSubtitle")}</p>
            <ShareIcon onClick={() => window.open(
              "https://support.willo.video/article/130-share-codes"
            )}
            />
          </Col>
          <Col md={6}>
            <Input
              name="shareCode"
              maxLength={11}
              formElement={{
                value: shareCode,
                elementConfig: {
                  placeholder: "shareCode",
                  type: "text"
                },
                inputClass: "shareCode-label"
              }}
              inputChangedHandler={memoizedUpdateShareCode}
            />
          </Col>
          <Col md={{ offset: 8, span: 4 }} xs={{ span: 12 }}>
            <div className="button__wrapper upload">
              <button
                className="n-fluid n-button__large n-bg-purple-100 n-white"
                style={
                  memorizedCompanyInfo && {
                    background: memorizedCompanyInfo.button_color
                  }
                }
                type="button"
                disabled={!shareCode}
                onClick={handleSubmit}
              >
                {t("button.saveContinue")}
              </button>
            </div>
          </Col>
          {
            isSkipShareCodeAllowed && (
              <Col md={{ offset: 8, span: 4 }} xs={{ span: 12 }}>
                <div className="button__wrapper upload">
                  <button
                    className="n-fluid n-button__large n-button__large-border n-border-purple-100 n-purple-100 flex flex-row items-center"
                    style={
                      memorizedCompanyInfo && {
                        background: memorizedCompanyInfo.button_color
                      }
                    }
                    type="button"
                    onClick={handleSkip}
                  >
                    {t("button.skip")}
                  </button>
                </div>
              </Col>
            )
          }
        </Row>
      </Container>
    );
  }

  return (
    <PageWrapper
      header={(
        <HeaderCandidateJob
          company={memorizedCompanyInfo}
          className={isMobileOnly || isTablet ? "header-absolute" : ""}
        />
      )}
      footer={(
        <Footer
          company={memorizedCompanyInfo}
          isPublic
        />
      )}
      overflowVisible
    >
      {pageContent}
      <ErrorModal
        show={showErrorModal}
        hide={() => setShowErrorModal(false)}
        t={t}
      />
    </PageWrapper>
  );
};

export default withTranslation()(withLoading(withDocumentTitle(ShareCode)));

ShareCode.defaultProps = {
  isAllAnsweredSaved: undefined,
  match: undefined,
  history: undefined,
  jobInfoCompany: {},
  jobInfo: {},
  savedAnswers: [],
  questionsForCandidate: [],
  questionCurrentCashed: {},
  previousQuestionId: undefined,
  saveCurrentQuestion: undefined,
  savePreviousQuestion: undefined,
  setModal: undefined,
  clearPreviousQuestion: undefined,
  idvSession: {},
  token: ""
};

ShareCode.propTypes = {
  isAllAnsweredSaved: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      userId: PropTypes.string,
      jobId: PropTypes.string,
      questionId: PropTypes.string,
      step: PropTypes.string
    })
  }),
  history: PropTypes.shape(),
  jobInfoCompany: PropTypes.shape({
    layout_color: PropTypes.string,
    logo: PropTypes.string
  }),
  jobInfo: PropTypes.shape({
    key: PropTypes.string,
    questions: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string
    })),
    is_idv_share_code_skip_allowed: PropTypes.bool.isRequired
  }),
  questionsForCandidate: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      text: PropTypes.string
    })
  ),
  questionCurrentCashed: PropTypes.shape({
    blob: PropTypes.object,
    userId: PropTypes.string,
    questionId: PropTypes.string,
    questionNumber: PropTypes.number
  }),
  savedAnswers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      remote_link: PropTypes.string
    })
  ),
  previousQuestionId: PropTypes.string,
  saveCurrentQuestion: PropTypes.func,
  savePreviousQuestion: PropTypes.func,
  setModal: PropTypes.func,
  clearPreviousQuestion: PropTypes.func,
  retakeAnswer: PropTypes.func.isRequired,
  currentCandidateId: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  idvSession: PropTypes.shape({
    error: PropTypes.string,
    isLoading: PropTypes.bool,
    sessionId: PropTypes.string,
    sessionToken: PropTypes.string,
    sessionExpiry: PropTypes.number
  }),
  saveShareCode: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  token: PropTypes.string,
  saveIsAllAnswerSaved: PropTypes.func.isRequired,
  saveAnswer: PropTypes.func.isRequired
};
