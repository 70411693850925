export const dynamycCompaniesBreadcrumbs = (companyTitle, id) => [
  {
    key: "Company",
    title: "Companies",
    path: "/companies"
  },
  {
    key: `${companyTitle}-${id}`,
    title: companyTitle,
    noLink: true
  }
];

export const jobsBreadcrumbs = (t, jobTitle, id) => [
  {
    key: "Jobs",
    title: t("breadcrumbs.jobs"),
    path: "/jobs"
  },
  {
    key: `${jobTitle}-${id}`,
    title: jobTitle || t("breadcrumbs.jobCreate"),
    noLink: true
  }
];

export const jobsEditBreadcrumbs = t => [
  {
    key: "Job",
    title: t("breadcrumbs.jobs"),
    path: "/jobs"
  },
  {
    key: "edit",
    title: t("breadcrumbs.jobEdit"),
    path: "/jobs/edit"
  }
];

export const jobsInviteBreadcrumbs = (t, jobTitle = "", id = "") => [
  {
    key: "Job",
    title: t("breadcrumbs.jobs"),
    path: "/jobs"
  },
  {
    key: `${jobTitle}-${id}`,
    title: jobTitle,
    path: `/jobs/overview/${id}`
  },
  {
    key: `${jobTitle}-${id}`,
    title: t("breadcrumbs.invite"),
    path: `/jobs/invite/${id}`
  }
];

export const createCompanyBreadcrumbs = t => [
  {
    key: "Companies",
    title: t("breadcrumbs.companies"),
    path: "/companies"
  },
  {
    key: "create",
    title: t("breadcrumbs.companyCreate"),
    path: "/companies/create/"
  }
];

export const editCompanyBreadcrumbs = t => [
  {
    key: "Companies",
    title: t("breadcrumbs.companies"),
    path: "/companies"
  },
  {
    key: "edit",
    title: t("breadcrumbs.companyEdit"),
    path: "/companies/edit"
  }
];

export const companyBreadcrumbs = (t, companyTitle = "", companyId = "") => [
  {
    key: "Companies",
    title: t("breadcrumbs.companies"),
    path: "/companies"
  },
  {
    key: companyTitle,
    title: companyTitle,
    path: `/companies/overview/${companyId}`
  }
];

export const candidateBreadcrumbs = (
  companyTitle = "",
  companyId = "",
  jobTitle = "",
  jobId = "",
  candidate = "",
  candidateId = ""
) => [
  {
    key: `${companyTitle}-${companyId}`,
    title: companyTitle,
    path: `/companies/overview/${companyId}`
  },
  {
    key: `${jobTitle}-${jobId}`,
    title: jobTitle,
    path: `/jobs/overview/${jobId}`
  },
  {
    key: `${candidate}-${candidateId}`,
    title: candidate,
    noLink: true
  }
];

export const librariesBreadcrumbs = t => [
  {
    key: "Libraries",
    title: t("breadcrumbs.libraries"),
    path: "/libraries"
  },
  {
    key: "create",
    title: t("breadcrumbs.librariesCreate"),
    noLink: true
  }
];

export const librariesEditBreadcrumbs = t => [
  {
    key: "Libraries",
    title: t("breadcrumbs.libraries"),
    path: "/libraries"
  },
  {
    key: "edit",
    title: t("breadcrumbs.librariesEdit"),
    noLink: true
  }
];

export const scorecardViewBreadcrumbs = t => [
  {
    key: "Scorecard",
    title: t("link.scorecards"),
    path: "/scorecards"
  }
];

export const scoreCardEditBreadcrumbs = t => [
  {
    key: "Scorecard",
    title: t("link.scorecards"),
    path: "/scorecards"
  },
  {
    key: "edit",
    title: t("breadcrumbs.scorecardEdit"),
    path: "/scorecards/edit"
  }
];

export const scorecardsBreadcrumbs = (t, scorecardTitle, id) => [
  {
    key: "Scorecards",
    title: t("link.scorecards"),
    path: "/scorecards"
  },
  {
    key: `${scorecardTitle}-${id}`,
    title: scorecardTitle || t("breadcrumbs.scorecardCreate"),
    noLink: true
  }
];
