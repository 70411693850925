import moment from "moment";

import { DATE_FORMATS } from "configs/dates";

import { getTimeZones } from "@vvo/tzdb";

export const handleItemValue = item => {
  if (!item) {
    return null;
  }

  if (typeof item === "object" && !item.value) {
    return null;
  }

  if (item.value) {
    return item.value;
  }

  if (item) {
    return item;
  }

  return null;
};

export const handleItemLabel = item => {
  if (item) {
    return item.label ? item.label : item;
  }

  return null;
};

export const constructDayLabel = (t, field, item) => {
  if (item) {
    return `${item.value} ${t(`${field}.${item.label}`)}`;
  }

  return null;
};

export const constructMonthLabel = (t, field, item) => {
  const noEndDate = t("status.noEndDate");

  if (!item) return noEndDate;

  const itemForConstruct = handleItemLabel(item);

  if (itemForConstruct === noEndDate) return noEndDate;

  const serializedLabel = moment(itemForConstruct).format(
    DATE_FORMATS.SPACE_SEPARATOR_LONG_MONTH
  );

  const [day, month, year] = serializedLabel.split(" ");

  if (day === "Invalid") return null;

  return `${day} ${t(`${field}.${month}`)} ${year}`;
};

export const handleSelectValueAndLabel = (t, field, item) => {
  if (field === "days") {
    return {
      value: handleItemValue(item),
      label: constructDayLabel(t, field, item)
    };
  }

  if (field === "month") {
    return {
      value: handleItemValue(item),
      label: constructMonthLabel(t, field, item)
    };
  }

  return false;
};

export const translateOptionsForSelect = (t, options, field) =>
  options.map(item => handleSelectValueAndLabel(t, field, item));

export const translateValueForSelect = (t, field, options, value) => {
  let selectedValue = "";

  if (value && field === "days") {
    selectedValue = options.find(option => option.value === value);
    return handleSelectValueAndLabel(t, field, selectedValue);
  }

  if (value && field === "month") {
    selectedValue = value;
    return handleSelectValueAndLabel(t, field, selectedValue);
  }

  if (!value && field === "month") {
    return {
      value: null,
      label: t("status.noEndDate")
    };
  }

  return selectedValue;
};

export const timeZonesWithUtc = getTimeZones({ includeUtc: true })
  .map(timezone => {
    const utc = timezone.currentTimeFormat.split(" ")[0];

    return {
      ...timezone,
      label: `UTC ${utc} ${timezone?.name?.replace("_", " ")}`,
      value: timezone?.name
    };
  }).sort((a, b) => a?.value?.localeCompare(b?.value));

export const translateTimezoneForSelect = defaultValue =>
  timeZonesWithUtc.find(option => option.value === defaultValue?.value);
