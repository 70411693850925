import { handleActions, combineActions } from "redux-actions";

import {
  isJobsLoading,
  clearPreviewState,
  saveJobError,
  clearErrors,
  createJob,
  saveJobErrorWithFields
} from "./actions";

const defaultState = {
  isLoading: false,
  previewInfo: {
    deadline: null
  }
};

export default handleActions(
  {
    [isJobsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [saveJobError]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [saveJobErrorWithFields]: (state, { payload }) => {
      const fields = payload?.map(field => {
        const data = field?.split(":");

        return {
          field: data?.[0]?.toLowerCase(),
          error: data?.[1]
        };
      });

      return {
        ...state,
        errorWithFields: fields
      };
    },
    [combineActions(clearPreviewState, createJob)]: () => ({
      ...defaultState
    }),
    [clearErrors]: state => ({
      ...state,
      error: null
    })
  },
  defaultState
);
