import React from "react";
import { withTranslation } from "react-i18next";
import { confirmable } from "react-confirm";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import { Close } from "mixins/svgIcons";

import "./styles.scss";

const SpamWarning = ({
  t,
  show,
  dismiss,
  removeModal,
  saveLibrary
}) => {
  const handleClose = () => {
    dismiss();
    removeModal();
  };
  const handleUpdate = () => {
    saveLibrary();
    handleClose();
  };

  return (
    <Modal dialogClassName="spam" show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="share__block">
          <button type="button" className="share__close" onClick={handleClose}>
            <Close fill="#212B46" />
          </button>

          <h1 className="share__title n-font-giant n-font-semi-bold n-black-100">
            {t("modals.spam.title")}
          </h1>

          <p className="spam__text n-font-small n-black-100">
            {t("modals.spam.text.0")}
            <span className="amber-words">{t("modals.spam.text.1")}</span>
            .
          </p>
          <p className="spam__text n-font-small n-black-100">
            {t("modals.spam.text.2")}
          </p>
        </div>

        <div className="button__group_center">
          <button
            type="button"
            onClick={handleUpdate}
            className="n-button__medium-border n-border-purple-100 n-purple-100"
          >
            {t("button.useAnyway")}
          </button>
          <button
            type="button"
            onClick={handleClose}
            className="n-button__medium n-bg-purple-100 n-white"
          >
            {t("button.updatePhrases")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

SpamWarning.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
  saveLibrary: PropTypes.func.isRequired
};

export default confirmable(withTranslation()(SpamWarning));
