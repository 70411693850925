/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useObject } from "react-firebase-hooks/database";
import { ref } from "firebase/database";

import StartFirebase from "configs/firebase";
import { Close } from "mixins/svgIcons";
import { store } from "store";

import "./styles.scss";
import { downloadAndDeleteLinkFailed } from "../../store/modules/сandidates/actions";

const DownloadNotification = ({
  t,
  isOpen,
  videoArchives,
  isAuthenticated,
  updateVideoArchives,
  updateIsOpenVideoArchives,
  downloadAndDeleteLink,
  clearDownloadLinkError,
  hasDownloadError
}) => {
  const [db, setDB] = useState();
  const [snapshot] = useObject((db && videoArchives[0]) ? ref(db, `VideoArchives/${videoArchives[0]}`) : null);

  const handleClose = () => updateIsOpenVideoArchives(false);

  useEffect(() => {
    const getDatabase = async () => {
      const database = await StartFirebase();
      setDB(database);
    };

    getDatabase();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      const videoArchive = snapshot?.val();
      const status = videoArchive?.status;

      if (["error", "deleted"].includes(status) || videoArchive === null) {
        updateVideoArchives(videoArchives.filter(id => id !== videoArchives[0]));
        if (status === "error") store.dispatch(downloadAndDeleteLinkFailed());
      } else if (status === "completed") {
        downloadAndDeleteLink({
          link: videoArchive?.link,
          candidateId: videoArchives[0]
        });
      }
    }
  }, [snapshot?.val()?.link, snapshot?.val()?.status]);

  useEffect(() => {
    if (hasDownloadError) {
      setTimeout(() => {
        clearDownloadLinkError();
      }, 5000);
    }
  }, [hasDownloadError]);

  return (
    <>
      {
        isOpen
          ? (
            <div className="download__all-container">
              <p className="download__all-title n-font-small n-font-medium-weight n-grey-60">
                {t("job.candidate.download.preparingFile")}
              </p>
              <p className="n-font-small n-font-regular n-grey-100">
                {t("job.candidate.download.thisMake")}
              </p>
              <button type="button" className="button__without-styles" onClick={handleClose}>
                <Close fill="#212B46" />
              </button>
            </div>
          ) : null
      }
      {
        hasDownloadError ? (
          <div className="download__all-container" id="download_all_error">
            <p
              className="n-font-small n-font-regular n-grey-100"
              style={{
                color: "#FF7777"
              }}
            >
              {t("job.candidate.download.failed")}
            </p>
            <button
              type="button"
              className="button__without-styles"
              id="download_all_error-close"
              onClick={() => {
                clearDownloadLinkError();
              }}
            >
              <Close fill="#212B46" />
            </button>
          </div>
        ) : null
      }
    </>
  );
};

DownloadNotification.propTypes = {
  t: PropTypes.func.isRequired,
  clearDownloadLinkError: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hasDownloadError: PropTypes.bool.isRequired,
  videoArchives: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  updateVideoArchives: PropTypes.func.isRequired,
  updateIsOpenVideoArchives: PropTypes.func.isRequired,
  downloadAndDeleteLink: PropTypes.func.isRequired
};

export default withTranslation()(DownloadNotification);
