import React from "react";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";
import { withTranslation } from "react-i18next";

import Footer from "components/Common/Footer";
import G2Badges from "assets/images/auth/G2Badges.png";
import G2BadgesSignIn from "assets/images/auth/G2-badge-signin.svg";

import "./styles.scss";
import { isMobile } from "react-device-detect";
import IntroVideo from "components/Auth/IntroVideo";

const AuthWrapper = ({ t, header, footer = <Footer />, children }) => (
  <>
    <div className="auth-wrapper">
      {header}
      <div className="auth-cell">
        <div className="auth-content">
          <div className="auth-content__left">{children}</div>
          {
            (window.location.pathname.includes("sign-in") || window.location.pathname.includes("sign-up")) && (!isMobile || window.innerWidth >= 768) ? (
              <>
                <IntroVideo />
                <div className="auth-content-video">
                  <h1 className="auth-content-video__title">
                    {t("layouts.authRightPart_video")}
                  </h1>
                  <img
                    src={G2BadgesSignIn}
                    alt="G2 Logos"
                    className="auth-content-video__badge"
                  />
                </div>
              </>
            ) : (
              <div className="auth-content__right">
                <Row>
                  <h1 className="auth-content__right_title">
                    {t("layouts.authRightPart")}
                  </h1>
                  <img className="auth-content__right_g2" src={G2Badges} alt="G2 Logos" />
                </Row>
              </div>
            )
          }
        </div>
      </div>
    </div>
    {footer}
  </>
);

AuthWrapper.defaultProps = {
  header: undefined,
  footer: undefined,
  children: undefined
};

AuthWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  header: PropTypes.element,
  footer: PropTypes.element,
  children: PropTypes.element
};

export default withTranslation()(AuthWrapper);
