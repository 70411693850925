import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ProgressLine from "components/Common/Progress/Line";

import withLoader from "hoc/withLoader";

import { calculatePercent } from "mixins/helpers";
import { constructMonthLabel } from "mixins/helperTranslate";

const Received = ({ t, invited = 0, interviews = 0, start = 0, end = 0 }) => (
  <div className="candidate-progress__received">
    <div className="candidate-progress__received-info">
      <ProgressLine>
        <ProgressLine.Left width="half">
          {`${t("widgets.progressReceived.started")}:`}
        </ProgressLine.Left>

        <ProgressLine.Right width="half">
          {`${invited}
          ${t("widgets.progressReceived.people")}`}
        </ProgressLine.Right>
      </ProgressLine>

      <ProgressLine>
        <ProgressLine.Left width="half">
          {`${t("widgets.progressReceived.response")}:`}
        </ProgressLine.Left>

        <ProgressLine.Right width="half">
          {`${interviews}
            ${t("widgets.progressReceived.answers")}
            (${calculatePercent(interviews, invited)}%)`}
        </ProgressLine.Right>
      </ProgressLine>

      <ProgressLine>
        <ProgressLine.Left width="half">
          {`${t("widgets.progressReceived.start")}:`}
        </ProgressLine.Left>

        <ProgressLine.Right width="half">
          {constructMonthLabel(t, "month", start)}
        </ProgressLine.Right>
      </ProgressLine>

      <ProgressLine>
        <ProgressLine.Left width="half">
          {`${t("widgets.progressReceived.end")}:`}
        </ProgressLine.Left>

        <ProgressLine.Right width="half">
          {constructMonthLabel(t, "month", end)}
        </ProgressLine.Right>
      </ProgressLine>
    </div>
  </div>
);

Received.defaultProps = {
  invited: undefined,
  interviews: undefined,
  start: undefined,
  end: undefined
};

Received.propTypes = {
  t: PropTypes.func.isRequired,
  invited: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  interviews: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  start: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  end: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withLoader(withTranslation()(Received));
