export default {
  email: {
    label: "emailLabel",
    icon: "Mail",
    elementType: "input",
    elementConfig: {
      type: "email",
      placeholder: "emailPlaceholder",
      disabled: true
    },
    value: "",
    validation: {
      required: true,
      isEmail: true
    },
    errorMessage: "emailError",
    valid: true,
    touched: false
  },
  password: {
    label: "newPasswordLabel",
    icon: "Key",
    elementType: "password",
    elementConfig: {
      type: "password",
      placeholder: "newPasswordPlaceholder"
    },
    value: "",
    validation: {
      required: true,
      isTheSame: true
    },
    errorMessage: "passwordError",
    valid: false,
    touched: false
  },
  newPassword: {
    label: "passwordConfirmLabel",
    icon: "Key",
    elementType: "password",
    elementConfig: {
      type: "password",
      placeholder: "passwordConfirmPlaceholder"
    },
    value: "",
    validation: {
      required: true,
      isTheSame: true
    },
    errorMessage: "passwordConfirmError",
    valid: false,
    touched: false
  }
};
