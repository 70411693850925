/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { } from "react";
import { withTranslation } from "react-i18next";
import { ANSWER_TYPES } from "configs/jobs/constants";
import PropTypes from "prop-types";
import { getCurrentUser } from "store/modules/users/selectors";
import { ROLE_ADMIN, ROLE_STANDARD } from "configs/user/rolesConfig";
import {
  getIsFree,
  getIsPaid,
  getIsTrialOrFree
} from "store/modules/notifications/selectors";
import { getJobCurrent } from "store/modules/jobs/selectors";
import { store } from "store";
import { getIsIdvActivated } from "store/modules/marketplace/selectors";
import AnswerType from "./AnswerType";
import QuestionTitleEditor from "./QuestionTitleEditor";
import RetakesAndDuration from "./RetakesAndDuration";
import TextLimit from "./TextLimit";
import Thinking from "./Thinking";
import IdvCountryAndChecks from "./IdvCountryAndChecks";
import MultipleOptions from "./MultipleOptions/MultipleOptions";

const ActiveQuestionItem = ({
  order,
  answer_type: answerType,
  idv_type: idvType,
  idv_max_checks: idvMaxChecks,
  max_characters: maxCharacters,
  max_duration: maxDuration,
  max_retakes: maxRetakes,
  max_words: maxWords,
  type_specific_data: { answer_options: options = [] } = {},
  control,
  handleShowModal,
  suggestions,
  fetchSuggestions,
  handleSaveQuestions,
  setValue
}) => {
  const { role } = getCurrentUser(store.getState());
  const isPaid = getIsPaid(store.getState());
  const isFreeForever = getIsFree(store.getState());
  const isIdvActivated = getIsIdvActivated(store.getState());
  const isTrialOrFree = getIsTrialOrFree(store.getState());
  const { candidates_cnt: candidatesCnt = 0 } = getJobCurrent(store.getState());

  const isAdmin = role === ROLE_ADMIN;
  const isStandard = role === ROLE_STANDARD;
  const isEditable = candidatesCnt <= 0;
  const isIdvNeedAdmin = isStandard && (isPaid || isFreeForever);
  const isIdvLocked =
    ((isStandard && (isPaid || isFreeForever)) || (isAdmin && isTrialOrFree)) &&
    !isIdvActivated;

  return (
    <div
      className="active-question__wrapper display"
      key={`active-item-${order}`}
    >
      <QuestionTitleEditor
        isActive
        order={order}
        suggestions={suggestions}
        fetchSuggestions={fetchSuggestions}
      />
      <div className="input-wrapper__container">
        <AnswerType
          control={control}
          answerType={answerType}
          isIdvLocked={isIdvLocked}
          isIdvNeedAdmin={isIdvNeedAdmin}
          isEditable={isEditable}
          order={order}
          handleShowModal={handleShowModal}
          handleSaveQuestions={handleSaveQuestions}
        />
        {[ANSWER_TYPES.audio.value, ANSWER_TYPES.video.value].includes(
          answerType
        ) ? (
          <RetakesAndDuration
            control={control}
            maxRetakes={maxRetakes}
            maxDuration={maxDuration}
            order={order}
          />
          ) : null}
        {answerType === ANSWER_TYPES.text.value ? (
          <TextLimit
            textLimit={maxWords ? "max_words" : "max_characters"}
            control={control}
            maxCount={maxWords || maxCharacters}
            order={order}
          />
        ) : null}
        {answerType === ANSWER_TYPES.idv.value ? (
          <IdvCountryAndChecks
            setValue={setValue}
            control={control}
            idvMaxChecks={idvMaxChecks}
            isEditable={isEditable}
            isIdvLocked={isIdvLocked}
            order={order}
            idvType={idvType}
          />
        ) : null}

        {
          ![
            ANSWER_TYPES.idv.value
          ]?.includes(answerType) ? (
            <Thinking control={control} order={order} />
          ) : null
        }

        <MultipleOptions
          answerType={answerType}
          order={order}
          options={options}
          isEditable={isEditable}
        />
      </div>
    </div>
  );
};

ActiveQuestionItem.defaultProps = {
  order: undefined,
  answer_type: undefined,
  idv_max_checks: undefined,
  max_characters: undefined,
  max_duration: undefined,
  max_retakes: undefined,
  max_words: undefined,
  type_specific_data: undefined,
  control: undefined,
  handleShowModal: undefined,
  suggestions: undefined,
  fetchSuggestions: undefined,
  handleSaveQuestions: undefined,
  idv_type: undefined
};

ActiveQuestionItem.propTypes = {
  order: PropTypes.number,
  answer_type: PropTypes.string,
  idv_max_checks: PropTypes.string,
  max_characters: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max_duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max_retakes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  max_words: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  type_specific_data: PropTypes.shape({
    answer_options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
      })
    )
  }),
  control: PropTypes.shape({}),
  handleShowModal: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.shape({})),
  fetchSuggestions: PropTypes.func,
  handleSaveQuestions: PropTypes.func,
  setValue: PropTypes.func.isRequired,
  idv_type: PropTypes.string
};

export default withTranslation()(ActiveQuestionItem);
