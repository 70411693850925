import React, { useMemo, useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import countryList from "react-select-country-list";
import Select from "react-select";

import DropdownIndicator from "components/Forms/Select/dropdown";

import Buttons from "./Buttons";

const StepLocation = ({ t, setStep, updateAgency, country }) => {
  const countries = useMemo(() => countryList().getData(), []);
  const [newCountry, setCountry] = useState(null);
  const handlePrevStep = () => setStep(0);
  const handleNextStep = () => {
    setStep(2);
    updateAgency({ is_country_provided: true });
  };
  const handleClick = () => {
    updateAgency({
      country: newCountry.label,
      is_country_provided: true
    });
    setStep(2);
  };

  useEffect(() => {
    const countryValue = countries.find(({ label }) => label === country);
    if (country && countryValue) {
      setCountry(countryValue);
    }
  }, [country]);

  return (
    <>
      <div className="onboard__card">
        <Row className="onboard__flex">
          <Col md={12} className="onboard__card-title">
            <div>
              <h1 className="onboard__title n-grey-100 n-font-semi-bold">
                {t("onboarding.whereAreYou")}
              </h1>
              <p className="onboard__subtitle n-grey-100 n-font-large n-font-medium-weight">
                {t("onboarding.helpUs")}
              </p>

              <Select
                className="comapany__chart-select onboard__select"
                classNamePrefix="select"
                options={countries}
                value={newCountry}
                onChange={setCountry}
                components={{
                  DropdownIndicator: props => (
                    <DropdownIndicator
                      {...props}
                      height="12"
                      width="12"
                      fill="#768493"
                    />
                  )
                }}
              />
            </div>
            <div className="onboard__next">
              <button
                className="onboard__button n-button__medium n-bg-purple-100 n-white"
                type="button"
                onClick={handleClick}
                disabled={!newCountry}
              >
                {t("button.nextStep")}
              </button>
            </div>
          </Col>
        </Row>
      </div>
      <Buttons
        handlePrevStep={handlePrevStep}
        handleNextStep={handleNextStep}
      />
    </>
  );
};

StepLocation.propTypes = {
  t: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  updateAgency: PropTypes.func.isRequired,
  country: PropTypes.func.isRequired
};

export default withTranslation()(StepLocation);
