import React, { useRef, useState, useEffect } from "react";
import {
  useDidUpdateEffect,
  useKey,
  useMultiSelect,
  Cross,
  Arrow,
  DropdownHeader
} from "react-multi-select-component";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import _ from "lodash";

import SelectPanel from "./SelectPanel";
import "./styles.scss";

const KEY = {
  ARROW_DOWN: "ArrowDown",
  ARROW_UP: "ArrowUp",
  ENTER: "Enter",
  ESCAPE: "Escape",
  SPACE: "Space"
};

const Dropdown = ({ t }) => {
  const {
    onMenuToggle,
    isLoading,
    onChange,
    labelledBy,
    value,
    withTranslationLabel,
    fetchOnOpen,
    fetchLatest
  } = useMultiSelect();
  const [expanded, setExpanded] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const wrapper = useRef();

  useDidUpdateEffect(() => {
    if (onMenuToggle) onMenuToggle(expanded);
  }, [expanded]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapper]);

  const handleKeyDown = e => {
    if (
      ["text", "button"].includes(e.target.type) &&
      [KEY.SPACE, KEY.ENTER].includes(e.code)
    ) {
      return;
    }
    setExpanded(e.code !== KEY.ESCAPE);
    e.preventDefault();
  };

  useKey([KEY.ENTER, KEY.ARROW_DOWN, KEY.SPACE, KEY.ESCAPE], handleKeyDown, {
    target: wrapper
  });

  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (!expanded) setSelectedValue(value);
  };

  const handleClearSelected = e => {
    e.stopPropagation();
    setExpanded(false);
    if (value.length > 0) onChange([]);
  };

  const handleApply = () => {
    setExpanded(false);

    const isEqual = _.isEqual(_.sortBy(value, ["value"]), _.sortBy(selectedValue, ["value"]));
    if (!isEqual) onChange(selectedValue);
  };

  useEffect(() => {
    if (fetchOnOpen && expanded) {
      fetchLatest();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchOnOpen, expanded]);

  return (
    <div
      className={`dropdown-container ${expanded ? "focused" : ""}`}
      aria-labelledby={labelledBy}
      aria-expanded={expanded}
      ref={wrapper}
    >
      <div className="dropdown-heading" onClick={toggleExpanded} role="none">
        <div className="dropdown-heading-value">
          <DropdownHeader />
        </div>
        {value.length > 0 && (
          <button
            type="button"
            className="clear-selected-button"
            onClick={handleClearSelected}
          >
            <Cross />
          </button>
        )}
        <Arrow expanded={expanded} />
      </div>
      {expanded && (
        <div className="dropdown-content">
          <div className="panel-content">
            <SelectPanel
              value={selectedValue}
              onChange={setSelectedValue}
              withTranslationLabel={withTranslationLabel}
            />
            {!isLoading && (
              <div className="dropdown-footer">
                <button
                  type="button"
                  className="n-button__small button__purple n-font-small n-button__full-width"
                  onClick={handleApply}
                >
                  {t("button.apply")}
                </button>
                <button
                  type="button"
                  className="n-button__small n-button__small-border n-border-grey-100 n-font-small n-bg-white n-black-80"
                  onClick={handleClearSelected}
                >
                  {t("button.reset")}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(Dropdown);
