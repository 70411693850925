import React from "react";

export const Success = () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5711 0.408977C14.9988 -0.136326 14.0715 -0.136326 13.4992 0.408977L4.78506 8.71187L2.46798 6.81541C1.90349 6.27753 0.988358 6.27753 0.423369 6.81541C-0.141123 7.35326 -0.141123 8.22566 0.423369 8.76351L3.88509 11.5966C4.44958 12.1345 5.36471 12.1345 5.9297 11.5966C5.98635 11.5426 6.03373 11.4826 6.07914 11.4226C6.08743 11.4151 6.09671 11.41 6.10502 11.4026L15.5712 2.38266C16.1429 1.83783 16.143 0.953807 15.5711 0.408977Z" fill="#59CC4C" />
  </svg>
);

export const Error = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.06127 5.99987L11.7801 1.28102C12.0733 0.988097 12.0733 0.51281 11.7801 0.219883C11.4869 -0.0732943 11.0122 -0.0732943 10.719 0.219883L6.00012 4.93873L1.28102 0.219883C0.987847 -0.0732943 0.51306 -0.0732943 0.219883 0.219883C-0.0732943 0.51281 -0.0732943 0.988097 0.219883 1.28102L4.93898 5.99987L0.219883 10.7187C-0.0732943 11.0117 -0.0732943 11.4869 0.219883 11.7799C0.366471 11.9262 0.558587 11.9995 0.750453 11.9995C0.942319 11.9995 1.13444 11.9262 1.28102 11.7796L6.00012 7.06077L10.719 11.7796C10.8656 11.9262 11.0577 11.9995 11.2495 11.9995C11.4414 11.9995 11.6335 11.9262 11.7801 11.7796C12.0733 11.4867 12.0733 11.0114 11.7801 10.7185L7.06127 5.99987Z" fill="#E83B3B" />
  </svg>
);

export const ErrorMessage = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 12C4.90384 12 3.88943 11.726 2.95673 11.1779C2.05288 10.6587 1.34135 9.94712 0.822115 9.04327C0.274035 8.11057 0 7.09616 0 6C0 4.90384 0.274035 3.88943 0.822115 2.95673C1.34135 2.05288 2.05288 1.34135 2.95673 0.822115C3.88943 0.274035 4.90384 0 6 0C7.09616 0 8.11057 0.274035 9.04327 0.822115C9.94712 1.35096 10.6587 2.0673 11.1779 2.97115C11.726 3.90385 12 4.91346 12 6C12 7.08654 11.726 8.09615 11.1779 9.02885C10.649 9.9327 9.9327 10.649 9.02885 11.1779C8.09615 11.726 7.08654 12 6 12ZM3.57692 1.84616C4.32693 1.40384 5.13461 1.18269 6 1.18269C6.86539 1.18269 7.67307 1.40384 8.42308 1.84616C9.15385 2.26923 9.73077 2.84615 10.1538 3.57692C10.5962 4.32693 10.8173 5.13461 10.8173 6C10.8173 6.86539 10.5962 7.67307 10.1538 8.42308C9.73077 9.15385 9.15385 9.73077 8.42308 10.1538C7.67307 10.5962 6.86539 10.8173 6 10.8173C5.13461 10.8173 4.32693 10.5962 3.57692 10.1538C2.84615 9.73077 2.26923 9.15385 1.84616 8.42308C1.40384 7.67307 1.18269 6.86539 1.18269 6C1.18269 5.13461 1.40384 4.32693 1.84616 3.57692C2.26923 2.84615 2.84615 2.26923 3.57692 1.84616ZM6.59135 9.01442L6.59135 7.80289H5.40865L5.40865 9.01442H6.59135ZM6.59135 6.59135V2.98558H5.40865V6.59135H6.59135Z" fill="#E83B3B" />
  </svg>
);
