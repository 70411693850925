import React from "react";
import PropTypes from "prop-types";
import ProgressBar from "react-bootstrap/ProgressBar";

const Progress = ({ color, ...rest }) => <ProgressBar className={`progress-line__progress ${color}`} {...rest} />;

Progress.defaultProps = {
  color: undefined
};

Progress.propTypes = {
  color: PropTypes.string
};

export default Progress;
