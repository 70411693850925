import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "../styles.scss";

const InactiveFieldCollection = ({
  isActive,
  component
}) =>
   (
     <div
       className={`field_collection-wrapper__item-container__content ${!isActive ? "visible" : "hidden"}`}
     >
       {component}
     </div>
    );
InactiveFieldCollection.defaultProps = {
  isActive: false,
  component: undefined
};

InactiveFieldCollection.propTypes = {
  isActive: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.instanceOf(Component),
    PropTypes.shape({})
  ])
};

export default withTranslation()(InactiveFieldCollection);
