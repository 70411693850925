import { IDV_CHECK } from "configs/jobs/constants";

export const getAllIntegrations = state => state.marketplace.integrations;

export const getIsMarketplaceLoading = state => state.marketplace.isLoading;

export const getIsDeveloperToolHeader = state => state.marketplace.is_developer_tool;

export const getApiWebhooks = state => state?.marketplace?.webhooks;

export const getIsIdvActivated = state => state?.marketplace?.integrations
  ?.find(item => item.title === IDV_CHECK)?.is_enabled ?? false;


export const getMarketplaceIcons = state => state?.marketplace?.icons ?? [];
