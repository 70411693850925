import Confirmation from "components/Confirmation/Confirm";
import Subscription from "components/Confirmation/Subscription";
import CompanyDelete from "components/Confirmation/CompanyDelete";
import CompanyDefaultDelete from "components/Confirmation/CompanyDefaultDelete";
import ShareModal from "components/Modals/Share";
import ShareBulkModal from "components/Modals/ShareBulk";
import UpdateDetailsModal from "components/Modals/Details";
import RejectDetailsModal from "components/Modals/Reject";
import CardIsLockedModal from "components/Modals/CardIsLocked";
import Templates from "components/Modals/Templates";
import SpamWarning from "components/Modals/SpamWarning";
import UnsavedQuestion from "components/Modals/UnsavedQuestion";

import { createConfirmation } from "react-confirm";

const shareModal = createConfirmation(ShareModal);
const shareBulkModal = createConfirmation(ShareBulkModal);
const defaultConfirmation = createConfirmation(Confirmation);
const subscriptionConfirmation = createConfirmation(Subscription);
const companyDeleteConfirm = createConfirmation(CompanyDelete);
const companyDefaultDelete = createConfirmation(CompanyDefaultDelete);
const updateDetailsConfirmationModal = createConfirmation(UpdateDetailsModal);
const rejectDetailsConfirmationModal = createConfirmation(RejectDetailsModal);
const cardIsLockedConfirmationModal = createConfirmation(CardIsLockedModal);
const previewTemplateModal = createConfirmation(Templates);
const spamWarningModal = createConfirmation(SpamWarning);
const unsavedQuestionModal = createConfirmation(UnsavedQuestion);

export const deleteConfirmation = (confirmation, options = {}) =>
  defaultConfirmation({
    confirmation,
    ...options
  });

export const deleteConfirmationCompany = (confirmation, options = {}) =>
  companyDeleteConfirm({
    confirmation,
    ...options
  });

export const deleteDefaultCompany = (confirmation, options = {}) =>
  companyDefaultDelete({
    confirmation,
    ...options
  });

export const warningConfirmation = (confirmation, options = {}) =>
  defaultConfirmation({
    confirmation,
    ...options
  });

export const subcriptionConfirmation = (confirmation, options = {}) =>
  subscriptionConfirmation({
    confirmation,
    ...options
  });

export const updateDetailsConfirmation = (confirmation, options = {}) =>
  updateDetailsConfirmationModal({
    confirmation,
    ...options
  });

export const rejectDetailsConfirmation = (confirmation, options = {}) =>
  rejectDetailsConfirmationModal({
    confirmation,
    ...options
  });

export const shareModalConfirmation = (name, options = {}) =>
  shareModal({
    name,
    ...options
  });

export const shareBulkModalConfirmation = (name, options = {}) =>
  shareBulkModal({
    name,
    ...options
  });

export const cardIsLockedConfirmation = (confirmation, options = {}) =>
  cardIsLockedConfirmationModal({
    confirmation,
    ...options
  });

export const previewTemplate = (confirmation, options = {}) =>
  previewTemplateModal({
    confirmation,
    ...options
  });

export const spamWarning = (confirmation, options = {}) =>
  spamWarningModal({
    confirmation,
    ...options
  });

export const unsavedQuestion = (confirmation, options = {}) =>
  unsavedQuestionModal({
    confirmation,
    ...options
  });
