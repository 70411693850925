import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Popover from "components/Common/Popover";
import csv from "files/willo_invite-comma.csv";
import txt from "files/willo_invite-comma.txt";

const MoreInfo = ({ t, popoverText }) => (
  <Popover popoverText={popoverText} svgIconClass="invite-all-input__caption-info-icon n-svg-black-80">
    <>
      <h4>{t("job.invite.inviteMany.tip.title")}</h4>
      <p>{t("job.invite.inviteMany.tip.textSaveTime")}</p>
      <p>{t("job.invite.inviteMany.tip.textSpreadsheet")}</p>
      <p>
        {t("job.invite.inviteMany.tip.textDownload")}
        <a className="popover-link" href={csv}>
          {t("link.here")}
        </a>
      </p>
      <p>
        {t("job.invite.inviteMany.tip.textTab")}
        <a className="popover-link" href={txt} target="_blank" rel="noopener noreferrer">
          {t("link.here")}
        </a>
      </p>
    </>
  </Popover>
);

MoreInfo.defaultProps = {
  popoverText: ""
};

MoreInfo.propTypes = {
  t: PropTypes.func.isRequired,
  popoverText: PropTypes.string
};

export default withTranslation()(MoreInfo);
