import React from "react";
import PropTypes from "prop-types";
import { useMultiSelect } from "react-multi-select-component";
import { Waypoint } from "react-waypoint";

import Spinner from "components/Common/Spinner";

import SelectItem from "./SelectItem";

const SelectList = ({ value, options, onChange, withTranslationLabel }) => {
  const {
    next,
    fetchLatest,
    isLoading,
    isNextLoading,
    isSearchLoading
  } = useMultiSelect();
  const handleSelectionChanged = (option, checked) => {
    onChange(
      checked
        ? [...value, option]
        : value.filter(o => o.value !== option.value)
    );
  };

  return (
    <>
      {isSearchLoading ? (
        <Spinner size={6} />
      ) : (
        options.map((o, i) => (
          <li key={o?.key || i}>
            <SelectItem
              tabIndex={i}
              option={o}
              onSelectionChanged={c => handleSelectionChanged(o, c)}
              checked={!!value.find(s => s.value === o.value)}
              withTranslationLabel={withTranslationLabel}
            />
          </li>
        ))
      )}
      {(!isLoading && next) ? (
        <div className="multiselect_pagination">
          <Waypoint onEnter={() => fetchLatest(next)} />
          {isNextLoading && !isSearchLoading && <Spinner size={6} />}
        </div>
      ) : null}
    </>
  );
};

SelectList.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onChange: PropTypes.func.isRequired,
  withTranslationLabel: PropTypes.bool.isRequired
};

export default SelectList;
