import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";

import Header from "containers/Common/HeaderContainer";
import Spinner from "components/Common/Spinner";
import Breadcrumbs from "components/Breadcrumbs";
import PageWrapper from "hoc/PageWrapper";
import withLoaderScreen from "hoc/withLoaderScreen";
import { librariesBreadcrumbs, librariesEditBreadcrumbs } from "mixins/dynamicRoutes";

import Template from "../Template";
import "../styles.scss";

const Create = ({
  t,
  match: { params: { id = null } },
  isLoading,
  currentLibrary,
  fetchCurrentLibrary,
  updateLibrary,
  clearLibraryError,
  error,
  setModal
}) => {
  useEffect(() => {
    if (id) fetchCurrentLibrary(id);
  }, []);

  return (
    <PageWrapper header={<Header />}>
      <Container>
        <Row justify="center">
          <Col md={12}>
            <Breadcrumbs
              id={id}
              pathList={id ? librariesEditBreadcrumbs(t) : librariesBreadcrumbs(t)}
            />
          </Col>
          {isLoading ? <Spinner /> : (
            <Template
              currentLibrary={currentLibrary}
              handleSaveLibrary={updateLibrary}
              clearLibraryError={clearLibraryError}
              error={error}
              setModal={setModal}
            />
          )}
        </Row>
      </Container>
    </PageWrapper>
  );
};

Create.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  currentLibrary: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    trigger: PropTypes.string,
    content: PropTypes.string
  }).isRequired,
  error: PropTypes.shape.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchCurrentLibrary: PropTypes.func.isRequired,
  updateLibrary: PropTypes.func.isRequired,
  clearLibraryError: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired
};

export default withLoaderScreen(withTranslation()(Create));
