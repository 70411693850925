import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { clearErrors } from "store/modules/jobs/actions";

import VideoCreate from "components/Video/Recording/Desktop/JobVideo";

const mapDispatchToProps = {
  clearErrors
};

export default withRouter(
  connect(null, mapDispatchToProps)(VideoCreate)
);
