import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

import Footer from "components/Common/Footer";
import Header from "components/Common/Header/Main";
import { getNotifications } from "store/modules/notifications/selectors";
import { getIsSubscriptionUnpaid } from "store/modules/profile/selectors";

import "./styles.scss";
import { getIsCurrentUserLoading } from "store/modules/users/selectors";

const PageWrapper = ({
  header = <Header />,
  footer = <Footer />,
  children,
  prefix,
  prefixPage,
  contentRef,
  overflowVisible,
  notifications: {
    promo = {},
    is_quota_exceed: isQuotaExceed,
    is_trial_period: isTrialPeriod = false,
    is_setup_completed: isSetupCompleted,
    is_sms_hard_limit_reached: isSmsHardLimitReached
  },
  isSubscriptionUnpaid,
  showBanner
}) => {
  const isBannerShown =
    promo.show_banner || isQuotaExceed || isSmsHardLimitReached ||
    isSubscriptionUnpaid || (isTrialPeriod && isSetupCompleted) || showBanner;

  return (
    <>
      <div
        className={`page-wrapper ${overflowVisible &&
          "page-wrapper-visible"} ${isBannerShown
            ? "page-wrapper__banner-padding" : ""} ${prefixPage}`}
        ref={contentRef}
      >
        {header}
        <div className={`page-content ${prefix}`}>{ children}</div>
      </div>
      {footer}
    </>
  );
};

PageWrapper.defaultProps = {
  header: undefined,
  footer: undefined,
  children: undefined,
  prefix: "",
  prefixPage: "",
  contentRef: undefined,
  overflowVisible: false,
  notifications: undefined,
  showBanner: false
};

PageWrapper.propTypes = {
  header: PropTypes.element,
  footer: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  prefix: PropTypes.string,
  prefixPage: PropTypes.string,
  contentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(HTMLInputElement) })
  ]),
  overflowVisible: PropTypes.bool,
  notifications: PropTypes.shape({
    promo: PropTypes.shape({}),
    is_quota_exceed: PropTypes.bool,
    is_setup_completed: PropTypes.bool,
    is_trial_period: PropTypes.bool,
    is_sms_hard_limit_reached: PropTypes.bool
  }),
  isSubscriptionUnpaid: PropTypes.bool.isRequired,
  showBanner: PropTypes.bool
};

const mapStateToProps = state => ({
  notifications: getNotifications(state),
  isSubscriptionUnpaid: getIsSubscriptionUnpaid(state),
  isCurrentUserLoading: getIsCurrentUserLoading(state)

});

export default connect(mapStateToProps)(PageWrapper);
