import { handleActions } from "redux-actions";

import {
  isCommonJobsLoading,
  fetchCommonJobResponse,
  fetchCommonJobError,
  setHasCurrentUserLoaded
} from "./actions";

const defaultState = {
  currentDateRange: {
    label: "Select ...",
    value: ""
  },
  hasCurrentUserLoaded: false
};

export default handleActions(
  {
    [isCommonJobsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [fetchCommonJobResponse]: (state, { payload }) => ({
      ...state,
      error: "",
      commonJob: payload
    }),
    [fetchCommonJobError]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [setHasCurrentUserLoaded]: (state, { payload }) => ({
      ...state,
      hasCurrentUserLoaded: payload
    })
  },
  defaultState
);
