import React, { useEffect } from "react";
import { bool, func } from "prop-types";
import BeatLoader from "react-spinners/BeatLoader";

const withLoaderScreen = WrappedComponent => {
  const Wrapped = ({ isLoadingScreen, setLoadingScreen, ...rest }) => {
    useEffect(() => {
      if (isLoadingScreen && typeof setLoadingScreen === "function") {
        setLoadingScreen(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <>
        {isLoadingScreen && (
          <div className="loader-page">
            <BeatLoader color="#5a2af1" loading={isLoadingScreen} />
          </div>
        )}
        <WrappedComponent
          {...rest}
          setLoadingScreen={setLoadingScreen}
          isLoadingScreen={isLoadingScreen}
        />
      </>
    );
  };

  Wrapped.defaultProps = {
    isLoadingScreen: false,
    setLoadingScreen: undefined
  };

  Wrapped.propTypes = {
    isLoadingScreen: bool,
    setLoadingScreen: func
  };

  return Wrapped;
};

export default withLoaderScreen;
