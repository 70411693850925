import React from "react";
import { withTranslation } from "react-i18next";
import { confirmable } from "react-confirm";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import { Close } from "mixins/svgIcons";

import "./styles.scss";

const UnsavedQuestion = ({
  t,
  show,
  dismiss,
  removeModal,
  fetchCandidate,
  cancelFetch
}) => {
  const handleClose = () => {
    dismiss();
    removeModal();
  };
  const handleCancel = () => {
    cancelFetch();
    handleClose();
  };
  const handleContinue = () => {
    fetchCandidate();
    handleClose();
  };

  return (
    <Modal dialogClassName="unsaved" show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="share__block">
          <button type="button" className="share__close" onClick={handleClose}>
            <Close fill="#212B46" />
          </button>

          <h1 className="share__title n-font-giant n-font-semi-bold n-black-100">
            {t("modals.unsavedQuestion.title")}
          </h1>

          <p className="unsaved__text n-font-small n-black-100">
            {t("modals.unsavedQuestion.text")}
          </p>
        </div>

        <div className="button__group_center">
          <button
            type="button"
            onClick={handleCancel}
            className="n-button__medium-border n-border-purple-100 n-purple-100"
          >
            {t("button.cancel")}
          </button>
          <button
            type="button"
            onClick={handleContinue}
            className="n-button__medium n-bg-purple-100 n-white"
          >
            {t("button.proceed")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

UnsavedQuestion.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  cancelFetch: PropTypes.func.isRequired
};

export default confirmable(withTranslation()(UnsavedQuestion));
