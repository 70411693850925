import React from "react";
import PropTypes from "prop-types";

const Title = ({ children }) => (
  <h2 className="profile-card__list-title">{children}</h2>
);

Title.defaultProps = {
  children: undefined
};

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default Title;
