import { createAction } from "redux-actions";

export const handleOnboarding = createAction("HANDLE_ONBOARDING");
export const handleOnboardingError = createAction("HANDLE_ONBOARDING_ERROR");
export const handleOnboardingResponse = createAction("HANDLE_ONBOARDING_RESPONSE");

export const setLoadingScreen = createAction("SET_LOADING_SCREEN");
export const setLoadingScreenMaintenance = createAction("SET_LOADING_SCREEN_MAINTENANCE");

export const saveRoute = createAction("SAVE_ROUTE");

export const refreshStatus = createAction("REFRESH_STATUS");

export const saveRefreshRoute = createAction("SAVE_REFRESH_ROUTE");

export const saveIsOnline = createAction("SET_IS_ONLINE");

export const setHealthCheckError = createAction("SET_HEALTHCHECK_ERROR");

export const setIsNotificationsLoading = createAction("SET_NOTIFICATIONS_LOADING");

export const setRateLimittingTimeRemaining = createAction("SET_RATE_LIMITTING_TIME_REMAINING");
