import { connect } from "react-redux";

import {
  getPeopleFilters,
  getPeopleFiltersLoading,
  getPeopleFiltersPaginationLoading,
  getPeopleFilterCompaniesLoading,
  getPeopleFilterJobsLoading,
  getPeopleFilterOwnersLoading,
  getSearchPeopleFilterLoading,
  getPeoplePageSelectedFilter
} from "store/modules/people/selectors";
import {
  fetchLatestPeopleFilterCompanies,
  fetchLatestPeopleFilterJobs,
  fetchLatestPeopleFilterOwners,
  fetchSearchPeopleFilter
} from "store/modules/people/actions";
import People from "views/People";

const mapStateToProps = state => ({
  filters: getPeopleFilters(state),
  isLoading: getPeopleFiltersLoading(state),
  filtersPaginationData: getPeopleFiltersPaginationLoading(state),
  isFilterCompaniesLoading: getPeopleFilterCompaniesLoading(state),
  isFilterJobsLoading: getPeopleFilterJobsLoading(state),
  isFilterOwnersLoading: getPeopleFilterOwnersLoading(state),
  isSearchFilterLoading: getSearchPeopleFilterLoading(state),
  filter: getPeoplePageSelectedFilter(state)
});

const mapDispatchToProps = {
  fetchLatestPeopleFilterCompanies,
  fetchLatestPeopleFilterJobs,
  fetchLatestPeopleFilterOwners,
  fetchSearchPeopleFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(People);
