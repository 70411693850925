import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setLoadingScreen } from "store/modules/widgets/actions";
import { fetchCurrentLibrary, updateLibrary, clearLibraryError } from "store/modules/libraries/actions";
import { setModal } from "store/modules/modals/actions";
import Edit from "views/Libraries/Edit";

const mapStateToProps = state => ({
  currentLibrary: state.libraries.currentLibrary || {},
  isLoading: state.libraries.isCurrentLoading,
  isLoadingScreen: state.widgets.isLoading,
  error: state.libraries.errorSaving || {}
});

const mapDispatchToProps = {
  setLoadingScreen,
  fetchCurrentLibrary,
  updateLibrary,
  clearLibraryError,
  setModal
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Edit));
