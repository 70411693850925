/* eslint-disable react/prop-types */
import React from "react";

export const Email = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.26539 3.00157L7.02854 7.62092C7.28987 7.83015 7.65 7.92314 7.99978 7.90571C8.349 7.92314 8.70912 7.83073 8.97046 7.62092L14.7336 3.00157C15.1954 2.63367 15.0909 2.33203 14.505 2.33203H8.00036H1.49571C0.909287 2.33203 0.804753 2.63367 1.26539 3.00157Z" fill="#4D556B" />
    <path d="M15.1499 4.22356L8.85207 9.06202C8.61658 9.24103 8.30815 9.32821 8.00086 9.32414C7.693 9.32821 7.38457 9.24045 7.14908 9.06202L0.850056 4.22356C0.382525 3.86496 0 4.05733 0 4.65074V12.5893C0 13.1827 0.480167 13.6686 1.06659 13.6686H7.99971H14.9334C15.5198 13.6686 16 13.1827 16 12.5893V4.65074C16 4.05733 15.6175 3.86496 15.1499 4.22356Z" fill="#4D556B" />
  </svg>
);

export const Message = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 1C3.58125 1 0 3.90937 0 7.5C0 9.05 0.66875 10.4688 1.78125 11.5844C1.39062 13.1594 0.084375 14.5625 0.06875 14.5781C0 14.65 -0.01875 14.7563 0.021875 14.85C0.0625 14.9438 0.15 15 0.25 15C2.32188 15 3.875 14.0063 4.64375 13.3938C5.66563 13.7781 6.8 14 8 14C12.4187 14 16 11.0906 16 7.5C16 3.90937 12.4187 1 8 1Z" fill="#4D556B" />
  </svg>
);

export const Eye = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 2.5C4.36364 2.5 1.25818 4.78067 0 8C1.25818 11.2193 4.36364 13.5 8 13.5C11.6364 13.5 14.7418 11.2193 16 8C14.7418 4.78067 11.6364 2.5 8 2.5ZM8.00013 11.6667C5.99286 11.6667 4.36377 10.024 4.36377 8C4.36377 5.976 5.99286 4.33333 8.00013 4.33333C10.0074 4.33333 11.6365 5.976 11.6365 8C11.6365 10.024 10.0074 11.6667 8.00013 11.6667ZM8.00018 5.80009C6.7929 5.80009 5.81836 6.78276 5.81836 8.00009C5.81836 9.21742 6.7929 10.2001 8.00018 10.2001C9.20745 10.2001 10.182 9.21742 10.182 8.00009C10.182 6.78276 9.20745 5.80009 8.00018 5.80009Z" fill="#5A2AF1" />
  </svg>
);
