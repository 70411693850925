export const rejectQuestions = [
  {
    id: "culture",
    label: "Did not fit company culture",
  },
  {
    id: "quality_desired",
    label: "Did not meet desired qualifications",
  },
  {
    id: "quality_minimum",
    label: "Did not meet minimum qualifications",
  },
  {
    id: "screening",
    label: "Did not meet screening requirements",
  },
  {
    id: "incomplete",
    label: "Incomplete application",
  },
  {
    id: "ineligible",
    label: "Ineligible to work in location",
  },
  {
    id: "quality_misrepresented",
    label: "Misrepresented qualifications",
  },
  {
    id: "another_choosen",
    label: "More qualified candidate selected",
  },
  {
    id: "unresponsive",
    label: "Unresponsive",
  },
];

export const withdrawalQuestions = [
  {
    id: "another_job",
    label: "Took another job",
  },
  {
    id: "compensation",
    label: "Compensation",
  },
  {
    id: "personal",
    label: "Personal reasons",
  },
  {
    id: "commute",
    label: "Commute",
  },
  {
    id: "cultural",
    label: "Cultural fit",
  },
  {
    id: "no_follow_up",
    label: "Lack of recruiter follow-up",
  },
  {
    id: "confused",
    label: "Confusing job description",
  },
  {
    id: "stay",
    label: "Will stay in current company",
  },
  {
    id: "not_interested",
    label: "Not interested in the first place",
  },
];
