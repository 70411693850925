import React from "react";
import { withTranslation } from "react-i18next";
import { confirmable } from "react-confirm";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import ReactHtmlParser from "react-html-parser";

import { Close } from "mixins/svgIcons";

import DOMPurify from "dompurify";
import { Invite, Reminder, Success } from "./StaticTemplates";
import "./styles.scss";

const TriggersTemplate = {
  invite: Invite,
  reminder: Reminder,
  success_email: Success
};

const Templates = ({
  t,
  show,
  dismiss,
  removeModal,
  templateInfo
}) => {
  const blocksFromHtml = htmlToDraft(templateInfo.content);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const editorContent = EditorState.createWithContent(contentState);
  const counter = editorContent?.getCurrentContent()?.getPlainText()?.length || 0;
  const counterSms = Math.ceil(counter / 160);
  const TriggerTemplate = TriggersTemplate[templateInfo.trigger];
  const closeModal = () => {
    dismiss();
    removeModal();
  };
  return (
    <Modal dialogClassName="share" show={show} onHide={closeModal}>
      <Modal.Body>
        <div className="share__block">
          <button type="button" className="share__close" onClick={closeModal}>
            <Close fill="#212B46" />
          </button>

          <h1 className="share__title n-font-giant n-font-semi-bold">
            {templateInfo.label}
          </h1>
        </div>

        <div className="share-input__wrapper template">
          {templateInfo.type === "sms" ? (
            <div className="content-text">
              {ReactHtmlParser(DOMPurify.sanitize(templateInfo.content, {
                ADD_ATTR: "href"
              }))}
            </div>
          ) : (
            <TriggerTemplate t={t}>
              <div className="content-text">
                {ReactHtmlParser(DOMPurify.sanitize(templateInfo.content, {
                  ADD_ATTR: "href"
                }))}
              </div>
            </TriggerTemplate>
          )}
        </div>
        <p className="n-font-small n-grey-60 n-text-right">
          {templateInfo.type === "sms" ? <span>{`${t("modals.templates.sms")} ${counterSms}`}</span> : null}
          {`${t("modals.templates.characters")} ${counter}`}
        </p>
      </Modal.Body>
    </Modal>
  );
};

Templates.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
  removeModal: PropTypes.func.isRequired,
  templateInfo: PropTypes.shape({
    label: PropTypes.string,
    content: PropTypes.string,
    type: PropTypes.string,
    trigger: PropTypes.string
  }).isRequired
};

export default confirmable(withTranslation()(Templates));
