/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import "./styles.scss";
import { ChevronLeft, ChevronRight, FileInfo } from "mixins/svgIcons";
import moment from "moment";
import { size } from "lodash";
import { useTranslation } from "react-i18next";
import { store } from "store";
import { getProfileInfo } from "store/modules/profile/selectors";
import classNames from "classnames";
import {
  getCanSeeCandidateScores,
  getIsCurrentCandidateScored
} from "store/modules/jobs/selectors";
import { connect } from "react-redux";
import TooltipInfo from "components/Common/Tooltip/Info";
import { getRatingToString } from "mixins/helperCandidate";
import InterviewScorecardItem from "./InterviewScorecardItem";
import InterviewScorecardPill from "./InterviewScorecardPill";
import InterviewScorecard from "./InterviewScorecard";

const InterviewScorecardsView = ({
  userId,
  title,
  candidateScores,
  marginBottom = 48,
  isModalView = false,
  canSeeScores,
  isScored,
  jobId
}) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const currentUserEmail = getProfileInfo(store.getState())?.email;

  const candidateScoresToBeDisplayed = useMemo(() => {
    if (candidateScores?.length === 2) {
      if (canSeeScores && !isScored && !isModalView) {
        return [{}, ...candidateScores.filter(a => !a.is_total)];
      }
      return candidateScores.filter(a => !a.is_total);
    }

    if (canSeeScores && !isScored && !isModalView) {
      return [{}, ...candidateScores];
    }

    return candidateScores;
  }, [canSeeScores, candidateScores, isScored, isModalView]);

  const currentScorecard = useMemo(() => {
    if (currentIndex === 0 && canSeeScores && !isScored && !isModalView) {
      return {};
    }
    return candidateScoresToBeDisplayed?.[currentIndex] ?? {};
  }, [
    canSeeScores,
    candidateScoresToBeDisplayed,
    currentIndex,
    isModalView,
    isScored
  ]);

  const rating = getRatingToString({
    t,
    rating: Math.floor(currentScorecard?.general_score),
    isActive: true
  });

  const scoredByMe = currentScorecard?.scored_by?.email === currentUserEmail;

  const sortedCriteria = currentScorecard?.criteria_scores?.sort(
    (a, b) => a?.scorecard_criteria?.order - b?.scorecard_criteria?.order
  );

  if (isModalView) {
    return (
      <div
        className="scorecard-table"
        id="scorecard-table"
        style={{
          marginBottom
        }}
      >
        <div className="scorecard-table__modal">
          <div className="scorecard-table__header">
            <span className="scorecard-table__header-title">{title}</span>
            {size(candidateScoresToBeDisplayed) > 1 && (
              <div className="scorecard-table__header--navigation">
                <div
                  className=""
                  onClick={() =>
                    currentIndex === 0
                      ? setCurrentIndex(
                          candidateScoresToBeDisplayed?.length - 1
                        )
                      : setCurrentIndex(index => index - 1)
                  }
                >
                  <ChevronLeft />
                </div>
                {currentIndex + 1} of {candidateScoresToBeDisplayed?.length}
                <div
                  className=""
                  onClick={() =>
                    currentIndex + 1 === candidateScoresToBeDisplayed?.length
                      ? setCurrentIndex(0)
                      : setCurrentIndex(index => index + 1)
                  }
                >
                  <ChevronRight />
                </div>
              </div>
            )}
          </div>
          <div className="scorecard-table__list">
            {sortedCriteria?.map(a => (
              <InterviewScorecardItem
                {...a}
                key={a.key}
                isEditable={false}
                userId={userId}
                id={a.key}
                text={a.scorecard_criteria.text.text}
                text_markdown={a.scorecard_criteria.text.text_markdown}
                score={a.score}
                description={a?.scorecard_criteria?.description?.description}
              />
            ))}
          </div>
        </div>
        <div
          className="scorecard-table__footer"
          style={{ marginTop: 20, padding: "0px 12px" }}
        >
          <div className="submitted_by">
            <div>
              {currentScorecard?.is_total ? (
                <span style={{ fontWeight: 500 }}>
                  {t("scorecard.totalScore")}
                </span>
              ) : (
                <>{`${t("scorecard.submittedBy")} `}</>
              )}
              <span
                style={{
                  color: "#5A2AF1",
                  fontWeight: 500
                }}
              >
                {scoredByMe
                  ? t("status.user.Rating.you")
                  : currentScorecard?.scored_by?.full_name}
              </span>
            </div>
            {currentScorecard?.scored_at ? (
              <div style={{ fontWeight: 300 }}>
                {moment(currentScorecard?.scored_at).format("DD/MM/YY")} at{" "}
                {moment(currentScorecard?.scored_at).format("hh:mm A")}
              </div>
            ) : null}
            {currentScorecard?.is_total && (
              <div>
                {size(candidateScores) - 1}{" "}
                {size(candidateScores) - 1 === 1
                  ? t("scorecard.table.scoreSubmitted")
                  : t("scorecard.table.scoresSubmitted")}
              </div>
            )}
          </div>
          <TooltipInfo
            prefix="tooltip__icon tooltip__icon-people without-styles align-center"
            position="left"
            message={
              rating?.untranslated_label
                ? t(`scorecard.people.rating.${rating?.untranslated_label ?? ""}`)
                : null
            }
            disabled={!rating?.untranslated_label}
          >
            <div>
              <InterviewScorecardPill
                score={currentScorecard?.general_score}
                iconHeight={16}
                iconWidth={16}
                total={
                  currentScorecard?.is_total ? size(candidateScores) - 1 : null
                }
              />
            </div>
          </TooltipInfo>
        </div>
      </div>
    );
  }

  return (
    <div
      className="scorecard-table"
      style={{
        marginBottom
      }}
    >
      <div className="scorecard-table__header">
        <span className="scorecard-table__header-title" id="scorecard-table">
          {title}

          <TooltipInfo
            position="top"
            message={(
              <>
                <button
                  type="button"
                  data-beacon-article-sidebar="655200c11c5b4a50c1fb45d4"
                  className="tooltip__link scorecard-table__header-tooltip-link"
                >
                  {t("scorecard.learnMore.0")}
                </button>{t("scorecard.learnMore.1")}
              </>
            )}
            trigger={["click"]}
            element={<FileInfo width="13" height="13" fill="#212B46" />}
            close
          />
        </span>
        {size(candidateScoresToBeDisplayed) > 1 && (
          <div className="scorecard-table__header--navigation">
            <div
              className=""
              onClick={() =>
                currentIndex === 0
                  ? setCurrentIndex(candidateScoresToBeDisplayed?.length - 1)
                  : setCurrentIndex(index => index - 1)
              }
            >
              <ChevronLeft />
            </div>
            {currentIndex + 1} of {candidateScoresToBeDisplayed?.length}
            <div
              className=""
              onClick={() =>
                currentIndex + 1 === candidateScoresToBeDisplayed?.length
                  ? setCurrentIndex(0)
                  : setCurrentIndex(index => index + 1)
              }
            >
              <ChevronRight />
            </div>
          </div>
        )}
      </div>
      <div
        className={classNames(
          "scorecard-table__content",
          "scorecard-table__border"
        )}
      >
        {canSeeScores && !isScored && currentIndex === 0 ? (
          <InterviewScorecard
            userId={userId}
            jobId={jobId}
            withBorder={false}
          />
        ) : (
          <>
            {sortedCriteria?.map(a => (
              <InterviewScorecardItem
                {...a}
                key={a.key}
                isEditable={false}
                userId={userId}
                id={a.key}
                text={a.scorecard_criteria.text.text}
                text_markdown={a.scorecard_criteria.text.text_markdown}
                score={a.score}
                description={a?.scorecard_criteria?.description?.description}
              />
            ))}
            <div className="scorecard-table__footer">
              <div className="submitted_by">
                <div>
                  {currentScorecard?.is_total ? (
                    <span style={{ fontWeight: 500 }}>
                      {t("scorecard.totalScore")}
                    </span>
                  ) : (
                    <>{`${t("scorecard.submittedBy")} `}</>
                  )}
                  <span
                    style={{
                      color: "#5A2AF1",
                      fontWeight: 500
                    }}
                  >
                    {scoredByMe
                      ? t("status.user.Rating.you")
                      : currentScorecard?.scored_by?.full_name}
                  </span>
                </div>
                {currentScorecard?.scored_at ? (
                  <div style={{ fontWeight: 300 }}>
                    {moment(currentScorecard?.scored_at).format("DD/MM/YY")} at{" "}
                    {moment(currentScorecard?.scored_at).format("hh:mm A")}
                  </div>
                ) : null}
                {currentScorecard?.is_total && (
                  <div>
                    {size(candidateScores) - 1}{" "}
                    {size(candidateScores) - 1 === 1
                      ? t("scorecard.table.scoreSubmitted")
                      : t("scorecard.table.scoresSubmitted")}
                  </div>
                )}
              </div>

              <TooltipInfo
                prefix="tooltip__icon tooltip__icon-people without-styles align-center"
                position="left"
                message={
                  rating?.untranslated_label
                    ? t(`scorecard.people.rating.${rating?.untranslated_label ?? ""}`)
                    : null
                }
                disabled={!rating?.untranslated_label}
              >
                <div>
                  <InterviewScorecardPill
                    score={currentScorecard?.general_score}
                    iconHeight={16}
                    iconWidth={16}
                    total={
                      currentScorecard?.is_total
                        ? size(candidateScores) - 1
                        : null
                    }
                  />
                </div>
              </TooltipInfo>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

InterviewScorecardsView.defaultProps = {
  candidateScores: [],
  isModalView: false,
  marginBottom: 48,
  title: "",
  userId: ""
};

InterviewScorecardsView.propTypes = {
  candidateScores: PropTypes.arrayOf(),
  isModalView: PropTypes.bool,
  canSeeScores: PropTypes.bool.isRequired,
  isScored: PropTypes.bool.isRequired,
  marginBottom: PropTypes.number,
  title: PropTypes.string,
  userId: PropTypes.string,
  jobId: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  canSeeScores: getCanSeeCandidateScores(state),
  isScored: getIsCurrentCandidateScored(state)
});

export default connect(mapStateToProps)(InterviewScorecardsView);
