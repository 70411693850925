export const PERIODS = {
  monthly: "month",
  annual: "year"
};
export const YEAR_VALUES = [1, 2, 3];

export const PLANS_NAMES = {
  freeForever: "Free Forever",
  launch: "Launch",
  growth: "Growth",
  scale: "Scale",
  enterprise: "Enterprise",
  starter: "Starter",
  build: "Build"
};

export const API_PLANS = [
  {
    name: PLANS_NAMES.build,
    numerator: " ",
    denominator: "plans.denominator",
    billedMonthly: "plans.billedMonthly",
    description: "plans.starter.description",
    interval: PERIODS.monthly,
    size: 1,
    users: 3,
    jobs: "Unlimited",
    sms: "Custom",
    premiumIntegrations: false,
    whiteLabel: false
  }
];
export const PLANS = [
    {
      name: PLANS_NAMES.launch,
      numerator: " ",
      denominator: "plans.denominator",
      billedMonthly: "plans.billedMonthly",
      description: "plans.starter.description",
      interval: PERIODS.monthly,
      size: 1,
      users: 3,
      jobs: 5,
      sms: 75,
      premiumIntegrations: false,
      whiteLabel: false,
      fairUsage: 750
    },
    {
      name: PLANS_NAMES.growth,
      numerator: " ",
      denominator: "plans.denominator",
      billedMonthly: "plans.billedMonthly",
      description: "plans.growth.description",
      interval: PERIODS.monthly,
      size: 2,
      users: 15,
      jobs: 24,
      sms: 360,
      premiumIntegrations: true,
      whiteLabel: false,
      fairUsage: "3,600"

    },
    {
      name: PLANS_NAMES.scale,
      numerator: " ",
      denominator: "plans.denominator",
      billedMonthly: "plans.billedMonthly",
      description: "plans.scale.description",
      interval: PERIODS.monthly,
      size: 3,
      jobs: 60,
      sms: 900,
      premiumIntegrations: true,
      whiteLabel: true,
      fairUsage: "9,000"

    },
    {
      name: PLANS_NAMES.enterprise,
      numerator: "plans.numerator",
      denominator: "plans.denominator",
      billedMonthly: "plans.billedMonthly",
      description: "plans.enterprise.description",
      interval: PERIODS.monthly,
      size: 3,
      jobs: "240+",
      sms: "Custom",
      premiumIntegrations: true,
      whiteLabel: true,
      fairUsage: "Custom"
    }
  ];


export const PLANS_OPTIONS = {
  [PLANS_NAMES.starter]: [
    "plans.options.starterResponses",
    "plans.starter.users",
    "plans.options.unlimitedInterviews",
    "plans.options.ownBranding",
    "plans.starter.sms"
  ],
  [PLANS_NAMES.growth]: [
    "plans.options.growthResponses",
    "plans.growth.users",
    "plans.options.unlimitedInterviews",
    "plans.options.ownBranding",
    "plans.growth.sms",
    "plans.options.successManager"
  ],
  [PLANS_NAMES.scale]: [
    "plans.options.unlimitedResponses",
    "plans.scale.users",
    "plans.options.unlimitedInterviews",
    "plans.scale.whiteLabel",
    "plans.scale.sms",
    "plans.options.successManager",
    "plans.scale.level",
    "plans.scale.storage"
  ]
};

export const PLANS_OPTIONS_ALL = [
  "plans.options.prioritySupport",
  "plans.options.shareResponses",
  "plans.options.fileUploads",
  "plans.options.scheduling",
  "plans.options.videoIntro",
  "plans.options.zapier",
  "plans.options.webhooks",
  "plans.options.userManagement",
  "plans.options.ATSintegration"
];
