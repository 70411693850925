/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import templetesConfig from "configs/jobs/templetesConfig";
import Select from "components/Common/Select";
import TooltipSuccess from "components/Common/Tooltip/Success";
import { updateCurrentStateHandler, prefilledInfo } from "mixins/helpers";

import { Email, Message, Eye } from "./svgIcons";
import "./styles.scss";

const GREEK = "el";

const Templates = ({
  t,
  templatesInfo,
  librariesDivided,
  setIsJobTemplatesValid,
  setEditableJobTemplates,
  setCurrentStepActive,
  setModal,
  isLoadingDivided,
  isTrialOrPaid,
  currentLanguage
}) => {
  const [controls, setControls] = useState(templetesConfig);

  useEffect(() => {
    const predefinedControls = prefilledInfo(controls, templatesInfo) || controls;

    predefinedControls.invitation_email.options = librariesDivided.invitation_email;
    predefinedControls.invitation_sms.options = librariesDivided.invitation_sms;
    predefinedControls.reminder_email.options =
      librariesDivided.reminder_email;
    predefinedControls.reminder_sms.options =
      librariesDivided.reminder_sms;
    predefinedControls.success_notification_email.options =
      librariesDivided.success_notification_email;

    setControls(predefinedControls);
  }, [librariesDivided, templatesInfo]);

  const inputChangedHandler = (event, controlName, type) =>
    setControls(updateCurrentStateHandler(event, controls, controlName, type));
  const onGoBack = () => setCurrentStepActive("questions");
  const onSubmitHandler = event => {
    event.preventDefault();

    setEditableJobTemplates({
      id: templatesInfo.id,
      invitation_email: controls.invitation_email.value,
      invitation_sms: controls.invitation_sms.value,
      reminder_email: controls.reminder_email.value,
      reminder_sms: controls.reminder_sms.value,
      success_notification_email: controls.success_notification_email.value
    });
    setIsJobTemplatesValid(true);
    setCurrentStepActive("preview");
  };
  const openModal = type => {
    const templateInfo = controls[type]?.value;
    if (templateInfo) {
      setModal({
        type: "previewTemplate",
        templateInfo
      });
    }
  };

  // eslint-disable-next-line react/prop-types
  const SelectTemplate = ({ template, type }) => {
    const lockRef = useRef(null);
    const [isShowTooltip, setIsShowTooltip] = useState(false);

    const dropdownClasses = currentLanguage === GREEK ? "word-wrapper" : "";
    const labelClasses = currentLanguage === GREEK ? "word-wrapper__text" : "";

    return (
      <>
        <Select
          name={template}
          formElement={controls[template]}
          inputChangedHandler={inputChangedHandler}
          isLoading={isLoadingDivided}
          disabled={isLoadingDivided || !isTrialOrPaid}
          IconLabel={() => (type === "email" ? <Email /> : <Message />)}
          LabelRight={() => (
            <div
              role="presentation"
              className={`label__main preview ${labelClasses} ${controls[template]?.value ? "" : "disabled"}`}
              onClick={() => openModal(template)}
            >
              <Eye />
              {t("select.preview")}
            </div>
          )}
          labelClasses={dropdownClasses}
          titleClasses={labelClasses}
        />
        {isTrialOrPaid ? null : (
          <>
            <i
              ref={lockRef}
              role="presentation"
              onMouseEnter={() => setIsShowTooltip(true)}
              onMouseLeave={() => setIsShowTooltip(false)}
              className="fas gold fa-lock n-icon__medium right lock-icon"
            />
            <TooltipSuccess
              position="top"
              message={t("job.templates.unlock")}
              target={lockRef.current}
              show={isShowTooltip}
            />
          </>
        )}
      </>
    );
  };

  return (
    <form className="form-description" onSubmit={onSubmitHandler}>
      <Container>
        <Row>
          <Col md={12}>
            <p className="n-font-medium-weight n-black-80 n-font-large">
              {t("job.templates.invitation")}
            </p>
          </Col>
          <Col md={6}>
            <SelectTemplate template="invitation_email" type="email" />
          </Col>
          <Col md={6}>
            <SelectTemplate template="invitation_sms" type="sms" />
          </Col>

          <Col md={12}>
            <p className="n-font-medium-weight n-black-80 n-font-large">
              {t("job.templates.incompleteReminder")}
            </p>
          </Col>
          <Col md={6}>
            <SelectTemplate template="reminder_email" type="email" />
          </Col>
          <Col md={6}>
            <SelectTemplate template="reminder_sms" type="sms" />
          </Col>

          <Col md={12}>
            <p className="n-font-medium-weight n-black-80 n-font-large">
              {t("job.templates.successNotification")}
            </p>
          </Col>
          <Col md={6}>
            <SelectTemplate template="success_notification_email" type="email" />
          </Col>

          <Col md={12}>
            <div className="button__group button__group_center">
              <button
                type="button"
                className="n-button__medium-border n-purple-100 n-border-purple-100"
                onClick={onGoBack}
              >
                {t("button.back")}
              </button>
              <button
                id="job-next-1-button"
                type="submit"
                className="n-button__medium n-bg-purple-100 n-white"
              >
                {templatesInfo.id ? t("button.next") : t("button.save")}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

Templates.defaultProps = {
  history: undefined
};

Templates.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  templatesInfo: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  setIsJobTemplatesValid: PropTypes.func.isRequired,
  librariesDivided: PropTypes.shape({
    invitation_email: PropTypes.array,
    invitation_sms: PropTypes.array,
    reminder_email: PropTypes.array,
    reminder_sms: PropTypes.array,
    success_notification_email: PropTypes.array
  }).isRequired,
  setEditableJobTemplates: PropTypes.func.isRequired,
  setCurrentStepActive: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired,
  isLoadingDivided: PropTypes.bool.isRequired,
  isTrialOrPaid: PropTypes.bool.isRequired,
  currentLanguage: PropTypes.string.isRequired
};

export default withTranslation()(Templates);
