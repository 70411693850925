import React from "react";
import PropTypes from "prop-types";

const withAbilityCreateJob = WrappedComponent => {
  const wrapped = props => {
    const { isAccountOwner, history } = props;
    const redirectTo = path => isAccountOwner && history.push(path);

    return (
      <WrappedComponent
        redirectTo={redirectTo}
        {...props}
      />
    );
  };

  wrapped.defaultProps = {
    isAccountOwner: undefined,
    isSubscriptionActive: undefined,
    match: undefined,
    setModal: undefined,
    history: undefined
  };

  wrapped.propTypes = {
    isAccountOwner: PropTypes.bool,
    isSubscriptionActive: PropTypes.bool,
    match: PropTypes.shape({
      url: PropTypes.string
    }),
    setModal: PropTypes.func,
    history: PropTypes.shape({ push: PropTypes.func })
  };

  return wrapped;
};

export default withAbilityCreateJob;
