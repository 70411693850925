/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isFirefox, isMobileSafari, isMobileOnly, isTablet, isChrome } from "react-device-detect";

import "../Player/styles.scss";

const PlayerMobile = ({
  t,
  videoLink,
  videoPlayerRef,
  videoInputRef,
  videoFileRef,
  onFileUpload,
  cashedVideo,
  isMediaBlocked,
  videoError
}) => {
  const [showPlayer, setShowPlayer] = useState(false);

  useEffect(() => {
    if (!(videoLink || cashedVideo)) {
      setShowPlayer(false);
      return;
    }

    setShowPlayer(true);
  }, [videoLink, cashedVideo]);

  const src = useMemo(
    () => {
      const link = cashedVideo || videoLink;

      if (isMobileSafari || isFirefox || (isChrome && cashedVideo)) {
        return link;
      }

      return link && `${link}#t=0.001`;
    },
    [videoLink, cashedVideo]
  );

  const videoProps = isChrome && (isMobileOnly || isTablet) ? {
    autoplay: cashedVideo ? "autoplay" : "",
    preload: "metadata"
  } : (isMobileSafari || isFirefox) ? {
    autoplay: cashedVideo ? "autoplay" : ""
  } : {};

  const [height, setHeight] = useState("240px");

  const handleMetadata = event => {
    const { clientWidth } = videoPlayerRef?.current || {};
    const { videoHeight, videoWidth } = event?.target || {};
    const clientHeight = `${Math.ceil(clientWidth * videoHeight / videoWidth)}px`;
    setHeight(clientHeight);
  };

  const style = { height };

  return (
    <>
      <input
        ref={videoInputRef}
        type="file"
        accept="video/*"
        capture="user"
        className="mobile-player__video-input"
        onChange={onFileUpload}
      />

      <input
        ref={videoFileRef}
        type="file"
        accept="video/*"
        className="mobile-player__video-input"
        onChange={onFileUpload}
      />

      {showPlayer && (
        <div className="mobile-player" style={style}>
          <video
            {...videoProps}
            ref={videoPlayerRef}
            src={src}
            controls={src}
            volume={1}
            muted
            onLoadedMetadata={handleMetadata}
            style={style}
          />
          {isMediaBlocked && (
            <div className="danger job__video-blocked">
              {t("candidate.videoCreate.mediaBlocked")}
            </div>
          )}
          {!isMediaBlocked && videoError && (
            <div className="danger job__video-blocked">
              {t(videoError)}
            </div>
          )}
        </div>
      )}
    </>
  );
};

PlayerMobile.defaultProps = {
  videoLink: undefined,
  cashedVideo: undefined,
  videoPlayerRef: undefined,
  videoInputRef: undefined,
  videoFileRef: undefined,
  onFileUpload: undefined,
  isMediaBlocked: undefined,
  videoError: undefined
};

PlayerMobile.propTypes = {
  t: PropTypes.func.isRequired,
  videoLink: PropTypes.string,
  videoError: PropTypes.string,
  cashedVideo: PropTypes.string,
  videoFileRef: PropTypes.shape({
    current: PropTypes.object
  }),
  videoPlayerRef: PropTypes.shape({
    current: PropTypes.object
  }),
  videoInputRef: PropTypes.shape({
    current: PropTypes.object
  }),
  onFileUpload: PropTypes.func,
  isMediaBlocked: PropTypes.bool
};

export default withTranslation()(PlayerMobile);
