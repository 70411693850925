import { useState, useEffect } from "react";

const useDebouncedSearch = ({
  delay = 500, // default debounce delay of 500ms
  initialSearch = ""
}: {
  delay?: number; // Optional debounce delay in milliseconds
  initialSearch?: string; // Optional initial search string
}) => {
  const [search, setSearch] = useState<string>(initialSearch);
  const [debouncedSearch, setDebouncedSearch] = useState<string>(initialSearch);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, delay);

    // Clean up timeout when search or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [search, delay]);

  return { search, debouncedSearch, setSearch };
};

export default useDebouncedSearch;
