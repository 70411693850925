import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  saveCurrentQuestion,
  savePreviousQuestion,
  clearPreviousQuestion,
  retakeAnswer,
  saveShareCode,
  saveIsAllAnswerSaved,
  saveAnswer
} from "store/modules/сandidates/actions";
import { setModal } from "store/modules/modals/actions";
import {
  getSavedAnswers,
  getQuestionsForCandidate,
  getCandidateJobInfo,
  getCandidateJobInfoCompany,
  getIsAllAnsweredSaved,
  getPreviousQuestionId,
  getCurrentCandidateId,
  getErrorMessage,
  getCandidateToken,
  getCashedAnswer
} from "store/modules/сandidates/selectors";

import { getCurrentIdvSession } from "store/modules/idv/selectors";

import ShareCode from "views/Candidate/ShareCode/ShareCode";

const mapStateToProps = state => ({
  questionsForCandidate: getQuestionsForCandidate(state),
  jobInfo: getCandidateJobInfo(state),
  jobInfoCompany: getCandidateJobInfoCompany(state),
  savedAnswers: getSavedAnswers(state),
  isAllAnsweredSaved: getIsAllAnsweredSaved(state),
  previousQuestionId: getPreviousQuestionId(state),
  currentCandidateId: getCurrentCandidateId(state),
  idvSession: getCurrentIdvSession(state),
  error: getErrorMessage(state),
  token: getCandidateToken(state),
  cachedAnswer: getCashedAnswer(state)
});

const mapDispatchToProps = {
  saveCurrentQuestion,
  savePreviousQuestion,
  setModal,
  clearPreviousQuestion,
  retakeAnswer,
  saveShareCode,
  saveIsAllAnswerSaved,
  saveAnswer
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShareCode)
);
