/* eslint-disable no-confusing-arrow */
import { ROLE_ADMIN } from "configs/user/rolesConfig";
import { getIsTrialOrPaid } from "store/modules/notifications/selectors";
import isNull from "lodash/isNull";

export const getUsersTableData = state => {
  if (!state?.users?.tableData?.length) {
    return [];
  }

  const data = [...state.users.tableData];

  data.sort((a, b) => a.email?.localeCompare(b.email));

  return data;
};

export const getListOfUsers = state => state?.users?.usersList;

export const getCurrentUser = state => state?.users?.currentUser || {};

export const getIsCurrentUserLoading = state => state?.users?.currentUser?.isLoading || false;

// updated one
export const getHasCurrentUserLoaded = state => state?.common?.hasCurrentUserLoaded;
export const getIsCurrentAgencyLoading = state => state?.users?.currentUser?.isLoading;

export const getAbilityAddUser = state => {
  const { role } = getCurrentUser(state);
  const isTrialOrPaid = getIsTrialOrPaid(state);
  const isAdmin = ROLE_ADMIN === role;

  return isAdmin && isTrialOrPaid;
};

export const getIsUsersAdded = state => state?.users?.isUsersAdded || false;

export const getCurrentUserId = state =>
  getCurrentUser(state)?.key || state?.profile?.profileInfo?.agency || null;

export const getRoleOfCurrentUser = state => getCurrentUser(state).role;

export const getUsersError = state => state?.users?.error;

export const getUsersLoading = state => state?.users?.isLoading;

export const getCurrentUserIntegrationKey = state => getCurrentUser(state)?.integration_key;

export const getInviteCompany = state => state.users?.inviteCompany || {};

export const getInviteUser = state => (state.users?.inviteUser || [])[0];

export const getInviteLoading = state => state.users?.inviteLoading;

export const getInviteError = state => state.users?.inviteError;

export const getJobsMaximum = state => isNull(getCurrentUser(state)?.jobs_maximum_amount)
? 99999999 : getCurrentUser(state)?.jobs_maximum_amount;
