import React from "react";
import PropTypes from "prop-types";

import Labels from "./Labels";
import LeftLabel from "./LeftLabel";
import MovingLabels from "./MovingLabels";
import MovingLabelLeft from "./MovingLabels/Left";
import MovingLabelRight from "./MovingLabels/Right";
import Title from "./Title";
import TotalTitle from "./TotalTitle";
import ProgressBarGraph from "./ProgressBarGraph";
import Footer from "./Footer";

const Panel = ({ children, postfix }) => (
  <div className={`progress__pannel ${postfix}`}>{children}</div>
);

Panel.Labels = Labels;
Panel.LeftLabel = LeftLabel;
Panel.MovingLabels = MovingLabels;
Panel.MovingLabelLeft = MovingLabelLeft;
Panel.MovingLabelRight = MovingLabelRight;
Panel.Title = Title;
Panel.TotalTitle = TotalTitle;
Panel.ProgressBarGraph = ProgressBarGraph;
Panel.Footer = Footer;

Panel.defaultProps = {
  children: undefined,
  postfix: undefined
};

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]),
  postfix: PropTypes.string
};

export default Panel;
