import { handleActions } from "redux-actions";

import {
  setLoadingScreen,
  setLoadingScreenMaintenance,
  saveRoute,
  saveRefreshRoute,
  saveIsOnline,
  setHealthCheckError,
  setRateLimittingTimeRemaining
} from "./actions";

const defaultState = {
  isLoading: false,
  isOnline: navigator.onLine,
  healthcheckError: null,
  timeRemaining: null
};

export default handleActions(
  {
    [setLoadingScreen]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [setLoadingScreenMaintenance]: (state, { payload }) => ({
      ...state,
      isLoadingMaintenance: payload
    }),
    [saveRoute]: (state, { payload }) => ({
      ...state,
      savingRoute: payload
    }),
    [saveRefreshRoute]: (state, { payload }) => ({
      ...state,
      refreshRoute: payload
    }),
    [saveIsOnline]: (state, { payload }) => ({
      ...state,
      isOnline: payload
    }),
    [setHealthCheckError]: (state, { payload }) => ({
      ...state,
      healthcheckError: payload
    }),
    [setRateLimittingTimeRemaining]: (state, { payload }) => ({
      ...state,
      timeRemaining: payload
    })
  },
  defaultState
);
