/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { store } from "store";
import { fetchCandidateScores } from "store/modules/scorecards/action";
import {
  getCandidateScores,
  getCandidateScoresLoading
} from "store/modules/scorecards/selectors";
import InterviewScorecardsView from "views/Jobs/Candidate/InterviewScorecardsView";
import "./styles.scss";
import moment from "moment";
import { Avatar, Close } from "mixins/svgIcons";
import Spinner from "components/Common/Spinner";

const KanbanScorecardModal = ({
  handleCloseModal,
  user,
  title,
  candidateScores,
  isLoading
}) => {
  useEffect(() => {
    store.dispatch(fetchCandidateScores({ userId: user?.key }));
  }, [user]);

  return (
    <Modal
      show
      onHide={handleCloseModal}
      dialogClassName="scorecard-modal"
      centered
    >
      <Modal.Body
        className="important_notice-body"
        style={{ padding: "24px 20px" }}
      >
        <div className="scorecard-modal__header">
          <div className="scorecard-modal__details-container">
            <div>
              {user.avatar_remote_link ? (
                <img
                  src={user.avatar_remote_link}
                  className="scorecard-modal__details-avatar"
                  alt="avatar"
                />
              ) : (
                <div className="scorecard-modal__details-empty-avatar">
                  <Avatar svgIconClass="n-icon__small right" />
                </div>
              )}
            </div>
            <div>
              <div className="scorecard-modal__details-name">{user?.name}</div>
              <div className="scorecard-modal__details-date">
                {moment(user?.submitted_at).format("DD MMM YYYY")}
              </div>
            </div>
          </div>
          <div style={{ cursor: "pointer" }} onClick={handleCloseModal}>
            <Close fill="#595959" />
          </div>
        </div>

        {isLoading ? (
          <Spinner />
        ) : (
          <InterviewScorecardsView
            title={title}
            candidateScores={candidateScores}
            marginBottom={0}
            isModalView
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

KanbanScorecardModal.defaultProps = {
  candidateScores: [],
  title: "",
  user: {},
  handleCloseModal: () => {
    //
  }
};

KanbanScorecardModal.propTypes = {
  candidateScores: PropTypes.arrayOf,
  handleCloseModal: PropTypes.func,
  title: PropTypes.string,
  user: PropTypes.shape({
    jobId: PropTypes.number,
    name: PropTypes.string,
    submitted: PropTypes.string,
    rating: PropTypes.number,
    submitted_at: PropTypes.string,
    key: PropTypes.string
  }),
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  candidateScores: getCandidateScores(state),
  isLoading: getCandidateScoresLoading(state)
});

export default connect(mapStateToProps)(KanbanScorecardModal);
