import React from "react";
import PropTypes from "prop-types";

const Ava = ({ children }) => (
  <div className="profile-card__ava">{children}</div>
);

Ava.defaultProps = {
  children: undefined
};

Ava.propTypes = {
  children: PropTypes.element
};

export default Ava;
