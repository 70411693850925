import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import QRCode from "react-qr-code";
import InputMask from "react-input-mask";
import { Col } from "react-bootstrap";

import { SuccessCard } from "mixins/svgIcons";

export const Buttons = ({ handleCancel, disableContinue }) => {
  const { t } = useTranslation();

  return (
    <div className="button__group_center">
      <button
        type="button"
        className="n-button__medium-border n-purple-100 n-border-purple-100  n-black-100"
        onClick={handleCancel}
      >
        {t("button.cancel")}
      </button>
      <button
        type="submit"
        className="n-button__medium n-bg-purple-100 n-white"
        disabled={disableContinue}
      >
        {t("button.continue")}
      </button>
    </div>
  );
};

Buttons.defaultProps = {
  disableContinue: false
};

Buttons.propTypes = {
  disableContinue: PropTypes.bool,
  handleCancel: PropTypes.func.isRequired
};

export const StepFirst = ({
  qrCode,
  backupCode,
  handleCancel,
  handleContinue,
  createDevice
}) => {
  const { t } = useTranslation();

  const handleSubmit = e => {
    e.preventDefault();
    handleContinue();
  };

  return (
    <Col md={12}>
      <form onSubmit={handleSubmit}>
        <div className="security__qrcode">
          {qrCode ? (
            <>
              <QRCode value={qrCode} size={260} />
              <p className="security__subtitle qrcode n-font-small">
                {backupCode}
              </p>
            </>
          ) : (
            <div className="security__urlError">
              <p className="security__subtitle n-font-medium">
                {t("security.errorUrl")}
              </p>
              <button
                onClick={createDevice}
                className="questions-upload__button"
                type="button"
              >
                {t("link.retry")}
              </button>
            </div>
          )}
        </div>
        <Buttons
          handleCancel={handleCancel}
          disableContinue={!qrCode}
        />
      </form>
    </Col>
  );
};

StepFirst.propTypes = {
  qrCode: PropTypes.string.isRequired,
  backupCode: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  createDevice: PropTypes.func.isRequired
};

export const StepSecond = ({
  confirmDevice,
  handleBack,
  handleContinue,
  error,
  deviceKey,
  clearErrors
}) => {
  const [code, setCode] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    clearErrors();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkCode = e => {
    e.preventDefault();
    confirmDevice({
      code: code.replace(" ", ""),
      callBack: handleContinue,
      deviceKey
    });
  };

  return (
    <Col md={12}>
      <form onSubmit={checkCode}>
        <div className="security__qrcode padding">
          <InputMask
            mask="999 999"
            placeholder="000 000"
            maskChar={null}
            value={code}
            onChange={e => setCode(e.target.value)}
          >
            {inputProps => (
              <input
                className={`n-font-small security__input ${error ? "error" : ""}`}
                {...inputProps}
              />
            )}
          </InputMask>
          <div className="message security__error">
            {error ? t(error) : ""}
          </div>
        </div>
        <Buttons
          handleCancel={handleBack}
          disableContinue={code.length !== 7}
        />
      </form>
    </Col>
  );
};

StepSecond.propTypes = {
  error: PropTypes.string.isRequired,
  confirmDevice: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  deviceKey: PropTypes.string.isRequired,
  clearErrors: PropTypes.string.isRequired
};

export const Success = ({
  isSubscriptionFailed,
  isTrialCompleted,
  isSetupCompleted,
  history,
  backupCode,
  handleCancel
}) => {
  const { t } = useTranslation();
  const handleDone = () => {
    if (isSubscriptionFailed || isTrialCompleted || !isSetupCompleted) {
      history.push("/dashboard");
    } else {
      handleCancel();
    }
  };

  return (
    <Col md={12} className="security__success">
      <div>
        <SuccessCard width="250" height="211" viewBox="500 500 1000 1000" />
      </div>
      <Col md={10} className="security__success-content">
        <p className="security__subtitle n-font-small">
          {t("security.backup", { code: backupCode })}
        </p>
      </Col>
      <button
        type="button"
        className="n-button__medium n-bg-purple-100 n-white"
        onClick={handleDone}
      >
        {t("button.done")}
      </button>
    </Col>
  );
};

Success.propTypes = {
  isSubscriptionFailed: PropTypes.bool.isRequired,
  isTrialCompleted: PropTypes.bool.isRequired,
  isSetupCompleted: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  backupCode: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired
};
