import { connect } from "react-redux";

import {
  resetPassword,
  resetPasswordGetEmail,
  clearErrors
} from "store/modules/auth/actions";

import {
  getEmail,
  getIsLoading,
  getSessionError
} from "store/modules/auth/selectors";

import ResetPassword from "views/Auth/ResetPassword";

const mapStateToProps = state => ({
  email: getEmail(state),
  isLoading: getIsLoading(state),
  error: getSessionError(state)
});

const mapDispatchToProps = {
  resetPasswordGetEmail,
  resetPassword,
  clearErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
