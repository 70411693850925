import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import "../styles.scss";

const ActiveFieldCollection = ({
  isActive,
  component
}) =>
   (
     <div className={`field_collection-wrapper__item-container__content ${isActive ? "visible" : "hidden"}`}>
       {component}
     </div>
  );

ActiveFieldCollection.defaultProps = {
  isActive: false,
  component: undefined
};

ActiveFieldCollection.propTypes = {
  isActive: PropTypes.bool,
  component: PropTypes.oneOfType([
    PropTypes.instanceOf(Component),
    PropTypes.shape({})
  ])
};

export default withTranslation()(ActiveFieldCollection);
