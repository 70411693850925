import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import autosize from "autosize";

import { TEXT_WARNING_MESSAGE, MAX_TEXT_SYMBOLS } from "../constants";

const AddComment = ({ t, userId, sendComment, setCurrentComment, currentComment }) => {
  const [warningMessage, setWarningMessage] = useState("");
  const textarea = useRef(null);

  useEffect(() => {
    autosize(textarea.current);
  }, []);

  const saveComment = event => {
    event.preventDefault();
    sendComment({ userId, text: event.target[0].value });
    setCurrentComment("");
  };

  const handleComment = e => {
    const str = e.target.value.replace(/\n/g, "");

    setWarningMessage(str.length >= MAX_TEXT_SYMBOLS ? TEXT_WARNING_MESSAGE : "");
    setCurrentComment(e.target.value);
  };
  const handleKeyDown = e => {
    if ([37, 39].includes(e.keyCode)) {
      e.stopPropagation();
    }
  };

  return (
    <div>
      <form onSubmit={saveComment}>
        <textarea
          id="comment"
          type="text"
          name="comment"
          row="1"
          ref={textarea}
          value={currentComment}
          className={`form__add-comment-textarea ${
            warningMessage ? "with-warning-message" : ""
          }`}
          placeholder={t("input.candidateCommentPlaceholder")}
          onChange={handleComment}
          onKeyDown={handleKeyDown}
        />

        {warningMessage && (
          <p className="add-comment__warning-message">{warningMessage}</p>
        )}

        <div className="form__add-comment-submit">
          <p className="n-font-small n-black-50">{t("job.candidate.comments.header")}</p>
          <button
            disabled={Boolean(warningMessage) || !currentComment?.trim()}
            className="n-button__small n-bg-purple-100 n-white n-font-semi-bold"
            type="submit"
          >
            {t("button.addNote")}
          </button>
        </div>
      </form>
    </div>
  );
};

AddComment.defaultProps = {
  userId: undefined,
  sendComment: undefined
};

AddComment.propTypes = {
  t: PropTypes.func.isRequired,
  userId: PropTypes.string,
  sendComment: PropTypes.func,
  setCurrentComment: PropTypes.func.isRequired,
  currentComment: PropTypes.string.isRequired
};

export default withTranslation()(AddComment);
