/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { store } from "store";
import { filterAtsJobs, getAvailableAtsJobStages, getNextAtsJobs, resetFilterAtsJobs } from "store/modules/jobs/actions";
import Spinner from "components/Common/Spinner";
import axios from "axios";
import { isEmpty } from "lodash";
import { Search } from "mixins/svgIcons";
import { useDebouncedCallback } from "use-debounce";
import ConnectDropdownJobItem from "./ConnectDropdownJobItem";

let cancelToken;
let timeout;

const ConnectDropdownJobs = ({
  t,
  atsDetails,
  isMobileOrTabletScreen,
  agencyKey,
  selectedJobsAndStages,
  shouldShowStages,
  currentSelectedJob,
  setCurrentSelectedJob,
  setShouldShowStages,
  jobId
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const scrollRef = useRef(null);
  const isNextLoading = atsDetails?.isJobsLoading ?? false;

  const { availableJobs, isFilterLoading, atsConnected } = atsDetails;

  const noResults = !availableJobs?.results?.length && !isNextLoading && !isFilterLoading;
  const shouldShowFullHeight = !availableJobs?.results?.length && !isNextLoading
    && !isFilterLoading;

  const handleSearchChange = useDebouncedCallback(value => {
    setIsSearching(true);
    try {
      const searchParams = !isEmpty(value) ? { search: value } : {};

      if (isEmpty(value)) {
        store.dispatch(resetFilterAtsJobs());
      }

      store.dispatch(filterAtsJobs({
        agencyKey,
        availableJobs,
        params: {
          interview_key: jobId,
          ...{ ...searchParams }
        },
        cancelToken
      }));
    } catch (error) {
      // console.error(error);
    }
  }, 800);

  // eslint-disable-next-line consistent-return
  const fetchNextItems = async () => {
    try {
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      cancelToken = axios.CancelToken.source();

      store.dispatch(getNextAtsJobs({
        agencyKey,
        availableJobs,
        jobId,
        cancelToken
      }));
    } catch (errorMessage) {
      // console.log({ errors });
    }
  };

  const onScroll = () => {
    if (scrollRef.current) {
      clearTimeout(timeout);

      timeout = setTimeout(() => {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

        if (scrollTop + clientHeight + 20 > scrollHeight) {
          const isMobileAndShowingStages = isMobileOrTabletScreen && shouldShowStages;

          if (availableJobs && !isMobileAndShowingStages) {
            fetchNextItems(availableJobs);
          }
        }
      }, 50);
    }
  };

  const getTriggerStages = job => {
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    cancelToken = axios.CancelToken.source();

    setCurrentSelectedJob(job);

    if (isMobileOrTabletScreen) setShouldShowStages(true);

    store.dispatch(getAvailableAtsJobStages({
      agencyKey,
      atsJobId: job.remote_id,
      atsId: job.atsKey,
      cancelToken
    }));
  };

  if (shouldShowStages && isMobileOrTabletScreen) return null;

  return (
    <div
      ref={scrollRef}
      onScroll={onScroll}
      className={`left ${isMobileOrTabletScreen && shouldShowStages ? "hide" : isMobileOrTabletScreen ? "mobile" : ""}`}
    >
      <div style={{ position: "relative" }}>
        <div className="search-icon">
          <Search size={20} />
        </div>
        <input
          className="search"
          placeholder="Search for a job..."
          onChange={e => handleSearchChange(e.target.value)}
        />
      </div>

      <div className={shouldShowFullHeight ? "left-wrapper" : ""}>
        <div className="connect__dropdown__select-items">
          { isFilterLoading ? <Spinner /> : null }

          { !isFilterLoading && availableJobs?.results?.map((job, index) => (
            <ConnectDropdownJobItem
              job={job}
              getTriggerStages={getTriggerStages}
              currentSelectedJob={currentSelectedJob}
              selectedJobsAndStages={selectedJobsAndStages}
              key={`ats-job-item-${String(index)}`}
            />
          ))}

          { noResults && !isFilterLoading ? (
            <span className={isSearching ? "no-jobs-filtered" : "no-jobs"}>
              {
                isSearching
                  ? t("connectDropdown.noSearchResults")
                  : t("connectDropdown.noJobs", { value: atsConnected?.map(a => a.name)?.join(", ") })
              }
            </span>
          ) : null }
        </div>

        <div style={{ marginBottom: 25 }}>
          {isNextLoading ? <Spinner /> : null}
        </div>
      </div>
    </div>
  );
};

ConnectDropdownJobs.defaultProps = {
  t: undefined,
  atsDetails: undefined,
  agencyKey: undefined,
  isMobileOrTabletScreen: undefined,
  selectedJobsAndStages: undefined,
  shouldShowStages: undefined,
  currentSelectedJob: undefined,
  setCurrentSelectedJob: undefined,
  setShouldShowStages: undefined,
  jobId: undefined
};

ConnectDropdownJobs.propTypes = {
  t: PropTypes.func,
  atsDetails: PropTypes.shape({
    atsConnected: PropTypes.arrayOf(PropTypes.shape({})),
    isLoading: PropTypes.bool,
    availableJobs: PropTypes.shape({
      next: PropTypes.string,
      results: PropTypes.arrayOf(PropTypes.shape({})),
      atsKey: PropTypes.string
    }),
    availableStages: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string
    })),
    savedJobsAndStages: PropTypes.arrayOf(PropTypes.shape({
      remote_interview_name: PropTypes.string
    })),
    savedJobsAndStagesCount: PropTypes.number,
    isJobsLoading: PropTypes.bool,
    isFilterLoading: PropTypes.bool
  }),
  agencyKey: PropTypes.string,
  isMobileOrTabletScreen: PropTypes.bool,
  selectedJobsAndStages: PropTypes.arrayOf(PropTypes.shape({})),
  currentSelectedJob: PropTypes.shape({
    remote_id: PropTypes.string,
    title: PropTypes.string,
    atsKey: PropTypes.string
  }),
  shouldShowStages: PropTypes.bool,
  setCurrentSelectedJob: PropTypes.func,
  setShouldShowStages: PropTypes.func,
  jobId: PropTypes.string
};

export default withTranslation()(ConnectDropdownJobs);
