import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  addNewUsers,
  setIsUsersAdded,
  clearUserError
} from "store/modules/users/actions";
import { clearErrors as clearBillingError } from "store/modules/profile/actions";
import { setModal } from "store/modules/modals/actions";
import {
  getCurrentUser,
  getIsUsersAdded,
  getUsersTableData,
  getUsersError,
  getAbilityAddUser,
  getUsersLoading
} from "store/modules/users/selectors";
import {
  getCardInfoLoading,
  getIsLockedCardWithActiveSubscription
} from "store/modules/profile/selectors";
import { getIsTrialCompleted, getIsTrialOrPaid } from "store/modules/notifications/selectors";
import Users from "views/Users";

const mapStateToProps = state => {
  const { is_owner: isAccountOwner } = getCurrentUser(state);

  return {
    isAccountOwner,
    isAbilityAddUser: getAbilityAddUser(state),
    isUsersAdded: getIsUsersAdded(state),
    isCardInfoLoading: getCardInfoLoading(state),
    isSubscriptionActive: getIsTrialOrPaid(state),
    currentUser: getCurrentUser(state),
    dataUsers: getUsersTableData(state),
    error: getUsersError(state),
    isLockedCardWithActiveSubscription: getIsLockedCardWithActiveSubscription(state),
    isTrialCompleted: getIsTrialCompleted(state),
    isUsersLoading: getUsersLoading(state)
  };
};

const mapDispatchToProps = {
  addNewUsers,
  setModal,
  setIsUsersAdded,
  clearUserError,
  clearBillingError
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
