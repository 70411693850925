/* eslint-disable max-len */
import React from "react";
import PageWrapper from "hoc/PageWrapper";

import Lottie from "react-lottie";
import animationData from "assets/images/candidate/error.json";

import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";
import { useTranslation } from "react-i18next";
import { getIsStorageSupported, isCandidateRoute } from "mixins/helperCandidate";
import PropTypes from "prop-types";
import PrivateChat from "components/PrivateChat";
import PrivateChatCandidate from "components/PrivateChatCandidate";
import Offshoot from "views/Candidate/Video/Create/Offshoot";
import { getCandidateInfo } from "store/modules/сandidates/selectors";
import { store } from "store";

import "./styles.scss";

export const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const ErrorFallback = ({
  error,
  // eslint-disable-next-line react/prop-types
  resetErrorBoundary
}) => {
  const { t } = useTranslation();

  const retry = Number(getIsStorageSupported() ? window.sessionStorage.getItem("retry") ?? 0 : 4);
  let currentTime = 5;

  const downloadTimer = setInterval(() => {
    try {
      if (currentTime <= 1) {
        clearInterval(downloadTimer);

        if (retry < 4 && getIsStorageSupported()) {
          window.sessionStorage.setItem("retry", retry + 1);
          window.sessionStorage.setItem("failedPage", window.location.pathname);
        }

        if (retry <= 2) {
          window.location.reload();
          console.log({ resetErrorBoundary });
          // resetErrorBoundary();
        }
      }

      // eslint-disable-next-line no-plusplus
      currentTime--;

      if (retry < 3) {
        document.getElementById("refresh").innerText = `${t("errors.errorFallback.refreshIn", { value: currentTime })}`;
      }
    } catch (err) {
      // console.log({ err });
    }
  }, 1000);

  const message = {
    subject: t("errors.errorFallback.email.subject", { error: "JS Error: Generic" }),
    text: t("errors.errorFallback.email.body")
  };

  const candidateInfo = getCandidateInfo(store.getState());

  return (
    <>
      <PageWrapper
        prefix="complete"
        header={<Header />}
        footer={<Footer isPublic />}
      >
        { candidateInfo?.key && <Offshoot userId={candidateInfo?.key} isRecording={false} isShareCode={false} /> }
        <div className="interview-complete__wrapper" data-sentry-unmask>
          <div className="interview-complete" data-sentry-unmask>
            <Lottie options={defaultOptions} height={200} width={300} />

            <div className="interview-complete__text-box" data-sentry-unmask>
              <h3 className="interview-complete__title" data-sentry-unmask>
                {t("errors.errorFallback.title")}
              </h3>

              <p className="interview-complete__text" data-sentry-unmask>
                {t("errors.errorFallback.subtitle")}
              </p>

              <p className="interview-complete__text" style={{ color: "#E83B3B" }} data-sentry-unmask>
                {error?.message}
              </p>

              {/* <button
                style={{
                  padding: "12px 94px",
                  width: "100%",
                  fontSize: 16,
                  fontWeight: "medium"
                }}
                className="
                  n-bg-purple-100
                  n-x-lg
                  n-button__large
                  n-white
                "
                type="button"
                onClick={() => resetErrorBoundary()}
              >
                {t("modals.error.refresh")}
              </button> */}

              {
                retry >= 3 ? (
                  <button
                    style={{
                      padding: "12px 94px",
                      width: "100%",
                      fontSize: 16,
                      fontWeight: "medium"
                    }}
                    className="
                      n-bg-purple-100
                      n-x-lg
                      n-button__large
                      n-white
                    "
                    type="button"
                    onClick={() => {
                      if (window.Beacon) {
                        window.Beacon("open");
                        window.Beacon("navigate", "/ask/message/");
                        window.Beacon("prefill", message);
                      }
                    }}
                    data-sentry-unmask
                  >
                    {t("errors.errorFallback.contactSupport")}
                  </button>
                ) : (
                  <span id="refresh" data-sentry-unmask>{t("errors.errorFallback.refreshIn", { value: 5 })}</span>
                )
              }
            </div>
          </div>
        </div>

        { isCandidateRoute && error?.message ? <PrivateChatCandidate errorMessage={error?.message} /> : <PrivateChat errorMessage={error?.message} /> }
      </PageWrapper>
    </>
  );
};

ErrorFallback.defaultProps = {
  error: undefined
};

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string
  })
};

export default ErrorFallback;
