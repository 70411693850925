import React, { useEffect } from "react";
import PropTypes from "prop-types";

import {
  createMetaAuthor,
  createMetaDescrption,
  createMetaImage
} from "mixins/helpers";

import srcLogo from "assets/images/icons/Logo.svg";

const withDocumentTitle = WrappedComponent => {
  const Wrapped = props => {
    const {
      t,
      location: { pathname = "", key },
      publicUserInfo: { candidate = {} } = {},
      jobInfo = {},
      jobInfoCompany = {},
      commonJob = {}
    } = props;

    useEffect(() => {
      if (candidate.name) {
        document.title = `${candidate.name ||
          t("breadcrumbs.jobs")} - ${t("button.showcase")} - ${t("title")}`;
      }

      if (jobInfoCompany.name && pathname.includes("candidate")) {
        const jobTitle = jobInfo.title || t("breadcrumbs.jobs");
        const jobCompanyTitle = jobInfoCompany.name || t("breadcrumbs.companies");

        const jobFullTitle = `${jobTitle} - ${jobCompanyTitle} - ${t("title")}`;

        createMetaAuthor(jobCompanyTitle);
        createMetaDescrption(jobInfo.description);
        createMetaImage(jobInfo.company && jobInfo.company.logo, srcLogo);

        document.title = jobFullTitle;
      }

      if (commonJob.title && pathname.includes("common")) {
        const jobTitle = commonJob.title || t("breadcrumbs.jobs");
        const jobCompanyTitle =
          (commonJob.company && commonJob.company.name) || t("breadcrumbs.companies");

        const jobFullTitle = `${jobTitle} - ${jobCompanyTitle} - ${t("title")}`;

        createMetaAuthor(jobCompanyTitle);
        createMetaDescrption(commonJob.description);
        createMetaImage(commonJob.company && commonJob.company.logo, srcLogo);

        document.title = jobFullTitle;
      }
    }, [t, candidate, jobInfoCompany, commonJob]);

    return <WrappedComponent {...props} key={key} />;
  };

  Wrapped.defaultProps = {
    location: undefined,
    publicUserInfo: undefined,
    jobInfo: undefined,
    jobInfoCompany: undefined,
    commonJob: undefined
  };

  Wrapped.propTypes = {
    t: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
      key: PropTypes.string
    }),
    publicUserInfo: PropTypes.shape({
      candidate: PropTypes.shape({
        name: PropTypes.string
      })
    }),
    jobInfo: PropTypes.shape({
      title: PropTypes.string
    }),
    jobInfoCompany: PropTypes.shape({
      name: PropTypes.string
    }),
    commonJob: PropTypes.shape({
      title: PropTypes.string,
      company: PropTypes.shape({
        name: PropTypes.string
      })
    })
  };

  return Wrapped;
};

export default withDocumentTitle;
