import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getIsTrialOrFree, getNotifications } from "store/modules/notifications/selectors";
import {
  getProfileInfo,
  getIsSubscriptionUnpaid
} from "store/modules/profile/selectors";
import { getCurrentUser } from "store/modules/users/selectors";

import {
  fetchNotifications,
  setQuotaExceedMute
} from "store/modules/notifications/actions";

import Notifications from "components/Notifications";

const mapStateToProps = state => ({
  notifications: getNotifications(state),
  profileInfo: getProfileInfo(state),
  currentUser: getCurrentUser(state),
  isSubscriptionUnpaid: getIsSubscriptionUnpaid(state),
  isTrialOrFree: getIsTrialOrFree(state)
});

const mapDispatchToProps = {
  fetchNotifications,
  setQuotaExceedMute
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Notifications)
);
