import { connect } from "react-redux";

import { setModal } from "store/modules/modals/actions";
import Billing from "components/Billing";

const mapDispatchToProps = {
  setModal
};

export default connect(null, mapDispatchToProps)(Billing);
