import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import uuidv4 from "uuid/v4";

import { isLastElement } from "mixins/helpers";

const Breadcrumbs = ({ id = "", pathList = [] }) => (
  <div className="breadcrumbs">
    <ul className="breadcrumbs__list">
      {pathList.map((item = [], index, arr) => (
        <React.Fragment key={`breadcrumb-${item.title}-${uuidv4()}`}>
          <li className="breadcrumbs__item n-grey-100">
            {item.noLink ? (
              <span>{item.title}</span>
            ) : (
              <Link
                className="breadcrumbs__link n-font-medium n-grey-100"
                to={item.key === "edit" ? `${item.path}/${id}` : item.path}
              >
                {item.title}
              </Link>
            )}
          </li>

          {isLastElement(index, arr) || (
            <span className="n-font-medium n-grey-100"> &gt; </span>
          )}
        </React.Fragment>
      ))}
    </ul>
  </div>
);

Breadcrumbs.defaultProps = {
  id: undefined,
  pathList: PropTypes.array
};

Breadcrumbs.propTypes = {
  id: PropTypes.string,
  pathList: PropTypes.arrayOf(PropTypes.object)
};

export default Breadcrumbs;
