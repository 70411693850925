import { createAction } from "redux-actions";

export const isAllCandidatesLoading = createAction("IS_ALL_CANDIDATES_LOADING");
export const isAllJobsLoading = createAction("IS_ALL_JOBS_LOADING");

export const fetchAllJobsStats = createAction("FETCH_ALL_JOBS_STATS");

export const fetchAllJobsStatsResponse = createAction(
  "FETCH_ALL_JOBS_STATS_RESPONSES"
);

export const fetchAllJobsStatsError = createAction(
  "FETCH_ALL_JOBS_STATS_ERROR"
);

export const fetchAllCandidatesStatsResponse = createAction(
  "FETCH_ALL_CANIDATES_STATS_RESPONSES"
);
export const fetchAllCandidatesStatsError = createAction(
  "FETCH_ALL_CANIDATES_STATS_ERROR"
);

export const clearError = createAction("CLEAR_ERROR");

export const setDashboardDateRange = createAction("SET_DASHBOARD_DATE_RANGE");
