/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import "./index.scss";
import { Form } from "react-bootstrap";

const MultipleChoiceCheckboxItem = ({
  title,
  isChecked,
  onChange,
  id,
  isRadio,
  viewOnly,
  isCorrect,
  isRecruiter,
  hasCorrectOption
}) => {
  const status = useMemo(() => {
    if (!isRecruiter) return "";

    if (!hasCorrectOption) {
      return {
       status: "",
       bgColor: ""
      };
    }

    if (isCorrect && isChecked) {
      return {
        status: "correct",
        bgColor: "#eefaed"
      };
    }
    if (!isCorrect && isChecked) {
      return {
        status: "incorrect",
        bgColor: "#FF7F7F"
      };
    }
    if (isCorrect && !isChecked) {
      return {
        status: "correct_unselected",
        bgColor: "#eefaed"
      };
    }

    return {
      status: "",
      bgColor: ""
    };
  }, [hasCorrectOption, isChecked, isCorrect, isRecruiter]);


  return (
    <div
      className={`multiplechoice__item-container ${isRecruiter ? "" : "candidate"} ${viewOnly ? "viewonly" : ""} ${status.status}`}
      style={{
        pointerEvents: viewOnly ? "none" : "auto",
        cursor: "pointer",
        width: "fit-content"
      }}
      onClick={() => {
        onChange(!isChecked, id);
      }}
    >
      <Form.Check
        type={isRadio ? "radio" : "checkbox"}
        checked={isChecked}
        style={{
          pointerEvents: viewOnly ? "none" : "auto",
          accentColor: viewOnly ? "gray" : ""
        }}
        className={`radio__value ${isChecked ? "checked" : ""}`}
        onClick={() => {
          onChange(!isChecked, id);
        }}
      />
      <div>{title}</div>
      {
        isRecruiter && status?.status && (
          <div
            className={`multiplechoice__item-status ${status.status}`}
          >
            {
              status.status === "correct" ? "Correct" : status.status === "incorrect" ? "Incorrect" : ""
            }
          </div>
        )
      }
    </div>
  );
};

MultipleChoiceCheckboxItem.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isRadio: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  viewOnly: PropTypes.bool.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  isRecruiter: PropTypes.bool.isRequired,
  hasCorrectOption: PropTypes.bool.isRequired
};

export default MultipleChoiceCheckboxItem;
