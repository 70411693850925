import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

import Switcher from "components/Switcher";
import { calculatePercent } from "mixins/helpers";
import { DATE_FORMATS } from "configs/dates";
import { JOB_STATUSES } from "configs/jobs/constants";

const Job = ({ t, job, deleteJob, restoreJob }) => {
  const handleOn = () => {
    deleteJob({ jobId: job.key });
  };

  const handleOff = () => {
    restoreJob(job.key);
  };

  return (
    <div className="comapany__job-element">
      <header className="comapany__job-element-header">
        <Link className="comapany__job-link" to={`/jobs/overview/${job.key}`}>
          <h2 className="comapany__job-element-title">{job.title}</h2>
        </Link>
        <Switcher
          checked={job.status === JOB_STATUSES.LIVE}
          handleOn={handleOn}
          handleOff={handleOff}
        />
      </header>
      <main className="comapany__job-element-body">
        <p className="comapany__job-element-line">
          <span className="comapany__job-element-text">
            {t("status.started")}
          </span>
          <span className="comapany__job-element-data">
            {`
              ${job.applications_cnt || 0}
              ${t("company.people")}
            `}
          </span>
        </p>
        <p className="comapany__job-element-line">
          <span className="comapany__job-element-text">
            {t("status.startDate")}
          </span>
          <span className="comapany__job-element-data">
            {moment(job.created_at).format(DATE_FORMATS.SPACE_SEPARATOR)}
          </span>
        </p>
        <p className="comapany__job-element-line">
          <span className="comapany__job-element-text">
            {t("status.responded")}
          </span>
          <span className="comapany__job-element-data">
            {`${job.candidates_cnt || 0} ${t(
              "company.answers"
            )} (${calculatePercent(
              job.candidates_cnt,
              job.applications_cnt
            )}%)`}
          </span>
        </p>
        <p className="comapany__job-element-line">
          <span className="comapany__job-element-text">
            {t("status.endDate")}
          </span>
          <span className="comapany__job-element-data">
            {job.deadline
              ? moment(job.deadline).format(DATE_FORMATS.SPACE_SEPARATOR)
              : t("status.noEndDate")}
          </span>
        </p>
      </main>
    </div>
  );
};

Job.defaultProps = {
  job: undefined,
  deleteJob: undefined,
  restoreJob: undefined
};

Job.propTypes = {
  t: PropTypes.func.isRequired,
  job: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    invited: PropTypes.number,
    interviewed: PropTypes.number,
    created_at: PropTypes.string,
    deadline: PropTypes.string,
    status: PropTypes.string,
    applications_cnt: PropTypes.number,
    candidates_cnt: PropTypes.number
  }),
  deleteJob: PropTypes.func,
  restoreJob: PropTypes.func
};

export default withTranslation()(Job);
