/* eslint-disable camelcase */
import * as Yup from "yup";

import { JOB_STATUSES } from "configs/jobs/constants";

import { MAXIMUM_UPLOAD_IMAGE_SIZE } from "../constants";

const transformIndustryDataForSelect = data => ({
  label: data.name || "",
  value: data.key || null
});

export const setCompanyInfo = ({
  name = "",
  location = "",
  site = "",
  layout_color = "",
  button_color = "",
  industry,
  is_default,
  logo = null
} = {}) => ({
  name,
  location,
  site,
  layoutColor: layout_color,
  buttonColor: button_color,
  logo,
  industry: transformIndustryDataForSelect(industry || {}),
  errors: {},
  touched: {},
  isDefault: is_default
});

export const companyFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("errors.required"),
    location: Yup.string().nullable(),
    site: Yup.string()
      .nullable()
      .trim()
      .test(
        "website",
        "errors.websiteError",
        value => {
          if (!value) return true;
          const pattern = /(http|https):\/\/[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;
          const patternHttp = /^https?:\/\//i;
          return pattern.test(patternHttp.test(value) ? value : `https://${value}`);
        }
      ),
    logo: Yup.mixed()
      .test(
        "fileFormat",
        "errors.validPicture",
        value =>
          typeof value?.type === "undefined" ||
          (value && ["image/png", "image/jpeg"].includes(value.type))
      )
      .test(
        "fileFormat",
        "errors.validaImageSize",
        value =>
          typeof value?.size === "undefined" ||
          (value && value.size <= MAXIMUM_UPLOAD_IMAGE_SIZE)
      ),
    industry: Yup.object().shape({
      label: Yup.string().nullable(),
      value: Yup.string().nullable()
    })
  });

export const countActiveJobs = (jobsData = []) =>
  jobsData.filter(item => item.status === JOB_STATUSES.LIVE).length;

export const selectColor = (setState, setInputValue, field) => (
  color,
  { currentTarget: { value = "", tagName = "" } }
) => {
  const isInput = tagName === "INPUT";
  const isDiv = tagName === "DIV";

  const isString = typeof value === "string";
  const isFullLength = value.length >= 6;

  if (isInput && isString && isFullLength) {
    setState(false);
  }

  if (isDiv) {
    setState(false);
  }

  setInputValue(field, color.hex);
};
