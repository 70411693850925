/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Input from "components/Common/Input";
import TooltipInfo from "components/Common/Tooltip/Info";
import { FileInfo } from "mixins/svgIcons";
import { Controller, useFormContext } from "react-hook-form";

const Thinking = ({
  t,
  order
}) => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name={`questions[${order - 1}].thinking_hours`}
        render={({ field: { onChange, value } }) => (
          <Input
            name="hours"
            formElement={{
              value: value || "",
              icon: "Timer",
              label: "thinkingLabel",
              elementConfig: {
                placeholder: "thinkingHours",
                type: "text"
              },
              InfoElement: (
                <TooltipInfo
                  position="top"
                  trigger={["click"]}
                  message={(
                    <>
                      {t("job.questions.thinking.tooltip")}
                      <a href="#" data-beacon-article-sidebar="62bad7a4c74a080359c847c9" className="tooltip__link">{t("job.questions.types.learnMore")}</a>
                    </>
                  )}
                  element={<FileInfo width="13" height="13" fill="#C5C4C4" />}
                />
              ),
              inputClass: "thinking"
            }}
            inputChangedHandler={e => {
              const numberValue = e.target.value.replace(/\D/g, "");
              onChange(numberValue);
            }}
          />

        )}

      />
      <Controller
        control={control}
        name={`questions[${order - 1}].thinking_minutes`}
        render={({ field: { onChange, value } }) => (
          <Input
            name="minutes"
            formElement={{
              value: value || "",
              icon: "Timer",
              elementConfig: {
                placeholder: "thinkingMinutes",
                type: "text"
              },
              inputClass: "thinking"
            }}
            inputChangedHandler={e => {
              const numberValue = e.target.value.replace(/\D/g, "");
              onChange(numberValue);
            }}
            inputBlurHandler={() => {
              if (value > 59) onChange(59);
            }}
          />
        )
        }
      />
    </>
  );
};

Thinking.defaultProps = {
  t: undefined
};

Thinking.propTypes = {
  t: PropTypes.func,
  order: PropTypes.number.isRequired
};

export default withTranslation()(Thinking);
