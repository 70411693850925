import { createAction } from "redux-actions";

export const isCommonJobsLoading = createAction("IS_COMMON_JOB_LOADING");

export const fetchCommonJob = createAction("FETCH_COMMON_JOB");
export const fetchCommonJobResponse = createAction("FETCH_COMMON_JOB_RESPONSE");
export const fetchCommonJobError = createAction("FETCH_COMMON_JOB_STATS_ERROR");

export const fetchInviteLink = createAction("FETCH_INVITE_LINK");
export const setNewCandidateInfo = createAction(
  "SET_NEW_CANDIDATE_INFO"
);
export const fetchInviteLinkError = createAction("FETCH_INVITE_LINK_ERROR");

// temp put here as this doesnt persist data to local storage
// and this is needed to not duplicate user info api calls on login
export const setHasCurrentUserLoaded = createAction("SET_HAS_CURRENT_USER_LOADED");
