import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { refreshStatus, setLoadingScreen } from "store/modules/widgets/actions";
import { getHealthcheckError, getIsOnline } from "store/modules/widgets/selectors";
import Maintenance from "views/Common/Maintenance";

const mapStateToProps = state => ({
  isLoadingScreen: state.widgets.isLoadingMaintenance,
  isOnline: getIsOnline(state),
  healthcheckError: getHealthcheckError(state)
});

const mapDispatchToProps = {
  refreshStatus,
  setLoadingScreen
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Maintenance));
