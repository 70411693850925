import React from "react";
import PropTypes from "prop-types";

const Right = ({ width = "sm", children }) => (
  <span className={`progress-line__right ${width}`}>{children}</span>
);

Right.defaultProps = {
  width: undefined,
  children: undefined,
};

Right.propTypes = {
  width: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default Right;
