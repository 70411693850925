import React from "react";
import PropTypes from "prop-types";

const Disabled = ({
  fieldName,
  fieldValue,
  handleClassName,
  clicked,
  isDisabled
}) => (
  <div className="color-picker-wrapper">
    <span
      className="color-picker-current-color disabled"
      style={{ backgroundColor: fieldValue }}
    />

    <input
      id={fieldName}
      type="text"
      name="buttonColor"
      className={handleClassName("", "", "input-element disabled")}
      value={fieldValue}
      onClick={clicked}
      disabled={isDisabled}
      readOnly
    />
  </div>
);

Disabled.defaultProps = {
  isDisabled: undefined,
  fieldName: "",
  fieldValue: "",
  handleClassName: undefined,
  clicked: undefined
};

Disabled.propTypes = {
  isDisabled: PropTypes.bool,
  fieldName: PropTypes.string,
  fieldValue: PropTypes.string,
  handleClassName: PropTypes.func,
  clicked: PropTypes.func
};

export default Disabled;
