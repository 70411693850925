export const getEmail = state => state.auth.session.email;

export const getEmailIsSend = state => state.auth.session.resetEmailIsSend;

export const getAuthenticationError = state =>
  state.auth.data && state.auth.data.error;

export const getSessionError = state => state.auth.session.error;

export const getToken = state => state?.auth?.data && state?.auth?.data?.token;

export const getRefreshToken = state =>
  state.auth.data && state.auth.data.refreshToken;

export const getConfirmation = state => state.auth.session.confirmation;

export const getLoggedInParam = state => state.auth.logedIn;

export const getIsLoading = state => state.isLoading;
