import { connect } from "react-redux";

import { ROLE_ADMIN } from "configs/user/rolesConfig";
import {
  fetchLibraries,
  fetchLatestLibraries,
  duplicateLibrary,
  deleteLibrary
} from "store/modules/libraries/actions";
import { setModal } from "store/modules/modals/actions";
import { getLibrariesData, getLibrariesDataPagination } from "store/modules/libraries/selectors";
import { getCurrentUser } from "store/modules/users/selectors";
import Table from "components/Tables/Libraries";
import { getLanguage } from "store/modules/language/selectors";

const mapStateToProps = state => {
  const { role } = getCurrentUser(state);
  const isAdmin = role === ROLE_ADMIN;

  return {
    isAdmin,
    isLoading: state.libraries.isLoading,
    isLatestLibrariesLoading: state.libraries.isLatestLibrariesLoading,
    currentUser: getCurrentUser(state),
    dataLibraries: getLibrariesData(state),
    dataLibrariesPagination: getLibrariesDataPagination(state),
    currentLanguage: getLanguage(state)
  };
};

const mapDispatchToProps = {
  fetchLibraries,
  fetchLatestLibraries,
  duplicateLibrary,
  deleteLibrary,
  setModal
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
