/* eslint-disable operator-linebreak */
import React from "react";
import PropTypes from "prop-types";

const LeftLabel = ({ label, infoLabel, children }) => (
  <div className="progress__left-label">
    <span className="progress__left-label-title">{label}</span>

    <div className="progress__left-children-container">{children}</div>

    <span className="progress__left-label-info">{infoLabel}</span>
  </div>
);

LeftLabel.defaultProps = {
  label: undefined,
  children: undefined,
  infoLabel: undefined
};

LeftLabel.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]),
  infoLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ])
};

export default LeftLabel;
