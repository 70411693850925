const downloadFile = url => {
  const a = document.createElement("a");
  a.href = url;
  a.download = url.split("/").pop();
  document.body.appendChild(a);
  a.click();
  if (document.body.contains(a)) {
    document.body.removeChild(a);
  }
};

export default downloadFile;
