import React from "react";
import { Question } from "types/candidateAnswer.ts";

type StepperDesktopProps = {
  questions: Question[];
  currentStep: number;
  totalSteps: number;
  renderStep: (question: Question, index: number, isMobile: boolean) => React.ReactNode;
  handleStepClick: (questionId: string, index: number) => void;
};

const StepperDesktop = ({
  questions,
  renderStep
}: StepperDesktopProps) => (
  <>
    <div className="relative touch-pan-x">
      <div className="flex gap-0.5 transition-transform duration-300 ease-out pb-2 md:pb-4">
        {questions.map((question, index) => renderStep(question, index, false))}
      </div>
    </div>
  </>
);

export default StepperDesktop;
