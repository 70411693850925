import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { EditIconStroke, DeleteIconStroke } from "mixins/svgIcons";

const Footer = ({
  t,
  comapnyId = null,
  jobsNumber = 0,
  isDefault,
  handleEdit,
  handleDelete
}) => (
  <footer className="company-card-footer">
    <span className="company-jobs">
      {t("company.jobs")}
      <span className="company-jobs-number">{jobsNumber}</span>
    </span>

    <div className="company-card-footer-btns">
      <button
        onClick={handleEdit}
        value={comapnyId}
        className="button__without-styles"
        type="button"
      >
        <EditIconStroke svgIconClass="company-card-icon not-clickable-icon n-svg-black-50" />
      </button>
      <button
        id="delete-company-button"
        onClick={() => handleDelete({ comapnyId, isDefault })}
        className="button__without-styles"
        type="button"
      >
        <DeleteIconStroke svgIconClass="company-card-icon not-clickable-icon n-svg-black-50" />
      </button>
    </div>
  </footer>
);

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  comapnyId: PropTypes.string.isRequired,
  jobsNumber: PropTypes.number.isRequired,
  isDefault: PropTypes.bool.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

export default withTranslation()(Footer);
