/* eslint-disable react/prop-types */
import React from "react";
import { withTranslation } from "react-i18next";

import { Formik } from "formik";

import RadioRating from "components/Forms/Inputs/RadioRating";

import { STARS_QUANTITY } from "configs/mockup";

const RatingSearch = ({ t, rank, setRank, clearAll }) => (
  <div className="ranking-search">
    <h2 className="ranking-search__title n-font-small n-black-80">
      {t("job.ranking")}
    </h2>

    <div className="rating-wrapper">
      <Formik
        enableReinitialize
        initialValues={{
          radioGroup: rank
        }}
        render={({ values, setFieldValue, onBlur }) => (
          <form className="form__search form__search_ranking">
            {STARS_QUANTITY.map(star => (
              <RadioRating
                id={star.number}
                key={star.number}
                label={star.number}
                handleRating={ratingNumber => e => {
                  setRank(ratingNumber);
                  setFieldValue("radioGroup", Number(e.target.value));
                }}
                onBlur={onBlur}
                value={values.radioGroup}
                name="radioGroup"
                active={rank === star.number}
              />
            ))}
          </form>
        )}
      />
    </div>
    <div className="button__group_left">
      <button
        type="button"
        className="button__clear-all n-button__small n-button__small-border n-border-grey-100 n-font-small n-bg-white n-black-80"
        onClick={clearAll}
      >
        {t("button.clearAll")}
      </button>
    </div>
  </div>
);

export default withTranslation()(RatingSearch);
