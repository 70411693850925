import { combineActions, handleActions } from "redux-actions";

import {
  isLoading,
  signInResponse,
  signInWithLinkedInResponse,
  signInWithGoogleResponse,
  signUpResponse,
  confirmEmailResponse,
  saveToken,
  resetPasswordGetEmailResponse,
  resetPasswordGetEmailError,
  resetEmailIsSend,
  keppLogedIn,
  signInError,
  signUpError,
  confirmEmailError,
  resetPasswordError,
  clearConfirms,
  clearErrors,
  clearEmails,
  logOut,
  setSignInPayload
} from "./actions";

const defaultState = {
  data: {},
  session: {},
  isLoading: false,
  logedIn: true,
  signin: null
};

export default handleActions(
  {
    [isLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [setSignInPayload]: (state, { payload }) => ({
      ...state,
      signin: payload
    }),
    [combineActions(
      signInResponse,
      signUpResponse,
      signInWithLinkedInResponse,
      signInWithGoogleResponse
    )]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        ...payload
      }
    }),
    [saveToken]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        token: payload,
        error: null
      }
    }),
    [confirmEmailResponse]: (state, { payload }) => ({
      ...state,
      session: {
        ...state.session,
        confirmation: payload
      }
    }),
    [resetEmailIsSend]: (state, { payload }) => ({
      ...state,
      session: {
        ...state.session,
        resetEmailIsSend: payload
      }
    }),
    [resetPasswordGetEmailResponse]: (state, { payload }) => ({
      ...state,
      session: {
        ...state.data,
        email: payload
      }
    }),
    [resetPasswordGetEmailError]: (state, { payload }) => ({
      ...state,
      session: {
        ...state.data,
        error: payload
      }
    }),
    [signInError]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        error: payload
      }
    }),
    [combineActions(signUpError, confirmEmailError, resetPasswordError)]: (
      state,
      { payload }
    ) => ({
      ...state,
      session: {
        ...state.session,
        confirmationMessage: null,
        error: payload
      }
    }),
    [keppLogedIn]: (state, { payload }) => ({
      ...state,
      logedIn: payload
    }),
    [logOut]: () => ({
      ...defaultState
    }),
    [clearErrors]: state => ({
      ...state,
      data: {
        ...state.data,
        error: null
      },
      session: {
        ...state.session,
        error: null
      }
    }),
    [clearEmails]: state => ({
      ...state,
      session: {
        ...state.session,
        email: null,
        resetEmailIsSend: false
      }
    }),
    [clearConfirms]: state => ({
      ...state,
      session: {
        ...state.session,
        confirmation: null
      }
    })
  },
  defaultState
);
