import React from "react";
import PropTypes from "prop-types";

const Radio = ({
  id,
  label,
  field: { name, value, onChange, onBlur } = {},
  ...props
}) => (
  <div className="input__radio">
    <input
      name={name}
      id={id}
      type="radio"
      value={id} // could be something else for output?
      checked={id === value}
      onChange={onChange}
      onBlur={onBlur}
      className="input__radio-item"
      {...props}
    />
    <label className="input__radio-label" htmlFor={id}>
      {label}
    </label>
  </div>
);

Radio.defaultProps = {
  label: undefined,
  field: PropTypes.shape({})
};

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  })
};

export default Radio;
