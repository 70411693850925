import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchPublicUserInfo, clearErrors } from "store/modules/public/actions";
import { getPublicUserInfo } from "store/modules/public/selectors";
import CandidatePublic from "views/Candidate/Public";

const mapStateToProps = state => ({
  publicUserInfo: getPublicUserInfo(state)
});

const mapDispatchToProps = {
  fetchPublicUserInfo,
  clearErrors
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CandidatePublic)
);
