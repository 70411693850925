/* eslint-disable react/prop-types */
import React from "react";

export const Error = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6 12C4.90384 12 3.88943 11.726 2.95673 11.1779C2.05288 10.6587 1.34135 9.94712 0.822115 9.04327C0.274035 8.11057 0 7.09616 0 6C0 4.90384 0.274035 3.88943 0.822115 2.95673C1.34135 2.05288 2.05288 1.34135 2.95673 0.822115C3.88943 0.274035 4.90384 0 6 0C7.09616 0 8.11057 0.274035 9.04327 0.822115C9.94712 1.35096 10.6587 2.0673 11.1779 2.97115C11.726 3.90385 12 4.91346 12 6C12 7.08654 11.726 8.09615 11.1779 9.02885C10.649 9.9327 9.9327 10.649 9.02885 11.1779C8.09615 11.726 7.08654 12 6 12ZM3.57692 1.84616C4.32693 1.40384 5.13461 1.18269 6 1.18269C6.86539 1.18269 7.67307 1.40384 8.42308 1.84616C9.15385 2.26923 9.73077 2.84615 10.1538 3.57692C10.5962 4.32693 10.8173 5.13461 10.8173 6C10.8173 6.86539 10.5962 7.67307 10.1538 8.42308C9.73077 9.15385 9.15385 9.73077 8.42308 10.1538C7.67307 10.5962 6.86539 10.8173 6 10.8173C5.13461 10.8173 4.32693 10.5962 3.57692 10.1538C2.84615 9.73077 2.26923 9.15385 1.84616 8.42308C1.40384 7.67307 1.18269 6.86539 1.18269 6C1.18269 5.13461 1.40384 4.32693 1.84616 3.57692C2.26923 2.84615 2.84615 2.26923 3.57692 1.84616ZM6.59135 9.01442L6.59135 7.80289H5.40865L5.40865 9.01442H6.59135ZM6.59135 6.59135V2.98558H5.40865V6.59135H6.59135Z" fill="#E83B3B" />
  </svg>
);

export const Checked = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0.3125C4.64973 0.3125 0.3125 4.64973 0.3125 10C0.3125 15.3503 4.64973 19.6875 10 19.6875C15.3503 19.6875 19.6875 15.3503 19.6875 10C19.6875 4.64973 15.3503 0.3125 10 0.3125ZM10 2.1875C14.3177 2.1875 17.8125 5.68168 17.8125 10C17.8125 14.3177 14.3183 17.8125 10 17.8125C5.68234 17.8125 2.1875 14.3183 2.1875 10C2.1875 5.68234 5.68168 2.1875 10 2.1875ZM15.4767 7.27605L14.5964 6.38863C14.4141 6.20484 14.1173 6.20363 13.9335 6.38598L8.41195 11.8632L6.07633 9.50859C5.89402 9.3248 5.59723 9.32359 5.41344 9.5059L4.52598 10.3862C4.34219 10.5685 4.34098 10.8653 4.52332 11.0491L8.06945 14.624C8.25176 14.8078 8.54855 14.809 8.73234 14.6266L15.4741 7.93898C15.6579 7.75664 15.659 7.45984 15.4767 7.27605Z" fill="#5A2AF1" />
  </svg>
);

export const Circle = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.20312 0C4.11914 0 0 4.11914 0 9.20312C0 14.2871 4.11914 18.4062 9.20312 18.4062C14.2871 18.4062 18.4062 14.2871 18.4062 9.20312C18.4062 4.11914 14.2871 0 9.20312 0ZM9.20312 16.625C5.10254 16.625 1.78125 13.3037 1.78125 9.20312C1.78125 5.10254 5.10254 1.78125 9.20312 1.78125C13.3037 1.78125 16.625 5.10254 16.625 9.20312C16.625 13.3037 13.3037 16.625 9.20312 16.625Z" fill="#5A2AF1" fillOpacity="0.5" />
  </svg>
);
