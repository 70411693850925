/* eslint-disable operator-linebreak */
/* eslint-disable indent */
import { handleActions, combineActions } from "redux-actions";
import { logOut } from "../auth/actions";

import {
  isUsersLoading,
  setIsUsersAdded,
  setCurrentUser,
  addNewUsersResponse,
  addNewUsersError,
  updateUserResponse,
  fetchUsersResponse,
  fetchUsersError,
  fetchUsersListResponse,
  fetchUsersListError,
  deleteUserResponse,
  clearUserError,
  updateAgency,
  fetchInviteCompanyResponse,
  addNewInviteResponse,
  addNewInviteLoading,
  getCurrentAgencyLoading,
  setCurrentAgency,
  setResetCurrentAgency,
  updateUsersListResponse,
  setUpdatedAgency,
  setIsAgencyUpdated
} from "./actions";
import { generateNewKeyResponse } from "../marketplace/actions";

const defaultState = {
  usersList: [],
  currentUser: {
    isAgencyUpdated: false
  },
  tableData: []
};

export default handleActions(
  {
    [isUsersLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [setIsUsersAdded]: (state, { payload }) => ({
      ...state,
      isUsersAdded: payload
    }),
    [fetchUsersResponse]: (state, { payload }) => ({
        ...state,
        usersList: payload
      }),
    [updateUsersListResponse]: (state, { payload }) => ({
      ...state,
      usersList: payload
    }),
    [fetchUsersListResponse]: (state, { payload }) => ({
      ...state,
      tableData: payload
    }),
    [addNewUsersResponse]: (state, { payload }) => ({
      ...state,
      tableData: [...payload, ...(state.tableData || [])]
    }),
    [updateUserResponse]: (state, { payload }) => ({
      ...state,
      tableData: state.tableData.reduce(
        (acc, val) => [
          ...acc,
          val.key === payload.key
            ? {
                ...val,
                ...payload
              }
            : val
        ],
        []
      )
    }),
    [setCurrentUser]: (state, { payload }) => ({
      ...state,
      currentUser: payload
    }),
    [combineActions(fetchUsersError, fetchUsersListError, addNewUsersError)]: (
      state,
      { payload }
    ) => ({
      ...state,
      error: payload
    }),
    [deleteUserResponse]: (state, { payload }) => ({
      ...state,
      tableData: state.tableData.filter(item => item.key !== payload)
    }),
    [clearUserError]: state => ({
      ...state,
      error: null
    }),
    [updateAgency]: (state, { payload }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        ...payload
      }
    }),
    [fetchInviteCompanyResponse]: (state, { payload }) => ({
      ...state,
      inviteCompany: payload,
      inviteUser: null
    }),
    [addNewInviteResponse]: (state, { payload }) => ({
      ...state,
      inviteUser: payload
    }),
    [addNewInviteLoading]: (state, { payload }) => ({
      ...state,
      inviteLoading: payload
    }),
    [addNewUsersError]: (state, { payload }) => ({
      ...state,
      inviteError: payload
    }),

    // create a new state for current agency
    [getCurrentAgencyLoading]: (state, { payload }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        isLoading: payload
      }
    }),
    [setCurrentAgency]: (state, { payload }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        ...payload
      }
    }),
    [setUpdatedAgency]: (state, { payload }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        ...payload
      }
    }),
    [setIsAgencyUpdated]: (state, { payload }) => ({
      ...state,
      currentUser: {
        ...state.currentUser,
        isAgencyUpdated: payload
      }
    }),
    [setResetCurrentAgency]: state => ({
      ...state,
      currentUser: {}
    }),
    [logOut]: () => ({
      ...defaultState
    }),
    [generateNewKeyResponse]: (state, { payload: { newKey } }) => ({
        ...state,
        currentUser: {
          ...state.currentUser,
          integration_key: newKey
        }
      })
  },
  defaultState
);
