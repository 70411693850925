import { createAction } from "redux-actions";

export const fetchCurrentCompany = createAction("FETCH_CURRENT_COMPANY");
export const fetchCurrentCompanyResponse = createAction(
  "FETCH_CURRENT_COMPANY_RESPONSE"
);
export const fetchCurrentCompanyError = createAction(
  "FETCH_CURRENT_COMPANY_ERROR"
);

export const fetchCompaniesForTable = createAction("FETCH_COMPANIES_FOR_TABLE");
export const fetchCompaniesForTableResponse = createAction(
  "FETCH_COMPANIES_FOR_TABLE_RESPONSE"
);

export const editCompany = createAction("EDIT_COMPANY");
export const editCompanyResponse = createAction("EDIT_COMPANY_RESPONSE");
export const editCompanyError = createAction("EDIT_COMPANY_ERROR");

export const deleteCompany = createAction("DELETE_COMPANY");
export const deleteCompanyResponse = createAction("DELETE_COMPANY_RESPONSE");
export const deleteCompanyError = createAction("DELETE_COMPANY_ERROR");

export const fetchStartCompanyOverview = createAction(
  "FETCH_START_COMPANY_OVERVIEW"
);

export const createCompany = createAction("CREATE_COMPANY");
export const createCompanyResponse = createAction("CREATE_COMPANY_RESPONSE");
export const createCompanyError = createAction("CREATE_COMPANY_ERROR");

export const setCurrentDateRange = createAction("SET_CURRENT_DATE_RANGE");

export const clearCompanies = createAction("CLEAR_COMPANIES");

export const isCompaniesLoading = createAction("IS_COMPANIES_LOADING");

export const clearCompanyError = createAction("CLEAR_COMPANY_ERROR");
