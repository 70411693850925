import { connect } from "react-redux";

import Table from "components/Tables/Jobs";
import { ROLE_ADMIN } from "configs/user/rolesConfig";
import {
  cashCurrentJob, clearSetupState, clearStatuses, deleteJob, duplicateJob, fetchJobs,
  fetchLatestJobs, restoreJob
} from "store/modules/jobs/actions";
import {
  getIsJobsLoading, getIsLatestJobsLoading, getJobsData,
  getJobsDataPagination
} from "store/modules/jobs/selectors";
import { setModal } from "store/modules/modals/actions";
import { getIsFree, getIsTrialOrPaid } from "store/modules/notifications/selectors";
import { getProfileInfo } from "store/modules/profile/selectors";
import { getCurrentUser } from "store/modules/users/selectors";

const mapStateToProps = state => {
  const { plan_name: planName, role } = getCurrentUser(state);
  const isAdmin = role === ROLE_ADMIN;

  return {
    planName,
    isAdmin,
    isLoading: getIsJobsLoading(state),
    isLatestJobsLoading: getIsLatestJobsLoading(state),
    isSubscriptionActive: getIsTrialOrPaid(state),
    profileInfo: getProfileInfo(state),
    currentUser: getCurrentUser(state),
    dataJobs: getJobsData(state),
    dataJobsPagination: getJobsDataPagination(state),
    isFree: getIsFree(state)
  };
};

const mapDispatchToProps = {
  fetchJobs,
  fetchLatestJobs,
  cashCurrentJob,
  duplicateJob,
  restoreJob,
  deleteJob,
  clearStatuses,
  setModal,
  clearSetupState
};

export default connect(mapStateToProps, mapDispatchToProps)(Table);
