import React from "react";
import PropTypes from "prop-types";

import { UploadVideoSuccess, UploadVideoError } from "mixins/svgIcons";

const Video = ({ message, error = false }) => (
  <div className={`upload upload_${error ? "error" : "success"} sentry-unmask`}>
    {error ? (
      <UploadVideoError svgIconClass="upload__icon" />
    ) : (
      <UploadVideoSuccess svgIconClass="upload__icon" />
    )}
    {message}
  </div>
);

Video.defaultProps = {
  message: undefined,
  error: undefined
};

Video.propTypes = {
  message: PropTypes.string,
  error: PropTypes.bool
};

export default Video;
