import React, { ReactNode } from "react";
import useIsHydrated from "hooks/useIsHydrated.tsx";
import Spinner from "../Spinner";

type HydrationGuardProps = {
  children: ReactNode;
};

const HydrationGuard = ({ children }: HydrationGuardProps): React.ReactElement => {
  const isHydrated = useIsHydrated();

  if (!isHydrated) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    );
  }

  return <>{children}</>;
};

export default HydrationGuard;
