import React, { createRef, useLayoutEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import PropTypes from "prop-types";
import DOMPurify from "dompurify";

const QuestionsText = ({ text }) => {
  const ref = createRef();
  const [heightText, setHeight] = useState(0);
  useLayoutEffect(() => {
    if (ref.current) {
      const { current } = ref;
      const boundingRect = current.getBoundingClientRect();
      const { height } = boundingRect;
      setHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function transform(node) {
    if (node.type === "tag") {
      node.attribs = {
        ...node.attribs,
        target: "_blank",
        class: "",
        className: ""
      };
    }
  }

  return (
    <div
      ref={ref}
      className={`question-create__questions-text ${
        heightText > 150 ? "small-text" : ""
      }`}
    >
      {ReactHtmlParser(
        DOMPurify.sanitize(text, {
          ADD_ATTR: "href"
        }),
        {
          transform
        }
      )}
    </div>
  );
};

QuestionsText.propTypes = {
  text: PropTypes.string.isRequired
};

export default QuestionsText;
