import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import TooltipInfo from "components/Common/Tooltip/Info";
import { FileInfo } from "mixins/svgIcons";

const Input = ({
  t,
  getFilesFromEvent,
  accept,
  multiple,
  disabled,
  onFiles,
  files,
  extra: { maxFiles },
  buttonStyle,
  ButtonComponent,
  inputFileName
}) => {
const [error, setError] = useState(false);
  return (
    <>
      {files.length < maxFiles && (
        ButtonComponent || (
        <div>
          <div className="dzu-inputContainer">
            <TooltipInfo
              element={<FileInfo />}
              position="bottom"
              message={t("input.fileUploadInfo")}
              prefix="file-tooltip"
            />
            <p className="dzu-label">{t("candidate.videoCreate.dragAndDrop")}</p>
            <p className="dzu-label padding">{t("candidate.videoCreate.or")}</p>
            <label
              className="dzu-inputLabel n-fluid n-button__medium n-bg-purple-100 n-white"
              htmlFor={inputFileName}
              style={buttonStyle}
            >
              {t("button.browse")}
            </label>
            {
              error && (
                <div style={{
                  color: "red",
                  fontSize: 12,
                  padding: "0px 10px",
                  margin: "10px auto",
                  textAlign: "center"
                }}
                >
                  {t("input.fileUploadError")}
                </div>
              )
            }
          </div>
        </div>
        )
      )}
      <input
        id={inputFileName}
        className="dzu-input"
        type="file"
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        onChange={async e => {
          const { target } = e;
          const chosenFiles = await getFilesFromEvent(e);
          const fileName = chosenFiles?.[0]?.name;
          const name = fileName?.split(".")?.[0];
          if (name?.length > 128) {
            setError(true);
            return;
          }

          setError(false);
          if (files.length) files[0].remove();
          onFiles(chosenFiles);
          target.value = null;
        }}
      />
    </>
  );
};

Input.defaultProps = {
  ButtonComponent: null,
  inputFileName: "file"
};

Input.propTypes = {
  t: PropTypes.func.isRequired,
  getFilesFromEvent: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
  multiple: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onFiles: PropTypes.func.isRequired,
  extra: PropTypes.shape({
    maxFiles: PropTypes.number.isRequired
  }).isRequired,
  files: PropTypes.arrayOf(PropTypes.any).isRequired,
  buttonStyle: PropTypes.shape({}).isRequired,
  ButtonComponent: PropTypes.element,
  inputFileName: PropTypes.string
};

export default withTranslation()(Input);
