/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./styles.scss";

// eslint-disable-next-line react/prop-types
const CandidateActionErrorModal = ({ handleCloseModal }) => {
  const { t } = useTranslation();
  return (
    <Modal
      show
      onHide={handleCloseModal}
      backdrop="static"
      dialogClassName="invalidactions-modal"
      centered
    >
      <Modal.Body className="">
        <div className="invalidactions-modal__container">
          <div
            className="important_notice-container__body"
            style={{
              marginTop: 20
            }}
          >
            {t("job.overview.candidateProgress.actionNotAvailable")}
          </div>
          <button
            className="important_notice-container__cta"
            onClick={() => {
              handleCloseModal();
            }}
          >
            {t("button.ok")}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CandidateActionErrorModal;
