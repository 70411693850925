import { handleActions, combineActions } from "redux-actions";

import {
  isJobsLoading,
  fetchIndustriesResponse,
  setFormQuestionsWasEdit,
  createQuestionsInfo,
  clearQuestionsState,
  clearErrors,
  setSuggestions,
  createJob,
  setIdvActivationRedirect,
  setCurrentStepActive,
  setIsUpgradeComplete
} from "./actions";

const defaultState = {
  isLoading: false,
  suggestions: [],
  redirectUrl: null,
  activeStep: "setup",
  isUpgradeComplete: null
};

export default handleActions(
  {
    [isJobsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [createQuestionsInfo]: (state, { payload }) => {
      // Delete all question fields
      Object.keys(state)
        .filter(key => key.includes("question"))
        .forEach(key => {
          delete state[key];
        });

      return {
        ...state,
        ...payload
      };
    },
    [fetchIndustriesResponse]: (state, { payload }) => ({
      ...state,
      industriesOptions: payload
    }),
    [setFormQuestionsWasEdit]: (state, { payload }) => ({
      ...state,
      isFormQuestionsWasEdit: payload
    }),
    [combineActions(clearQuestionsState, createJob)]: state => ({
      ...defaultState,
      ...{
        redirectUrl: ["pending", "completed"].includes(state.isUpgradeComplete) ? state.redirectUrl : defaultState.redirectUrl,
        activeStep: ["pending", "completed"].includes(state.isUpgradeComplete) ? state.activeStep : defaultState.activeStep,
        isUpgradeComplete: ["pending", "completed"].includes(state.isUpgradeComplete) ? state.isUpgradeComplete : defaultState.isUpgradeComplete
      }
    }),
    [clearErrors]: state => ({
      ...state,
      error: null
    }),
    [setSuggestions]: (state, { payload }) => ({
      ...state,
      suggestions: payload
    }),
    [setIdvActivationRedirect]: (state, { payload }) => ({
      ...state,
      redirectUrl: payload
    }),
    [setCurrentStepActive]: (state, { payload }) => ({
      ...state,
      activeStep: payload
    }),
    [setIsUpgradeComplete]: (state, { payload }) => ({
      ...state,
      isUpgradeComplete: payload
    })
  },
  defaultState
);
