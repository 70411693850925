/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
import { takeLatest, select, call, put } from "redux-saga/effects";

// TO-DO Rework cycle dependencies

/* eslint-disable-next-line */
import { history } from "store";

import {
  getCheckoutLink,
  getStripeSessionInfo,
  getCheckoutLinkError,
  isBillingInfoLoading,
  getCurrencies,
  setCurrencies,
  setCurrenciesLoading
} from "store/modules/profile/actions";
import { getToken } from "store/modules/auth/selectors";
import { getCurrentUser, getCurrentUserId } from "store/modules/users/selectors";
import { fetchNotifications } from "store/modules/notifications/actions";

import { getCurrentIdvActivationRedirect } from "store/modules/jobs/selectors";
import { setIsUpgradeComplete } from "store/modules/jobs/actions";
import { setUpdatedAgency } from "store/modules/users/actions";
import { putRequestWithToken, postRequestWithToken, getRequestWithToken } from "./api";
import { handleProfileInfo } from "./profile";
import { getCurrentAgencyPlanWorker } from "./users";
import { fetchNotificationsWorker } from "./notifications";

function* getCheckoutLinkWorker({ payload }) {
  try {
    yield put(isBillingInfoLoading(true));
    yield put(getCheckoutLinkError(""));

    const token = yield select(getToken);
    const agencyKey = yield select(getCurrentUserId);

    if (payload) {
      const {
        data: { checkout_link: checkoutLink }
      } = yield call(postRequestWithToken, {
        url: `/api/agency/${agencyKey}/checkout/`,
        payload: {
          price: payload
        },
        token
      });

      window.location.href = checkoutLink;
    } else {
      yield call(putRequestWithToken, {
        url: `/api/agency/${agencyKey}/set-forever-free/`,
        token
      });
      yield call(handleProfileInfo);
      yield put(fetchNotifications({ key: agencyKey }));

      const idvActivationRedirect = yield select(getCurrentIdvActivationRedirect);

      if (idvActivationRedirect !== null) {
        yield put(setIsUpgradeComplete("completed"));
        history.push(idvActivationRedirect);
      } else { history.push("/dashboard"); }

      yield put(isBillingInfoLoading(false));
    }
  } catch (error) {
    console.log("[getCheckoutLinkError]", error);
    yield put(getCheckoutLinkError(error));
    yield put(isBillingInfoLoading(false));
  }
}

function* handleStripeRedirectionWorker() {
  try {
    const agencyKey = yield select(getCurrentUserId);
    const currentUser = yield select(getCurrentUser);
    const agencyPlan = yield call(getCurrentAgencyPlanWorker, { payload: { agencyKey } });

    const updatedAgency = { ...currentUser, ...agencyPlan, isAgencyUpdated: true };
    yield put(setUpdatedAgency(updatedAgency));
  } catch (error) {
    console.log({ error });
  }
}

function* getStripeSessionInfoWorker({ payload }) {
  try {
    const token = yield select(getToken);

    const {
      data: {
        subscription_exists,
        customer_address_updated,
        subscription
      }
    } = yield call(getRequestWithToken, {
      url: `/api/stripe/session/${payload}/check/`,
      token
    });

    const agencyKey = yield select(getCurrentUserId);
    yield fetchNotificationsWorker({ payload: { key: agencyKey } });

    if (subscription_exists && customer_address_updated && subscription) {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "purchase",
        ecommerce: subscription
      });

      yield call(handleStripeRedirectionWorker);
    }
  } catch (error) {
    console.log("[getStripeSessionInfoError]", error);
  }
}

function* getCurrenciesWorker({ payload }) {
  const { currency, period, year, isApi } = payload;
  try {
    yield put(setCurrenciesLoading(true));
    const token = yield select(getToken);

    const response = yield call(getRequestWithToken, {
      url: `/api/stripe/prices/?currency=${currency}&interval=${period}&interval_count=${year}&is_api=${isApi}`,
      token
    });
    if (response.status === 200) {
      yield put(setCurrencies(response.data));
    }
  } catch (error) {
    console.log("[getCurrenciesError]", error);
  } finally {
    yield put(setCurrenciesLoading(false));
  }
}

export function* billingWatcher() {
  yield takeLatest(getCheckoutLink, getCheckoutLinkWorker);
  yield takeLatest(getStripeSessionInfo, getStripeSessionInfoWorker);
  yield takeLatest(getCurrencies, getCurrenciesWorker);
}

export default billingWatcher;
