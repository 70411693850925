import { useSelector, TypedUseSelectorHook } from "react-redux";

interface RootState {
  _persist?: {
    rehydrated: boolean;
  };
}

const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

// eslint-disable-next-line no-underscore-dangle
const useIsHydrated = (): boolean => useTypedSelector(state => state._persist?.rehydrated ?? false);

export default useIsHydrated;
