import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setCurrentDateRange } from "store/modules/companies/actions";
import { fetchCandidatesStats } from "store/modules/сandidates/actions";
import {
  fetchJobs,
  fetchJobsStats,
  restoreJob,
  deleteJob,
  createJob
} from "store/modules/jobs/actions";
import {
  getIsLoading,
  getCompanyJobs,
  getCurrentDateRange,
  getCompanyCandidatesStats,
  getCompainesTableData
} from "store/modules/companies/selectors";
import { getCurrentUser } from "store/modules/users/selectors";
import { getJobsData } from "store/modules/jobs/selectors";
import Overview from "views/Companies/Overview";

const mapStateToProps = state => {
  const { is_owner: isAccountOwner } = getCurrentUser(state);

  return {
    isAccountOwner,
    isLoading: getIsLoading(state),
    dataCompanies: getCompainesTableData(state),
    companyCandidates: getCompanyCandidatesStats(state),
    companyJobs: getCompanyJobs(state),
    currentDateRange: getCurrentDateRange(state),
    currentUser: getCurrentUser(state),
    dataJobs: getJobsData(state)
  };
};

const mapDispatchToProps = {
  fetchJobs,
  fetchJobsStats,
  fetchCandidatesStats,
  setCurrentDateRange,
  restoreJob,
  deleteJob,
  createJob
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Overview)
);
