/* eslint-disable no-nested-ternary */
import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { Waypoint } from "react-waypoint";

import Input from "components/Common/Input";
import Spinner from "components/Common/Spinner";
import { Close } from "mixins/svgIcons";
import withLoaderScreen from "hoc/withLoaderScreen";

import { nanoid } from "nanoid";
import AddPeople from "./AddPeople";
import UserItem from "./UserItem";
import "./styles.scss";

const UsersModal = ({
  t,
  show,
  setShow,
  jobCurrent,
  jobUsers,
  companyName,
  fetchJobUsersList,
  fetchNextJobUsersList,
  updateJobUsersList,
  isUsersLoading,
  isNextUsersLoading,
  hasAbilityEdit,
  currentUserKey,
  inviteUsers,
  inviteError,
  addNewUsersError
}) => {
  const [query, setQuery] = useState("");
  const searchQuery = query === "" ? null : query;

  const jobUsersList = jobUsers.filter(
    (value, index, self) => index === self.findIndex(a => a.key === value.key)
  );
  const usersCanSee = jobUsersList.filter(
    ({ can_see_interview: canSee }) => canSee
  );
  const usersCannotSee = jobUsersList.filter(
    ({ can_see_interview: canSee }) => !canSee
  );

  const fetchDataDebounce = useCallback(
    debounce(newQuery => fetchJobUsersList(newQuery), 300),
    []
  );
  const fetchNextData = () =>
    fetchNextJobUsersList({ jobId: jobCurrent.key, query: searchQuery });
  const refresh = () =>
    fetchDataDebounce({ jobId: jobCurrent.key, query: searchQuery });


  const handleChangeQuery = event => {
    setQuery(event.target.value);
    fetchDataDebounce({
      jobId: jobCurrent.key, query: event.target.value
    });
  };


  const handleClose = () => {
    setShow(false);
    setQuery("");
    fetchDataDebounce({ jobId: jobCurrent.key, query: null });
  };
  const handleDelete = user => {
    const newReviewers = jobCurrent.reviewers
      .filter(reviewer => reviewer.email !== user.email)
      .map(reviewer => reviewer.key);

    const newReviewersToNotify = jobCurrent.reviewers_to_notify
      .filter(reviewer => reviewer.email !== user.email)
      .map(reviewer => reviewer.key);

    updateJobUsersList({
      id: jobCurrent.key,
      reviewers: newReviewers,
      reviewers_to_notify: newReviewersToNotify,
      isUpdateJobUsersList: true,
      reviewer: {
        ...user,
        is_receive_notifications: false,
        can_see_interview: false,
        isRemove: true
      },
      agency: currentUserKey
    });

    refresh();
  };

  const handleNotifications = user => {
    const newReviewers = jobCurrent.reviewers.map(reviewer => reviewer.key);

    const removeFromNotifications = jobCurrent.reviewers_to_notify
      .filter(reviewer => reviewer.email !== user.email)
      .map(reviewer => reviewer.key);

    const addToNotifications = [
      ...jobCurrent.reviewers_to_notify.map(reviewer => reviewer.key),
      user.key
    ];

    updateJobUsersList({
      id: jobCurrent.key,
      reviewers_to_notify: user.is_receive_notifications
        ? removeFromNotifications
        : addToNotifications,
      reviewers: newReviewers,
      isUpdateJobUsersList: true,
      reviewer: {
        ...user,
        is_receive_notifications: !user.is_receive_notifications
      },
      agency: currentUserKey
    });
  };

  const handleAdd = user => {
    const newReviewersToNotify = jobCurrent.reviewers_to_notify.map(
      reviewer => reviewer.key
    );

    const newReviewers = [
      ...jobCurrent.reviewers.map(reviewer => reviewer.key),
      user.key
    ];

    updateJobUsersList({
      id: jobCurrent.key,
      reviewers: newReviewers,
      reviewers_to_notify: newReviewersToNotify,
      isUpdateJobUsersList: true,
      reviewer: {
        ...user,
        can_see_interview: true,
        isAdd: true
      },
      agency: currentUserKey
    });

    fetchDataDebounce({ jobId: jobCurrent.key, query: searchQuery });
  };

  return (
    <Modal dialogClassName="users" show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="users__block">
          <button type="button" className="users__close" onClick={handleClose}>
            <Close fill="#9095A3" />
          </button>

          <h1
            className="n-font-giant n-font-regular n-text-center title_modal"
          >
            {jobCurrent.title}
          </h1>
          <Input
            name="query"
            formElement={{
              value: query,
              icon: "Search",
              elementConfig: {
                placeholder: "findMembers",
                type: "text"
              },
              inputClass: "users-modal"
            }}
            inputChangedHandler={handleChangeQuery}
          />
          {isUsersLoading ? (
            <div className="loading-next">
              <Spinner size={10} />
            </div>
          ) : query ? (
            <div className="users__user-list">
              {jobUsers.length ? (
                <>
                  {usersCanSee.length ? (
                    <>
                      <p className="users__user-list-title n-font-extra-small n-font-regular n-black-80">
                        {t("modals.userManagement.canSee")}
                      </p>
                      {usersCanSee.map(user => (
                        <UserItem
                          user={user}
                          handleDelete={handleDelete}
                          handleNotifications={handleNotifications}
                          hasAbilityEdit={hasAbilityEdit}
                          isOwner={jobCurrent.created_by_email === user.email}
                          key={`user-${nanoid()}`}
                        />
                      ))}
                      {hasAbilityEdit && !usersCannotSee.length ? (
                        <AddPeople
                          companyName={companyName}
                          addNewUsers={inviteUsers}
                          inviteError={inviteError}
                          addNewUsersError={addNewUsersError}
                          jobCurrent={jobCurrent}
                          currentUserKey={currentUserKey}
                          updateJobUsersList={updateJobUsersList}
                          refresh={refresh}
                        />
                      ) : null}
                    </>
                  ) : null}
                  {usersCannotSee.length ? (
                    <div className="users__user-list-cannotsee">
                      <p className="users__user-list-title n-font-extra-small n-font-regular n-black-80">
                        {t("modals.userManagement.cannotSee")}
                      </p>
                      {usersCannotSee.map((user, index) => (
                        <UserItem
                          user={user}
                          handleAdd={handleAdd}
                          isCanSee={false}
                          hasAbilityEdit={hasAbilityEdit}
                          isOwner={jobCurrent.created_by_email === user.email}
                          key={`cannotSee-${String(index)}`}
                        />
                      ))}
                      {hasAbilityEdit ? (
                        <AddPeople
                          companyName={companyName}
                          addNewUsers={inviteUsers}
                          inviteError={inviteError}
                          addNewUsersError={addNewUsersError}
                          jobCurrent={jobCurrent}
                          currentUserKey={currentUserKey}
                          updateJobUsersList={updateJobUsersList}
                          refresh={refresh}
                        />
                      ) : null}
                    </div>
                  ) : null}
                  <div
                    className={`loading-next ${
                      usersCannotSee.length ? "purple-list" : ""
                    }`}
                  >
                    <Waypoint onEnter={fetchNextData} />
                    {isNextUsersLoading && <Spinner size={10} />}
                  </div>
                </>
              ) : (
                <>
                  {query ? (
                    <p className="users__user-list-title n-font-extra-small n-font-regular n-black-80">
                      {t("modals.userManagement.noMatches")}
                      <span className="n-font-bold">{query}</span>
                    </p>
                  ) : null}
                  {hasAbilityEdit ? (
                    <AddPeople
                      companyName={companyName}
                      addNewUsers={inviteUsers}
                      inviteError={inviteError}
                      addNewUsersError={addNewUsersError}
                      jobCurrent={jobCurrent}
                      currentUserKey={currentUserKey}
                      updateJobUsersList={updateJobUsersList}
                      refresh={refresh}
                    />
                  ) : null}
                </>
              )}
            </div>
          ) : (
            <div className="users__user-list">
              <p className="users__user-list-title n-font-extra-small n-font-regular n-black-80">
                {t("modals.userManagement.canSee")}
              </p>
              {hasAbilityEdit ? (
                <AddPeople
                  companyName={companyName}
                  addNewUsers={inviteUsers}
                  inviteError={inviteError}
                  addNewUsersError={addNewUsersError}
                  jobCurrent={jobCurrent}
                  currentUserKey={currentUserKey}
                  updateJobUsersList={updateJobUsersList}
                  refresh={refresh}
                />
              ) : null}
              {usersCanSee.map((user, index) => (
                <UserItem
                  user={user}
                  handleDelete={handleDelete}
                  handleNotifications={handleNotifications}
                  hasAbilityEdit={hasAbilityEdit}
                  isOwner={jobCurrent.created_by_email === user.email}
                  key={`canSee-${String(index)}`}
                />
              ))}
              {usersCannotSee.length ? (
                <div className="users__user-list-cannotsee">
                  <p className="users__user-list-title n-font-extra-small n-font-regular n-black-80">
                    {t("modals.userManagement.cannotSee")}
                  </p>
                  {usersCannotSee.map((user, index) => (
                    <UserItem
                      user={user}
                      handleAdd={handleAdd}
                      isCanSee={false}
                      hasAbilityEdit={hasAbilityEdit}
                      isOwner={jobCurrent.created_by_email === user.email}
                      key={`usersCantSee-${String(index)}`}
                    />
                  ))}
                  {hasAbilityEdit ? (
                    <AddPeople
                      companyName={companyName}
                      addNewUsers={inviteUsers}
                      inviteError={inviteError}
                      addNewUsersError={addNewUsersError}
                      jobCurrent={jobCurrent}
                      currentUserKey={currentUserKey}
                      updateJobUsersList={updateJobUsersList}
                      refresh={refresh}
                    />
                  ) : null}
                </div>
              ) : null}
              <div className="loading-next">
                <Waypoint onEnter={fetchNextData} />
                {isNextUsersLoading && <Spinner size={10} />}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

UsersModal.defaultProps = {
  companyName: undefined,
  isNextUsersLoading: undefined,
  inviteError: undefined,
  isUsersLoading: undefined
};

UsersModal.propTypes = {
  t: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  jobCurrent: PropTypes.shape({
    key: PropTypes.string,
    created_by_email: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    reviewers: PropTypes.arrayOf(PropTypes.shape({})),
    reviewers_to_notify: PropTypes.arrayOf(PropTypes.shape({})),
    title: PropTypes.string
  }).isRequired,
  jobUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  companyName: PropTypes.string,
  fetchJobUsersList: PropTypes.func.isRequired,
  fetchNextJobUsersList: PropTypes.func.isRequired,
  updateJobUsersList: PropTypes.func.isRequired,
  isUsersLoading: PropTypes.bool,
  isNextUsersLoading: PropTypes.bool,
  hasAbilityEdit: PropTypes.bool.isRequired,
  currentUserKey: PropTypes.string.isRequired,
  inviteUsers: PropTypes.func.isRequired,
  inviteError: PropTypes.string,
  addNewUsersError: PropTypes.func.isRequired
};

export default withLoaderScreen(withTranslation()(UsersModal));
