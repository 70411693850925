import React from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";

import animationData from "assets/images/candidate/start.json";

import "./styles.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Completion = ({ t, setCompletedOnboarding, updateAgency }) => {
  const openBeacon = () => {
    if (window.Beacon) {
      window.Beacon("open");
      window.Beacon("navigate", "/ask/message/");
      window.Beacon("prefill", {
        subject: t("onboarding.freeOnboarding"),
        text: t("onboarding.freeTraining")
      });
    }
  };
  const handleClose = () => {
    setCompletedOnboarding(true);
    updateAgency({
      is_setup_completed: true
     });
  };

  return (
    <>
      <Row>
        <Col lg={5} md={12} className="onboard__card-title">
          <div className="onboard__card">
            <h1 className="onboard__title n-grey-100 n-font-semi-bold n-text-center">
              {t("onboarding.youreAll")}
            </h1>
            <p className="onboard__subtitle n-grey-100 n-font-large n-font-medium-weight n-text-center">
              {t("onboarding.whereWill")}
            </p>
            <Lottie options={defaultOptions} height={200} width={200} />
          </div>
        </Col>
        <Col lg={7} md={12} className="onboard__card-title">
          <div className="onboard__card onboard__card-completion">
            <p className="n-grey-100 n-font-medium-weight n-font-large">
              {t("onboarding.createMy")}
            </p>
            <p className="n-grey-100 n-font-small">
              {t("onboarding.jumpStraight")}
            </p>
            <div className="button__group">
              <a
                className="n-button__medium-border n-purple-100 n-border-purple-100"
                rel="noopener noreferrer"
                href="/#"
                data-beacon-article-modal="5ff1d71a40f24b1b2aaf5eb1"
                target="_blank"
              >
                {t("button.watchVideo")}
              </a>
              <Link
                className="n-button__medium n-bg-purple-100 n-white"
                to="/jobs/create"
                onClick={handleClose}
              >
                {t("button.createInterview")}
              </Link>
            </div>
          </div>
          <div className="onboard__card onboard__card-completion">
            <p className="n-grey-100 n-font-medium-weight n-font-large">
              {t("onboarding.schedule")}
            </p>
            <p className="n-grey-100 n-font-small">
              {t("onboarding.toHelpYou")}
            </p>
            <div className="button__group">
              <button
                type="button"
                onClick={openBeacon}
                className="n-button__medium n-bg-purple-100 n-white"
              >
                {t("button.getFreeOnboarding")}
              </button>
            </div>
          </div>
        </Col>
      </Row>
      <Link
        className="button__without-styles n-grey-100 n-font-large n-font-medium-weight onboard__skip"
        to="/dashboard"
        onClick={handleClose}
      >
        {t("button.close")}
      </Link>
    </>
  );
};

Completion.propTypes = {
  t: PropTypes.func.isRequired,
  setCompletedOnboarding: PropTypes.func.isRequired,
  updateAgency: PropTypes.func.isRequired
};

export default withTranslation()(Completion);
