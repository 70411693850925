import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchIndustries } from "store/modules/jobs/actions";
import { fetchCurrentCompany, editCompany, clearCompanyError } from "store/modules/companies/actions";
import { getIsTrialOrPaid } from "store/modules/notifications/selectors";
import { getIsLoading, getCurrentCompanyData, getIndustriesOptionsForSelect, getCompanyError } from "store/modules/companies/selectors";
import { getCardInfoLoading } from "store/modules/profile/selectors";
import Edit from "views/Companies/Edit";

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  isCardInfoLoading: getCardInfoLoading(state),
  isAbiltyСreate: getIsTrialOrPaid(state),
  currentCompany: getCurrentCompanyData(state),
  industriesOptions: getIndustriesOptionsForSelect(state),
  error: getCompanyError(state),
  isLoadingScreen: state.widgets.isLoading
});

const mapDispatchToProps = {
  fetchIndustries,
  fetchCurrentCompany,
  editCompany,
  clearCompanyError
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Edit));
