import React from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { Add } from "mixins/svgIcons";

const AddMoreBtn = ({ t, disabled, btnText, clicked, link }) => (
  <>
    {link ? (
      <div className="add-more">
        <Link to={link} className="main-link main-link__without-decoration">
          <button type="button" className="add-more__btn">
            <Add svgIconClass="add-more__icon" />
            <span className="add-more__text">
              {btnText ? t(`button.${[btnText]}`) : t("button.addCompany")}
            </span>
          </button>
        </Link>
      </div>
    ) : (
      <div className="add-more">
        <button
          type="button"
          className="add-more__btn"
          disabled={disabled}
          onClick={clicked}
        >
          <Add svgIconClass="add-more__icon" />
          <span className="add-more__text">
            {btnText ? t(`button.${[btnText]}`) : t("button.addCompany")}
          </span>
        </button>
      </div>
    )}
  </>
);

AddMoreBtn.defaultProps = {
  disabled: true,
  btnText: undefined,
  link: undefined,
  clicked: undefined
};

AddMoreBtn.propTypes = {
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  btnText: PropTypes.string,
  link: PropTypes.string,
  clicked: PropTypes.func
};

export default withTranslation()(AddMoreBtn);
