import { connect } from "react-redux";

import {
  mfaSignin,
  clearErrors
} from "store/modules/auth/actions";

import { getIsLoading } from "store/modules/auth/selectors";

import MultiFA from "views/Auth/MultiFA";

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  error: state.auth.data.error
});

const mapDispatchToProps = dispatch => ({
  mfaSignin: payload => dispatch(mfaSignin(payload)),
  clearErrors: () => dispatch(clearErrors())
});

export default connect(mapStateToProps, mapDispatchToProps)(MultiFA);
