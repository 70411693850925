import { createAction } from "redux-actions";

// eslint-disable-next-line import/prefer-default-export
export const fetchScorecards = createAction("FETCH_SCORECARDS");
export const fetchScorecardsResponse = createAction(
  "FETCH_SCORECARDS_RESPONSE"
);
export const fetchLatestScorecards = createAction("FETCH_LATEST_SCORECARDS");
export const fetchLatestScorecardsResponse = createAction(
  "FETCH_LATEST_SCORECARDS_RESPONSE"
);
export const fetchScorecardsError = createAction("FETCH_SCORECARDS_ERROR");

export const setScorecardsIsLoading = createAction("SET_SCORECARDS_LOADING");
export const setLatestScorecardsLibraryLoading = createAction(
  "SET_LATEST_SCORECARDS_LOADING"
);

export const setDeleteScorecardsLoading = createAction(
  "SET_DELETE_SCORECARDS_LOADING"
);

export const deleteScorecard = createAction("DELETE_SCORECARD");
export const duplicateScorecard = createAction("DUPLICATE_SCORECARD");

export const fetchCriteriaTitleSuggestions = createAction(
  "FETCH_CRITERIA_TITLE_SUGGESTIONS"
);
export const setCriteriaTitleSuggestions = createAction(
  "SET_SCORECARDS_SUGGESTIONS"
);

export const fetchCriteriaDescriptionSuggestions = createAction(
  "FETCH_CRITERIA_DESCRIPTION_SUGGESTIONS"
);
export const setCriteriaDescriptionSuggestions = createAction(
  "SET_CRITERIA_DESCRIPTION_SUGGESTIONS"
);

export const saveScoreCard = createAction("SAVE_SCORECARD");
export const saveScoreCardLoading = createAction("SAVE_SCORECARD_LOADING");
export const saveScoreCardResponse = createAction(
  "SAVE_SCORECARD_LOADING_RESPONSE"
);
export const saveScoreCardError = createAction("SAVE_SCORECARD_LOADING_ERROR");
export const resetScorecardError = createAction("RESET_SCORECARD_ERROR");

export const fetchScoreCardDetails = createAction("FETCH_SCORECARD_DETAILS");
export const fetchScoreCardDetailsLoading = createAction(
  "FETCH_SCORECARD_DETAILS_LOADING"
);
export const fetchScoreCardDetailsResponse = createAction(
  "FETCH_SCORECARD_DETAILS_LOADING_RESPONSE"
);
export const fetchScoreCardDetailsError = createAction(
  "FETCH_SCORECARD_DETAILS_LOADING_ERROR"
);

export const fetchScorecardsOptions = createAction("FETCH_SCORECARDS_OPTIONS");
export const fetchScorecardsOptionsLoading = createAction(
  "FETCH_SCORECARDS_OPTIONS_LOADING"
);
export const fetchScorecardsOptionsResponse = createAction(
  "FETCH_SCORECARDS_OPTIONS_LOADING_RESPONSE"
);
export const fetchScorecardsOptionsError = createAction(
  "FETCH_SCORECARDS_OPTIONS_LOADING_ERROR"
);

export const fetchCandidateScoresResponse = createAction(
  "FETCH_CANDIDATE_SCORES_RESPONSE"
);
export const fetchCandidateScoresLoading = createAction(
  "FETCH_CANDIDATE_SCORES_LOADING"
);

export const fetchCandidateScores = createAction("FETCH_CANDIDATE_SCORECARDS");
export const fetchInterviewCardScorecard = createAction(
  "FETCH_INTERVIEW_CARD_SCORECARDS"
);
export const fetchInterviewCardScorecardResponse = createAction(
  "FETCH_INTERVIEW_CARD_SCORECARDS_RESPONSE"
);
export const fetchInterviewScorecardScores = createAction(
  "FETCH_INTERVIEW_SCORECARDS_SCORE"
);
export const fetchInterviewScorecardScoresResponse = createAction(
  "FETCH_INTERVIEW_SCORECARDS_SCORE_RESPONSE"
);

export const updateInterviewScore = createAction("UPDATE_INTERVIEW_SCORE");
export const fetchCandidateScoreByCardId = createAction(
  "FETCH_CANDIDATE_SCORE_BY_CARD_ID"
);
export const fetchCandidateScoreByCardIdResponse = createAction(
  "FETCH_CANDIDATE_SCORE_BY_CARD_ID_RESPONSE"
);

export const setCandidateScoreByCardIdLoading = createAction(
  "SET_CANDIDATE_SCORE_BY_CARD_ID_LOADING"
);

export const updateCandidateScoreCardResponse = createAction(
  "UPDATE_CANDIDATE_SCORECARD_RESPONSE"
);

export const setIsUpdatingScoreLoading = createAction(
  "SET_UPDATING_SCORE_LOADING"
);

export const duplicateScorecardSuccess = createAction(
  "DUPLICATE_SCORECARD_SUCCESS"
);
export const duplicateScorecardError = createAction(
  "DUPLICATE_SCORECARD_ERROR"
);

export const shouldShowScorecard = createAction("SHOULD_SHOW_SCORECARD");
