import { withTranslation } from "react-i18next";
import { getCurrentIdvBundles, getCurrentIdvPaygPrice, getIdvCheckoutStatus, getIsPricesDataLoaded } from "store/modules/idv/selectors";
import { getClearPaymentStatus, getIdvCheckoutLink, fetchIdvPrices } from "store/modules/idv/actions";
import { connect } from "react-redux";
import { getCurrentUserId } from "store/modules/users/selectors";
import IdvConnectModal from "./IdvConnectModal";

const mapStateToProps = state => ({
  idvBundles: getCurrentIdvBundles(state),
  idvCheckoutStatus: getIdvCheckoutStatus(state),
  paygPrice: getCurrentIdvPaygPrice(state),
  currentUserId: getCurrentUserId(state),
  isPriceDataLoaded: getIsPricesDataLoaded(state)
});

const mapDispatchToProps = {
  getClearPaymentStatus,
  getIdvCheckoutLink,
  fetchIdvPrices
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(IdvConnectModal));
