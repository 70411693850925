import React from "react";

export const Replay = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.571056 3.64087e-07L2.8272 3.64087e-07C2.90453 -8.69827e-05 2.98107 0.0155429 3.05218 0.0459421C3.12329 0.0763414 3.1875 0.120877 3.24089 0.176847C3.29429 0.232818 3.33577 0.299058 3.36282 0.371551C3.38987 0.444043 3.40192 0.521278 3.39825 0.598571L3.2079 4.53952C4.31463 3.23452 5.69221 2.18647 7.24482 1.46829C8.79742 0.75012 10.4877 0.379068 12.1982 0.380953C18.7016 0.380953 24.0048 5.6919 24 12.1995C23.9952 18.7176 18.713 24 12.1982 24C9.27581 24.0041 6.45659 22.9192 4.29005 20.9567C4.23217 20.9048 4.18547 20.8417 4.1528 20.7712C4.12013 20.7007 4.10217 20.6243 4.10003 20.5466C4.09789 20.4689 4.11162 20.3916 4.14036 20.3194C4.16911 20.2472 4.21227 20.1816 4.26721 20.1267L5.88519 18.5076C5.98759 18.4052 6.12506 18.3455 6.26977 18.3406C6.41449 18.3358 6.55565 18.3861 6.66468 18.4814C7.94315 19.607 9.5341 20.3158 11.2254 20.5135C12.9168 20.7112 14.6282 20.3883 16.1314 19.5878C17.6347 18.7874 18.8584 17.5475 19.6395 16.0333C20.4206 14.5192 20.7219 12.8028 20.5032 11.113C20.2846 9.42312 19.5563 7.84011 18.4157 6.57496C17.275 5.30982 15.776 4.42265 14.1186 4.03173C12.4612 3.6408 10.724 3.7647 9.13877 4.3869C7.55352 5.0091 6.19545 6.10004 5.24561 7.51428L10.0772 7.28238C10.1545 7.2787 10.2316 7.29076 10.3041 7.31783C10.3765 7.3449 10.4427 7.38641 10.4987 7.43984C10.5546 7.49327 10.5991 7.55751 10.6295 7.62867C10.6599 7.69983 10.6755 7.77643 10.6754 7.85381V10.1114C10.6754 10.263 10.6152 10.4083 10.5081 10.5155C10.401 10.6226 10.2558 10.6829 10.1043 10.6829L0.571056 10.6829C0.419601 10.6829 0.274351 10.6226 0.167259 10.5155C0.0601654 10.4083 0 10.263 0 10.1114V0.571429C0 0.419876 0.0601654 0.274531 0.167259 0.167368C0.274351 0.0602042 0.419601 3.64087e-07 0.571056 3.64087e-07Z" fill="#FFFFFF" />
  </svg>
);

export const Play = () => (
  <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0 17.7532V0.248047L15.1595 9.00064L0 17.7532Z" fill="#1E384B" />
  </svg>
);
