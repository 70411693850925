import React from "react";
import PropTypes from "prop-types";

const Message = ({ message, error = false }) => (
  <div className={`message message_${error ? "error" : "success"}`}>
    {message}
  </div>
);

Message.defaultProps = {
  message: undefined,
  error: undefined
};

Message.propTypes = {
  message: PropTypes.string,
  error: PropTypes.bool
};

export default Message;
