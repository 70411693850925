import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";

import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";
import Spinner from "components/Common/Spinner";

import PageWrapper from "hoc/AuthWrapper";

const MultiFA = ({
  t,
  isLoading,
  error,
  mfaSignin,
  clearErrors
}) => {
  const [code, setCode] = useState("");

  useEffect(() => clearErrors, []);

  const onSubmit = event => {
    event.preventDefault();
    mfaSignin(code.replace(" ", ""));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <PageWrapper header={<Header />} footer={<Footer isPublic />}>
      <div className="form">
        <h2 className="form__title">
          {t("mfa.header")}
        </h2>

        <p className="n-font-small security__description">
          {t("mfa.text")}
        </p>
        <form onSubmit={onSubmit}>
          <InputMask
            mask="999 999"
            placeholder="000 000"
            maskChar={null}
            value={code}
            onChange={e => setCode(e.target.value)}
          >
            {inputProps => (
              <input
                className={`n-font-small security__input security__input-signin ${error ? "error" : ""}`}
                {...inputProps}
              />
            )}
          </InputMask>
          <div className="message security__error">
            {error ? t("security.errorCode") : ""}
          </div>
          <button
            type="submit"
            className="n-button__medium n-bg-purple-100 n-white n-button__full-width"
            disabled={code.length !== 7}
          >
            {t("button.continue")}
          </button>
        </form>

        <div className="security__link">
          <Link to="/multifa-disable">{t("link.signInDifferent")}</Link>
        </div>
      </div>
    </PageWrapper>
  );
};

MultiFA.defaultProps = {
  clearErrors: PropTypes.func,
  isLoading: false,
  error: undefined
};

MultiFA.propTypes = {
  t: PropTypes.func.isRequired,
  mfaSignin: PropTypes.func.isRequired,
  clearErrors: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string
};

export default withTranslation()(MultiFA);
