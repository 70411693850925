import React, { useRef, useCallback } from "react";
import Autosuggest from "react-autosuggest";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import { store } from "store";
import { setSuggestions } from "store/modules/jobs/actions";
import { BeatLoader } from "react-spinners";
import { IDV_LABEL } from "configs/jobs/constants";

const getSuggestionValue = suggestion => suggestion.text;
const renderSuggestion = suggestion => {
  if (suggestion?.disabled) {
    return (
      // @TODO use classes instead of inline styles
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <BeatLoader color="#5a2af1" size="10" loading />
      </div>
    );
  }

  return (
    <span>{suggestion.text}</span>
  );
};

const QuestionAutosuggest = ({
  valueInput,
  suggestions,
  renderInputComponent,
  fetchSuggestions,
  onSuggestionSelected,
  onInputChanged
}) => {
  const ref = useRef(null);
  const wrapper = useRef(null);

  const debouncedFetchSuggestions = useCallback(
    debounce(query => fetchSuggestions(query), 500),
    []
  );

  const onSuggestionsClearRequested = () => fetchSuggestions("");
  const onSuggestionsFetchRequested = ({ value }) => {
    if (value !== IDV_LABEL) {
      store.dispatch(setSuggestions([{ text: null, disabled: true }]));
      debouncedFetchSuggestions(value);
    }
  };
  const handleOnInputChange = evt => (typeof evt?.target === "string" && typeof onInputChanged === "function" ? onInputChanged(evt?.target?.value) : () => "");

  const handleSuggestionSelected =
    (e, value) => {
      e.preventDefault();
      e.stopPropagation();

      if (!value.suggestion?.disabled) onSuggestionSelected(value.suggestionValue);
    };

  const inputProps = {
    value: valueInput,
    onChange: handleOnInputChange
  };

  return (
    <div ref={wrapper}>
      <Autosuggest
        ref={ref}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        onSuggestionSelected={handleSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderInputComponent={renderInputComponent}
        inputProps={inputProps}
        focusInputOnSuggestionClick={false}
      />
    </div>
  );
};

QuestionAutosuggest.defaultProps = {
  onInputChanged: undefined
};

QuestionAutosuggest.propTypes = {
  valueInput: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  renderInputComponent: PropTypes.func.isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  onSuggestionSelected: PropTypes.func.isRequired,
  onInputChanged: PropTypes.func
};

export default QuestionAutosuggest;
