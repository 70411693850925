import pick from "lodash/pick";

export const getPeopleData = state => state.people.tablesData?.results || [];
export const getPeopleDataPagination = state =>
  pick(state.people.tablesData, ["count", "next", "previous"]) || {};
export const getPeopleFilters = state => state.people.tablesFilters || {};
export const getPeopleFiltersPaginationLoading = state => state.people.filtersPaginationData || {};

export const getPeopleLoading = state => state.people.isLoading;
export const getPeopleLoadingLatest = state => state.people.isLatestLoading;
export const getPeopleFiltersLoading = state => state.people.isFiltersLoading;

export const getPeopleFilterCompaniesLoading = state => state.people.isFilterCompaniesLoading;
export const getPeopleFilterJobsLoading = state => state.people.isFilterJobsLoading;
export const getPeopleFilterOwnersLoading = state => state.people.isFilterOwnersLoading;
export const getPeopleFilterStatusesLoading = state => state.people.isFilterStatusesLoading;
export const getPeopleFilterRatingsLoading = state => state.people.isFilterRatingsLoading;
export const getSearchPeopleFilterLoading = state => state.people.isSearchFilterLoading;
export const getPeoplePageSelectedFilter = state => state.people.selectedFilter;
