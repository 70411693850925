import { handleActions, combineActions } from "redux-actions";

import {
  setJobSetupValid,
  setJobQuestionsValid,
  clearStatuses,
  createJob
} from "./actions";

const defaultState = {
  isJobSetupValid: false,
  isJobQuestionsValid: false
};

export default handleActions(
  {
    [setJobQuestionsValid]: (state, { payload }) => ({
      ...state,
      isJobQuestionsValid: payload
    }),
    [setJobSetupValid]: (state, { payload }) => ({
      ...state,
      isJobSetupValid: payload
    }),
    [combineActions(clearStatuses, createJob)]: () => ({
      ...defaultState
    })
  },
  defaultState
);
