import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import PageWrapper from "hoc/PageWrapper";

import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";

import "./styles.scss";

const NotFound = ({
  t,
  match: {
    params: { jobCreatorEmail = "" }
  }
}) => (
  <PageWrapper header={<Header />} footer={<Footer isPublic />}>
    <div className="common__not-found">
      <p>
        {t("notFound.expired")}
        <br />
        {t("notFound.contact")}
        <a
          className="common__not-found-email"
          href={`mailto:${jobCreatorEmail}`}
        >
          {jobCreatorEmail}
        </a>
        {t("notFound.request")}
      </p>
    </div>
  </PageWrapper>
);

NotFound.defaultProps = {
  match: undefined
};

NotFound.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      jobCreatorEmail: PropTypes.string
    })
  })
};

export default withTranslation()(NotFound);
