import React from "react";

export const Up = () => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.37821 0.76523L0.257566 8.09864C-0.0858555 8.45233 -0.0858555 9.02574 0.257566 9.37938L1.08808 10.2347C1.43091 10.5878 1.98655 10.5885 2.33019 10.2362L8.00002 4.42437L13.6698 10.2363C14.0134 10.5885 14.5691 10.5878 14.9119 10.2347L15.7424 9.37942C16.0859 9.02574 16.0859 8.45232 15.7424 8.09868L8.62179 0.765268C8.27841 0.411584 7.72163 0.411584 7.37821 0.76523Z" fill="#656F8D" />
  </svg>
);

export const Down = () => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.62179 10.2348L15.7424 2.90136C16.0859 2.54767 16.0859 1.97426 15.7424 1.62062L14.9119 0.765292C14.5691 0.412212 14.0134 0.411532 13.6698 0.763783L7.99998 6.57563L2.33019 0.763745C1.98655 0.411495 1.43091 0.412174 1.08808 0.765254L0.257567 1.62058C-0.0858556 1.97426 -0.0858556 2.54767 0.257567 2.90132L7.37821 10.2347C7.72159 10.5884 8.27837 10.5884 8.62179 10.2348Z" fill="#656F8D" />
  </svg>
);
