import { EditorState, Modifier } from "draft-js";

const newEditorState = EditorState.createEmpty();
const newContentState = Modifier.setBlockData(
  newEditorState.getCurrentContent(),
  newEditorState.getSelection(),
  { "text-align": "center" }
);

export const Types = [{
  label: "Email",
  value: "email"
}, {
  label: "SMS",
  value: "sms"
}];
export const Triggers = [{
  label: "Invite",
  value: "invite"
}, {
  label: "Reminder",
  value: "reminder"
}, {
  label: "Completion Email",
  value: "success_email"
}];
export const TriggersSms = [{
  label: "Invite",
  value: "invite"
}, {
  label: "Reminder",
  value: "reminder"
}];
export const Variables = [
  {
    label: "Candidate first name",
    value: "{candidate_first_name}"
  },
  {
    label: "Candidate last name",
    value: "{candidate_last_name}"
  },
  {
    label: "Candidate full name",
    value: "{candidate_full_name}"
  },
  {
    label: "Deadline date",
    value: "{deadline_date}"
  },
  {
    label: "Interview name",
    value: "{interview_name}"
  },
  {
    label: "Interview owner name",
    value: "{interview_owner_name}"
  },
  {
    label: "Company name",
    value: "{company_name}"
  },
  {
    label: "Organisation name",
    value: "{organisation_name}"
  }
];

export const InviteLink = {
  label: "Invite link",
  value: "{link}"
};

export default {
  key: {
    value: "",
    valid: true,
    touched: false
  },
  content_text: {
    value: "",
    valid: true,
    touched: false
  },
  updated_at: {
    value: "",
    valid: true,
    touched: false
  },
  is_in_use: {
    value: "",
    valid: true,
    touched: false
  },
  title: {
    label: "templateTitle",
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "templateTitlePlaceholder"
    },
    value: "",
    validation: {
      required: true
    },
    prefix: "overview",
    errorMessage: "templateTitleError",
    valid: false,
    touched: false
  },
  type: {
    label: "templateType",
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "templateTypePlaceholder"
    },
    value: Types[0],
    options: Types,
    validation: {
      required: true
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  trigger: {
    label: "templateTrigger",
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "templateTriggerPlaceholder"
    },
    value: Triggers[0],
    options: Triggers,
    validation: {
      required: true
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  content: {
    label: "templateContent",
    elementType: "texteditor",
    value: EditorState.push(newEditorState, newContentState, "change-block-data"),
    validation: {
      required: true
    },
    prefix: "overview",
    valid: false,
    touched: false
  },
  variables: {
    elementConfig: {
      type: "text",
      placeholder: "insertVariable",
      isSearchable: false
    },
    options: Variables,
    valid: true
  },
  system_default: {
    value: false,
    valid: true,
    touched: false
  }
};
