import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchInviteCompany, addNewInvite, clearUserError } from "store/modules/users/actions";
import { getInviteCompany, getUsersError, getInviteLoading, getInviteUser } from "store/modules/users/selectors";
import { getToken } from "store/modules/auth/selectors";
import Maintenance from "views/Common/GenericInvite";

const mapStateToProps = state => ({
  company: getInviteCompany(state),
  error: getUsersError(state),
  loading: getInviteLoading(state),
  user: getInviteUser(state),
  isAuthenticated: Boolean(getToken(state))
});

const mapDispatchToProps = {
  fetchInviteCompany,
  addNewInvite,
  clearUserError
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Maintenance));
