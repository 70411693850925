import React from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import Footer from "./Footer";

const CardCompany = ({ children }) => (
  <div className="company-card">{children}</div>
);

CardCompany.Header = Header;
CardCompany.Footer = Footer;

CardCompany.defaultProps = {
  children: undefined
};

CardCompany.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element)
};

export default CardCompany;
