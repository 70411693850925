import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import PropTypes from "prop-types";

const ProgressBarGraph = ({ currentValue, totalValue, passLimit = false }) => {
  const notPassLimit = !passLimit;
  const progressAmount = Math.round((currentValue / totalValue) * 100);
  const color = notPassLimit && (currentValue / totalValue >= 0.9) ? "danger" : "";

  return (
    <div className="progress__wrapper">
      <ProgressBar now={progressAmount} variant={color} />
    </div>
  );
};

ProgressBarGraph.defaultProps = {
  currentValue: undefined,
  totalValue: undefined,
  passLimit: undefined
};

ProgressBarGraph.propTypes = {
  currentValue: PropTypes.number,
  totalValue: PropTypes.number,
  passLimit: PropTypes.bool
};

export default ProgressBarGraph;
