/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import Switch from "rc-switch";

import ColorPickerEnabled from "components/ColorPicker/Enabled";
import ColorPickerDisabled from "components/ColorPicker/Disabled";
import LogoUploadEnabled from "components/LogoUpload/Enabled";
import Label from "components/Company/Label";
import DropdownIndicator from "components/Forms/Select/dropdown";
import { handleClassName } from "mixins/helpers";
import { setCompanyInfo, companyFormSchema } from "mixins/helperCompany";
import TooltipInfo from "components/Common/Tooltip/Info";

import "../styles.scss";
import { connect } from "react-redux";

const CompanyForm = ({
  t,
  history,
  isAbiltyСreate,
  isCardInfoLoading,
  currentCompany,
  error,
  industriesOptions,
  handleSaveCompany,
  handleShowModals,
  allowCustomBranding
}) => {
  const [controls, setControls] = useState(setCompanyInfo());
  useEffect(() => {
    if (currentCompany) {
      setControls(setCompanyInfo(currentCompany));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Col md={{ span: 6, offset: 3 }}>
      <Formik
        enableReinitialize
        initialValues={controls}
        validationSchema={companyFormSchema()}
        onSubmit={handleSaveCompany}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          setFieldValue,
          setFieldTouched,
          handleSubmit
        }) => (
          <form
            className="form-companies"
            id="create-company-form"
            onSubmit={handleSubmit}
          >
            <div className="input without-icon">
              <div className="input-wrapper">
                <label htmlFor="company-name" className="label">
                  {t("input.companyNameTitle")}
                </label>

                <input
                  id="company-name"
                  type="text"
                  name="name"
                  placeholder={t("input.companyNamePlaceholder")}
                  className={handleClassName(errors.name, touched.name)}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                />

                {touched.name && (errors.name || error?.name) && (
                  <p className="input-error-message company">
                    {errors.name ? t(errors.name) : error?.name}
                  </p>
                )}
              </div>
            </div>

            <div className="input without-icon">
              <div className="input-wrapper">
                <label htmlFor="company-location" className="label">
                  {t("input.companyLocationTitle")}
                </label>
                <input
                  id="company-location"
                  type="text"
                  name="location"
                  placeholder={t("input.companyLocationPlaceholder")}
                  className={handleClassName(
                    errors.location,
                    touched.location
                  )}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.location}
                />

                {touched.location && (errors.location || error?.location) && (
                  <p className="input-error-message company">
                    {errors.location ? t(errors.location) : error?.location}
                  </p>
                )}
              </div>
            </div>

            <div className="input without-icon">
              <div className="input-wrapper">
                <label htmlFor="company-website" className="label">
                  {t("input.companyWebsiteTitle")}
                  <span className="label__secondary">
                    {t("input.inputLabelOptional")}
                  </span>
                </label>
                <input
                  id="company-website"
                  type="text"
                  name="site"
                  placeholder={t("input.companyWebsitePlaceholder")}
                  onBlur={handleBlur}
                  className={handleClassName(errors.site, touched.site)}
                  onChange={handleChange}
                  value={values.site}
                />

                {touched.site && (errors.site || error?.site) && (
                  <p className="input-error-message company">
                    {errors.site ? t(errors.site) : error?.site}
                  </p>
                )}
              </div>
            </div>

            <Row justify="center" noGutters>
              <Col md={6}>
                <div className="input offset-right">
                  <div className="input-wrapper">
                    <Label
                      htmlFor="comapny-header-footer-color"
                      title={t("input.companyHeaderColorTitle")}
                      condition={isAbiltyСreate && allowCustomBranding}
                      clicked={handleShowModals}
                      isDisabled={isCardInfoLoading}
                    />

                    {isAbiltyСreate && allowCustomBranding ? (
                      <ColorPickerEnabled
                        fieldName="layoutColor"
                        fieldValue={values.layoutColor}
                        handleClassName={handleClassName}
                        setFieldValue={setFieldValue}
                      />
                    ) : (
                      <ColorPickerDisabled
                        fieldName="layoutColor"
                        handleClassName={handleClassName}
                        clicked={handleShowModals}
                        isDisabled={isCardInfoLoading}
                      />
                    )}
                  </div>
                </div>

                <div className="input offset-right">
                  <div className="input-wrapper">
                    <Label
                      htmlFor="comapny-button-color"
                      title={t("input.companyButtonColorTitle")}
                      condition={isAbiltyСreate && allowCustomBranding}
                      clicked={handleShowModals}
                      isDisabled={isCardInfoLoading}
                    />

                    {isAbiltyСreate && allowCustomBranding ? (
                      <ColorPickerEnabled
                        fieldName="buttonColor"
                        fieldValue={values.buttonColor}
                        handleClassName={handleClassName}
                        setFieldValue={setFieldValue}
                      />
                    ) : (
                      <ColorPickerDisabled
                        fieldName="buttonColor"
                        fieldValue={values.buttonColor}
                        handleClassName={handleClassName}
                        setFieldValue={setFieldValue}
                        clicked={handleShowModals}
                        isDisabled={isCardInfoLoading}
                      />
                    )}
                  </div>
                </div>
              </Col>

              <Col md={6}>
                <div className="input offset-left">
                  <div className="input-wrapper">
                    <Label
                      htmlFor="comapny-logo"
                      title={t("input.companyLogoTitle")}
                      condition
                    />

                    <LogoUploadEnabled
                      fieldName="logo"
                      valid={!(errors.logo && touched.logo)}
                      fieldValue={values.logo}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                    />

                    {errors.logo && touched.logo && (
                      <p className="input-error-message image">
                        {t(errors.logo)}
                      </p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <div className="input without-icon">
              <div className="input-wrapper">
                <label htmlFor="comapny-industry" className="label">
                  {t("select.jobIndustryTitle")}
                </label>

                <Select
                  id="comapny-industry"
                  isSearchable={false}
                  classNamePrefix="select"
                  value={values.industry}
                  options={industriesOptions}
                  placeholder={t("select.jobIndustryPlaceholder")}
                  components={{ DropdownIndicator }}
                  className={handleClassName(
                    !!errors.industry,
                    touched.industry,
                    "select",
                    values.industry.value
                  )}
                  onChange={option => {
                    setFieldValue("industry", option);
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  onBlur={() => setFieldTouched("industry", true)}
                />
              </div>
            </div>
            <Col md={12}>
              <div className="form-companies-switcher">
                <p className="form-companies-switcher-text n-black-100">
                  <TooltipInfo
                    position="top"
                    message={t("company.defaultCompanies")}
                  />
                  <span>{t("company.useAsDefault")}</span>
                </p>

                <Switch
                  name="isDefault"
                  onChange={value => setFieldValue("isDefault", value)}
                  checked={values.isDefault}
                  disabled={currentCompany?.is_default}
                />
              </div>
            </Col>

            <div className="button__group_center">
              <button
                type="button"
                className="n-button__medium-border n-border-purple-100 n-purple-100"
                onClick={() => history.goBack()}
              >
                {t("button.cancel")}
              </button>
              <button
                type="submit"
                className="n-button__medium n-bg-purple-100 n-white"
                disabled={!isEmpty(errors)}
                id="create-company-button"
              >
                {t("button.save")}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </Col>
  );
};

CompanyForm.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func
  }).isRequired,
  isAbiltyСreate: PropTypes.bool.isRequired,
  isCardInfoLoading: PropTypes.bool.isRequired,
  currentCompany: PropTypes.shape.isRequired,
  error: PropTypes.shape.isRequired,
  industriesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleSaveCompany: PropTypes.func.isRequired,
  handleShowModals: PropTypes.func.isRequired,
  allowCustomBranding: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  allowCustomBranding: state?.users?.currentUser?.allow_custom_branding

});

export default connect(mapStateToProps)(withRouter(withTranslation()(CompanyForm)));
