import { connect } from "react-redux";

import { getToken } from "store/modules/auth/selectors";
import { confirmEmail } from "store/modules/auth/actions";
import { getProfileInfo } from "store/modules/profile/selectors";
import PrivateRouteAuth from "components/Common/PrivateRouteAuth";

const mapStateToProps = state => {
  const {
    mfa_available: mfaAvailable,
    mfa_enabled: mfaEnabled
  } = getProfileInfo(state);

  return {
    isAuthenticated: Boolean(getToken(state)),
    mfaAvailable: mfaAvailable && !mfaEnabled
  };
};

const mapDispatchToProps = {
  confirmEmail
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRouteAuth);
