/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
import React, { useEffect } from "react";
import postscribe from "postscribe";
import { withRouter } from "react-router-dom";
import { getCandidateInfo, getCandidateJobInfo } from "store/modules/сandidates/selectors";
import { connect } from "react-redux";

const PrivateChatCandidate = ({
  location: { pathname },
  candidateInfo: { key },
  candidateJobInfo,
  errorMessage
}) => {
  useEffect(() => {
    postscribe("#beacon", `<script type="text/javascript">!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});</script>
      <script type="text/javascript">window.Beacon('init', 'e0b270a1-b24f-4cf6-95bd-5b6dae1f443b')</script>`);

      return () => {
        if (window.Beacon) {
          console.log("Destroying beacon...");
          window.Beacon("once", "ready", () => window.Beacon && window.Beacon("destroy"));
          window.Beacon("destroy");
        }
      };
  }, []);

  useEffect(() => {
    if (!key) return;

    const identify = {
      company: candidateJobInfo?.company?.name,
      "Candidate-Admin-Link": `${process.env.REACT_APP_BACKEND_URL}/admin/jobs/interviewer/?q=${key}`,
      "Agency-Admin-Link": `${process.env.REACT_APP_BACKEND_URL}/admin/agencies/agency/?q=${candidateJobInfo?.agency_key}`,
      "Error-Message": errorMessage,
      "Interview-Name": candidateJobInfo?.title
    };

    if (window.Beacon) {
      window.Beacon("identify", identify);
    }
  }, [candidateJobInfo, errorMessage, key]);

  useEffect(() => {
    if (!window.Beacon) return;

    window.Beacon("event", {
      type: "page-viewed",
      url: document.location.href,
      title: document.title
    });

    window.Beacon("suggest");
  }, [pathname]);

  return <div id="beacon" />;
};


const mapStateToProps = state => ({
  candidateInfo: getCandidateInfo(state),
  candidateJobInfo: getCandidateJobInfo(state)
});

export default withRouter(connect(mapStateToProps)(PrivateChatCandidate));
