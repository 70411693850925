/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CloseButton, Col, Modal, Row, Spinner } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { nanoid } from "nanoid";
import { resolveCurrency, resolveBundleSavings, resolveTotalBundlePrice } from "mixins/helperForPayment";
import { FileError, Info } from "mixins/svgIcons";
import Axios from "axios";

let cancelToken;

const IdvConnectModal = ({
  show,
  hide,
  onDismiss,
  t,
  idvBundles,
  getIdvCheckoutLink,
  idvCheckoutStatus,
  isConnected,
  paygPrice,
  getClearPaymentStatus,
  currentUserId,
  isPriceDataLoaded,
  fetchIdvPrices
}) => {
  const [currentIdvBundle, setCurrentIdvBundle] = useState(null);
  const isJobCreation = window.location.pathname.includes("/jobs/edit/") || window.location.pathname.includes("/jobs/create");

  useEffect(() => {
    let isMounted = true;

    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    // Save the cancel token for the current request
    cancelToken = Axios.CancelToken.source();

    if (currentUserId && !isPriceDataLoaded && isMounted) {
      isMounted = false;
      fetchIdvPrices({ cancelToken });
    }

    return () => {
      isMounted = true;
    };
  }, [currentUserId]);

  const handlePayBundle = bundleKey => {
    setCurrentIdvBundle(bundleKey);
    getIdvCheckoutLink({ bundleKey, isActivated: isConnected });
  };

  const handleOnHide = () => {
    if (isJobCreation) {
      onDismiss();
    } else {
      hide();
    }

    getClearPaymentStatus();
  };

  return (
    <Modal dialogClassName="idv-connect-modal" show={show} onHide={handleOnHide} centered>
      <div>
        <CloseButton onClick={handleOnHide} />
      </div>
      <Modal.Body>
        <div>
          <div className="idv-connect-modal__header">
            <p className="n-font-extra-large idv-connect-modal__header-title">{t("connect.idvConnect.modal.title")}</p>
            <p className="n-grey-100 n-font-large idv-connect-modal__header-subtitle">
              {
                isJobCreation ? t("connect.idvConnect.modal.subtitle2") : t("connect.idvConnect.modal.subtitle")
              }
            </p>
          </div>
          <Row className="idv-connect-modal__plans">
            {
              idvBundles.length > 0
                ? idvBundles?.map(item => (
                  <Col
                    md={3}
                    sm={12}
                    className="idv-connect-modal__plans-col"
                    key={`idv-bundle-${nanoid()}`}
                  >
                    <div className="idv-connect-modal__plans-item">
                      <h3 className="plan-info__name n-font-extra-large n-font-regular n-black-80">
                        {t("connect.idvConnect.modal.plans.plan.title", { credits: item?.meta_idv_credits_amount?.toLocaleString("en-US") ?? 0 })}
                      </h3>
                      <div className="plan-info__price idv-modal-price">
                        <span className="plan-info__price-value idv-connect-modal__plans-col-price">
                          {`${resolveCurrency(item.currency)}${item?.price?.toLocaleString("en-US")}`}
                        </span>
                        <div className="plan-info__price-label n-black-80">
                          <span className="n-font-extra-small">{t("connect.idvConnect.modal.plans.check")}</span>
                        </div>
                      </div>
                      <div>
                        <p className="n-font-small n-purple-100 idv-modal-price-save">
                          {t("connect.idvConnect.modal.plans.plan.saving", { amount: resolveBundleSavings(paygPrice.price, item.price, item.meta_idv_credits_amount, item.currency) })}
                        </p>
                        <p className="n-font-extra-small n-grey-100">

                          {t("connect.idvConnect.modal.plans.plan.total", { amount: resolveTotalBundlePrice(item.price, item.meta_idv_credits_amount, item.currency) })}
                        </p>
                      </div>
                      <div className="wrap-button">
                        <button
                          type="button"
                          className="idv-btn n-button__medium n-bg-purple-100 n-white idv-buy-btn"
                          onClick={() => handlePayBundle(item.key)}
                        >
                          {
                            idvCheckoutStatus?.isLoading && currentIdvBundle === item.key ? (
                              <Spinner
                                as="span"
                                variant="light"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                animation="border"
                                style={{ marginRight: 5 }}
                              />
                            ) : null
                          }
                          {t("connect.idvConnect.button.buyNow")}
                        </button>
                      </div>
                      <div className="error-container">
                        { idvCheckoutStatus?.error && currentIdvBundle === item.key ? (
                          <>
                            <FileError />
                            {t("connect.idvConnect.modal.paymentFailed")}
                          </>
                        ) : null }
                      </div>
                    </div>
                  </Col>
                )) : null
            }
            {
              !isConnected ? (
                <Col md={12} className="idv-connect-modal__subscribe">
                  <div className="plans__pro">
                    <div className="plans__pro-payg">
                      <span className="plans__pro-title">{t("connect.idvConnect.modal.payAsYouGo.title")}</span>
                      <span className="plans__pro-desc n-font-small n-grey">
                        {t("connect.idvConnect.modal.payAsYouGo.price", {
                          amount: `${ resolveCurrency(paygPrice.currency)}${paygPrice.price}`
                        })}
                      </span>
                      <button
                        type="button"
                        className="idv-btn n-button__medium-border n-purple-100"
                        onClick={() => handlePayBundle(null)}
                      >
                        {
                    idvCheckoutStatus?.isLoading && currentIdvBundle === null ? (
                      <Spinner
                        as="span"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        animation="border"
                        style={{ color: "#5A2AEF", marginRight: 5 }}
                      />
                    ) : null
                        }
                        {t("connect.idvConnect.modal.payAsYouGo.subscribeBtn")}
                      </button>
                    </div>
                    <div className="error-container">
                      { idvCheckoutStatus?.error && currentIdvBundle === null ? (
                        <>
                          <FileError />
                          {t("connect.idvConnect.modal.paymentFailed")}
                        </>
                      ) : null }
                    </div>
                  </div>
                </Col>
              ) : null
            }
          </Row>
          <div className="idv-connect-modal__footer-subtitle">
            <div
              className="idv-connect-modal__footer-icon"
              onClick={() => {
                window.open("https://support.willo.video/article/129-digital-identity-check", "_blank");
              }}
            >
              <Info fill="#595959" height="10px" width="10px" />
            </div>
            {t("connect.idvConnect.modal.payAsYouGo.subtitle")}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

IdvConnectModal.defaultProps = {
  idvBundles: [],
  idvCheckoutStatus: {},
  isConnected: false,
  currentUserId: undefined,
  onDismiss: undefined
};

IdvConnectModal.propTypes = {
  show: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  t: PropTypes.func.isRequired,
  idvBundles: PropTypes.arrayOf(PropTypes.shape({
    length: PropTypes.func,
    map: PropTypes.func
  })),
  getIdvCheckoutLink: PropTypes.func.isRequired,
  idvCheckoutStatus: PropTypes.shape({
    isLoading: PropTypes.bool,
    error: PropTypes.string
  }),
  isConnected: PropTypes.bool,
  paygPrice: PropTypes.shape({
    isLoading: PropTypes.bool,
    currency: PropTypes.string,
    price: PropTypes.number
  }).isRequired,
  getClearPaymentStatus: PropTypes.func.isRequired,
  isPriceDataLoaded: PropTypes.bool.isRequired,
  fetchIdvPrices: PropTypes.func.isRequired,
  currentUserId: PropTypes.string
};

export default withTranslation()(IdvConnectModal);
