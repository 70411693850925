/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import PageWrapper from "hoc/PageWrapper";
import Header from "containers/Common/HeaderContainer";
import animationData from "assets/images/components/onboarding/right-image.json";

import "./styles.scss";
import { store, history } from "store";
import { getCurrentIdvActivationRedirect } from "store/modules/jobs/selectors";
import { setIsUpgradeComplete } from "store/modules/jobs/actions";
import {
  getSelectedAgency,
  setIsAgencyUpdated
} from "store/modules/users/actions";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const StripeSession = ({
  t,
  match: {
    params: { id = null }
  },
  getStripeSessionInfo,
  fetchProfileInfo,
  redirectUrl,
  currentUser
}) => {
  const [refreshCount, setRefreshCount] = useState(0);
  const timer = useRef();


  useEffect(() => {
    if (refreshCount < 10) {
      timer.current = setTimeout(async () => {
        setRefreshCount(refreshCount + 1);
        getStripeSessionInfo(id);
      }, 1500);
    }

    return () => clearInterval(timer.current);
  }, [refreshCount]);

  useEffect(() => {
    let isMounted = true;

    if (currentUser?.isAgencyUpdated && isMounted) {
      isMounted = false;

      const idvActivationRedirect = getCurrentIdvActivationRedirect(
        store.getState()
      );
      store.dispatch(setIsAgencyUpdated(false));

      if (idvActivationRedirect !== null) {
        store.dispatch(setIsUpgradeComplete("completed"));
        history.push(idvActivationRedirect);
      } else {
        store.dispatch(getSelectedAgency());
        store.dispatch(fetchProfileInfo());
        history.push("/jobs");
      }
    }

    return () => {
      isMounted = true;
    };
  }, [currentUser?.isAgencyUpdated]);

  return (
    <PageWrapper header={<Header />}>
      <Container className="stripe-session__container">
        <Row>
          <Col>
            <Lottie options={defaultOptions} height={200} width={250} />
            <h1 className="n-font-giant n-text-center stripe-session__header">
              {t("stripeSession.header")}
            </h1>
            {refreshCount >= 10 ? (
              <>
                <p className="n-font-medium n-text-center stripe-session__text">
                  {t("stripeSession.subtitle.0")}
                  <Link
                    className="n-font-medium n-text-center stripe-session__link"
                    to={redirectUrl ?? "/jobs"}
                    onClick={() => {
                      fetchProfileInfo();
                      store.dispatch(getSelectedAgency());
                      store.dispatch(fetchProfileInfo());
                    }}
                  >
                    {t("stripeSession.subtitle.1")}
                  </Link>
                </p>
              </>
            ) : (
              <p className="n-font-medium n-text-center stripe-session__text">
                {t("stripeSession.pleaseWait")}
              </p>
            )}
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

StripeSession.defaultProps = {
  isAgencyUpdated: undefined,
  currentUser: undefined
};

StripeSession.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  getStripeSessionInfo: PropTypes.func.isRequired,
  fetchProfileInfo: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string.isRequired,
  isAgencyUpdated: PropTypes.bool,
  currentUser: PropTypes.shape({
    plan_name: PropTypes.string,
    isAgencyUpdated: PropTypes.bool
  })
};

export default withTranslation()(StripeSession);
