import { handleActions } from "redux-actions";
import {
  duplicateScorecardError,
  duplicateScorecardSuccess,
  fetchCandidateScoreByCardIdResponse,
  fetchCandidateScoresResponse,
  fetchInterviewCardScorecardResponse,
  fetchLatestScorecardsResponse,
  fetchScoreCardDetailsResponse,
  fetchScorecardsOptionsResponse,
  fetchScorecardsResponse,
  resetScorecardError,
  saveScoreCardError,
  setCandidateScoreByCardIdLoading,
  setDeleteScorecardsLoading,
  setIsUpdatingScoreLoading,
  setScorecardsIsLoading,
  setCriteriaTitleSuggestions,
  shouldShowScorecard,
  setCriteriaDescriptionSuggestions
} from "./action";

const defaultState = {
  isLoading: false,
  tablesData: {
    results: []
  },
  success: null,
  error: null,
  autocompleteTitle: [],
  autocompleteDescription: [],
  currentScorecard: null,
  options: {},
  interviewScorecard: null,
  candidateScores: [],
  isCandidateScoresLoading: false,
  isUpdatingScoreLoading: false,
  isDeleteScorecardLoading: false,
  shouldShowScorecard: false
};

export default handleActions(
  {
    [fetchScorecardsResponse]: (state, { payload }) => ({
      ...state,
      tablesData: payload,
      isLoading: false
    }),
    [setScorecardsIsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [fetchLatestScorecardsResponse]: (
      state,
      { payload: { next, previous, count, results } }
    ) => ({
      ...state,
      tablesData: {
        next,
        previous,
        count,
        results: [...state.tablesData.results, ...results]
      }
    }),
    [setCriteriaTitleSuggestions]: (state, { payload }) => ({
      ...state,
      autocompleteTitle: payload
    }),
    [setCriteriaDescriptionSuggestions]: (state, { payload }) => ({
      ...state,
      autocompleteDescription: payload
    }),
    [fetchScoreCardDetailsResponse]: (state, { payload }) => ({
      ...state,
      currentScorecard: {
        ...payload,
        errors: null
      },
      autocompleteDescription: []
    }),
    [fetchScorecardsOptionsResponse]: (state, { payload }) => ({
      ...state,
      options: payload
    }),
    [saveScoreCardError]: (state, { payload }) => ({
      ...state,
      currentScorecard: {
        ...state.currentScorecard,
        errors: payload
      }
    }),
    [resetScorecardError]: state => ({
      ...state,
      currentScorecard: {
        ...state.currentScorecard,
        errors: null
      }
    }),
    [fetchInterviewCardScorecardResponse]: (state, { payload }) => ({
      ...state,
      interviewScorecard: payload
    }),

    [fetchCandidateScoreByCardIdResponse]: (state, { payload }) => ({
      ...state,
      interviewScores: payload
    }),
    [fetchCandidateScoresResponse]: (state, { payload }) => ({
      ...state,
      candidateScores: payload
    }),
    [setIsUpdatingScoreLoading]: (state, { payload }) => ({
      ...state,
      isUpdatingScoreLoading: payload
    }),
    [setCandidateScoreByCardIdLoading]: (state, { payload }) => ({
      ...state,
      isCandidateScoresLoading: payload
    }),
    [duplicateScorecardSuccess]: (state, { payload }) => ({
      ...state,
      success: payload
    }),
    [duplicateScorecardError]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [setDeleteScorecardsLoading]: (state, { payload }) => ({
      ...state,
      isDeleteScorecardLoading: payload
    }),
    [shouldShowScorecard]: (state, { payload }) => ({
      ...state,
      shouldShowScorecard: payload
    })
  },
  defaultState
);
