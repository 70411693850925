import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const SelectItem = ({
  t,
  option,
  checked,
  tabIndex,
  onSelectionChanged,
  withTranslationLabel
}) => {
  const handleClick = () => onSelectionChanged(!checked);

  return (
    <label
      className={`select-item ${checked && "selected"}`}
      aria-selected={checked}
      tabIndex={tabIndex}
      htmlFor={option.value}
    >
      <div className="owner__item">
        <input
          type="checkbox"
          className="owner__item-checkbox"
          name="owner-checkbox"
          id={option.value}
          onChange={handleClick}
          checked={checked}
          tabIndex={-1}
        />
        <label className="n-font-small n-black-100" htmlFor={option.value}>
          {withTranslationLabel ? t(option.label) : option.label}
        </label>
      </div>
      <p className="n-font-small n-purple-100">
        {option.amount}
      </p>
    </label>
  );
};

SelectItem.propTypes = {
  t: PropTypes.func.isRequired,
  option: PropTypes.shape({
    amount: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  checked: PropTypes.bool.isRequired,
  tabIndex: PropTypes.number.isRequired,
  onSelectionChanged: PropTypes.func.isRequired,
  withTranslationLabel: PropTypes.bool.isRequired
};

export default withTranslation()(SelectItem);
