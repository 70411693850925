import { createAction } from "redux-actions";

export const fetchUsers = createAction("FETCH_USERS");
export const fetchUsersResponse = createAction("FETCH_USERS_RESPONSE");
export const fetchUsersError = createAction("FETCH_USERS_ERROR");

export const fetchUsersList = createAction("FETCH_USERS_LIST");
export const fetchUsersListResponse = createAction("FETCH_USERS_LIST_RESPONSE");
export const updateUsersListResponse = createAction(
  "UPDATE_USERS_LIST_RESPONSE"
);
export const fetchUsersListError = createAction("FETCH_USERS_LIST_ERROR");

export const addNewUsers = createAction("ADD_NEW_USERS");
export const addNewUsersResponse = createAction("ADD_NEW_USERS_RESPONSE");
export const addNewUsersError = createAction("ADD_NEW_USERS_ERROR");

export const inviteUsers = createAction("INVITE_USERS");

export const updateUser = createAction("UPDATE_USER");
export const updateUserResponse = createAction("UPDATE_USER_RESPONSE");
export const updateUserError = createAction("UPDATE_USER_ERROR");

export const deleteUser = createAction("DELETE_USER");
export const deleteUserResponse = createAction("DELETE_USER_RESPONSE");
export const deleteUserError = createAction("DELETE_USER_ERROR");

export const clearUserError = createAction("CLEAR_USER_ERROR");

export const setCurrentUser = createAction("SET_CURRENT_USER");

export const getCurrentAgency = createAction("GET_CURRENT_AGENCY");
export const setCurrentAgency = createAction("SET_CURRENT_AGENCY");
export const setUpdatedAgency = createAction("SET_UPDATED_AGENCY");
export const setIsAgencyUpdated = createAction("SET_IS_AGENCY_UPDATED");
export const getCurrentAgencyLoading = createAction(
  "IS_GET_CURRENT_AGENCY_LOADING"
);
export const setResetCurrentAgency = createAction("SET_RESET_CURRENT_AGENCY");
export const getCurrentAgencyError = createAction("GET_CURRENT_AGENCY_ERROR");

export const isUsersLoading = createAction("IS_USER_LOADING");
export const setIsUsersAdded = createAction("IS_USER_ADDED");

export const updateAgency = createAction("UPDATE_AGENCY");
export const getSelectedAgency = createAction("GET_SELECTED_AGENCY");

export const fetchInviteCompany = createAction("FETCH_INVITE_COMPANY");
export const fetchInviteCompanyResponse = createAction(
  "FETCH_INVITE_COMPANY_RESPONSE"
);
export const fetchInviteCompanyError = createAction(
  "FETCH_INVITE_COMPANY_ERROR"
);

export const addNewInvite = createAction("ADD_NEW_INVITE");
export const addNewInviteResponse = createAction("ADD_NEW_INVITE_RESPONSE");
export const addNewInviteError = createAction("ADD_NEW_INVITE_ERROR");
export const addNewInviteLoading = createAction("ADD_NEW_INVITE_LOADING");

export const fetchStripePortalUrl = createAction("FETCH_STRIPE_PORTAL_URL");
