import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";
import { Row } from "react-bootstrap";

import Spinner from "components/Common/Spinner";
import withLoader from "hoc/withLoader";

import Column from "./Column";

const candidatesStatus = [
  "invited",
  "to_review",
  "accepted",
  "rejected"
];

const List = ({
  t,
  interviewers,
  interviewersAmount,
  changeSelected,
  setSelectedCandidatesByStatus,
  isNextLoading,
  isLoading,
  fetchItems,
  selectedStatus,
  setSelectedStatus,
  ...rest
}) => (
  <Row bsPrefix="row candidates__list">
    {Object.keys(interviewers).length ? candidatesStatus.map(status => (
      <Column
        key={status}
        title={t(`job.overview.candidateProgress.columns.${status}`)}
        candidates={interviewers[status]}
        status={status}
        quantity={interviewers[status]?.length || 0}
        changeSelected={changeSelected}
        setSelectedCandidatesByStatus={setSelectedCandidatesByStatus}
        setSelectedStatus={setSelectedStatus}
        selectedStatus={selectedStatus}
        {...rest}
      />
      )) : null}
    {!isLoading && (
    <div className="loading-next">
      <Waypoint onEnter={fetchItems} scrollableAncestor={window} />
      {isNextLoading && <Spinner />}
    </div>
      )}
  </Row>
  );

List.defaultProps = {
  changeSelected: undefined,
  setSelectedCandidatesByStatus: undefined,
  selected: undefined,
  isNextLoading: undefined
};

List.propTypes = {
  t: PropTypes.func.isRequired,
  job: PropTypes.shape({}).isRequired,
  interviewers: PropTypes.shape({
    invited: PropTypes.arrayOf(PropTypes.shape),
    to_review: PropTypes.arrayOf(PropTypes.shape),
    accepted: PropTypes.arrayOf(PropTypes.shape),
    rejected: PropTypes.arrayOf(PropTypes.shape)
  }).isRequired,
  interviewersAmount: PropTypes.shape({
    invited: PropTypes.number,
    to_review: PropTypes.number,
    accepted: PropTypes.number,
    rejected: PropTypes.number
  }).isRequired,
  handleBillingModal: PropTypes.func.isRequired,
  currentUser: PropTypes.shape({}).isRequired,
  changeSelected: PropTypes.func,
  setSelectedCandidatesByStatus: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
  isNextLoading: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  fetchItems: PropTypes.func.isRequired,
  selectedStatus: PropTypes.string.isRequired,
  setSelectedStatus: PropTypes.func.isRequired
};

export default withLoader(withTranslation()(List));
