/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
import PageWrapper from "hoc/PageWrapper";
import React, { useEffect, useRef, useState } from "react";
import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";
import { useTranslation } from "react-i18next";
import animationData from "assets/images/candidate/error.json";
import Notification from "components/Notifications/Notification";
import RateLimiting from "assets/images/candidate/rate-limiting.png";
import "./styles.scss";
import { connect } from "react-redux";

export const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const RateLimitingPage = ({ timeRemaining }) => {
  const [refreshSeconds, setRefreshSeconds] = useState(timeRemaining ?? 30);
  const timer = useRef();


  useEffect(() => {
    if (refreshSeconds > 0) {
      timer.current = setTimeout(() => setRefreshSeconds(refreshSeconds - 1), 1000);
    } else {
      window.location.reload();
      setRefreshSeconds(30);
    }

    return () => clearInterval(timer.current);
  }, [refreshSeconds]);


  const { t } = useTranslation();
  const handleClick = () => {
    window.location.reload();
  };

  function formatTime() {
    const minutes = Math.floor(refreshSeconds / 60);
    const seconds = refreshSeconds % 60;

    if (minutes > 0) {
      return `${minutes}m ${seconds}s`;
    }
    return `${seconds}s`;
  }

  return (
    <>
      <PageWrapper
        prefix="complete"
        header={<Header />}
        footer={<Footer isPublic />}
      >
        <div className="interview-complete__wrapper">
          <div className="rate-limiting__wrapper">

            <Notification
              type="trial"
              message={t("maintenance.refreshing", { time: formatTime() })}
              withCloseIcon={false}
              style={{ justifyContent: "center" }}
              buttonOnClick={handleClick}
              buttonText={t("button.tryNow")}
            />
            <img
              src={RateLimiting}
              style={{
                margin: "auto",
                paddingTop: 42,
                paddingBottom: 11
              }}
              alt="rate-limiting"
            />
            <div className="rate-limiting__wrapper-title">
              {t("rateLimiting.title")}
            </div>
            <div className="rate-limiting__wrapper-description">
              {t("rateLimiting.description")}
              <span className="rate-limiting__wrapper-link">
                support@willo.video
              </span>
            </div>
            <button
              className="important_notice-container__cta"
              style={{
                marginTop: 30
              }}
            >
              {t("rateLimiting.contactSupport")}
            </button>
            <div
              style={{
                marginTop: 10
              }}
              className="rate-limiting__wrapper-description"
            >
              {t("rateLimiting.thankYou")}
            </div>
            <div
              style={{
                paddingBottom: 20
              }}
              className="rate-limiting__wrapper-description"
            >
              {t("rateLimiting.willoTeam")}
            </div>
          </div>
        </div>

      </PageWrapper>
    </>
  );
};

const mapStateToProps = state => ({
  timeRemaining: state.widgets.timeRemaining
});
export default connect(mapStateToProps)(RateLimitingPage);
