/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { SuccessIcon, FileError } from "mixins/svgIcons";
import formatBytes from "./helpers";

const errorsUpload = ["exception_upload", "error_upload", "error_upload_params"];

class Preview extends React.PureComponent {
  render() {
    const {
      t,
      className,
      style,
      fileWithMeta: { remove },
      meta: { name = "", size = 0, status, validationError },
      bytesSent,
      canRemove,
      extra: { minSizeBytes },
      onSave,
      onRemove,
      isEdit,
      children,
      uploadProgress,
      error
    } = this.props;

    const handleRemove = props => {
      remove(props);
      if (onRemove) onRemove(props);
    };
    const handleSave = () => onSave(handleRemove);

    if (["error_file_size", "error_validation", ...errorsUpload].includes(status) || (uploadProgress !== null && error)) {
      return (
        <div className={`${className} error`} style={style}>
          <div className="dzu-previewFileNameContainer">
            <div className="dzu-iconFile">
              <FileError />
            </div>
            <div className="dzu-previewFileNameIcon">
              <span className="dzu-previewFileName">{name || "File answer"}</span>
            </div>
          </div>
          {uploadProgress !== null && error ? (
            <>
              <span className="dzu-percent">{error}</span>
              <div>
                {onSave && <button type="button" className="dzu-removeButton button__without-styles view" onClick={handleSave} disabled={isEdit}>{t("link.retry")}</button>}
                <span className="dzu-span">{t("candidate.videoCreate.or")}</span>
                <span className="dzu-removeButton view" onClick={handleRemove} role="button">{t("button.remove")}</span>
              </div>
            </>
          ) : (
            <div className="dzu-removeButtonContainer">
              <span className="dzu-removeButton remove" onClick={handleRemove} role="button">{t("button.remove")}</span>
            </div>
          )}
          <div className="dzu-percentContainer">
            {status === "error_file_size" && <span className="dzu-percent">{size > minSizeBytes ? "Oh no, file size too big. Maximum file size 25mb." : ""}</span>}
            {status === "error_validation" && <span className="dzu-percent">{String(validationError)}</span>}
            {errorsUpload.includes(status) && <span className="dzu-percent">{t("candidate.videoCreate.uploadFailed")}</span>}
          </div>
        </div>
      );
    }

    return (
      <div className={`${className} ${status !== "preparing" && status !== "getting_upload_params" && status !== "uploading" && "success"}`} style={style}>
        <div className="dzu-previewFileNameContainer">
          <div className="dzu-iconFile">
            {status !== "preparing" && status !== "getting_upload_params" && status !== "uploading" && (
              <SuccessIcon svgIconClass="not-clickable-icon" width="24" height="24" />
            )}
          </div>
          <div className="dzu-previewFileNameIcon">
            {children}
          </div>
        </div>
        <div className="dzu-removeButtonContainer">
          {!!bytesSent && (
            <span className="dzu-removeButton uploading">{t("candidate.videoCreate.uploading")}</span>
          )}
          {uploadProgress !== null && !error && (
            <span className="dzu-removeButton uploading">{t("candidate.videoCreate.saving", { percent: uploadProgress })}</span>
          )}
          {!bytesSent && status !== "preparing" && status !== "getting_upload_params" && status !== "uploading" && canRemove && (
            uploadProgress !== null ? (
              <div className="questions-upload">
                <progress
                  value={uploadProgress}
                  max="100"
                  className="questions-upload__progress dzu-progress"
                />
              </div>
            ) : (
              <>
                <span className="dzu-removeButton remove view" onClick={handleRemove} role="button">{t("button.remove")}</span>
                {onSave && (
                  <>
                    <span className="dzu-span">{t("candidate.videoCreate.or")}</span>
                    <button type="button" className="dzu-removeButton button__without-styles view" onClick={handleSave} disabled={isEdit}>{t("button.save")}</button>
                  </>
                )}
              </>
            )
          )}
        </div>

        {(status === "headers_received" || !bytesSent ? (
          <div className="dzu-percentContainer">
            <span className="dzu-percent">
              {formatBytes(size)}
            </span>
          </div>
        ) : (
          <div className="dzu-percentContainer">
            <span className="dzu-percent">
              {`${formatBytes(bytesSent || 0)} of ${formatBytes(size)}`}
            </span>
            <progress className="questions-upload__progress dzu-progress" max={100} value={status === "headers_received" ? 100 : bytesSent * 100 / size} />
          </div>
        ))}
      </div>
    );
  }
}

Preview.defaultProps = {
  className: "",
  style: {},
  onSave: null,
  onRemove: null,
  isEdit: false,
  uploadProgress: null,
  error: "",
  bytesSent: undefined
};

Preview.propTypes = {
  t: PropTypes.func.isRequired,
  fileWithMeta: PropTypes.shape({
    file: PropTypes.any.isRequired,
    meta: PropTypes.object.isRequired,
    cancel: PropTypes.func.isRequired,
    restart: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    xhr: PropTypes.any
  }).isRequired,
  meta: PropTypes.shape({
    status: PropTypes.oneOf([
      "preparing",
      "error_file_size",
      "error_validation",
      "ready",
      "getting_upload_params",
      "error_upload_params",
      "uploading",
      "exception_upload",
      "aborted",
      "error_upload",
      "headers_received",
      "done"
    ]).isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    uploadedDate: PropTypes.string.isRequired,
    percent: PropTypes.number,
    size: PropTypes.number,
    lastModifiedDate: PropTypes.string,
    previewUrl: PropTypes.string,
    duration: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    videoWidth: PropTypes.number,
    videoHeight: PropTypes.number,
    validationError: PropTypes.any
  }).isRequired,
  bytesSent: PropTypes.number,
  canRemove: PropTypes.bool.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({}),
  extra: PropTypes.shape({
    active: PropTypes.bool.isRequired,
    reject: PropTypes.bool.isRequired,
    dragged: PropTypes.arrayOf(PropTypes.any).isRequired,
    accept: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    minSizeBytes: PropTypes.number.isRequired,
    maxSizeBytes: PropTypes.number.isRequired,
    maxFiles: PropTypes.number.isRequired
  }).isRequired,
  children: PropTypes.element.isRequired,
  onSave: PropTypes.func,
  onRemove: PropTypes.func,
  isEdit: PropTypes.bool,
  uploadProgress: PropTypes.number,
  error: PropTypes.string
};

export default withTranslation()(Preview);
