/* eslint-disable import/prefer-default-export */
const handlePlayFromRef = async refValue => {
  if (refValue) {
    try {
      await refValue.play();
    } catch (error) {
      if (error.name === "AbortError") {
        // Handle case where audio element was removed during playback
        console.log("Audio element was removed during playback");
      } else {
        console.error("Playback failed:", error);
      }
    }
  }
};

export default handlePlayFromRef;
