import uuidv4 from "uuid/v4";
import videoSrcWillo from "files/willo.mp4";

export const SELECT_DAYS_RANGE = [
  {
    label: "Today",
    value: 0
  },
  {
    label: "Yesterday",
    value: 1
  },
  {
    label: "Last 7 days",
    value: 7
  },
  {
    label: "This month",
    value: 30
  },
  {
    label: "Last month",
    value: 60
  }
];

export const CANDIDATES = {
  invited: [
    {
      id: "79ac64c74ee543b39f2cc4940c70efb1",
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 0
    },
    {
      id: "79ac64c74ee543b39f2cc4940c70efb1",
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 0
    },
    {
      id: "79ac64c74ee543b39f2cc4940c70efb1",
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 0
    },
    {
      id: "79ac64c74ee543b39f2cc4940c70efb1",
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 0
    }
  ],
  review: [
    {
      id: 5,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 6,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 7,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 8,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 9,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    }
  ],
  accept: [
    {
      id: 10,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 11,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 12,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 13,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 14,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    }
  ],
  reject: [
    {
      id: 15,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 16,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 17,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    },
    {
      id: 18,
      name: "Bryan Walters",
      submitted: "06 Feb 2019",
      rating: 5
    }
  ]
};

export const CHART_DATA = [
  {
    name: "1/09",
    invited: 4000,
    received: 2400
  },
  {
    name: "1/10",
    invited: 3000,
    received: 1398
  },
  {
    name: "1/11",
    invited: 2000,
    received: 9800
  },
  {
    name: "1/12",
    invited: 2780,
    received: 3908
  },
  {
    name: "1/13",
    invited: 1890,
    received: 4800
  },
  {
    name: "1/14",
    invited: 2390,
    received: 3800
  },
  {
    name: "1/15",
    invited: 3490,
    received: 4300
  },
  {
    name: "1/16",
    invited: 3490,
    received: 4300
  }
];

export const COMPANY_JOB_LIST = [
  {
    id: 1,
    job_title: "Customer Service Advisor",
    invited: 32,
    interviewed: 15,
    expiry: 7,
    end: "27-07-2019"
  },
  {
    id: 2,
    job_title: "Customer Service Advisor",
    invited: 40,
    interviewed: 20,
    expiry: 5,
    end: "14-07-2019"
  },
  {
    id: 3,
    job_title: "Customer Service Advisor",
    invited: 63,
    interviewed: 10,
    expiry: 3,
    end: "20-07-2019"
  },
  {
    id: 4,
    job_title: "Customer Service Advisor",
    invited: 63,
    interviewed: 10,
    expiry: 3,
    end: "20-07-2019"
  }
];

export const CANDIDATE_STEPS_INFO = [
  {
    label: "Candidate invited",
    description: "Last Sunday at 11:10 am",
    icon: "start"
  },
  {
    label: "Visited interview page",
    description: "Last Sunday at 11:30 am",
    icon: "smallEye"
  },
  {
    label: "Video interview received",
    description: "Monday at 11:10 am",
    icon: "video"
  },
  {
    label: "James Taylor voted 5 stars",
    description: "Monday at 19:10 am",
    icon: "star"
  },
  {
    label: "Candidate Accepted",
    description: "Tuesday at 15:10 am",
    icon: "check"
  }
];

export const CANDIDATE_VIDEO_ANSWERS = [
  ...[...Array(5)].map(() => ({
    id: uuidv4(),
    videoSrc: videoSrcWillo,
    label: "Tell me about yourself"
  }))
];

export const STARS_QUANTITY = [
  ...[...Array(5)].map((item, index) => ({
    id: uuidv4(),
    number: index + 1
  }))
];

export const STARS_QUANTITY_NUMBER = [
  ...[...Array(5)].map((item, index) => ({
    number: index + 1
  }))
];
