import React from "react";

const CandidateDownloadIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.875 5.875V12.5195L14.8711 10.5234C15.1992 10.168 15.7734 10.168 16.1016 10.5234C16.457 10.8516 16.457 11.4258 16.1016 11.7539L12.6016 15.2539C12.2734 15.6094 11.6992 15.6094 11.3711 15.2539L7.87109 11.7539C7.51562 11.4258 7.51562 10.8516 7.87109 10.5234C8.19922 10.168 8.77344 10.168 9.10156 10.5234L11.125 12.5195V5.875C11.125 5.41016 11.5078 5 12 5C12.4648 5 12.875 5.41016 12.875 5.875ZM6.75 14.625H9.51172L10.7422 15.8828C11.4258 16.5664 12.5469 16.5664 13.2305 15.8828L14.4609 14.625H17.25C18.207 14.625 19 15.418 19 16.375V17.25C19 18.2344 18.207 19 17.25 19H6.75C5.76562 19 5 18.2344 5 17.25V16.375C5 15.418 5.76562 14.625 6.75 14.625ZM16.8125 17.4688C17.168 17.4688 17.4688 17.1953 17.4688 16.8125C17.4688 16.457 17.168 16.1562 16.8125 16.1562C16.4297 16.1562 16.1562 16.457 16.1562 16.8125C16.1562 17.1953 16.4297 17.4688 16.8125 17.4688Z" fill="#5A2AF1" />
  </svg>

);

export default CandidateDownloadIcon;
