import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

import resetSendEmailConfig from "configs/authentification/resetSendEmail";

import Input from "components/Common/Input";
import Message from "components/Common/Message";
import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";
import SendEmail from "components/SendEmail";
import Spinner from "components/Common/Spinner";

import PageWrapper from "hoc/AuthWrapper";

import { updateObject, checkValidity } from "mixins/helpers";

import "./styles.scss";

const ResetSendEmail = ({
  t,
  resetSendEmail,
  resetEmailIsSend,
  emailIsSend,
  clearErrors,
  isLoading,
  error
}) => {
  const [controls, setControls] = useState({ ...resetSendEmailConfig });
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    clearErrors();
    resetEmailIsSend(false);
  }, []);

  const inputChangedHandler = (event, controlName) => {
    const updatedControls = updateObject(controls, {
      [controlName]: updateObject(controls[controlName], {
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          controls[controlName].validation
        ),
        touched: true
      })
    });

    let formIsValidCurrent = true;
    Object.keys(updatedControls).forEach(key => {
      formIsValidCurrent = updatedControls[key].valid && formIsValidCurrent;
    });

    setControls(updatedControls);
    setFormIsValid(formIsValidCurrent);
  };

  const onSubmitHandler = event => {
    event.preventDefault();
    resetSendEmail({ email: controls.email.value.toLowerCase() });
  };

  let resetEmailView = (
    <div className="form form__reset-send-email">
      <form className="form__inner" onSubmit={onSubmitHandler}>
        <h2 className={error ? "form__title_error" : "form__title"}>
          {t("resetPassword.header")}
        </h2>
        {error && <Message error message={t(error)} />}

        <Input
          name="email"
          formElement={controls.email}
          inputChangedHandler={inputChangedHandler}
        />
        <button
          type="submit"
          className={classNames(
            "button",
            "button__submit",
            "button__purple",
            "button__purple_shadow"
          )}
          disabled={!formIsValid}
        >
          {t("button.resetPassword")}
        </button>

        <div className="form__link">
          <Link to="/sign-in">{t("link.signIn")}</Link>
        </div>
      </form>
    </div>
  );

  if (isLoading) {
    resetEmailView = <Spinner />;
  }

  if (!isLoading && emailIsSend) {
    resetEmailView = <SendEmail />;
  }

  return (
    <PageWrapper header={<Header />} footer={<Footer isPublic />}>
      {resetEmailView}
    </PageWrapper>
  );
};

ResetSendEmail.defaultProps = {
  emailIsSend: PropTypes.bool,
  clearErrors: PropTypes.func,
  resetEmailIsSend: undefined,
  isLoading: undefined,
  error: undefined
};

ResetSendEmail.propTypes = {
  t: PropTypes.func.isRequired,
  resetSendEmail: PropTypes.func.isRequired,
  resetEmailIsSend: PropTypes.func,
  emailIsSend: PropTypes.bool,
  clearErrors: PropTypes.func,
  isLoading: PropTypes.bool,
  error: PropTypes.string
};

export default withTranslation()(ResetSendEmail);
