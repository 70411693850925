import React, { useState } from "react";
import { TwitterPicker } from "react-color";
import PropTypes from "prop-types";

import { selectColor } from "mixins/helperCompany";

const Enabled = ({ fieldName, fieldValue, handleClassName, setFieldValue }) => {
  const [showColorPicker, setShowColorPicker] = useState(false);

  const handleClose = () => setShowColorPicker(false);

  return (
    <div className="color-picker-wrapper">
      <span
        className="color-picker-current-color"
        style={{ backgroundColor: fieldValue }}
        onClick={() => setShowColorPicker(true)}
        onKeyPress={() => {}}
        tabIndex={0}
        role="button"
      />

      <input
        id={fieldName}
        type="text"
        name="buttonColor"
        className={handleClassName()}
        onClick={() => setShowColorPicker(true)}
        value={fieldValue}
        readOnly
      />

      {showColorPicker && (
        <>
          {/* eslint-disable-next-line */}
          <div className="cover" onClick={handleClose} />
          <TwitterPicker
            color={fieldValue}
            className="custom"
            onChange={selectColor(setShowColorPicker, setFieldValue, fieldName)}
          />
        </>
      )}
    </div>
  );
};

Enabled.defaultProps = {
  fieldValue: "",
  handleClassName: undefined,
  setFieldValue: undefined
};

Enabled.propTypes = {
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.string,
  handleClassName: PropTypes.func,
  setFieldValue: PropTypes.func
};

export default Enabled;
