import React from "react";
import PropTypes from "prop-types";

import { CopyTable } from "mixins/svgIcons";

const Duplicate = ({ handleDuplicate }) => (
  <button
    onClick={handleDuplicate}
    className="button__without-styles duplicate"
    type="button"
  >
    <CopyTable svgIconClass="not-clickable-icon" />
  </button>
);

Duplicate.propTypes = {
  handleDuplicate: PropTypes.func.isRequired
};

export default Duplicate;
