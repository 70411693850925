export const SELECT_DAY_OF_THE_WEEK = [
  { value: 1, label: "one" },
  { value: 2, label: "many" },
  { value: 3, label: "many" },
  { value: 4, label: "many" },
  { value: 5, label: "many" },
  { value: 6, label: "many" },
  { value: 7, label: "many" }
];

export const JOB_DEADLINE_RANGE = 30;
