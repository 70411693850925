/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Formik } from "formik";
import BeatLoader from "react-spinners/BeatLoader";
import Lottie from "react-lottie";

import PageWrapper from "hoc/AuthWrapper";
import Header from "components/Common/Header/Main";
import Footer from "components/Common/Footer";
import withLoaderScreen from "hoc/withLoaderScreen";
import { Mail } from "mixins/svgIcons";
import { inviteConfig, inviteFormSchema } from "mixins/helperInvite";
import animationData from "assets/images/candidate/complete.json";

import "./styles.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const GenericInvite = ({
  t,
  location: { search = "" },
  history,
  company: { agency_key: agencyKey, agency_name: agency, role, logo },
  error,
  loading,
  user,
  isAuthenticated,
  fetchInviteCompany,
  addNewInvite,
  clearUserError
}) => {
  const query = new URLSearchParams(search);
  const token = query.get("token");
  const [redirectSeconds, setRedirectSeconds] = useState(5);
  const [userEmail, setEmail] = useState("");
  const timer = useRef(null);
  const userInfo = isAuthenticated
    ? {
      link: "/jobs",
      text: "genericInvite.yourAccount",
      button: "button.continueAccount"
    }
    : user?.status === "invited"
      ? {
        link: `/sign-up?email=${userEmail}`,
        text: "genericInvite.yourAccountPrepared",
        button: "button.continueSignup"
      }
      : {
        link: `/sign-in?email=${userEmail}`,
        text: "genericInvite.yourAccount",
        button: "button.continueLogin"
      };

  const handleRedirect = () => history.push(userInfo.link);

  useEffect(() => {
    clearUserError();
    fetchInviteCompany(token);
  }, []);
  useEffect(() => {
    if (user) {
      if (redirectSeconds > 0) {
        timer.current = setTimeout(
          () => setRedirectSeconds(redirectSeconds - 1),
          1000
        );
      } else {
        handleRedirect();
      }
    }

    return () => clearInterval(timer.current);
  }, [user, redirectSeconds]);
  const handleSubmit = values => {
    const { email } = values;
    addNewInvite({
      token,
      agencyKey,
      email,
      role
    });
    setEmail(email);
  };

  return (
    <PageWrapper header={<Header />} footer={<Footer isPublic />}>
      <div className="form form__generic">
        {user ? (
          <div>
            <div className="generic__success">
              <Lottie options={defaultOptions} height={200} width={300} />
            </div>
            {logo ? (
              <img src={logo} alt="logo" className="generic__logo" />
            ) : null}
            <p className="generic__text n-blue-700 n-font-medium">
              {t(userInfo.text)}
            </p>
            <button
              type="button"
              className="button button__submit button__purple button__purple_shadow"
              onClick={handleRedirect}
            >
              {t(userInfo.button)}
            </button>
            <p className="generic__refresh">
              {t("genericInvite.youWillBe.0")}
              <span className="main-link__without-decoration n-font-medium">
                {t("genericInvite.youWillBe.1", { seconds: redirectSeconds })}
              </span>
            </p>
          </div>
        ) : (
          <div>
            {logo ? (
              <img src={logo} alt="logo" className="generic__logo" />
            ) : null}

            <h3 className="generic__title n-grey-300 n-font-giant">
              {t("genericInvite.header", { agency })}
            </h3>

            <p className="generic__text n-black-80 n-font-small">
              {t("genericInvite.youHaveBeen", { agency })}
            </p>

            <div className="input">
              <label htmlFor="email" className="label">
                {t("input.emailLabel")}
              </label>
              <Formik
                enableReinitialize
                initialValues={inviteConfig()}
                validationSchema={inviteFormSchema()}
                onSubmit={handleSubmit}
                render={fk => (
                  <form
                    autoComplete="off"
                    className="form__inner"
                    onSubmit={fk.handleSubmit}
                  >
                    <div className="input-wrapper">
                      <Mail svgIconClass="input-icon" />
                      <input
                        id="email"
                        name="email"
                        type="text"
                        autoComplete="email"
                        className={`input-element ${
                          fk.errors.email && fk.touched.email ? "invalid" : ""
                        }`}
                        placeholder={t("input.yourEmailPlaceholder")}
                        value={fk.values.email}
                        onBlur={fk.handleBlur}
                        onChange={fk.handleChange}
                      />

                      {(fk.errors.email && fk.touched.email) || error ? (
                        <p className="input-error-message generic-error-message">
                          {t(fk.errors.email) || error}
                        </p>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      className={`button button__submit button__purple button__purple_shadow ${
                        loading ? "loading" : ""
                      }`}
                      disabled={
                        loading || !(Object.keys(fk.errors).length === 0)
                      }
                    >
                      {loading ? (
                        <>
                          {t("button.preparingAccount")}
                          <BeatLoader
                            color="#BBC2C9"
                            size={6}
                            margin={1}
                            loading
                          />
                        </>
                      ) : (
                        t("button.continue")
                      )}
                    </button>
                  </form>
                )}
              />
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

GenericInvite.propTypes = {
  t: PropTypes.func.isRequired,
  company: PropTypes.shape({
    agency_key: PropTypes.string,
    agency_name: PropTypes.string,
    logo: PropTypes.string,
    role: PropTypes.string
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  error: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  user: PropTypes.shape.isRequired,
  fetchInviteCompany: PropTypes.func.isRequired,
  addNewInvite: PropTypes.func.isRequired,
  clearUserError: PropTypes.func.isRequired
};

export default withLoaderScreen(withTranslation()(GenericInvite));
