const CANDIDATE_STATUSES = {
  INVITED: "Invited",
  RECEIVED: "Received",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  EXPIRED: "Expired"
};


export default CANDIDATE_STATUSES;
