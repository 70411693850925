/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { ArrowDown } from "mixins/svgIcons";
import { get } from "lodash";
import "./styles.scss";
import ConnectDropdownFooter from "./ConnectDropdownFooter";
import ConnectDropdownTriggerStage from "./ConnectDropdownTriggerStage";
import ConnectDropdownJobs from "./ConnectDropdownJobs";

const ConnectDropdown = ({
  t,
  atsDetails,
  agencyKey,
  jobId
}) => {
  const dropdownRef = useRef(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [isMobileOrTabletScreen, setIsMobileOrTabletScreen] = useState(window.innerWidth <= 767);
  const [shouldShowStages, setShouldShowStages] = useState(!isMobileOrTabletScreen);
  const [currentSelectedJob, setCurrentSelectedJob] = useState(null);
  const [selectedJobsAndStages, setSelectedJobsAndStages] = useState(
    () => atsDetails?.savedJobsAndStages ?? []
  );

  const reset = () => {
    setShowDropdown(false);
    setCurrentSelectedJob(null);
    setShouldShowStages(!isMobileOrTabletScreen);
  };

  const handleDropdown = shouldShow => {
    setSelectedJobsAndStages(atsDetails?.savedJobsAndStages ?? []);

    if (shouldShow) {
      setShowDropdown(shouldShow);
    } else {
      reset();
    }
  };

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobileOrTabletScreen(window.innerWidth <= 767);
      setShouldShowStages(window.innerWidth >= 768);

      if (window.innerWidth <= 767) setCurrentSelectedJob(null);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function handleClickOutside(ev) {
    const classNames = ev.target.getAttribute("class") || "";
    const parentElement = get(ev, "target.parentElement", "") || "";
    const parentClassNames = typeof parentElement === "string"
      ? parentElement?.className || "" : typeof parentElement === "object"
        ? parentElement?.getAttribute("class") || "" : "";

    const isNotModalClasses = parentClassNames !== ""
      && (!classNames?.includes("close"))
      && (!parentClassNames?.includes("modal") && !parentClassNames?.includes("close"))
      && !parentClassNames?.includes("button__group_center");

    if (dropdownRef?.current != null && !dropdownRef?.current?.contains(ev.target)) {
      if (isNotModalClasses) {
        reset();
        setSelectedJobsAndStages(atsDetails?.savedJobsAndStages ?? []);
      }
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  const updateSelectedJobsAndStages = useCallback(jobs => setSelectedJobsAndStages(jobs), []);

  if (!atsDetails?.atsConnected?.length || atsDetails?.isLoading) {
    return (
      <div
        style={{ visibility: "hidden" }}
        className="connect__dropdown-container"
        ref={dropdownRef}
      />
    );
  }

  return (
    <div className="connect__dropdown-container" ref={dropdownRef}>
      <div
        onClick={() => handleDropdown(!showDropdown)}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
        className="connect__dropdown-wrapper"
      >
        <div className="ats__details-wrapper">
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            {atsDetails?.atsConnected?.map((ats, index) => (
              <div className="ats-logo" key={String(index)}>
                <img src={ats.image_url} alt="ats logo" style={{ left: 10, height: 15, width: 15, top: 10 }} />
              </div>
            ))}
          </div>
          <p className="ats-label">
            {
            atsDetails?.savedJobsAndStagesCount < 1
              ? t("job.overview.connect.connectToJob") :
              atsDetails?.savedJobsAndStagesCount > 1
                ? t("job.overview.connect.connectedToXJobs", { total: atsDetails?.savedJobsAndStagesCount })
                : atsDetails?.savedJobsAndStages?.[0]?.remote_interview_name
            }
          </p>
        </div>
        <ArrowDown />
      </div>
      <div className={`${showDropdown ? "display" : "hide"} `}>
        <div className="connect__dropdown__select-container">
          <ConnectDropdownJobs
            atsDetails={atsDetails}
            isMobileOrTabletScreen={isMobileOrTabletScreen}
            agencyKey={agencyKey}
            selectedJobsAndStages={selectedJobsAndStages}
            shouldShowStages={shouldShowStages}
            currentSelectedJob={currentSelectedJob}
            setCurrentSelectedJob={setCurrentSelectedJob}
            setShouldShowStages={setShouldShowStages}
            jobId={jobId}
          />
          <ConnectDropdownTriggerStage
            shouldShowStages={shouldShowStages}
            isMobileOrTabletScreen={isMobileOrTabletScreen}
            currentSelectedJob={currentSelectedJob}
            t={t}
            setShouldShowStages={setShouldShowStages}
            setCurrentSelectedJob={setCurrentSelectedJob}
            atsDetails={atsDetails}
            selectedJobsAndStages={selectedJobsAndStages}
            updateSelectedJobsAndStages={updateSelectedJobsAndStages}
          />
        </div>

        {
          atsDetails?.availableJobs?.results?.length ? (
            <ConnectDropdownFooter
              isMobileOrTabletScreen={isMobileOrTabletScreen}
              atsDetails={atsDetails}
              selectedJobsAndStages={selectedJobsAndStages}
              jobId={jobId}
              agencyKey={agencyKey}
              reset={reset}
            />
          ) : null
        }
      </div>
    </div>
  );
};

ConnectDropdown.defaultProps = {
  t: undefined,
  atsDetails: undefined,
  agencyKey: undefined,
  jobId: undefined
};

ConnectDropdown.propTypes = {
  t: PropTypes.func,
  jobId: PropTypes.string,
  atsDetails: PropTypes.shape({
    atsConnected: PropTypes.arrayOf(PropTypes.shape({})),
    isLoading: PropTypes.bool,
    availableJobs: PropTypes.shape({
      next: PropTypes.string,
      results: PropTypes.arrayOf(PropTypes.shape({})),
      atsKey: PropTypes.string
    }),
    availableStages: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string
    })),
    savedJobsAndStages: PropTypes.arrayOf(PropTypes.shape({
      remote_interview_name: PropTypes.string
    })),
    savedJobsAndStagesCount: PropTypes.number
  }),
  agencyKey: PropTypes.string
};

export default withTranslation()(ConnectDropdown);
