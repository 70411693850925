import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { setLoadingScreen } from "store/modules/widgets/actions";
import { fetchCompaniesForQuestion, fetchCurrentJob, fetchLanguagesForQuestion, saveJob, fetchSuggestions } from "store/modules/jobs/actions";
import { fetchLibrariesDivided } from "store/modules/libraries/actions";
import { getCurrentUser } from "store/modules/users/selectors";
import { getJobCurrent, getPreviewError, getSuggestions } from "store/modules/jobs/selectors";

import Create from "views/Jobs/Create";
import { getLanguage } from "store/modules/language/selectors";
import { setModal } from "store/modules/modals/actions";

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  currentJob: getJobCurrent(state),
  isLoading: state.jobs.isLoading,
  librariesDivided: state.libraries.librariesDivided || {},
  currentLanguage: getLanguage(state),
  suggestions: getSuggestions(state),
  error: getPreviewError(state)
});

const mapDispatchToProps = {
  setLoadingScreen,
  fetchCompaniesForQuestion,
  fetchLanguagesForQuestion,
  fetchLibrariesDivided,
  fetchCurrentJob,
  saveJob,
  setModal,
  fetchSuggestions
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Create));
