import { handleActions, combineActions } from "redux-actions";

import { updateProfileInfoResponse, fetchProfileInfoResponse } from "../profile/actions";
import { fetchCandidateInfoResponse } from "../сandidates/actions";
import { fetchCommonJobResponse } from "../common/actions";
import { saveLanguage, saveLanguagePublic } from "./actions";

const defaultState = {
  currentLanguage: null,
  currentLanguagePublic: null
};

export default handleActions(
  {
    [saveLanguage]: (state, { payload }) => ({
      ...state,
      currentLanguage: payload
    }),
    [saveLanguagePublic]: (state, { payload }) => ({
      ...state,
      currentLanguagePublic: payload
    }),
    [combineActions(
      fetchProfileInfoResponse,
      updateProfileInfoResponse
    )]: (state, { payload }) => ({
      ...state,
      currentLanguage: payload.language
    }),
    [fetchCandidateInfoResponse]: (state, { payload }) => ({
      ...state,
      currentLanguagePublic: payload.candidateInfo?.selected_language || state.currentLanguagePublic
    }),
    [fetchCommonJobResponse]: (state, { payload }) => ({
      ...state,
      currentLanguagePublic: payload?.default_language
    })
  },
  defaultState
);
