import { connect } from "react-redux";

import { getCurrentUser } from "store/modules/users/selectors";
import { getProfileInfo } from "store/modules/profile/selectors";
import PrivateChat from "components/PrivateChat";

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
  profileInfo: getProfileInfo(state)
});

export default connect(mapStateToProps, null)(PrivateChat);
