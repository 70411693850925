import React from "react";
import { bool, string } from "prop-types";

import Spinner from "components/Common/Spinner";

const withLoader = WrappedComponent => {
  const Wrapped = ({ isLoading, className, ...rest }) => (
    <div className={`${className} ${isLoading ? "loader" : ""}`}>
      {isLoading && <Spinner />}

      <div className="loader__component">
        <WrappedComponent {...rest} isLoading={isLoading} />
      </div>
    </div>
  );

  Wrapped.defaultProps = {
    isLoading: false,
    className: ""
  };

  Wrapped.propTypes = {
    isLoading: bool,
    className: string
  };

  return Wrapped;
};

export default withLoader;
