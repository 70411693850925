import React from "react";
import PropTypes from "prop-types";

import { PLANS_NAMES } from "configs/plans";

const Title = ({
  user,
  handleBillingModal,
  plan_name: planName = PLANS_NAMES.freeForever
}) => {
  if (Boolean(user?.is_locked) && planName !== PLANS_NAMES.freeForever) {
    return (
      <p
        className="candidates__list-item-title n-font-small"
        type="button"
      >
        {user.name}
      </p>
    );
  }

  if (Boolean(user?.is_locked) && planName === PLANS_NAMES.freeForever) {
    return (
      <button
        onClick={handleBillingModal}
        className="button__without-styles candidates__list-item-title n-font-small"
        type="button"
      >
        {user.name}
      </button>
    );
  }

  return (
    <div
      className="candidates__list-item-title n-font-small"
    >
      {user.name}
    </div>
  );
};

Title.defaultProps = {
  job: undefined,
  user: undefined,
  handleBillingModal: undefined
};

Title.propTypes = {
  job: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string
  }),
  user: PropTypes.shape({
    is_locked: PropTypes.bool,
    jobId: PropTypes.number,
    key: PropTypes.string,
    status: PropTypes.string,
    name: PropTypes.string,
    submitted: PropTypes.string,
    rating: PropTypes.number
  }),
  handleBillingModal: PropTypes.func,
  plan_name: PropTypes.string.isRequired
};

export default Title;
