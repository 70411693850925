import { handleActions } from "redux-actions";
import {
  getIdvBundlesError,
  getIdvBundlesLoading,
  getIdvCheckoutLinkError,
  getClearPaymentStatus,
  isIdvCheckoutLinkLoading,
  setIdvBundles,
  setIdvPaymentStatus,
  setAgencyIdvPurchased,
  getAgencyIdvPurchasedLoading,
  getAgencyIdvPurchasedError,
  setAgencyIdvUsage,
  getAgencyIdvUsageLoading,
  getAgencyIdvUsageError,
  setIdvPaygPrice,
  getIdvPaygPriceLoading,
  getIdvPaygPriceError,
  setIdvSession,
  getIdvSessionLoading,
  getIdvSessionError,
  setIsPricesDataFetched
} from "./actions";

const defaultState = {
  isIdvBundlesLoading: true,
  isAgencyIdvPurchasedLoading: true,
  isAgencyIdvUsageLoading: true,
  idvBundles: [],
  paymentStatus: {},
  agencyIdvPurchased: {},
  agencyIdvUsage: {},
  paygPrice: {},
  idvSession: {},
  isPricesDataLoaded: false
};

export default handleActions(
  {
    [getClearPaymentStatus]: state => ({
      ...state,
      paymentStatus: defaultState.paymentStatus
    }),
    [setIdvBundles]: (state, { payload }) => ({
      ...state,
      idvBundles: payload
    }),
    [getIdvBundlesLoading]: (state, { payload }) => ({
      ...state,
      isIdvBundlesLoading: payload
    }),
    [getIdvBundlesError]: (state, { payload }) => ({
      ...state,
      idvBundles: {
        ...state.idvBundles,
        error: payload
      }
    }),
    [setIdvPaymentStatus]: (state, { payload }) => ({
      ...state,
      paymentStatus: payload
    }),
    [isIdvCheckoutLinkLoading]: (state, { payload }) => ({
      ...state,
      paymentStatus: {
        ...state.paymentStatus,
        isLoading: payload
      }
    }),
    [getIdvCheckoutLinkError]: (state, { payload }) => ({
      ...state,
      paymentStatus: {
        ...state.paymentStatus,
        error: payload
      }
    }),
    [setAgencyIdvPurchased]: (state, { payload }) => ({
      ...state,
      agencyIdvPurchased: payload
    }),
    [getAgencyIdvPurchasedLoading]: (state, { payload }) => ({
      ...state,
      isAgencyIdvPurchasedLoading: payload
    }),
    [getAgencyIdvPurchasedError]: (state, { payload }) => ({
      ...state,
      agencyIdvPurchased: {
        ...state.agencyIdvPurchased,
        error: payload
      }
    }),
    [setAgencyIdvUsage]: (state, { payload }) => ({
      ...state,
      agencyIdvUsage: {
        totalCreditsUsed: payload.total_credits_used,
        totalCredits: payload.total_credits,
        totalRemainingCredits: payload.remaining_credits,
        paygCredits: payload.subscription_payment_threshold,
        paygCreditsUsed: payload.subscription_used_credits,
        remainingPaygCredits:
        payload.subscription_payment_threshold - payload.subscription_used_credits
      }
    }),
    [getAgencyIdvUsageLoading]: (state, { payload }) => ({
      ...state,
      isAgencyIdvUsageLoading: payload
    }),
    [getAgencyIdvUsageError]: (state, { payload }) => ({
      ...state,
      agencyIdvUsage: {
        ...state.agencyIdvUsage,
        error: payload
      }
    }),
    [setIdvPaygPrice]: (state, { payload }) => ({
      ...state,
      paygPrice: payload
    }),
    [getIdvPaygPriceLoading]: (state, { payload }) => ({
      ...state,
      paygPrice: {
        ...state.paygPrice,
        isLoading: payload
      }
    }),
    [getIdvPaygPriceError]: (state, { payload }) => ({
      ...state,
      paygPrice: {
        ...state.paygPrice,
        error: payload
      }
    }),
    [setIdvSession]: (state, { payload }) => ({
      ...state,
      idvSession: {
        sessionId: payload.session_id,
        sessionToken: payload.session_token,
        sessionExpiry: payload.session_token_ttl
      }
    }),
    [getIdvSessionLoading]: (state, { payload }) => ({
      ...state,
      idvSession: {
        ...state.idvSession,
        isLoading: payload
      }
    }),
    [getIdvSessionError]: (state, { payload }) => ({
      ...state,
      idvSession: {
        ...state.idvSession,
        error: payload
      }
    }),
    [setIsPricesDataFetched]: (state, { payload }) => ({
      ...state,
      isPricesDataLoaded: payload
    })
  },
  defaultState
);
