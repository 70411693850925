import SkeletonWrapper from "containers/Hoc/SkeletonWrapper";
import React from "react";
import { getIsJobCandidateLoading } from "store/modules/jobs/selectors";
import "./index.scss";
import { AUDIO_TYPE, CHECKBOXES_TYPE, DBS_RTW_TYPE, DBS_TYPE, EXPIRED, FILE_TYPE, IDV_TYPE, MULTIPLE_CHOICE_TYPE, RTW_TYPE, TEXT_TYPE, VIDEO_TYPE } from "configs/jobs/constants";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isPublicInfoLoading } from "store/modules/public/selectors";
import CandidateAnswerText from "./CandidateAnswerText";
import CandidateAnswerMedia from "./CandidateAnswerMedia/CandidateAnswerMedia";
import CandidateAnswerFile from "./CandidateAnswerFile";
import CandidateAnswerMCQ from "./CandidateAnswerMCQ";
import CandidateAnswerIDV from "./CandidateAnswerIDV/CandidateAnswerIDV";
import CandidateAnswerExpired from "./CandidateAnswerExpired";

const CandidateAnswerResponse = ({
  currentQuestionAnswer: {
    question
  } = {},
  handleGoToScorecard,
  isExpired,
  isJobCandidateLoading,
  isShowcaseLoading,
  ...props
}) => {
  const components = {
    [TEXT_TYPE]: (
      <CandidateAnswerText {...props} />
    ),
    [FILE_TYPE]: (
      <CandidateAnswerFile {...props} />
    ),
    [MULTIPLE_CHOICE_TYPE]: (
      <CandidateAnswerMCQ {...props} />
    ),
    [CHECKBOXES_TYPE]: (
      <CandidateAnswerMCQ {...props} />
    ),
    [IDV_TYPE]: (
      <CandidateAnswerIDV {...props} />
    ),
    [DBS_TYPE]: (
      <CandidateAnswerIDV {...props} />
    ),
    [DBS_RTW_TYPE]: (
      <CandidateAnswerIDV {...props} />
    ),
    [RTW_TYPE]: (
      <CandidateAnswerIDV {...props} />
    ),
    [AUDIO_TYPE]: (
      <CandidateAnswerMedia
        handleGoToScorecard={handleGoToScorecard}
        {...props}
      />
    ),
    [VIDEO_TYPE]: (
      <CandidateAnswerMedia
        handleGoToScorecard={handleGoToScorecard}
        {...props}
      />
    ),
    [EXPIRED]: (
      <CandidateAnswerExpired size="small" />
    )
  };

  return (
    <div
      className={`candidateanswer__section__content__question ${String(question?.answer_type || "expired")} flex-col`}
      style={{
        position: "relative",
        overflow: "hidden",
        ...(isJobCandidateLoading && { padding: 0 })
      }}
    >
      <SkeletonWrapper
        skeletonProps={{
          count: 1,
          enableAnimation: true,
          height: window.innerWidth > 1024 ? "60vh" : "300px"
        }}
        skeletonWrapperProps={{
          style: {
            display: "block",
            lineHeight: 1.5,
            width: "100%"
          }
        }}
        isLoading={isJobCandidateLoading || isShowcaseLoading}
      >
        {isExpired || question?.answer_type
          ? components[isExpired ? EXPIRED : question?.answer_type]
          : <div />}
      </SkeletonWrapper>
    </div>
  );
};

CandidateAnswerResponse.defaultProps = {
  currentQuestionAnswer: {},
  handleGoToScorecard: () => { },
  isExpired: false
};

CandidateAnswerResponse.propTypes = {
  currentQuestionAnswer: PropTypes.shape({
    key: PropTypes.string,
    question: PropTypes.shape({
      answer_type: PropTypes.string
    })
  }),
  handleGoToScorecard: PropTypes.func,
  isExpired: PropTypes.bool
};

const mapStateToProps = state => ({
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  isJobCandidateLoading: getIsJobCandidateLoading(state),
  isShowcaseLoading: isPublicInfoLoading(state)
});

export default connect(mapStateToProps)(withTranslation()(CandidateAnswerResponse));
