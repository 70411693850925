import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import AddCardButton from "./AddCardButton";
import ShowCard from "./ShowCard";

const Billing = ({
  currentUser: { card = {}, customer_billing_info: billingInfo },
  handleUpgrade,
  children
}) => {
  const [isCardAdded, setIsCardAdded] = useState({
    isCardAdded: false
  });

  useEffect(() => {
    setIsCardAdded(Boolean(card.last4) && Boolean(card.exp_month) && Boolean(card.exp_year));
  }, [card]);

  return (
    <>
      {isCardAdded ? (
        <div className="profile-card">
          <ShowCard cardInfo={card || {}} billingInfo={billingInfo || {}} />
        </div>
      ) : (
        <div className="billing">
          <AddCardButton handleUpgrade={handleUpgrade} />
        </div>
      )}
      {children}
    </>
  );
};

Billing.propTypes = {
  currentUser: PropTypes.shape({
    card: PropTypes.object,
    customer_billing_info: PropTypes.object
  }).isRequired,
  handleUpgrade: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired
};

export default Billing;
