import React from "react";
import PropTypes from "prop-types";
import { FaLock } from "react-icons/fa";

const Label = ({ htmlFor, title, condition, clicked, isDisabled }) => (
  <label htmlFor={htmlFor} className="label">
    {condition ? (
      title
    ) : (
      <button
        type="button"
        className="button__without-styles"
        disabled={isDisabled}
        onClick={clicked}
      >
        <span className="label__text">{title}</span>
        <i className="fas gold fa-lock">
          <FaLock />
        </i>
      </button>
    )}
  </label>
);

Label.defaultProps = {
  isDisabled: undefined,
  htmlFor: undefined,
  title: undefined,
  condition: undefined,
  clicked: undefined
};

Label.propTypes = {
  isDisabled: PropTypes.bool,
  htmlFor: PropTypes.string,
  title: PropTypes.string,
  condition: PropTypes.bool,
  clicked: PropTypes.func
};

export default Label;
