import { handleActions } from "redux-actions";

import { updateAgency } from "store/modules/users/actions";
import { logOut } from "../auth/actions";

import {
  setNotificationsResponse,
  setPromoResponse,
  setQuotaExceedMuteResponse,
  setShowTutorialMuteResponse,
  setCompletedOnboarding
} from "./actions";
import { setIsNotificationsLoading } from "../widgets/actions";

const defaultState = {
  is_quota_exceed: false,
  is_tutorial_enabled: false,
  is_subscription_unpaid: false,
  promo: {
    show_banner: false
  },
  is_admin_user: true,
  is_agency_locked: false,
  is_trial_period: false,
  show_subscription_issue_banner: false,
  trial_period_end: null,
  is_country_provided: false,
  is_agency_name_provided: false,
  is_team_invited: false,
  is_setup_completed: true,
  show_metered_subscription_unpaid_page: false,
  show_primary_subscription_unpaid_page: false,
  show_trial_expired_page: true,
  is_sms_hard_limit_reached: false,
  notificationsLoading: false
};

export default handleActions(
  {
    [setNotificationsResponse]: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    [setPromoResponse]: (state, { payload }) => ({
      ...state,
      ...payload
    }),
    [setQuotaExceedMuteResponse]: state => ({
      ...state,
      is_quota_exceed: false
    }),
    [setShowTutorialMuteResponse]: state => ({
      ...state,
      is_tutorial_enabled: false
    }),
    [updateAgency]: (state, { payload }) => ({
        ...state,
        ...payload
      }),
    [setCompletedOnboarding]: (state, { payload }) => ({
      ...state,
      completedOnboarding: payload
    }),
    [logOut]: () => ({
      ...defaultState
    }),
    [setIsNotificationsLoading]: (state, { payload }) => ({
      ...state,
      notificationsLoading: payload
    })
  },
  defaultState
);
