/* eslint-disable no-nested-ternary */
import { isEmpty } from "lodash";
import darkFooterLogo from "assets/images/icons/footer-logo.svg";
import lightFooterLogo from "assets/images/icons/light-footer-logo.svg";
import darkHeaderLogo from "assets/images/icons/logo-blue.png";
import lightHeaderLogo from "assets/images/icons/header-light-logo.svg";

/* eslint-disable import/prefer-default-export */
export const getFontColorFromBgColor = ({
  hex,
  fallbackColor = "#595959",
  fontColor = undefined
}) => {
  if (!isEmpty(hex)) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const colorContrast = Math.round((r * 299 + g * 587 + b * 114) / 1000);
    // if dark contrast
    if (colorContrast < 140) return fontColor ?? "#FFF";

    return fallbackColor;
  }

  return null;
};

export const getColorFromBgColor = ({ hex, fallbackColor = "#595959" }) => {
  if (!isEmpty(hex)) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const colorContrast = Math.round((r * 299 + g * 587 + b * 114) / 1000);

    // if dark contrast, use hex
    if (colorContrast < 140) return hex;

    return fallbackColor;
  }

  return null;
};

export const getBackIconColor = ({ hex, fallbackColor = "#595959" }) => {
  if (!isEmpty(hex)) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const colorContrast = Math.round((r * 299 + g * 587 + b * 114) / 1000);

    // if dark contrast, use hex
    if (colorContrast < 190) return hex;

    return fallbackColor;
  }

  return null;
};

export const getColorContrastOrHex = ({
  hex,
  fontColor = undefined
}) => {
  if (!isEmpty(hex)) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const colorContrast = Math.round((r * 299 + g * 587 + b * 114) / 1000);

    // if light contrast, use #595959
    // otherwise, use hex color
    if (colorContrast < 140) return hex;

    return fontColor ?? "#595959";
  }

  return null;
};

export const getWilloLogoFromBgColor = (hex, type = "footer") => {
  if (!isEmpty(hex)) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    const colorContrast = Math.round((r * 299 + g * 587 + b * 114) / 1000);

    if (colorContrast < 140) {
      return type === "footer" ? lightFooterLogo : lightHeaderLogo;
    }
  }

  return type === "footer" ? darkFooterLogo : darkHeaderLogo;
};

export const getRandomVideo = () => {
  const videos = [
    {
      video:
        "https://s3.eu-west-2.amazonaws.com/willotalent.com/video1/playlist.m3u8"
    },
    {
      video:
        "https://s3.eu-west-2.amazonaws.com/willotalent.com/video2/playlist.m3u8"
    },
    {
      video:
        "https://s3.eu-west-2.amazonaws.com/willotalent.com/video3/playlist.m3u8"
    }
  ];

  const randomItem = Math.floor(Math.random() * videos.length);

  return videos[randomItem];
};
