/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Select from "components/Forms/Select";
import Input from "components/Common/Input";
import { TEXT_LIMITS } from "configs/jobs/constants";
import { Controller, useFormContext } from "react-hook-form";

const TextLimit = ({
  t,
  control,
  order
}) => {
  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  const getLabel = useCallback(({ titleKey }) => t(titleKey), []);
  const { watch, setValue } = useFormContext();
  const isMaxWords = isNumeric(watch(`questions[${order - 1}].max_words`));
  const textLimit = watch(`questions[${order - 1}].max_words`) || watch(`questions[${order - 1}].max_characters`) || 0;

  return (
    <>
      <Select
        label={t("job.questions.textLimit.title")}
        value={isMaxWords ? TEXT_LIMITS[1] : TEXT_LIMITS[0]}
        options={TEXT_LIMITS}
        changed={val => {
          setValue(`questions[${order - 1}].max_words`, val.value === "max_words" ? textLimit : null);
          setValue(`questions[${order - 1}].max_characters`, val.value === "max_characters" ? textLimit : null);
        }}
        getOptionLabel={getLabel}
        isSearchable={false}
      />
      <Controller
        name={`questions[${order - 1}].${isMaxWords ? "max_words" : "max_characters"}`}
        control={control}
        render={({
          field: { onChange, value }
        }) => (
          <Input
            name="maxCount"
            formElement={{
              value: value || "",
              label: "maxCount",
              elementConfig: {
                placeholder: "maxCount",
                type: "text"
              },
              inputClass: "max-count"
            }}
            inputChangedHandler={e => {
              const numberValue = e.target.value.replace(/\D/g, "");
              onChange(numberValue);
            }}
          />
        )}
      />
    </>
  );
};

TextLimit.defaultProps = {
  t: undefined,
  control: undefined
};

TextLimit.propTypes = {
  t: PropTypes.func,
  control: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({})
  ]),
  order: PropTypes.number.isRequired
};

export default withTranslation()(TextLimit);
