import React, { useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import NiceInputPassword from "react-nice-input-password";

import { Key, Eye } from "mixins/svgIcons";

import "./styles.scss";

const PasswordField = ({
  t,
  name,
  formElement: {
    label,
    touched,
    valid,
    value: valuePassword,
    errorMessage,
    elementConfig
  },
  inputChangedHandler
}) => {
  const inputRef = useRef(null);
  const [passwordFields, setPasswordFields] = useState({
    bar: false,
    description: false
  });

  const showHideFields = () => {
    setPasswordFields({
      bar: true,
      description: true
    });
  };

  const showPassword = () => {
    const ATTR_NAME = "type";
    const inputElement = inputRef.current;
    const inputAttribute = inputElement.getAttribute(ATTR_NAME);
    const changedValue = inputAttribute === "password" ? "text" : "password";

    return inputElement.setAttribute(ATTR_NAME, changedValue);
  };

  return (
    <div className="input">
      <label htmlFor="password" className="label">
        {t(`input.${label}`)}
      </label>

      <div role="presentation" className="input-wrapper password" onClick={showHideFields}>
        <div className="input-icon-wrapper">
          <Key svgIconClass="input-icon" />
        </div>
        <NiceInputPassword
          inputRef={inputRef}
          name={name}
          type="text"
          label=""
          placeholder={t(`input.${[elementConfig.placeholder]}`)}
          className={`input-element-nice-password ${(valuePassword !== null && !valid && touched) ? "invalid" : ""}`}
          value={valuePassword}
          securityLevels={[
            {
              descriptionLabel: t("validation.oneNumber"),
              validator: /.*[0-9].*/
            },
            {
              descriptionLabel: t("validation.oneLetter"),
              validator: /.*[a-zA-Z].*/
            },
            {
              descriptionLabel: t("validation.oneUpperCaseLetter"),
              validator: /.*[A-Z].*/
            },
            {
              descriptionLabel: t("validation.minLength"),
              validator: value => value?.length >= 12
            }
          ]}
          showSecurityLevelBar={passwordFields.bar}
          showSecurityLevelDescription={passwordFields.bar}
          onChange={({ value, isValid }) =>
            inputChangedHandler(value, name, isValid)
          }
        />
        <button
          type="button"
          className="button__show-password"
          onClick={showPassword}
        >
          <Eye svgIconClass="input-icon right" />
        </button>
        {valuePassword !== null && !valid && touched && (
          <div className="input-element-nice-password">
            <div className="input-password__description">
              <div className="input-password__level">
                <li className="danger">{t(`input.${errorMessage}`)}</li>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

PasswordField.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  formElement: PropTypes.shape({
    label: PropTypes.string,
    touched: PropTypes.bool,
    valid: PropTypes.bool,
    errorMessage: PropTypes.string,
    value: PropTypes.string,
    elementConfig: PropTypes.shape
  }).isRequired,
  inputChangedHandler: PropTypes.func.isRequired
};

export default withTranslation()(PasswordField);
