import { connect } from 'react-redux';

import {
  resetSendEmail,
  resetEmailIsSend,
  clearErrors,
} from 'store/modules/auth/actions';

import {
  getEmailIsSend,
  getIsLoading,
  getSessionError,
} from 'store/modules/auth/selectors';

import ResetSendEmail from 'views/Auth/ResetSendEmail';

const mapStateToProps = state => ({
  emailIsSend: Boolean(getEmailIsSend(state)),
  isLoading: getIsLoading(state),
  error: getSessionError(state),
});

const mapDispatchToProps = dispatch => ({
  resetSendEmail: payload => dispatch(resetSendEmail(payload)),
  resetEmailIsSend: payload => dispatch(resetEmailIsSend(payload)),
  clearErrors: () => dispatch(clearErrors()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetSendEmail);
