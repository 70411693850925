import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { saveSetupInfo } from "store/modules/jobs/actions";
import { getCompaniesOptionsForSelect, getPreviewError } from "store/modules/jobs/selectors";
import Setup from "components/Job/SetupComponent";

const mapStateToProps = state => ({
  companiesOptionsForSelect: getCompaniesOptionsForSelect(state),
  videoError: state.jobs.videoError,
  isLoadingCompanies: state.jobsSetup.isLoadingCompanies,
  companies: state.jobsSetup.companiesOptionsResults,
  error: getPreviewError(state)
});

const mapDispatchToProps = {
  saveSetupInfo
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Setup));
