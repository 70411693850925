import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const NoData = ({ t, noDataMessage }) => <div className="rt-noData">{noDataMessage || t("default.noData")}</div>;

NoData.defaultProps = {
  noDataMessage: ""
};

NoData.propTypes = {
  t: PropTypes.func.isRequired,
  noDataMessage: PropTypes.string
};

export default withTranslation()(NoData);
