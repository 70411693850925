import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import { SetupIcon, QuestionIcon, PreviewIcon, Message } from "mixins/svgIcons";

const Job = ({
  t,
  isJobSetupValid,
  isJobQuestionsValid,
  isJobTemplatesValid,
  currentStepActive,
  setCurrentStepActive
}) => (
  <div className="steps-wrapper">
    <div className={`steps ${currentStepActive}`}>
      <div className="steps__item">
        <button onClick={() => setCurrentStepActive("setup")} type="button" tabIndex="-1">
          <div className="steps__circle">
            <SetupIcon />
          </div>
        </button>

        <div className="steps__text">{t("breadcrumbs.setup")}</div>
      </div>

      <div className="steps__item">
        <button
          onClick={() => setCurrentStepActive("questions")}
          type="button"
          className={`steps__link ${isJobSetupValid ? "enabled" : "disabled"}`}
          tabIndex="-1"
        >
          <div className="steps__circle">
            <QuestionIcon />
          </div>
        </button>

        <div className="steps__text">{t("breadcrumbs.questions")}</div>
      </div>

      <div className="steps__item">
        <button
          onClick={() => setCurrentStepActive("templates")}
          type="button"
          className={`steps__link ${isJobQuestionsValid ? "enabled" : "disabled"}`}
          tabIndex="-1"
        >
          <div className="steps__circle">
            <Message />
          </div>
        </button>

        <div className="steps__text">{t("breadcrumbs.templates")}</div>
      </div>

      <div className="steps__item">
        <button
          onClick={() => setCurrentStepActive("preview")}
          type="button"
          className={`steps__link ${isJobTemplatesValid ? "enabled" : "disabled"}`}
          tabIndex="-1"
        >
          <div className="steps__circle">
            <PreviewIcon />
          </div>
        </button>

        <div className="steps__text">{t("breadcrumbs.publish")}</div>
      </div>
    </div>
  </div>
);

Job.propTypes = {
  isJobSetupValid: PropTypes.bool.isRequired,
  isJobQuestionsValid: PropTypes.bool.isRequired,
  isJobTemplatesValid: PropTypes.bool.isRequired,
  currentStepActive: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  setCurrentStepActive: PropTypes.func.isRequired
};

export default withTranslation()(Job);
