import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import TooltipSuccess from "components/Common/Tooltip/Success";
import moment from "moment";
import { DATE_FORMATS } from "configs/dates";

const SkeletonWrapper = ({
  t,
  isLoading,
  anonymizedDate,
  children,
  skeletonProps,
  skeletonWrapperProps
}) => {
  const itemRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const formattedDate = moment(String(anonymizedDate))
    .format(DATE_FORMATS.SPACE_SEPARATOR_LONG_MONTH);

  return (
    <>
      {
        isLoading || Boolean(anonymizedDate) ? (
          <div
            {...skeletonWrapperProps}
            ref={itemRef}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Skeleton {...skeletonProps} />
          </div>
        ) : children
      }

      <TooltipSuccess
        position="right"
        message={t("job.overview.anonymizedTooltip", { date: formattedDate })}
        target={itemRef.current}
        show={showTooltip && Boolean(anonymizedDate)}
      />
    </>
  );
};

SkeletonWrapper.defaultProps = {
  isLoading: true,
  skeletonProps: {},
  skeletonWrapperProps: {},
  anonymizedDate: "",
  children: null
};

SkeletonWrapper.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  children: PropTypes.node,
  skeletonProps: PropTypes.shape({}),
  skeletonWrapperProps: PropTypes.shape({}),
  anonymizedDate: PropTypes.string
};

export default withTranslation()(SkeletonWrapper);
