import React, { useCallback, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TooltipSuccess from "components/Common/Tooltip/Success";

import IconChromeSrc from "assets/images/icons/chrome.png";
import IconFirefoxSrc from "assets/images/icons/firefox.png";
import IconSafariSrc from "assets/images/icons/safari.png";
import IconEdgeSrc from "assets/images/icons/edge.png";

import { Copy } from "mixins/svgIcons";
import { copyTextFromButton } from "mixins/helpers";

const ChangeBrowserExployer = ({ t }) => {
  const [showTooltip, setShowTooltip] = useState(null);
  const [tootipText, setTooltipText] = useState("");

  const linkRef = useRef(null);
  const copyRef = useRef(null);

  const handleCopyText = useCallback(() => {
    setShowTooltip(false);
    setTooltipText(t("button.successTip"));
    copyTextFromButton(linkRef);
    setTimeout(() => setShowTooltip(true), 50);
  }, []);

  const handleShowTooltip = useCallback(() => {
    setTooltipText(t("button.infoClickTip"));
    setShowTooltip(true);
  }, []);

  const handleHideTooltip = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <div className="change-browser__change-browser">
      <header className="change-browser__header">
        <h1 className="change-browser__title">{t("notSupported.update")}</h1>

        <p className="change-browser__text">
          {t("notSupported.unsupported")}
          <br />
          {t("notSupported.options")}
        </p>

        <div className="change-browser__block">
          <div className="change-browser__icon-block">
            <img
              className="change-browser__icon"
              src={IconChromeSrc}
              alt="chrome_icon"
            />
            <span className="change-browser__icon-caption">
              {t("notSupported.chrome")}
            </span>
          </div>

          <div className="change-browser__icon-block">
            <img
              className="change-browser__icon"
              src={IconFirefoxSrc}
              alt="firefox_icon"
            />
            <span className="change-browser__icon-caption">
              {t("notSupported.firefox")}
            </span>
          </div>

          <div className="change-browser__icon-block">
            <img
              className="change-browser__icon"
              src={IconSafariSrc}
              alt="edge_icon"
            />
            <span className="change-browser__icon-caption">
              {t("notSupported.safari")}
            </span>
          </div>

          <div className="change-browser__icon-block">
            <img
              className="change-browser__icon"
              src={IconEdgeSrc}
              alt="edge_icon"
            />
            <span className="change-browser__icon-caption">
              {t("notSupported.edge")}
            </span>
          </div>
        </div>
      </header>

      <main className="change-browser__main">
        <p className="change-browser__text">{t("notSupported.link")}</p>

        <div
          aria-hidden="true"
          className="change-browser__text-block"
          onClick={handleCopyText}
          onMouseEnter={handleShowTooltip}
          onMouseLeave={handleHideTooltip}
          ref={copyRef}
        >
          <p
            className="change-browser__link"
            ref={linkRef}
          >
            {window.location.href}
          </p>

          <button
            className="button__without-styles"
            type="button"
          >
            <Copy svgIconClass="invite-info__copy-icon" />
          </button>

          {showTooltip && (
            <TooltipSuccess
              position="top"
              message={t(tootipText)}
              target={copyRef.current}
              show={showTooltip}
            />
          )}
        </div>
      </main>

      <footer className="change-browser__footer">
        <a
          href="https://support.willo.video"
          target="_blank"
          rel="noopener noreferrer"
          className="change-browser__link-button white main-link main-link__without-decoration"
        >
          <button
            type="button"
            className="
            button__purple
            button__purple-shadow
            button__without-border
            button__large
            button__large_round"
          >
            {t("button.visitSupport")}
          </button>
        </a>
      </footer>
    </div>
  );
};

ChangeBrowserExployer.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(ChangeBrowserExployer);
