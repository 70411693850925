import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import JobsInfo from "views/Jobs/Info";

import { fetchCurrentJob } from "store/modules/jobs/actions";

import { getIsJobLoading, getJobCurrent } from "store/modules/jobs/selectors";

const mapStateToProps = state => ({
  isLoading: getIsJobLoading(state),
  jobCurrent: getJobCurrent(state)
});

const mapDispatchToProps = {
  fetchCurrentJob
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(JobsInfo)
);
