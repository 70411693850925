import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TooltipInfo from "components/Common/Tooltip/Info";
import { Down, Duplicate, Up } from "components/Job/QuestionsComponent/svgIcons";
import Switcher from "components/Switcher";
import { RiDeleteBin5Line } from "react-icons/ri";
import FieldCollectionWrapper from "./FieldCollectionWrapper";
import InactiveFieldCollection from "./InactiveFieldCollection";
import ActiveFieldCollection from "./ActiveFieldCollection";

import "./styles.scss";

const FieldCollection = ({
  t,
  itemRef,
  isActive,
  order,
  disableUp,
  disableDown,
  inactiveComponent,
  activeComponent,
  handleDuplicate,
  onUp,
  onDown,
  handleDelete,
  switcherLabel,
  isSwitcherOn,
  handleSwitcherOn,
  handleSwitcherOff,
  isHovering,
  setIsHovering,
  isEditable,
  disabled,
  canDuplicate = true,
  className = ""
}) => (
  <div
    className={`field_collection-wrapper__item-container ${
      (isActive || isHovering) ? "active" : "inactive"
      } ${order} ${String(className)}
    `}
    style={disabled ? { cursor: "not-allowed" } : {}}
    ref={itemRef}
    role="presentation"
    onMouseEnter={() => setIsHovering(true)}
    onMouseLeave={() => setIsHovering(false)}
  >
    <InactiveFieldCollection
      isActive={isActive}
      component={inactiveComponent}
    />
    <ActiveFieldCollection
      isActive={isActive}
      component={activeComponent}
    />

    <div className={`field_collection-action__wrapper ${isHovering || isActive ? "visible" : "hidden"}`}>
      <div className="field_collection-action">
        {
          switcherLabel ? (
            <p className="candidate__button-switcher table">
              {switcherLabel}
              <span className="candidate__button-switcher-main do-not-activate field-optional">
                <Switcher
                  checked={isSwitcherOn}
                  handleOn={handleSwitcherOn}
                  handleOff={handleSwitcherOff}
                  className="do-not-activate field-optional"
                  disabled={disabled}
                />
              </span>
            </p>
          ) : null
        }

        {
          canDuplicate ? (
            <TooltipInfo
              element={null}
              position="bottom"
              message={t("job.questions.duplicate.tooltip")}
              className={`do-not-activate field-duplicate ${!isEditable ? "disabled" : ""}`}
            >
              <button
                onClick={() => {
              if (isEditable) handleDuplicate();
            }}
                className={`button__without-styles question__button-duplicate do-not-activate field-duplicate ${!isEditable ? "disabled" : ""}`}
                type="button"
              >
                <Duplicate svgClassName={`do-not-activate field-duplicate ${!isEditable ? "disabled" : ""}`} />
              </button>
            </TooltipInfo>
          ) : null
        }

        {
          order !== 1 && isEditable ? (
            <TooltipInfo
              element={null}
              position="bottom"
              message={t("button.delete")}
              className="do-not-activate field-delete"
            >
              <button
                type="button"
                className="question__button-delete button__without-styles do-not-activate field-delete"
                onClick={handleDelete}
              >
                <RiDeleteBin5Line className="do-not-activate field-delete" size={20} color="#212B46" />
              </button>
            </TooltipInfo>
          ) : null
        }
      </div>

      <div className="field_collection__ordering">
        <TooltipInfo
          element={null}
          position="bottom"
          message={t("button.up")}
          className={`field-up do-not-activate ${disableUp ? "disabled" : "" }`}
        >
          <button
            type="button"
            className={`button__without-styles field-up do-not-activate ${disableUp ? "disabled" : "" }`}
            onClick={() => {
            if (disableUp) return;

            onUp();
          }}
          >
            <Up svgClassName={`do-not-activate field-up ${disableUp ? "disabled" : "" }`} fill={disableUp ? "#BBC2C9" : "#595959"} />
          </button>
        </TooltipInfo>

        <TooltipInfo
          element={null}
          position="bottom"
          message={t("button.down")}
          className={`do-not-activate field-down ${ disableDown ? "disabled" : "" }`}
        >
          <button
            type="button"
            className={`button__without-styles field-down do-not-activate ${ disableDown ? "disabled" : "" }`}
            onClick={() => {
            if (disableDown) return;

            onDown();
          }}
          >
            <Down svgClassName={`do-not-activate field-down ${ disableDown ? "disabled" : "" }`} fill={disableDown ? "#BBC2C9" : "#595959"} />
          </button>
        </TooltipInfo>
      </div>
    </div>
  </div>
  );

FieldCollection.defaultProps = {
  itemRef: undefined,
  isActive: undefined,
  order: null,
  disableUp: undefined,
  disableDown: undefined,
  inactiveComponent: undefined,
  activeComponent: undefined,
  handleDuplicate: undefined,
  onUp: undefined,
  onDown: undefined,
  handleDelete: undefined,
  switcherLabel: undefined,
  isSwitcherOn: false,
  handleSwitcherOn: undefined,
  handleSwitcherOff: undefined,
  isHovering: undefined,
  setIsHovering: undefined,
  isEditable: undefined,
  disabled: undefined,
  canDuplicate: true,
  className: ""
};

FieldCollection.propTypes = {
  t: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  itemRef: PropTypes.any,
  isActive: PropTypes.bool,
  order: PropTypes.number,
  disableUp: PropTypes.bool,
  disableDown: PropTypes.bool,
  inactiveComponent: PropTypes.oneOfType([
    PropTypes.instanceOf(Component),
    PropTypes.shape({})
  ]),
  activeComponent: PropTypes.oneOfType([
    PropTypes.instanceOf(Component),
    PropTypes.shape({})
  ]),
  handleDuplicate: PropTypes.func,
  onUp: PropTypes.func,
  onDown: PropTypes.func,
  handleDelete: PropTypes.func,
  switcherLabel: PropTypes.string,
  isSwitcherOn: PropTypes.bool,
  handleSwitcherOn: PropTypes.func,
  handleSwitcherOff: PropTypes.func,
  isHovering: PropTypes.bool,
  setIsHovering: PropTypes.func,
  isEditable: PropTypes.bool,
  disabled: PropTypes.bool,
  canDuplicate: PropTypes.bool,
  className: PropTypes.string
};

export default FieldCollectionWrapper(withTranslation()(FieldCollection));
