import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { getDownloadHasError, getVideoArchives } from "store/modules/сandidates/selectors";
import { getToken } from "store/modules/auth/selectors";
import { updateVideoArchives, updateIsOpenVideoArchives, downloadAndDeleteLink, clearDownloadLinkError } from "store/modules/сandidates/actions";
import DownloadNotification from "components/DownloadNotification";

const mapStateToProps = state => ({
  isOpen: state.candidates.isOpen,
  videoArchives: getVideoArchives(state),
  isAuthenticated: Boolean(getToken(state)),
  hasDownloadError: getDownloadHasError(state)
});

const mapDispatchToProps = {
  updateVideoArchives,
  updateIsOpenVideoArchives,
  downloadAndDeleteLink,
  clearDownloadLinkError
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DownloadNotification)
);
