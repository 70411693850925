import React from "react";
import PropTypes from "prop-types";

const TotalTitle = ({ children }) => (
  <div className="progress__total-title">
    <h2>{children}</h2>
  </div>
);

TotalTitle.defaultProps = {
  children: undefined
};

TotalTitle.propTypes = {
  children: PropTypes.string
};

export default TotalTitle;
