import React from "react";
import PropTypes from "prop-types";

import { Close } from "mixins/svgIcons";

const Hamburger = ({ isOpen }) => (
  <div className={`hamburger ${isOpen && "hamburger__open"}`} type="button">
    {isOpen ? (
      <Close fill="#5A2AF1" />
    ) : (
      <>
        <span />
        <span />
        <span />
      </>
    )}
  </div>
);

Hamburger.defaultProps = {
  isOpen: undefined
};

Hamburger.propTypes = {
  isOpen: PropTypes.bool
};

export default Hamburger;
