/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { ANSWER_TYPES, CHECKBOXES_TYPE, IDV_TYPE, IDV_TYPE_LABELS, MULTIPLE_CHOICE_TYPE, RTW_TYPE } from "configs/jobs/constants";
import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";
import Select from "components/Forms/Select";
import { components } from "react-select";
import TooltipInfo from "components/Common/Tooltip/Info";
import { FileInfo } from "mixins/svgIcons";
import PropTypes from "prop-types";
import { getQuestionTypes } from "mixins/helpers";
import { Controller, useFormContext } from "react-hook-form";
import { Link } from "react-router-dom";
import { getJobCurrent } from "store/modules/jobs/selectors";
import { store } from "store";
import {
  setCurrentStepActive,
  setIdvActivationRedirect,
  setIsUpgradeComplete
} from "store/modules/jobs/actions";
import { nanoid } from "nanoid";

const AnswerType = ({
  t,
  isIdvLocked,
  isIdvNeedAdmin,
  isEditable,
  order,
  handleShowModal,
  handleSaveQuestions
}) => {
  const { control, watch, setValue } = useFormContext();
  const fields = watch("questions");
  const error = watch(`questions[${order - 1}].error`);

  const [idvSelected, setIdvSelected] = useState(false);

  const getLabel = useCallback(({ titleKey }) => t(titleKey), []);

  const SingleValueTypes = props => (
    <components.SingleValue {...props}>
      <div className="answer-select-wrapper">
        <i className={`fa xsm mr4 ml4 grey ${props.data.icon}`}>
          {props.data.reactIcon}
        </i>
        {t(props.data.titleKey)}

        {isIdvLocked && props?.data?.value === IDV_TYPE && (
          <i className="fa xsm mr4 ml4 grey fa-lock" />
        )}
      </div>
    </components.SingleValue>
  );

  const CustomOption = ({ innerProps, innerRef, isFocused, isfocused, label } = {}) => (
    <div
      {...innerProps}
      ref={innerRef}
      role="presentation"
      className="answer-option"
      style={{
        backgroundColor:
          isFocused || isfocused ? "#F4F4F5" : "inherit"
      }}
    >
      {label}
    </div>
  );

  const currentJob = getJobCurrent(store.getState());
  const idvRedirectUrl = window?.location?.pathname?.includes("jobs/edit/")
    ? window.location.pathname
    : `/jobs/edit/${currentJob?.key}`;

  return (
    <Controller
      name={`questions[${order - 1}].answer_type`}
      control={control}
      render={({ field: { onChange, value } }) => {
        const questionTypes = getQuestionTypes(fields, value);

        return (
          <Select
            label={t("job.questions.types.title")}
            value={questionTypes.find(option => option.value === value)}
            options={questionTypes}
            changed={val => {
              if (val.value !== IDV_TYPE && idvSelected) {
                setValue(`questions[${order - 1}].error`, undefined);
                setValue(`questions[${order - 1}].html_text`, "<p></p>");
                setValue(`questions[${order - 1}].text`, "");
                setIdvSelected(false);
              }

              // IDV
              if (val.value === IDV_TYPE) {
                setValue(
                  `questions[${order - 1}].html_text`,
                  `<p>${IDV_TYPE_LABELS.idv}</p>`
                );

                setValue(
                  `questions[${order - 1}].text`,
                  IDV_TYPE_LABELS.idv
                );

                handleShowModal(true, order - 1);
                setIdvSelected(true);
              }

              // RTW
              if (val.value === RTW_TYPE) {
                setValue(
                  `questions[${order - 1}].html_text`,
                  "<p>UK - Right to Work</p>"
                );

                setValue(`questions[${order - 1}].text`, "UK - Right to Work");
                handleShowModal(true, order - 1);
                setIdvSelected(true);
              }

              if ([CHECKBOXES_TYPE, MULTIPLE_CHOICE_TYPE].includes(value)) {
                return onChange(val.value);
              }

              if ([CHECKBOXES_TYPE, MULTIPLE_CHOICE_TYPE].includes(val.value)) {
                const options = [
                  {
                    key: nanoid(),
                    text: null,
                    autofocus: false,
                    is_correct: false,
                    is_touched: false
                  }
                ];
                setValue(`questions[${order - 1}].type_specific_data.answer_options`, options);
              }

              return onChange(val.value);
            }}
            getOptionLabel={getLabel}
            components={{ SingleValue: SingleValueTypes, Option: CustomOption }}
            disabled={!isEditable}
            tooltipMessage={t("job.questions.disabledTooltip")}
            isSearchable={false}
            isShowTooltip={!isEditable}
            topRightLabel={
              value === ANSWER_TYPES.file.value && (
                <span className="label__main">
                  <TooltipInfo
                    position="top"
                    message={
                      <>
                        {t("job.questions.types.tooltip")}
                        <a
                          href="#"
                          data-beacon-article-sidebar="605f65d6207e3b1188e20c54"
                          className="tooltip__link"
                        >
                          {t("job.questions.types.learnMore")}
                        </a>
                      </>
                    }
                    trigger={["click"]}
                    element={<FileInfo width="13" height="13" fill="#C5C4C4" />}
                    close
                  />
                </span>
              )
            }
            error={
              // eslint-disable-next-line no-nested-ternary
              error ? (
                isIdvNeedAdmin ? (
                  <>{t("errors.idvActivateNeedAdmin")}</>
                ) : (
                  <>
                    {t("errors.idvActivateError.0")}
                    <span className="link">
                      {t("errors.idvActivateError.1")}
                      <Link
                        to="/plans?utm_source=internal&utm_medium=user&utm_campaign=header_upgrade"
                        onClick={async () => {
                          await store.dispatch(setIsUpgradeComplete("pending"));
                          await store.dispatch(
                            setIdvActivationRedirect(idvRedirectUrl)
                          );
                          await store.dispatch(
                            setCurrentStepActive("questions")
                          );
                          handleSaveQuestions();
                        }}
                      >
                        {t("errors.idvActivateError.2")}
                      </Link>
                    </span>
                  </>
                )
              ) : (
                undefined
              )
            }
          />
        );
      }}
    />
  );
};

AnswerType.defaultProps = {
  t: undefined,
  isIdvLocked: undefined,
  isIdvNeedAdmin: undefined,
  isEditable: undefined,
  order: undefined,
  control: undefined,
  handleShowModal: undefined,
  handleSaveQuestions: undefined
};

AnswerType.propTypes = {
  t: PropTypes.func,
  isIdvLocked: PropTypes.bool,
  isIdvNeedAdmin: PropTypes.bool,
  isEditable: PropTypes.bool,
  order: PropTypes.number,
  control: PropTypes.shape({}),
  handleShowModal: PropTypes.func,
  handleSaveQuestions: PropTypes.func
};

export default withTranslation()(AnswerType);
