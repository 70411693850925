import { handleActions } from "redux-actions";
import { removeDuplicates } from "mixins/helpers";
import {
  activateKomboConnectResponse,
  connectKomboError,
  connectKomboResponse,
  deleteWebhookLogLoading,
  deleteWebhookLogResponse,
  getIntegrationsResponse,
  getPaginatedWebhooksLogsLoading,
  getPaginatedWebhooksLogsResponse,
  getWebhooksLogsLoading,
  getWebhooksLogsResponse,
  isMarketplaceLoading,
  resetMarketPlaceState,
  resetWebhooksLogs,
  setMarketplaceIcons,
  updateHeaderToggle
} from "./actions";

const defaultState = {
  isLoading: true,
  integrations: [],
  is_developer_tool: false,
  webhooks: {
    next: null,
    previous: null,
    results: [],
    isLoading: true,
    isDeleteLoading: false,
    error: null,
    isNextLoading: false
  },
  icons: []
};

export default handleActions(
  {
    [resetMarketPlaceState]: () => ({
      defaultState
    }),
    [isMarketplaceLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [getIntegrationsResponse]: (state, { payload }) => {
      const updatedIntegrations = payload.map(integration => {
        const plans = Object.assign([], integration.products.map(product => product.name)).join(", ");

        return { ...integration, plans };
      });


      return {
        ...state,
        integrations: updatedIntegrations
      };
    },
    [connectKomboResponse]: (state, { payload }) => {
      const { integrations } = state;

      const updatedIntegrations = integrations.map(integration => {
        if (integration.key === payload.integrationKey) {
          return { ...integration, link: payload.link };
        }

        return integration;
      });

      return {
        ...state,
        integrations: updatedIntegrations
      };
    },
    [connectKomboError]: (state, { payload }) => {
      const { integrations } = state;

      const updatedIntegrations = integrations.map(integration => {
        if (integration.key === payload.integrationKey) {
          return { ...integration, error: payload.error };
        }

        return integration;
      });

      return {
        ...state,
        integrations: updatedIntegrations
      };
    },
    [activateKomboConnectResponse]: (state, { payload }) => {
      const { integrations } = state;

      const updatedIntegrations = integrations.map(integration => {
        if (integration.key === payload.integrationKey) {
          return { ...integration, is_enabled: true };
        }

        return integration;
      });

      return {
        ...state,
        integrations: updatedIntegrations
      };
    },
    [updateHeaderToggle]: (state, { payload }) => ({
      ...state,
      is_developer_tool: payload
    }),
    [getWebhooksLogsLoading]: (state, { payload }) => ({
      ...state,
      webhooks: {
        ...state.webhooks,
        isLoading: payload
      }
    }),
    [getWebhooksLogsResponse]: (state, { payload }) => ({
      ...state,
      webhooks: {
        ...state.webhooks,
        ...payload
      }
    }),
    [deleteWebhookLogLoading]: (state, { payload }) => ({
      ...state,
      webhooks: {
        ...state.webhooks,
        isDeleteLoading: payload
      }
    }),
    [deleteWebhookLogResponse]: (state, { payload }) => {
      const newLogs = state.webhooks.results.filter(item => item.key !== payload.webhookKey);

      return {
        ...state,
        webhooks: {
          ...state.webhooks,
          results: newLogs,
          isDeleteLoading: false
        }
      };
    },
    [getPaginatedWebhooksLogsResponse]: (state, { payload }) => {
      const { webhooks } = state;

      let next = payload?.next;

      if (!payload?.results?.length) next = null;

      const results = removeDuplicates([...webhooks?.results, ...payload.results], "key");

      return {
        ...state,
        webhooks: {
          ...webhooks,
          next,
          results,
          isNextLoading: false
        }
      };
    },
    [getPaginatedWebhooksLogsLoading]: (state, { payload }) => ({
      ...state,
      webhooks: {
        ...state.webhooks,
        isNextLoading: payload
      }
    }),
    [resetWebhooksLogs]: state => ({
      ...state,
      webhooks: defaultState.webhooks
    }),
    [setMarketplaceIcons]: (state, { payload }) => ({
      ...state,
      icons: payload
    })
  },
  defaultState
);
