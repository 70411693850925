import moment from "moment";

import { DATE_FORMATS } from "configs/dates";

const expiration = (createdDate, videoExpirationDays) =>
  moment(createdDate).add(videoExpirationDays, "days");

export const handleExpirationDate = (createdDate, videoExpirationDays) =>
  expiration(createdDate, videoExpirationDays).format(
    DATE_FORMATS.SLASH_SEPARATOR
  );

export const handleExpirationLeft = (createdDate, videoExpirationDays) =>
  moment().to(expiration(createdDate, videoExpirationDays));

export const handleExpirationWithToday = (createdDate, videoExpirationDays) =>
  moment().isBefore(expiration(createdDate, videoExpirationDays));
