import { ALL_IDENTITY_VERIFICATION_TYPES } from "configs/jobs/constants";

/* eslint-disable import/prefer-default-export */
export const getCurrentIdvBundles = state => state.idv.idvBundles || [];

export const getIsIdvBundlesLoading = state => state.idv.isIdvBundlesLoading;

export const getIdvCheckoutStatus = state => state.idv.paymentStatus;

export const getCurrentAgencyIdvPurchased = state =>
  state.idv.agencyIdvPurchased || [];

export const isAgencyIdvPurchasedLoading = state =>
  state.idv.isAgencyIdvPurchasedLoading;

export const isAgencyIdvUsageLoading = state =>
  state.idv.isAgencyIdvUsageLoading;

export const getCurrentAgencyIdvUsage = state =>
  state.idv.agencyIdvUsage || {};

export const getHasIdvCheck = state => {
  const hasIdvCheck =
    state?.common?.commonJob?.questions?.some(
      question =>
      ALL_IDENTITY_VERIFICATION_TYPES.includes(question?.answer_type)
    ) || false;

  return hasIdvCheck;
};

export const getInterviewQuestions = state => state?.common?.commonJob?.questions ?? [];

export const getCurrentIdvPaygPrice = state => state.idv.paygPrice;

export const getCurrentIdvSession = state => state.idv.idvSession || {};

export const getIsPricesDataLoaded = state => state.idv.isPricesDataLoaded || false;
