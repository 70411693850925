import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import ProgressLine from "components/Common/Progress/Line";

import withLoader from "hoc/withLoader";

const Send = ({ t, invited = 0, received = 0, accepted = 0, rejected = 0 }) => (
  <div className="candidate-progress__send" style={{ paddingRight: 0 }}>
    <div className="candidate-progress__send-info">
      <ProgressLine>
        <ProgressLine.Left>
          {`${t("widgets.progressSend.invited")}:`}
        </ProgressLine.Left>
        <ProgressLine.Progress max={rejected + invited + accepted + received} now={invited} color="purple" />
        <ProgressLine.Right>{invited}</ProgressLine.Right>
      </ProgressLine>

      <ProgressLine>
        <ProgressLine.Left>
          {`${t("widgets.progressSend.review")}:`}
        </ProgressLine.Left>
        <ProgressLine.Progress max={rejected + invited + accepted + received} now={received} color="blue" />
        <ProgressLine.Right>{received}</ProgressLine.Right>
      </ProgressLine>

      <ProgressLine>
        <ProgressLine.Left>
          {`${t("widgets.progressSend.accepted")}:`}
        </ProgressLine.Left>
        <ProgressLine.Progress max={rejected + invited + accepted + received} now={accepted} color="green" />
        <ProgressLine.Right>{accepted}</ProgressLine.Right>
      </ProgressLine>

      <ProgressLine>
        <ProgressLine.Left>
          {`${t("widgets.progressSend.rejected")}:`}
        </ProgressLine.Left>
        <ProgressLine.Progress max={rejected + invited + accepted + received} now={rejected} color="red" />
        <ProgressLine.Right>{rejected}</ProgressLine.Right>
      </ProgressLine>
    </div>
  </div>
);

Send.defaultProps = {
  invited: undefined,
  received: undefined,
  accepted: undefined,
  rejected: undefined
};

Send.propTypes = {
  t: PropTypes.func.isRequired,
  invited: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  received: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  accepted: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rejected: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withLoader(withTranslation()(Send));
