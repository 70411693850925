/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import { Waypoint } from "react-waypoint";
import Spinner from "components/Common/Spinner";
import "react-table/react-table.css";

import NoData from "./NoData";

const Table = ({
  t,
  header,
  body,
  next,
  fetchLatestItems,
  isLoading,
  isLoadingTable,
  noDataMessage,
  ...rest
}) => {
  const fetchItems = useCallback(() => next && fetchLatestItems({ next }), [
    next,
    fetchLatestItems
  ]);

  return (
    <>
      <div className="_space" />
      <ReactTable
        minRows={1}
        columns={header}
        data={body}
        showPagination={false}
        resizable={false}
        filterable={false}
        sortable={false}
        pageSize={Number(body?.length)}
        NoDataComponent={props => (
          <NoData noDataMessage={noDataMessage} {...props} />
        )}
        {...rest}
      />
      {!isLoadingTable && (
        <>
          <Waypoint onEnter={fetchItems} />
          {isLoading && <Spinner />}
        </>
      )}
    </>
  );
};

Table.defaultProps = {
  header: undefined,
  body: undefined,
  next: undefined,
  fetchLatestItems: undefined,
  isLoading: false,
  isLoadingTable: false,
  noDataMessage: ""
};

Table.propTypes = {
  t: PropTypes.func.isRequired,
  header: PropTypes.arrayOf(PropTypes.object.isRequired),
  body: PropTypes.arrayOf(PropTypes.object.isRequired),
  next: PropTypes.string,
  fetchLatestItems: PropTypes.func,
  isLoading: PropTypes.bool,
  isLoadingTable: PropTypes.bool,
  noDataMessage: PropTypes.string
};

export default Table;
