/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./styles.scss";
import CandidateIdvCheck from "./CandidateIdvCheck";
import CandidateIdvOverview from "./CandidateIdvOverview";

const IDVCheckItem = ({
  t,
  question,
  questionNumber,
  size,
  textSize,
  questionTextProps,
  thinkingTime,
  spentTime,
  idvCheckData,
  currentQuestion
}) => {
  if (idvCheckData?.isLoading) {
    return null;
  }

  if (idvCheckData?.isRecruiter && !idvCheckData?.isLoading) {
    return (
      <CandidateIdvCheck
        t={t}
        question={question}
        questionNumber={questionNumber}
        size={size}
        textSize={textSize}
        questionTextProps={questionTextProps}
        thinkingTime={thinkingTime}
        spentTime={spentTime}
        idvCheckData={idvCheckData}
        currentQuestion={currentQuestion}
      />
    );
  }

  return (
    <CandidateIdvOverview
      t={t}
      question={question}
      questionNumber={questionNumber}
      size={size}
      textSize={textSize}
      questionTextProps={questionTextProps}
      thinkingTime={thinkingTime}
      spentTime={spentTime}
      idvCheckData={idvCheckData}
    />
  );
};

IDVCheckItem.defaultProps = {
  history: null,
  question: {},
  questionNumber: "",
  size: "",
  textSize: "",
  answer: {},
  questionTextProps: null,
  memorizedCompanyInfo: {},
  thinkingTime: 0,
  spentTime: 0
};

IDVCheckItem.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    length: PropTypes.number,
    push: PropTypes.func
  }),
  question: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    text: PropTypes.string,
    key: PropTypes.string
  }),
  questionNumber: PropTypes.number,
  size: PropTypes.string,
  textSize: PropTypes.string,
  answer: PropTypes.shape({
    html_text: PropTypes.string,
    text: PropTypes.string
  }),
  questionTextProps: PropTypes.shape({}),
  memorizedCompanyInfo: PropTypes.shape({
    button_color: PropTypes.string
  }),
  thinkingTime: PropTypes.number,
  spentTime: PropTypes.number,
  idvCheckData: PropTypes.shape({
    document_type: PropTypes.string,
    issuing_country: PropTypes.string,
    share_code: PropTypes.string,
    share_code_skipped: PropTypes.bool,
    isRecruiter: PropTypes.bool,
    isLoading: PropTypes.bool,
    is_idv_skipped: PropTypes.bool
  }).isRequired,
  currentQuestion: PropTypes.shape({
    question: PropTypes.shape({
      html_text: PropTypes.string
    })
  }).isRequired
};

export default withTranslation()(IDVCheckItem);
