/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { Container, Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import PageWrapper from "hoc/PageWrapper";
import Header from "containers/Common/HeaderContainer";
import animationData from "assets/images/components/onboarding/right-image.json";

import "./styles.scss";
import useRetryByTimes from "hooks/useRetry";
import { FileError } from "mixins/svgIcons";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const IdvCheckout = ({
  t,
  getIdvPaymentStatus,
  match: {
    params: { id = null, type = null }
  }
}) => {
  const [error, setErrorMessage] = useState(null);
  const fetchIdvPaymentStatus = () => getIdvPaymentStatus({ id, type });

  const handleErrorMessage = () => setErrorMessage("Payment failed.");

  useRetryByTimes(fetchIdvPaymentStatus, handleErrorMessage, 5, 2000);

  return (
    <PageWrapper header={<Header />}>
      <Container className="idv-checkout-session__container">
        <Row>
          <Col>
            <Lottie options={defaultOptions} height={200} width={250} />
            { error ? (
              <div className="error-container">
                <FileError />
                <span className="error-container-text">{t("connect.idvConnect.modal.paymentFailed")}</span>
              </div>
            ) : (
              <h1 className="n-font-giant n-text-center idv-checkout-session__header">
                {t("connect.idvConnect.checkoutSession.header")}
              </h1>
            ) }

            {
              error ? (
                <p className="n-font-medium n-text-center idv-checkout-session__text">
                  {t("connect.idvConnect.checkoutSession.errorRedirect.0")}
                  <Link className="n-font-medium n-text-center idv-checkout-session__link" to="/connect">
                    {t("connect.idvConnect.checkoutSession.errorRedirect.1")}
                  </Link>
                  {t("connect.idvConnect.checkoutSession.errorRedirect.2")}
                </p>
              ) : (
                <p className="n-font-medium n-text-center idv-checkout-session__text">
                  {t("connect.idvConnect.checkoutSession.subtitle.0")}
                  <Link className="n-font-medium n-text-center idv-checkout-session__link" to="/connect">
                    {t("connect.idvConnect.checkoutSession.subtitle.1")}
                  </Link>
                </p>
              )
            }
          </Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

IdvCheckout.propTypes = {
  t: PropTypes.func.isRequired,
  getIdvPaymentStatus: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string
    })
  }).isRequired
};

export default withTranslation()(IdvCheckout);
