import React from "react";

import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "hoc/PageWrapper";
import Header from "components/Common/Header/Main";

const TermsAndConditins = () => (
  <PageWrapper header={<Header />}>
    <Container>
      <Row className="justify-content-md-center">
        <Col md={8}>
          <div className="document">
            <h1>WILLO PRIVACY POLICY</h1>

            <p>
              Willo Technologies Limited realise that our Willo website,
              <a
                href="https://www.willotalent.com"
                _target="blank"
                rel="noopener noreferrer"
              >
                https://www.willotalent.com
              </a>
              , and any related services we offer, including mobile applications
              (collectively, the “Platform”), can only work if we build a
              relationship of trust with our users. We control the purpose for
              which, and the manner in which, Personal Information (defined
              below) about you is processed and we are therefore the data
              controller under applicable laws. Our contact details are set out
              at the end of this privacy policy (“Privacy Policy”).
            </p>

            <p>
              Willo is a cutting edge video recruitment software as a service
              (“SaaS”) platform that utilises video and audio technology to
              assist Recruitment Agencies (“Agencies”) and Recruiting Companies
              (“Employers”) recruit and build great teams quickly and
              efficiently as well as helping individual candidates succeed in
              their reaching their employment goals.
            </p>

            <p>
              This Privacy Policy outlines the categories of Personal
              Information the Platform collects from you, that you provide to
              us, or that you authorise to be provided to us and for which
              purposes your Personal Information might be used.
            </p>

            <p>
              It is important that you read this Privacy Policy together with
              any other Willo terms of use we may provide on specific occasions
              when we are collecting or processing personal data about you so
              that you are fully aware of how and why we are using your data
            </p>

            <h1>Key Terms</h1>

            <h2>Personal Information</h2>

            <p>
              So we are clear about the terminology we are using, when we use
              the phrase “Personal Information” in this Privacy Policy, we mean
              contact information (e.g., name, address, email address, and
              telephone number) and any other information about an identified or
              identifiable individual. Personal Information includes information
              concerning the health, age, gender, marital status, education and
              occupation of an individual.
            </p>

            <p>
              When we use the phrase “Anonymous Information” in this Privacy
              Policy, we mean information rendered anonymous in such a way that
              it cannot or can no longer be used to personally identify an
              individual.
            </p>

            <h2>Information Collection</h2>

            <p>
              We require that you submit certain Personal Information about
              yourself, including your user name and email address (“User
              Information”), when you register for the Platform. This
              information is required for you to be able to use the Platform and
              therefore if you do not provide this information you will not be
              able to use the Platform. You may submit this User Information
              yourself by registering electronically.
            </p>

            <p>
              In the course of registering for or using the Platform, we may
              prompt you to provide additional information about yourself
              including your year of birth, alternate email addresses, telephone
              numbers, your residential address, your city, county or country of
              residence, (also, “User Information”). When you communicate with
              us through our Platform, we may collect and store any information
              that is contained in your communications with us. Willo does not
              automatically include or display User Information in your shared
              profile data.
            </p>

            <p>
              We might also receive Personal Information related to you from
              Agencies or Employers that have been gathered in preparation of or
              during a video interview or any other communication in which you
              have been involved.
            </p>

            <h2>Purposes of use of Personal Information</h2>

            <p>
              Willo may use your Personal Information based on your consent (i)
              to provide you with the Platform (including related services);
              (ii) to improve the quality of the Platform; (iii) to fulfil any
              request you make; (iv) to communicate with you; (v) to contact you
              regarding other products and services we offer if you have
              requested such information; (vi) or as otherwise directed by you.
            </p>

            <p>
              If you have requested us to provide you with information regarding
              other products and services we offer, we may process part of your
              Personal Information automatically with the objective of assessing
              which products and services may be interesting for you.
            </p>

            <p>
              To the extent necessary we may also process your Personal
              Information so as to protect legitimate interests of our own and
              of third parties such as (i) to resolve disputes and/or
              troubleshoot problems and (ii) to verify your compliance with your
              obligations in our Terms and Conditions or other Willo policies.
            </p>

            <p>
              From time to time, you may be offered the opportunity to respond
              to requests for additional information from Willo, including but
              not limited to surveys, polls, questionnaires, and feedback.
              Sometimes, these requests will be serviced by a third party vendor
              who provides its services through Willo but may host its service
              separately. In such cases, we will not share your Personal
              Information with the third party vendor. De-identified data
              collected in these instances, including responses to demographic
              questions, will be processed by the respective third party vendor
              for analytic purposes only, and the third party vendor’s Privacy
              Policy and Terms of Use will be observed.
            </p>

            <p>
              When we communicate with you regarding other products and services
              we offer or regarding other studies you may be qualified for, you
              will be given the opportunity in each communication to unsubscribe
              and prevent future communications of that sort. Furthermore, you
              may opt-out of such communications by selecting the “unsubscribe”
              hyperlink, which is located at the bottom of all emails sent from
              Willo. Alternatively, you can email
              <a href="mailto:support@willotalent.com">
                support@willotalent.com.
              </a>
              Once you have communicated to us that you wish to opt-out of
              future communications of that sort, we will endeavour to remove
              you from such mailing lists within 5 working days.
            </p>

            <p>
              Additionally, we may use your Personal Information to create
              Anonymous Information including for use in market research, if you
              agree to let us do so.
            </p>

            <h1>Recipients of Personal Information</h1>

            <p>
              Willo uses the Platform to facilitate the recruitment process.
              This involves the receiving of and sharing of Personal Information
              with Agencies and Employers. As set out above, our use and
              disclosure of this information will be governed by this Privacy
              Policy. Deletion of individual and specific data in the Platform
              will have no impact on how Agencies and Employers will handle
              individual related data of you in the recruitment process. This is
              governed by separate terms and consents in which Willo has no
              involvement.
            </p>

            <p>
              We may also disclose your Personal Information: (a) to third party
              vendors who help us provide the Platform; (b) to third parties to
              whom you ask us to send Personal Information, including other
              users to whom you authorise us to provide certain details of your
              Personal Information; (c) as required by law, such as to comply
              with a citation, or similar legal process when we believe in good
              faith that disclosure is necessary to protect our rights, protect
              your safety or the safety of others, investigate fraud, or respond
              to a government request; (d) to a parent company, any
              subsidiaries, joint ventures, or other companies under common
              control with us (collectively, “Affiliates”), in the event we have
              such Affiliates now or in the future, in which case we will
              require our Affiliates to honour this Privacy Policy, or (e) to a
              company that merges with us, acquires us, or purchases our assets,
              or a successor in interest in insolvency, in which case such
              company may continue to process your Personal Information as set
              forth in this Privacy Policy.
            </p>

            <p>
              We will share your Personal Information with third parties only in
              the ways that are described in this Privacy Policy. We do not
              otherwise share or sell your Personal Information with or to third
              parties. We may use and disclose Anonymous Information without
              restriction.
            </p>

            <h1>Your Rights</h1>

            <p>
              You have the following rights, which you can exercise free of
              charge:
            </p>

            <p>
              For further information on each of those rights, including the
              circumstances in which they apply, please contact us or see the
              Guidance from the UK Information Commissioner’s Office (ICO) on
              individuals’ rights under the General Data Protection Regulation.
              If you would like to exercise any of those rights, please: email,
              call or write to us — see below: ‘How to contact us’; and let us
              have enough information to identify you (eg your full name,
              address and invoice or estimate reference number);
            </p>

            <p>
              let us have proof of your identity and address (a copy of your
              driving licence or passport and a recent utility or credit card
              bill); and let us know what right you want to exercise and the
              information to which your request relates.
            </p>

            <h1>Information Security</h1>

            <p>
              We have appropriate security measures to prevent Personal
              Information from being accidentally lost, or used or accessed
              unlawfully. We limit access to your Personal Information to those
              who have a genuine business need to access it. Those processing
              your information will do so only in an authorised manner and are
              subject to a duty of confidentiality. We also have procedures in
              place to deal with any suspected data security breach. We will
              notify you and any applicable regulator of a suspected data
              security breach where we are legally required to do so. If you
              want detailed information from Get Safe Online on how to protect
              your information and your computers and devices against fraud,
              identity theft, viruses and many other online problems, please
              visit
              <a
                href="www.getsafeonline.org"
                target="_blank"
                rel="noopener noreferrer"
              >
                www.getsafeonline.org.
              </a>
              Get Safe Online is supported by HM Government and leading
              businesses.
            </p>

            <h1>Cookies & Other Anonymous Information</h1>

            <p>
              As you use the Platform, certain Anonymous Information may be
              collected and stored via cookies and similar technologies, such as
              your Internet protocol address, domain names, browser type,
              click-stream data, and access times.
            </p>

            <p>
              A cookie is a small text file that is stored on a user’s computer
              for record-keeping purposes. We use cookies on this Platform. We
              do not link the information we store in cookies to any Personal
              Information you submit while on our site without your express
              consent.
            </p>

            <p>
              We may use both session ID cookies and persistent cookies. We use
              session cookies to make it easier for you to navigate our site. A
              session ID cookie expires when you close your browser. A
              persistent cookie remains on your hard drive for an extended
              period of time. You can remove persistent cookies by following
              directions provided in your Internet browser’s “help” file. We may
              also set persistent cookies to store your passwords, so you do not
              have to enter it more than once. Persistent cookies will enable us
              to track and target the interests of our users to enhance the
              experience on our site.
            </p>

            <p>
              We may use the Anonymous Information we collect from you to
              customise the content and layout of the Platform for you and
              improve our internal operations and the content of our Platform.
              With your opt-in consent, we may combine this Anonymous
              Information with your Personal Information such that the
              information is no longer anonymous.
            </p>

            <h1>Third Party Websites and Services</h1>

            <p>
              The Platform may contain links to websites hosted and operated by
              companies other than us (“Third Party Websites”) to which you can
              export (part of) your Personal Information on the Platform. We do
              not disclose your Personal Information to these Third Party
              Websites without your explicit consent. Note that any information
              you disclose to Third Party Websites is no longer under our
              control and no longer subject to this Privacy Policy. You should
              review the privacy policy practices of any such Third Party
              Website to understand how that Third Party Website collects and
              uses your Personal Information should you have decided to disclose
              your Personal Information to them. We are not responsible for the
              content or performance of these Third Party Websites. We are in no
              way responsible or liable for the manner in which a Third Party
              Website treats any Personal Information that you choose to provide
              to such a Third Party Website and use of Third Party Websites is
              strictly at your own risk. You can stop sharing your Personal
              Information by emailing us at
              <a href="mailto:support@willotalent.com">
                support@willotalent.com.
              </a>
            </p>

            <p>
              We may use a third party tracking service such as Google Analytics
              to track and analyse Anonymous Information from users of the
              Platform. Such third parties may use cookies to help track user
              behaviour. The use of cookies by third parties is not covered by
              this Privacy Policy. We do not have access or control over these
              cookies.
            </p>

            <h1>Notification of Privacy Policy Modifications</h1>

            <p>
              We may update this Privacy Policy to reflect changes to our
              information practices. If we make any material changes we will
              notify you by email (sent to the email address specified in your
              account) or by means of a notice on this Platform prior to the
              change becoming effective. We encourage you to periodically review
              this page for the latest information on our privacy practices.
              Continued use of our Platform following notice of such changes
              will indicate your acknowledgement of such changes and agreement
              to be bound by the terms and conditions of such changes.
            </p>

            <p>
              Any changes to this Privacy Policy may affect our use or
              disclosure of Personal Information collected prior to the changes.
              If you do not agree to any of the changes, you must notify us
              prior to the effective date of the changes that you wish to
              terminate your account with us. Continued use of our Platform
              following such notice of such changes shall indicate your
              acknowledgement of such changes and agreement to be bound by the
              terms and conditions of such changes.
            </p>

            <h1>Retention Period</h1>

            <p>
              The length of time for which Willo retains Personal Information
              and other information will vary depending on the nature of the
              services for which the information has been collected and the
              nature of the information.
            </p>

            <p>
              Although the Retention Period may extend beyond the last
              communication between you and the Platform, it will be retained
              for only so long as necessary for Willo to respond to any issues
              arising from your relationship with Willo.
            </p>

            <p>
              When Willo no longer needs or requires your Personal Information
              in the normal course of business operations and/or due diligence,
              or where a business customer no longer needs or requires your
              Personal Information and either consents or requests to the
              deletion of such content, Willo will destroy, delete, erase or
              convert information to an anonymous form without notice to you.
            </p>

            <p>
              Depending on the package of services provided, Willo may delete
              information including, but not limited to, stored messages,
              interviews, videos, audio, other content, and records of your
              account without notice to you.
            </p>

            <p>
              Should your account be terminated, Willo may immediately and
              permanently delete all stored messages, interviews, videos, audio,
              other content, and records of your account without notice to you.
            </p>

            <p>
              The foregoing being said, the employer or staffing company for
              whom you have provided information may be required to retain
              information beyond Willo’s direct use of such information. Because
              of this, Willo must defer to the specific Agency’s or Employer’s
              retention requirements.
            </p>

            <h1>Children</h1>

            <p>
              The Platform is not directed to children and children are not
              eligible to use our Platform. Protecting the privacy of children
              is very important to us. We do not collect or maintain Personal
              Information from people we actually know are under 18 years of
              age, and no part of our Platform is designed to attract people
              under 18 years of age. If we later learn that a user is under 18
              years of age, we will take steps to remove that user’s information
              from our databases and to prevent the user from utilising the
              Platform.
            </p>

            <h1>How to contact us</h1>

            <p>
              If you have questions or comments about this Privacy Policy please
              contact us at:
            </p>

            <p>
              Willo Technologies Limited
              <br />
              1/1 69 Minerva Street,
              <br />
              Glasgow,
              <br />
              United Kingdom, G3 8LE
              <br />
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  </PageWrapper>
);

export default TermsAndConditins;
