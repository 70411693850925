import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  saveCurrentQuestion,
  saveAnswer,
  savePreviousQuestion,
  clearPreviousQuestion,
  clearUploadInfo,
  clearErrors
} from "store/modules/сandidates/actions";
import { setModal } from "store/modules/modals/actions";
import {
  getQuestionsForCandidate,
  getCurrentQuestion,
  getCandidateJobInfo,
  getCandidateJobInfoCompany,
  getCurrentCandidateId
} from "store/modules/сandidates/selectors";
import VideoQuestionsTest from "views/Candidate/Video/Test";

const mapStateToProps = state => ({
  questionsForCandidate: getQuestionsForCandidate(state),
  questionCurrentCashed: getCurrentQuestion(state),
  jobInfo: getCandidateJobInfo(state),
  jobInfoCompany: getCandidateJobInfoCompany(state),
  currentCandidateId: getCurrentCandidateId(state)
});

const mapDispatchToProps = {
  setModal,
  saveCurrentQuestion,
  saveAnswer,
  savePreviousQuestion,
  clearPreviousQuestion,
  clearUploadInfo,
  clearErrors
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoQuestionsTest)
);
