import moment from "moment";

import { DATE_FORMATS } from "configs/dates";

export const selectValue = (options, value) =>
  options.find(option => option.value === value);

export const currentDay = moment().format(DATE_FORMATS.HYPHEN_SEPARATOR);

// Endpoint which takes data exclude end date, that's why we need add 1 extra day
export const nextDay = moment()
  .add(1, "d")
  .format(DATE_FORMATS.HYPHEN_SEPARATOR);

export const subtractDaysFromNow = amount =>
  moment()
    .subtract(amount, "d")
    .format(DATE_FORMATS.HYPHEN_SEPARATOR);

export const subtractMonthFromtNowStart = amount =>
  moment()
    .subtract(amount, "months")
    .startOf("month")
    .format(DATE_FORMATS.HYPHEN_SEPARATOR);

export const subtractMonthFromtNowEnd = amount =>
  moment()
    .subtract(amount, "months")
    .endOf("month")
    .format(DATE_FORMATS.HYPHEN_SEPARATOR);

const formRangeString = (start, end) => `start_date=${start}&end_date=${end}`;

export const selectListDatesWithTranslations = t => {
  if (!t) return [];

  return [
    {
      label: t("days.today"),
      value: formRangeString(currentDay, nextDay)
    },
    {
      label: t("days.yesterday"),
      value: formRangeString(subtractDaysFromNow(1), currentDay)
    },
    {
      label: t("days.7-days"),
      value: formRangeString(subtractDaysFromNow(7), nextDay)
    },
    {
      label: t("days.30-days"),
      value: formRangeString(subtractDaysFromNow(30), nextDay)
    },
    {
      label: t("days.thisMonth"),
      value: formRangeString(subtractMonthFromtNowStart(0), nextDay)
    },
    {
      label: t("days.lastMonth"),
      value: formRangeString(
        subtractMonthFromtNowStart(1),
        subtractMonthFromtNowEnd(1)
      )
    }
  ];
};
