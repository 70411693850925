import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { fetchPublicBulkCandidateInfo, clearErrors, fetchPublicBulkInfo } from "store/modules/public/actions";
import { getPublicUserInfo } from "store/modules/public/selectors";
import CandidatePublic from "views/Candidate/BulkPublic";

const mapStateToProps = state => ({
  publicUserInfo: getPublicUserInfo(state),
  publicBulk: state.publicPage.publicBulk || [],
  isLoading: state.publicPage.isPublicInfoLoading,
  currentCompany: state.publicPage.currentCompany
});

const mapDispatchToProps = {
  fetchPublicBulkCandidateInfo,
  fetchPublicBulkInfo,
  clearErrors
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CandidatePublic)
);
