import { LinesIcon } from "mixins/svgIcons";
import React from "react";

import { useTranslation, withTranslation } from "react-i18next";
import useTranscrpitionStore from "store/transcriptionStore.ts";

const NoTranscriptionsResults = ({ retry }) => {
  const { searchString } = useTranscrpitionStore();
  const { t } = useTranslation();

  const handleTranscribeNow = () => {
    retry(searchString);
  };

  return (
    <div className="no-transcriptions__container pt-12 md:pt-0">
      <div className="no-transcriptions__icon">
        <LinesIcon />
      </div>

      <span className="no-transcriptions__text">
        {t("transcriptions.results.sorry")}
      </span>

      <button
        type="button"
        onClick={handleTranscribeNow}
        className="no-transcriptions__transcribe button__without-styles"
        aria-label="Try Again"
      >
        {t("transcriptions.results.tryAgain")}
      </button>
    </div>
  );
};


export default withTranslation()(NoTranscriptionsResults);
