import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { JOB_STATUSES } from "configs/jobs/constants";

const Job = ({ t, isLive = false, prefix = "" }) => (
  <span className={`status-job ${isLive ? JOB_STATUSES.LIVE : JOB_STATUSES.CLOSED} ${prefix}`}>
    {t(`job.table.${isLive ? JOB_STATUSES.LIVE : JOB_STATUSES.CLOSED}`)}
  </span>
);

Job.defaultProps = {
  isLive: false,
  prefix: undefined
};

Job.propTypes = {
  t: PropTypes.func.isRequired,
  isLive: PropTypes.bool,
  prefix: PropTypes.string
};

export default withTranslation()(Job);
