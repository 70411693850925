import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import PageWrapper from "hoc/PageWrapper";
import withLoaderScreen from "hoc/withLoaderScreen";

import "./styles.scss";

const ConnectionLost = ({ t, refreshStatus }) => {
  const [refreshSeconds, setRefreshSeconds] = useState(5);
  const timer = useRef();

  useEffect(() => {
    if (refreshSeconds > 0) {
      timer.current = setTimeout(() => setRefreshSeconds(refreshSeconds - 1), 1000);
    } else {
      refreshStatus();
      setRefreshSeconds(5);
    }

    return () => clearInterval(timer.current);
  }, [refreshSeconds, refreshStatus]);

  return (
    <PageWrapper>
      <div className="maintenance__wrapper">
        <div className="maintenance">
          <div style={{ margin: "auto" }}>
            <h3 className="maintenance__title">
              {t("connectionLost.header")}
            </h3>

            <p className="maintenance__text">
              {t("connectionLost.text.0")}
              <br />
              {t("connectionLost.text.1")}
            </p>

            <p className="maintenance__text-refresh">
              {t("connectionLost.refreshing", { seconds: refreshSeconds })}
            </p>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

ConnectionLost.propTypes = {
  t: PropTypes.func.isRequired,
  refreshStatus: PropTypes.func.isRequired
};

export default withLoaderScreen(withTranslation()(ConnectionLost));
