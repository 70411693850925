import React from "react";
import PropTypes from "prop-types";

const Left = ({ width = "md", children }) => (
  <span className={`progress-line__left ${width}`}>{children}</span>
);

Left.defaultProps = {
  width: undefined,
  children: undefined,
};

Left.propTypes = {
  width: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default Left;
