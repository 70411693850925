export const MAC_OS_BROWSERS = {
  Chrome: 52,
  Edge: 12,
  Firefox: 36
};

export const WINDOWS_BROWSERS = {
  Chrome: 52,
  Edge: 12,
  Firefox: 36
};

export const LINUX_BROWSERS = {
  Chrome: 52,
  Firefox: 36
};

export const GENTOO_BROWSERS = {
  Chrome: 52,
  Firefox: 36
};

export const CHROMIUM_BROWSERS = {
  Chrome: 52,
  Firefox: 36
};
