import { EditorState } from "draft-js";

export default {
  id: {
    value: "",
    valid: true,
    touched: false
  },
  title: {
    label: "jobTitle",
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "jobTitlePlaceholder"
    },
    value: "",
    validation: {
      required: true
    },
    prefix: "overview",
    errorMessage: "jobTitleError",
    valid: false,
    touched: false
  },
  salary: {
    label: "jobSalaryTitle",
    supLabel: "inputLabelOptional",
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "jobSalaryPlaceholder"
    },
    value: "",
    validation: {
      required: false
    },
    prefix: "overview",
    errorMessage:
      "Not a valid salary number, please only use numbers 0 to 9. No symbols or alphanumeric characters.",
    valid: true,
    touched: false
  },
  company: {
    label: "jobCompanyTitle",
    elementType: "select",
    elementConfig: {
      type: "text",
      placeholder: "jobCompanyPlaceholder"
    },
    value: "",
    options: [],
    validation: {
      required: true
    },
    addMoreBtn: true,
    prefix: "overview",
    valid: false,
    touched: false
  },
  video: {
    label: "jobVideoTitle",
    infoLabel: "jobVideoTip",
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "jobVideoPlaceholder"
    },
    value: "",
    validation: {
      required: false,
      isVideoUrl: true
    },
    prefix: "overview",
    errorMessage: "jobVideoError",
    valid: true,
    touched: false,
    className: "video"
  },
  description: {
    label: "jobDescriptionTitle",
    supLabel: "inputLabelOptional",
    elementType: "texteditor",
    value: EditorState.createEmpty(),
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  isOwn: {
    value: false,
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  ownVideo: {
    value: "",
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  ownVideoUrl: {
    value: "",
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  },
  blob: {
    value: null,
    validation: {
      required: false
    },
    prefix: "overview",
    valid: true,
    touched: false
  }
};
