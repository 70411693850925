import React from "react";
import PropTypes from "prop-types";

const VideoStatus = ({ label, color }) => (
  <p className="player__recording-top-panel-desktop">
    <span className={`player__recording-spot ${color}`} />
    <span>{label}</span>
  </p>
);

VideoStatus.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default VideoStatus;
