import { useEffect } from "react";
import PropTypes from "prop-types";

const CountdownTimer = ({ setCashedElapsingTime }) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCashedElapsingTime(seconds => {
        if (seconds === 1000) {
          clearInterval(intervalId);
        }
        return seconds - 1000;
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return null;
};

CountdownTimer.defaultProps = {
  setCashedElapsingTime: undefined
};

CountdownTimer.propTypes = {
  setCashedElapsingTime: PropTypes.func
};

export default CountdownTimer;
