import { handleActions } from "redux-actions";

import {
  fetchLeverConfigurationResponse,
  isLeverLoading,
  isLeverStagesLoading,
  fetchLeverStagesResponse,
  loginLeverError,
  setSavedStages,
  fetchLeverStagesError
} from "./actions";

const defaultState = {
  isLeverLoading: false,
  configuration: {},
  stages: [],
  savedStages: false,
  error: null
};

export default handleActions(
  {
    [fetchLeverConfigurationResponse]: (state, { payload }) => ({
      ...state,
      configuration: payload
    }),
    [fetchLeverStagesResponse]: (state, { payload }) => ({
      ...state,
      stages: payload
    }),
    [fetchLeverStagesError]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [isLeverLoading]: (state, { payload }) => ({
      ...state,
      isLeverLoading: payload
    }),
    [isLeverStagesLoading]: (state, { payload }) => ({
      ...state,
      isLeverStagesLoading: payload
    }),
    [loginLeverError]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [setSavedStages]: (state, { payload }) => ({
      ...state,
      savedStages: payload
    })
  },
  defaultState
);
