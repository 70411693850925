/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getCandidateAnswers, getCandidateIdvResponse } from "store/modules/jobs/selectors";
import { connect } from "react-redux";
import { setCurrentQuestionAnswer } from "store/modules/jobs/actions";
import { store } from "store";
import ReactHtmlParser from "react-html-parser";
import { ALL_IDENTITY_VERIFICATION_TYPES, AUDIO_TYPE, CHECKBOXES_TYPE, FILE_TYPE, MULTIPLE_CHOICE_TYPE, TEXT_TYPE, VIDEO_TYPE } from "configs/jobs/constants";
import classNames from "classnames";
import DownloadThumbnailIcon from "assets/images/candidate/DownloadThumbnailIcon";
import useWindowResize from "hooks/useWindowResize.tsx";
import { getProfileInfo } from "store/modules/profile/selectors";
import useMediaPlayerStore from "store/mediaPlayerStore";
import UnsafeModal from "components/Answer/List/File/UnsafeModal";
import CandidateThumbnailAudio from "./CandidateThumbnailAudio";
import CandidateThumbnailFile from "./CandidateThumbnailFile";
import CandidateThumbnailVideo from "./CandidateThumbnailVideo";
import CandidateThumbnailMultipleChoice from "./CandidateThumbnailMultipleChoice";
import CandidateThumbnailIDV from "./CandidateThumbnailIDV";
import CandidateThumbnailText from "./CandidateThumbnailText";
import "../index.scss";

const CandidateAnswerThumbnailItem = ({
  answer,
  index,
  candidateAnswers,
  currentQuestionAnswer,
  idvResponse,
  isMaliciousFileMuted,
  setAutoPlay,
  isProcessed
}) => {
  const { timePlaying, setTimePlaying, isPlaying } = useMediaPlayerStore();
  const { setCurrentTimeStamp } = useMediaPlayerStore();
  const selected = currentQuestionAnswer?.key === answer?.key;
  const isVideoOrAudio = [VIDEO_TYPE, AUDIO_TYPE].includes(answer?.question?.answer_type);
  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const currentSelectedIndex = candidateAnswers.findIndex(candidateAnswer =>
    candidateAnswer.key === currentQuestionAnswer?.key);
  const isNext = candidateAnswers[currentSelectedIndex + 1]?.key === answer?.key;
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [showUnsafeModal, setShowUnsafeModal] = useState(false);

  const { isMobileScreen } = useWindowResize();

  const selectedAnswersIds =
    answer?.type_specific_data?.selected_answer_options?.map(a => a.option);

  const status = useMemo(() => {
    if (ALL_IDENTITY_VERIFICATION_TYPES.includes(answer?.question?.answer_type)) {
      if (idvResponse) {
        return idvResponse?.overall_status;
      }
      return "";
    }

    if (answer?.question?.answer_type === MULTIPLE_CHOICE_TYPE ||
      answer?.question?.answer_type === CHECKBOXES_TYPE) {
      let isAllCorrect = true;
      let incorrectCount = 0;
      answer?.type_specific_data?.selected_answer_options?.forEach(a => {
        if (!a.is_correct) {
          isAllCorrect = false;
          incorrectCount += 1;
        }
      });

      const correctSelectedAnswers = [];
      const correctUnselectedAnswers = [];
      const incorrectSelectedAnswers = [];


      answer?.question?.type_specific_data?.answer_options?.forEach(a => {
        if (a.is_correct) {
          if (selectedAnswersIds?.includes(a.key)) {
            correctSelectedAnswers.push(a);
          } else {
            correctUnselectedAnswers.push(a);
          }
        } else if (selectedAnswersIds?.includes(a.key)) {
          incorrectSelectedAnswers.push(a);
        }
      });


      const correctOptions =
        answer?.question?.type_specific_data?.answer_options?.filter(a => a.is_correct);
      if (correctOptions.length === 0) {
        return "";
      }
      if ((correctSelectedAnswers?.length > 0 &&
          incorrectSelectedAnswers?.length > 0 &&
          answer?.question?.answer_type === CHECKBOXES_TYPE)) {
        return "Inconclusive";
      }

      if (correctSelectedAnswers?.length > 0 &&
        correctUnselectedAnswers?.length >= 0 &&
        incorrectSelectedAnswers?.length === 0) {
        return "Correct";
      }

      if ((incorrectCount > 0)) {
        return "Incorrect";
      }

      if (isAllCorrect) {
        return "Correct";
      }

      if (!isAllCorrect) {
        return "Inconclusive";
      }
    }
    return "";
  }, [answer.question.answer_type, answer.question.type_specific_data.answer_options,
    answer.type_specific_data.selected_answer_options, idvResponse, selectedAnswersIds]);

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = answer?.remote_link;
    a.download = `${answer?.name}.${answer?.media_extension}`;
    document.body.appendChild(a);
    a.click();

    if (document.body.contains(a)) {
      document.body.removeChild(a);
    }
  };

  const handleClickDownload = e => {
    e.stopPropagation();

    if (isMaliciousFileMuted) {
      return handleDownload();
    }

    return setShowUnsafeModal(true);
  };

  useEffect(() => {
    setWidth(ref.current?.offsetWidth);
  }, []);


  return (
    <div
      className="candidatethumbnail__item"
      onClick={() => {
        if (timePlaying) setTimePlaying({});
        store.dispatch(setCurrentQuestionAnswer(answer));
        setCurrentTimeStamp(0);

        const element = document.querySelector(".candidate__response__tabs-wrapper");
        if (element) {
          const yOffset = -30;
          const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: "smooth" });
        }
        setAutoPlay(true);
      }}
      role="button"
      onKeyDown={() => {
        if (timePlaying) setTimePlaying({});
        store.dispatch(setCurrentQuestionAnswer(answer));
      }}
      tabIndex={0}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
    >
      <div
        className={classNames("candidatethumbnail__item__header", status?.toLocaleLowerCase())}
        ref={ref}
        style={{
          backgroundColor: isMouseOver ? "#59595940" : "",
          position: "relative"
        }}
      >
        {
          answer?.remote_link && isMouseOver && !isMobileScreen && isProcessed && (
            <div onClick={handleClickDownload}>
              <DownloadThumbnailIcon />
            </div>
          )
        }

        {answer?.question?.answer_type === VIDEO_TYPE && (
          <CandidateThumbnailVideo answer={answer} />
        )}
        {answer?.question?.answer_type === AUDIO_TYPE && (
          <CandidateThumbnailAudio answer={answer} />
        )}
        {answer?.question?.answer_type === TEXT_TYPE && <CandidateThumbnailText answer={answer} />}
        {answer?.question?.answer_type === FILE_TYPE && <CandidateThumbnailFile answer={answer} />}
        {ALL_IDENTITY_VERIFICATION_TYPES.includes(answer?.question?.answer_type)
          && <CandidateThumbnailIDV status={status} />}
        {(answer?.question?.answer_type === CHECKBOXES_TYPE ||
          answer?.question?.answer_type === MULTIPLE_CHOICE_TYPE)
          && <CandidateThumbnailMultipleChoice
            answer={answer}
            status={status}
          />}
        {
          selected && <div style={{
            width: width * timePlaying[answer?.key]?.time / timePlaying[answer?.key]?.duration,
            height: 2,
            backgroundColor: "#5A2AF1",
            position: "absolute",
            bottom: 0,
            left: 0
          }}
          />
        }
      </div>
      <div
        className="candidatethumbnail__item__footer"
        style={{
          backgroundColor: isMouseOver ? "#5959591A" : selected ? "#eee" : ""
        }}
      >
        {selected ? (
          <div className="candidatethumbnail__item__footer-header selected">
            {
              !isVideoOrAudio ? "Selected" : isPlaying ? "Playing" : "Paused"
            }
          </div>
        ) : (isNext && isMobileScreen)
          ? (
            <div className="candidatethumbnail__item__footer-header selected">
              Up Next
            </div>
          )
          : (
            <div className="candidatethumbnail__item__footer-header">
              Q
              {index + 1}
              {" "}
              of
              {" "}
              {candidateAnswers?.length}
            </div>
          )}
        <div className="candidatethumbnail__item__footer-header title">
          {ReactHtmlParser(answer.question?.text)}
        </div>
      </div>

      {showUnsafeModal && (
        <UnsafeModal
          onDownload={handleDownload}
          handleCloseModal={() => setShowUnsafeModal(false)}
        />
      )}
    </div>
  );
};

CandidateAnswerThumbnailItem.defaultProps = {
  currentQuestionAnswer: null,
  isMaliciousFileMuted: false
};

CandidateAnswerThumbnailItem.propTypes = {
  answer: PropTypes.shape({
    html_text: PropTypes.string,
    key: PropTypes.string.isRequired,
    question: PropTypes.shape({
      answer_type: PropTypes.string.isRequired,
      html_text: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      type_specific_data: PropTypes.shape({
        answer_options: PropTypes.shape({
          filter: PropTypes.func,
          forEach: PropTypes.func
        })
      })
    }).isRequired,
    remote_link: PropTypes.string,
    text: PropTypes.string,
    thumbnail_remote_link: PropTypes.string,
    type_specific_data: PropTypes.shape({
      selected_answer_options: PropTypes.shape({
        forEach: PropTypes.func,
        length: PropTypes.any,
        map: PropTypes.func
      })
    })
  }).isRequired,
  candidateAnswers: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string
  })).isRequired,
  currentQuestionAnswer: PropTypes.shape({
    key: PropTypes.string,
    mediaStatus: PropTypes.string
  }),
  idvResponse: PropTypes.shape({
    overall_status: PropTypes.string
  }).isRequired,
  index: PropTypes.number.isRequired,
  isMaliciousFileMuted: PropTypes.bool
};

const mapStateToProps = state => ({
  candidateAnswers: getCandidateAnswers(state),
  currentQuestionAnswer: state?.jobsCandidate?.currentQuestionAnswer,
  idvResponse: getCandidateIdvResponse(state),
  isMaliciousFileMuted: getProfileInfo(state)?.is_malicious_file_message_muted || false
});

export default connect(mapStateToProps)(CandidateAnswerThumbnailItem);
