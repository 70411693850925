import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Lottie from "react-lottie";

import animationData from "assets/images/auth/sendEmail.json";

import "./styles.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const SendEmail = ({ t }) => (
  <div className="send-email-wrapper">
    <Lottie options={defaultOptions} height={250} width={400} />
    <div className="send-email-body">
      <h1 className="send-email-body__title">{t("resetSendEmail.header")}</h1>
      <p className="send-email-body__text">{t("resetSendEmail.text")}</p>
    </div>
  </div>
);

SendEmail.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(SendEmail);
