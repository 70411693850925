import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";

import Header from "containers/Common/HeaderContainer";
import PageWrapper from "hoc/PageWrapper";
import withDocumentTitle from "hoc/withDocumentTitle";

import animationData from "assets/images/candidate/error.json";

import "./styles.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const NotAccess = ({
  t,
  location: { search = "" }
}) => {
  const query = new URLSearchParams(search);
  const backPath = query.get("back");
  return (
    <PageWrapper header={<Header />}>
      <div className="notaccess__wrapper">
        <div className="notaccess">
          <Lottie options={defaultOptions} height={200} width={300} />

          <div className="notaccess__text-box">
            <h3 className="notaccess__title">
              {t("notAccess.header")}
            </h3>

            <p className="notaccess__text">
              {t("notAccess.text1")}
            </p>
            <p className="notaccess__text">
              {t("notAccess.text2")}
            </p>
            <div className="button__group_center notaccess__buttons">
              <Link to={decodeURIComponent(backPath)} className="main-link main-link__without-decoration">
                <button
                  className="n-button__medium n-bg-purple-100 n-white"
                  type="button"
                >
                  {t("button.returnToInterview")}
                </button>
              </Link>
              <Link to="/jobs" className="main-link main-link__without-decoration">
                <button
                  className="n-button__medium-border n-border-purple-100 n-bg-white n-purple-100"
                  type="button"
                >
                  {t("button.proceedToWillo")}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

NotAccess.propTypes = {
  t: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired
};

export default withTranslation()(withDocumentTitle(NotAccess));
