import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import withLoaderScreen from "hoc/withLoaderScreen";

import Header from "containers/Common/HeaderContainer";
import Breadcrumbs from "components/Breadcrumbs";
import PageWrapper from "hoc/PageWrapper";
import { createCompanyBreadcrumbs } from "mixins/dynamicRoutes";

import CompanyForm from "../CompanyForm";

const Create = ({
  t,
  isAbiltyСreate,
  isCardInfoLoading,
  industriesOptions,
  error,
  fetchIndustries,
  createCompany,
  clearCompanyError
}) => {
  useEffect(() => {
    clearCompanyError();
    fetchIndustries();
  }, []);

  const handleShowModals = () =>
    (isAbiltyСreate ? false : window.open("/plans?utm_source=internal&utm_medium=user&utm_campaign=locked_branding", "_blank"));

  const handleSaveCompany = ({
    name,
    location,
    site,
    layoutColor,
    buttonColor,
    logo,
    industry,
    isDefault
  }) => {
    const patternHttp = /^https?:\/\//i;
    createCompany({
      name,
      location,
      site: site && (patternHttp.test(site) ? site : `https://${site}`),
      logo,
      layout_color: layoutColor,
      button_color: buttonColor,
      industry: industry.value,
      is_default: isDefault
    });
  };

  return (
    <PageWrapper header={<Header />}>
      <Container>
        <Row justify="center">
          <Col md={12}>
            <Breadcrumbs pathList={createCompanyBreadcrumbs(t)} />
          </Col>
          <CompanyForm
            isAbiltyСreate={isAbiltyСreate}
            isCardInfoLoading={isCardInfoLoading}
            industriesOptions={industriesOptions}
            handleShowModals={handleShowModals}
            handleSaveCompany={handleSaveCompany}
            error={error}
          />
        </Row>
      </Container>
    </PageWrapper>
  );
};

Create.propTypes = {
  t: PropTypes.func.isRequired,
  isAbiltyСreate: PropTypes.bool.isRequired,
  isCardInfoLoading: PropTypes.bool.isRequired,
  industriesOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.shape.isRequired,
  fetchIndustries: PropTypes.func.isRequired,
  createCompany: PropTypes.func.isRequired,
  clearCompanyError: PropTypes.func.isRequired
};

export default withLoaderScreen(withTranslation()(Create));
