import React, { useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player/lazy";
import { getRandomVideo } from "../../../mixins/helperDynamicStyles";

const IntroVideo = () => {
  const videoPlayerRef = useRef(null);
  const [media, setMedia] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      isMounted = false;

      const { video } = getRandomVideo();

      setMedia(video);
    }

    return () => {
      isMounted = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!media) return null;

  return (
    <>
      <div className="auth-video-player">
        <div className="player">
          <ReactPlayer
            id="auth-video-player"
            ref={videoPlayerRef}
            url={media}
            playing
            loop
            muted
            width="100%"
            height="100%"
            onProgress={state => {
              if (state.playedSeconds >= 12) {
                videoPlayerRef.current.seekTo(0);
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default IntroVideo;
