export const getCompainesTableData = state => state.companies?.tableData || {};

export const getCurrentCompanyData = state => state.companies.currentCompany;

export const getCompanyError = state => state.companies.error || {};

export const getIsLoading = state => state.companies.isLoading;

export const getCompanyCandidatesStats = state =>
  state.companies.companyCandidates;

export const getCompanyJobs = state => state.companies.companyJobs || [];

export const getCurrentDateRange = state => state.companies.currentDateRange;

export const getIndustriesOptionsForSelect = ({
  companies: { industriesOptions = [] }
}) => {
  const updatedOptionsForSelect = industriesOptions.map(({ key, name }) => ({
    label: name,
    value: key
  }));

  return updatedOptionsForSelect;
};
