import { ROLE_STANDARD } from "configs/user/rolesConfig";
import * as Yup from "yup";

export const setInitialInfo = data => ({
  agency: data?.agency,
  email: data?.email || "",
  role: data?.role || ROLE_STANDARD,
  errors: {}
});

export const editFormSchema = () =>
  Yup.object().shape({
    email: Yup.string().required("Email must be present").email("Email must be a valid"),
    // change this
    role: Yup.string().required().matches(/admin|standard/)
  });
