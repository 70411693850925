import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { clearErrors, saveJob } from "store/modules/jobs/actions";
import { fetchUsersList } from "store/modules/users/actions";
import { getPreviewError, getJobCurrent, getLanguagesOptions } from "store/modules/jobs/selectors";
import { getCurrentUser, getUsersTableData } from "store/modules/users/selectors";
import { getProfileInfo } from "store/modules/profile/selectors";
import { getLanguage } from "store/modules/language/selectors";

import Preview from "components/Job/PreviewComponent";
import { getScorecardsOptions } from "store/modules/scorecards/selectors";

const mapStateToProps = state => ({
  profileInfo: getProfileInfo(state),
  currentUser: getCurrentUser(state),
  currentJob: getJobCurrent(state),
  dataUsers: getUsersTableData(state),
  error: getPreviewError(state),
  languagesOptions: getLanguagesOptions(state),
  currentLanguage: getLanguage(state),
  scorecardOptions: getScorecardsOptions(state)
});

const mapDispatchToProps = {
  fetchUsersList,
  clearErrors,
  saveJob
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Preview)
);
