/* eslint-disable no-nested-ternary */
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

const PrivateRouteAuth = ({
  isAuthenticated,
  mfaAvailable,
  confirmEmail,
  ...rest
}) => {
  const { computedMatch: { params: { uid = null, token = null } } = { params: {} } } = rest;
  useEffect(() => {
    if (uid && token) {
      confirmEmail({ uid, token });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, token]);

  return isAuthenticated
    ? (mfaAvailable
      ? <Redirect to="/security" />
      : <Redirect to="/dashboard" />)
    : <Route {...rest} />;
};

PrivateRouteAuth.defaultProps = {
  mfaAvailable: false
};

PrivateRouteAuth.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  mfaAvailable: PropTypes.bool,
  confirmEmail: PropTypes.func.isRequired
};

export default PrivateRouteAuth;
