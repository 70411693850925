/* eslint-disable import/no-cycle */
import { takeEvery, takeLatest, select, call, put } from "redux-saga/effects";

// eslint-disable-next-line import/no-cycle
import { history } from "store";

import {
  isCompaniesLoading,
  fetchCurrentCompany,
  fetchCurrentCompanyResponse,
  fetchCurrentCompanyError,
  editCompany,
  editCompanyError,
  fetchCompaniesForTable,
  fetchCompaniesForTableResponse,
  createCompany,
  createCompanyError,
  deleteCompany,
  deleteCompanyResponse,
  deleteCompanyError,
  clearCompanyError,
  createCompanyResponse,
  editCompanyResponse
} from "store/modules/companies/actions";
import { setLoadingScreen } from "store/modules/widgets/actions";

import { getToken } from "store/modules/auth/selectors";
import { getCurrentUserId } from "store/modules/users/selectors";

import { getProfileInfo } from "store/modules/profile/selectors";
import {
  getRequestWithToken,
  uploadRequestWithToken,
  deleteRequestWithToken,
  getFullUrlRequestWithToken
} from "./api";

export function* fetchCurrentCompanyWorker({ payload = "" }) {
  try {
    yield put(isCompaniesLoading(true));
    const token = yield select(getToken);
    const response = yield getRequestWithToken({
      url: `/api/companies/${payload}/`,
      token
    });
    yield put(fetchCurrentCompanyResponse(response.data));
  } catch (error) {
    console.error("[fetchCompaniesRequestError]", error);
    yield put(fetchCurrentCompanyError("Could not load current company info"));
  } finally {
    yield put(isCompaniesLoading(false));
  }
}

// eslint-disable-next-line consistent-return
export function* fetchCompaniesForTableWorker({ payload: { key, next } = {} }) {
  try {
    yield put(isCompaniesLoading(true));
    const token = yield select(getToken);

    let response;

    if (!next) {
      response = yield getRequestWithToken({
        url: `/api/companies/?agency_key=${key}&page_size=20`,
        token
      });
    } else {
      response = yield getFullUrlRequestWithToken({
        fullUrl: next,
        token
      });
    }
    yield put(fetchCompaniesForTableResponse(response.data));
    return response.data;
  } catch (error) {
    console.error("[fetchCompaniesRequestError]", error);
  } finally {
    yield put(isCompaniesLoading(false));
  }
}

export function* createCompanyWorker({ payload = {} }) {
  try {
    yield put(setLoadingScreen(true));
    yield put(clearCompanyError());

    const token = yield select(getToken);
    const method = "POST";
    const currentUserId = yield select(getCurrentUserId);
    const updatedPayload = { ...payload, agency: currentUserId };

    const response = yield uploadRequestWithToken({
      url: "/api/companies/",
      token,
      method,
      payload: updatedPayload
    });

    if ([201, 200].includes(response.status)) {
      const profileInfo = yield select(getProfileInfo);

      yield put(createCompanyResponse({
        ...response?.data,
        is_owner: true,
        owner_full_name: profileInfo?.full_name
      }));
      yield call(history.push, "/companies");
    }
  } catch (error) {
    console.error("[createCompanyError]", error);
    yield put(createCompanyError(error?.response?.data || {
      message: "Could not create company"
    }));
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* editCompanyWorker({ payload = "" }) {
  try {
    yield put(setLoadingScreen(true));
    yield put(clearCompanyError());

    const method = "PUT";
    const token = yield select(getToken);
    const currentUserId = yield select(getCurrentUserId);
    const updatedPayload = { ...payload, agency: currentUserId };

    const response = yield uploadRequestWithToken({
      url: `/api/companies/${payload.id}/`,
      token,
      method,
      payload: updatedPayload
    });

    if ([200, 201].includes(response.status)) {
      yield put(editCompanyResponse(response.data));
      yield call(history.push, "/companies");
    }
  } catch (error) {
    console.error("[editCompanyError]", error);
    yield put(editCompanyError(error?.response?.data || {
      message: "Could not save edited company info"
    }));
  } finally {
    yield put(setLoadingScreen(false));
  }
}

export function* deleteCompanyWorker({ payload = {} }) {
  try {
    yield put(isCompaniesLoading(true));
    const token = yield select(getToken);
    const response = yield deleteRequestWithToken({ url: `/api/companies/${payload}/`, token });

    if ([200, 201, 204].includes(response.status)) {
      yield put(deleteCompanyResponse(payload));
    }
  } catch (error) {
    console.error("[deleteCompanyRequestError]", error);
    yield put(deleteCompanyError("Could not delete company"));
  } finally {
    yield put(isCompaniesLoading(false));
  }
}

export function* createCompaniesWatcher() {
  yield takeEvery(fetchCurrentCompany, fetchCurrentCompanyWorker);
  yield takeLatest(fetchCompaniesForTable, fetchCompaniesForTableWorker);
  yield takeEvery(createCompany, createCompanyWorker);
  yield takeEvery(editCompany, editCompanyWorker);
  yield takeEvery(deleteCompany, deleteCompanyWorker);
}

export default createCompaniesWatcher;
