import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import autosize from "autosize";
import { isMobile } from "react-device-detect";

import { Edit, TrashBin, Check, Cancel } from "mixins/svgIcons";

import { TEXT_WARNING_MESSAGE, MAX_TEXT_SYMBOLS } from "../constants";

const Item = ({
  userId,
  profileEmail,
  comment,
  updateComment,
  deleteComment
}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [currentComment, setCurrentComment] = useState(comment.text);
  const [cashedComment, setCashedComment] = useState(comment.text);
  const [warningMessage, setWarningMessage] = useState("");
  const textarea = useRef(null);

  useEffect(() => {
    autosize(textarea.current);
  }, []);

  const enableEdit = () => setIsEditable(true);

  const disableEdit = e => {
    e.preventDefault();
    setIsEditable(false);
    setWarningMessage("");
    setCurrentComment(cashedComment);
  };

  const handleUpdateComment = () => {
    setIsEditable(false);
    setCashedComment(currentComment);
    updateComment({ userId, commentId: comment.key, text: currentComment });
  };

  const handleDeleteComment = e => {
    e.preventDefault();
    setIsEditable(false);
    setCashedComment(currentComment);
    deleteComment({ userId, commentId: comment.key });
  };

  const handleComment = e => {
    setWarningMessage(e.target.value.length >= MAX_TEXT_SYMBOLS ? TEXT_WARNING_MESSAGE : "");
    setCurrentComment(e.target.value);
  };

  return (
    <form className="comments__item">
      <div className="comments__item-header-block">
        <div className="rc-steps-item-title">{comment.author_name}</div>
        {comment.author_email && comment.author_email === profileEmail && (
          <div>
            {isEditable ? (
              <div className={`comments__item-buttons ${isMobile ? "visible" : ""}`}>
                <button
                  className="button__without-styles comments__item-buttons-purple"
                  type="button"
                  disabled={Boolean(warningMessage) || !currentComment?.trim()}
                  onClick={handleUpdateComment}
                >
                  <Check
                    svgIconClass={`comments__item-icon ${
                      warningMessage ? "disabled" : ""
                    }`}
                  />
                </button>
                <button
                  className="button__without-styles comments__item-buttons-red"
                  type="button"
                  onClick={disableEdit}
                >
                  <Cancel svgIconClass="comments__item-icon" />
                </button>
              </div>
            ) : (
              <div className={`comments__item-buttons ${isMobile ? "visible" : ""}`}>
                <button
                  className="button__without-styles comments__item-buttons-purple"
                  type="button"
                  onClick={enableEdit}
                >
                  <Edit svgIconClass="comments__item-icon" />
                </button>
                <button
                  className="button__without-styles comments__item-buttons-red"
                  type="submit"
                  onClick={handleDeleteComment}
                >
                  <TrashBin svgIconClass="comments__item-icon" />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={`commtents__item-body ${warningMessage ? "warning" : ""} ${isEditable ? "editable" : ""}`}>
        <textarea
          ref={textarea}
          row="1"
          value={currentComment}
          disabled={!isEditable}
          onChange={handleComment}
          className="comments__item-text"
        />
      </div>

      {warningMessage && (
        <p className="add-comment__warning-message">{warningMessage}</p>
      )}
    </form>
  );
};

Item.propTypes = {
  userId: PropTypes.string.isRequired,
  comment: PropTypes.shape({
    id: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string,
    text: PropTypes.string,
    created_at: PropTypes.string,
    author_email: PropTypes.string,
    author_name: PropTypes.string
  }).isRequired,
  profileEmail: PropTypes.string.isRequired,
  updateComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired
};

export default Item;
