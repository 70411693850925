import { pick } from "lodash";

// eslint-disable-next-line import/prefer-default-export
export const getScorecardsLibraryData = state =>
  state.scorecards?.tablesData?.results || [];

export const getIsScorecardsLibraryLoading = state =>
  state.scorecards?.isLoading || false;
export const getIsLatestScorecardsLibraryLoading = state =>
  state.scorecards?.isLatestLoading || false;

export const getScorecardsLibraryPagination = state =>
  pick(state.scorecards.tablesData, ["count", "next", "previous"]) || {};

export const getCriteriaTitleSuggestions = state =>
  state?.scorecards?.autocompleteTitle || [];

export const getCriteriaDescriptionSuggestions = state => {
  const suggestions = (state?.scorecards?.autocompleteDescription || []).map(item => ({
      ...item,
      text: item.description
    }));

  return suggestions;
};

export const getScorecardDetails = state =>
  state?.scorecards?.currentScorecard || {};

export const getScorecardsOptions = state =>
  state?.scorecards?.options?.results || [];

export const getScorecardInterviewCriteria = state =>
  state?.jobs?.jobCurrent?.scorecard?.criteria || [];

export const getScorecardInterviewTitle = state =>
  state?.jobs?.jobCurrent?.scorecard?.title || "";

export const getInterviewScorecard = state =>
  state?.jobs?.jobCurrent?.scorecard ?? {};

export const getInterviewHasScorecard = state =>
  Object.keys(state?.jobs?.jobCurrent?.scorecard ?? {})?.length > 0;

export const getInterviewScores = state =>
  state?.scorecards?.interviewScores ?? {};

export const getCandidateScoresLoading = state =>
  state?.scorecards?.isCandidateScoresLoading ?? false;

export const getCandidateScores = state =>
  state?.scorecards?.candidateScores ?? [];

export const getIsUpdatingScoreLoading = state =>
  state?.scorecards?.isUpdatingScoreLoading ?? false;

export const getIsCurrentCandidateScored = () => {};

export const getScorecardsSuccess = state => state?.scorecards?.success || null;

export const getScorecardsError = state => state?.scorecards?.error || null;
export const getIsScorecardDeleteLoading = state =>
  state?.scorecards?.isDeleteScorecardLoading || false;

export const getShouldShowScorecard = state =>
  state?.scorecards?.shouldShowScorecard || false;
