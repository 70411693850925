import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { Col } from "react-bootstrap";

import TablesCalendar from "components/Tables/Calendar";
import AddToCalendar from "components/Tables/Calendar/Cell/AddToCalendar";

import "./styles.scss";

const Availability = ({
  t,
  candidateName,
  candidateEmail,
  calendarTitle,
  availabilities,
  utcMinutesDelta
}) => {
  const nextMonday = moment().startOf("isoWeek").add(1, "week").set({ hours: 9 });

  return (
    <Col md={12}>
      <div className="candidate-overview__availabilities-wrapper" id="availabilities">
        <TablesCalendar
          chosenCalendarDates={availabilities.map(date => moment(date).format("YYYY-MM-DDTHH:mm"))}
          isOverview={availabilities[0] || new Date()}
          candidateName={candidateName}
          candidateEmail={candidateEmail}
          calendarTitle={calendarTitle}
          utcMinutesDelta={utcMinutesDelta}
        />
        <div className="candidate-overview__create">
          <p className="n-font-small n-black-80">{t("job.candidate.doNone")}</p>
          <AddToCalendar
            title={calendarTitle}
            startDatetime={nextMonday}
            email={candidateEmail}
            dropdownClass="atc-dropdown-top"
          />
        </div>
      </div>
    </Col>
  );
};

Availability.defaultProps = {
  utcMinutesDelta: undefined
};

Availability.propTypes = {
  t: PropTypes.func.isRequired,
  candidateName: PropTypes.string.isRequired,
  candidateEmail: PropTypes.string.isRequired,
  calendarTitle: PropTypes.string.isRequired,
  availabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  utcMinutesDelta: PropTypes.number
};

export default withTranslation()(Availability);
