/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from "react";
import { Overlay, Popover as Body } from "react-bootstrap";
import Calendar from "react-calendar";
import PropTypes from "prop-types";

import moment from "moment";
import { FaCalendar } from "react-icons/fa";
import { isMobile } from "react-device-detect";

const DateTimePopOver = ({ value, onChange, error }) => {
  const [show, setShow] = useState(0);
  const target = useRef(null);
  const [dateValue, setDateValue] = useState(value ? new Date(value) : new Date());
  const [timeValue, setTimeValue] = useState(value ? moment(value).format("HH:mm") : "00:00");

  useEffect(() => {
    setDateValue(value ? new Date(value) : new Date());
    setTimeValue(value ? moment(value).format("HH:mm") : "00:00");
  }, [value]);

  return (
    <>
      <div
        className="datepopover__button"
        style={{
          borderColor: error ? "red" : ""
        }}
        ref={target}
        onClick={() => setShow(true)}
      >
        {value
          ? moment(value).format("MMM DD YYYY hh:mm A")
          : "YYYY-MM-DD HH:mm"}
        <FaCalendar />
      </div>
      <Overlay
        target={target.current}
        show={Boolean(show)}
        placement={
        isMobile ? "top" : "bottom"
      }
      >
        {({ show: _show, ...props }) => (
          <Body
            {...props}
          >
            <div>
              <div>
                <Calendar
                  minDate={moment().add(1, "day").toDate()}
                  onChange={e => {
                    setDateValue(e);
                  }}
                  value={dateValue}
                />
                <input
                  className="datepopover__time"
                  type="time"
                  style={{
                    width: "100%"
                  }}
                  value={timeValue}
                  onChange={e => {
                    setTimeValue(e.target.value);
                  }
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 20
                }}
              >
                <button
                  type="button"
                  className="datepopover__cancel"
                  onClick={() => setShow(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="datepopover__apply"
                  onClick={e => {
                    e.preventDefault();
                    onChange(
                      moment(
                        `${moment(dateValue).format("YYYY-MM-DD")} ${timeValue}`,
                        "YYYY-MM-DD HH:mm"
                      ).toISOString()
                    );
                    setShow(false);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </Body>
        )}
      </Overlay>
    </>
  );
};

DateTimePopOver.propTypes = {
  value: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default DateTimePopOver;
