/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable operator-linebreak */
import React, { useCallback, useState, useRef } from "react";
import { withTranslation } from "react-i18next";
import { confirmable } from "react-confirm";
import { Modal, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";
import ReactHtmlParser from "react-html-parser";

import TooltipSuccess from "components/Common/Tooltip/Success";
import Switcher from "components/Switcher";
import { copyTextFromButton } from "mixins/helpers";
import { Close, Question } from "mixins/svgIcons";
import { IDV_TYPES } from "configs/jobs/constants";

const ShareBulk = ({
  t,
  name = "",
  bulkShowcaseLink = "",
  clearSharableLink,
  show,
  dismiss,
  questions,
  setAvailableAnswer
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tootipText, setTooltipText] = useState("");
  const [selected, setSelected] = useState(questions?.filter(item => item?.answer_type !== "idv")?.map(({ key }) => key));

  const changeSelected = newItem => {
    const newSelected = selected.includes(newItem)
      ? selected.filter(item => item !== newItem) : [...selected, newItem];
    setSelected(newSelected);
    setAvailableAnswer(newSelected);
  };

  const fieldWithInput = useRef(null);
  const buttonCopy = useRef(null);

  const handleClose = () => {
    clearSharableLink();
    dismiss();
  };

  const copyToClipboard = useCallback(() => {
    setTooltipText(t("modals.showCase.successTip"));
    setShowTooltip(true);
    copyTextFromButton(fieldWithInput);
  }, []);

  const handleShowTooltip = useCallback(() => {
    setTooltipText(isMobile ? t("modals.showCase.successTip") : t("modals.showCase.copyTip"));
    setShowTooltip(true);
  }, []);

  const handleHideTooltip = useCallback(() => {
    setShowTooltip(false);
  }, []);

  return (
    <Modal dialogClassName="share" show={show} onHide={handleClose}>
      <Modal.Body>
        <div className="share__block">
          <button type="button" className="share__close" onClick={handleClose}>
            <Close fill="#212B46" />
          </button>

          <h1 className="share__title n-font-giant n-font-semi-bold">
            {`
              ${t("modals.showCase.groupHeader")}
              ${name}
            `}
          </h1>
          <p className="share__text n-font-small n-black-100">
            {t("modals.showCase.groupTextGenerate")}
          </p>
        </div>

        <div className="share-input__wrapper">
          <input
            id="share-input"
            type="text"
            name="email"
            className="share-input__field n-font-medium n-black-100"
            readOnly
            ref={fieldWithInput}
            value={bulkShowcaseLink}
          />

          <button
            type="button"
            ref={buttonCopy}
            onClick={copyToClipboard}
            onMouseEnter={handleShowTooltip}
            onMouseLeave={handleHideTooltip}
            className="
              n-button__medium
              n-bg-purple-100
              n-white
            "
          >
            {t("button.copy")}
          </button>

          <TooltipSuccess
            position="bottom"
            message={tootipText}
            target={buttonCopy.current}
            show={showTooltip}
          />
        </div>

        <div className="share__question share__question-bulk">
          {questions.map(({ text, isAvailable = true, key, answer_type: answerType }, index) => (
            <div className="share__question-item" key={key}>
              <Col md={11}>
                <p className="n-font-small n-black-50">
                  <Question svgIconClass="share__question-icon" />
                  <span>{`${t("candidate.common.question")} ${index + 1}`}</span>
                </p>
                <p className="share__question-text n-font-small n-black-100">{ReactHtmlParser(text)}</p>
              </Col>
              <Switcher
                checked={IDV_TYPES.includes(answerType) ? false : isAvailable}
                disabled={IDV_TYPES.includes(answerType) === "idv"}
                handleOn={() => changeSelected(key)}
                handleOff={() => changeSelected(key)}
              />
            </div>
          ))}
        </div>

        <div className="share__bottom">
          <p className="share__text n-font-small n-black-50">
            {t("modals.showCase.textContent")}
          </p>
          <p className="share__text n-font-small n-black-50">
            {t("modals.showCase.sharing")}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ShareBulk.defaultProps = {
  name: undefined,
  show: undefined,
  dismiss: undefined,
  questions: [],
  setAvailableAnswer: undefined
};

ShareBulk.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string,
  bulkShowcaseLink: PropTypes.string.isRequired,
  show: PropTypes.bool,
  dismiss: PropTypes.func,
  clearSharableLink: PropTypes.func.isRequired,
  setAvailableAnswer: PropTypes.func,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      key: PropTypes.string,
      isAvailable: PropTypes.bool
    })
  )
};

export default confirmable(withTranslation()(ShareBulk));
