import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IdvCheckoutLoader from "views/Connect/IdvConnect/IdvCheckoutLoader";
import { getIdvPaymentStatus } from "store/modules/idv/actions";
import { getIdvCheckoutStatus } from "store/modules/idv/selectors";

const mapStateToProps = state => ({
  idvCheckoutStatus: getIdvCheckoutStatus(state)
});

const mapDispatchToProps = {
  getIdvPaymentStatus
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(IdvCheckoutLoader));
