import { takeEvery, select, put, call } from "redux-saga/effects";

import {
  fetchAllJobsStats,
  fetchAllJobsStatsError,
  fetchAllCandidatesStatsResponse,
  fetchAllCandidatesStatsError
} from "store/modules/dashboard/actions";

import { getToken } from "store/modules/auth/selectors";

/* eslint-disable-next-line */
import { getRequestWithToken } from "./api";

export function* fetchAllCandidatesStats({
  currentUserKey = "",
  currentDateRange = {}
}) {
  try {
    const token = yield select(getToken);

    if (currentUserKey && token) {
      const response = yield call(getRequestWithToken, {
        token,
        url: `/api/job/stats/candidates/?agency_key=${currentUserKey}&${currentDateRange}`
      });

      yield put(fetchAllCandidatesStatsResponse(response.data));
    }
  } catch (error) {
    console.error("Could not get companies data for chart", error);
    yield put(
      fetchAllCandidatesStatsError("Could not get data for companies chart")
    );
  }
}

export function* fetchAllJobsStatsWorker({
  payload: { currentUserKey = "", currentDateRange = "" } = {}
}) {
  try {
    const token = yield select(getToken);

    if (currentUserKey && token) {
      yield call(fetchAllCandidatesStats, {
        currentUserKey,
        currentDateRange
      });
    }
  } catch (error) {
    console.error("Could not get data data for jobs stats", error);
    yield put(fetchAllJobsStatsError("Could not get data for jobs stats"));
  }
}

export function* createDashboardWatcher() {
  yield takeEvery(fetchAllJobsStats, fetchAllJobsStatsWorker);
}

export default createDashboardWatcher;
