import React from "react";
import PropTypes from "prop-types";

const Thumb = ({ logo, clicked, blured }) => (
  <div
    role="button"
    tabIndex={0}
    className="logo-img-wrapper"
    onClick={clicked}
    onBlur={blured}
    onKeyPress={() => {}}
  >
    <img
      src={logo}
      alt="img-thumbnail"
      className="img-thumbnail"
      height={100}
      width={100}
    />
  </div>
);

Thumb.defaultProps = {
  logo: undefined,
  clicked: undefined,
  blured: undefined
};

Thumb.propTypes = {
  logo: PropTypes.string,
  clicked: PropTypes.func,
  blured: PropTypes.func
};

export default Thumb;
