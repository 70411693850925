import { CandidateAnswersType, Question } from "types/candidateAnswer.ts";

export const STEPS_PER_GROUP = 5;

export const sortQuestions = (questions: Question[]): Question[] =>
  [...questions].sort((a, b) => a.order - b.order);

export const getCurrentStep = (questions: Question[], currentStepActive: string): number =>
  questions.findIndex(q => q.key === currentStepActive) + 1;

export const getTotalGroups = (totalSteps: number): number =>
  Math.ceil(totalSteps / STEPS_PER_GROUP);

export const isQuestionCompleted = (questionKey: string, savedAnswers: CandidateAnswersType):
  boolean => savedAnswers.some(answer => answer.question.key === questionKey);

export const isQuestionCurrentActive = (questionKey: string, currentStepActive: string): boolean =>
  questionKey !== currentStepActive;

export const getStepState = (
  questionKey: string,
  currentStepActive: string,
  savedAnswers: CandidateAnswersType
): "completed" | "current" | "inactive" => {
  if (isQuestionCompleted(questionKey, savedAnswers)) return "completed";
  if (questionKey === currentStepActive) return "current";
  return "inactive";
};
