import { create } from "zustand";

const useMediaPlayerStore = create(set => ({
  currentTimeStamp: null,
  setCurrentTimeStamp: currentTimeStamp => set({ currentTimeStamp }),
  timePlaying: {},
  setTimePlaying: val => set({ timePlaying: val }),
  isPlaying: false,
  setIsPlaying: isPlaying => set({ isPlaying })
}));


export default useMediaPlayerStore;
