import React from "react";
import { TrashBin } from "mixins/svgIcons";
import PropTypes from "prop-types";

const Delete = ({ handleDelete }) => (
  <button
    onClick={handleDelete}
    className="button__without-styles icon__delete"
    type="button"
  >
    <TrashBin svgIconClass="not-clickable-icon" />
  </button>
);

Delete.propTypes = {
  handleDelete: PropTypes.func.isRequired
};

export default Delete;
