/* eslint-disable react/prop-types */
/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

import PrivateChatCandidate from "components/PrivateChatCandidate";
import { useObject } from "react-firebase-hooks/database";
import { ref } from "firebase/database";
import StartFirebase from "configs/firebase";

const PrivateRouteCandidate = ({
  isCandidate,
  candidateInfo,
  setCurrentCandidateId,
  path,
  ...rest
}) => {
  const { computedMatch: { params: { userId } } = { params: {} } } = rest;

  const [db, setDB] = useState();
  const [snapshot] = useObject((db && userId) ? ref(db, `Candidates/${userId}`) : null);

  useEffect(() => {
    const getDatabase = async () => {
      const database = await StartFirebase();
      setDB(database);
    };

    getDatabase();
  }, []);

  useEffect(() => {
    if (userId !== "undefined" || typeof userId !== "undefined") setCurrentCandidateId(userId);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeof db === typeof undefined) return null;

  const redirectUrl = snapshot?.val?.()?.jobId ? `/common/${snapshot?.val?.()?.jobId}` : "/";

  const shouldRedirect = (candidateInfo?.key === userId
    && !isCandidate) || (snapshot?.val?.()?.token?.length < 1 && !isCandidate);

  return shouldRedirect
    ? <Redirect to={redirectUrl} />
    : (
      <>
        <Route {...rest} />
        <PrivateChatCandidate candidateInfo={candidateInfo ?? {}} />
      </>
    );
};

export default PrivateRouteCandidate;
