const CURRENCY_SYMBOLS = {
  usd: "$",
  eur: "€",
  crc: "₡",
  gbp: "£",
  ils: "₪",
  inr: "₹",
  jpy: "¥",
  krw: "₩",
  ngn: "₦",
  php: "₱",
  pln: "zł",
  pyg: "₲",
  thb: "฿",
  uah: "₴",
  vnd: "₫",
  aud: "A$"
};

export const resolveCurrency = currency => CURRENCY_SYMBOLS[currency] || CURRENCY_SYMBOLS.usd;

export const calculateInterviewsExceedCount = ({
  interviewsQuota,
  interviewsCount
}) => {
  if (
    (typeof interviewsQuota !== "number" && !interviewsQuota) ||
    (typeof interviewsQuota !== "number" && !interviewsCount)
  ) {
    return 0;
  }

  if (interviewsCount > interviewsQuota) {
    return interviewsCount - interviewsQuota;
  }

  return 0;
};

export const resolvePayment = ({
  planInfo: { pricePerUser = 0, pricePerInterview = 0, planCurrency },
  usersCount,
  interviewsQuota,
  interviewsCount
}) => {
  const paymentForUsers = usersCount * pricePerUser;
  const paymentForInterviews =
    calculateInterviewsExceedCount({ interviewsQuota, interviewsCount }) *
    pricePerInterview;

  return {
    total: `${resolveCurrency(planCurrency)} ${(paymentForUsers / 100).toFixed(2)}`,
    extra: `${resolveCurrency(planCurrency)} ${(paymentForInterviews / 100).toFixed(2)}`,
    paymentForUsers,
    paymentForInterviews
  };
};

export const resolveBundleSavings = (paygPrice, bundlePrice, bundleCredits, currency) => `${resolveCurrency(currency)}${(((paygPrice * bundleCredits) - (bundlePrice * bundleCredits))?.toLocaleString("en-US")) || 0}`;

export const resolveTotalBundlePrice = (bundlePrice, bundleCredits, currency) => `${resolveCurrency(currency)}${((bundlePrice * bundleCredits)?.toLocaleString("en-US")) || 0}`;
