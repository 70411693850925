import React from "react";

export const Message = () => (
  <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.55556 2.66667H12.4444V8H3.55556V2.66667ZM14.6667 5.33333C14.6667 6.06972 15.2636 6.66667 16 6.66667V9.33333C16 10.0697 15.4031 10.6667 14.6667 10.6667H1.33333C0.596944 10.6667 0 10.0697 0 9.33333V6.66667C0.736389 6.66667 1.33333 6.06972 1.33333 5.33333C1.33333 4.59694 0.736389 4 0 4V1.33333C0 0.596944 0.596944 0 1.33333 0H14.6667C15.4031 0 16 0.596944 16 1.33333V4C15.2636 4 14.6667 4.59694 14.6667 5.33333ZM13.3333 2.44444C13.3333 2.07625 13.0349 1.77778 12.6667 1.77778H3.33333C2.96514 1.77778 2.66667 2.07625 2.66667 2.44444V8.22222C2.66667 8.59042 2.96514 8.88889 3.33333 8.88889H12.6667C13.0349 8.88889 13.3333 8.59042 13.3333 8.22222V2.44444Z" fill="#595959" />
  </svg>
);

export const Coupon = () => (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5556 5.22179C11.5556 2.76623 8.96944 0.777344 5.77778 0.777344C2.58611 0.777344 0 2.76623 0 5.22179C0 6.17457 0.391667 7.05234 1.05556 7.77734C0.683333 8.61623 0.0694444 9.2829 0.0611111 9.29123C0 9.35512 -0.0166667 9.44957 0.0194444 9.5329C0.0555556 9.61623 0.133333 9.66623 0.222222 9.66623C1.23889 9.66623 2.08056 9.32457 2.68611 8.97179C3.58056 9.4079 4.63889 9.66623 5.77778 9.66623C8.96944 9.66623 11.5556 7.67734 11.5556 5.22179ZM14.9444 11.3329C15.6083 10.6107 16 9.73012 16 8.77734C16 6.91901 14.5139 5.32734 12.4083 4.66345C12.4333 4.84679 12.4444 5.0329 12.4444 5.22179C12.4444 8.16345 9.45278 10.5551 5.77778 10.5551C5.47778 10.5551 5.18611 10.5329 4.89722 10.5023C5.77222 12.0996 7.82778 13.2218 10.2222 13.2218C11.3611 13.2218 12.4194 12.9662 13.3139 12.5273C13.9194 12.8801 14.7611 13.2218 15.7778 13.2218C15.8667 13.2218 15.9472 13.169 15.9806 13.0885C16.0167 13.0079 16 12.9135 15.9389 12.8468C15.9306 12.8385 15.3167 12.1746 14.9444 11.3329Z" fill="#595959" />
  </svg>
);
