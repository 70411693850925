import { handleActions, combineActions } from "redux-actions";

import { createJob } from "store/modules/jobs/actions";

import {
  isBillingInfoLoading,
  isCardInfoLoading,
  updateProfileInfoResponse,
  fetchProfileInfoResponse,
  fetchProfileInfoError,
  clearErrors,
  isProfileInfoLoading,
  createDeviceResponse,
  createDevice,
  createDeviceError,
  confirmDevice,
  confirmDeviceError,
  getCheckoutLinkError,
  setCurrencies,
  setCurrenciesLoading,
  updateProfileAgencyInfoResponse
} from "./actions";

const defaultState = {
  isLoading: false,
  isBillingLoading: false,
  isCardInfoLoading: false,
  errorCheckout: "",
  isAgencyUpdated: false
};

export default handleActions(
  {
    [isProfileInfoLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [isBillingInfoLoading]: (state, { payload }) => ({
      ...state,
      isBillingLoading: payload
    }),
    [getCheckoutLinkError]: (state, { payload }) => ({
      ...state,
      errorCheckout: payload
    }),
    [isCardInfoLoading]: (state, { payload }) => ({
      ...state,
      isCardInfoLoading: payload
    }),
    [combineActions(
      fetchProfileInfoResponse,
      updateProfileInfoResponse
    )]: (state, { payload }) => ({
      ...state,
      profileInfo: payload
    }),
    [combineActions(createDevice, confirmDevice)]: state => ({
      ...state,
      error: null
    }),
    [createDeviceResponse]: (state, { payload }) => ({
      ...state,
      newDevice: payload
    }),
    [combineActions(
      createDeviceError,
      confirmDeviceError,
      fetchProfileInfoError
    )]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [combineActions(clearErrors, createJob)]: state => ({
      ...state,
      billingError: null,
      error: null
    }),
    [setCurrenciesLoading]: (state, { payload }) => ({
      ...state,
      isCurrenciesLoading: payload
    }),
    [setCurrencies]: (state, { payload }) => ({
      ...state,
      currencies: payload
    }),
    [updateProfileAgencyInfoResponse]: (state, { payload }) => ({
      ...state,
      profileInfo: {
        ...state.profileInfo,
        ...payload
      }
    })
  },
  defaultState
);
