import React from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import WellcomeImage from "assets/images/onboarding/wellcome.png";

const Welcome = ({ t, setStep }) => {
  const handleClick = () => setStep(1);
  return (
    <div className="onboard__card">
      <Row>
        <Col md={8} className="onboard__card-title">
          <div>
            <h1 className="onboard__title n-grey-100 n-font-semi-bold">
              {t("onboarding.welcome")}
            </h1>
            <p className="onboard__subtitle n-grey-100 n-font-large n-font-medium-weight">
              {t("onboarding.letsGet")}
            </p>
          </div>
          <button
            className="onboard__button n-button__medium n-bg-purple-100 n-white"
            type="button"
            onClick={handleClick}
          >
            {t("button.letsGo")}
          </button>
        </Col>
        <Col md={4}>
          <img src={WellcomeImage} alt="logo" className="onboard__image" />
        </Col>
      </Row>
    </div>
  );
};

Welcome.propTypes = {
  t: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired
};

export default withTranslation()(Welcome);
