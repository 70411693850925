import React from "react";
import PropTypes from "prop-types";

const Footer = ({ children }) => (
  <div className="profile-card__footer">
    <p className="profile-card__footer_text">{children}</p>
  </div>
);

Footer.defaultProps = {
  children: undefined
};

Footer.propTypes = {
  children: PropTypes.element
};

export default Footer;
