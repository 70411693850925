import { SELECT_DAY_OF_THE_WEEK, JOB_DEADLINE_RANGE } from "configs/select";
import { addDeadlineRange } from "mixins/helpers";
import languageItems from "i18n/config";

const SELECT_LIST_DATES = addDeadlineRange(JOB_DEADLINE_RANGE);

// OPTIONS for react-select
const optionsDuration = SELECT_DAY_OF_THE_WEEK;
const optionsDeadline = SELECT_LIST_DATES;

// VALUES default values
const defaultDurationValue = optionsDuration[optionsDuration.length - 1];
const defaultDeadlineValue = optionsDeadline[optionsDeadline.length - 1];

const previewConfig = {
  duration: defaultDurationValue,
  deadline: defaultDeadlineValue,
  timezone: {
    label: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
    value: Intl.DateTimeFormat().resolvedOptions().timeZone
  },
  reviewers: [],
  reviewers_to_notify: [],
  default_language: languageItems[0],
  show_availability_calendar: true,
  redirect_url: {
    label: "jobRedirectTitle",
    supLabel: "inputLabelOptional",
    elementType: "input",
    elementConfig: {
      type: "text",
      placeholder: "jobRedirectPlaceholder"
    },
    value: "",
    validation: {
      required: false,
      isRedirectUrl: true
    },
    prefix: "overview",
    errorMessage: "jobRedirectError",
    valid: true,
    touched: false
  },
  show_hints: true
};

export { previewConfig, optionsDuration, optionsDeadline };
