import React from "react";
import PropTypes from "prop-types";

import Ava from "./Ava";
import Title from "./Title";
import ListTitle from "./ListTitle";
import Header from "./Header";
import Footer from "./Footer";
import Body from "./Body";
import List from "./List";
import EditForm from "./EditForm";

const ProfileCard = ({ children }) => (
  <div className="profile-card">{children}</div>
);

ProfileCard.Ava = Ava;
ProfileCard.Title = Title;
ProfileCard.ListTitle = ListTitle;
ProfileCard.Header = Header;
ProfileCard.Footer = Footer;
ProfileCard.Body = Body;
ProfileCard.List = List;
ProfileCard.EditForm = EditForm;

ProfileCard.defaultProps = {
  children: undefined
};

ProfileCard.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.shape({})])
};

export default ProfileCard;
