/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logo from "assets/images/icons/warning.png";
import Cross from "assets/images/icons/Cross.png";
import { useTranslation } from "react-i18next";

const NoAudioSnackbar = ({ hasAudio }) => {
  const { t } = useTranslation();
  const [hasShownSnackbar, setHasShownSnackbar] = useState(false);

  if (hasShownSnackbar || hasAudio || process.env.REACT_APP_DETECT_MUTE === "false") return null;

  return (
    <div className="audio-snackbar--container">
      <img src={logo} alt="no-audio" />
      <div className="audio-snackbar--title">
        {t("candidate.videoCreate.checkMicrophone")}
        <span className="audio-snackbar--link">
          <a
            href="#"
            data-beacon-article="5feb2c886451e825e3b8eac5"
            target="_blank"
            rel="noopener noreferrer"
            className="audio-snackbar--link"
          >
            {t("candidate.videoCreate.learnMore")}
          </a>
        </span>
      </div>
      <div onClick={() => setHasShownSnackbar(true)}>
        <img src={Cross} alt="no-audio" />
      </div>
    </div>
  );
};

export default NoAudioSnackbar;
