import React from "react";
import PropTypes from "prop-types";

import Spinner from "components/Common/Spinner";

const LoadingView = ({ t, onlyAudio }) => (
  <div className="candidate-player__starting">
    <p className="n-font-huge n-font-medium-weight n-white n-text-center">
      {t(`candidate.videoCreate.${onlyAudio ? "startingMicrophone" : "startingCamera"}`)}
    </p>
    <Spinner />
  </div>
);

LoadingView.propTypes = {
  t: PropTypes.func.isRequired,
  onlyAudio: PropTypes.bool.isRequired
};

export default LoadingView;
