import React, { useRef } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { getCountryByISO } from "mixins/helperGeolocation";
import ConnectedIcon from "../../../../assets/images/candidate/connected.svg";

const ConnectDropdownJobItem = ({
  t,
  job,
  getTriggerStages,
  currentSelectedJob,
  selectedJobsAndStages
}) => {
  const itemRef = useRef(null);

  const country = job?.location?.country
    ? getCountryByISO(job?.location?.country)?.Name ?? null
    : null;

  const city = job?.location?.city ?? null;

  const location = country && city ? `${country}, ${city}` : job?.location?.raw || null;

  return (
    <>
      <div
        onClick={() => {
          if (currentSelectedJob?.remote_id !== job.remote_id) getTriggerStages(job);
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex="0"
        className={`connect__dropdown__select-items-option ${currentSelectedJob?.remote_id === job.remote_id ? "active" : ""}`}
        ref={itemRef}
      >
        <img
          alt="connected"
          src={ConnectedIcon}
          className={`ats-connected-img
            ${selectedJobsAndStages?.some(selectedJob => selectedJob.remote_interview_id === job.remote_id)
            ? "" : "hidden"}`}
        />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="ats-job-item-title">
            {job.name}
          </span>
          <span className="ats-job-item-location">
            {isEmpty(location) ? t("marketplace.noLocationFound") : location}
          </span>
        </div>
      </div>
    </>
  );
};

ConnectDropdownJobItem.defaultProps = {
  job: undefined,
  getTriggerStages: undefined,
  currentSelectedJob: undefined,
  selectedJobsAndStages: undefined
};

ConnectDropdownJobItem.propTypes = {
  t: PropTypes.func.isRequired,
  job: PropTypes.shape({
    remote_id: PropTypes.string,
    name: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      raw: PropTypes.string
    }),
    is_connected: PropTypes.bool
  }),
  getTriggerStages: PropTypes.func,
  currentSelectedJob: PropTypes.shape({
    remote_id: PropTypes.string
  }),
  selectedJobsAndStages: PropTypes.arrayOf(PropTypes.shape({}))
};

export default withTranslation()(ConnectDropdownJobItem);
