import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCurrentIdvActivationRedirect } from "store/modules/jobs/selectors";

import { getStripeSessionInfo, fetchProfileInfo } from "store/modules/profile/actions";
import StripeSession from "views/Billing/StripeSession";
import { fetchNotifications } from "store/modules/notifications/actions";
import { getIsAgencyUpdated } from "store/modules/profile/selectors";
import { getCurrentUser } from "store/modules/users/selectors";

const mapStateToProps = state => ({
  redirectUrl: getCurrentIdvActivationRedirect(state),
  isAgencyUpdated: getIsAgencyUpdated(state),
  currentUser: getCurrentUser(state)
});

const mapDispatchToProps = {
  getStripeSessionInfo,
  fetchProfileInfo,
  fetchNotifications
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StripeSession));
