import React from "react";
import { useTranslation } from "react-i18next";

const NoAudioPopUp = () => {
  const { t } = useTranslation();

  if (process.env.REACT_APP_DETECT_MUTE === "false") return null;

  return (
    <div className="no-audio--container">
      <div className="no-audio--circle" />
      <div className="no-audio--title">
        {t("candidate.videoCreate.noAudio")}
      </div>
    </div>
  );
};

export default NoAudioPopUp;
