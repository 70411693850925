import { createAction } from "redux-actions";

export const isMarketplaceLoading = createAction("IS_MARKETPLACE_LOADING");
export const hasError = createAction("HAS_MARKETPLACE_ERROR");
export const resetMarketPlaceState = createAction("RESET_MARKETPLACE_STATE");

export const getIntegrations = createAction("FETCH_MARKETPLACE_INTEGRATIONS");
export const getIntegrationsResponse = createAction("FETCH_MARKETPLACE_INTEGRATIONS_RESPONSE");
export const getIntegrationsError = createAction("FETCH_MARKETPLACE_INTEGRATIONS_ERROR");

export const connectKombo = createAction("CONNECT_KOMBO");
export const connectKomboResponse = createAction("CONNECT_KOMBO_RESPONSE");
export const connectKomboError = createAction("CONNECT_KOMBO_ERROR");

export const activateKomboConnect = createAction("ACTIVATE_KOMBO_CONNECT");
export const activateKomboConnectResponse = createAction("ACTIVATE_KOMBO_CONNECT_RESPONSE");
export const activateKomboConnectError = createAction("ACTIVATE_KOMBO_CONNECT_ERROR");

export const updateHeaderToggle = createAction("UPDATE_HEADER_TOGGLE");

export const generateNewKey = createAction("GENERATE_NEW_KEY");
export const generateNewKeyLoading = createAction("GENERATE_NEW_KEY_LOADING");
export const generateNewKeyResponse = createAction("GENERATE_NEW_KEY_RESPONSE");
export const generateNewKeyError = createAction("GENERATE_NEW_KEY_ERROR");

export const populateMarketplaceData = createAction("POPULATE_MARKETPLACE");

export const getWebhooksLogs = createAction("GET_WEBHOOKS_LOGS");
export const getWebhooksLogsLoading = createAction("GET_WEBHOOKS_LOGS_LOADING");
export const getWebhooksLogsResponse = createAction("GET_WEBHOOKS_LOGS_RESPONSE");
export const getWebhooksLogsError = createAction("GET_WEBHOOKS_LOGS_ERROR");

export const deleteWebhookLog = createAction("DELETE_WEBHOOK_LOG");
export const deleteWebhookLogLoading = createAction("DELETE_WEBHOOK_LOG_LOADING");
export const deleteWebhookLogResponse = createAction("DELETE_WEBHOOK_LOG_RESPONSE");
export const deleteWebhookLogError = createAction("DELETE_WEBHOOK_LOG_ERROR");

export const resetWebhooksLogs = createAction("RESET_WEBHOOKS_LOGS");

export const getPaginatedWebhooksLogs = createAction("GET_PAGINATION_WEBHOOKS_LOGS");
export const getPaginatedWebhooksLogsLoading = createAction("GET_PAGINATION_WEBHOOKS_LOGS_LOADING");
export const getPaginatedWebhooksLogsResponse = createAction("GET_PAGINATION_WEBHOOKS_LOGS_RESPONSE");
export const getPaginatedWebhooksLogsError = createAction("GET_PAGINATION_WEBHOOKS_LOGS_ERROR");

export const setMarketplaceIcons = createAction("SET_MARKETPLACE_ICONS");
export const fetchMarketplaceIcons = createAction("FETCH_MARKETPLACE_ICONS");
