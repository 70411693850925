import React, { useEffect } from "react";
import PropTypes from "prop-types";

import { Container, Row, Col } from "react-bootstrap";

import Details from "components/Common/Details";
import Spinner from "components/Common/Spinner";
import HeaderPreview from "components/Common/Header/Preview";

import Header from "containers/Common/HeaderContainer";

import PageWrapper from "hoc/PageWrapper";

const Info = ({
  match: {
    params: { jobId = null }
  },
  isLoading,
  jobCurrent,
  jobCurrent: { company = {} },
  fetchCurrentJob
}) => {
  useEffect(() => {
    fetchCurrentJob(jobId);
  }, []);

  let content = (
    <>
      <HeaderPreview job={jobCurrent} company={company} />
      <Details
        jobMainInfo={jobCurrent}
        jobCompanyInfo={company}
        prefix="preview"
      />
    </>
  );

  if (isLoading) {
    content = <Spinner />;
  }

  return (
    <PageWrapper header={<Header />}>
      <Container>
        <Row justify="center">
          <Col md={12}>{content}</Col>
        </Row>
      </Container>
    </PageWrapper>
  );
};

Info.defaultProps = {
  match: undefined,
  isLoading: undefined,
  jobCurrent: undefined,
  fetchCurrentJob: undefined
};

Info.propTypes = {
  match: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  jobCurrent: PropTypes.shape({}),
  fetchCurrentJob: PropTypes.func
};

export default Info;
