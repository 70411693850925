import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-bootstrap";

import Header from "containers/Common/HeaderContainer";
import Breadcrumbs from "components/Breadcrumbs";
import PageWrapper from "hoc/PageWrapper";
import withLoaderScreen from "hoc/withLoaderScreen";
import { librariesBreadcrumbs } from "mixins/dynamicRoutes";

import Template from "../Template";
import "../styles.scss";

const Create = ({
  t,
  createLibrary,
  clearLibraryError,
  error,
  setModal
}) => (
  <PageWrapper header={<Header />}>
    <Container>
      <Row justify="center">
        <Col md={12}>
          <Breadcrumbs pathList={librariesBreadcrumbs(t)} />
        </Col>
        <Template
          handleSaveLibrary={createLibrary}
          error={error}
          clearLibraryError={clearLibraryError}
          setModal={setModal}
        />
      </Row>
    </Container>
  </PageWrapper>
);

Create.propTypes = {
  t: PropTypes.func.isRequired,
  error: PropTypes.shape.isRequired,
  createLibrary: PropTypes.func.isRequired,
  clearLibraryError: PropTypes.func.isRequired,
  setModal: PropTypes.func.isRequired
};

export default withLoaderScreen(withTranslation()(Create));
