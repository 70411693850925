/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Info } from "mixins/svgIcons";

const TooltipInfo = ({
  position,
  message,
  element = <Info />,
  prefix = " ",
  tooltipPrefix = " ",
  children = "",
  isShow,
  trigger,
  close,
  disabled,
  delay = {},
  popperConfig = {}
}) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    if (isShow) {
      ref.current.show();
      setShow(true);
    } else {
      ref.current.hide();
      setShow(false);
    }
  }, [isShow]);

  const handleClick = e => {
    e.stopPropagation();
    if (Array.isArray(trigger) && trigger.includes("click")) {
      setShow(!show);
      if (show) {
        ref.current?.hide();
      } else {
        ref.current?.show();
      }
    }
  };

  const handleTooltipClick = e => {
    e.stopPropagation();
    if (Array.isArray(trigger) && trigger.includes("click")) {
      setShow(false);
      ref.current?.hide();
    }
    if (close) {
      close();
    }
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (show &&
          ref.current &&
          !event.target.closest(".tooltip__icon") &&
          !event.target.closest(".tooltip__popup")) {
        setShow(false);
        ref.current.hide();
        if (close) close();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [show, close]);

  const triggerElement = children || (
    <span
      className={`tooltip__icon ${prefix}`}
      onClick={handleClick}
    >
      {element}
    </span>
  );

  return disabled ? (
    children || <span className={`tooltip__icon ${prefix}`}>{element}</span>
  ) : (
    <OverlayTrigger
      ref={ref}
      key={position}
      trigger={trigger}
      show={show}
      placement={position}
      delay={delay}
      overlay={
        <Tooltip
          className={`tooltip__popup ${tooltipPrefix}`}
          onClick={handleTooltipClick}
        >
          {message}
        </Tooltip>
      }
      popperConfig={{
        ...popperConfig,
        modifiers: [
          ...(Array.isArray(popperConfig.modifiers) ? popperConfig.modifiers : []),
          {
            name: "eventListeners",
            enabled: true,
            options: {
              scroll: true,
              resize: true
            }
          }
        ]
      }}
    >
      {triggerElement}
    </OverlayTrigger>
  );
};

TooltipInfo.defaultProps = {
  position: undefined,
  message: undefined,
  element: undefined,
  children: undefined,
  prefix: undefined,
  tooltipPrefix: undefined,
  isShow: undefined,
  trigger: ["click", "hover", "focus"],
  close: false,
  disabled: false,
  delay: undefined,
  popperConfig: {}
};

TooltipInfo.propTypes = {
  position: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  element: PropTypes.element,
  children: PropTypes.element,
  prefix: PropTypes.string,
  tooltipPrefix: PropTypes.string,
  isShow: PropTypes.bool,
  trigger: PropTypes.arrayOf(PropTypes.string),
  close: PropTypes.bool,
  disabled: PropTypes.bool,
  delay: PropTypes.objectOf(PropTypes.string),
  popperConfig: PropTypes.shape({})
};

export default TooltipInfo;
