/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import PropTypes from "prop-types";
import { getToken } from "store/modules/auth/selectors";
import { store } from "store";

const PrivateWrapper = ({ children, fetchProfileInfo, hasCurrentUserLoaded }) => {
  const token = getToken(store.getState());

  useEffect(() => {
    // this is to ensure that we're always getting the updated data on reload
    let isMounted = true;

    if (isMounted && !hasCurrentUserLoaded && token) {
      isMounted = false;
      fetchProfileInfo();
    }

    return () => {
      isMounted = true;
    };
  }, [hasCurrentUserLoaded, token]);

  return children;
};

PrivateWrapper.defaultProps = {
  children: undefined,
  fetchProfileInfo: undefined,
  hasCurrentUserLoaded: false
};

PrivateWrapper.propTypes = {
  children: PropTypes.element,
  fetchProfileInfo: PropTypes.func,
  hasCurrentUserLoaded: PropTypes.bool
};

export default PrivateWrapper;
