/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import { handleActions, combineActions } from "redux-actions";

import { setAvailableAnswerResponse } from "store/modules/public/actions";

import {
  isJobsLoading,
  isCommentsLoading,
  fetchUserCurrentJobResponse,
  fetchUserCurrentJobError,
  fetchCandidateAnswersResponse,
  fetchCandidateAnswersError,
  fetchCandidateHistoryResponse,
  fetchCandidateHistoryError,
  updateCandidateRatingResponse,
  updateCandidateRatingError,
  deleteCandidateResponseError,
  sendCommentResponse,
  sendCommentError,
  updateCommentResponse,
  updateCommentError,
  deleteCommentResponse,
  deleteCommentError,
  clearErrors,
  updateCandidateStatusResponse,
  setCandidateInformationLoading,
  fetchJobUsersListResponse,
  fetchNextJobUsersListResponse,
  isJobUsersLoading,
  isNextJobUsersLoading,
  updateJobUsersListResponse,
  fetchCandidateIdvResponse,
  fetchCandidateIdvLoading,
  fetchCandidateIdvError,
  fetchCandidateIdvDocumentImageResponse,
  fetchCandidateIdvIdentityImageResponse,
  fetchCandidateIdvDocumentImageError,
  fetchCandidateIdvIdentityImageError,
  fetchCandidateIdvPdfResponse,
  isJobCandidateLoading,
  setCurrentQuestionAnswer,
  setCurrentAnswerDuration,
  setCurrentAnswerStatus,
  fetchCandidateHistoryLoading
} from "./actions";
import { updateCandidateScoreCardResponse } from "../scorecards/action";
import { updateCandidateFileResponse } from "../сandidates/actions";

const defaultState = {
  isLoading: false,
  isJobCandidateLoading: true,
  candidateHistoryLoading: false,
  idvResponse: {}
};

export default handleActions(
  {
    [isJobsLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload
    }),
    [isJobCandidateLoading]: (state, { payload }) => ({
      ...state,
      isJobCandidateLoading: payload
    }),
    [isCommentsLoading]: (state, { payload }) => ({
      ...state,
      isCommentsLoading: payload
    }),
    [fetchUserCurrentJobResponse]: (state, { payload }) => ({
      ...state,
      currentCandidateJob: payload
    }),
    [updateCandidateRatingResponse]: (state, { payload }) => ({
      ...state,
      currentCandidateJob: {
        ...state.currentCandidateJob,
        rank: payload
      }
    }),
    [updateCandidateStatusResponse]: (state, { payload }) => ({
      ...state,
      currentCandidateJob: payload
    }),
    [fetchCandidateAnswersResponse]: (state, { payload }) => ({
      ...state,
      candidateAnswers: payload || []
    }),
    [updateCandidateFileResponse]: (state, { payload }) => {
      const newState = state.candidateAnswers.map(answer => {
        if (answer?.key === payload?.key) {
          return {
            ...answer,
            ...payload
          };
        }

        return answer;
      });

      return {
        ...state,
        candidateAnswers: newState
      };
    },
    [setAvailableAnswerResponse]: (
      state,
      { payload: { answerId, is_showcase_available } }
    ) => ({
      ...state,
      candidateAnswers: (state.candidateAnswers || []).map(answer =>
        (answer.key === answerId ? { ...answer, is_showcase_available } : answer))
    }),
    [fetchCandidateHistoryResponse]: (state, { payload }) => ({
      ...state,
      candidateHistory: payload
    }),
    [fetchCandidateHistoryLoading]: (state, { payload }) => ({
      ...state,
      candidateHistoryLoading: payload
    }),
    [sendCommentResponse]: (state, { payload }) => ({
      ...state,
      currentCandidateJob: {
        ...state.currentCandidateJob,
        comments: [...state.currentCandidateJob.comments, payload]
      }
    }),
    [updateCommentResponse]: (state, { payload }) => {
      const updatedComments = state.currentCandidateJob.comments.map(item => {
        if (item.key === payload.key) {
          return {
            ...item,
            ...payload
          };
        }

        return item;
      });

      return {
        ...state,
        currentCandidateJob: {
          ...state.currentCandidateJob,
          comments: updatedComments
        }
      };
    },
    [deleteCommentResponse]: (state, { payload }) => ({
      ...state,
      currentCandidateJob: {
        ...state.currentCandidateJob,
        comments: state.currentCandidateJob.comments.filter(
          item => item.key !== payload.key
        )
      }
    }),
    [combineActions(
      sendCommentError,
      updateCommentError,
      deleteCommentError,
      fetchCandidateAnswersError,
      fetchCandidateHistoryError,
      fetchUserCurrentJobError,
      updateCandidateRatingError,
      deleteCandidateResponseError
    )]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [clearErrors]: state => ({
      ...state,
      error: null
    }),
    [setCandidateInformationLoading]: (state, { payload }) => ({
      ...state,
      candidateInformationLoading: payload
    }),
    [fetchJobUsersListResponse]: (state, { payload }) => ({
      ...state,
      nextJobUsers: payload.next,
      ...(payload.query
        ? {
          jobUsersModal: payload.results,
          usersCanSeeCount: payload.user_with_access_count || 0
        }
        : {
          jobUsers: payload.results,
          jobUsersModal: payload.results,
          usersCanSeeCount: payload.user_with_access_count || 0
        })
    }),
    [fetchNextJobUsersListResponse]: (state, { payload }) => ({
      ...state,
      nextJobUsers: payload.next,
      jobUsersModal: [...state.jobUsersModal, ...payload.results]
    }),
    [isJobUsersLoading]: (state, { payload }) => ({
      ...state,
      isJobUsersLoading: payload
    }),
    [isNextJobUsersLoading]: (state, { payload }) => ({
      ...state,
      isNextJobUsersLoading: payload
    }),
    [updateJobUsersListResponse]: (
      state,
      { payload: { isAdd = false, isRemove = false, ...rest } }
    ) => ({
      ...state,
      jobUsers: isAdd
        ? [...state.jobUsers, rest]
        : isRemove
          ? state.jobUsers.filter(({ key }) => key !== rest.key)
          : state.jobUsers,
      jobUsersModal: state.jobUsersModal.map(reviewer =>
        (reviewer.key === rest.key ? rest : reviewer))
    }),
    [fetchCandidateIdvResponse]: (state, { payload }) => ({
      ...state,
      idvResponse: { ...payload, isRecruiter: true }
    }),
    [fetchCandidateIdvLoading]: (state, { payload }) => ({
      ...state,
      idvResponse: {
        ...state.idvResponse,
        isLoading: payload,
        isRecruiter: true
      }
    }),
    [fetchCandidateIdvError]: (state, { payload }) => ({
      ...state,
      idvResponse: {
        error: payload,
        isRecruiter: true
      }
    }),
    [fetchCandidateIdvDocumentImageResponse]: (state, { payload }) => ({
      ...state,
      idvResponse: {
        ...state.idvResponse,
        documentImage: payload,
        isRecruiter: true
      }
    }),
    [fetchCandidateIdvDocumentImageError]: (state, { payload }) => ({
      ...state,
      idvResponse: {
        ...state.idvResponse,
        documentImage: { error: payload },
        isRecruiter: true
      }
    }),
    [fetchCandidateIdvIdentityImageResponse]: (state, { payload }) => ({
      ...state,
      idvResponse: {
        ...state.idvResponse,
        identityImage: payload,
        isRecruiter: true
      }
    }),
    [fetchCandidateIdvIdentityImageError]: (state, { payload }) => ({
      ...state,
      idvResponse: {
        ...state.idvResponse,
        identityImage: { error: payload },
        isRecruiter: true
      }
    }),
    [fetchCandidateIdvPdfResponse]: (state, { payload }) => ({
      ...state,
      idvResponse: {
        ...state.idvResponse,
        pdfUrl: payload,
        isRecruiter: true
      }
    }),
    [updateCandidateScoreCardResponse]: (state, { payload }) => ({
      ...state,
      currentCandidateJob: {
        ...state.currentCandidateJob,
        draft_score_key: payload?.data?.key
      }
    }),
    [fetchCandidateIdvPdfResponse]: (state, { payload }) => ({
      ...state,
      idvResponse: {
        ...state.idvResponse,
        pdfUrl: payload,
        isRecruiter: true
      }
    }),
    [setCurrentQuestionAnswer]: (state, { payload }) => ({
      ...state,
      currentQuestionAnswer: {
        ...payload
      }
    }),
    [setCurrentAnswerDuration]: (state, { payload }) => ({
      ...state,
      currentQuestionAnswer: {
        duration: payload
      }
    }),
    [setCurrentAnswerStatus]: (state, { payload }) => ({
      ...state,
      currentQuestionAnswer: {
        mediaStatus: payload
      }
    })
  },
  defaultState
);
