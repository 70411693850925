import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

import TimerCountdown from "components/CountdownTimer";

import srcPoster from "assets/images/video/poster.jpg";

import { Camera } from "mixins/svgIcons";

import "../Player/styles.scss";

const PlayerDesktop = ({
  t,
  isRecording,
  videoBlob,
  videoLink,
  videoPlayerRef,
  videoInputRef,
  onFileUpload,
  cashedElapsingTime,
  videoDevices,
  videoDeviceId,
  onSwitchCamera,
  hasEBML,
  isEBMLCalled
}) => {
  const isShowControls = !isRecording && (videoBlob || videoLink);
  const isVideoEmpty = !(isRecording || videoBlob || videoLink);
  const [devicesListOpen, setDevicesListOpen] = useState(false);

  const [height, setHeight] = useState("330px");

  const handleMetadata = event => {
    const { clientWidth } = videoPlayerRef?.current || {};
    const { videoHeight, videoWidth } = event?.target || {};
    const clientHeight = `${Math.min(
      Number((clientWidth * videoHeight) / videoWidth),
      350
    )}px`;
    setHeight(clientHeight);
  };

  const style = { height };

  return (
    <div className="player" style={style}>
      {isRecording && (
        <p className="player__recording-top-panel-desktop">
          <span
            className={`player__recording-spot ${
              isRecording ? "green" : "red"
            }`}
          />
          <span>{t("candidate.videoCreate.playerRecording")}</span>
        </p>
      )}

      {devicesListOpen && isVideoEmpty && (
        <div className="video__camera-devices">
          {videoDevices.map(videoDevice => (
            <span
              className={
                videoDeviceId === videoDevice?.deviceId
                  ? "video__camera-device active"
                  : "video__camera-device"
              }
              onClick={() => {
                onSwitchCamera(videoDevice?.deviceId);
                setDevicesListOpen(false);
              }}
              onKeyDown={() => {
                onSwitchCamera(videoDevice?.deviceId);
                setDevicesListOpen(false);
              }}
              role="button"
              tabIndex={0}
              key={videoDevice?.deviceId}
            >
              {videoDevice?.name}
            </span>
          ))}
        </div>
      )}

      {videoDevices?.length > 1 && isVideoEmpty && (
        <span
          className="video__switch-camera"
          onClick={() => setDevicesListOpen(!devicesListOpen)}
          onKeyDown={() => setDevicesListOpen(!devicesListOpen)}
          role="button"
          tabIndex={0}
        >
          <Camera svgIconClass="n-icon__medium" />
        </span>
      )}

      {isRecording && (
        <TimerCountdown cashedElapsingTime={cashedElapsingTime} />
      )}

      {onFileUpload && (
        <input
          ref={videoInputRef}
          type="file"
          accept="video/*"
          capture="user"
          className="mobile-player__video-input"
          onChange={onFileUpload}
        />
      )}

      <div className="player">
        <div className={`processing-wrapper ${!hasEBML ? "no-ebml" : ""}`}>
          {
            isEBMLCalled && !hasEBML ? (
              <span className="processing-wrapper__text">
                {t("errors.ebmlError.0")}
                <a href="https://support.willo.video/article/52-network-error" target="_blank" rel="noopener noreferrer">{t("errors.ebmlError.1")}</a>
                {t("errors.ebmlError.2")}
                <a href="https://support.willo.video/article/116-transfering-to-another-device" target="_blank" rel="noopener noreferrer">{t("errors.ebmlError.3")}</a>
              </span>
            ) : null
          }

          <video
            ref={videoPlayerRef}
            poster={isVideoEmpty ? srcPoster : ""}
            controls={isShowControls}
            controlsList="nodownload"
            autoPlay
            muted
            onLoadedMetadata={handleMetadata}
            className={isVideoEmpty ? "" : "job-player"}
          />
        </div>
      </div>
    </div>
  );
};

PlayerDesktop.defaultProps = {
  isRecording: false,
  videoBlob: undefined,
  cashedElapsingTime: undefined,
  videoPlayerRef: undefined,
  videoInputRef: undefined,
  onFileUpload: undefined,
  videoDevices: [],
  videoDeviceId: undefined,
  onSwitchCamera: undefined,
  hasEBML: true,
  isEBMLCalled: false
};

PlayerDesktop.propTypes = {
  t: PropTypes.func.isRequired,
  isRecording: PropTypes.bool,
  videoBlob: PropTypes.shape({}),
  videoLink: PropTypes.string.isRequired,
  cashedElapsingTime: PropTypes.number,
  videoPlayerRef: PropTypes.shape({
    current: PropTypes.object
  }),
  videoInputRef: PropTypes.shape({
    current: PropTypes.object
  }),
  onFileUpload: PropTypes.func,
  videoDevices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      name: PropTypes.string
    })
  ),
  videoDeviceId: PropTypes.string,
  onSwitchCamera: PropTypes.func,
  hasEBML: PropTypes.bool,
  isEBMLCalled: PropTypes.bool
};

export default withTranslation()(PlayerDesktop);
