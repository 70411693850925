import React from "react";
import PropTypes from "prop-types";

const Body = ({ children }) => (
  <div className="profile-card__body">{children}</div>
);

Body.defaultProps = {
  children: undefined
};

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export default Body;
