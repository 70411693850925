/* eslint-disable camelcase */
export const getCurrentCandidateId = state => state?.candidates?.currentCandidateId;

export const getCurrentCandidate = state => {
  const currentCandidateId = getCurrentCandidateId(state);
  return state?.candidates?.[currentCandidateId] || {};
};

export const getCurrentCandidateLang = state => {
  const currentCandidateId = getCurrentCandidateId(state);
  return state.candidates[currentCandidateId]?.candidateInfo?.selected_language;
};

export const getIsVideoUploading = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.isVideoLoading;
};

export const getIsCandidateLoading = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.isCandidateLoading || false;
};

export const getIsInfoUpdating = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.isInfoUpdating || false;
};

export const getErrorMessage = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.error;
};

export const getCandidateJobInfo = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.candidateJobInfo || {};
};

export const getCandidateJobMainInfo = state => {
  const {
    title = "",
    salary = "",
    video_link = "",
    description = "",
    deadline = "",
    status = ""
  } = getCandidateJobInfo(state);

  return { title, salary, video_link, description, deadline, status };
};

export const getCandidateJobInfoCompany = state =>
  getCandidateJobInfo(state).company || {};

export const getSavedAnswers = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.savedAnswers || [];
};

export const getQuestionsForCandidate = state =>
  getCandidateJobInfo(state).questions || [];

export const getCurrentQuestion = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.questionCurrentCashed || {};
};

export const getCandidateJobId = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate?.jobId || "";
};

export const getCandidateJobIdByKey = (state, key) => {
  const currentCandidate = state?.candidates?.[key];
  return currentCandidate?.jobId || "";
};

export const getCandidateToken = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate?.candidateToken || "";
};

export const getCandidateTokenByKey = (state, key) => {
  const currentCandidate = state?.candidates?.[key];
  return currentCandidate?.candidateToken || "";
};

export const getCandidateVolume = state => Number(state?.candidates?.volume) || 1;

export const getAudioDevice = state => state?.candidates?.audioDevice;
export const getVideoDevice = state => state?.candidates?.videoDevice;

export const getCandidateInfo = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate?.candidateInfo || {};
};

export const getCandidatePosition = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.candidatePosition || {};
};

export const getCandidateError = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.candidateInfoError || "";
};

export const getChoosenCalendarDates = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.choosenCalendarDates || [];
};

export const getPreviousQuestionId = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.previousQuestion || "";
};

export const getIsAllAnsweredSaved = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.isAllAnsweredSaved || false;
};

export const getCashedAnswer = state => {
  const currentCandidate = getCurrentCandidate(state);
  return currentCandidate.cachedAnswer || [];
};

export const getVideoArchives = state => state.candidates.videoArchives || [];

export const getHintsAndTips = state => state.candidates.hintsAndTips || [];

export const getCurrentIdvCheckData = state => state.candidates.idvCheckData || {};

export const getDownloadHasError = state => state.candidates.hasDownloadError || false;
