import React from "react";
import BeatLoader from "react-spinners/BeatLoader";
import PropTypes from "prop-types";

const Spinner = ({ size, color = "#5a2af1" }) => (
  <div className="lds-ring">
    <BeatLoader color={color} loading size={size} />
  </div>
);

Spinner.defaultProps = {
  size: 15,
  color: "#5a2af1"
};

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string
};

export default Spinner;
