export const TIME_FORMATS = {
  DEFAULT: "h:mm a",
  HOURS: "h a"
};

export const DATE_FORMATS = {
  SPACE_SEPARATOR: "DD MMM YYYY",
  SPACE_SEPARATOR_LONG_MONTH: "DD MMMM YYYY",
  SPACE_SEPARATOR_DAY_MONTH: "DD MMM",
  SPACE_SEPARATOR_DAY_MONTH_YEAR: "DD MMM YYYY",
  SPACE_SEPARATOR_MONTH_FIRST: "MMMM DD YYYY",

  SLASH_SEPARATOR: "DD/MM/YY",
  SLASH_SEPARATOR_LONG_YEAR: "DD/MM/YYYY",

  HYPHEN_SEPARATOR: "YYYY-MM-DD",

  COMA_SEPARATOR: "MMMM DD, YYYY",

  DAY_OF_WEEK: "dddd",

  CALENDAR: "YYYYMMDDTHHmmssZ",

  MONTH: "MMMM",
  DAY_YEAR: "DD, YYYY",
  DAY: "DD",
  YEAR: "YYYY",
  TIME: "LT"
};

// should be '00:15 am' not '12:15 am', but another time should be in 12h format
export const defaultTimeFormat = (momentDate, withoutMinutes) => {
  if (momentDate.hours() < 1) {
    return momentDate.format("HH:mm a");
  }

  return momentDate.format(withoutMinutes ? TIME_FORMATS.HOURS : TIME_FORMATS.DEFAULT);
};
