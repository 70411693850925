import { handleActions, combineActions } from "redux-actions";

import {
  isLibrariesLoading,
  isCurrentLibraryLoading,
  fetchLibraries,
  fetchLibrariesResponse,
  fetchLibrariesError,
  isLatestLibrariesLoading,
  fetchLatestLibraries,
  fetchLatestLibrariesResponse,
  fetchLibrariesDivided,
  fetchLibrariesDividedResponse,
  fetchLibrariesDividedError,
  duplicateLibrary,
  duplicateLibraryResponse,
  duplicateLibraryError,
  deleteLibrary,
  deleteLibraryResponse,
  deleteLibraryError,
  fetchCurrentLibrary,
  fetchCurrentLibraryResponse,
  fetchCurrentLibraryError,
  createLibrary,
  createLibraryError,
  updateLibrary,
  updateLibraryError,
  clearLibraryError
} from "./actions";

const defaultState = {
  isLoading: false,
  isLatestLibrariesLoading: false,
  isLoadingDivided: false,
  tablesData: {
    results: []
  },
  librariesDivided: {},
  success: null,
  error: null
};

const getOptions = (templates, type, trigger) => (templates || []).map(({
  key: value, title: label, content, system_default
}) => ({ value, label, content, type, system_default, trigger }));

export default handleActions(
  {
    [isLibrariesLoading]: (state, { payload }) => ({
      ...state,
      isLoading: payload,
      isLoadingDivided: payload
    }),
    [isCurrentLibraryLoading]: (state, { payload }) => ({
      ...state,
      isCurrentLoading: payload
    }),
    [combineActions(fetchLibraries, fetchLibrariesDivided)]: () => defaultState,
    [fetchLibrariesResponse]: (state, { payload }) => ({
      ...state,
      tablesData: payload
    }),
    [fetchLibrariesDividedResponse]: (state, { payload }) => ({
      ...state,
      librariesDivided: {
        invitation_email: getOptions(payload?.invitation?.email, "email", "invite"),
        invitation_sms: getOptions(payload?.invitation?.sms, "sms", "invite"),
        reminder_email: getOptions(payload?.invitation_reminder?.email, "email", "reminder"),
        reminder_sms: getOptions(payload?.invitation_reminder?.sms, "sms", "reminder"),
        success_notification_email: getOptions(payload?.success_notification?.email, "email", "success_email")
      }
    }),
    [isLatestLibrariesLoading]: (state, { payload }) => ({
      ...state,
      isLatestLibrariesLoading: payload
    }),
    [combineActions(
      fetchLatestLibraries,
      duplicateLibrary,
      deleteLibrary,
      createLibrary,
      updateLibrary
    )]: state => ({
      ...state,
      error: null,
      success: null,
      errorSaving: null
    }),
    [fetchLatestLibrariesResponse]: (state, { payload: { next, previous, count, results } }) => ({
      ...state,
      tablesData: { next, previous, count, results: [...state.tablesData.results, ...results] }
    }),
    [duplicateLibraryResponse]: (state, { payload }) => ({
      ...state,
      success: payload
    }),
    [combineActions(
      fetchLibrariesError,
      fetchLibrariesDividedError,
      duplicateLibraryError,
      deleteLibraryError,
      fetchCurrentLibraryError,
      createLibraryError,
      updateLibraryError
    )]: (state, { payload }) => ({
      ...state,
      error: payload
    }),
    [combineActions(createLibraryError, updateLibraryError)]: (state, { payload }) => ({
      ...state,
      errorSaving: payload
    }),
    [deleteLibraryResponse]: (state, { payload }) => ({
      ...state,
      tablesData: {
        ...state.tablesData,
        results: state.tablesData.results.filter(item => item.key !== payload),
        count: state.tablesData.count - 1
      }
    }),
    [fetchCurrentLibrary]: state => ({
      ...state,
      currentLibrary: {},
      error: null,
      success: null
    }),
    [fetchCurrentLibraryResponse]: (state, { payload }) => ({
      ...state,
      currentLibrary: payload
    }),
    [clearLibraryError]: state => ({
      ...state,
      error: null,
      success: null,
      errorSaving: null
    })
  },
  defaultState
);
