import React from "react";
import PropTypes from "prop-types";
import { Overlay, Tooltip } from "react-bootstrap";

const TooltipSuccess = ({ position, message, target, show }) => (
  <Overlay key={position} target={target} show={show} placement={position}>
    {({ show: _show, ...props }) => <Tooltip {...props}>{message}</Tooltip>}
  </Overlay>
);

TooltipSuccess.defaultProps = {
  position: undefined,
  message: undefined,
  target: undefined,
  show: undefined
};

TooltipSuccess.propTypes = {
  position: PropTypes.string,
  message: PropTypes.string,
  target: PropTypes.shape({}),
  show: PropTypes.bool
};

export default TooltipSuccess;
